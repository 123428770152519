const alphabetsArray = [
  'A', 'B', 'C', 'D', 'E', 'F', 'G',
  'H', 'I', 'J', 'K', 'L', 'M', 'N',
  'O', 'P', 'Q', 'R', 'S', 'T', 'U',
  'V', 'W', 'X', 'Y', 'Z',
];

export async function createAlphabetTabs () {
  let alphabetTabs = []; // eslint-disable-line
  for (let index = 0; index < 26; index += 1) { // 26 alphabets
    alphabetTabs.push({
      alphabet: alphabetsArray[index],
      disabled: true,
    });
  }
  return alphabetTabs;
}
