<template>
  <IcrsTemplate>
    <template #content>
      <h2 class="text-center">Contact Us</h2>
      <br>
      <div class="text-center">
        <font-awesome-icon
          :icon="['fal', 'envelope-open-text']"
          size="7x"
        />
      </div>
      <br>
      <h3 class="text-center">
        Send us a feedback or enquiry at&nbsp;
        <a href="mailto:support@lawnet.com">support@lawnet.com</a>
      </h3>
    </template>
  </IcrsTemplate>
</template>
<script>
import IcrsTemplate from './template/IcrsStaticTemplate.vue';

export default {
  components: {
    IcrsTemplate,
  },
};
</script>
