<template>
  <IcrsWrapperPage>
    <div class="page-management">
      <div class="container container-fluid">
        <div class="page-management-content">
          <div class="page-title">Page Management</div>
          <!-- success alert-->
          <b-alert
            class="has-icon alert-large"
            style="margin-top: 10px;"
            :show="dismissCountDown"
            dismissible
            variant="success"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
          >
            <div style="display: flex">
              <span class="has-icon"><i class="fa fa-check-circle"></i></span>
              <div class="alert-content">
                <h4>Success</h4>
                <p v-if="isDeleted">Page has been deleted successfully.</p>
                <p v-if="isEdited">Page has been edited successfully.</p>
                <p v-if="isSaved">New page has been created successfully.</p>
              </div>
            </div>
          </b-alert>
          <TabPageManagement :isLoading="isLoading" @clickDelete="clickDeletePage" @sortItems="sortItems"/>

           <!--Delete confirm dialog-->
          <b-modal
            :id="'deletePageModal'"
            size="xl"
            :centered="true"
            :hide-footer="true"
            header-close-content="<i class='fa fa-times-circle' />"
            :title="deleteModalTitle"
            @hide="closeDeletePageModal"
          >
          <div>
            <div>
              <p style="margin-bottom: 0">
                Are you sure that you want to delete <strong>{{ deleteItemTitle }}</strong>? This action cannot be undone.
              </p>
            </div>
            <div style="margin-top: 10px;">
              <b-button
                @click="handleDeletePage"
                variant="primary"
                :disabled="isLoading"
              >Confirm</b-button>
              <b-button
                @click.stop="closeDeletePageModal"
                variant="outline-primary"
                style="margin-left: 5px"
              >Cancel</b-button>
            </div>
          </div>
          </b-modal>
        </div>
      </div>
    </div>
  </IcrsWrapperPage>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TabPageManagement from '@/components/tab-page-management/TabPageManagement.vue';
import IcrsWrapperPage from '@/pages/icrs-wrapper-page/IcrsWrapperPage.vue';
import PageManagementService from '@/services/page-management.service';

export default {
  name: '',
  components: {
    IcrsWrapperPage,
    TabPageManagement,
  },
  methods: {
    ...mapActions('pageManagement', ['setCheckPage', 'setNewPageCurrent', 'setUpdating', 'setBottomNavList', 'setFooterList', 'execSortNavItems', 'execSortFooterItems', 'setPreviewData']),
    clickDeletePage (item, index) {
      this.selectItem = { ...item, index };
      this.$bvModal.show('deletePageModal');
    },
    handleDeletePage () {
      this.resetMessage();
      this.isLoading = true;
      PageManagementService.deletePageDetail(this.selectItem.id).then((res) => {
        if (res.data === 'delete successful') {
          this.isDeleted = true;
          this.dismissCountDown = 10;
          if (this.selectItem.checkPage === 'Navigation') {
            const newList = this.bottomNavList.toSpliced(this.selectItem.index, 1);
            this.setBottomNavList(newList);
          } else {
            const newList = this.footerList.toSpliced(this.selectItem.index, 1);
            this.setFooterList(newList);
          }
        }
      }).finally(() => {
        this.isLoading = false;
        this.closeDeletePageModal();
      });
    },
    closeDeletePageModal () {
      this.$bvModal.hide('deletePageModal');
      this.selectItem = {};
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
      if (this.dismissCountDown === 0) {
        this.resetMessage();
      }
    },
    resetMessage () {
      this.dismissCountDown = 0;
      this.isDeleted = false;
      this.isSaved = false;
      this.isEdited = false;
    },
    async sortItems ({ checkPage, idPage1, idPage2 }) {
      this.isLoading = true;
      if (checkPage === 'Navigation') {
        await this.execSortNavItems({ checkPage, idPage1, idPage2 });
      } else {
        await this.execSortFooterItems({ checkPage, idPage1, idPage2 });
      }
      this.isLoading = false;
    },
  },
  data () {
    return {
      dismissCountDown: 0,
      isSaved: false,
      isDeleted: false,
      isEdited: false,
      isLoading: false,
      selectItem: {},
    };
  },
  mounted () {
    this.setCheckPage('');
    this.setNewPageCurrent(null);
    this.setPreviewData(undefined);
    if (this.updating) {
      //
      this.resetMessage();
      if (this.updating === 'create') {
        this.isSaved = true;
      }
      if (this.updating === 'edit') {
        this.isEdited = true;
      }
      this.dismissCountDown = 10;
      this.setUpdating('');
    }
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  },
  computed: {
    ...mapGetters('pageManagement', ['updating', 'bottomNavList',
      'footerList', 'isLoadingList']),
    deleteModalTitle () {
      const selectType = this.selectItem.type ? this.selectItem.type : '';
      return `Delete ${selectType}`;
    },
    deleteItemTitle () {
      return this.selectItem.title ? this.selectItem.title : '';
    },
  },
  watch: {
    isLoadingList: {
      handler (value) {
        this.isLoading = value;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
/* styles go here */
.page-management {
  margin-top: $nav-bar-height-breadcrumbs;
  .page-management-content{
    margin-left: 12px;
    margin-bottom: 70px;
    .page-title {
      font-weight: 600;
      padding: 30px 0 16px 0;
      font-size: $page-title;
    }
    .page-content{
      background-color: #fff;
      box-shadow: 0px 3px 6px #0000000D;
      border-radius: 4px;
      min-height: 374px;

      margin-bottom: 130px;
    }
  }
}
</style>
