<template>
  <fieldset :class="['t-input', withIcon, noLabelClass]">
    <label v-if="label" :for="localId" class="col-form-label pt-0" v-dompurify-html="label"></label>
    <div role="group">
      <b-form-input
        v-bind="$props"
        v-on="$listeners"
        :type="type"
        :plaintext="plaintext"
        :id="localId"
        :name="name"
        :disabled="disabled"
        :required="required"
        :autofocus="autofocus"
        :state="state"
        :aria-invalid="ariaInvalid"
        :readonly="readonly"
        :placeholder="placeholder"
        :trim="trim"
        :lazy="lazy"
        :debounce="debounce"
        :value="value"
        @input="inputEvent"
        @focus="focusEvent"
        @change="changeEvent"
        @update="updateEvent"
        @blur="blurEvent"
      >
        <slot></slot>
      </b-form-input>
      <!-- This will only be shown if the preceding input has an invalid state -->
      <b-form-invalid-feedback id="input-live-feedback">
        {{ invalidMessage }}
      </b-form-invalid-feedback>
      <i v-if="defaultIcon" :class="['icon', 'fas', faIcon]"></i>
      <!-- @slot Use this slot for custom icon -->
      <span v-if="customIcon" class="icon">
        <slot name="custom-icon" class="icon"></slot>
      </span>
    </div>
  </fieldset>
</template>

<script>
/**
 * Input component.
 */
export default {
  name: 'Input',
  status: 'release',
  components: {
    // BFormInput,
    // BFormInvalidFeedback,
    // BFormGroup,
  },
  props: {
    /**
     *
     * Input type. e.g `password` or `text` or `email` or `number`
     */
    type: {
      type: String,
    },
    /**
     *
     * Text to place in the label/legend of the form group
     */
    label: {
      type: String,
    },
    /**
     *
     * Used to set invalid message if the preceding input has an invalid state
     */
    invalidMessage: {
      type: String,
    },
    /**
     *
     * Used to set the 'id' attribute on the rendered content, and used as the base to generate any additional element IDs as needed
     */
    id: {
      type: String,
    },
    /**
     *
     * Sets the value of the 'name' attribute on the form control
     */
    name: {
      type: String,
    },
    /**
     *
     * plaintext
     */
    plaintext: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * When set to 'true', disables the component's functionality and places it in a disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Adds the 'required' attribute to the form control
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * When set to 'true', attempts to auto-focus the control when it is mounted, or re-activated when in a keep-alive. Does not set the 'autofocus' attribute on the control
     */
    autofocus: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Controls the validation state appearance of the component. 'true' for valid, 'false' for invalid', or 'null' for no validation state
     */
    state: {
      type: Boolean,
      default: null,
    },
    /**
     *
     * Sets the 'aria-invalid' attribute value on the wrapper element. When not provided, the 'state' prop will control the attribute
     */
    ariaInvalid: {
      type: [Boolean, String],
      default: false,
    },
    /**
     *
     * Sets the 'readonly' attribute on hte form control
     */
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Sets the 'placeholder' attribute value on the form control
     */
    placeholder: {
      type: String,
    },
    /**
     *
     * When set, trims any leading and trailing white space from the input value. Emulates the Vue '.trim' v-model modifier
     */
    trim: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * When set, updates the v-model on 'change'/'blur' events instead of 'input'. Emulates the Vue '.lazy' v-model modifier
     */
    lazy: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * When set to a number of milliseconds greater than zero, will debounce the user input. Has no effect if prop 'lazy' is set
     */
    debounce: {
      type: [Number, String],
      default: 0,
    },
    /**
     *
     * The current value of the input. Result will always be a string or number.
     */
    value: {
      type: [String, Number],
    },
    /**
     * The value to set icon name. It uses fontawesome icon
     */
    icon: {
      type: String,
    },
  },
  methods: {
    inputEvent (value) {
      /**
       * Input event triggered by user interaction. Emitted after any formatting (not including 'trim' or 'number' props) and after the v-model is updated
       *
       * @event input
       * @property {object} value - Current value of input
       * @type {function}
       */
      this.$emit('input', value);
    },
    changeEvent (value) {
      /**
       * Change event triggered by user interaction. Emitted after any formatting (not including 'trim' or 'number' props) and after the v-model is updated.
       *
       * @event change
       * @property {object} value - Current value of input
       * @type {function}
       */
      this.$emit('change', value);
    },
    updateEvent (value) {
      /**
       * Emitted to update the v-model
       *
       * @event update
       * @property {object} value - Value of input, after any formatting. Not emitted if the value does not change
       * @type {function}
       */
      this.$emit('update', value);
    },
    blurEvent (event) {
      /**
       * Emitted after the input looses focus
       *
       * @event blur
       * @property {object} event - Native blur event (before any formatting)
       * @type {function}
       */
      this.$emit('blur', event);
    },
    focusEvent (event) {
      /**
       * Emitted when the input is on focus
       *
       * @event focus
       * @property {object} event - Native focus event (before any formatting)
       * @type {function}
       */
      this.$emit('focus', event);
    },
  },
  computed: {
    localId () {
      // eslint-disable-next-line no-underscore-dangle
      return this.id ? this.id : `t-input-${this._uid}`;
    },
    faIcon () {
      if (this.icon) {
        return `fa-${this.icon}`;
      }
      return '';
    },
    defaultIcon () {
      return this.icon && !this.$slots['custom-icon'];
    },
    customIcon () {
      return this.$slots['custom-icon'];
    },
    withIcon () {
      return this.icon || this.$slots['custom-icon'] ? 'with-icon' : '';
    },
    noLabelClass () {
      return !this.label ? 'no-label' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin text-md-14 {
  font-size: $font-md;
  font-weight: $weight-normal;
  line-height: $ln-height-20;
}

@mixin label-md-14 {
  font-size: $font-md;
  line-height: $ln-height-20;
  font-weight: $weight_semi_bold;
}

@mixin input-style {
  @include text-md-14;
  height: 36px;
  padding: $gap-xs;
  color: $blue-gray-400;
}

.t-input {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  position: relative;
  padding: 0;
  &.with-icon {
    &.no-label {
      .icon {
        top: 12.33px;
      }
    }
    .icon {
      top: 34.33px;
      right: 10px;
      position: absolute;
      color: #9ea9b9;
    }
    .form-control {
      padding-right: 28px;
    }
  }
  label {
    @include label-md-14;
    color: $blue-gray-400;
    padding-bottom: $gap-xxs;
  }
  .form-control-plaintext {
    @include input-style;
  }
  .form-control {
    @include input-style;
    border: 1px solid $blue-gray-100;
    &:disabled {
      background: $blue-gray-100;
      color: $blue-gray-200;
      border: 1px solid $blue-gray-100;
    }

    &.is-invalid {
      border: 1px solid $red-500;
      background-image: none;
      &:focus {
        box-shadow: none;
      }
    }

    &.is-valid {
      border: 1px solid $green-500;
      background-image: none;
    }

    &:focus {
      border: 1px solid $blue-500;
      box-shadow: none;
    }

    &::-webkit-input-placeholder {
      /* Edge */
      @include text-md-14;
      color: $blue-gray-200;
    }

    &::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      @include text-md-14;
      color: $blue-gray-200;
    }

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      @include text-md-14;
      color: $blue-gray-200;
    }
  }
}
</style>

<docs>
  ```jsx
  <div>
    <div>
      <Input v-bind:state="true" id="input-1" label="Valid Input" placeholder="Please type something here" />
    </div>
    <div class="mt-lg">
      <Input id="input-2" label="Input field" placeholder="Please type something here" />
    </div>
    <div class="mt-lg">
      <Input id="input-3" label="Active" value="Lorem Ipsum" placeholder="Please type something here" />
    </div>
    <div class="mt-lg">
      <Input id="input-4" label="Error" v-bind:state="false" invalidMessage="This is a warning message" />
    </div>
    <div class="mt-lg">
      <Input id="input-5" label="Disabled" disabled placeholder="Placeholder" />
    </div>
    <div class="mt-lg">
      <Input id="input-6" label="Fields with icon" icon="calendar-alt" placeholder="Please type something here" />
    </div>
    <div class="mt-lg">
      <Input id="input-7" label="Password Field" type="password" placeholder="Please type something here" />
    </div>
    <div class="mt-lg">
      <Input id="input-8" icon="search" placeholder="Please type something here" />
    </div>
  </div>
  ```
</docs>
