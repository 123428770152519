<template>
  <div
    class="loading-spinner"
    :class="{ 'loading-spinner--lg': size == sizes.LARGE }"
  >
    <div class="text-center">
      <font-awesome-icon
        class="icon"
        :icon="['far', 'spinner-third']"
        :size="iconSize"
      />
    </div>
    <div
      v-if="text"
      class="loading-text"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
const SIZES = {
  MEDIUM: 'md',
  LARGE: 'lg',
};
export default {
  props: {
    text: String,
    size: {
      type: String,
      default: SIZES.MEDIUM,
      validate (value) {
        return Object.values(SIZES).includes(value);
      },
    },
  },

  computed: {
    sizes () {
      return SIZES;
    },

    iconSize () {
      const map = {
        [SIZES.MEDIUM]: '1x',
        [SIZES.LARGE]: '2x',
      };

      return map[this.size];
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
  display: inline-block;

  .loading-text {
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
  }

  &--lg {
    .loading-text {
      margin-top: 12px;
      font-size: 20px;
    }
  }

  &/deep/ svg {
    animation: spin 500ms ease-in forwards infinite;
  }
}
</style>
