import Vue from 'vue';
import VueRouter from 'vue-router';

import defaultRoutes from './defaultRoutes';
import projectRoutes from './projectRoutes';
import pageManagementRoutes from './pageManagementRoutes';
import { authGuard, pageGuard } from './guards';

export const BASE_ROUTE = process.env.VUE_APP_BASE_ROUTE || '/';

Vue.use(VueRouter);

/**
 * Instantiate the router
 */
const router = new VueRouter({
  base: BASE_ROUTE,
  routes: [
    ...defaultRoutes,
    ...projectRoutes,
    ...pageManagementRoutes,
  ],
});

/**
 *  Route Guards - Ordering is important
 */
router.beforeEach(authGuard);
router.beforeEach(pageGuard);

export default router;
