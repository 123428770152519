export default {
  setGuidedSearch ({ commit }, guidedSearch) {
    commit('setGuidedSearch', guidedSearch);
  },
  setDropdownGuidedTag ({ commit }, dropdownGuidedTag) {
    commit('setDropdownGuidedTag', dropdownGuidedTag);
  },
  setActiveGuidedPrefix ({ commit }, activeGuidedPrefix) {
    commit('setActiveGuidedPrefix', activeGuidedPrefix);
  },
  setSelectedLevel1 ({ commit }, selectedLevel1) {
    commit('setSelectedLevel1', selectedLevel1);
  },
  setSelectedLevel2 ({ commit }, selectedLevel2) {
    commit('setSelectedLevel2', selectedLevel2);
  },
  setSelectedLevel3 ({ commit }, selectedLevel3) {
    commit('setSelectedLevel3', selectedLevel3);
  },
  setLevel2CategoriesForSelectedLevel1 ({ commit }, level2CategoriesForSelectedLevel1) {
    commit('setLevel2CategoriesForSelectedLevel1', level2CategoriesForSelectedLevel1);
  },
  setLevel3CategoriesForSelectedLevel2 ({ commit }, level3CategoriesForSelectedLevel2) {
    commit('setLevel3CategoriesForSelectedLevel2', level3CategoriesForSelectedLevel2);
  },
  setAllGuidedCategories ({ commit }, allGuidedCategories) {
    commit('setAllGuidedCategories', allGuidedCategories);
  },
  setAdvancedSearchSelectedLevel ({ commit }, advancedSearchSelectedLevel) {
    commit('setAdvancedSearchSelectedLevel', advancedSearchSelectedLevel);
  },
};
