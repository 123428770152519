<template>
  <connector-wizard-widget
    class="search-connector-styles"
    @go-to-connector="goToConnector"
    @go-to-dashboard="goToDashboard"
  />
</template>

<script>
import { goToConnector, goToDashboard } from '../helpers';

export default {
  methods: {
    goToConnector,
    goToDashboard,
  },
};
</script>
