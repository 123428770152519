export default {
  /**
   * @param user
   * mirrors localStorage->jhl-userModel
   * state is stored so that vuex can watch it
   */
  user: undefined,
  isProcessing: false,
  isFail: false,
  isSuccess: false,
  message: '',
  isADAuth: undefined,
  latestErrorConfig: undefined,
};
