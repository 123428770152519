import {
  TIMEOUT_MESSAGE, AUTH_ERROR_STATUS, AUTH_UNAUTHORIZED_STATUS, AUTH_AAD_UNAUTHORIZED_STATUS, SAML_LOGIN_MESSAGE,
} from '@/constants/auth-service-constants';
import store from '../../store';
import route from '../../router';
import ConfigService from '../config.service';

const createICRSErrorResponseInterceptor = (httpConfig) => {
  const interceptor = httpConfig.interceptors.response.use(
    (response) => {
      // clear errorConfig
      const responseConfig = response.response ? response.response.config : null;

      store.dispatch('authService/resetErrorConfig', { responseConfig });
      return response;
    },
    /**
     * @param {import('axios').AxiosError} error
     */
    (error) => {
      // Reject promise if usual error
      const status = error.response ? error.response.status : null;

      if (status === AUTH_ERROR_STATUS && ConfigService.getSAMLAuthEnabled()) {
        httpConfig.interceptors.response.eject(interceptor);
        store.dispatch('authService/logout', {
          message: SAML_LOGIN_MESSAGE,
          isForced: true,
        });
      }

      if (status === AUTH_ERROR_STATUS) {
        httpConfig.interceptors.response.eject(interceptor);
        store.dispatch('authService/logout', {
          message: TIMEOUT_MESSAGE,
          isForced: true,
        });
      }
      if (status === AUTH_UNAUTHORIZED_STATUS) {
        const targetPath = route.currentRoute.fullPath;
        store.dispatch('authService/logout', {
          message: TIMEOUT_MESSAGE,
          targetPath,
          isForced: true,
        });
      }
      if (status === AUTH_AAD_UNAUTHORIZED_STATUS) {
        const targetPath = route.currentRoute.fullPath;
        store.dispatch('authService/aadLogout', {
          message: SAML_LOGIN_MESSAGE,
          targetPath,
        });
      }

      return Promise.reject(error);
    },
  );
};

export default createICRSErrorResponseInterceptor;
