import GUIDED_ONTOLOGY from './guided-ontology.json';
import USEFUL_LINKS from './useful-links.json';
import FAQ from './faq.json';
import POLICY from './policy.json';
import TERMS from './terms.json';
import AGGREGATIONS from './aggregations.json';
import FILTERGROUPCONFIG from './filter-group-config.json';

export default {
  GUIDED_ONTOLOGY,
  USEFUL_LINKS,
  FAQ,
  POLICY,
  TERMS,
  AGGREGATIONS,
  FILTERGROUPCONFIG,
};
