import Vue from 'vue';
import getDefaultState from './state';

export default {
  setBottomNavList (state, bottomNavList) {
    Vue.set(state, 'bottomNavList', bottomNavList);
  },
  setFooterList (state, footerList) {
    Vue.set(state, 'footerList', footerList);
  },
  resetState (state) {
    Object.assign(state, getDefaultState());
  },
  setNewPageCurrent (state, newPageCurrent) {
    Vue.set(state, 'newPageCurrent', newPageCurrent);
  },
  setCheckPage (state, checkPage) {
    Vue.set(state, 'checkPage', checkPage);
  },
  setPreviewData (state, previewData) {
    Vue.set(state, 'previewData', previewData);
  },
  setUpdating (state, updating) {
    Vue.set(state, 'updating', updating);
  },
  setIsLoadingList (state, isLoadingList) {
    Vue.set(state, 'isLoadingList', isLoadingList);
  },
};
