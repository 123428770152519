
class NotificationsModel {
  constructor (data = {}) {
    this.append = !_.isUndefined(data.append) ? data.append : false;
    this.title = !_.isUndefined(data.title) ? data.title : 'Notification';
    this.bodyContent = !_.isUndefined(data.bodyContent) ? data.bodyContent : null;
    this.autoHideDelay = !_.isUndefined(data.autoHideDelay) ? data.autoHideDelay : 3000;
    this.variant = !_.isUndefined(data.variant) ? data.variant : 'success'; // info, success, warning or danger
  }
}

export default NotificationsModel;
