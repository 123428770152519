<template>
  <div class="container">
    <div class="icrs-unauthorized-page-body row">
      <div class="col-md-8 offset-md-2">
        <h4>{{ contentDetails.message }}</h4>
        <p>Please try again or re-login with another account.</p>
        <button
          class="btn btn-primary"
          @click="handleButtonClick"
        >{{ contentDetails.buttonLabel }}</button>
      </div>
    </div>
  </div>

</template>
<script>

import ConfigService from '@/services/config.service';

const ERROR_STATE = {
  ICRS_NO_PERMISSION: 'no_icrs_access_permission',
  AUTH_FAIL: 'authentication_fail',
  MAXIMUM_CONCURRENT_LOGIN_REACHED: 'maximum_concurrent_login_reached',
};

const ERROR_MESSAGES = {
  [ERROR_STATE.ICRS_NO_PERMISSION]: {
    message: 'You’ve successful login but you do not have access rights to ICRS',
    buttonLabel: 'Logout',
  },
  [ERROR_STATE.AUTH_FAIL]: {
    message: 'Authentication fail, internal server error',
    buttonLabel: 'Retry Login',
  },
  [ERROR_STATE.MAXIMUM_CONCURRENT_LOGIN_REACHED]: {
    message: 'You have reached the maximum concurrent logins',
    buttonLabel: 'Retry Login',
  },
};

export default {
  name: 'UnauthorizedPage',
  data: function data () {
    return {
      contentDetails: {
        message: '',
        buttonLabel: '',
      },
      errCode: '',
    };
  },
  mounted () {
    this.errCode = this.$route.query.oauth2_login_error || '';
    this.contentDetails = ERROR_MESSAGES[this.errCode] || ERROR_MESSAGES[ERROR_STATE.AUTH_FAIL];
  },
  methods: {
    handleButtonClick: function handleButtonClick () {
      // call logout to make sure that lawnet session is cleared
      window.location.href = ConfigService.getICRSSALLogoutUrl();
    },
  },
};
</script>
<style lang="scss">
.icrs-unauthorized-page-header {
  width: 100%;
  padding: 1rem 0;
}

.icrs-unauthorized-page-body {
  margin-top: 10rem;
}
</style>
