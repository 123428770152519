import EventBusService from '@/services/event-bus.service';
import DocManagerService from '../../../services/doc-manager.service';
import DocRequestParameter from '../../../services/models/doc-manager/doc-request-parameter.model';
import DocUpdateParameter from '../../../services/models/doc-manager/doc-update-parameter.model';
// import DocDeleteParameter from '../../../services/models/doc-manager/doc-delete-parameter.model';

export default {
  getBlacklistedField ({ commit }) {
    return DocManagerService.getBlacklistedFields()
      .then((data) => {
        commit('setBlacklistedField', data);
      })
      .catch((err) => { throw (err); });
  },
  getAllClasses ({ commit }) {
    return DocManagerService.getAllClasses()
      .then((data) => {
        commit('setAllClass', data);
      })
      .catch((err) => { throw (err); });
  },
  getIndividualClass ({ commit }, params) {
    return DocManagerService.getIndividualClass(params)
      .then((data) => {
        commit('setIndividualClass', data);
      })
      .catch((err) => { throw (err); });
  },
  execSearchDoc ({ commit }, params) {
    const docRequestParameter = new DocRequestParameter(params);

    return DocManagerService.execSearchDoc(docRequestParameter)
      .then((data) => {
        commit('setDocumentContent', data);
      })
      .catch((err) => { throw (err); });
  },
  execUpdateDoc ({ state: { blacklistedField = [] } = {} }, params) {
    const docUpdateParameter = new DocUpdateParameter(params);
    const { id } = params;

    blacklistedField.forEach((item) => {
      delete docUpdateParameter[item];
    });

    return DocManagerService.execUpdateDoc(docUpdateParameter, id)
      .then(() => {
        EventBusService.emitNotificationEvent({ title: 'Successfully updated document' });
      })
      .catch((err) => { throw (err); });
  },
  execDeleteDoc (_, params) {
    const docDeleteParameter = params;

    return DocManagerService.execDeleteDoc(docDeleteParameter)
      .then(() => {
        EventBusService.emitNotificationEvent({ title: 'Successfully deleted document' });
      })
      .catch((err) => { throw (err); });
  },
};
