<template>
  <div
    :id="id"
    class="dot-badge"
  />
</template>

<script>
export default {
  name: 'dot-badge',
  props: {
    id: {
      type: String,
      default: 'my-dot-badge',
    },
  },
};
</script>

<style lang="scss" scoped>
.dot-badge {
  width: 7px;
  height: 7px;
  border-radius: 100px;
  background: $red-500;
  position: absolute;
  z-index: 1;
}
</style>
