<template>
  <IcrsWrapperPage>
    <div class="add-edit-data-page">
      <div class="container container-fluid">
        <div class="add-edit-data-content">
          <div class="add-edit-data-title">Add/Edit Data</div>
          <b-alert
            class="has-icon alert-large"
            :show="dismissCountDown"
            variant="success"
            dismissible
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
          >
            <div style="display: flex">
              <span class="has-icon"><i class="fa fa-check-circle"></i></span>
              <div class="alert-content">
                <h4>Success</h4>
                <p style="margin-bottom: 0">Files have been uploaded successfully.</p>
              </div>
            </div>
          </b-alert>
          <div class="add-edit-data-box">
            <p>
              You can add the case data into the excel template and then upload the
              file into the system.
            </p>
            <div class="guide-template-container">
              <div class="guide-template-section" @click="handleUploadGuide">
                <font-awesome-icon :icon="['fas', 'file-download']" /> Excel upload Guide
              </div>
              <div class="guide-template-section" @click="handleDownloadTemplate">
                <font-awesome-icon :icon="['fas', 'file-download']" /> Sample Excel template
              </div>
            </div>
            <div class="upload-box">
              <Upload
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                limitText="up to 2MB (XLSX)"
                :uploadApi="uploadFile"
                @uploadFile="handleFile"
                :tempFolder="tempFolderName"
                :saveSuccessfully="isSaveSuccessfully"></Upload>
            </div>
          </div>
          <div class="add-edit-data-btn">
            <button
              class="btn btn-cancel"
              @click="handleCancel"
            >Cancel</button>
            <button
              class="btn btn-primary"
              :disabled="!fileIdList.length || isSaved"
              @click="handleSaveFile"
            >Save</button>
          </div>
        </div>
      </div>
    </div>
  </IcrsWrapperPage>
</template>
<script>
import IcrsWrapperPage from '@/pages/icrs-wrapper-page/IcrsWrapperPage.vue';
import Upload from '@/components/Upload.vue';
import { mapActions } from 'vuex';
import router from '@/router/index';

export default {
  components: { IcrsWrapperPage, Upload },
  data () {
    return {
      fileIdList: [],
      tempFolderName: '',
      dismissCountDown: 0,
      isSaved: false,
      isSaveSuccessfully: false,
    };
  },
  methods: {
    ...mapActions({
      uploadFile: 'searchService/uploadFile',
      downloadExcelTemplate: 'searchService/downloadExcelTemplate',
      saveFile: 'searchService/saveFile',
    }),
    handleFile (value) {
      this.fileIdList = value.filter(item => item.id).map(item => item.id);
      this.tempFolderName = value.length ? value[0].tempFolderName : '';
    },
    handleUploadGuide () {
      const uploadGuideRoute = '/icrs/files/icrs_excel_upload_guide_v1.0.pdf';
      window.open(uploadGuideRoute, '_blank');
    },
    handleDownloadTemplate () {
      this.downloadExcelTemplate();
    },
    handleSaveFile () {
      this.isSaved = true;
      this.saveFile({ tempFolderName: this.tempFolderName, listUuid: this.fileIdList })
        .then((res) => {
          if (res === 'Save successfully !') {
            this.isSaveSuccessfully = true;
            this.dismissCountDown = 10;
            this.fileIdList = [];
            this.tempFolderName = '';
          }
        }).finally(() => {
          this.isSaved = false;
          this.isSaveSuccessfully = false;
        });
    },
    handleCancel () {
      router.go(-1);
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};
</script>

<style scoped lang="scss">
@mixin font($size, $height, $weight : normal) {
  font-size: $size !important;
  line-height: $height !important;
  font-weight: $weight;
}

.add-edit-data-page {
  margin-top: $nav-bar-height-breadcrumbs;

  .add-edit-data-content {
    margin: 0 12px;

    .add-edit-data-title {
      font-weight: 600;
      padding-top: 30px;
      padding-bottom: 16px;
      font-size: $page-title;
    }

    .add-edit-data-box {
      background-color: #fff;
      padding: 16px;
      border-radius: 4px;

      .guide-template-container {
        display: flex;
        gap: 40px;
        margin-bottom: 32px;

        .guide-template-section {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #751C24;
          gap: 8px;
        }
      }

      .upload-box{
        width: 50%;
        overflow:hidden;
      }

    }

    .add-edit-data-btn {
      padding-top: 24px;
      padding-bottom: 48px;
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      button {
        padding: 10px 18px !important;
      }

      .btn-cancel {
        border: 1px solid #283c61;

        &:hover {
          color: #000 !important;
          border: 1px solid #283c61 !important;
        }
      }
    }
  }
}
</style>
