import constants from '@/constants/search-service-constants';
import actions from '../actions';
import createState from '../state';

describe('search-core/filters/promoted-content action tests', () => {
  test('setPromotedContentFilterEnabled', () => {
    const commit = jest.fn();
    const enablePromoteFilter = true;

    actions.setPromotedContentFilterEnabled({ commit }, enablePromoteFilter);
    expect(commit).toHaveBeenLastCalledWith('setPromotedFilterEnable', enablePromoteFilter);
  });

  test('setPromotedDateRangeMode', () => {
    const commit = jest.fn();
    const rangeMode = constants.PROMOTED_DATE_RANGE_MODE.TODAY;

    actions.setPromotedDateRangeMode({ commit }, rangeMode);
    expect(commit).toHaveBeenLastCalledWith('setPromotedDateRangeMode', rangeMode);
  });

  test('setPromotedContentDateObject', () => {
    const commit = jest.fn();
    const dispatch = jest.fn();

    const dateObject = {
      field: 'metadata.promote',
      start: new Date(),
      end: new Date(),
    };

    actions.setPromotedContentDateObject({ commit, dispatch }, dateObject);
    expect(commit).toHaveBeenLastCalledWith('setPromoteDateObject', dateObject);
    expect(dispatch).toHaveBeenLastCalledWith('setSearchQueryPromotedDateObject');
  });

  test('setSearchQueryPromotedDateObject', () => {
    const dispatch = jest.fn();
    const state = createState();
    actions.setSearchQueryPromotedDateObject({ dispatch, state });
    expect(dispatch.mock.calls[0][0]).toBe('searchService/setPromoteDateObject',
      state.promoteDateObject, { root: true });
    expect(dispatch.mock.calls[1][0]).toBe('searchService/newResults/setPromoteDateObject',
      state.promoteDateObject, { root: true });
  });

  test('resetPromotedDateConfig', () => {
    const commit = jest.fn();
    const dispatch = jest.fn();

    actions.resetPromotedDateConfig({ dispatch, commit });
    expect(commit).toHaveBeenLastCalledWith('resetPromotedContent');
    expect(dispatch).toHaveBeenLastCalledWith('setSearchQueryPromotedDateObject');
  });

  test('removePromotedContentFilters', () => {
    const dispatch = jest.fn();
    const filters = [];
    actions.removePromotedContentFilters({ dispatch }, filters);
    expect(dispatch.mock.calls[0][0]).toBe('resetPromotedDateConfig');
    expect(dispatch.mock.calls[1][0]).toBe('triggerSearchQuery', filters);
  });

  test('triggerSearchQuery', () => {
    const dispatch = jest.fn();
    const filters = [];
    actions.triggerSearchQuery({ dispatch }, filters);
    expect(dispatch.mock.calls[0][0]).toBe('searchService/setPromoteDateObject', undefined, { root: true });
    expect(dispatch.mock.calls[1][0]).toBe('searchService/newResults/setPromoteDateObject', undefined, { root: true });
    expect(dispatch.mock.calls[2][0]).toBe('searchService/filters/setSelectedFilters', filters, { root: true });
    expect(dispatch.mock.calls[3][0]).toBe('searchService/filters/executeFilterSearch', {}, { root: true });
  });

  test('setPromotedContentFilterForSavedSearch', () => {
    const dispatch = jest.fn();
    const dateObject = {
      field: 'metadata.promote',
      start: new Date(),
      end: new Date(),
    };
    actions.setPromotedContentFilterForSavedSearch({ dispatch }, dateObject);
    expect(dispatch.mock.calls[0][0]).toBe('setPromotedContentFilterEnabled', true);
    expect(dispatch.mock.calls[1][0]).toBe('setPromotedDateRangeMode', constants.PROMOTED_DATE_RANGE_MODE.CUSTOM_RANGE);
    expect(dispatch.mock.calls[2][0]).toBe('setPromotedContentDateObject', dateObject);
  });
});
