<template>
  <div>
    <OverviewTemplate :overview-routes="overviewRoutes">
      <div slot="topic">Module</div>
      <ModuleMarkdown slot="markdown"/>
    </OverviewTemplate>
  </div>
</template>

<script>

import ModuleMarkdown from './ModuleWidgetOverview.md';
import { moduleRoutes } from '../RouteSettings';
import OverviewTemplate from '../components/OverviewTemplate';

export default {
  name: 'Module',
  data () {
    return {
      selectedItemPath: '',
      moduleRoutes,
    };
  },
  components: {
    ModuleMarkdown,
    OverviewTemplate,
  },
  computed: {
    overviewRoutes () {
      return moduleRoutes;
    },
  },
};
</script>
