import searchConstants from '@/constants/search-service-constants';

export default ({
  selectedFilters = [],
  selectedGroup = null,
  filterModal = {
    selectedFilters: [],
    searchKeyword: '',
    sortBy: searchConstants.FILTER_SORT_BY_OPTIONS.MOST_TO_LEAST,
    isFirstOpenHasSelectedFilters: false,
    tempTopicsQuery: {
      should: [],
      must: [],
    },
  },
  filterGroupings,
} = {}) => ({
  selectedFilters,
  selectedGroup,
  filterModal,
  filterGroupings,
});
