<template>
  <b-button
    v-bind="$props"
    v-bind:class="['button', withIcon, textButton, iconOnly, smallButtonClass]"
    :variant="variant"
    :active="active"
    :disabled="disabled"
    :block="block"
    :type="type"
    :tag="tag"
    :pill="pill"
    :squared="squared"
    @click="clickEvent"
  >
    <i v-if="defaultIcon" :class="['icon', 'fas', faIcon, iconOnly]"></i>
    <!-- @slot Use this slot for custom icon -->
    <span v-if="customIcon" :class="['icon', iconOnly]">
      <slot name="custom-icon"></slot>
    </span>
    <span>
      <!-- @slot Use this as default slot -->
      <slot></slot>
    </span>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue';
/**
 * The &lt;Button&gt; component generally renders a &lt;button&gt; element.
 * However, you can also render an &lt;a&gt; element by providing an href prop value.
 * You may also generate vue-router <router-link> when providing a value for the to prop (vue-router is required).
 */
export default {
  name: 'Button',
  status: 'release',
  components: {
    BButton,
  },
  props: {
    /**
     * The value to set icon name. It uses fontawesome icon
     */
    icon: {
      type: String,
    },
    /**
     * The value to set the button's 'type' attribute to. Can be one of 'button', 'submit', or 'reset'
     */
    variant: {
      type: String,
      default: 'secondary',
    },
    /**
     * The html element used for the button.
     * `button, a`
     */
    type: {
      type: String,
      default: 'button',
    },
    /**
     * Specify the HTML tag to render instead of the default tag
     */
    tag: {
      type: String,
      default: 'button',
    },
    /**
     * Renders a 100% width button (expands to the width of it's parent container)
     */
    block: {
      type: Boolean,
      default: false,
    },
    /**
     * When set to 'true', places the component in the active state with active styling
     */
    active: {
      type: Boolean,
      default: false,
    },
    /**
     * When set to 'true', the button will have small size as the predefined in figma
     */
    isSmall: {
      type: Boolean,
      default: false,
    },
    /**
     * When set to 'true', disables the component's functionality and places it in a disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Renders the button with the pill style appearance when set to 'true'
     */
    pill: {
      type: Boolean,
      default: false,
    },
    /**
     * Renders the button with non-rounded corners when set to 'true'
     */
    squared: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textButton () {
      if (this.variant && /text/g.test(this.variant)) {
        return `text-${this.variant.split('-').pop()}`;
      }
      return '';
    },
    faIcon () {
      return `fa-${this.icon}`;
    },
    defaultIcon () {
      return this.icon && !this.$slots['custom-icon'];
    },
    customIcon () {
      return this.$slots['custom-icon'];
    },
    iconOnly () {
      return !this.$slots.default ? 'icon-only' : '';
    },
    withIcon () {
      return this.icon || this.$slots['custom-icon'] ? 'button-with-icon' : '';
    },
    smallButtonClass () {
      return this.isSmall ? 'small' : '';
    },
  },
  methods: {
    clickEvent (event) {
      /**
       * Click event.
       *
       * @event click
       * @property {object} MouseEvent - Native click event object
       * @type {function}
       */
      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss" scoped>
// @mixin custom-button-variant{
  // color: $color;

// }
.icon {
  &:not(.icon-only) {
    margin-right: $gap-xxs;
  }
  font-size: $font-xs;
}

.button {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  font-size: $font-md;
  line-height: $ln-height-20;
  min-height: 36px;
  padding: $gap-xs $btn-padding-x;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    vertical-align: baseline;
  }
  &.small {
    min-height: 24px;
    line-height: 1;
    font-size: $font-sm;
    padding: $gap-xxs $gap-xs;
    min-width: 55px;
  }
  &:not(.icon-only):not(.small) {
    min-width: $min_width_button;
  }
  &.text-primary {
    // @include custom-button-variant(
      // $color_disabled: $blue-gray-200,
      // $color: $blue-500,
      // $hover-background: $blue-200,
      // $hover-border: $blue-200
    // );
  }
  &.btn-primary {
    // @include custom-button-variant(
      // $background_disabled: $blue-300,
      // $border_disabled: $blue-300,
      // $hover-background: $blue-400,
      // $hover-border: $blue-400
    // );
  }
  &.btn-outline-warning:disabled {
    color: $yellow-400;
  }
  &.btn-outline-warning:not(:disabled):not(.disabled):active,
  &.btn-outline-warning:not(:disabled):not(.disabled):hover,
  &.btn-outline-warning:not(:disabled):not(.disabled).active {
    color: $white-500;
  }
  &.btn-success {
    // @include custom-button-variant($hover-background: $green-400, $hover-border: $green-400);
  }
  &.btn-warning {
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled).active {
      color: $white-500;
    }
    // @include custom-button-variant(
    //   $color: $white-500,
    //   $hover-background: $yellow-400,
    //   $hover-border: $yellow-400
    // );
  }
  &.btn-danger {
    // @include custom-button-variant($hover-background: $red-400, $hover-border: $red-400);
  }
  &.button-with-icon:not(.icon-only):not(.small) {
    padding-right: 28px;
  }
}
</style>

<docs>
  ```jsx
  <div>
    <div>Contained Style</div>
    <div>
      <Button variant="primary">Primary</Button>
      <Button variant="success">Success</Button>
      <Button variant="danger">Danger</Button>
      <Button variant="warning">Warning</Button>
    </div>

    <div class="mt-md">Small Size</div>
    <div>
      <Button is-small variant="primary">Primary</Button>
      <Button is-small variant="success">Success</Button>
      <Button is-small variant="danger">Danger</Button>
      <Button is-small variant="warning">Warning</Button>
    </div>

    <div class="mt-md">Small Size With Icon</div>
    <div>
      <Button is-small icon='plus' variant="primary">Add</Button>
    </div>

    <div class="mt-md">Disabled Contained Style</div>
    <div>
      <Button disabled variant="primary">Primary</Button>
      <Button disabled variant="success">Success</Button>
      <Button disabled variant="danger">Danger</Button>
      <Button disabled variant="warning">Warning</Button>
    </div>

    <div class="mt-md">Outlined Style</div>
    <div>
      <Button variant="outline-primary">Primary</Button>
      <Button variant="outline-success">Success</Button>
      <Button variant="outline-danger">Danger</Button>
      <Button variant="outline-warning">Warning</Button>
    </div>

    <div class="mt-md">With Icon</div>
    <div>
      <Button variant="primary" icon='plus'>
        Button with default icon
      </Button>
    </div>
  </div>
  ```
</docs>
