<template>
  <b-modal
    @hide="$emit('hide')"
    :id="modalId"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    hide-footer
    static
    lazy
    modal-class="p-0"
    dialog-class="m-0 p-0 overflow-hidden min-vh-100 min-vw-100"
    content-class="rounded-0 jumbo-modal-content"
    body-class="p-0 d-flex flex-column"
  >
    <jumbo-modal-nav
      :title="title"
      @hide="closeJumboModal"
    >
      <template #nav>
        <slot name="nav" />
      </template>
    </jumbo-modal-nav>
    <div class="main-content-holder d-flex justify-content-md-center">
      <slot name="content" />
    </div>
  </b-modal>
</template>

<script>
import JumboModalNav from './components/JumboModalNav';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    modalId: {
      type: String,
      default: 'jumbo-modal',
    },
  },
  components: {
    JumboModalNav,
  },
  methods: {
    closeJumboModal () {
      this.$bvModal.hide(this.modalId);
      this.$emit('hide');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .jumbo-modal-content {
  height: 100%;
  background: $blue-gray-50 !important;
  top: 0 !important;
  position: fixed !important;
}

/deep/ .modal-body {
  height: inherit;
  overflow: auto;
}

.main-content-holder {
  overflow: scroll;
  padding: $gap-lg 0;
}
</style>
