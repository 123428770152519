<template>
  <div class="liveDemoComponent">
    <h1>About this widget</h1>
    <h2>Page Widget<br>HTML Tag: faceted-search-widget<br>Default enterprise Search UI for administrative use.<br>Made
      with a combination of module widgets (refer to Dependencies).
    </h2>
    <div class="demoWrap">
      <div class="liveDemo">
        <search-result-card/>
      </div>
    </div>
  </div>
</template>

<script>
import SearchResultCard from '../../../../components/search-result-card/SearchResultCard.vue';

export default {
  name: 'LiveDemo',
  components: {
    SearchResultCard,
  },
  data () {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    selectStyle (index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
.liveDemoComponent{
  padding-left:100px;
  width: 920px;
}
h1{
  font-size: $font-xl;
  line-height: $ln-height-xl;
  font-weight: 500;
  padding-bottom: $gap-lg;
  padding-top: 60px;
}
.liveDemo{
  padding-left: $gap-xl;
  padding-right:$gap-xl;
  display: block;
  padding-top: 80px;
  margin-top: 0px;
  padding-bottom: 80px;
  background-color: $blue-gray-50;
  border-top-left-radius: $gap-xxs;
  border-top-right-radius: $gap-xxs;
}
</style>
