
export default {
  getUser (state) {
    return state.user;
  },
  isAdminUser (state) {
    let isAdmin = false;
    const { roles } = state.user || {};

    if (roles && roles.indexOf('ROLE_ADMIN') >= 0) {
      isAdmin = true;
    }
    return isAdmin;
  },
  isPageManager (state) {
    let pageManager = false;
    const { roles } = state.user || {};
    if (roles && roles.indexOf('ROLE_PAGEMANAGER') >= 0) {
      pageManager = true;
    }
    return pageManager;
  },
  isCaseManager (state) {
    let caseManager = false;
    const { roles } = state.user || {};
    if (roles && roles.indexOf('ROLE_CASEMANAGER') >= 0) {
      caseManager = true;
    }
    return caseManager;
  },
  isKnowledgeUser (state) {
    let isKnowledge = false;
    const { roles } = state.user || {};

    if (roles && roles.indexOf('ROLE_KNOWLEDGE') >= 0) {
      isKnowledge = true;
    }
    return isKnowledge;
  },
  message: state => state.message,
  isProcessing: state => state.isProcessing,
  isFail: state => state.isFail,
  isSuccess: state => state.isSuccess,
  isADAuth: state => state.isADAuth,
};
