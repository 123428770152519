<template>
  <b-card
    tag="div"
    class="h-100"
  >
    <b-card-title
      class="toolKitTitle d-inline-flex align-items-center"
      title-tag="div"
    >
      <a
        :href="fixLink(path)"
        rel="noopener"
        :target="newTabOnClick ? '_blank' : '_top'"
        v-dompurify-html="title"
      />

      <font-awesome-icon
        class="icon"
        :icon="['fa', 'chevron-right']"
        size="sm"
      />

    </b-card-title>
    <b-card-text
      class="toolKitText d-flex flex-column"
      v-dompurify-html="content"
    >
    </b-card-text>
  </b-card>

</template>

<script>
import { sanitizeUrl } from '@braintree/sanitize-url';
export default {
  name: 'ToolkitsCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '#',
    },
    newTabOnClick: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fixLink(url) {
      return sanitizeUrl(url);
    }
  },
};
</script>

<style lang="scss">
.toolKitTitle {
  color: $blue-gray-500;
  font-size: $font-lg;
  font-weight: $weight-semi-bold;
  line-height: 22px;
  cursor: pointer;
  & a {
    color: $blue-gray-500;
    padding-right: 8px;
    &:hover {
      text-decoration: none;
      color: $blue-gray-500;
    }
  }
}

.toolKitText {
  font-size: $font-md;
  color: $blue-gray-500;
  & a {
    color: $blue-500;
    &:hover {
      text-decoration: none;
      color: $blue-500;
    }
  }
}

.card-body {
  padding: $gap-md !important;

  p {
    margin: 0;
  }
}
</style>
