<template>
  <div class="icrs-redirect-page">
    <div
      v-if="!useICRSConfig"
      class="icrs-redirect-illustration"
    >
      <div class="icrs-redirect-illustration__logo">
        <Logo />
      </div>
    </div>

    <b-modal
      ref="redirect-modal"
      :centered="true"
      :hide-header="true"
      :hide-footer="true"
      header-close-content="<i class='fa fa-times-circle' />"
    >
      <div
        class="right-section"
        style="background-color: #fff; justify-content: center"
      >
        <div
          v-if="useICRSConfig"
          class="loading-spinner-wrapper"
        >
          <LoadingSpinner
            :text="redirectMessage"
            size="lg"
          />
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import Logo from '../../assets/taiger-logo-white-horizontal.svg';
import LoadingSpinner from '../../components/LoadingSpinner.vue';

export default {
  components: {
    Logo,
    LoadingSpinner,
  },
  data () {
    return {
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
    };
  },
  methods: {
    showModal () {
      this.$refs['redirect-modal'].show();
    },
  },
  created () {
    this.redirectMessage = this.$route.query.redirectMessage;
    this.redirectUrl = this.$route.query.redirectUrl;
  },
  mounted () {
    this.showModal();
    window.open(this.redirectUrl, '_self');
  },
};
</script>

<style lang="scss" scoped>
.icrs-redirect-page {
  display: flex;
}

.icrs-redirect-illustration {
  position: relative;
  background-image: url(../../assets/login-illustration.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 440px;
  min-height: 100vh;

  &__logo {
    position: absolute;
    top: 48px;
    left: 48px;
  }
}

.icrs-redirect-form-wrapper {
  width: 360px;
  margin: 0 auto;
}

.right-section {
  flex: 1 1 0%;
  display: flex;
  align-items: center;
}
</style>
