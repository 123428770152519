<template>
  <div class="t-modal">
    <b-modal
      static
      :centered="centered"
      :hide-footer="noButtons"
      :id="id"
      :size="size"
      :title="title"
      :visible="visible"
      :no-close-on-backdrop="noCloseOnBackdrop"
      :no-close-on-esc="noCloseOnEsc"
      :no-stacking="noStacking"
      :no-fade="noFade"
      :scrollable="scrollable"
      header-close-content="<i class='fa fa-times-circle' />"
      @ok="okEvent"
      @cancel="cancelEvent"
      @close="closeEvent"
      @show="showEvent"
      @shown="shownEvent"
      @hide="hideEvent"
      @hidden="hiddenEvent"
    >
      <slot></slot>
      <template v-slot:modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <span
          class="d-flex align-items-center state"
          v-if="state"
        >
          <iconSuccess
            v-if="state === 'success'"
            class="state-icon"
          />
          <iconLoading
            v-else
            class="state-icon"
          />
          <span
            :class="['state-text', colorClass]"
            v-dompurify-html="localStateText"
          ></span>
        </span>
        <Button
          :disabled="buttonsDisabled || cancelDisabled"
          v-if="!hideCancel"
          class="mr-2 cancel"
          :variant="cancelVariant"
          @click="cancel()"
          v-dompurify-html="cancelTitle"
        ></Button>
        <Button
          :disabled="buttonsDisabled || okDisabled"
          class="save"
          :variant="okVariant"
          @click="ok()"
        >
          <template
            v-if="okSpinner"
            name="custom-icon"
          >
            <i class="fa fa-spin fa-spinner m-l-xs"></i>
          </template>
          <span v-dompurify-html="okTitle"></span>
        </Button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import Vue from 'vue';
import { BModal, VBModal } from 'bootstrap-vue';
import iconSuccess from '@/assets/tds-icons/check.svg';
import iconLoading from '@/assets/tds-icons/loading_blue.svg';

Vue.directive('b-modal', VBModal);
/**
 * Modal component.
 */
export default {
  name: 'Modal',
  status: 'release',
  release: '1.0.0',
  components: {
    BModal,
    iconSuccess,
    iconLoading,
  },
  data () {
    return {
      localVisible: false,
    };
  },
  watch: {
    visible () {
      this.localVisible = this.visible;
    },
  },
  props: {
    /**
     *
     * Set the size of the modal's width. 'sm', 'md' (default), 'lg', or 'xl'.
     * @values sm, md, lg, xl
     */
    size: {
      type: String,
      default: 'md',
    },
    /**
     *
     * Text string to place next to state icon.
     */
    stateText: {
      type: String,
    },
    /**
     *
     * Text string to place in the default footer OK button.
     */
    okTitle: {
      type: String,
      default: 'Save',
    },
    /**
     *
     * set to `true` to add spinner to OK button.
     */
    okSpinner: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Button color theme variant to apply to the default footer OK button.
     * @values primary, success, danger, warning, outline-primary, outline-success, outline-danger, outline-warning, text-primary
     */
    okVariant: {
      type: String,
      default: 'primary',
    },
    /**
     *
     * Text string to place in the default footer OK button.
     */
    cancelTitle: {
      type: String,
      default: 'Cancel',
    },
    /**
     *
     * Button color theme variant to apply to the default footer Cancel button.
     * @values primary, success, danger, warning, outline-primary, outline-success, outline-danger, outline-warning, text-primary
     */
    cancelVariant: {
      type: String,
      default: 'text-primary',
    },
    /**
     *
     * Disables the ability to close the modal by clicking the backdrop.
     */
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Disables the ability to close the modal by pressing ESC.
     */
    noCloseOnEsc: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Prevents other modals from stacking over this one.
     */
    noStacking: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * When set to 'true', disables the fade animation/transition on the component.
     */
    noFade: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Enables scrolling of the modal body.
     */
    scrollable: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Vertically centers the modal in the viewport.
     */
    centered: {
      type: Boolean,
      default: true,
    },
    /**
     *
     * Visible
     */
    visible: {
      type: Boolean,
    },
    /**
     *
     * Text to place in the label/legend of the form group.
     */
    id: {
      type: String,
    },
    /**
     *
     * Text content to place in the title.
     */
    title: {
      type: String,
    },
    /**
     *
     * When set to 'true'. Hide Save and Cancel buttons in footer side.
     */
    noButtons: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Used to set the state of modal.
     * @values loading, success
     */
    state: {
      type: String,
    },
    /**
     *
     * When set to 'true'. Disable all buttons.
     */
    buttonsDisabled: {
      type: Boolean,
    },
    /**
     *
     * When set to 'true'. Disable ok button.
     */
    okDisabled: {
      type: Boolean,
    },
    /**
     *
     * When set to 'true'. Disable cancel button.
     */
    cancelDisabled: {
      type: Boolean,
    },
    /**
     *
     * When set to 'true'. Disable cancel button.
     */
    hideCancel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localStateText () {
      if (!this.stateText) {
        return this.state && this.state === 'success' ? 'Done' : 'Processing';
      }
      return this.stateText;
    },
    stateIcon () {
      return this.state && this.state === 'success' ? this.iconSuccess : this.iconLoading;
    },
    colorClass () {
      return this.state && this.state === 'success' ? 'success' : 'loading';
    },
  },
  methods: {
    okEvent (bvModalEvt) {
      /**
       * When default OK button pressed, just before modal has hidden. Cancelable
       *
       * @event ok
       * @property {object} bvModalEvt - BvModalEvent object. Call bvModalEvt.preventDefault() to cancel hide
       * @type function
       */
      this.$emit('ok', bvModalEvt);
    },
    cancelEvent (bvModalEvt) {
      /**
       * When default CANCEL button pressed, just before modal has hidden. Cancelable
       *
       * @event cancel
       * @property {object} bvModalEvt - BvModalEvent object. Call bvModalEvt.preventDefault() to cancel hide
       * @type function
       */
      this.$emit('cancel', bvModalEvt);
    },
    closeEvent (bvModalEvt) {
      /**
       * When default header close button pressed, just before modal has hidden. Cancelable
       *
       * @event close
       * @property {object} bvModalEvt - BvModalEvent object. Call bvModalEvt.preventDefault() to cancel hide
       * @type function
       */
      this.$emit('close', bvModalEvt);
    },
    showEvent (bvModalEvt) {
      /**
       * Always emits just before modal is shown. Cancelable
       *
       * @event show
       * @property {object} bvModalEvt - BvModalEvent object. Call bvModalEvt.preventDefault() to cancel show
       * @type function
       */
      this.$emit('show', bvModalEvt);
    },
    shownEvent (bvModalEvt) {
      /**
       * Always emits when modal is shown
       *
       * @event shown
       * @property {object} bvModalEvt - BvModalEvent object
       * @type function
       */
      this.$emit('shown', bvModalEvt);
    },
    hideEvent (bvModalEvt) {
      /**
       * Always emits just before modal has hidden. Cancelable (as long as modal wasn't forcibly hidden)
       *
       * @event hide
       * @property {object} bvModalEvt - BvModalEvent object. Call bvModalEvt.preventDefault() to cancel hide
       * @type function
       */
      this.$emit('hide', bvModalEvt);
    },
    hiddenEvent (bvModalEvt) {
      /**
       * Always emits after modal is hidden
       *
       * @event hidden
       * @property {object} bvModalEvt - BvModalEvent object
       * @type function
       */
      this.$emit('hidden', bvModalEvt);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin label-lg-18 {
  font-size: $font-lg;
  font-weight: $weight_bold;
  line-height: $ln-height-lg;
  color: $blue-gray-500;
}
@mixin paragraph-14 {
  font-size: $font-md;
  font-weight: $weight-normal;
  line-height: $ln-height-md;
  color: $blue-gray-400;
}
.t-modal /deep/ {
  font-family: $icrs-font-family;
  .modal-content {
    border-radius: $radius_4;
  }
  .modal-header {
    @include label-lg-18;
    border-bottom: none;
    padding: $gap-lg $gap-lg $gap-md $gap-lg;
    .close {
      font-size: 1.2rem;
    }
  }
  .modal-body {
    @include paragraph-14;
    padding: $gap-md $gap-lg;

    .t-input{
      ::placeholder{
        color: $gray-500;
        font-weight: 500;
      }
    }
  }
  .modal-footer {
    border-top: none;
    padding: $gap-lg $gap-lg $gap-md $gap-lg;
    > * {
      margin: 0;
    }
    .state {
      flex-grow: 2;
      .state-text {
        font-style: $style_italic;
        font-weight: $weight_normal;
        font-size: $font-sm;
        line-height: $ln-height-xs;
        margin-left: $gap-xs;
      }
      .success {
        color: $green-500;
      }
      .loading {
        color: $blue-500;
      }
    }
  }
}
</style>

<docs>
  ```jsx
  <div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-custom-buttons-variant'">Modal with Custom Buttons Variant</Button>
      <Modal ok-variant="success" cancel-variant="danger" title="Modal Title" id="modal-custom-buttons-variant">Modal with Custom Buttons Variant</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'extra-large-modal'">Extra Large Modal</Button>
      <Modal size="xl" title="Modal Title" id="extra-large-modal">Hello From My Extra Large Modal!</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'large-modal'">Large Modal</Button>
      <Modal size="lg" title="Modal Title" id="large-modal">Hello From My Large Modal!</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'small-modal'">Small Modal</Button>
      <Modal size="lg" title="Modal Title" id="small-modal">Hello From My Small Modal!</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal'">Regular Modal</Button>
      <Modal title="Modal Title" id="modal">Hello From My Modal!</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-no-buttons'">Modal No Buttons</Button>
      <Modal title="Modal Title" noButtons id="modal-no-buttons">Modal No Buttons</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-loading'">Modal with Loading State</Button>
      <Modal title="Modal Title" state="loading" id="modal-loading">Modal with Loading State</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-ok-spinner'">Modal with OK button spinner</Button>
      <Modal ok-spinner title="Modal Title" id="modal-ok-spinner">Modal with OK button spinner</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-success'">Modal with Success State</Button>
      <Modal title="Modal Title" state="success" id="modal-success">Modal with Success State</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-loading-custom-state-text'">Modal Loading with Custom State Text</Button>
      <Modal title="Modal Title" state="loading" state-text="Processing ..." id="modal-loading-custom-state-text">Modal Loading with Custom State Text</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-success-custom-state-text'">Modal Success with Custom State Text</Button>
      <Modal title="Modal Title" state="success" state-text="Completed!" id="modal-success-custom-state-text">Modal Success with Custom State Text</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-loading-disabled-all-buttons'">Modal Loading with All Buttons Disabled</Button>
      <Modal title="Modal Title" state="loading" buttons-disabled id="modal-loading-disabled-all-buttons">Modal Loading with All Buttons Disabled</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-loading-disabled-ok-button'">Modal Loading with OK Button Disabled</Button>
      <Modal title="Modal Title" state="loading" ok-disabled id="modal-loading-disabled-ok-button">Modal Loading with OK Button Disabled</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-loading-disabled-cancel-button'">Modal Loading with Cancel Button Disabled</Button>
      <Modal title="Modal Title" state="loading" cancel-disabled id="modal-loading-disabled-cancel-button">Modal Loading with Cancel Button Disabled</Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-input-field'">Modal with Input Fields</Button>
      <Modal title="Modal Title" id="modal-input-field">
        <Input label="Input field" placeholder="Please type something here" />
        <Dropdown block label="Normal Dropdown" v-bind:options="[{ value: null, text: 'Please select an option' },{ value: 1, text: 'First Option' },{ value: 2, text: 'Second Option' }]"></Dropdown>
      </Modal>
    </div>
    <div class="mt-lg">
      <Button variant="primary" v-b-modal="'modal-text-and-input-field'">Modal with Text and Input Fields</Button>
      <Modal title="Modal Title" id="modal-text-and-input-field">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <Dropdown block label="Normal Dropdown" v-bind:options="[{ value: null, text: 'Please select an option' },{ value: 1, text: 'First Option' },{ value: 2, text: 'Second Option' }]"></Dropdown>
      </Modal>
    </div>
  </div>
  ```
</docs>
