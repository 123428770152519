/* eslint-disable no-param-reassign */
const platformRequestInterceptor = (config) => {
  // Insert X-Active-Organization
  const organizationId = localStorage.getItem('organization');
  config.headers['X-Active-Organization'] = organizationId;

  // Insert Authorization
  const accessToken = sessionStorage.getItem('access_token');

  config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
};

export default platformRequestInterceptor;
