import createState from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import filters from './modules/filters/index.module';
import newResults from './modules/new-results/index.module';

const searchService = {
  namespaced: true,
  state: createState(),
  getters,
  mutations,
  actions,
  modules: {
    filters,
    newResults,
  },
};

export default searchService;
