<template>
  <BadgeDropdown
    @blur="handleDropdownBlur"
    :text="`${filterGroup.displayName} (${groupSelectedCount})`"
  >
    <template slot="dropdown">
      <div
        @click.stop
        class="applied-filters-dropwdown"
      >
        <div class="applied-filters-dropdown__actions-wrapper">
          <button
            @click="handleClearAll"
            :disabled="!groupSelectedCount"
            class="btn-clear"
          >Clear all</button>
          <button
            @click="$emit('show-filters', filterGroup)"
            class="btn-show-filters"
          >Show filters</button>
        </div>

        <div class="applied-filters-dropdown__filters">
          <div
            class="filter-item-wrapper"
            v-for="item in filterGroup.items"
            :key="item.key"
          >
            <FilterItem
              :filterCountDisabled="filterCountDisabled"
              :group-name="filterGroup.name"
              :applied="selectedFilterKeys
                .includes(createFilterKey(
                  item.key,
                  filterGroup.name
                ))
              "
              id-prefix="applied-filters-group-item"
              @change="handleFilterChange"
              :item="item"
            />
          </div>
        </div>
      </div>
    </template>
  </BadgeDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BadgeDropdown from '@/components/badge-dropdown/BadgeDropdown.vue';
import searchConstants from '../../../constants/search-service-constants';
import FilterItem from '../../search-filter/FilterItem.vue';

export default {
  components: {
    FilterItem,
    BadgeDropdown,
  },

  props: {
    filterGroup: {
      type: Object,
    },
    filterCountDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('searchService/filters', [
      'selectedFilters',
    ]),
    selectedFilterKeys () {
      return this.selectedFilters.map(filter => (
        this.createFilterKey(filter.key, filter.groupName)
      ));
    },
    shouldDisableClearAll () {
      return false;
    },
    groupSelectedCount () {
      const keys = this.filterGroup.items
        .map(filter => this.createFilterKey(filter.key, this.filterGroup.name))
        .filter(key => this.selectedFilterKeys.includes(key));

      return keys.length;
    },
  },

  methods: {
    ...mapActions('searchService', [
      'setCurrentSearchMode',
      'executeSearchQuery',
      'triggerFilterSelect',
      'executeCategories',
    ]),
    ...mapActions('searchService/newResults',
      [
        'executeSearchNewQuery',
        'executeCategoriesNew',
      ]),
    ...mapActions('searchService/filters', [
      'setSelectedFilters',
    ]),
    handleClearAll () {
      const excludes = this.filterGroup.items;
      const excludedKeys = excludes.map(item => this.createFilterKey(item.key, this.filterGroup.name));
      const nextSelectedFilters = this.selectedFilters
        .filter((item) => {
          const key = this.createFilterKey(item.key, this.filterGroup.name);

          return !excludedKeys.includes(key);
        });
      this.setSelectedFilters(nextSelectedFilters);
      this.triggerFilterSelect({
        excludes,
      });
      this.executeSearchQuery({ filterGroupLevel: 2 });
      this.executeSearchNewQuery({ filterGroupLevel: 2 });
      // this.executeCategories();
      // this.executeCategoriesNew();
    },
    /**
     * @param {string} key
     * @param {string} groupName
     */
    createFilterKey (key, groupName) {
      return `${groupName}_${key.split(' ; ').length === 2 ? key.split(' ; ')[0] : key}`;
    },
    getNextFilters (uncheckedFilter, currentFilters) {
      const { key, groupName } = uncheckedFilter;
      const uncheckedFilterId = this.createFilterKey(key, groupName);
      const nextFilters = currentFilters
        .filter((filter) => {
          const filterId = this.createFilterKey(filter.key, filter.groupName);
          return uncheckedFilterId !== filterId;
        });
      return nextFilters;
    },
    handleDropdownBlur () {
      this.$emit('blur', {
        isEmpty: !this.groupSelectedCount,
        filterGroup: this.filterGroup,
      });
    },
    /**
     * @param {import('@typings/search').FilterToggleEvent} event
     */
    handleFilterChange (event) {
      let { selectedFilters } = this;
      if (event.checked) {
        const isExists = Boolean(
          this.selectedFilters.find(filter => (
            filter.key === event.filter.key
            && filter.groupName === event.filter.groupName
          )),
        );

        if (isExists) return;
        selectedFilters.push(event.filter);
      } else {
        selectedFilters = this.getNextFilters(event.filter, selectedFilters);
      }

      this.setSelectedFilters(selectedFilters);
      this.setCurrentSearchMode(searchConstants.SEARCH_MODE.FILTER);
      this.executeSearchQuery({ filterGroupLevel: 2 });
      this.executeSearchNewQuery({ filterGroupLevel: 2 });
      // this.executeCategories();
      // this.executeCategoriesNew();
    },
  },
};
</script>

<style lang="scss" scoped>
.applied-filters-dropdown {
  &/deep/ .filter-item {
    margin: 0 !important;
  }

  &__actions-wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $blue-gray-100;
  }

  &__filters {
    padding: $gap-xs;
  }
}

.filter-item-wrapper {
  margin-bottom: $gap-xs;

  &:last-child {
    margin-bottom: 0;
  }
}

.btn-clear,
.btn-show-filters {
  padding: $gap-xs;
  font-size: 12px;
  line-height: 16px;
  color: $blue-500;
  background: transparent;
  border: 0;

  &:disabled {
    opacity: 0.5;
  }
}
</style>
