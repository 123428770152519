import widgetCatalogue from '@/services/widget-catalogue.service';
import { toPascal } from '@/helpers/helpers';

const catalogRoutes = {
  name: 'uiCatalog',
  path: '/ui-catalog',
  component: () => import('./UiCatalogPage.vue'),
  children: [],
};

const mainCatalogRoutes = [
  {
    name: 'Overview',
    path: '/ui-catalog',
    component: () => import('./overview/Overview.vue'),
    attribute: 'main',
    type: 'overview',
  },
  {
    name: 'Module',
    path: '/ui-catalog/module',
    component: () => import('./module-widget/ModuleWidgetOverview.vue'),
    attribute: 'main',
    type: 'module',
  },
  {
    name: 'Page',
    path: '/ui-catalog/page',
    component: () => import('./page-widget/PageWidgetOverview.vue'),
    attribute: 'main',
    type: 'page',
  },
  {
    name: 'Component',
    path: '/ui-catalog/component',
    component: () => import('./component-widget/ComponentWidgetOverview.vue'),
    attribute: 'main',
    type: 'component',
  },
];


function extractWidgetRoutes (registryWidget, type) {
  const registryGroup = registryWidget[`${type}s`];

  if (!registryGroup) return [];

  const { components } = registryGroup;

  return components
    .filter(component => component.enabled)
    .map(({ tag, name }) => {
      const routeName = `${name} demo`;
      const routePath = `/ui-catalog/${type}/${tag}`;
      const fileName = toPascal(`${tag}-${type}`);
      let component = null;
      try {
        /**
         * Note: dynamic import does not accept string variable!
         */
        // eslint-disable-next-line import/no-dynamic-require, global-require
        require(`./${type}-widget/${tag}-${type}/${fileName}.vue`);
        component = () => import(`./${type}-widget/${tag}-${type}/${fileName}.vue`);
      } catch (e) {
        // component = () => import('./Placeholder.vue');
        return null;
      }
      return {
        name: routeName,
        path: routePath,
        component,
        type,
        label: name,
      };
    }).filter(Boolean);
}

const { registry } = widgetCatalogue;
const moduleRoutes = Object.freeze(extractWidgetRoutes(registry, 'module'));
const pageRoutes = Object.freeze(extractWidgetRoutes(registry, 'page'));
const componentRoutes = Object.freeze(extractWidgetRoutes(registry, 'component'));

function initCatalogRoutes () {
  catalogRoutes.children = catalogRoutes.children.concat(
    [...mainCatalogRoutes,
      ...moduleRoutes,
      ...pageRoutes,
      ...componentRoutes],
  );
  Object.freeze(catalogRoutes.children);
}

initCatalogRoutes();

export {
  catalogRoutes,
  mainCatalogRoutes,
  moduleRoutes,
  pageRoutes,
  componentRoutes,
};
