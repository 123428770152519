
<template>
  <div id="preview-content">
    <add-tag
      :key="reRenderModal"
      :class-options="allClass"
      :individual-options="individualClass"
      :id="tagsModalId"
      :title="tagsModalTitle"
      :selected-tag="selectedTag"
      :tags-value="tagsValue"
      @submit="handleSubmitTag"
      @closed="handleCloseModal"
      size="md"
    />
    <add-metadata
      :key="reRender + 1"
      id="add-metadata"
      :title="`${selectedMetadata.key ? 'Edit' : 'Add new'} metadata`"
      :selected-metadata="selectedMetadata"
      :all-metadata="allMetadata"
      @submit="handleSubmitMetadata"
      @closed="handleCloseModal"
      size="md"
    />
    <delete-modal
      id="delete-modal"
      :title="`Delete ${deleteModalType}`"
      :type="deleteModalType"
      :deleted-item="deleteModalItem"
      @delete="onConfirmDelete"
    />
    <b-nav
      id="doc-nav-header"
      pills
      card-header
      slot="header"
      v-b-scrollspy:nav-scroller
    >
      <b-nav-item
        v-for="tab in tabs"
        :key="tab.id"
        :href="fixLink('#' + tab.id)"
        @click="scrollIntoView"
      >
        {{ tab.label }}
      </b-nav-item>
    </b-nav>
    <div class="content-container">
      <div
        id="nav-scroller"
        ref="content"
        class="content"
        :style="contentStyle"
      >
        <div
          v-if="showTabContent.details"
          id="details"
          style="padding-top: 30px"
        >
          <span class="tab-title">Details</span>
          <details-tab
            @save="handleSaveDetails"
            :disableForm="disableForm"
            v-model="detailsModel"
            :have-write-access="haveWriteAccess"
          />
        </div>
        <div
          v-if="showTabContent.metadata"
          id="metadata"
          v-bind:class="[!showTabContent.details && '--top-categories']"
        >
          <metadata-tab
            @add="handleAddMetadata"
            @edit="handleEditMetadata"
            @delete="(value) => handleDeleteConfirmation({ item: value, type: constants.DELETE_MODAL_TYPE.METADATA })"
            :is-editable="isEditable"
            v-model="metadataModel"
            :disableForm="disableForm"
            :have-write-access="haveWriteAccess"
          />
        </div>
        <div
          v-if="showTabContent.tags"
          id="tags"
          style="padding-top: 5px"
          v-bind:class="[!showTabContent.metadata && '--top-categories']"
        >
          <tags-tab
            @add="handleAddTags"
            @edit="handleEditTag"
            @delete="(value) => handleDeleteConfirmation({ item: value, type: constants.DELETE_MODAL_TYPE.TAG })"
            :key="reRender"
            :is-editable="isEditable"
            v-model="tagsValue"
            :disableForm="disableForm"
            :have-write-access="haveWriteAccess"
          />
        </div>
        <div
          v-if="showTabContent.promotedContent"
          id="promotedContent"
          style="padding-top: 20px"
          v-bind:class="[!showTabContent.tags && '--top-categories']"
        >
          <promoted-content-tab
            @save="handleSaveDetails"
            :disableForm="disableForm"
            :value="value"
            :have-write-access="haveWriteAccess"
          />
          <div
            v-if="haveWriteAccess"
            class="py-2"
          >
            <b-btn
              @click="handleDeleteConfirmation({ type: constants.DELETE_MODAL_TYPE.DOCUMENT })"
              class="confirm-btn ml-2 pl-4 pr-4"
              variant="outline-danger"
            >
              Delete this Document
            </b-btn>
          </div>
        </div>
      </div>
      <div id="nav-scroller-shadow" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import constants from '../../../constants/doc-manager-service-constants';
import {
  DetailsTab,
  PromotedContentTab,
  MetadataTab,
  TagsTab,
  AddTag,
  AddMetadata,
  DeleteModal,
} from './index.module';
import { sanitizeUrl } from '@braintree/sanitize-url';
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    formValue: {
      type: Object,
      default: () => ({}),
    },
    haveWriteAccess: {
      type: Boolean,
      default: false,
    },
    activatedTab: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DetailsTab,
    PromotedContentTab,
    MetadataTab,
    TagsTab,
    AddTag,
    AddMetadata,
    DeleteModal,
  },
  data () {
    const { TEXT_INFO: { PREVIEW_CONTENT: pageLabel = '' } = {} } = constants;
    const { tags } = this.value;
    return {
      constants,
      label: pageLabel,
      tagsModalId: '',
      tagsModalTitle: '',
      tagsValue: { tags },
      selectedMetadata: {},
      selectedTag: {},
      reRender: 0,
      deleteModalType: '',
      deleteModalItem: {},
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      blacklistedField: 'docManagerService/blacklistedField',
      allClass: 'docManagerService/allClass',
      individualClass: 'docManagerService/individualClass',
    }),

    reRenderModal () {
      return this.allClass.length + this.reRender;
    },
    disableForm () {
      const form = {};

      this.blacklistedField.forEach((item, index) => {
        const uppercaseItem = item.toUpperCase();
        form[uppercaseItem] = true;
      });

      return form;
    },
    allMetadata: {
      get () {
        const { metadata = {} } = this.value;
        const allMetadata = Object.keys(metadata);
        return allMetadata;
      },
    },
    detailsModel: {
      get () {
        const {
          title,
          id,
          path,
          createdDate,
        } = this.value;
        return {
          title, id, path, createdDate,
        };
      },
      set (value) {
        this.$emit('input', value);
      },
    },
    metadataModel: {
      get () {
        const {
          metadata,
        } = this.value;
        return metadata;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
    tagsModel: {
      get () {
        const {
          tags,
        } = this.value;
        return {
          tags,
        };
      },
      set (value) {
        this.$emit('input', value);
      },
    },
    contentStyle () {
      return {
        // 'padding-top': '60px',
      };
    },
    showTabContent () {
      const showTabMap = {};
      // eslint-disable-next-line no-return-assign
      this.tabs.forEach(tab => showTabMap[tab.id] = !tab.disabled);
      return showTabMap;
    },
  },
  methods: {
    ...mapActions({
      getAllClasses: 'docManagerService/getAllClasses',
      getIndividualClass: 'docManagerService/getIndividualClass',
      execUpdateDoc: 'docManagerService/execUpdateDoc',
      execSearchDoc: 'docManagerService/execSearchDoc',
      execDeleteDoc: 'docManagerService/execDeleteDoc',
    }),
    fixLink(url) {
      return sanitizeUrl(url);
    },
    /**
     * @param {Object} parameters - document body value
     */
    async updateDocument (parameters) {
      const newDoc = {
        id: this.value.id,
        ...parameters,
      };
      await this.execUpdateDoc(newDoc);
      return this.execSearchDoc(newDoc);
    },
    /**
     * @param {Object} parameters - data object with value
     */
    setModalState (parameters = {}) {
      const arr = Object.entries(parameters);
      arr.map(([key, value]) => {
        this[key] = value;
        return 0;
      });
    },
    async onConfirmDelete () {
      const { DELETE_MODAL_TYPE: { TAG, DOCUMENT, METADATA } } = constants;
      const resetData = () => {
        this.$bvModal.hide('delete-modal');
        this.deleteModalType = '';
        this.deleteModalItem = {};
        this.reRender += 1;
      };

      if (this.deleteModalType === DOCUMENT) {
        await this.handleDeleteDocument();
        resetData();
      }
      if (this.deleteModalType === TAG) {
        await this.handleDeleteTag(this.deleteModalItem);
        resetData();
      }
      if (this.deleteModalType === METADATA) {
        await this.handleDeleteMetadata(this.deleteModalItem);
        resetData();
      }
    },
    handleResize () {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    async handleSaveDetails (value, setIsEdited) {
      await this.updateDocument(value);
      setIsEdited(false);
    },
    handleAddMetadata () {
      this.selectedMetadata = {};
      this.reRender += 1;

      this.$nextTick(() => {
        this.$bvModal.show('add-metadata');
      });
    },
    handleEditMetadata (item) {
      this.selectedMetadata = item;
      this.reRender += 1;

      this.$nextTick(() => {
        this.$bvModal.show('add-metadata');
      });
    },
    handleDeleteMetadata ({ key }) {
      const { metadata } = this.value;

      delete metadata[key];

      this.updateDocument({ metadata });
    },
    /**
     * @param {String} id - modal id
     * @param {String} title - modal title
     */
    handleShowTagsModal ({ id = '', title = '' }) {
      this.setModalState({
        tagsModalId: id,
        tagsModalTitle: title,
      });
      this.$nextTick(() => {
        this.$bvModal.show(id);
      });
    },
    handleAddTags () {
      this.selectedTag = {};

      this.handleShowTagsModal({
        id: 'add-tags',
        title: 'Add new tag',
      });
    },
    handleEditTag ({ key, value }) {
      this.selectedTag = {
        key,
        value,
      };

      this.$nextTick(() => {
        this.handleShowTagsModal({
          id: 'edit-tags',
          title: 'Edit Tag',
        });
      });
    },
    async handleSubmitTag ({
      key, value, mode, isEditMode,
    }) {
      const { tags: { topic = [] } = {}, tags } = this.value;

      const transformValue = () => {
        if (!topic.includes(key)) {
          topic.push(key);
          tags.topic = topic;
        }
        if (!isEditMode) {
          if (_.has(tags, key)) {
            const newValue = tags[key].concat(value);
            const uniqueValue = new Set(newValue);

            return {
              [key]: [...uniqueValue],
            };
          }
        }
        return {
          [key]: value,
        };
      };

      const newTag = {
        tags: {
          ...tags,
          ...transformValue(),
        },
      };

      try {
        await this.updateDocument(newTag);
        this.tagsValue = newTag;
        this.$emit('input', newTag);
      } finally {
        this.$bvModal.hide(this.tagsModalId);
        this.reRender += 1;
      }
    },
    async handleDeleteTag ({ key, value }) {
      const { tags } = this.value;

      tags.topic = tags.topic.filter(item => item !== key);
      delete tags[key];

      try {
        await this.updateDocument({ tags });
        this.tagsValue = { tags };
        this.$emit('input', { tags });
      } catch (err) {
        console.error('Failed to update document');
      }
    },
    async handleSubmitMetadata ({ key, value }) {
      const { key: oldKey = '' } = this.selectedMetadata;

      delete this.value.metadata[oldKey];

      const newMetadata = {
        metadata: {
          ...this.value.metadata,
          [key]: value,
        },
      };

      try {
        await this.updateDocument(newMetadata);
      } finally {
        this.$bvModal.hide('add-metadata');
        this.selectedMetadata = {};
      }
    },
    async handleDeleteDocument () {
      const { id } = this.value;

      try {
        await this.execDeleteDoc(id);
        this.$emit('click-delete');
      } catch (err) {
        console.error('Failed to delete document');
      }
    },
    handleDeleteConfirmation ({ type = '', item = '' }) {
      this.deleteModalType = type;
      this.deleteModalItem = item;
      this.$nextTick(() => {
        this.$bvModal.show('delete-modal');
      });
    },
    handleCloseModal () {
      this.selectedTag = {};
    },
    /**
     * @param {Function} evt - event emmiter
     */
    scrollIntoView (evt) {
      evt.preventDefault();
      const href = evt.target.getAttribute('href');
      this.scrollToSection(href);
    },
    scrollToSection (tab) {
      const el = tab ? document.querySelector(tab) : null;
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop;
      }
    },
  },
  async mounted () {
    await this.getAllClasses();
    this.scrollToSection(`#${this.activatedTab}`);
    const allClassKey = Object.keys(this.allClass || {});

    await this.getIndividualClass(allClassKey);

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" >
#doc-manager {
  #preview-content {
    caption {
      padding-top: 0px !important;
    }

    .actions-mw {
      width: 150px;
    }

    .card-header-pills {
      margin-right: unset;
      margin-left: unset;
    }

    .headline-text {
      font-style: normal;
      font-weight: $weight-semi-bold;
      font-size: 18px;
      line-height: 22px;
    }
    .label-container {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .action-btn {
      font-size: 12px;
    }
    .action-text {
      margin: unset !important;
      font-size: 14px;
      color: $blue-gray-600;
    }
    .action-text:hover {
      color: $blue-500;
    }

    #nav-scroller.content {
      max-height: 80vh;
      padding: 0px 30px;
      background: #fcfdff;
      position: relative;
      overflow-y: scroll;
      padding-bottom: 50vh;
    }

    #nav-scroller-shadow {
      height: 80vh;
      box-shadow: inset 0px 0px 14px -4px rgba(153, 155, 168, 0.2);
      width: 100%;
      position: absolute;
      bottom: 0;
      pointer-events: none;
    }

    .tab-title {
      color: $blue-gray-500;
      font-weight: $weight-bold;
      font-size: 20px;
    }

    .content-container {
      input {
        font-size: 14px !important;
        line-height: 30px;
        height: 42px;
      }
    }

    #doc-nav-header {
      position: relative;
      width: 100%;
      z-index: 10;
      transition: 0.2s;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        left: 10px;
      }

      .nav-link {
        padding: 5px;
        margin: 0px 10px;
        color: #687587;
      }
      .nav-link.active {
        color: $blue-500 !important;
        border-radius: 0px !important;
        border-bottom: 3px solid $blue-500;
        background-color: transparent !important;
      }
    }
  }
}
</style>
