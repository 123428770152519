<template>
  <b-modal
    :id="modalId"
    size="lg"
    :centered="true"
    :hide-footer="true"
    header-close-content="<i class='fa fa-times-circle' />"
    :title="`Summary Sheet: ${activeDocTitle}`"
    @hide="closeSummarySheet"
    @hidden="handleHiddenEvent"
  >
    <div id="previewContentElement">
      <p v-if="useIntranetConfig && activeSummarySheet.uuid == ''">No UUID retrieved for current case.</p>
      <p v-if="useIntranetConfig && activeSummarySheet.uuid != ''">UUID: {{activeSummarySheet.uuid}}</p>

      <u v-if="activeSummarySheet.column1.length != 0">Case Name and Party Names</u>
      <ul v-if="activeSummarySheet.column1.length != 0">
        <li
          v-for="(content, index) in activeSummarySheet.column1"
          :key="'column1_' + index"
        >{{content}}</li>
      </ul>
      <u v-if="activeSummarySheet.column2.length != 0">Case Details</u>
      <ul v-if="activeSummarySheet.column2.length != 0">
        <li
          v-for="(content, index) in activeSummarySheet.column2"
          :key="'column2_' + index"
        >{{content}}</li>
      </ul>
      <u v-if="activeSummarySheet.column3.length != 0">Plaintiff's Profile</u>
      <ul v-if="activeSummarySheet.column3.length != 0">
        <li
          v-for="(content, index) in activeSummarySheet.column3"
          :key="'column3_' + index"
        >{{content}}</li>
      </ul>
      <u v-if="activeSummarySheet.column4.length != 0">Type of Injury/Description</u>
      <ul v-if="activeSummarySheet.column4.length != 0">
        <li
          v-for="(content, index) in activeSummarySheet.column4"
          :key="'column4_' + index"
        >{{content}}</li>
      </ul>
      <div
        v-for="(content, index) in activeSummarySheet.column5"
        :key="'column5_' + index"
      >
        <u>{{index}}</u>
        <ul>
          <li
            v-for="(subContent, subIndex) in content"
            :key="'column5_sub_' + subIndex"
          >{{subContent}}</li>
        </ul>
      </div>
      <u v-if="activeSummarySheet.tier1.length != 0">Amount Awarded at First Instance</u>
      <ul v-if="activeSummarySheet.tier1.length != 0">
        <li
          v-for="(content, index) in activeSummarySheet.tier1"
          :key="'tier1_' + index"
        >{{content}}</li>
      </ul>
      <u v-if="activeSummarySheet.tier2.length != 0">Amount Awarded on Appeal</u>
      <ul v-if="activeSummarySheet.tier2.length != 0">
        <li
          v-for="(content, index) in activeSummarySheet.tier2"
          :key="'tier2_' + index"
        >{{content}}</li>
      </ul>
      <u v-if="activeSummarySheet.tier3.length != 0">Amount Awarded on Further Appeal</u>
      <ul v-if="activeSummarySheet.tier3.length != 0">
        <li
          v-for="(content, index) in activeSummarySheet.tier3"
          :key="'tier3_' + index"
        >{{content}}</li>
      </ul>
      <u v-if="activeSummarySheet.tier4.length != 0">Amount Awarded on Further Appeal</u>
      <ul v-if="activeSummarySheet.tier4.length != 0">
        <li
          v-for="(content, index) in activeSummarySheet.tier4"
          :key="'tier4_' + index"
        >{{content}}</li>
      </ul>
    </div>
    <button
      type="button"
      class="btn btn-primary"
      @click="copySummarySheet"
    >Copy Summary Sheet</button>
    <b-alert
      class="has-icon alert-large"
      style="margin-top: 10px;"
      :show="dismissCountDown"
      dismissible
      variant="success"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      <div style="display: flex">
        <span class="has-icon"><i class="fa fa-check-circle"></i></span>
        <div class="alert-content">
          <h4>Success</h4>
          <p>Copied summary sheet to clipboard!</p>
        </div>
      </div>
    </b-alert>
  </b-modal>
</template>

<script>
import ICRSService from '@/services/icrs-service';
import { mapGetters } from 'vuex';
import copy from 'copy-to-clipboard';

export default {
  name: 'ICRSSummarySheet',
  props: {
    activeDocTitle: {
      type: String,
      default: null,
    },
    activeDocId: {
      type: String,
      default: null,
    },
    activeDoc: {
      type: Object,
      default: () => {},
    },
    modalId: {
      type: String,
      default: 'show-summary-sheet-modal',
    },
    viewType: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      activeSummarySheet: {
        column1: [],
        column2: [],
        column3: [],
        column4: [],
        column5: {},
        column6: [],

        tier1: [],
        tier2: [],
        tier3: [],
        tier4: [],
      },
      copyContent: '',
      dismissCountDown: 0,
      useIntranetConfig: process.env.VUE_APP_USE_INTRANET_CONFIG === 'true',
    };
  },
  watch: {
    activeDocId: {
      immediate: true,
      handler (value) {
        // if (value) {
        //   let data;
        //   if (this.icrsPlotData.nonGlobal.preview && this.viewType === 'overView') {
        //     const tempPreview = this.icrsPlotData.nonGlobal.preview.find((m) => {
        //       const [key] = Object.keys(m);
        //       if (key === value) {
        //         return true;
        //       }
        //       return false;
        //     });
        //     if (tempPreview) {
        //       let [json] = Object.values(tempPreview);
        //       json = { preview: json };
        //       if (json.uuid != null) {
        //         this.activeSummarySheet.uuid = json.uuid;
        //       } else {
        //         this.activeSummarySheet.uuid = '';
        //       }
        //       data = json.preview;
        //     }
        //   } else if (this.viewType === 'listView') {
        //     data = this.activeDoc.metadata.preview;
        //   }
        //   let previewKeys = []; // dummy initialisation
        //   if (data.previews != null) {
        //     previewKeys = Object.keys(data.previews);
        //   }
        //   if (previewKeys.includes('column1')) {
        //     this.activeSummarySheet.column1 = data.previews.column1;
        //   }
        //   if (previewKeys.includes('column2')) {
        //     this.activeSummarySheet.column2 = data.previews.column2;
        //   }
        //   if (previewKeys.includes('column3')) {
        //     this.activeSummarySheet.column3 = data.previews.column3;
        //   }
        //   if (previewKeys.includes('column4')) {
        //     this.activeSummarySheet.column4 = data.previews.column4;
        //   }
        //   if (previewKeys.includes('column5')) {
        //     Object.keys(data.previews.column5).map(rawKey => this.redefineColumn5(rawKey, data));
        //   }
        //   if (previewKeys.includes('column6')) {
        //     this.activeSummarySheet.column6 = data.previews.column6;
        //     const column6Tiers = Object.keys(this.activeSummarySheet.column6);

        //     if (column6Tiers.includes('tier 1')) {
        //       this.activeSummarySheet.tier1 = this.activeSummarySheet.column6['tier 1'];
        //     }
        //     if (column6Tiers.includes('tier 2')) {
        //       this.activeSummarySheet.tier2 = this.activeSummarySheet.column6['tier 2'];
        //     }
        //     if (column6Tiers.includes('tier 3')) {
        //       this.activeSummarySheet.tier3 = this.activeSummarySheet.column6['tier 3'];
        //     }
        //     if (column6Tiers.includes('tier 4')) {
        //       this.activeSummarySheet.tier4 = this.activeSummarySheet.column6['tier 4'];
        //     }
        //   }
        //   this.$bvModal.show(this.modalId);
        // }
        if (value !== undefined && value !== null && value !== '') {
          const params = { id: value };
          ICRSService.execGetICRSPreview(params).then((json) => {
            if (json.uuid != null) {
              this.activeSummarySheet.uuid = json.uuid;
            } else {
              this.activeSummarySheet.uuid = '';
            }

            const data = JSON.parse(json.preview);
            let previewKeys = []; // dummy initialisation
            if (data.previews != null) {
              previewKeys = Object.keys(data.previews);
            }
            if (previewKeys.includes('column1')) {
              this.activeSummarySheet.column1 = data.previews.column1;
            }
            if (previewKeys.includes('column2')) {
              this.activeSummarySheet.column2 = data.previews.column2;
            }
            if (previewKeys.includes('column3')) {
              this.activeSummarySheet.column3 = data.previews.column3;
            }
            if (previewKeys.includes('column4')) {
              this.activeSummarySheet.column4 = data.previews.column4;
            }
            if (previewKeys.includes('column5')) {
              Object.keys(data.previews.column5).map(rawKey => this.redefineColumn5(rawKey, data));
            }
            if (previewKeys.includes('column6')) {
              this.activeSummarySheet.column6 = data.previews.column6;
              const column6Tiers = Object.keys(this.activeSummarySheet.column6);

              if (column6Tiers.includes('tier 1')) {
                this.activeSummarySheet.tier1 = this.activeSummarySheet.column6['tier 1'];
              }
              if (column6Tiers.includes('tier 2')) {
                this.activeSummarySheet.tier2 = this.activeSummarySheet.column6['tier 2'];
              }
              if (column6Tiers.includes('tier 3')) {
                this.activeSummarySheet.tier3 = this.activeSummarySheet.column6['tier 3'];
              }
              if (column6Tiers.includes('tier 4')) {
                this.activeSummarySheet.tier4 = this.activeSummarySheet.column6['tier 4'];
              }
            }
            this.$bvModal.show(this.modalId);
          });
        }
      },
    },
  },
  methods: {
    closeSummarySheet () {
      // reset summary data
      this.activeSummarySheet = {
        column1: [],
        column2: [],
        column3: [],
        column4: [],
        column5: {},
        column6: [],

        tier1: [],
        tier2: [],
        tier3: [],
        tier4: [],
      };
      this.$bvModal.hide(this.modalId);
    },
    handleHiddenEvent () {
      this.$emit('reset-active-doc');
      this.dismissCountDown = 0;
    },
    copySummarySheetMobile () {
      const previewContentElement = document.getElementById('previewContentElement');
      const textarea = document.createElement('textarea');
      document.body.appendChild(textarea);

      let value = previewContentElement.innerHTML;

      value = value
        .replace(new RegExp('(</.*?>)', 'gi'), '\n')
        .replace('<!----><!---->', '')
        .replace('<!----><!----><!----><!----><!----><!---->', '');

      value = value.replace(/\n+/g, '\n');

      value = value
        .replaceAll('<u>', '\n- ')
        .replaceAll('<div>', '')
        .replaceAll('<ul>', '')
        .replaceAll('<li>', '• ');

      textarea.value = value.trim();

      navigator.clipboard.writeText(textarea.value);
      document.body.removeChild(textarea);
    },
    copySummarySheet () {
      const previewContentElement = document.getElementById('previewContentElement');
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        this.copySummarySheetMobile();
      } else {
        copy(previewContentElement.innerHTML, {
          format: 'text/html',
        });
      }
      // For alert
      this.dismissCountDown = 10;
    },
    // copySummarySheet () {
    //   if (document.documentMode) {
    //     // This set of codes works on IE, Chrome and Firefox.
    //     // For Edge, the formatting like underlines and bullet points
    //     // are gone with this set of codes.
    //     const range = document.createRange();
    //     range.selectNode(document.getElementById('previewContentElement'));
    //     window.getSelection().removeAllRanges(); // clear current selection
    //     window.getSelection().addRange(range); // to select text
    //     document.execCommand('copy');
    //     window.getSelection().removeAllRanges();// to deselect
    //   } else {
    //     // This set of codes works on Edge, Chrome and Firefox.
    //     // For IE, not sure why it doesn't work. Probably because of the listeners involved.
    //     this.copyContent = document.getElementById('previewContentElement').innerHTML;
    //     navigator.clipboard.writeText(this.copyContent);
    //   }
    //   // For alert
    //   this.dismissCountDown = 10;
    // },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    redefineColumn5 (rawKey, data) {
      const newKey = this.customCapitalise(rawKey);
      this.activeSummarySheet.column5[newKey] = data.previews.column5[rawKey];
    },
    customCapitalise (phrase) {
      const words = phrase.split(' ');
      let newPhrase = '';

      function formNewPhrase (word) {
        if (word !== 'of') {
          newPhrase += `${word.replace(word.charAt(0), word.charAt(0).toUpperCase())} `;
        } else {
          newPhrase += `${word} `;
        }
      }
      words.map(word => formNewPhrase(word));

      newPhrase = newPhrase.trim();
      return newPhrase;
    },
  },
  computed: {
    ...mapGetters('searchService', [
      'icrsPlotData',
    ]),
  },
};
</script>
