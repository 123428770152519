<template>
  <b-modal
    :id="modalId"
    size="xl"
    :centered="true"
    :hide-footer="true"
    header-close-content="<i class='fa fa-times-circle' />"
    title="Search Suggestions"
    @hide="closeModal(modalId)"
  >
    <div
      v-if="didYouMeanTerms.size > 0"
      class="card suggestionsCard"
    >
      <div
        class="card-header"
        @click="toggleDidYouMean"
      >
        <i
          v-if="!showDidYouMean"
          class="fa fa-chevron-down"
        ></i>
        <i
          v-if="showDidYouMean"
          class="fa fa-chevron-up"
        ></i>
        Did You Mean?
      </div>
      <b-collapse
        :visible="showDidYouMean"
        :id="didYouMeanId"
      >
        <div class="card-body">
          <ol>
            <li
              v-for="term in didYouMeanTerms"
              :key="`${currentQuery}_${term}`"
              class="search-suggestion"
              @click="performSuggestedSearch(term)"
            ><a>{{term}}</a></li>
          </ol>
        </div>
      </b-collapse>
    </div>
    <div
      class="card suggestionsCard"
    >
      <div
        class="card-header"
        @click="toggleBodyProximity"
      >
        <i
          v-if="!showBodyProximity"
          class="fa fa-chevron-down"
        ></i>
        <i
          v-if="showBodyProximity"
          class="fa fa-chevron-up"
        ></i>
        {{bodyProximityLength}} Body Proximity Terms
      </div>
      <b-collapse
        :visible="showBodyProximity && bodyProximityLength > 0"
        :id="bodyProximityId"
      >
        <div class="card-body">
          <div
            v-for="(bodyRows,bodyName) in bodyProximityDecks"
            :key="`${currentQuery}_${bodyName}`"
          >
            <span class="sub-section">{{bodyName}}</span>
            <div
              class="row mobile"
              v-for="(bodyRow, bodyRowIndex) in bodyRows"
              :key="`${currentQuery}_${bodyName}_body_group_${bodyRowIndex}`"
              deck
            >
              <div
                class="col-md-3 one-line"
                v-for="(bodyCol, bodyColIndex) in bodyRow"
                :key="`${currentQuery}_${bodyName}_body_group_${bodyRowIndex}_${bodyColIndex}`"
                border-variant="light"
              >
                <ol :start="bodyRowIndex*boxesPerRow*listItemsPerCol + bodyColIndex*listItemsPerCol + 1">
                  <li
                    v-for="term in bodyCol"
                    :key="`${currentQuery}_${bodyName}_${term}`"
                    class="search-suggestion"
                    @click="performSuggestedSearch(term)"
                  ><a>{{term}}</a></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div
      class="card suggestionsCard"
    >
      <div
        class="card-header"
        @click="toggleRelatedMedical"
      >
        <i
          v-if="!showRelatedMedical"
          class="fa fa-chevron-down"
        ></i>
        <i
          v-if="showRelatedMedical"
          class="fa fa-chevron-up"
        ></i>
        {{relatedMedicalLength}} Related Medical Terms
      </div>
      <b-collapse
        :visible="showRelatedMedical && relatedMedicalLength > 0"
        :id="relatedMedicalId"
      >
        <div class="card-body">
          <div
            v-for="(relatedRows,relatedName) in relatedMedicalDecks"
            :key="`${currentQuery}_${relatedName}`"
          >
            <span class="sub-section">{{relatedName}}</span>
            <div
              class="row mobile"
              v-for="(relatedRow, relatedRowIndex) in relatedRows"
              :key="`${currentQuery}_${relatedName}_related_group_${relatedRowIndex}`"
              deck
            >
              <div
                class="col-md-3 one-line"
                v-for="(relatedCol, relatedColIndex) in relatedRow"
                :key="`${currentQuery}_${relatedName}_related_group_${relatedRowIndex}_${relatedColIndex}`"
                border-variant="light"
              >
                <ol :start="relatedRowIndex*boxesPerRow*listItemsPerCol + relatedColIndex*listItemsPerCol + 1">
                  <li
                    v-for="term in relatedCol"
                    :key="`${currentQuery}_${relatedName}_${term}`"
                    class="search-suggestion"
                    @click="performSuggestedSearch(term)"
                  ><a>{{term}}</a></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ICRSSearchSuggestions',
  props: {
    modalId: {
      type: String,
      default: 'searchSuggestionsExercise',
    },
    parentShowDidYouMean: {
      type: Boolean,
      default: false,
    },
    parentShowBodyProximity: {
      type: Boolean,
      default: false,
    },
    parentShowRelatedMedical: {
      type: Boolean,
      default: false,
    },
    bodyPartsResponse: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      showDidYouMean: false,
      didYouMeanTerms: [],
      showBodyProximity: false,
      bodyProximityTerms: {},
      bodyProximityLength: 0,
      bodyProximityDecks: {},
      showRelatedMedical: false,
      relatedMedicalTerms: {},
      relatedMedicalLength: 0,
      relatedMedicalDecks: {},
      boxesPerRow: 4,
      listItemsPerCol: 10,
    };
  },
  computed: {
    ...mapGetters('searchService', [
      'corrections',
      'suggestions',
      'currentQuery',
    ]),
    didYouMeanId () {
      return `${this.modalId}_didYouMean`;
    },
    bodyProximityId () {
      return `${this.modalId}_bodyProximity`;
    },
    relatedMedicalId () {
      return `${this.modalId}_relatedMedical`;
    },
  },
  methods: {
    ...mapActions({
      triggerSearchQuery: 'searchService/triggerSearchQuery',
      resetNewSearchResults: 'searchService/newResults/resetNewSearchResults',
    }),
    ...mapActions('icrs', [
      'setGuidedSearch',
      'setDropdownGuidedTag',
      'setActiveGuidedPrefix',
      'setSelectedLevel1',
      'setSelectedLevel2',
      'setSelectedLevel3',
      'setLevel2CategoriesForSelectedLevel1',
      'setLevel3CategoriesForSelectedLevel2',
    ]),
    toggleDidYouMean () {
      this.showDidYouMean = !this.showDidYouMean;
      this.$root.$emit(`bv::collapse::${this.showDidYouMean}`, this.didYouMeanId);
    },
    toggleBodyProximity () {
      this.showBodyProximity = !this.showBodyProximity;
      this.$root.$emit(`bv::collapse::${this.showBodyProximity}`, this.bodyProximityId);
    },
    toggleRelatedMedical () {
      this.showRelatedMedical = !this.showRelatedMedical;
      this.$root.$emit(`bv::collapse::${this.showRelatedMedical}`, this.relatedMedicalId);
    },
    performSuggestedSearch (suggestedTerm) {
      // Clear existing nature of injury stuff
      this.setGuidedSearch(false);
      this.setDropdownGuidedTag('');
      this.setActiveGuidedPrefix(0);
      this.setSelectedLevel1({ code: 'All' });
      this.setSelectedLevel2({ code: 'All' });
      this.setSelectedLevel3({ code: 'All' });
      this.setLevel2CategoriesForSelectedLevel1([{ code: 'All' }]);
      this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);

      const eventMsg = { resetSources: true, query: `"${suggestedTerm}"` };
      this.triggerSearchQuery(eventMsg);
      this.resetNewSearchResults();
      this.closeModal(this.modalId);
    },
    closeModal (modalId) {
      this.showDidYouMean = false;
      this.showBodyProximity = false;
      this.showRelatedMedical = false;
      this.$bvModal.hide(modalId);
      this.$emit('setParentShow');
    },
    getSuggestedDecks (suggestionTerms) {
      const newSuggestionDecks = {};
      // eslint-disable-next-line array-callback-return
      Object.keys(suggestionTerms).forEach((key) => {
        const suggestions = [...suggestionTerms[key]];
        const suggestionPerRow = [];
        for (let i = 0; i < suggestions.length; i += this.boxesPerRow * this.listItemsPerCol) {
          suggestionPerRow.push(suggestions.slice(i, i + this.boxesPerRow * this.listItemsPerCol));
        }
        const newSuggestionDecksForKey = [];
        for (let i = 0; i < suggestionPerRow.length; i += 1) {
          const suggestionCols = [];
          for (let j = 0; j < suggestionPerRow[i].length; j += this.listItemsPerCol) {
            suggestionCols.push(suggestionPerRow[i].slice(j, j + this.listItemsPerCol));
          }
          newSuggestionDecksForKey.push(suggestionCols);
        }
        while (newSuggestionDecksForKey.length < this.boxesPerRow) {
          newSuggestionDecksForKey.push([]);
        }
        newSuggestionDecks[key] = newSuggestionDecksForKey;
      });
      return newSuggestionDecks;
    },
    processBodyProximityTerms (data, levels) {
      const newBodyProximityTerms = {};
      const bodyParts = levels.reduce((acc, currentLevel) => {
        acc[currentLevel] = data[currentLevel].BodyPart;
        return acc;
      }, {});

      if (bodyParts.parent.length > 0) {
        newBodyProximityTerms[`Where "${data.nerLabel}" is found`] = bodyParts.parent;
      }

      const nearbyBodyParts = new Set([...bodyParts.siblings, ...bodyParts.neighbors]);
      if (nearbyBodyParts.size > 0) {
        newBodyProximityTerms['Nearby Body Parts'] = nearbyBodyParts;
      }

      if (bodyParts.current.length > 0) {
        newBodyProximityTerms[`Synonyms of "${data.nerLabel}"`] = bodyParts.current;
      }

      if (bodyParts.children.length > 0) {
        newBodyProximityTerms['Component Body Parts'] = bodyParts.children;
      }

      this.bodyProximityLength = bodyParts.current.length + bodyParts.parent.length + nearbyBodyParts.size + bodyParts.children.length;

      return newBodyProximityTerms;
    },
    processRelatedMedicalTerms (data, levels, organTypes) {
      const newRelatedMedicalTerms = {};
      let totalCount = 0;

      organTypes.forEach((organType) => {
        const currentTermsArray = levels.reduce((acc, level) => {
          acc.push(...data[level][organType]);
          return acc;
        }, []);
        const currentTermsSet = new Set(currentTermsArray);
        if (currentTermsSet.size > 0) {
          totalCount += currentTermsSet.size;
          newRelatedMedicalTerms[organType] = currentTermsSet;
        }
      });

      this.relatedMedicalLength = totalCount;

      return newRelatedMedicalTerms;
    },
  },
  watch: {
    bodyPartsResponse: {
      immediate: true,
      handler (data) {
        if (!data) return;

        if (Object.values(data).filter(item => item !== null).length === 0) {
          this.$emit('setBodyProximityLength', 0);
          this.$emit('setRelatedMedicalLength', 0);
          this.bodyProximityDecks = [];
          this.relatedMedicalDecks = [];
          this.bodyProximityLength = 0;
          this.relatedMedicalLength = 0;
          return;
        }

        const levels = ['current', 'parent', 'siblings', 'neighbors', 'children'];
        const organTypes = ['Organ', 'Bone', 'Muscle'];

        // Body Proximity
        this.bodyProximityTerms = this.processBodyProximityTerms(data, levels);
        this.bodyProximityDecks = this.getSuggestedDecks(this.bodyProximityTerms);
        this.$emit('setBodyProximityLength', this.bodyProximityLength);

        // Related Medical
        this.relatedMedicalTerms = this.processRelatedMedicalTerms(data, levels, organTypes);
        this.relatedMedicalDecks = this.getSuggestedDecks(this.relatedMedicalTerms);
        this.$emit('setRelatedMedicalLength', this.relatedMedicalLength);
      },
    },
    corrections: {
      immediate: true,
      handler (value) {
        this.didYouMeanTerms = new Set([...value, ...this.suggestions]);
        this.$emit('setDidYouMeanLength', this.didYouMeanTerms.size);
      },
    },
    suggestions: {
      immediate: true,
      handler (value) {
        this.didYouMeanTerms = new Set([...this.corrections, ...value]);
        this.$emit('setDidYouMeanLength', this.didYouMeanTerms.size);
      },
    },
    parentShowDidYouMean: {
      immediate: true,
      handler () {
        this.showDidYouMean = this.parentShowDidYouMean;
      },
    },
    parentShowBodyProximity: {
      immediate: true,
      handler () {
        this.showBodyProximity = this.parentShowBodyProximity;
      },
    },
    parentShowRelatedMedical: {
      immediate: true,
      handler () {
        this.showRelatedMedical = this.parentShowRelatedMedical;
      },
    },
  },
};
</script>
