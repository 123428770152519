

export const ICRS_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const GUIDED_PREFIX_IGNORE_LIST = [
  'Head - ',
  'Neck - ',
  'Back - ',
  'Upper Limb - ',
  'Lower Limb - ',
  'Miscellaneous - ',
  'Skull - ',
  'Clavicle - ',
  'Shoulder - ',
  'Upper Arm - ',
  'Elbow - ',
  'Forearm -',
  'Pelvis and Hip - ',
  'Upper Leg - ',
  'Knee - ',
  'Lower Leg - ',
  'Ankle - ',
];

export const KEYWORDS_TOPIC_TITLE_DISPLAY_ORDER = {
  // level1: 'Nature of Injury - Level 1 (TBC)',
  // level2: 'Nature of Injury - Level 2 (TBC)',
  // level3: 'Nature of Injury - Level 3 (TBC)',
  dateAssessment: 'Assessment Date',
  courtType: 'Court Type',
  sex: 'Sex',
  plaintiffAge: 'Age',
  occupation: 'Occupation',
  dateAccident: 'Accident Date',
  salaryAssessmentAnnual: 'Salary (Assessment) (Annual)',
  salaryAssessmentAdHoc: 'Salary (Assessment) (Ad-Hoc)',
  salaryAccidentAnnual: 'Salary (Accident) (Annual)',
  salaryAccidentAdHoc: 'Salary (Accident) (Ad-Hoc)',
  // injuryFinalAwardedAmount: 'Final Amount',
  // injuryNonGlobalFinalAwardedAmount: 'Non-Global Final Amount',
  // injuryGlobalFinalAwardedAmount: 'Global Final Amount',
};

export const FILTERS_TOPIC_TITLE_DISPLAY_ORDER = {
  level1code: {
    display: 'Nature of Injury - Level 1',
    type: 'dropdown',
  },
  level2code: {
    display: 'Nature of Injury - Level 2',
    type: 'dropdown',
  },
  level3code: {
    display: 'Nature of Injury - Level 3',
    type: 'dropdown',
  },
  dateAssessment: {
    display: 'Assessment Date',
    type: 'date',
  },
  injuryFinalAwardedAmount: {
    display: 'Amount Awarded for Pain and Suffering',
    type: 'numeric',
  },
  courtTypeHigh: {
    display: 'Court Type',
    type: 'checkbox',
  },
  sex: {
    display: 'Sex',
    type: 'checkbox',
  },
  plaintiffAge: {
    display: 'Age',
    type: 'slider',
  },
  occupation: {
    display: 'Occupation',
    type: 'dropdown',
  },
  dateAccident: {
    display: 'Accident Date',
    type: 'date',
  },
  salaryAssessmentAnnual: {
    display: 'Salary (Assessment) (Annual)',
    type: 'numeric',
  },
  salaryAssessmentAdHoc: {
    display: 'Salary (Assessment) (Ad-Hoc)',
    type: 'numeric',
  },
  salaryAccidentAnnual: {
    display: 'Salary (Accident) (Annual)',
    type: 'numeric',
  },
  salaryAccidentAdHoc: {
    display: 'Salary (Accident) (Ad-Hoc)',
    type: 'numeric',
  },
};
