import _isString from 'lodash/isString';
/**
 * @summary Boolean values might be transformed to a String when injecting from cicd
 * additional check needed to transform config back to Boolean value.
 */

const runtimeConfig = window.SEARCH_WIDGET_CONFIG;

export default {
  getBaseUrl () {
    if (this.getUseDevProxy()) {
      return '';
    }

    return runtimeConfig.VUE_APP_TAIGER_SEARCH_BASE_URL;
  },
  getUseDevProxy () {
    const config = runtimeConfig.VUE_APP_TAIGER_SEARCH_USE_DEV_PROXY;
    if (_isString(config)) {
      return config === 'true';
    }
    return config;
  },
  getBaseRoute () {
    return runtimeConfig.VUE_APP_TAIGER_SEARCH_BASE_ROUTE;
  },
  getEnableAuth () {
    const config = runtimeConfig.VUE_APP_TAIGER_SEARCH_ENABLE_AUTH;
    if (_isString(config)) {
      return config === 'true';
    }
    return config;
  },
  getIsADAuth () {
    const config = runtimeConfig.VUE_APP_TAIGER_SEARCH_IS_AD_AUTH;
    if (_isString(config)) {
      return config === 'true';
    }
    return config;
  },
  getADAuthEndpoint () {
    return runtimeConfig.VUE_APP_TAIGER_SEARCH_AD_AUTH_ENDPOINT;
  },
  getKPOIntegrationEnabled () {
    const config = runtimeConfig.VUE_APP_TAIGER_SEARCH_KPO_INTEGRATION;
    if (_isString(config)) {
      return config === 'true';
    }
    return config;
  },
  getKPOAdminUIUrl () {
    return runtimeConfig.VUE_APP_TAIGER_SEARCH_KPO_ADMIN_UI_URL;
  },
  getSearchConnector () {
    return runtimeConfig.VUE_APP_API_SEARCH_GATEWAY_CONTEXT + runtimeConfig.VUE_APP_API_SEARCH_CONNECTOR;
  },
  getUaaContext () {
    return runtimeConfig.VUE_APP_API_UAA_CONTEXT;
  },
  getSearchGatewayContext () {
    return runtimeConfig.VUE_APP_API_SEARCH_GATEWAY_CONTEXT
      ? runtimeConfig.VUE_APP_API_SEARCH_GATEWAY_CONTEXT : runtimeConfig.VUE_APP_API_SEARCH_SEARCH_CONTEXT;
  },
  getSearchSearchContext () {
    if (runtimeConfig.VUE_APP_API_SEARCH_GATEWAY_CONTEXT) {
      return runtimeConfig.VUE_APP_API_SEARCH_GATEWAY_CONTEXT + runtimeConfig.VUE_APP_API_SEARCH_SEARCH_CONTEXT;
    }
    return runtimeConfig.VUE_APP_API_SEARCH_SEARCH_CONTEXT;
  },
  getSearchAdminContext () {
    return runtimeConfig.VUE_APP_API_SEARCH_ADMIN_CONTEXT;
  },
  getSearchKmContext () {
    return runtimeConfig.VUE_APP_API_SEARCH_KM_CONTEXT;
  },
  getOntologyUrl () {
    return runtimeConfig.VUE_APP_TAIGER_SEARCH_ADMIN_ONTOLOGY_URL;
  },
  getICRSSearchGraphContext () {
    return runtimeConfig.VUE_APP_ICRS_SEARCH_GRAPH_CONTEXT;
  },
  getEventTrackerContext () {
    return runtimeConfig.VUE_APP_API_EVENT_TRACKER_CONTEXT;
  },
  getApmServerEndpoint () {
    return runtimeConfig.VUE_APP_API_APM_SERVER_ENDPOINT;
  },
  getApmAgentEnabled () {
    const config = runtimeConfig.VUE_APP_TAIGER_SEARCH_ENABLE_APM_AGENT;
    if (_isString(config)) {
      return config === 'true';
    }
    return config;
  },
  useICRSConfig () {
    return runtimeConfig.VUE_APP_USE_ICRS_CONFIG;
  },
  getICRSSAMLLoginUrl () {
    return runtimeConfig.VUE_APP_ICRS_SAML_LOGIN_URL;
  },
  getSAMLAuthEnabled () {
    return runtimeConfig.VUE_APP_ICRS_ENABLE_SAML_AUTH;
  },
  getICRSSAMLUserDetails () {
    return runtimeConfig.VUE_APP_ICRS_SAML_USER_DETAILS_URL;
  },
  getICRSSAMLLogoutUrl () {
    return runtimeConfig.VUE_APP_ICRS_SAML_LOGOUT_URL;
  },
  getICRSSALLoginUrl () {
    return runtimeConfig.VUE_APP_ICRS_SAL_LOGIN_URL;
  },
  getSALAuthEnabled () {
    return runtimeConfig.VUE_APP_ICRS_ENABLE_SAL_AUTH;
  },
  getICRSSALLogoutUrl () {
    return runtimeConfig.VUE_APP_ICRS_SAL_LOGOUT_URL;
  },
  getICRSExcelUploadUrl () {
    return runtimeConfig.VUE_APP_ICRS_EXCEL_UPLOAD;
  },
  getIntranetEnabled () {
    return runtimeConfig.VUE_APP_USE_INTRANET_CONFIG;
  },
  getLawNetLink () {
    return runtimeConfig.VUE_APP_ICRS_LAWNET_LINK;
  },
  getUseWOGAA () {
    return runtimeConfig.VUE_APP_ICRS_ENABLE_WOGAA;
  },
};
