<template>
  <div class="badge-dropdown">
    <button
      ref="button"
      class="badge-dropdown__button"
      @click.self.stop="handleClick"
      @focusout="handleBlur"
      v-click-outside="handleOutsideClick"
    >
      <span
        @click.stop="handleClick"
        class="badge-dropdown__text"
      >{{ text }}</span>
      <span
        @click.stop="handleClick"
        class="badge-dropdown__chevron"
      >
        <svg
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.06 0L4 3.05333L0.94 0L0 0.94L4 4.94L8 0.94L7.06 0Z"
            fill="#5B6981"
          ></path>
        </svg>
      </span>
    </button>
    <div
      tabindex="0"
      class="badge-dropdown__dropdown"
      ref="dropdown"
    >
      <slot name="dropdown"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },

  data () {
    return {
      isDropdownShowing: false,
    };
  },

  methods: {
    handleOutsideClick (e) {
      window.lastClickedBadgeElement = null;
    },

    handleBlur (e) {
      const isWithinDropdown = this.$el.contains(e.relatedTarget);
      if (isWithinDropdown) {
        this.$refs.button.focus();
        return;
      }
      this.$refs.dropdown.removeAttribute('style');

      const isMeBlurring = window.lastClickedBadgeElement === this.$refs.button;
      const isToggleClose = e.target === this.$refs.button;
      if (isMeBlurring || isToggleClose) {
        this.$emit('blur');
      }
    },

    handleClick (e) {
      const isClickedBefore = this.$refs.button === window.lastClickedBadgeElement;
      if (isClickedBefore) {
        e.preventDefault();
        window.lastClickedBadgeElement = null;
        this.$refs.button.blur();
        return;
      }

      this.$refs.dropdown.style.opacity = 1;
      window.lastClickedBadgeElement = this.$refs.button;
    },
  },

  updated () {
    this.$nextTick(() => {
      if (window.lastClickedBadgeElement === this.$refs.button) {
        this.$refs.button.focus();
        this.$refs.dropdown.style.opacity = 1;
      } else {
        // this.$refs.button.blur();
      }
    });
  },

  beforeDestroy () {
    window.lastClickedBadgeElement = null;
    this.$refs.button.blur();
  },
};
</script>

<style lang="scss" scoped>
.badge-dropdown {
  display: inline-block;
  position: relative;

  &__button {
    background: $white;
    display: inline-block;
    border: 1px solid $blue-gray-100;
    padding: 4px 8px;
    border-radius: 13px;
    line-height: 16px;
    cursor: pointer;

    &:focus {
      border-color: $blue-500;
      outline: none;

      .badge-dropdown__text {
        color: $blue-500;
      }

      svg {
        transform: rotate(180deg);

        path {
          fill: $blue-500;
        }
      }
    }

    &:focus + .badge-dropdown__dropdown {
      opacity: 0;
      display: block;
    }
  }

  &__text {
    color: $blue-gray-400;
    font-size: 12px;
    line-height: 16px;
  }

  &__chevron {
    margin-left: 8px;
  }

  &__dropdown {
    display: none;
    position: absolute;
    z-index: 10;
    left: 0;
    margin-top: 2px;
    border-radius: 4px;
    width: 242px;
    background: $white;
    border: 1px solid $blue-gray-100;
    box-shadow: 2px 4px 8px rgba(153, 155, 168, 0.2);
    font-size: 14px;
    line-height: 20px;
    color: $blue-gray-400;
    text-align: left;
  }
}
</style>
