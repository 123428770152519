<template>
  <b-navbar
    :fixed="fixed"
    :sticky="sticky"
    class="t-top-navigation"
    :toggleable="toggleable"
    :type="this.backgroundType"
  >
    <b-navbar-brand
      href="#"
      :class="[withLeftDropdownClass, 'p-10-20']"
      @click="selectBrand"
    >
      <component
        v-if="config.appLogo"
        :is="require(`../assets/${this.config.appLogo}`).default"
      />
      <span
        class="brand"
        v-dompurify-html="title"
      ></span>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse
      id="nav-collapse"
      is-nav
    >
      <b-navbar-nav v-if="showLeftDropdown">
        <!-- @slot Use this slot to provide custom left dropdown  -->
        <slot name="left-dropdown">
          <div
            v-if="!leftDropdownOptions.length"
            class="tenant-list left-dropdown-label"
          >
            <span
              class="mr-lg"
              v-dompurify-html="leftDropdownLabel"
            ></span>
          </div>
          <b-nav-item-dropdown
            v-if="leftDropdownOptions.length"
            class="tenant-list"
            right
          >
            <template v-slot:button-content>
              <span
                v-if="leftDropdownSelectedOption"
                class="mr-lg"
                v-dompurify-html="leftDropdownSelectedOption.text"
              ></span>
              <span
                v-if="!leftDropdownSelectedOption && leftDropdownLabel"
                class="mr-lg"
                v-dompurify-html="leftDropdownLabel"
              ></span>
            </template>
            <template v-for="option in leftDropdownOptions">
              <b-dropdown-item
                :href="fixLink(option.to || '#')"
                :key="option.value"
                @click="selectLeftDropdownOption(option)"
              >{{ option.text }}</b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
          <span
            v-if="toggleable == 'xl'"
            class="polygon mr-32"
          ></span>
        </slot>
      </b-navbar-nav>
      <b-navbar-nav class="p-10-20">
        <!-- @slot Use this slot to provide links  -->
        <slot name="links"></slot>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav
        class="ml-auto right-dropdown d-flex align-items-center"
        v-if="showRightDropdown"
      >
        <b-nav-item
          v-if="this.showHelp"
          @click="navToHelp"
        >
          <font-awesome-icon
            class="question-icon"
            :icon="['far', 'question-circle']"
            size="lg"
          />
        </b-nav-item>

        <slot name="right-dropdown">
          <b-nav-text class="right-nav d-flex align-items-center">
            <span class="user-icon d-flex align-items-center justify-content-center">
              <userIcon />
            </span>
          </b-nav-text>
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <span
                class="mr-lg"
                v-dompurify-html="rightDropdownLabel"
              ></span>
            </template>
            <template v-for="option in rightDropdownOptions">
              <b-dropdown-item
                @click="selectRightDropdownOption(option)"
                :href="fixLink(option.to || '#')"
                :key="option.value"
              >{{ option.text }}</b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
        </slot>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import userIcon from '@/assets/tds-icons/user.svg';
import { sanitizeUrl } from '@braintree/sanitize-url';
/**
 * The Top Navigation patterns.
 */
export default {
  name: 'TopNavigation',
  status: 'release',
  release: '1.0.0',
  components: {
    userIcon,
  },
  props: {
    /**
     * navigation title
     */
    title: {
      type: String,
      default: 'Navigation Title',
    },
    /**
     * Set to 'top' for fixed to the top of the viewport, or 'bottom' for fixed to the bottom of the viewport
     */
    fixed: {
      type: String,
    },
    /**
     * Set to true to make the navbar stick to the top of the viewport
     * (or parent container that has 'position: relative' set) when scrolled
     */
    sticky: {
      type: Boolean,
      default: false,
    },
    /**
     * Label for left dropdown
     */
    leftDropdownLabel: {
      type: String,
    },
    /**
     * Label for right dropdown
     */
    rightDropdownLabel: {
      type: String,
    },
    /**
     * Set to `true` to show the left dropdown
     */
    showLeftDropdown: {
      type: Boolean,
      default: false,
    },
    /**
     * Left dropdown options.
     */
    leftDropdownOptions: {
      type: Array,
      default: () => [],
    },
    /**
     * Set to `true` to show the right dropdown
     */
    showRightDropdown: {
      type: Boolean,
      default: false,
    },
    /**
     * Right dropdown options.
     */
    rightDropdownOptions: {
      type: Array,
      default: () => [],
    },
    backgroundType: {
      type: String,
      default: 'dark',
    },
    showHelp: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      toggleable: 'xl',
      delta: null,
      elWidth: null,
      leftDropdownSelectedOption: null,
      config: {
        appLogo: 'tds-icons/app_logo.svg',
      },
    };
  },
  computed: {
    withLeftDropdownClass () {
      return this.showLeftDropdown ? 'with-left-dropdown' : 'mr-52';
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getElWidth);
  },
  mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.getElWidth);
      // Init
      this.getElWidth();
    });
  },
  methods: {
    fixLink(url) {
      return sanitizeUrl(url);
    },
    getElWidth (event) {
      this.elWidth = this.$el.clientWidth;
    },
    selectLeftDropdownOption (option) {
      this.leftDropdownSelectedOption = option;
      this.leftDropdownChangeEvent(option);
    },
    selectRightDropdownOption (option) {
      this.rightDropdownChangeEvent(option);
    },
    async leftDropdownChangeEvent (option) {
      /**
       * Emitted when a left dropdown has changed.
       *
       * @event left-dropdown-change
       * @property {object} option
       * @type function
       */
      await this.$nextTick();

      this.$emit('left-dropdown-change', option);
    },
    rightDropdownChangeEvent (option) {
      /**
       * Emitted when a right dropdown has changed.
       *
       * @event right-dropdown-change
       * @property {object} option
       * @type function
       */
      this.$emit('right-dropdown-change', option);
    },
    selectBrand () {
      this.$emit('brand-click');
    },
    navToHelp () {
      this.$emit('nav-help');
    },
  },
};
</script>

<style lang="scss">
@mixin text {
  font-size: $font-md;
  line-height: $ln-height-20;
}
.pr-lg {
  padding-right: $gap-lg;
}
.mr-52 {
  margin-right: 52px;
}
.mr-32 {
  margin-right: 32px;
}
.p-10-20 {
  padding: 10px $gap-lg;
}
.t-top-navigation {
  // font-family: "Nunito", Helvetica, Arial, sans-serif;
  font-family: $icrs-font-family;
  background-color: $blue-gray-600;
  .left-dropdown-label {
    line-height: 4;
    margin: auto;
    color: $white-500;
  }

  &.navbar {
    padding: 0;
  }
  @media (min-width: 992px) {
    height: 58px;
  }
  .tenant-list {
    background-color: #1f58b1;
    margin-right: 0;
    padding: 0 12px;
  }
  .polygon {
    width: 20px;
    border-left: solid 20px #1f58b1;
    border-bottom: solid 28px transparent;
    border-top: solid 28px transparent;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .right-nav {
    border-left: 2px solid $blue-gray-500;
    padding-left: $gap-lg;
  }
  .menu {
    cursor: pointer;
  }
  .user-icon {
    background-color: $blue-gray-500;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
  .brand {
    margin-left: $gap-sm;
    @include text;
    font-weight: $font-weight-bold;
  }
  .navbar-brand {
    &.with-left-dropdown {
      background-color: #1f58b1;
      border-right: 2px solid #396cbd;
      margin-right: 0;
    }
  }

  .navbar-collapse {
    height: 100%;
    &.show {
      .polygon {
        display: none;
      }
      .right-dropdown {
        padding-right: 12px;
        padding-left: 12px;
      }
    }
  }

  .navbar-nav {
    background-color: $blue-gray-600;
    .nav-link {
      @include text;
      font-weight: $weight_normal;
      color: $white-500;
      height: 100%;
      &.dropdown-toggle {
        line-height: 3;
        &::after {
          vertical-align: middle;
        }
      }
    }
    .nav-item {
      &:not(.tenant-list) {
        margin-right: $gap-xs;
      }
      .question-icon {
        color: $white-500;
      }
    }
  }
}
</style>
