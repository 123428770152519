<template>
  <b-form-group
    v-bind="$props"
    class="t-toggle-button"
    :label="label"
  >
    <b-form-radio-group
      button-variant="primary"
      buttons
      :disabled="disabled"
      :class="[pillClasses, iconClasses]"
      :id="id"
      :name="name"
      :required="required"
      :checked="value"
      v-on:input="updateValue"
    >
      <template v-for="(option, idx) in values">
        <b-form-radio
          :key="idx"
          :value="option.value"
          :disabled="disabled ? disabled : option.disabled"
        >
          <font-awesome-icon
            v-if="icon"
            :icon="['fas', `${option.text}`]"
          />
          {{ !icon ? option.text : "" }}

        </b-form-radio>
      </template>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
// import { BFormRadio, BFormRadioGroup, BFormGroup } from 'bootstrap-vue';

const ANY = [String, Number, Boolean, Array, Object, Date, Function];
/**
 * Toggle Button component
 */
export default {
  name: 'ToggleButton',
  status: 'release',
  release: '1.0.0',
  data () {
    const defaultIcons = [
      {
        text: 'align-left',
        value: 'align-left',
        disabled: false,
      },
      {
        text: 'align-justify',
        value: 'align-justify',
        disabled: false,
      },
      {
        text: 'align-right',
        value: 'align-right',
        disabled: false,
      },
    ];
    const { options } = this;
    const icons = options.length > 0 ? options : defaultIcons;
    const values = this.icon ? icons : options;
    return {
      values,
    };
  },
  methods: {
    updateValue (checked) {
      /**
       * Input event.
       *
       * @event input
       * @type {function}
       */
      this.$emit('input', checked);
    },
  },
  props: {
    /**
     * Act as `v-model`. The current value of the checked radio in the group
     */
    value: {
      type: String,
    },
    /**
     * Adds the 'required' attribute to the form control
     */
    required: {
      type: Boolean,
    },
    /**
     * Set to true to make Toggle Button pill
     */
    pill: {
      type: Boolean,
    },
    /**
     * When set to 'true', disables all toggle button's functionality and places it in a disabled state
     */
    disabled: {
      type: Boolean,
    },
    /**
     * The value to determine toggle button should display text or icon.
     * Set to true to display Icon. Set to false to display text.
     */
    icon: {
      type: Boolean,
    },
    /**
     * The value to set toggle button options. It should be array of Object
     *
     * ##### Text Mode
     * [
     *  { text: 'Toggle 1', value: 'Toggle 1' },
     *  { text: 'Toggle 2', value: 'Toggle 2' }
     * ]
     *
     * ##### Icon Mode
     *
     * text   : must be fontawesome icon name
     * <br />
     * value  : can be anything
     *
     * [{text: 'plus', value: 'icon-plus'},{ text: 'lock', value: 'icon-lock'}]
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     *
     * This value property determine the label of the Toggle Button.
     */
    label: {
      type: String,
    },
    /**
     *
     * Used to set the 'id' attribute on the rendered content,
     * and used as the base to generate any additional element IDs as needed
     */
    id: {
      type: String,
      default: 'radio-group',
    },
    /**
     *
     * Sets the value of the 'name' attribute on the form control
     */
    name: {
      type: String,
      default: 'radio-sub-component',
    },
  },
  computed: {
    pillClasses () {
      return this.pill ? 'pill' : '';
    },
    iconClasses () {
      return this.icon ? 'icon' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.t-toggle-button {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  .btn-group-toggle {
    &.pill {
      > :first-child {
        border-top-left-radius: $radius_pill;
        border-bottom-left-radius: $radius_pill;
      }
      > :last-child {
        border-top-right-radius: $radius_pill;
        border-bottom-right-radius: $radius_pill;
      }
    }
    &.icon {
      .btn {
        padding: 10px;
      }
    }
  }

  .btn {
    font-size: $font-md;
    font-weight: $weight-normal;
    line-height: $ln-height-20;
    padding: 8px 20px;
    height: 36px;

    svg {
      vertical-align: baseline;
    }

    &.btn-primary {
      // @include custom-button-variant(
      //   $background: $white-500,
      //   $color: $blue-gray-400,
      //   $color_hover: $white-500,
      //   $border: $blue-gray-100,
      //   $color_disabled: $blue-gray-200,
      //   $hover-background: $blue-500,
      //   $hover-border: $blue-500
      // );
    }
  }
}
</style>
