
import _isUndefined from 'lodash/isUndefined';

export default class DocUpdateParameter {
  constructor (params) {
    this.id = !_isUndefined(params.id) ? params.id : null;
    this.title = !_isUndefined(params.title) ? params.title : null;
    this.source = !_isUndefined(params.source) ? params.source : null;
    this.path = !_isUndefined(params.path) ? params.path : null;
    this.content = !_isUndefined(params.content) ? params.content : null;
    this.createdDate = !_isUndefined(params.createdDate) ? params.createdDate : null;
    this.lastModifiedDate = !_isUndefined(params.lastModifiedDate) ? params.lastModifiedDate : null;
    this.version = !_isUndefined(params.version) ? params.version : null;
    this.latest = !_isUndefined(params.latest) ? params.latest : null;
    this.roles = !_isUndefined(params.roles) ? params.roles : null;
    this.metadata = !_isUndefined(params.metadata) ? params.metadata : null;
    this.priority = !_isUndefined(params.priority) ? params.priority : null;
    this.featured = !_isUndefined(params.featured) ? params.featured : null;
    this.tags = !_isUndefined(params.tags) ? params.tags : null;
  }
}
