<template>
  <!-- Start of JDS Scroll to Top Widget -->
  <a
    v-if="showScrollToTop"
    id="scroll-to-top"
    title="Scroll to Top"
    class="show"
    @click="scrollTop"
  >
    <span class="fas fa-chevron-up"></span>
  </a>
  <!-- End of JDS Scroll to Top Widget -->
</template>

<script>
export default {
  props: {
    handleCustom :{
      type: Function
    }
  },
  data() {
    return {
      showScrollToTop: false
    }
  },
  methods: {
    scrollTop () {
      if (this.handleCustom) this.handleCustom();
      this.routerWindow.scrollTop = 0;
    },
    onScroll () {
      if (!this.routerWindow) return;
      const currentScrollPosition = this.routerWindow.scrollTop;
      if (currentScrollPosition < 0) return;

      if (currentScrollPosition === 0) {
        this.showScrollToTop = false;
      } else {
        this.showScrollToTop = true;
      }
    },
  },
  computed: {
    routerWindow() {
      const routerWindow = document.getElementById("router-window");
      if (!routerWindow) return;
      return routerWindow;
    }
  },
  mounted() {
    if (!this.routerWindow) return;
    this.scrollTop();
    this.routerWindow.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    if (!this.routerWindow) return;
    this.routerWindow.removeEventListener('scroll', this.onScroll);
  }
}
</script>