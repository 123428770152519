import Api from './api/api.service';
import configService from './config.service';

const SEARCH_CONTEXT = configService.getSearchSearchContext();

export default {
  getTableItemsList (checkPage) {
    return Api().get(`${SEARCH_CONTEXT}/pagemanagement/getallpage?checkPage=${checkPage}`);
  },
  addNewPage (newPage) {
    return Api().post(`${SEARCH_CONTEXT}/pagemanagement/createpage`, newPage);
  },
  editNewPage (newPage) {
    return Api().post(`${SEARCH_CONTEXT}/pagemanagement/editpage`, newPage);
  },
  getPageDetail (pageId) {
    return Api().get(`${SEARCH_CONTEXT}/pagemanagement/pagedetail?pageId=${pageId}`).then(response => response);
  },
  deletePageDetail (pageId) {
    return Api().post(`${SEARCH_CONTEXT}/pagemanagement/deletepage?pageId=${pageId}`);
  },

  changeOrder (payload) {
    return Api().post(`${SEARCH_CONTEXT}/pagemanagement/reorder?idPage1=${payload.idPage1}&idPage2=${payload.idPage2}&checkPage=${payload.checkPage}`);
  },
};
