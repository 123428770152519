<template>
  <div id="left_bubble">
    <left-bubble-svg viewBox="0 0 320 320" />
    <div id="bubble_content">
      <div id="bubble_text">Documents from various sources with different formats</div>
      <div id="bubble_icons">
        <filesystem-svg v-b-tooltip.hover title="Filesystem" viewBox="-12.5 -12.5 75 75" />
        <sharepoint-svg v-b-tooltip.hover title="MS Sharepoint" viewBox="-12.5 -12.5 75 75" />
        <csv-svg v-b-tooltip.hover title="CSV" viewBox="-12.5 -12.5 75 75" />
        <html-svg v-b-tooltip.hover title="HTML sites" viewBox="-12.5 -12.5 75 75" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftBubbleSvg from '@/assets/admin-page/graphics/left-bubble.svg';
import FilesystemSvg from '@/assets/admin-page/icons/filesystem.svg';
import SharepointSvg from '@/assets/admin-page/icons/sharepoint.svg';
import CsvSvg from '@/assets/admin-page/icons/csv.svg';
import HtmlSvg from '@/assets/admin-page/icons/html.svg';

export default {
  components: {
    LeftBubbleSvg,
    FilesystemSvg,
    SharepointSvg,
    CsvSvg,
    HtmlSvg,
  },
};
</script>

<style lang="scss" scoped>
#left_bubble {
  position: relative;
  z-index: 10;
  display: flex;
}
#left_bubble > * {
  position: absolute;
}
#bubble_content {
  color: $white-500;
  font-size: $font-sm;
  width: 150px;
  height: 150px;
  z-index: 10;
  text-align: center;
  margin: 2.6em;
}
#bubble_icons {
  padding: 0.5em 2em;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  img {
    width: 2.5em;
    margin: 0.5em;
  }
}
#bubble_text {
  padding: 0 1em;
}
</style>
