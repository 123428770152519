<template>
  <b-navbar
    id="jumbo-modal-navbar"
    class="d-flex align-items-center p-0"
  >
    <b-button
      squared
      class="back-button border-0 m-0"
      @click="hideModal"
    >
      <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" />
    </b-button>
    <span class="title pl-4">{{ title }}</span>
    <slot
      class="d-flex"
      name="nav"
    />
  </b-navbar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    hideModal () {
      this.$emit('hide');
    },
  },
};
</script>

<style lang="scss" scoped>
#jumbo-modal-navbar {
  background-color: $blue-gray-100;
  color: $blue-gray-500;

  z-index: 10;
  font-size: $font-lg;
  font-weight: $weight-normal;
  min-height: 50px;

  .back-button {
    background-color: $blue-gray-100;
    color: $blue-gray-500;

    height: 100%;
    &:hover,
    &:active {
      background: $blue-gray-200;
    }
  }

  .title {
    font-weight: $weight-semi-bold;
    text-align: left;
    white-space: nowrap;
  }
}
</style>
