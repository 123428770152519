<template>
  <div>
    <div class="truncate" :id="tooltipId" >{{ text }}</div>
    <b-tooltip v-if="isOverflown" :target="tooltipId" :container="containerId">
      {{ text }}
    </b-tooltip>
  </div>

</template>

<script>
export default {
  name: 'SelfTooltip',
  data () {
    return {
      isOverflown: false,
    };
  },
  props: {
    text: {
      type: String,
      default: '',
      required: true,
    },
    // anchor tooltip id
    tooltipId: {
      type: String,
      default: '',
      required: true,
    },
    // required, elementId to append the tooltip
    containerId: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    checkOverFlow ({
      clientWidth, clientHeight, scrollWidth, scrollHeight,
    }) {
      return scrollHeight > clientHeight || scrollWidth > clientWidth;
    },
  },
  mounted () {
    this.$nextTick(() => {
      const element = document.querySelector(`#${this.tooltipId}`);
      if (element) {
        this.isOverflown = this.checkOverFlow(element);
      }
    });
  },
};
</script>

<style scoped>
  .truncate {
    display: block;
    /* max-width: 100%; */

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

</style>
