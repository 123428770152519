import Vue from 'vue';
import getDefaultState from './state';

export default {
  setGuidedSearch (state, guidedSearch) {
    Vue.set(state, 'guidedSearch', guidedSearch);
  },
  setDropdownGuidedTag (state, dropdownGuidedTag) {
    Vue.set(state, 'dropdownGuidedTag', dropdownGuidedTag);
  },
  setActiveGuidedPrefix (state, activeGuidedPrefix) {
    Vue.set(state, 'activeGuidedPrefix', activeGuidedPrefix);
  },
  setSelectedLevel1 (state, selectedLevel1) {
    Vue.set(state, 'selectedLevel1', selectedLevel1);
  },
  setSelectedLevel2 (state, selectedLevel2) {
    Vue.set(state, 'selectedLevel2', selectedLevel2);
  },
  setSelectedLevel3 (state, selectedLevel3) {
    Vue.set(state, 'selectedLevel3', selectedLevel3);
  },
  setLevel2CategoriesForSelectedLevel1 (state, level2CategoriesForSelectedLevel1) {
    Vue.set(state, 'level2CategoriesForSelectedLevel1', level2CategoriesForSelectedLevel1);
  },
  setLevel3CategoriesForSelectedLevel2 (state, level3CategoriesForSelectedLevel2) {
    Vue.set(state, 'level3CategoriesForSelectedLevel2', level3CategoriesForSelectedLevel2);
  },
  resetState (state) {
    Object.assign(state, getDefaultState());
  },
  setAllGuidedCategories (state, allGuidedCategories) {
    Vue.set(state, 'allGuidedCategories', allGuidedCategories);
  },
  setAdvancedSearchSelectedLevel (state, advancedSearchSelectedLevel) {
    Vue.set(state, 'advancedSearchSelectedLevel', advancedSearchSelectedLevel);
  },
};
