<template>
  <table class="search-table">
    <thead>
      <tr>
        <th style="width: 300px">Document Name</th>
        <th>Date</th>
        <th v-if="config.showType">Type</th>
        <th>Source</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="document in searchResultDocuments"
        :key="document.id"
      >
        <td>
          <a
            class="document-title"
            :href="fixLink(document.path)"
            rel="noopener"
            target="_blank"
            v-dompurify-html="document.title"
            @click="$emit('trigger-document-metrics', document)"
          ></a>
          <Badge
            class="ml-1"
            v-if="isNew"
            variant="primary"
            pill
          >NEW</Badge>
          <Badge
            class="ml-1"
            pill
            variant="warning"
            v-if="document.promotedDocument"
          >Promoted</Badge>
        </td>
        <td>{{ document.lastModifiedDate }}
        </td>
        <td v-if="config.showType">{{ document.docFormat }}</td>
        <td>{{ document.source }}</td>
        <td>
          <div class="buttonTray">
            <div>
              <button
                v-if="config.showPreviewAction"
                @click="$emit('open-preview-modal', document)"
                class="btn btn-link btn-sm"
              >
                <font-awesome-icon
                  class="icon"
                  :icon="['far', 'eye']"
                  size="1x"
                />
              </button>
            </div>
            <div>
              <button
                v-if="config.showShareAction"
                @click="handleShareClick(document)"
                class="btn btn-link btn-sm"
              >
                <font-awesome-icon
                  class="icon"
                  :icon="['fal', 'share-alt']"
                  size="1x"
                />
              </button>
            </div>
            <div>
              <SettingsDropdown
                class="position-absolute"
                :disableLabel="true"
                @click="$emit('open-document-settings-modal', document, $event)"
              />
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import EventBusService from '@/services/event-bus.service';
import SettingsDropdown from '../../../components/settings-dropdown/SettingsDropdown.vue';
import SearchResultCardModel from '../../../services/models/search-result-card/search-result-card.model';
import { sanitizeUrl } from '@braintree/sanitize-url';
export default {
  name: 'CompactResults',
  components: {
    SettingsDropdown,
  },
  props: {
    searchResults: {
      type: Array,
      default: () => [],
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      config: {
        showType: true,
        showPreviewAction: true,
        showShareAction: false,
      },
    };
  },
  computed: {
    searchResultDocuments () {
      const searchResultModels = this.searchResults.map(result => new SearchResultCardModel(result));
      return searchResultModels;
    },
  },
  mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
  },
  methods: {
    fixLink(url) {
      return sanitizeUrl(url);
    },
    async handleShareClick (document) {
      this.$clipboard(document.path);
      EventBusService.emitNotificationEvent({
        title: 'Link copied to clipboard',
        variant: 'success',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-table {
  width: 100%;

  tr {
    th {
      font-weight: 500;
      color: $blue-gray-500;
    }

    td {
      color: $blue-gray-400;
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th,
    td {
      padding: $gap-lg $gap-xs;
    }
  }

  thead {
    tr {
      background: $blue-gray-50;

      th {
        font-weight: $weight-semi-bold;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $blue-gray-100;
      &:hover,
      &:active {
        background: $blue-200;
      }

      &/deep/ .btn-link {
        color: $blue-400 !important;
      }
    }
  }
  .document-title {
    color: $blue-gray-400;

    &:hover {
      color: $blue-gray-400;
    }
  }
  .buttonTray {
    display: flex;
    > * {
      display: flex;
    }
  }
}
</style>
