<template>
  <div id="leftBar">
    <div id="title">Search UI Catalog</div>
    <div id="version">1.1.0</div>
    <div class="catalogComponent">
      <ul>
        <div
          v-for="(widget, index) in sidebarRoutes"
          :key="index"
          @click="selectStyle (widget) "
        >
          <li
            class="clickable"
            :class="{
              'active': widget.name===selectedItem,
              'main': widget.attribute==='main',
              'sub': widget.attribute!=='main'
            }"
          >
            <span>{{widget.label || widget.name}}</span>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import _groupBy from 'lodash/groupBy';

export default {
  name: 'Sidebar',
  data () {
    return {
      selectedItem: 'Overview',
    };
  },
  methods: {
    selectStyle (item) {
      this.$router.push(item.path).catch((err) => { });
    },
  },
  props: {
    catalogRoutes: {
      type: Array,
      default () {
        return {};
      },
    },
  },
  computed: {
    sidebarRoutes () {
      const sortedRoutes = _groupBy(this.catalogRoutes, 'type');
      const sidebarRoutes = [].concat(
        sortedRoutes.overview,
        sortedRoutes.module,
        sortedRoutes.page,
        sortedRoutes.component,
      );

      return sidebarRoutes;
    },
  },
  watch: {
    '$route.path': {
      handler () {
        const currentRoute = this.catalogRoutes.find(route => route.path === this.$route.path);
        if (currentRoute) {
          this.selectedItem = currentRoute.name;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
#leftBar {
  height: 100%;
  background-color: #0c1d35;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: $gap-xl;
}
#title {
  font-weight: $weight-normal;
  font-size: 24px;
  line-height: 35px;
  color: $blue-gray-50;
  padding-left: $gap-xl;
}
#version {
  color: $blue-gray-50;
  padding: $gap-xxs $gap-xl;
  font-size: $font-md;
  opacity: 50%;
}
ul {
  padding: 0px;
}
li {
  color: $blue-gray-50;
  list-style: none;
  margin-top: $gap-xs;
  padding-top: 0px !important;
  font-size: $font-lg;
  line-height: $ln-height-xl;
  font-weight: $weight-light;
  padding-left: $gap-xl;
  background-image: linear-gradient($blue-300, $blue-300);
  background-position: center left;
  background-size: 0 32px;
  background-repeat: no-repeat;
  transition: 0.2s;
}
.subCatalog {
  padding: 0 $gap-s;
}
.subContent {
  font-size: $font-lg;
  line-height: $ln-height-lg;
}
.main {
  padding-top: $gap-md;
  width: 300px;
  margin-top: $gap-sm;
  height: 35px;
  line-height: 35px;
  overflow: hidden;
}
.sub {
  padding: $gap-xs 0 0 60px;
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
}
.active {
  background-size: 100% 32px;
}
.clickable {
  cursor: pointer;
}
</style>
