<template>
  <div class="search-within-badge">
    <div class="search-within-badge__text">{{ searchText.split(' ; ')[0] }}</div>
    <button
      v-if="showCloseButton"
      @click="$emit('remove', text)"
      class="search-within-badge__button"
    >
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable-next-line -->
        <path
          d="M8 0.805714L7.19429 0L4 3.19429L0.805714 0L0 0.805714L3.19429 4L0
          7.19429L0.805714 8L4 4.80571L7.19429 8L8 7.19429L4.80571 4L8 0.805714Z"
          fill="#5B6981"
        />
      </svg>
    </button>
    <button
      v-if="showIcrsCloseButton"
      @click="$emit('remove', icrsFilterGroupName, text)"
      class="search-within-badge__button"
    >
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable-next-line -->
        <path
          d="M8 0.805714L7.19429 0L4 3.19429L0.805714 0L0 0.805714L3.19429 4L0
          7.19429L0.805714 8L4 4.80571L7.19429 8L8 7.19429L4.80571 4L8 0.805714Z"
          fill="#5B6981"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    name: {
      type: String,
      default: 'Search within:',
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    showIcrsCloseButton: {
      type: Boolean,
      default: false,
    },
    icrsFilterGroupName: {
      type: String,
    },
  },
  computed: {
    searchText () {
      return `${this.name} ${this.text}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-within-badge {
  background: $white;
  display: inline-flex;
  align-items: center;
  border: 1px solid $blue-gray-100;
  padding: 4px 8px;
  border-radius: 13px;
  line-height: 16px;
  cursor: pointer;
  // max-width: 240px;

  &:focus {
    border-color: $blue-500;
    outline: none;

    .search-within-badge__text {
      color: $blue-500;
    }
  }

  &__text {
    max-width: 200px;
    color: $blue-gray-400;
    font-size: 12px;
    display: inline-block;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    // text-overflow: ellipsis;
  }

  &__button {
    background: transparent;
    border: 0;
    display: inline-block;
    padding-left: 8px;
  }
}
</style>
