import router from '@/router';

function goToConnector (event) {
  const req = event.detail[0];
  const { id, tabIndex } = req;
  let path = `/connector/dashboard/${id}`;
  if (tabIndex) {
    path = `${path}/${tabIndex}`;
  }
  router.push(path);
}

function goToDashboard () {
  router.push('/connector/dashboard');
}

export {
  goToConnector,
  goToDashboard,
};
