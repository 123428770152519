<template>
  <div>
    <header
      v-if="useICRSConfig"
      class="hide-for-printing"
      :class="navbarContainer"
      style="top:0"
    >
      <nav class="navbar navbar-expand-lg " id="popover-settings-container"
        :class="{hasBreadcrumb: showBreadcrumbs}"
      >
        <div class="container-fluid">
          <div class="navbar-brand">
            <a
              :href="fixLink(lawnetLink)"
              target="_blank"
              rel="noopener"
              class="tab-focus"
            >
              <img
                src="icrs/images/lawnet.svg"
                style="width:55px; background-color: white;"
                alt="Logo"
              >
            </a>
            <a
              href="#"
              @click="handleRedirect('/search')"
              class="tab-focus"
            >
              <img
                src="icrs/images/icrs-wording.png"
                style="width:75%; background-color: white;"
                alt="Logo"
              >
            </a>
          </div>
          <div
            :class="navbarToggler"
            @click="toggleMobileNav(toggleMode)"
          >
            <div class="menu-icon">
              <div class="menu-bar1"></div>
              <div class="menu-bar2"></div>
              <div class="menu-bar3"></div>
            </div>
          </div>
          <div :class="navbarCollapse">
            <ul class="icrs-navbar navbar-nav ml-md-auto">
              <li
                v-show="config.showEditUI && (isAdminUser || isPageManager || isCaseManager)"
                class="nav-item mx-md-2"
                style="cursor:pointer"
                @click="showModal"
              >
                <a
                  class="nav-link"
                  title="Upload New Data"
                >Add/Edit Data</a>
              </li>
              <li class="nav-item ml-2 ml-md-0 my-2 my-md-0">
                <div class="btn-group">
                  <button
                    class="btn btn-user btn-lg"
                    type="button"
                    style="cursor:default;"
                  >
                    <i class="fa fa-user-circle"></i> {{this.user.firstName}}
                  </button>
                  <div class="d-flex" style="gap: 8px;" >
                    <b-button
                      v-if="isAdminUser || isPageManager || isCaseManager"
                      class="btn btn-setting btn-lg"
                      style="cursor:pointer;"
                      id="popover-settings-target"
                      data-trigger="focus"
                      aria-label="Data and Page Management Menu"
                    >
                      <i class="fas fa-cog" style="color: #751c24;"></i>
                    </b-button>
                    <b-popover
                      v-if="true"
                      target="popover-settings-target"
                      triggers="click blur" placement="bottom"
                      custom-class="popover-container"
                      container="popover-settings-container"
                    >
                      <div class="d-flex flex-column justify-content-around h-100 title-normal">
                          <a
                            v-if="isAdminUser || isCaseManager"
                            class="title-normal-font"
                            href="#/add-edit-data">
                            Add/Edit Data
                          </a>
                          <a
                            v-if="isAdminUser || isPageManager"
                            class="title-normal-font"
                            href="#/page-management">
                              Page Management
                          </a>
                      </div>
                    </b-popover>
                    <button
                      class="btn btn-sign-out btn-lg"
                      type="button"
                      aria-label="Log Out"
                      title="Log Out"
                      @click="logout()"
                    ><i class="fa fa-sign-out-alt"></i> Sign Out </button>

                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Breadcrumbs
      v-if="showBreadcrumbs"
      :items="breadcrumbItems"
      />
    </header>
    <Modal
      v-if="useICRSConfig"
      id="upload-new-data-modal"
      static
      lazy
      no-close-on-backdrop
      ref="modal"
      :no-buttons="true"
      :centered="true"
      title="Add/Edit Data"
      :visible="isModalShowing"
      @close="closeModal"
      @hidden="closeModal"
      okTitle="Confirm"
    >
      <b-alert
        class="has-icon alert-large"
        style="margin-top: 10px;"
        :show="showUploadAlert"
        dismissible
        @dismissed="showUploadAlert=false"
        :variant="alertVariant"
      >
        <div style="display: flex">
          <span class="has-icon">
            <i
              v-if="alertVariant == 'success'"
              class="fa fa-check-circle"
            ></i>
            <i
              v-if="alertVariant == 'danger'"
              class="fa fa-times-circle"
            ></i>
          </span>
          <div class="alert-content">
            <h4>{{uploadHeader}}</h4>
            <p>{{uploadMessage}}</p>
          </div>
        </div>
      </b-alert>
      <b-container
        style="color: black !important"
        fluid
      >
        <div>
          <!-- Styled -->
          <b-form-file
            v-model="uploadedFile"
            :state="Boolean(uploadedFile)"
            placeholder="Choose an Excel file or drop it here."
            drop-placeholder="Drop Excel file here."
            accept=".xls, .xlsx"
          ></b-form-file>
          <p style="margin: 5px 0px; font-weight: bold !important;">
            Selected File:&nbsp;
            <span style="font-weight: normal">
              {{ uploadedFile ? uploadedFile.name : '' }}
            </span>
          </p>
          <p
            v-if="showExcelValidationError"
            style="color: red"
          >
            Error: Only Excel files can be uploaded.
          </p>
          <b-form-group>
            <button
              v-if="!uploading"
              class="btn btn-primary"
              :disabled="showDisabledUpload"
              @click="uploadData"
            >Add/Edit Data</button>
            <div v-if="uploading">
              <font-awesome-icon
                class="icon"
                :icon="['far', 'spinner-third']"
                size="lg"
                spin
              />
              <p>Uploading data...</p>
            </div>
          </b-form-group>
          <hr />
          <p style="margin-bottom: 0; font-weight: bold !important;">Useful Links:</p>
          <ul>
            <li>
              <a
                class="upload-link"
                :href="fixLink(ICRS_FILE_PATHS.UPLOAD_GUIDE)"
                target="_blank"
                rel="noopener"
              >Excel Upload Guide</a>
            </li>
            <li>
              <a
                class="upload-link"
                :href="fixLink(ICRS_FILE_PATHS.SAMPLE_EXCEL_TEMPLATE)"
                target="_blank"
                rel="noopener"
              >Sample Excel Template</a>
            </li>
          </ul>
        </div>
      </b-container>
    </Modal>
    <TopNavigation
      v-if="!useICRSConfig"
      title="Search Dashboard"
      :right-dropdown-options="rightDropdowns"
      :right-dropdown-label="username"
      show-right-dropdown
      @right-dropdown-change="handleRightOptionClick"
      @brand-click="handleBrandClick"
      @nav-help="handleRedirect('/dashboard')"
      :background-type="this.config.backgroundType"
      :show-help="this.config.showHelp"
    >
      <template v-slot:links>
        <b-nav-item-dropdown v-if="connectorChildren.length > 0 && config.showConnectorManagerRoute">
          <template v-slot:button-content>
            <span class="mr-xxs">Connector Manager</span>
          </template>
          <b-dropdown-item
            v-for="(child, index) in connectorChildren"
            :key="index"
            @click="handleRedirect(child.path)"
          >
            {{child.name}}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <div
          v-for="(item, index) in config.navbarItems"
          :key="index"
        >
          <b-nav-item
            v-if="!item.disabled"
            :href="fixLink(item.path)"
            rel="noopener"
            :target="item.newTabOnClick ? '_blank' : '_top'"
          >
            {{ item.title }}
          </b-nav-item>
        </div>
      </template>
    </TopNavigation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ICRSService from '@/services/icrs-service';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import pageManagementRoutes from '@/router/pageManagementRoutes';
import { sanitizeUrl } from '@braintree/sanitize-url';


const SIGNOUT_OPTION = { text: 'Sign Out', value: 'signout' };
const ICRS_FILE_PATHS = {
  UPLOAD_GUIDE: '/icrs/files/icrs_upload_excel_guide.docx',
  SAMPLE_EXCEL_TEMPLATE: '/icrs/files/icrs_sample_upload_excel_template.xlsx',
};
const { ICRS_STATUS } = ICRSService.Constants;

export default {
  name: 'Navbar',
  components: {
    Breadcrumbs,
  },
  props: {
    searchWidgetsEnabled: {
      type: Boolean,
      default: false,
    },
    searchWidgets: {
      type: Array,
      default () {
        return [];
      },
    },
    adminRoutesEnabled: {
      type: Boolean,
      default: true,
    },
    connectorRouteSettings: {
      type: Object,
      default () {
        return {};
      },
    },
  },
  data () {
    return {
      isModalShowing: false,
      rightDropdowns: [
        SIGNOUT_OPTION,
      ],
      config: {
        showConnectorManagerRoute: true,
        navbarItems: [],
        navbarTitle: 'Search Dashboard',
        backgroundType: 'dark',
        showHelp: true,
        showEditUI: false,
      },
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
      navbarContainer: 'navbar-container',
      navbarToggler: 'navbar-toggler',
      navbarCollapse: 'navbar-collapse',
      toggleMode: true,
      uploadedFile: null,
      showDisabledUpload: true,
      showExcelValidationError: false,
      uploadHeader: '',
      uploadMessage: '',
      uploading: false,
      alertVariant: '',
      showUploadAlert: false,
      lawnetLink: process.env.VUE_APP_ICRS_LAWNET_LINK,
      ICRS_FILE_PATHS,
      breadcrumbItems: [{
        text: 'Home',
        href: '/',
      }],
    };
  },
  watch: {
    uploadedFile: {
      immediate: true,
      handler (value) {
        if (value) {
          const fileFormat = value.name.split('.').pop();
          if (fileFormat !== 'xls' && fileFormat !== 'xlsx') {
            this.showDisabledUpload = true;
            this.showExcelValidationError = true;
          } else {
            this.showDisabledUpload = false;
            this.showExcelValidationError = false;
          }
        } else {
          this.showDisabledUpload = true;
          this.showExcelValidationError = false;
        }
      },
    },
    $route (to, from) {
      // close popover whenever routing
      this.$root.$emit('bv::hide::popover');
      // calculate breadcrumbs
      this.calculatedBreadcrumbs();
    },
  },
  computed: {
    ...mapGetters('authService', {
      user: 'getUser',
      isAdminUser: 'isAdminUser',
      isPageManager: 'isPageManager',
      isCaseManager: 'isCaseManager',
    }),
    username () {
      if (!this.user) return '';
      return this.user.firstName;
    },
    connectorChildren () {
      if (!this.connectorRouteSettings || !this.connectorRouteSettings.children) {
        return [];
      }
      return this.connectorRouteSettings.children.filter((c) => {
        if (!c.meta) {
          return true;
        }
        return !c.meta.hide;
      });
    },
    showBreadcrumbs () {
      const { query } = this.$route;
      const allowPath = [
        {
          path: '/page-management',
          name: 'Page Management',
        },
        {
          path: '/page-management/create-new-page',
          name: 'Create New Page / Link',
        },
        {
          path: '/add-edit-data',
          name: 'Add/Edit Data',
        },
        {
          path: '/page-management/edit-page',
          name: 'Edit Page / Link',
        },
        {
          path: '/page-management/preview',
          name: 'Preview',
        },
      ];
      return allowPath.some(pathItem => this.$route.path.startsWith(pathItem.path)) && query.from !== 'Footer';
    },
  },
  methods: {
    logout () {
      this.$emit('logout');
    },
    fixLink (url) {
      return sanitizeUrl(url);
    },
    uploadData () {
      this.uploadHeader = '';
      this.uploadMessage = '';
      this.uploading = true;
      this.showUploadAlert = false;
      ICRSService.execUploadICRSExcel(this.uploadedFile)
        .then(({ status, message }) => {
          if (status === ICRS_STATUS.SUCCESS) {
            this.showSuccessOnUpload(message);
          } else {
            this.showErrorOnUpload(message);
          }
        })
        .finally(() => {
          this.uploading = false;
          this.showUploadAlert = true;
        });
    },
    handleRightOptionClick (option) {
      if (option.value === SIGNOUT_OPTION.value) {
        this.$emit('logout');
      }
    },
    handleRedirect (path) {
      this.$router.push(path, () => { }, err => err);
    },
    handleBrandClick () {
      this.handleRedirect('/');
    },
    toggleMobileNav (mode) {
      if (mode) {
        this.navbarContainer = 'navbar-container nav-open';
        this.navbarToggler = 'navbar-toggler change active';
        this.navbarCollapse = 'navbar-collapse nav-open';
      } else {
        this.navbarContainer = 'navbar-container';
        this.navbarToggler = 'navbar-toggler';
        this.navbarCollapse = 'navbar-collapse';
      }
      this.toggleMode = !mode;
    },
    showModal () {
      this.isModalShowing = true;
    },
    closeModal () {
      this.isModalShowing = false;
      this.showUploadAlert = false;
      this.uploadedFile = null;
    },
    showErrorOnUpload (message) {
      this.uploadHeader = 'Error';
      this.uploadMessage = message || 'Error encountered when uploading the data.';
      this.alertVariant = 'danger';
    },
    showSuccessOnUpload (message) {
      this.uploadHeader = 'Success';
      this.uploadMessage = message || 'Successfully uploaded Excel data';
      this.alertVariant = 'success';
    },
    calculatedBreadcrumbs () {
      this.breadcrumbItems = [{
        text: 'Home',
        href: '#/',
      }];
      // eslint-disable-next-line prefer-destructuring
      const path = this.$route.path;
      const segments = path.split('/');
      let href = '#';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < segments.length; i++) {
        if (segments[i] === '') {
        // eslint-disable-next-line no-continue
          continue;
        }
        href += `/${segments[i]}`;
        const routeIndex = pageManagementRoutes.findIndex(route => route.path === `/${segments[i]}`);
        const text = routeIndex !== -1 ? pageManagementRoutes[routeIndex].name : '#';
        if (i === segments.length - 1) {
          this.breadcrumbItems.push({ text: `${this.$route.name}`, active: true });
        } else if (text !== '#') this.breadcrumbItems.push({ text, href });
      }
    },
  },
  mounted () {
    // after DOM update throw this logic
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }

    this.calculatedBreadcrumbs();
  },
};
</script>
<style lang="scss" scoped>
.title-normal {
    .title-normal-font {
      font-family: "Roboto", Helvetica, Arial, sans-serif !important;
      font-size: 16px;
    }
}

.navbar-brand {
  .tab-focus:focus-visible {
    outline: 2px solid gray;
  }
}

</style>
