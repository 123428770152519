export default class User {
  constructor (data) {
    this.id = !_.isUndefined(data.id) ? data.id : 1;
    this.login = !_.isUndefined(data.login) ? data.login : null;
    this.groups = !_.isUndefined(data.groups) ? data.groups : [];
    this.division = !_.isUndefined(data.division) ? data.division : null;
    this.firstName = !_.isUndefined(data.firstName) ? data.firstName : 'User';
    this.roles = !_.isUndefined(data.roles) ? data.roles : [];
    this.expirationTime = !_.isUndefined(data.expirationTime) ? data.expirationTime : null;
    this.signature = !_.isUndefined(data.signature) ? data.signature : null;
    this.designationType = !_.isUndefined(data.designationType) ? data.designationType : null;
  }
}
