<template>
  <div>
    <b-alert
      class="has-icon alert-large"
      style="margin-top: 10px;"
      :show="dismissCountDown"
      dismissible
      variant="success"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      <div style="display: flex">
        <span class="has-icon"><i class="fa fa-check-circle"></i></span>
        <div class="alert-content">
          <h4>Success</h4>
          <p v-if="isSaved">Search saved.</p>
          <p v-if="isDeleted">Search deleted.</p>
          <p v-if="isEdited">Saved new search title.</p>
          <p v-if="isCopied">Link copied to clipboard.</p>
        </div>
      </div>
    </b-alert>
    <div v-if="mode === modes.LIST">
      <button
        class="btn btn-primary"
        style="margin-bottom: 5px"
        @click.stop="handleCreate()"
      >Save Current Search</button>
      <hr style="margin: 0.75rem 0rem" />
    </div>
    <LoadingSpinner v-if="isSavedSearchesLoading"
      text="Loading..."
      size="md"
    />
    <div class="d-flex justify-content-between">
      <span v-if="mode === modes.LIST && !isSavedSearchesLoading">{{ headerText }}</span>
    </div>
    <div
      class="container"
      v-if="mode === modes.LIST && !isSavedSearchesLoading"
      id="save-search-dropdown"
    >
      <div
        class="saved-search-items__placeholder"
        v-if="!savedSearches.length"
      >
        <div class="saved-search-items__placeholder-content">
          <img
            src="../../assets/empty.png"
            alt=""
          >
          <p class="mb-0 mt-lg">You haven't saved any searches yet.</p>
        </div>
      </div>

      <div
        class="row"
        v-if="savedSearches.length"
      >
        <div class="col-lg-6">
          <div class="saved-search-items__list">
            <div
              v-for="(item, index) in savedSearches"
              :key="item.id"
              class="saved-search-item"
              :class="{
                    'saved-search-item--hovered': isItemHovered(item)
                  }"
              @mouseover="handleHoverItem(item)"
              style="cursor: default !important"
            >
              <div class="details">
                <div class="d-flex">
                  <p class="saved-search-item__name">
                      <SelfTooltip
                        :text="item.name.split(' [')[0]"
                        :tooltipId="`table-title-${item.id}-${index}`"
                        containerId="save-search-dropdown"/>
                    </p>
                  <Badge
                    variant="primary"
                    v-if="item.newResults > 0"
                    pill
                  >+{{ item.newResults }}</Badge>
                </div>
                <p class="saved-search-item__options">
                  {{ getAppliedFiltersCountText(item) }}
                </p>
              </div>

              <div class="actions">
                <div
                  :id="`${item.id}-edit-wrapper`"
                  class="d-inline-block"
                >
                  <button @click.stop="handleEdit(hoveredItem)">
                    <font-awesome-icon
                      class="icon"
                      :icon="['fal', 'pen']"
                      size="sm"
                    />
                  </button>
                  <Tooltip
                    :container="`${item.id}-edit-wrapper`"
                    :target="`${item.id}-edit-wrapper`"
                  >
                    Edit
                  </Tooltip>
                </div>
                <div
                  :id="`${item.id}-delete-wrapper`"
                  class="d-inline-block"
                >
                  <button @click.stop="handleDelete(item)">
                    <font-awesome-icon
                      class="icon"
                      :icon="['fal', 'trash-alt']"
                      size="sm"
                    />
                  </button>
                  <Tooltip
                    :container="`${item.id}-delete-wrapper`"
                    :target="`${item.id}-delete-wrapper`"
                  >
                    Delete
                  </Tooltip>
                </div>
                <div
                  :id="`share-wrapper-${item.id}`"
                  class="share-wrapper"
                >
                  <button @click.stop="handleShare(item)">
                    <font-awesome-icon
                      class="icon"
                      :icon="['far', 'share-alt']"
                      size="sm"
                    />
                  </button>
                  <Tooltip
                    :container="`share-wrapper-${item.id}`"
                    :target="`share-wrapper-${item.id}`"
                  >
                    <span class="share-text">Get Shareable Link</span>

                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 hr-tablet-section">
          <hr style="border: 1px solid black; margin: 0;" />
        </div>
        <div
          v-if="hoveredItem"
          class="col-lg-6"
        >
          <div class="icrs-save-min-font-size saved-search-items__detail">
            <div class="icrs-filters-label filters-label">
              Saved Query:
            </div>
            <div class="name">{{ hoveredItem.request.query }}</div>
            <div class="icrs-filters-label filters-label">
              Saved Date:
            </div>
            <div class="name">{{ icrsCreatedDate }}</div>
            <div class="icrs-filters-label filters-label">
              Sorted By:
            </div>
            <div class="name">{{ getSortByText(hoveredItem) }}</div>
            <template v-if="getAppliedFiltersCount(hoveredItem)">
              <div class="icrs-filters-label filters-label">
                Applied Filters:
              </div>
              <div class="mt-xxs">
                <div class="badge-wrapper w-100">
                  <BadgeDropdown
                    v-for="group in hoveredItemAppliedFilterGroups"
                    :key="`${group.name}-${hoveredItem.id}`"
                    :text="`${group.name} (${group.items.length})`"
                  >
                    <div
                      class="filter-dropdown"
                      slot="dropdown"
                    >{{ getFilterText(group) }}</div>
                  </BadgeDropdown>
                  <SearchWithinBadge
                    v-for="text in hoveredItemSearchWithin"
                    :key="text"
                    :text="text"
                  />
                  <SearchWithinBadge
                    v-for="date in hoveredItemRangeQuery.dateFilters"
                    :key="date.gte || date.lte"
                    :text="date.gte || date.lte"
                    :name="date.gte ? 'Modified Date: from' : 'Modified Date: to'"
                  />
                </div>
              </div>
            </template>

            <button
              @click="handleSavedSearchClick(hoveredItem)"
              class="btn btn-primary"
              style="margin-top: 16px"
            >Perform This Saved Search</button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container"
      v-if="mode === modes.EDIT"
    >
      <ValidationObserver v-slot="{ invalid }">
        <div class="row">
          <p style="margin-bottom: 0">
            <strong>Note:</strong> When editing the saved search, you will only be editing the title of the saved search.
          </p>
        </div>
        <div class="row">
          <ValidationProvider
            name="Name"
            rules="required"
            v-slot="{ errors }"
            style="width: 100%"
          >
            <input
              ref="nameInput"
              type="text"
              v-model="editedItem.name"
              class="saved-search-form__input"
              :class="{ 'saved-search-form__input--error': errors.length }"
            >
            <span class="saved-search-form__error">{{ errors[0] }}</span>
          </ValidationProvider>

          <span
            v-if="icrsEditedFailValidation"
            class="saved-search-form__error"
          >{{ icrsFailValidationMessage }}</span>
        </div>
        <div class="row icrs-save-actions">
          <b-button
            @click="handleSaveEdit"
            variant="primary"
            :disabled="!isEditedTitleChanged || invalid || isSubmitting || icrsEditedFailValidation"
          >
            Save New Title
          </b-button>
          <b-button
            @click.stop="handleCancelEdit"
            variant="outline-primary"
            style="margin-left: 5px"
          >Cancel</b-button>
        </div>
      </ValidationObserver>
    </div>

    <div
      class="container"
      v-if="mode === modes.CREATE"
    >
      <ValidationObserver v-slot="{ invalid }">
        <div class="row">
          <p style="margin-bottom: 0">
            <strong>Note:</strong> When saving this search, you will be saving the search query, the filters applied as well as the "search within" terms applied.
          </p>
        </div>
        <div class="row">
          <ValidationProvider
            name="Name"
            rules="required"
            v-slot="{ errors }"
            style="width: 100%"
          >
            <input
              ref="nameInput"
              v-model="savedSearchName"
              placeholder="e.g. High Court Head Injuries Cases"
              type="text"
              class="saved-search-form__input"
              :class="{ 'saved-search-form__input--error': errors.length }"
            >
          </ValidationProvider>
          <span
            v-if="icrsCreatedFailValidation"
            class="saved-search-form__error"
          >{{ icrsFailValidationMessage }}</span>
          <span
            v-if="isSearchQueryEmpty"
            class="saved-search-form__error"
          >No search query found, please re-enter your query.</span>
          <span
            v-if="isSavedFailed"
            class="saved-search-form__error"
          >{{ failureMessage }}</span>
        </div>
        <div class="row icrs-save-actions">
          <b-button
            @click="handleSaveSearch"
            variant="primary"
            :disabled="invalid || isSubmitting || icrsCreatedFailValidation"
          >Save Current Search</b-button>
          <b-button
            @click.stop="handleCancelCreate"
            variant="outline-primary"
            style="margin-left: 5px"
          >Cancel</b-button>
        </div>
      </ValidationObserver>
    </div>

    <div
      class="container"
      v-if="mode === modes.CONFIRM_REMOVAL"
    >
      <div class="row">
        <p style="margin-bottom: 0">
          Are you sure that you want to delete this saved search? This action cannot be undone.
        </p>
      </div>
      <div class="row icrs-save-actions">
        <b-button
          @click="handleDeleteSearch"
          variant="primary"
          :disabled="isSubmitting"
        >Confirm</b-button>
        <b-button
          @click.stop="handleCancelDelete"
          variant="outline-primary"
          style="margin-left: 5px"
        >Cancel</b-button>
      </div>
    </div>
    <p
      id="icrsBaseUrlElement"
      style="display:none"
    >{{icrsBaseUrl}}</p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import _groupBy from 'lodash/groupBy';
import _cloneDeep from 'lodash/cloneDeep';
import SearchWithinBadge from '@/modules/search-within/SearchWithinBadge.vue';
import SelfTooltip from '@/components/selfTooltip/SelfTooltip.vue';
import SearchConstants from '@/constants/search-service-constants';
import { formatDateRangeString } from '@/helpers/promoted-content-helpers';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { createBadgeDropdownGroups } from '../../helpers/applied-filters';
import { formatSlashDateDayMonthYear, formatDateDayMonthYear } from '../../helpers/date-filters';

extend('required', {
  ...required,
  message: '{_field_} cannot be empty',
});

const SAVED_SEARCH_MODES = {
  LIST: 'list',
  EDIT: 'edit',
  CONFIRM_REMOVAL: 'confirm_removal',
  CREATE: 'create',
};

export default {
  components: {
    SearchWithinBadge,
    ValidationObserver,
    ValidationProvider,
    LoadingSpinner,
    SelfTooltip,
  },

  props: {
    position: {
      type: String,
      default: 'left',
    },
    icrsDisplayMode: {
      type: String,
      default: 'list',
    },
  },

  data () {
    return {
      savedSearchName: '',
      initialEditedSaveSearchName: '',
      hoveredItem: null,
      editedItem: null,
      originalEditedItemName: '',
      hoveredItemAppliedFilterGroups: [],
      hoveredItemSearchWithin: [],
      hoveredItemRangeQuery: {
        dateFilters: null,
        promotedContentFilters: null,
      },
      beingRemovedItem: null,
      isSubmitting: false,
      isSaved: false,
      isDeleted: false,
      isCopied: false,
      isEdited: false,
      mode: SAVED_SEARCH_MODES.LIST,
      dropdownBound: {},
      hasUnreadNewResults: false,
      icrsCreatedDate: '',
      icrsBaseUrl: '',
      dismissCountDown: 0,
      icrsFailValidation: false,
      icrsFailValidationMessage: 'Saved title cannot include "[" or "]".',
      isSearchQueryEmpty: false,
      isSavedSearchesLoading: false,
      failureMessage: '',
      isSavedFailed: false,
    };
  },
  computed: {
    ...mapGetters('searchService', [
      'savedSearches',
      'currentQuery',
      'filterGroupConfigs',
    ]),
    ...mapGetters('authService', {
      user: 'getUser',
    }),
    ...mapGetters('icrs', ['allGuidedCategories']),

    isListShowing () {
      return this.mode === SAVED_SEARCH_MODES.LIST;
    },

    headerText () {
      return `Saved Searches (${this.savedSearches.length})`;
    },

    modes () {
      return SAVED_SEARCH_MODES;
    },

    isEditedTitleChanged () {
      if (!this.editedItem) return false;
      return this.editedItem.name !== this.initialEditedSaveSearchName;
    },
    icrsCreatedFailValidation () {
      if (!this.savedSearchName) return false;
      if (this.checkVaidationFail(this.savedSearchName)) {
        return true;
      }
      return false;
    },
    icrsEditedFailValidation () {
      if (!this.editedItem) return false;
      if (this.checkVaidationFail(this.editedItem.name)) {
        return true;
      }
      return false;
    },
    isPromotedContentFilterApplied () {
      let query = this.hoveredItem.request.topicsQuery.must;
      query = _groupBy(query, 'field');
      return query[SearchConstants.PROMOTED_CONTENT_FIELDS.FILTER] !== undefined;
    },
  },

  mounted () {
    this.mode = this.icrsDisplayMode;
    this.isSavedSearchesLoading = true;
    this.getSavedSearches().then(() => {
      this.setHasUnreadNewResults();
    }).finally(() => {
      this.isSavedSearchesLoading = false;
    });
  },

  watch: {
    icrsDisplayMode (newval) {
      this.mode = newval;
    },
    savedSearchName () {
      this.isSearchQueryEmpty = false;
    },
  },

  methods: {
    ...mapActions('searchService', [
      'getSavedSearches',
      'createSavedSearch',
      'updateSavedSearch',
      'removeSavedSearch',
      'searchForSavedSearch',
      'setSavedSearchFromHomePage',
    ]),

    ...mapActions('searchService/newResults', [
      'searchForNewDocuments',
      'resetNewSearchResults',
    ]),

    ...mapActions('icrs', [
      'setGuidedSearch',
      'setDropdownGuidedTag',
      'setActiveGuidedPrefix',
      'setSelectedLevel1',
      'setSelectedLevel2',
      'setSelectedLevel3',
      'setLevel2CategoriesForSelectedLevel1',
      'setLevel3CategoriesForSelectedLevel2',
      'setAdvancedSearchSelectedLevel',
    ]),

    getFilterText (group) {
      return group.items.map(item => item.value).join(', ');
    },
    checkVaidationFail (title) {
      if (title.includes('[') || title.includes(']')) {
        return true;
      }
      return false;
    },
    getSortByText (item) {
      const { sortBy } = item.request;
      const sortByOptions = SearchConstants.SORT_BY_OPTIONS;
      if (sortBy) {
        if (sortBy.field && sortBy.field === 'lastModifiedDate') {
          if (sortBy.order === 'desc') {
            return sortByOptions.find(option => option.value === 'latestModifiedDate').text;
          }
          return sortByOptions.find(option => option.value === 'earliestModifiedDate').text;
        }
        if (sortBy.field && sortBy.field === 'tags.dateAssessment_s') {
          if (sortBy.order === 'desc') {
            return sortByOptions.find(option => option.value === 'latestAssessmentDate').text;
          }
          return sortByOptions.find(option => option.value === 'earliestAssessmentDate').text;
        }
        if (sortBy.field && sortBy.field === 'tags.injuryFinalAwardedAmount_s') {
          if (sortBy.order === 'desc') {
            return sortByOptions.find(option => option.value === 'highestInjuryAmount').text;
          }
          return sortByOptions.find(option => option.value === 'lowestInjuryAmount').text;
        }
      }
      return sortByOptions.find(option => option.value === 'relevance').text;
    },

    handleDropdownShow (rect) {
      const {
        top, right, bottom, left, width, height, x, y,
      } = rect;
      const bound = {
        top, right, bottom, left, width, height, x, y,
      };
      this.dropdownBound = bound;
      this.highlightFirstOption();
    },

    highlightFirstOption () {
      const firstSavedSearch = this.savedSearches[0];
      if (!firstSavedSearch) return;
      this.handleHoverItem(firstSavedSearch);
    },

    handleDropdownHide () {
      this.resetMessage();
      this.showList();
    },
    getQueryCode (requestQuery) {
      const firstQuoteIndex = requestQuery.indexOf('"');
      const secondQuoteIndex = requestQuery.indexOf('"', firstQuoteIndex + 1);
      const queryCode = requestQuery.substring(firstQuoteIndex + 1, secondQuoteIndex);

      return queryCode;
    },

    handleSelectedLevel (query) {
      if (query.includes('tags.level3')) {
        const codeLevel3 = this.getQueryCode(query);

        const foundLevel3 = this.allGuidedCategories.level3.find(item => item.code === codeLevel3);
        const foundLevel2 = this.allGuidedCategories.level2.find(item => item.code === foundLevel3.parentCode);
        const foundLevel1 = this.allGuidedCategories.level1.find(item => item.code === foundLevel2.parentCode);

        this.setSelectedLevel1(foundLevel1);
        this.setSelectedLevel2(foundLevel2);
        this.setSelectedLevel3(foundLevel3);
        this.setLevel2CategoriesForSelectedLevel1(
          [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
        );
        this.setLevel3CategoriesForSelectedLevel2(
          [{ code: 'All' }, ...this.allGuidedCategories.level3.filter(item => item.parentCode === foundLevel2.code)],
        );

        this.setAdvancedSearchSelectedLevel({
          level1: foundLevel1,
          level2: foundLevel2,
          level3: foundLevel3,
          level2ForSelectedLevel1: [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
          level3ForSelectedLevel2: [{ code: 'All' }, ...this.allGuidedCategories.level3.filter(item => item.parentCode === foundLevel2.code)],
        });
      } else if (query.includes('tags.level2')) {
        const codeLevel2 = this.getQueryCode(query);

        const foundLevel2 = this.allGuidedCategories.level2.find(item => item.code === codeLevel2);
        const foundLevel1 = this.allGuidedCategories.level1.find(item => item.code === foundLevel2.parentCode);

        this.setSelectedLevel1(foundLevel1);
        this.setSelectedLevel2(foundLevel2);
        this.setSelectedLevel3({ code: 'All' });
        this.setLevel2CategoriesForSelectedLevel1(
          [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
        );
        this.setLevel3CategoriesForSelectedLevel2(
          [{ code: 'All' }, ...this.allGuidedCategories.level3.filter(item => item.parentCode === foundLevel2.code)],
        );

        this.setAdvancedSearchSelectedLevel({
          level1: foundLevel1,
          level2: foundLevel2,
          level3: { code: 'All' },
          level2ForSelectedLevel1: [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
          level3ForSelectedLevel2: [{ code: 'All' }, ...this.allGuidedCategories.level3.filter(item => item.parentCode === foundLevel2.code)],
        });
      } else if (query.includes('tags.level1')) {
        const codeLevel1 = this.getQueryCode(query);
        const foundLevel1 = this.allGuidedCategories.level1.find(item => item.code === codeLevel1);

        this.setSelectedLevel1(foundLevel1);
        this.setSelectedLevel2({ code: 'All' });
        this.setSelectedLevel3({ code: 'All' });
        this.setLevel2CategoriesForSelectedLevel1(
          [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
        );
        this.setLevel3CategoriesForSelectedLevel2(
          [{ code: 'All' }],
        );

        this.setAdvancedSearchSelectedLevel({
          level1: foundLevel1,
          level2: { code: 'All' },
          level3: { code: 'All' },
          level2ForSelectedLevel1: [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
          level3ForSelectedLevel2: [{ code: 'All' }],
        });
      } else {
        this.setSelectedLevel1({ code: 'All' });
        this.setSelectedLevel2({ code: 'All' });
        this.setSelectedLevel3({ code: 'All' });
        this.setLevel2CategoriesForSelectedLevel1([{ code: 'All' }]);
        this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);

        this.setAdvancedSearchSelectedLevel({
          level1: { code: 'All' },
          level2: { code: 'All' },
          level3: { code: 'All' },
          level2ForSelectedLevel1: [{ code: 'All' }],
          level3ForSelectedLevel2: [{ code: 'All' }],
        });
      }
    },

    handleSavedSearchClick (savedSearch) {
      // Clear existing nature of injury stuff
      this.setGuidedSearch(true);
      this.setDropdownGuidedTag('');
      this.setActiveGuidedPrefix(0);
      this.handleSelectedLevel(savedSearch.request.query);
      this.setSavedSearchFromHomePage(true);

      this.searchForSavedSearch(savedSearch.request);
      if (savedSearch.newResults > 0) {
        this.searchForNewDocuments(savedSearch);
      } else {
        this.resetNewSearchResults();
      }
      document.body.click();
      this.$bvModal.hide('savedSearchesModal');
      if (this.$router.currentRoute.path !== '/icrs-search') {
        this.$router.push({ path: '/icrs-search' });
      }
    },

    getAppliedFiltersCount (savedSearch) {
      const { request } = savedSearch;
      const searchWithinCount = Array.isArray(request.searchWithin) ? request.searchWithin.length : 0;
      const savedSearchCount = this.getTopicQueryCount(request.topicsQuery.must);

      const savedRangeQuery = this.getRangeQueryCount(request.rangeQuery);
      return searchWithinCount + savedSearchCount + savedRangeQuery;
    },

    getRangeQueryCount (rangeQuery) {
      const filteredRangeQuery = rangeQuery
        .filter(query => !(query.field === SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_FROM
          || query.field === SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_TO));

      return filteredRangeQuery.length;
    },

    getTopicQueryCount (topicsQuery) {
      const filteredTopicsQuery = topicsQuery
        .filter(query => query.field !== SearchConstants.PROMOTED_CONTENT_FIELDS.FILTER);

      return filteredTopicsQuery.length;
    },

    getPromotedContentText (text, savedSearch) {
      let formattedText = text;
      const { request } = savedSearch;

      const containPromotedContent = request.topicsQuery.must
        .find(query => query.field === SearchConstants.PROMOTED_CONTENT_FIELDS.FILTER);

      if (containPromotedContent !== undefined) {
        if (formattedText !== ' ') {
          formattedText += ' | ';
        }
        formattedText += 'Promoted content history applied';
      }

      return formattedText;
    },

    getAppliedFiltersCountText (savedSearch) {
      const count = this.getAppliedFiltersCount(savedSearch);
      let text = ' ';
      if (count) {
        if (count === 1) {
          text = `${count} Applied Filter`;
        } else {
          text = `${count} Applied Filters`;
        }
      }

      text = this.getPromotedContentText(text, savedSearch);
      return text;
    },

    getPromotedContentLabel () {
      if (this.hoveredItemRangeQuery.promotedContentFilters.length > 0) {
        const promotedContentFiltersCopy = _cloneDeep(this.hoveredItemRangeQuery.promotedContentFilters);
        const promotedContentFiltersGroup = _groupBy(promotedContentFiltersCopy, 'field');

        /**
         * promote period >= start date (metadata.promote_to) and <= end date (metadata.promote_from)
         */
        const promotedStartDateObj = promotedContentFiltersGroup[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_TO][0];
        const promotedEndDateObj = promotedContentFiltersGroup[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_FROM][0];

        return formatDateRangeString(promotedStartDateObj.gte, promotedEndDateObj.lte);
      }

      return SearchConstants.PROMOTED_CONTENT_FIELDS.ALL_PROMOTED_CONTENT;
    },

    handleCreate () {
      this.savedSearchName = this.currentQuery;
      this.resetMessage();
      this.mode = SAVED_SEARCH_MODES.CREATE;
      this.$emit('saveCurrentMode', this.mode);
      this.$nextTick(() => {
        this.$refs.nameInput.select();
      });
    },

    handleCancelCreate () {
      this.savedSearchName = '';
      this.showList();
    },

    handleSaveSearch () {
      if (!this.currentQuery) {
        this.isSearchQueryEmpty = true;
        return;
      }

      this.isSubmitting = true;
      this.resetMessage();

      const currentDate = (new Date()).toString().split(' GMT')[0];
      this.createSavedSearch(`${this.savedSearchName.trim()} [${currentDate}]`)
        .then(() => {
          this.savedSearchName = '';
          this.isSaved = true;
          this.dismissCountDown = 10;
          this.showList();
        })
        .catch((error) => {
          this.isSavedFailed = true;
          this.failureMessage = error.response.data || '';
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    resetMessage () {
      this.dismissCountDown = 0;
      this.isDeleted = false;
      this.isSaved = false;
      this.isCopied = false;
      this.isEdited = false;
      this.isSavedFailed = false;
      this.failureMessage = '';
    },

    handleEdit (item) {
      this.resetMessage();
      this.editedItem = { ...item };
      this.mode = SAVED_SEARCH_MODES.EDIT;
      this.initialEditedSaveSearchName = item.name;
      this.$emit('saveCurrentMode', this.mode);
      this.$nextTick(() => {
        this.$refs.nameInput.select();
        this.$refs.nameInput.focus();
      });
    },

    handleSaveEdit () {
      this.isSubmitting = true;
      const editedItem = {
        ...this.editedItem,
        name: `${this.editedItem.name.trim()} [${this.icrsCreatedDate}]`,
      };
      this.updateSavedSearch(editedItem)
        .then(() => {
          this.showList();
          this.editedItem = null;
          this.hoveredItem = null;
          this.isEdited = true;
          this.dismissCountDown = 10;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    handleCancelDelete () {
      this.beingRemovedItem = null;
      this.showList();
    },

    handleDelete (item) {
      this.resetMessage();
      this.beingRemovedItem = item;
      this.mode = SAVED_SEARCH_MODES.CONFIRM_REMOVAL;
      this.$emit('saveCurrentMode', this.mode);
    },

    handleDeleteSearch () {
      this.isSubmitting = true;
      this.resetMessage();
      this.removeSavedSearch(this.beingRemovedItem.request.criteriaId)
        .then(() => {
          this.isDeleted = true;
          this.dismissCountDown = 10;
          this.showList();
          this.setHasUnreadNewResults();
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    isItemHovered (item) {
      return this.hoveredItem && this.hoveredItem.id === item.id;
    },

    getSearchWithinText (searchWithin) {
      return searchWithin.value;
    },

    handleHoverItem (item) {
      const itemName = item.name;
      const itemNameParts = itemName.split(' [');


      const formattedItem = { ...item };
      // eslint-disable-next-line prefer-destructuring
      formattedItem.name = itemNameParts[0];
      if (itemNameParts.length > 1) {
        this.icrsCreatedDate = itemNameParts[1].replace(']', '');
      } else {
        this.icrsCreatedDate = '';
      }

      this.hoveredItem = formattedItem;
      this.hoveredItemAppliedFilterGroups = createBadgeDropdownGroups(
        formattedItem.request.topicsQuery,
        this.filterGroupConfigs,
      );
      // handle range query
      this.hoveredItemRangeQuery = this.buildRangeQuery(formattedItem.request.rangeQuery);
      const hasSearchWithin = Boolean(formattedItem.request.searchWithin);
      this.hoveredItemSearchWithin = hasSearchWithin
        ? formattedItem.request.searchWithin.map(this.getSearchWithinText)
        : [];
    },

    handleCancelEdit () {
      this.editedItem.name = this.initialEditedSaveSearchName;
      this.initialEditedSaveSearchName = '';
      this.editedItem = null;
      this.showList();
    },

    handleShare (item) {
      this.resetMessage();
      const criteriaId = item.request.criteriaId ? item.request.criteriaId : '';
      this.icrsBaseUrl = this.getShareUrl(criteriaId);
      this.copyShareUrl(this.icrsBaseUrl);
    },
    getShareUrl (criteriaId) {
      let currUrl = window.location.href.split('#')[0];
      const hasTrailingSpace = currUrl[currUrl.length - 1] === '/';
      if (hasTrailingSpace) {
        currUrl = currUrl.slice(0, currUrl.length - 1);
      }
      /** if the current URL already contains a criteria id
         * (e.g. from a previously triggered saved search url from an email),
         * that criteriaId will be sliced off to get the baseUrl * */
      const criteriaStringIndex = currUrl.indexOf('/#/icrs-search?criteria=');
      const baseUrl = criteriaStringIndex === -1 ? currUrl : currUrl.slice(0, criteriaStringIndex);
      return `${baseUrl}/#/icrs-search?criteria=${criteriaId}`;
    },

    copyShareUrl (url) {
      // if (document.documentMode) {
      //   // This set of codes works on IE, Chrome and Firefox.
      //   // For Edge, the formatting like underlines and bullet points
      //   // are gone with this set of codes.
      //   const range = document.createRange();
      //   range.selectNode(document.getElementById('icrsBaseUrlElement'));
      //   window.getSelection().removeAllRanges(); // clear current selection
      //   window.getSelection().addRange(range); // to select text
      //   document.execCommand('copy');
      //   window.getSelection().removeAllRanges();// to deselect
      // } else {
      //   // This set of codes works on Edge, Chrome and Firefox.
      //   // For IE, not sure why it doesn't work. Probably because of the listeners involved.
      //   document.addEventListener('copy', this.listener);
      //   document.execCommand('copy');
      //   document.removeEventListener('copy', this.listener);
      // }
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(url);
      }
      this.isCopied = true;
      this.dismissCountDown = 10;
    },
    listener (e) {
      e.clipboardData.setData('text/html', this.icrsBaseUrl);
      e.clipboardData.setData('text/plain', this.icrsBaseUrl);
      e.preventDefault();
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
      if (this.dismissCountDown === 0) {
        this.resetMessage();
      }
    },
    showList () {
      this.mode = SAVED_SEARCH_MODES.LIST;
      this.$emit('saveCurrentMode', SAVED_SEARCH_MODES.LIST);
    },

    setHasUnreadNewResults () { this.hasUnreadNewResults = !!this.savedSearches.find(s => (s.newResults > 0)); },

    /**
     * @typedef {import('@typings/search').RangeQuery} RangeQuery
     * @param {RangeQuery[]} rangeQuery
     */
    buildRangeQuery (rangeQuery) {
      const rangeQueryCopy = _cloneDeep(rangeQuery);

      const formatedRangeQuery = {
        dateFilters: null,
        promotedContentFilters: null,
      };

      let dateFilters = [];

      const rangeQueries = _groupBy(rangeQueryCopy, 'field');

      if (rangeQueries.lastModifiedDate) {
        dateFilters = rangeQueries.lastModifiedDate.map(
          /**
           * @param {RangeQuery} element
           */
          (element) => {
            const date = element.gte ? 'gte' : 'lte';
            if (element[date]) {
              return {
                ...element,
                [date]: element[date].includes('/')
                  ? formatSlashDateDayMonthYear(element[date]) // todo can remove when backend is fixed
                  : formatDateDayMonthYear(element[date]),
              };
            }
            return { ...element };
          },
        );
      }

      formatedRangeQuery.dateFilters = dateFilters;

      const promotedContentFilters = [];

      /**
       * promote period >= start date (metadata.promote_to) and <= end date (metadata.promote_from)
       */
      if (rangeQueries[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_TO]
        && rangeQueries[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_FROM]) {
        /**
        * @type {RangeQuery}
        */
        const promoteStartDateObj = rangeQueries[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_TO].reduce(item => item);
        /**
         * @type {RangeQuery}
         */
        const promoteEndDateObj = rangeQueries[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_FROM].reduce(item => item);

        if (promoteStartDateObj) {
          promoteStartDateObj.gte = formatDateDayMonthYear(promoteStartDateObj.gte);
          promotedContentFilters.push(promoteStartDateObj);
        }

        if (promoteEndDateObj) {
          promoteEndDateObj.lte = formatDateDayMonthYear(promoteEndDateObj.lte);
          promotedContentFilters.push(promoteEndDateObj);
        }
      }

      formatedRangeQuery.promotedContentFilters = promotedContentFilters;

      return formatedRangeQuery;
    },

  },
};
</script>

<style lang="scss" scoped>
.save-search {
  display: inline-block;
}

.saved-search-items {
  max-height: 296px;
  display: flex;
  min-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;

  button:disabled {
    cursor: not-allowed;
  }

  &__placeholder {
    padding: $gap-xs 0;
    width: 100%;
    text-align: center;
    padding-top: 28px;
    padding-bottom: 74px;

    &-content {
      display: inline-block;
    }
  }

  &__list {
    width: 328px;
    min-height: 0;
    height: 296px;
    overflow-y: auto;
    border-right: 1px solid $blue-gray-100;
    padding: $gap-xs 0;
    white-space: nowrap;
    padding-right: 10px;
  }

  &__detail {
    flex: 1 1 0%;
    padding: 16px 14px;
    overflow-y: auto;

    .name {
      white-space: break-spaces;
      font-size: 14px;
      color: $blue-gray-600;
      line-height: 20px;
    }

    .sort-by {
      color: $blue-gray-500;
      font-size: 12px;
      line-height: 16px;
      margin-top: 8px;
    }

    .filters-label {
      margin-top: 16px;
      font-weight: $weight-semi-bold;
      font-size: 12px;
      line-height: 16px;
      color: $blue-gray-600;
    }
  }
}

.saved-search-item {
  padding: $gap-xs;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  .details {
    width: 200px;
  }
  .actions {
    display: none;
    top: 0;
    bottom: 0;
    right: 0;
    width: 124px;
    background-color: $blue-200;
    align-items: center;
    justify-content: space-around;

    button {
      background: transparent;
      border: 0;
      color: $blue-gray-400;
      font-weight: $weight-bold;

      &:hover {
        color: $blue-400;
      }
    }
  }

  &--hovered {
    background-color: $blue-200;

    .actions {
      display: flex;
    }
  }

  &__name {
    color: $blue-gray-600;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: $font-md;
    margin-right: $gap-xxs;
    max-width: 100%;

    &/deep/ .badge {
      padding: 4px 8px;
      line-height: 12px;
    }
  }

  &__options {
    color: $blue-gray-400;
    margin: 0;
    margin-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-sm;
  }
}

.share-wrapper {
  position: relative;
  .share-text {
    white-space: nowrap;
  }
}

.saved-message,
.copied-message,
.edited-message,
.deleted-message {
  color: $green-500;
  font-weight: $weight-normal;
  font-size: $font-md;
}

.saved-search-form-wrapper {
  width: 100%;
}

.badge-wrapper {
  margin-bottom: 4px;
  margin-right: 4px;
  display: inline-block;
}

.filter-dropdown {
  padding: $gap-xs;
}

.saved-search-form {
  padding-left: 0;
  padding-right: 0;
  padding-top: 24px;
  padding-bottom: 16px;
  display: flex;
  align-items: flex-start;

  .input-wrapper {
    flex: 1 1 0%;
  }

  .confirm-removal-text {
    white-space: initial;
    line-height: 20px;
    width: 100%;
  }

  .confirm-removal-text + .saved-search-form__actions {
    min-width: 178px;
  }

  &/deep/ .button {
    height: 36px;
  }

  &__error {
    margin-top: 4px;
    line-height: 16px;
    font-size: 12px;
    color: $red-500;
  }

  &__actions {
    display: flex;
    height: 36px;
  }

  &__input {
    border-radius: $radius-4;
    height: 36px;
    font-size: $font-md;
    padding: $gap-xxs $gap-xs;
    border: 1px solid $blue-500;
    flex: 1 1 0%;
    width: 100%;
    display: block;

    &--error {
      display: block;
      border-color: $red-500;
    }
  }

  button {
    padding: 8px 20px;
    border: 0;

    &.primary {
      color: $blue-500;

      &:disabled {
        color: $blue-300;
      }
    }

    &.secondary {
      background: transparent;
      color: $blue-gray-300;
      height: 36px;
    }
  }
}
</style>
