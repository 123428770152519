class FilterConfigItem {
  constructor (data = {}) {
    this.className = !_.isUndefined(data.className) ? data.className : null;
    this.classLabel = !_.isUndefined(data.classLabel) ? data.classLabel : null;
    this.classId = !_.isUndefined(data.classId) ? data.classId : null;
    this.groupName = !_.isUndefined(data.groupName) ? data.groupName : null;
    this.filters = !_.isUndefined(data.filters) ? data.filters.map(filter => new FilterConfigItem(filter)) : [];
  }
}

export default FilterConfigItem;
