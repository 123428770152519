import SearchParameter from '@/services/models/search/search-parameter.model';
import constants from '@/constants/search-service-constants';
import createState from '../state';
import getters from '../getters';

jest.mock('../../../../services/icrs-service', () => ({
  getFiltersTopicTitleDisplayOrder: jest.fn(),
}));

describe('search-core vuex getters test', () => {
  test('currentQuery', () => {
    let state = createState();
    expect(getters.currentQuery(state)).toBe(undefined);

    state = createState({ currentQuery: 'Singapore' });
    expect(getters.currentQuery(state)).toBe('Singapore');
  });

  test('currentPage', () => {
    let state = createState();
    expect(getters.currentPage(state)).toBe(1);

    state = createState({ currentPage: 2 });
    expect(getters.currentPage(state)).toBe(2);
  });

  test('itemsPerPage', () => {
    let state = createState();
    expect(getters.itemsPerPage(state)).toBe(10);

    state = createState({ itemsPerPage: 20 });
    expect(getters.itemsPerPage(state)).toBe(20);
  });

  test('allSourceDocCount', () => {
    let state = createState();
    expect(getters.allSourceDocCount(state)).toBe(0);
    const categories = {
      total: 100,
    };
    state = createState({ categories });
    expect(getters.allSourceDocCount(state)).toBe(100);
  });

  test('corrections', () => {
    const corrections = ['correction a', 'correction b'];
    const state = createState({ corrections });

    expect(getters.corrections(state)).toEqual(corrections);
  });

  test('suggestions', () => {
    const suggestions = ['suggestion a', 'suggestion b'];
    const state = createState({ suggestions });

    expect(getters.suggestions(state)).toEqual(suggestions);
  });

  test('savedSearches', () => {
    const savedSearches = [
      { id: '123', name: 'saved search a', shared: false },
      { id: '456', name: 'saved search b', shared: false },
    ];
    const state = createState({ savedSearches });

    expect(getters.savedSearches(state)).toEqual(savedSearches);
  });

  test('selectedSource', () => {
    let state = createState();

    expect(getters.selectedSource(state)).toEqual(undefined);
    state = createState({ selectedSource: ['SSO'] });
    expect(getters.selectedSource(state)).toEqual(['SSO']);
  });

  describe('searchCategories', () => {
    test('initial value should be empty array', () => {
      const state = createState();

      expect(getters.searchCategories(state)).toEqual([]);
    });

    test('should be empty array if aggregation has no any items', () => {
      const state = createState({
        categories: {
          aggregations: [],
        },
      });

      expect(getters.searchCategories(state)).toEqual([]);
    });

    test('should return filter group items', () => {
      const bucket = [
        { key: 'CASA', docCount: 1 },
        { key: 'SSO', docCount: 100 },
      ];
      const state = createState({
        categories: {
          aggregations: [
            { name: 'caa', docCount: 10, bucket },
          ],
        },
      });

      expect(getters.searchCategories(state)).toEqual([
        { key: 'CASA', docCount: 1, label: 'CASA (1)' },
        { key: 'SSO', docCount: 100, label: 'SSO (100)' },
      ]);
    });
  });

  test('appliedSort', () => {
    let state = createState();
    expect(getters.appliedSort(state)).toEqual({});

    const appliedSort = {
      field: 'lastModifiedDate',
      order: 'desc',
    };
    state = createState({ appliedSort });
    expect(getters.appliedSort(state)).toEqual(appliedSort);
  });

  test('filterGroups', () => {
    let state = createState();

    expect(getters.filterGroups(state)).toEqual([]);
    state = createState({
      filterGroups: {
        data: [],
      },
    });
  });

  test('isFetchingFilterGroups', () => {
    let state = createState();

    expect(getters.isFetchingFilterGroups(state)).toEqual(false);
    state = createState({
      filterGroups: {
        isFetching: true,
      },
    });
    expect(getters.isFetchingFilterGroups(state)).toEqual(true);
  });

  test('filterGroupConfigs', () => {
    const filterGroupConfigs = {
      caa: {
        displayName: 'CAA',
        displayed: true,
        field: 'tags.caa',
        showInCloud: true,
      },
    };
    const state = createState({ filterGroupConfigs });

    expect(getters.filterGroupConfigs(state)).toEqual(filterGroupConfigs);
  });

  test('currentSearchRequestParameter', () => {
    const currentSearchRequestParameter = new SearchParameter(
      constants.SEARCH_MODE.NEW,
      {},
      undefined,
      1,
      {},
      undefined,
      {},
      10,
      '124',
    );
    const state = createState({ currentSearchRequestParameter });

    expect(getters.currentSearchRequestParameter(state)).toEqual(currentSearchRequestParameter);
  });
});
