import Vue from 'vue';

export default {
  PAGE_TYPE: {
    ID_FORM: 'ID_FORM',
    PREVIEW_CONTENT: 'PREVIEW_CONTENT',
    NOT_FOUND: '404',
  },
  DISABLE_FORM: {
    ID: true,
    TITLE: false,
    PATH: false,
    CREATED_DATE: true,
    FEATURED: false,
    PRIORITY: false,
  },
  TEXT_INFO: {
    ID_FORM: 'Start by entering a document ID',
    PREVIEW_CONTENT: 'Preview Document Content',
    NOT_FOUND: 'Document Id provided does not exist \n or has been deleted',
  },
  MODELS_DEFAULT: {
    RESULT_PER_PAGE: 10,
    SEARCH_MODE: {
      NEW: 'NEW',
      SEARCH_WITHIN: 'SEARCH_WITHIN',
      FILTER: 'FILTER',
      SOURCE: 'SOURCE',
      PAGINATION: 'PAGINATION',
      SORTING: 'SORTING',
      AGGREGATION: 'AGGREGATION',
      SAVE_SEARCH: 'SAVE_SEARCH',
    },
  },
  DELETE_MODAL_TYPE: {
    TAG: 'Tag',
    METADATA: 'Metadata',
    DOCUMENT: 'Document',
  },

  getTabsConfig: () => Vue.prototype.$config.DocManager.tabs,
};
