import FilterGroup from '@/services/models/search/filter-group.model';
import _groupBy from 'lodash/groupBy';
import searchConstants from '../constants/search-service-constants';

/**
 * @param {import('@typings/search').Aggregation[]}     aggregations
 * @param {import('@typings/search').AggregationConfig} config
 */
export function getRelevantFilterGroups (aggregations, config) {
  return aggregations
    .filter((aggregation) => {
      const { displayed } = config[aggregation.name];
      return displayed && Array.isArray(aggregation.bucket);
    })
    .map((aggregation) => {
      // eslint-disable-next-line
      if (!aggregation.bucket) aggregation.bucket = [];

      return FilterGroup.create({
        ...aggregation,
        displayName: config[aggregation.name].displayName,
      });
    });
}

/**
 * @param {object} filter
 * @param {string} filter.groupName
 * @param {string} filter.key
 */
export function createFilterUniqueKey ({ groupName, key }) {
  return `${groupName}_${key}`;
}

function getSortingFunction (type) {
  switch (type) {
    case searchConstants.FILTER_SORT_BY_OPTIONS.MOST_TO_LEAST:
      return (a, b) => b.docCount - a.docCount;
    case searchConstants.FILTER_SORT_BY_OPTIONS.LEAST_TO_MOST:
      return (a, b) => a.docCount - b.docCount;
    case searchConstants.FILTER_SORT_BY_OPTIONS.A_TO_Z:
      return (a, b) => {
        if (a.key.split(" ; ").length > 1 && a.key.split(" ; ").length > 1) {
          return a.key.split(" ; ")[1].charCodeAt(0) - b.key.split(" ; ")[1].charCodeAt(0);
        }
        else {
          return a.key.charCodeAt(0) - b.key.charCodeAt(0);
        }
      }
    case searchConstants.FILTER_SORT_BY_OPTIONS.Z_TO_A:
      return (a, b) => {
        if (a.key.split(" ; ").length > 1 && a.key.split(" ; ").length > 1) {
          return b.key.split(" ; ")[1].charCodeAt(0) - a.key.split(" ; ")[1].charCodeAt(0);
        }
        else {
          return b.key.charCodeAt(0) - a.key.charCodeAt(0);
        }
      }
    default:
      throw new Error(`sort type is not recognized: ${type}`);
  }
}

export function sortFiltersBy (filters, type) {
  const sortFn = getSortingFunction(type);

  return filters.sort(sortFn);
}

const generateGroupId = (filterGroupConfig) => {
  if (filterGroupConfig.groupName !== null) {
    return filterGroupConfig.groupName.replaceAll(' ', '-').toLowerCase();
  }
  return filterGroupConfig.filters[0].classId;
};

const getFiltersByClassId = (filterGroupConfig, filterGroups) => {
  const filters = [];
  if (filterGroups) {
    filterGroupConfig.filters.forEach((filter) => {
      const found = filterGroups[filter.classId];
      if (found) {
        filters.push(found[0]);
      }
    });
  }

  return filters;
};

/**
 * @param {FilterGroup[]} filterGroups
 * @param {FilterConfigItem[]} groupConfig
 * @return {import('@typings/search-filter').FormattedFilterGroups}
 */
export function formatFiltersByGroup (groupConfig, filterGroups) {
  const groups = _groupBy(filterGroups, 'name');
  const formattedFilterGroups = [];
  if (groupConfig) {
    groupConfig.forEach((config) => {
      const filterGroup = {};
      filterGroup.groupName = config.groupName;
      filterGroup.groupId = generateGroupId(config);

      filterGroup.filters = getFiltersByClassId(config, groups);

      if (filterGroup.filters.length > 0 || config.groupName === null) {
        formattedFilterGroups.push(filterGroup);
      }
    });
  }

  return formattedFilterGroups;
}

/**
 *
 * @param {String[]} appliedFilters
 * @param {FilterGroup[]} filterGroups
 * @return Boolean
 */
export function getFilterGroupAccordianState (appliedFilters, filterGroups) {
  let open = false;

  if (appliedFilters.length > 0) {
    const { filters } = filterGroups;
    appliedFilters.some((appliedFilter) => {
      // eslint-disable-next-line no-unused-vars
      const [filterClass, indv] = appliedFilter.split('_');
      open = filters.some(filter => filter.name === filterClass);

      return open;
    });
  }
  return open;
}


export function getOriginAggregations (aggregations) {
  const result = [];
  if (aggregations) {
    aggregations.some((item) => {
      const itemLevel1 = [];
      item.documentAttributeValueCountPairs.some((itemChild) => {
        itemLevel1.push({ docCount: itemChild.count, key: itemChild.documentAttributeValue.stringValue });
        return false;
      });
      result.push({
        displayName: item.documentAttributeKey,
        name: item.documentAttributeKey,
        docCount: 0,
        bucket: itemLevel1,
      });
      return false;
    });
  }
  return result;
}
