export default class FilterGroup {
  /**
   * @param {object} aggregation
   * @param {string} aggregation.name
   * @param {number} aggregation.docCount
   * @param {import('./filter-item.model').default[]} aggregation.bucket
   * @param {string} aggregation.displayName
   */
  constructor (aggregation) {
    const {
      name,
      docCount,
      bucket,
      displayName,
    } = aggregation;
    this.name = name;
    this.docCount = docCount;
    this.items = bucket;
    this.displayName = displayName;
  }

  static create (aggregation) {
    return new FilterGroup(aggregation);
  }
}
