<template>
  <div
    id="filter-based-search"
    class="d-flex h-100 flex-column mx-5"
  >
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <b-button
          v-for="(alphabet, index) in this.alphabetTabs"
          :key="index"
          class="alphabet_button"
          size="sm"
          :variant="isAlphabetClicked(alphabet.alphabet) ? 'primary' : 'link'"
          :disabled="alphabet.disabled ? true : false"
          @click="handleButtonClick"
          :data-cy="`filter-based-alphabet-${index}`"
        >
          {{ alphabet.alphabet }}
        </b-button>
        <b-button
          v-show="this.selectedAlphabetTabs.length > 0"
          class="font-blue"
          variant="link"
          @click="handleResetFilter"
        >
          Reset filter
        </b-button>
      </div>
      <div class="float-right">
        <b-button
          v-show="this.selectedFilterGroupItems.length > 0"
          class="font-blue"
          variant="link"
          @click="handleUnselectAll"
        >
          Unselect all
        </b-button>

        <b-button
          variant="primary"
          @click="handleSearch"
          :disabled="this.selectedFilterGroupItems.length === 0 ? true : false"
          data-cy="filter-based-search-button"
        >
          Search ({{ this.selectedFilterGroupItems.length }})
        </b-button>
      </div>
    </div>
    <div class="checkboxes border p-3 mt-3 rounded">
      <span
        v-for="(element, index) in this.displayedFilterGroupItems"
        class="d-flex w-25"
        :key="index"
      >
        <Checkbox
          class="font-secondary text-md m-2"
          :value="element.applied"
          @change="handleCheckboxChange(element)"
          :data-cy="`filter-based-checkbox-${index}`"
        >{{ element.key }}</Checkbox>
      </span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { createAlphabetTabs } from './filter-based-search-helpers';

export default {
  data () {
    return {
      alphabetTabs: [],
      allFilterGroupsInfo: [],
      allFilterGroupItems: [],
      displayedFilterGroupItems: [],
      selectedAlphabetTabs: [],
      selectedFilterGroupItems: [],
    };
  },
  watch: {
    selectedAlphabetTabs (value) {
      if (value && this.selectedAlphabetTabs.length === 0) {
        this.displayedFilterGroupItems = this.allFilterGroupItems;
      }
    },
    inputIndividual (val) {
      if (val) {
        this.resetFilters();
        this.initialiseAllFilterGroups();
      }
    },
  },
  computed: {
    ...mapGetters('advancedSearch', [
      'inputIndividual',
      'selectedFilterGroup',
    ]),
  },
  async mounted () {
    this.initialiseAllFilterGroups();
  },
  methods: {
    ...mapActions('searchService', [
      'triggerSearchQuery',
    ]),
    initialiseAllFilterGroups () {
      this.allFilterGroupsInfo = this.selectedFilterGroup;
      this.initialiseAlphabetTabs();
      this.initialiseDisplayedFilterGroupItems();
    },
    async initialiseAlphabetTabs () {
      this.alphabetTabs = await createAlphabetTabs();
      for (let index = 0; index < this.allFilterGroupsInfo.items.length; index += 1) {
        const item = this.allFilterGroupsInfo.items[index];
        const firstAlphabet = item.key.substring(0, 1).toUpperCase();
        for (let tabIndex = 0; tabIndex < this.alphabetTabs.length; tabIndex += 1) {
          const tabElement = this.alphabetTabs[tabIndex];
          if (tabElement.alphabet === firstAlphabet) {
            tabElement.disabled = false;
          }
        }
      }
    },
    initialiseDisplayedFilterGroupItems () {
      for (let index = 0; index < this.allFilterGroupsInfo.items.length; index += 1) {
        const item = this.allFilterGroupsInfo.items[index];
        this.allFilterGroupItems.push({
          docCount: item.docCount,
          key: item.key,
          groupName: this.allFilterGroupsInfo.name,
          applied: false,
        });
      }
      this.displayedFilterGroupItems = this.allFilterGroupItems;
    },
    handleButtonClick (event) {
      const selectedAlphabet = event.target.innerText.trim();
      const found = this.selectedAlphabetTabs.find(element => element === selectedAlphabet);
      if (found === undefined) { // Alphabet tab is selected
        this.selectedAlphabetTabs.push(selectedAlphabet);
      } else { // Alphabet tab is unselected
        const selectedAlphabetIndex = this.selectedAlphabetTabs.indexOf(selectedAlphabet);
        this.selectedAlphabetTabs.splice(selectedAlphabetIndex, 1);
      }
      this.displayedFilterGroupItems = [];
      for (let index = 0; index < this.allFilterGroupItems.length; index += 1) {
        const firstAlphabet = this.allFilterGroupItems[index].key.substring(0, 1).toUpperCase();
        if (this.selectedAlphabetTabs.includes(firstAlphabet)) {
          this.displayedFilterGroupItems.push(this.allFilterGroupItems[index]);
        }
      }
    },
    isAlphabetClicked (alphabet) {
      return this.selectedAlphabetTabs.includes(alphabet);
    },
    handleCheckboxChange (element) {
      let currElement = this.displayedFilterGroupItems.find(item => item.key === element.key); // eslint-disable-line
      const currAppliedState = currElement.applied;
      currElement.applied = !currAppliedState;
      if (currElement.applied) {
        this.selectedFilterGroupItems.push(currElement);
      } else {
        const currIndex = this.selectedFilterGroupItems.indexOf(currElement);
        this.selectedFilterGroupItems.splice(currIndex, 1);
      }
    },
    handleResetFilter () {
      this.selectedAlphabetTabs = [];
      this.displayedFilterGroupItems = this.allFilterGroupItems;
    },
    handleUnselectAll () {
      const allItems = this.allFilterGroupItems;
      const displayedItems = this.displayedFilterGroupItems;
      allItems.forEach((currItem) => {
        if (currItem.applied === true) {
          currItem.applied = false; // eslint-disable-line
        }
      });
      displayedItems.forEach((currDisplayedItem) => {
        if (currDisplayedItem.applied) {
          currDisplayedItem.applied = false; // eslint-disable-line
        }
      });
      this.selectedFilterGroupItems = [];
    },
    handleSearch () {
      let currSearchQuery = '';
      const currClass = this.selectedFilterGroup.name;
      for (let index = 0; index < this.selectedFilterGroupItems.length; index += 1) {
        currSearchQuery += (`${currClass}:"${this.selectedFilterGroupItems[index].key}"`);
        if (index !== this.selectedFilterGroupItems.length - 1) {
          currSearchQuery += ' OR ';
        }
      }
      this.triggerSearchQuery({ query: currSearchQuery });
      if (this.$router.currentRoute.path !== '/faceted-search') {
        this.$router.push('/faceted-search');
      }
      this.$emit('on-reset');
    },
    resetFilters () {
      this.allFilterGroupItems = [];
      this.displayedFilterGroupItems = [];
      this.selectedAlphabetTabs = [];
      this.selectedFilterGroupItems = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.alphabet_button {
  margin-right: 1px;
  min-width: 31px;
  color: $blue-500 !important;

  &:disabled {
    color: $blue-gray-200 !important;
  }
}

.btn-primary {
  color: $white-500 !important;
}

.checkboxes {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  height: 55vh;
  align-content: flex-start;
  overflow-y: scroll;
}
.font-blue {
  color: $blue-500 !important;
}
</style>
