<template>
  <div class="home">
    <div
      v-if="!useICRSConfig"
      class="container container-fluid"
    >
      <div class="search-wrapper">
        <img
          v-if="config.showIcon"
          class="taiger-logo"
          src="../../assets/taiger-logo-black.png"
          alt=""
        >
        <p class="tag-line">{{config.iconText}}</p>
        <div class="d-flex flex-row align-items-center">
          <SearchBar
            class="home-search-bar"
            search-input-placeholder="Search documents, news, websites..."
            :postMessageOnSearch="true"
            @submit="handleSubmit"
          />
          <AdvancedSearchModal class="advanced-search" />
        </div>
        <div class="trending-topics">
          <p class="mb-2 font-secondary">Trending topics</p>
          <div
            class="topic-wrapper"
            v-for="topic in trendingTopics"
            :key="topic.term"
            @click="handleBadgeClick(topic)"
          >
            <Badge
              variant="primary"
              pill
            >
              {{ topic.term }}
            </Badge>
          </div>
        </div>
      </div>
      <div
        class="toolkits-wrapper"
        v-if="config.showAdminToolkits"
      >
        <p class="tag-line">{{config.toolkitsTitle}}</p>
        <div class="toolkitcard-container">
          <div
            v-for="(toolkit, index) in config.adminToolkits"
            :key="index"
            class="toolkitcard"
          >
            <toolkits-card
              :title="toolkit.title"
              :path="toolkit.path"
              :content="toolkit.content"
              :newTabOnClick="toolkit.newTabOnClick"
            >
            </toolkits-card>
          </div>
        </div>
      </div>
    </div>
    <div v-if="useICRSConfig">
      <div class="icrs-home-search-bar">
        <ICRSSearchBarWrapper
          :postMessageOnSearch="true"
          guidedDisplayOption="landing"
          :showScrollToTop="showScrollToTop"
        />
      </div>
      <ICRSFooter />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AdvancedSearchModal from '@/modules/advanced-search/AdvancedSearchModal.vue';
import ToolkitsCard from '@/components/toolkits-card/ToolkitsCard';
import ICRSSearchBarWrapper from '@/modules/icrs-search-bar-wrapper/ICRSSearchBarWrapper.vue';
import ICRSFooter from '@/modules/icrs-footer/ICRSFooter.vue';
import SearchBar from '@/modules/search-bar/SearchBar.vue';
import SearchService from '../../services/search.service';

export default {
  name: 'Home',

  components: {
    SearchBar,
    AdvancedSearchModal,
    ToolkitsCard,
    ICRSSearchBarWrapper,
    ICRSFooter,
  },

  data () {
    return {
      trendingTopics: [],
      config: {
        showIcon: true,
        iconText: 'Hi. What insights are you looking for?',
        showAdminToolkits: false,
        toolkitsTitle: '',
        adminToolkits: [],
      },
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
      showScrollToTop: false,
    };
  },

  ...mapGetters({
    isAdminUser: 'authService/isAdminUser',
  }),

  mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
    window.addEventListener('scroll', this.onScroll);
  },

  methods: {
    ...mapActions('searchService', [
      'setCurrentQuery',
    ]),
    handleSubmit (searchValue) {
      this.$router.push({ path: '/faceted-search', query: { query: searchValue } });
    },
    fetchTrendingTopics () {
      SearchService.getTrendingSearch({ lookbackDays: 30, size: 5 })
        .then((response) => {
          this.trendingTopics = response.data;
        });
    },
    handleBadgeClick (topic) {
      this.setCurrentQuery(topic.term);
    },
    onScroll () {
      // Get the current scroll position
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }

      if (currentScrollPosition === 0) {
        this.showScrollToTop = false;
      } else {
        this.showScrollToTop = true;
      }
    },
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  max-width: 700px;
  padding: 0 20px;
  margin: 0 auto;
  text-align: center;
  padding-top: 80px;

  .taiger-logo {
    width: 80px;
    display: inline-block;
    margin-bottom: 30px;
  }

  .tag-line {
    margin-bottom: 30px;
    font-size: 24px;
  }

  .home-search-bar {
    width: 100%;
  }

  .advanced-search {
    width: 200px;
    margin-left: 0.2em;
  }

  .trending-topics {
    text-align: left;
    margin-top: $gap-md;

    .topic-wrapper {
      cursor: pointer;
      display: inline-block;
      margin-right: $gap-xxs;
      margin-bottom: $gap-xxs;
    }
  }
}

.toolkits-wrapper {
  margin: 60px 0 140px 0;
  .tag-line {
    margin-bottom: 24px;
    font-size: 24px;
  }
}

.toolkitcard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.toolkitcard {
  flex: 0 32%;
  margin-bottom: 2%;
}
</style>
