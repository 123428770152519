import searchConstants from '@/constants/search-service-constants';
import FilterItem from '@/services/models/search/filter-item.model';
import FilterGroup from '@/services/models/search/filter-group.model';
import * as getters from '../getters';
import createState from '../state';


describe('search-core/filters vuex getters test', () => {
  test('selectedFilters', () => {
    const selectedFilters = [
      new FilterItem({ key: 'casa', docCount: 1 }, 'caa'),
    ];
    const state = createState({
      selectedFilters,
    });

    expect(getters.selectedFilters(state)).toEqual(selectedFilters);
  });

  test('selectedGroup', () => {
    const selectedGroup = new FilterGroup({
      name: 'caa',
      docCount: 10,
      bucket: [],
      displayName: 'CAA',
    });
    const state = createState({
      selectedGroup,
    });

    expect(getters.selectedGroup(state)).toEqual(selectedGroup);
  });

  test('filterModal', () => {
    const filterModal = {
      selectedFilters: [],
      searchKeyword: 'airplane',
      sortBy: searchConstants.FILTER_SORT_BY_OPTIONS.MOST_TO_LEAST,
      isFirstOpenHasSelectedFilters: true,
    };
    const state = createState({
      filterModal,
    });

    expect(getters.filterModal(state)).toEqual(filterModal);
  });

  test('appliedFilters', () => {
    const selectedFilters = [
      new FilterItem({ key: 'casa', docCount: 1 }, 'caa'),
      new FilterItem({ key: 'fasa', docCount: 1 }, 'caa'),
      new FilterItem({ key: 'airplane', docCount: 1 }, 'sectors'),
    ];

    const state = createState({
      selectedFilters,
    });

    expect(getters.appliedFilters(state)).toEqual({
      caa: ['casa', 'fasa'],
      sectors: ['airplane'],
    });
  });

  test('filterGroupings', () => {
    const filterGroupings = [
      {
        groupName: 'General',
        filters: [
          {
            classId: 'subject',
          },
        ],
      },
    ];

    const state = createState({ filterGroupings });

    expect(getters.filterGroupings(state)).toEqual(filterGroupings);
  });
});
