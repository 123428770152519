/**
 * Test action factory function. Use this in test file.
 * @example
 * const testAction = createTestAction(expect)
 * testAction(actions.getSomething, null, {}, [
 *   { type: 'REQUEST_SOMETHING' },
 *   { type: 'RECEIVE_SOMETHING', payload: { message: 'mocked response' } },
 * ])
 * @param  {Function} expect jest's expect global function
 * @return {Function}
 */
export function createTestAction (expect) {
  /**
   * Note: Taken from https://vuex.vuejs.org/guide/testing.html
   * @param {Function} action
   * @param {object|null} payload
   * @param {object} ctx
   * @param {object} ctx.state
   * @param {object} ctx.rootState
   * @param {object} ctx.rootGetters
   * @param {object} ctx.dispatch
   * @param {Array<object>} expectedMutations
   * @param {Function} done
   */
  function testAction (action, payload, ctx, expectedMutations, done = () => {}) {
    let count = 0;

    // mock commit
    const commit = (type, payload) => {  // eslint-disable-line
      const mutation = expectedMutations[count];

      try {
        expect(type).toBe(mutation.type);
        expect(payload).toEqual(mutation.payload);
      } catch (error) {
        done(error);
      }

      count++; // eslint-disable-line
      if (count >= expectedMutations.length) {
        done();
      }
    };

    const {
      rootState = {},
      state = {},
      rootGetters = {},
      dispatch = () => {},
    } = ctx;
    // call the action with mocked store and arguments
    const actionCtx = {
      commit,
      dispatch,
      rootState,
      state,
      rootGetters,
    };
    const value = action(actionCtx, payload);

    // check if no mutations should have been dispatched
    if (expectedMutations.length === 0) {
      expect(count).toBe(0);
    }

    if (value instanceof Promise) {
      return value
        .finally(done);
    }

    return Promise.resolve()
      .finally(done);
  }

  return testAction;
}
