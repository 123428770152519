/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import { toPascal } from '../helpers/helpers';

const registry = {
  pages: {
    directory: 'pages',
    components: [
      {
        tag: 'login-page', name: 'LoginPage', enabled: true, props: {},
      },
      {
        tag: 'faceted-search', name: 'Faceted Search', enabled: true, props: { useVanillaSearchCard: true },
      },
      // // Note: Instead of using the above faceted search,
      // // ICRS uses the below icrs-search for the search page.
      // {
      //   tag: 'icrs-search', name: 'ICRS Search', enabled: true, props: { useVanillaSearchCard: true },
      // },
      // {
      //   tag: 'icrs-redirect-page', name: 'IcrsRedirectPage', enabled: true, props: {},
      // },
      {
        tag: 'unauthorized-page', name: 'unauthorizedPage', enabled: true, props: {},
      },
    ],
  },
  modules: {
    directory: 'modules',
    components: [
      {
        tag: 'search-bar', name: 'Search Bar', enabled: true, props: { synonymSearchPluginEnabled: true },
      },
      {
        tag: 'login', name: 'Login', enabled: true, props: {},
      },
      {
        tag: 'doc-manager',
        name: 'Doc Manager',
        enabled: true,
        props: { showModalButton: true, haveWriteAccess: true },
      },
      {
        tag: 'loading-page', name: 'Loading Page', enabled: false, props: { showButton: true },
      },
      {
        tag: 'notifications', name: 'Notifications', enabled: false, props: { showButton: true },
      },
      {
        tag: 'save-search', name: 'Save Search', enabled: false, props: { left: true },
      },
      {
        tag: 'preview-viewer-modal', name: 'Preview Viewer Modal', enabled: false, props: {},
      },
    ],
  },
  components: {
    directory: 'components',
    components: [
      {
        tag: 'search-result-card', name: 'Search Result Card', enabled: true, props: {},
      },
      {
        tag: 'jumbo-modal', name: 'jumbo-modal', enabled: false, props: {},
      },
    ],
  },
};

/**
 * @typedef WidgetRouteConfig
 * @property {string} path
 * @property {string} name
 * @property {object} props
 * @property {string} directory
 * @property {string} componentPath
 * @property {string} tag
 * */

/**
 * @param  {string} type possible value "pages" | "modules"
 * @return {Array<WidgetRouteConfig>}
 */
function createRoutesFor (type) {
  const registryGroup = registry[type];

  if (!registryGroup) throw new Error(`Cannot find ${registryGroup} in registry`);

  const { directory, components } = registryGroup;

  return components
    .filter(component => component.enabled)
    .map(({ props, tag }) => {
      const routePath = `/${tag}`;
      const name = toPascal(tag);

      return {
        path: routePath,
        name,
        tag,
        directory,
        props,
      };
    });
}

function getComponents (type = null) {
  let components = [];
  const isIncludeAll = type === null;

  if (isIncludeAll || type === 'modules') {
    components = components.concat(registry.modules.components);
  }

  if (isIncludeAll || type === 'pages') {
    components = components.concat(registry.pages.components);
  }

  if (isIncludeAll || type === 'components') {
    components = components.concat(registry.components.components);
  }

  return components.filter(component => component.enabled);
}

function getRoutes () {
  const moduleRoutes = createRoutesFor('modules');
  const pageRoutes = createRoutesFor('pages');
  const componentRoutes = createRoutesFor('components');

  return [...moduleRoutes, ...pageRoutes, ...componentRoutes];
}

const components = getComponents();
const routes = getRoutes();

function createWidgetTag (name) {
  return `${name}-widget`;
}

function registerCustomElement ({ store, component, directory }) {
  // if (component.enabled !== true) return;

  const tagName = createWidgetTag(component.tag); // kebab case
  const fileName = toPascal(component.tag); // pascal case

  // eslint-disable-next-line global-require, import/no-dynamic-require
  const VueComponent = require(`../${directory}/${component.tag}/${fileName}`).default;

  VueComponent.store = store;

  Vue.customElement(tagName, { ...VueComponent }, { shadow: false });
}

function registerCustomElements (store) {
  const registeredWidgets = [];

  getComponents('modules').forEach((component) => {
    registerCustomElement({ component, store, directory: 'modules' });
    registeredWidgets.push(createWidgetTag(component.tag));
  });
  getComponents('pages').forEach((component) => {
    registerCustomElement({ component, store, directory: 'pages' });
    registeredWidgets.push(createWidgetTag(component.tag));
  });
  getComponents('components').forEach((component) => {
    registerCustomElement({ component, store, directory: 'components' });
    registeredWidgets.push(createWidgetTag(component.tag));
  });
}

export default {
  registry,
  components,
  routes,
  registerCustomElements,
};
