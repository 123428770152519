<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Search Type</th>
        <th>How To</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Term</td>
        <td>Typing in the searchbox and clicking on <em>Search</em> button.</td>
      </tr>
      <tr>
        <td>Boolean</td>
        <td>
          <p>Using AND, OR &amp; NOT (uppercase) in the search query. Examples:</p>
          <ul>
            <li>neck OR whiplash (searches for case with either "neck" or "whiplash")</li>
            <li>head AND "back pain" (searches for cases with both "head" and "back pain")</li>
            <li>NOT head (searches for cases without "head")</li>
            <li>head NOT face (searches for cases with "head" and without "face")</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Wildcard</td>
        <td>Wildcard search only supports middle and trailing *, prefix character should be more than 3.<br><br>eg. word* or word*word</td>
      </tr>
      <tr>
        <td colspan="2">Once the search has been made, you can refine your search with the filters on the left side. Moreover, you can save a search for a future time.</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'ICRSSearchHelp',
};
</script>
