<template>
  <IcrsTemplate>
    <template #content>
      <h2 class="text-center">Terms of Use</h2>
      <p>The Singapore Academy of Law ("SAL") operates the Website (defined below). Please read the following Website Terms and Conditions (including the Privacy Policy) (collectively "Website Conditions") before using the Website.</p>
      <br>
      <p style="font-weight:600 !important">If you do not accept these Website Conditions, please do not use the Website.</p>
      <div class="icrs-policy-terms">
        <ol>
          <li
            v-for="(content1, key1) in terms"
            :key="key1"
          >
            {{key1}}
            <ol>
              <li
                v-for="(content2, key2) in content1"
                :key="`${key1}_${key2}`"
              >
                {{key2}}
                <ol>
                  <li
                    v-for="(content3, idx) in content2"
                    :key="`${key1}_${key2}_${idx}`"
                  >
                    {{content3}}
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <!-- <ol>
          <li>
            Agreement
            <ol>
              <li>You hereby represent and warrant that you have read these Website Conditions.</li>
              <li>You hereby represent and warrant that you have the necessary legal capacity, right, power and authority to agree to these Website Conditions.</li>
              <li>SAL may from time to time vary or amend these Website Conditions by posting the amended Website Conditions at the Website. Any use of the Website after the amendment of these Website Conditions will be deemed to be acceptance of the amended Website Conditions by you.</li>
            </ol>
          </li>
          <li>
            Definitions
            <ol>
              <li>In these Website Conditions, the following definitions shall apply unless the context does not permit such application: <span>"Content" means materials, information, news, advertisements, listings, data, text, audio, video, pictures, graphics, software, blogs, webcasts, podcasts, broadcasts, messages, comments, suggestions, ideas and other content or other matter that is made available on or via the Website.</span> <span>"Linked Sites" is defined in Clause 8.1 .</span> <span>"Servers" means the computer software, systems and servers hosting, operating, managing, providing or contributing to the Website and the Services.</span> <span>"Website" means the website motoraccidents.lawnet.sg or www.lawnet.com.sg and any and all services made available, whether by subscription or otherwise, on or via the website.</span> </li>
              <li>The words "include" and "including" shall not be construed as having any limiting effect.</li>
              <li>The headings in these Website Conditions do not have any legal effect nor shall they affect the construction of these Website Conditions in any way.</li>
            </ol>
          </li>
          <li>
            Subscriptions and paid content
            <ol>
              <li>You acknowledge and agree that certain services are made available on or via the Website only to paying customers or registered subscribers (each a "Customer") and their authorized end-users (each an "Authorised User"). All applications for registration in relation to such services and all subscriptions shall be subject to the terms and conditions of subscription or paid content imposed by SAL (collectively the "Subscription T&amp;Cs") which shall be made known to applicants during the registration process. If you are a Customer, the Subscription T&amp;Cs shall apply in addition to these Website Conditions. If you visit the Website as an Authorised User, your access to and use of the services comprising the subscription shall be subject to the Subscription T&amp;Cs. You should obtain a copy of the Subscription T&amp;Cs from the Customer. In the event of any conflict or inconsistency between the Subscription T&amp;Cs and these Website Conditions, the Subscription T&amp;Cs shall prevail.</li>
            </ol>
          </li>
          <li>
            Usage Conditions
            <ol>
              <li>You may download, print, e-mail, transmit electronically and store reasonable copies of the Content purely for your personal use or for your internal business purposes.</li>
              <li>
                You must not do or permit or cause any other person to do the following without SAL's prior written consent:
                <ol>
                  <li>reproduce, duplicate or copy the whole or any portion of the Website or Content, except to make reasonable copies as stated in Clause 4.1;</li>
                  <li>modify, adapt, translate, publish, display, transmit, broadcast, podcast, webcast, distribute, sell, resell, trade or exploit for any purpose, the whole or any portion of, or any access to, the Website or Content;</li>
                  <li>use the Website or Content for the provision of outsourcing or the running of service bureaus; or</li>
                  <li>whether manually or with the help of computer programs, download or attempt to download information for the purpose of creating or adding to an electronic search or retrieval facility (online or otherwise).</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Intellectual Property
            <ol>
              <li>The Website and Content are owned by SAL or its licensors. You acknowledge that you have no right, title or interest in or to the Website and Content, except as otherwise stated in these Website Conditions, and you will not challenge SAL or its licensors' ownership of them.</li>
              <li>The domain name on which the Website is hosted on is the sole property of SAL and you may not use or otherwise adopt a similar name for your own use.</li>
            </ol>
          </li>
          <li>
            Online Conduct
            <ol>
              <li>
                You hereby undertake:
                <ol>
                  <li>to comply with these Website Conditions, and such other notices or guidelines that may be posted on the Website by SAL from time to time (which are hereby incorporated by reference into these Website Conditions);</li>
                  <li>not to use the Website or any Content for any unlawful purpose, and to comply with all applicable laws and regulations, including without limitation, copyright law;</li>
                  <li>not to hack into, interfere with, disrupt, disable, over-burden or otherwise impair the proper working of the Website or Servers, which shall include but is not limited to denial-of-service attacks, spoof attacks, session hacking, sniffing, tampering, reverse engineering or reprogramming.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Disclaimers &amp; Limitations
            <ol>
              <li>THE WEBSITE AND CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE EXTENT PERMITTED BY LAW, SAL DISCLAIMS ALL REPRESENTATIONS, CONDITIONS AND WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, IN RELATION TO THE WEBSITE AND CONTENT, INCLUDING WITHOUT LIMITATION ALL IMPLIED CONDITIONS AND WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</li>
              <li>SAL does not warrant that the Website and Content will meet your requirements or that the Website and Content will be accurate, complete, reliable, timely, secure or error-free or perform in an uninterrupted manner or be free from viruses or other harmful code. SAL shall not be liable in any way whatsoever for any losses, damage, fees, costs or expenses arising out of or in connection with the use of or reliance on the Website or Content or any downtime or service outage affecting them.</li>
              <li>The data and information made available on the Website do not purport, and shall not in any way be deemed, to constitute an offer or provision of any legal, professional or expert advice.</li>
              <li>
                You agree that:
                <ol>
                  <li>SAL shall be entitled at any time, at its sole and absolute discretion and without prior notice, to add to, vary, terminate, withdraw or suspend the operation of the whole or any part or feature of the Website or Content without assigning any reason; and</li>
                  <li>access to or the operation of the Website and/or Servers may from time to time be interrupted or encounter technical or other problems and may not necessarily continue uninterrupted or without technical or other errors, and in any such event, SAL shall not be liable for any loss, liability or damage which may be incurred as a result.</li>
                </ol>
              </li>
              <li>To the extent permitted by law, under no circumstances, including, but not limited to, negligence, shall SAL be liable for any direct, indirect, special, consequential, or incidental damages that result from the use of, or the inability to use, the Website or Content.</li>
            </ol>
          </li>
          <li>
            Linked Sites
            <ol>
              <li>SAL may provide links to other websites ("Linked Sites") that may be of relevance and interest to users. SAL has no control over, and is not responsible for the Linked Sites or their content and accepts no responsibility for any damage you may incur from using the Linked Sites (including any virus, spyware, malware, worms, errors or damaging material contained in the Linked Sites) or the availability of any content on the Linked Sites, and you hereby irrevocably waive any claim against SAL with respect to the Linked Sites.</li>
              <li>Third party web sites may link to the Website. Such links do not necessarily mean that SAL is associated or affiliated with the third party or its goods and services. Your reliance on any third party statements of association or affiliation with SAL or approval of SAL is at your own risk.</li>
            </ol>
          </li>
          <li>
            Data Use &amp; Privacy
            <ol>
              <li>The Privacy Policy explains SAL's data use and privacy practices in detail. You agree that SAL may collect, use, disclose and process your personal data in accordance with the terms of the Privacy Policy.</li>
            </ol>
          </li>
          <li>
            Termination
            <ol>
              <li>You agree that SAL has the right in its sole and absolute discretion and without prior notice to restrict, suspend, or terminate your access to and/or use of all or any part of the Website or Content, without assigning any reason.</li>
            </ol>
          </li>
          <li>
            Jurisdictional Issues
            <ol>
              <li>The Website is owned and operated by SAL. SAL makes no representation that the Website or Content are appropriate or available for use in your location. Those who choose to access the Website from any location do so on their own initiative and are responsible for compliance with local laws,if and to the extent local laws are applicable.</li>
            </ol>
          </li>
          <li>
            Indemnity
            <ol>
              <li>
                You agree to indemnify and hold SAL, and its subsidiaries, affiliates, officers, agents, partners, and employees, harmless from all claims, demands, actions, proceedings, liabilities (including statutory liability and liability to third parties), penalties, and costs (including without limitation, legal costs on a full indemnity basis), awards, losses and/or expenses, arising out of or in connection with:
                <ol>
                  <li>your access to or use of the Website or Content;</li>
                  <li>your connection to the Website;</li>
                  <li>your breach of any of these Website Conditions;</li>
                  <li>your violation of any rights of another person or entity; or</li>
                  <li>your breach of any statutory requirement, duty or law.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Severability
            <ol>
              <li>The invalidity, illegality or unenforceability of any of these Website Conditions shall not affect the validity, legality and enforceability of the remaining provisions of these Website Conditions.</li>
            </ol>
          </li>
          <li>
            Relationship of Parties
            <ol>
              <li>Nothing in these Website Conditions shall constitute or be deemed to constitute an agency, partnership or joint venture between SAL and you and neither party shall have any authority to bind the other in any way.</li>
            </ol>
          </li>
          <li>
            Waiver
            <ol>
              <li>No waiver of any rights or remedies by SAL shall be effective unless made in writing and signed by an authorised representative of SAL.</li>
              <li>A failure by SAL to exercise or enforce any rights conferred upon it by these Website Conditions shall not be deemed to be a waiver or variation of any such rights or operate so as to bar the exercise or enforcement thereof at any subsequent time or times.</li>
            </ol>
          </li>
          <li>
            Rights of Third Parties
            <ol>
              <li>Except as provided for in Clause 12 , a person or entity who is not a party to these Website Conditions shall have no right under the Contracts (Rights of Third Parties) Act (Cap 53B) to enforce any term of these Website Conditions, regardless of whether such person or entity has been identified by name, as a member of a class or as answering a particular description.</li>
            </ol>
          </li>
          <li>
            Force Majeure
            <ol>
              <li>No party shall be liable for any failure to perform its obligations under these Website Conditions if the failure results from a Force Majeure Event (defined below), provided always that whenever possible, the affected party will resume that obligation as soon as the Force Majeure Event occasioning the failure ceases or abates.</li>
              <li>For purposes of these Website Conditions, a "Force Majeure Event" is an event which is a circumstance or event beyond the reasonable control of a party which affects the general public in that party's country or in the territory, and which results in the party being unable to observe or perform on time an obligation under these Website Conditions.</li>
            </ol>
          </li>
          <li>
            Governing Law &amp; Jurisdiction
            <ol>
              <li>These Website Conditions and all matters relating to your access to, or use of, the Website shall be governed by and construed in accordance with the laws of Singapore. Any dispute arising out of or in connection with these Website Conditions, including any question regarding its existence, validity or termination, shall be settled by mediation in Singapore at the Singapore Mediation Centre ("SMC") in accordance with SMC's Mediation Procedure in force for the time being. Unless otherwise agreed by the parties, the Mediator(s) will be appointed by SMC. The mediation will take place in Singapore in the English language and the parties agree to be bound by any settlement agreement reached. If the mediation does not result in a settlement agreement within four (4) weeks of the date of the first request for mediation made by either party, the dispute shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre ("SIAC") in accordance with the Arbitration Rules of the Singapore International Arbitration Centre ("SIAC Rules") for the time being in force, which rules are deemed to be incorporated by reference to this clause. The seat of the arbitration shall be Singapore. The Tribunal shall consist of one (1) arbitrator. The language of arbitration shall be English. The arbitration agreement contained in this Clause 18.1 shall be governed by the laws of Singapore.</li>
              <li>For the avoidance of doubt, it is agreed that nothing in this Clause 18 shall prevent a party from seeking urgent injunctive or equitable relief before any appropriate court.</li>
            </ol>
          </li>
        </ol> -->
      </div>
    </template>
  </IcrsTemplate>
</template>
<script>
import ICRSService from '@/services/icrs-service';
import IcrsTemplate from './template/IcrsStaticTemplate.vue';

export default {
  components: {
    IcrsTemplate,
  },
  computed: {
    terms () {
      return ICRSService.getTerms();
    },
  },
};
</script>
