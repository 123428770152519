import _ from 'lodash';
import SearchServiceTypes from '../../../constants/search-service-constants';
import DocumentModel from './search-result-document.model';

class SearchResultModel {
  constructor (data = {}) {
    // this.total = !_.isUndefined(data.total) ? data.total : 0;
    // this.id = !_.isUndefined(data.id) ? data.id : null;
    // this.size = !_.isUndefined(data.size) ? data.size : SearchServiceTypes.RESULTS_PER_PAGE;
    // this.documents = !_.isUndefined(data.documents)
    //   ? SearchResultModel.formatDocuments(data.documents, SearchResultModel.size) : [];
    this.total = !_.isUndefined(data.totalNumberOfResults) ? data.totalNumberOfResults : 0;
    this.id = !_.isUndefined(data.id) ? data.id : null;
    this.size = !_.isUndefined(data.size) ? data.size : SearchServiceTypes.RESULTS_PER_PAGE;
    this.facetResults = !_.isUndefined(data.facetResults)
      ? data.facetResults : [];
    this.resultItems = !_.isUndefined(data.resultItems)
      ? data.resultItems : [];
    this.spellCorrectedQueries = !_.isUndefined(data.spellCorrectedQueries)
      ? data.spellCorrectedQueries : [];
  }

  /**
      * Build array of DocumentModel from JSON array response
      */
  static formatDocuments (responseData, size) {
    const documents = [];
    let count = 0;
    _.forEach(responseData, (data) => {
      if (count >= size) {
        return;
      }
      const doc = new DocumentModel(data);
      if (!_.isUndefined(doc)) {
        documents.push(doc);
      }
      count += 1;
    });

    return documents;
  }
}

export default SearchResultModel;
