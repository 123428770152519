import Api from './api/api.service';
import ConfigService from './config.service';

const KM_CONTEXT = ConfigService.getSearchKmContext();

export default {
  getConcepts () {
    return Api().get(`${KM_CONTEXT}/ontology/getConcepts`).then(response => response.data);
  },
  getRelations (individualId) {
    return Api().get(`${KM_CONTEXT}/ontology/getRelations`, { params: { id: individualId } })
      .then(response => response.data);
  },
  getProperties (id) {
    return Api().get(`${KM_CONTEXT}/ontology/getProperties?id=${id}`).then(response => response.data);
  },
};
