import Api from './api/api.service';
import ConfigService from './config.service';

const UAA_CONTEXT = ConfigService.getUaaContext();
const SEARCH_CONTEXT = ConfigService.getSearchSearchContext();
const GATEWAY_CONTEXT = ConfigService.getSearchGatewayContext();

export default {
  /**
   * Retrieve user data based on auth token in cookie
   * @return {Promise}
   */
  getUser () {
    return Api().get(`${SEARCH_CONTEXT}/api/user/getUser`)
      .then(res => res.data);
  },

  authenticate (user, password, rememberMe) {
    const formData = {
      j_username: !_.isUndefined(user) ? encodeURIComponent(user) : '',
      j_password: !_.isUndefined(password) ? encodeURIComponent(password) : '',
      'remember-me': rememberMe,
      submit: 'Login',
    };

    const searchAuthInstance = Api({
      contentType: 'application/x-www-form-urlencoded',
    });

    const authPromises = [
      /**
       * sequence is important! only search provides credentials
       */
      searchAuthInstance.post(`${GATEWAY_CONTEXT}/api/authentication`, formData),
    ];

    return Promise.all(authPromises).then(response => response[0].data);
  },

  logout () {
    if (ConfigService.getKPOIntegrationEnabled()) {
      return this.revokeToken();
    }
    return Api().post(`${GATEWAY_CONTEXT}/api/logout`, {}).then(response => response.data);
  },

  getUserAuth () {
    return Api({ contentType: 'application/x-www-form-urlencoded' })
      .get(`${ConfigService.getADAuthEndpoint()}`).then(response => response.data);
  },

  refreshToken () {
    return Promise.reject(new Error('user-service > refreshToken: unsupported feature'));
  },

  revokeToken () {
    return Api().delete(`${UAA_CONTEXT}/oauth/revoke`).then(res => res.data);
  },

  getICRSSAMLUserDetails () {
    return Api().get(`${ConfigService.getICRSSAMLUserDetails()}`).then(res => res);
  },

  getICRSUserObject () {
    return Api().get(`${GATEWAY_CONTEXT}/account`).then(res => res);
  },
};
