<template>
  <div class="background-white rounded border basic-information">
    <div class="basic-information__individual-info">
      <Badge
        pill
        class="align-self-center"
        :style="{'background-color': this.selectedClass.backgroundColor, 'color': this.selectedClass.color}"
      >{{this.selectedClass.badgeLabel | capitalize}}</Badge>
      <div class="label-lg p-1 basic-information__title"> {{ this.displayedIndividual | capitalize }}</div>
    </div>

    <div
      v-if="displayedDetails.length"
      class="label-sm basic-information__details"
    >
      <div class='column'>
        <div
          v-for="(detail, index) in displayedDetails"
          :key="index"
          class="mb-xs w-50 d-flex flex-row text-left"
        >
          <span class="property-label">{{ detail.label | capitalize }}</span>
          <span class='text-sm value-label'>
            <span v-if="!detail.viewMore">
              {{ detail.truncatedValue }}
            </span>
            <span v-else>{{ detail.values[0] }}</span>
            <br />
            <b-button
              v-if="detail.showViewMoreButton"
              class="p-0"
              variant="link"
              @click="togglePropertyViewInformation(index)"
            >
              <div class="font-blue">
                <span v-if="!detail.viewMore">More</span>
                <span v-else>Less</span>
                <font-awesome-icon
                  class="basic-information__view-icon"
                  :icon="!detail.viewMore ? ['fa', 'chevron-right'] : ['fa', 'chevron-up']"
                />
              </div>
            </b-button>
          </span>
        </div>
      </div>
      <b-button
        v-if="showViewButtons"
        class="basic-information__view-button"
        variant="link"
        @click="toggleViewInformation"
      >
        <div
          v-if="!viewAllInformation"
          class="font-blue"
        >View more
          <font-awesome-icon
            class="basic-information__view-icon"
            :icon="['fa', 'chevron-right']"
          />
        </div>
        <div
          v-if="viewAllInformation"
          class="font-blue"
        >View less
          <font-awesome-icon
            class="basic-information__view-icon"
            :icon="['fa', 'chevron-up']"
          />
        </div>
      </b-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { initialisePropertyData } from '../ontology-search-helpers';

const NUM_PROPERTIES_VIEWED_LESS = 6;
const PROPERTY_VALUE_MAX_CHARACTER = 340;

export default {
  name: 'BasicInformation',
  data () {
    return {
      displayedIndividual: '',
      viewAllInformation: false,
      details: [],
      displayedDetails: [],
    };
  },
  filters: {
    capitalize (value) {
      if (!value) return;
      return value.toString().charAt(0).toUpperCase() + value.slice(1); // eslint-disable-line
    },
  },
  computed: {
    ...mapGetters('advancedSearch', [
      'inputIndividual',
      'individualId',
      'selectedClass',
    ]),
    ...mapGetters('advancedSearch/ontologySearch', [
      'individualProperties',
    ]),
    showViewButtons () {
      return this.details.length > NUM_PROPERTIES_VIEWED_LESS;
    },
  },
  methods: {
    ...mapActions('advancedSearch/ontologySearch', [
      'getIndividualProperties',
    ]),
    initialiseProperties () {
      const maxLength = PROPERTY_VALUE_MAX_CHARACTER;
      this.getIndividualProperties(this.individualId).then(() => {
        this.details = this.individualProperties.map(data => initialisePropertyData(data, maxLength));
        this.initialiseDisplayedProperties();
      });
    },
    initialiseDisplayedProperties () {
      this.displayedDetails = this.details;
      if (!this.viewAllInformation) {
        this.displayedDetails = this.displayedDetails.slice(0, NUM_PROPERTIES_VIEWED_LESS);
      }
    },
    toggleViewInformation () {
      this.viewAllInformation = !this.viewAllInformation;
      this.initialiseDisplayedProperties();
    },
    togglePropertyViewInformation (index) {
      const { details } = this;
      details[index].viewMore = !details[index].viewMore;
      Vue.set(details, index, details[index]);
      Vue.set(this.displayedDetails, index, details[index]);
    },
  },
  watch: {
    individualId (val) {
      if (val) {
        this.viewAllInformation = false;
        this.displayedIndividual = this.inputIndividual;
        this.initialiseProperties();
      }
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.displayedIndividual = this.inputIndividual;
      this.initialiseProperties();
    });
  },
};
</script>

<style lang="scss" scoped>
.basic-information {
  padding: $gap-md;
  -ms-flex-negative: 0;
  &__title {
    font-weight: $weight-semi-bold;
  }
  &__details {
    text-align: left;
    margin-top: $gap-xs;

    .column {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .property-label {
      color: $blue-gray-500;
      word-break: normal;
      text-align: left;
      min-width: 30%;
    }

    .value-label {
      color: $blue-gray-400;
      font-weight: $weight-normal;
      overflow-wrap: break-word;
      word-wrap: break-word;
      text-align: left;
      min-width: 70%;
    }
  }
  &__individual-info {
    display: flex;
    flex-direction: row;
  }
  &__view-button {
    padding: 0;
  }
  &__view-icon {
    margin-left: $gap-xxs;
    margin-bottom: $gap-xxs;
  }
}
</style>
