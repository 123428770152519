<template>
  <jumbo-modal :modalId="modalId" title="Preview Viewer" @hide="closePreviewViewerModal">
    <template #content>
      <text-viewer v-if="documentType === documentTypes.TEXT" />
    </template>
  </jumbo-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import JumboModal from '@/components/jumbo-modal/JumboModal';
import PreviewViewerConstants from '@/constants/preview-viewer-service.constants';

export default {
  components: {
    JumboModal,
    TextViewer: () => import('./components/TextViewer'),
  },
  watch: {
    previewViewerModalOpen (val, oldVal) {
      if (val) {
        this.$bvModal.show(this.modalId);
      }
    },
  },
  data () {
    return {
      documentTypes: PreviewViewerConstants.DOCUMENT_TYPES,
      modalId: 'preview-viewer-modal',
    };
  },
  computed: {
    ...mapGetters('previewViewer', ['previewViewerModalOpen', 'documentType']),
  },
  methods: {
    ...mapActions('previewViewer', [
      'openPreviewViewer', // to be public called using $ref
      'resetPreviewViewer',
    ]),
    closePreviewViewerModal () {
      this.resetPreviewViewer();
    },
  },
};
</script>
