import mutations from '../mutations';
import createState from '../state';

describe('advanced-search vuex mutations test', () => {
  it('setInputIndividual', () => {
    const state = createState();
    const query = 'Singapore';
    mutations.setInputIndividual(state, query);
    expect(state.inputIndividual).toBe(query);
  });

  it('setIndividualId', () => {
    const state = createState();
    const query = 'city_singapore';
    mutations.setIndividualId(state, query);
    expect(state.individualId).toBe(query);
  });

  it('setSelectedClass', () => {
    const state = createState();
    const query = 'Country';
    mutations.setSelectedClass(state, query);
    expect(state.selectedClass).toBe(query);
  });

  test('setIndividualSearchSuggestMap', () => {
    const state = createState();
    const individualSearchSuggestMap = {
      'aia singapore': '6qltoced9i8',
      'amara singapore': 'g1ohevsnpk',
    };
    mutations.setIndividualSearchSuggestMap(state, individualSearchSuggestMap);
    expect(state.individualSearchSuggestMap).toBe(individualSearchSuggestMap);
  });

  test('setFilterGroupConfigs', () => {
    const state = createState();
    const config = { caa: {}, domains: {} };
    mutations.setFilterGroupConfigs(state, config);
    expect(state.filterGroupConfigs).toBe(config);
  });

  test('setFilterGroups', () => {
    const state = createState();
    const filterGroups = [];
    mutations.setFilterGroups(state, filterGroups);
    expect(state.filterGroups.data).toBe(filterGroups);
  });

  test('setFilterGroupFetchingStatus', () => {
    const state = createState();
    mutations.setFilterGroupFetchingStatus(state, true);
    expect(state.filterGroups.isFetching).toBe(true);
    mutations.setFilterGroupFetchingStatus(state, false);
    expect(state.filterGroups.isFetching).toBe(false);
  });

  test('setCurrentSearch', () => {
    const state = createState();
    const currentSearch = {
      ontologySearch: true,
      filterBasedSearch: false,
    };
    mutations.setCurrentSearch(state, currentSearch);
    expect(state.currentSearch).toBe(currentSearch);
  });

  test('setIsLoading', () => {
    const state = createState();
    mutations.setIsLoading(state, false);
    expect(state.isLoading).toBe(false);
    mutations.setIsLoading(state, true);
    expect(state.isLoading).toBe(true);
  });

  test('setAdvancedSearchModalOpen', () => {
    const state = createState();
    mutations.setIsLoading(state, true);
    expect(state.isLoading).toBe(true);
    mutations.setIsLoading(state, false);
    expect(state.isLoading).toBe(false);
  });
});
