<template>
  <div id="search-bar">
    <div class="search-bar-container">
      <div class="autosuggest-container">
        <i
          aria-label="Search Help"
          class="infoIcon fa fa-info-circle tab-focus"
          size="lg"
          v-b-modal="showHelpId"
        ></i>
        <b-modal
          :id="showHelpId"
          size="xl"
          :centered="true"
          :hide-footer="true"
          header-close-content="<i class='fa fa-times-circle' />"
          title="Search Bar Help"
          @hide="closeShowHelp"
        >
          <div class="user-guide-section">
            <a
              class="user-guide-link"
              href="/icrs/files/user_guide_v1.0.pdf"
              target="_blank"
            >Click here to view the user guide.</a>
          </div>
          <ICRSSearchHelp />
        </b-modal>
        <vue-autosuggest
          ref="myVueAutosuggest"
          class="autosuggest-input form-input"
          :suggestions="[{data:autoCompleteList}]"
          :input-props="{
            id:'autosuggest__input',
            placeholder:this.config.searchInputPlaceholder,
            'data-cy': 'search-bar-input'
          }"
          :get-suggestion-value="getSuggestionValue"
          :should-render-suggestions="shouldRenderSuggestions"
          :value="searchBarInput"
          @selected="onSelect"
          @keyup.enter="onEnterKey"
          @input="onInputChange"
          componentAttrClassAutosuggestResults="my_autosuggest__results"
          componentAttrClassAutosuggestResultsContainer="my_autosuggest__results-container"
        >
          <template slot="before-suggestions">
            <template v-if="showPlugin">
              <component
                :is="defaultTemplatePlugin"
                v-bind="{eventName : 'pluginEvent'}"
                @pluginEvent="handlePluginEvent"
              ></component>
            </template>
          </template>
          <template slot-scope="{suggestion}">
            <span v-dompurify-html="highlightQuery(suggestion.item, searchBarInput)"></span>
          </template>
        </vue-autosuggest>
      </div>
      <b-button
        class="append"
        variant="main-search"
        :disabled="!isInputValid"
        @click="handleClickFunc"
        aria-label="Main Search"
      >
        <!-- {{searchButtonText}} -->
        <font-awesome-icon
          :icon="['fas', 'search']"
        />
      </b-button>
    </div>
    <synonym-search-plugin
      ref="synonymSearchPlugin"
      v-if="synonymSearchPluginEnabled"
      id="synonym_plugin"
      :searchBarInput="searchBarInput"
    >
    </synonym-search-plugin>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { VueAutosuggest } from 'vue-autosuggest';
import { highlightQuery } from '@/helpers/helpers';


const SEARCH_LENGTH_TILL_AUTOCOMPLETE = 3;

export default {
  name: 'SearchBar',
  props: {
    searchInputPlaceholder: {
      type: String,
      default: null,
    },
    searchButtonText: {
      type: String,
      default: 'Search',
    },
    searchEndpoint: {
      type: String,
      default: '',
    },
    redirectOnSearch: {
      type: Boolean,
      default: false,
    },
    newTabOnSearch: {
      type: Boolean,
      default: false,
    },
    postMessageOnSearch: { // set true if search bar is used as individual entity
      type: Boolean,
      default: false,
    },
    onSearchEventName: {
      type: String,
      default: 'searchClick',
    },
    defaultTemplatePlugin: {
      type: String,
      default: null,
    },
    inputValue: {
      type: String,
      default: null,
    },
    synonymSearchPluginEnabled: {
      type: Boolean,
      default: false,
    },
    quoteQueryOnSelect: {
      type: Boolean,
      default: false,
    },
    icrsGuidedSearchQuery: {
      type: String,
      default: null,
    },
  },
  components: {
    VueAutosuggest, // https://github.com/darrenjennings/vue-autosuggest
    TrendingSearchPlugin: () => import('./components/TrendingSearchPlugin'),
    SynonymSearchPlugin: () => import('./components/SynonymSearchPlugin'),
    ICRSSearchHelp: () => import('./components/ICRSSearchHelp'),
  },
  data () {
    return {
      autoCompleteList: [],
      searchBarInput: '',
      config: {
        searchInputPlaceholder: this.searchInputPlaceholder,
      },
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
      showHelpId: 'show-help-modal',
    };
  },
  computed: {
    ...mapGetters('searchService', [
      'searchInput',
      'currentQuery',
      'suggestedQueryText',
    ]),

    ...mapGetters('icrs', [
      'guidedSearch',
    ]),

    isInputValid () {
      return this.searchBarInput && this.searchBarInput.trim().length > 0;
    },

    showPlugin () {
      return (this.searchInput && this.searchBarInput.length === 0)
        && (this.autoCompleteList && this.autoCompleteList.length === 0);
    },
  },
  methods: {
    ...mapActions({
      execGetAutocomplete: 'searchService/execGetAutocomplete',
      buildAutocomplete: 'searchService/buildAutocomplete',
      triggerSearchQuery: 'searchService/triggerSearchQuery',
      resetNewSearchResults: 'searchService/newResults/resetNewSearchResults',
      setSuggestedQueryText: 'searchService/setSuggestedQueryText',
      setKeepOriginSearchText: 'searchService/setKeepOriginSearchText',
    }),
    ...mapActions('icrs', [
      'setGuidedSearch',
      'setDropdownGuidedTag',
      'setActiveGuidedPrefix',
      'setSelectedLevel1',
      'setSelectedLevel2',
      'setSelectedLevel3',
      'setLevel2CategoriesForSelectedLevel1',
      'setLevel3CategoriesForSelectedLevel2',
    ]),
    ...mapActions('advancedSearch', [
      'setSearchShowOverview',
    ]),
    closeShowHelp () {
      this.$bvModal.hide(this.showHelpId);
      this.$emit('hide');
    },
    setSearchBarInput (value) {
      this.searchBarInput = value;
    },
    async doAutocomplete (value) {
      if (value && value.length >= SEARCH_LENGTH_TILL_AUTOCOMPLETE) {
        this.execGetAutocomplete({ textToComplete: value }).then((resp) => {
          this.buildAutocomplete(resp).then((acResp) => {
            this.autoCompleteList = acResp;
          });
        });
      } else {
        this.autoCompleteList = [];
      }
    },
    handleClickFunc () {
      this.setKeepOriginSearchText(false);
      this.handleClick();
    },
    onSelect (event) {
      if (!event) {
        return;
      }
      const { item } = event;
      // this.searchBarInput = this.quoteQueryOnSelect ? `"${item.suggest}"` : item.suggest;
      this.searchBarInput = this.quoteQueryOnSelect ? `"${item}"` : item;

      // For ICRS to trigger search immediately upon selection
      this.handleClick();
    },
    onEnterKey (event) {
      this.setKeepOriginSearchText(false);
      this.handleClick();
    },
    onInputChange (text) {
      // event fired when the input changes
      this.searchBarInput = text;

      this.setSuggestedQueryText('');
    },
    getSuggestionValue (suggestion) {
      return suggestion.item;
    },
    /**
     * @param {Array} size - total results displayed
     * @param {Boolean} loading - value that indicates if vue-autosuggest _thinks_ that the
     *                            the popover should be open (e.g. if user hit escape, or
     *                            user clicked away)
     * @returns {Boolean}
     */
    shouldRenderSuggestions (size, loading) {
      return !loading;
    },
    handleClick () {
      if (!this.searchBarInput) {
        return;
      }

      // if (this.useICRSConfig) {
      // Clear existing nature of injury stuff
      this.setGuidedSearch(false);
      this.setDropdownGuidedTag('');
      this.setActiveGuidedPrefix(0);
      this.setSelectedLevel1({ code: 'All' });
      this.setSelectedLevel2({ code: 'All' });
      this.setSelectedLevel3({ code: 'All' });
      this.setLevel2CategoriesForSelectedLevel1([{ code: 'All' }]);
      this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);
      // }
      const url = `${this.searchEndpoint}?query=${this.searchBarInput}`;

      let appliedSynonyms = null;

      if (this.synonymSearchPluginEnabled) {
        appliedSynonyms = this.activateSynonymSearchPlugin();
      }

      if (this.redirectOnSearch) {
        // window.location = url;
        window.top.location.href = url;
      } else if (this.newTabOnSearch) {
        window.open(url);
      } else if (this.postMessageOnSearch) {
        // Used by ICRS in home page
        const eventMsg = { query: this.searchBarInput };
        if (appliedSynonyms) {
          eventMsg.appliedSynonyms = appliedSynonyms;
        }
        this.$emit(this.onSearchEventName, eventMsg);
        // if (this.useICRSConfig) {
        this.$router.push({ path: '/icrs-search', query: eventMsg });
        // } else {
        //   this.$router.push({ path: '/faceted-search', query: eventMsg });
        // }
      } else {
        // Used by ICRS in search page
        const eventMsg = { resetSources: true, query: this.searchBarInput };
        if (appliedSynonyms) {
          eventMsg.appliedSynonyms = appliedSynonyms;
        }
        this.triggerSearchQuery(eventMsg);
      }
      // Commented off for ICRS
      // this.$emit('submit', this.searchBarInput);
      this.setSearchShowOverview(false);
      this.resetNewSearchResults();
    },
    handleICRSClick (searchInput) {
      this.setGuidedSearch(true);
      const url = `${this.searchEndpoint}?query=${searchInput}`;

      let appliedSynonyms = null;

      if (this.synonymSearchPluginEnabled) {
        appliedSynonyms = this.activateSynonymSearchPlugin();
      }

      if (this.redirectOnSearch) {
        // window.location = url;
        window.top.location.href = url;
      } else if (this.newTabOnSearch) {
        window.open(url);
      } else if (this.postMessageOnSearch) {
        // Used by ICRS in home page
        const eventMsg = { query: searchInput };
        if (appliedSynonyms) {
          eventMsg.appliedSynonyms = appliedSynonyms;
        }
        this.$emit(this.onSearchEventName, eventMsg);
        // if (this.useICRSConfig) {
        this.$router.push({ path: '/icrs-search', query: eventMsg });
        // } else {
        //   this.$router.push({ path: '/faceted-search', query: eventMsg });
        // }
      } else {
        // Used by ICRS in search page
        const eventMsg = { resetSources: true, query: searchInput };
        if (appliedSynonyms) {
          eventMsg.appliedSynonyms = appliedSynonyms;
        }
        this.triggerSearchQuery(eventMsg);
      }
      // Commented off for ICRS
      // this.$emit('submit', searchInput);
      this.resetNewSearchResults();
    },
    handlePluginEvent (event) {
      // this.onSelect({ item: { suggest: event } });
      this.searchBarInput = event;
    },
    updateSearchBarInput (newValue) {
      if (this.searchBarInput !== newValue) {
        this.searchBarInput = newValue;
      }
    },
    activateSynonymSearchPlugin () {
      let appliedSynonyms = null;
      const mySynonyms = this.$refs.synonymSearchPlugin.getSelectedSynonyms();
      appliedSynonyms = mySynonyms || null;
      return appliedSynonyms;
    },
    /**
     * Wrap matched substring with strong tag
     * @params {string} text
     * @return {string}
     */
    highlightQuery,
  },
  watch: {
    // eslint-disable-next-line func-names
    searchBarInput: _.debounce(function (value) {
      this.doAutocomplete(value);
    }, 200),
    inputValue (val) {
      this.updateSearchBarInput(val);
    },
    currentQuery (val) {
      this.searchBarInput = val;
    },
    icrsGuidedSearchQuery (val) {
      if (val && typeof val === 'string') {
        this.searchBarInput = '';
        // remove the timestamp from text query (last 13 characters)
        this.handleICRSClick(val.slice(0, -13));
      }
    },
    suggestedQueryText (val) {
      if (val) {
        this.autoCompleteList = [val];
      }
    },
  },
  mounted () {
    // after DOM update throw this logic
    if (this.$config[this.$options.name]) {
      // if ( this.$config[this.$options.name]) origin logic
      this.config = this.$config[this.$options.name];
    }
    if (this.currentQuery) {
      this.searchBarInput = this.currentQuery;
    }
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#search-bar {
  position: relative;

  input {
    width: 100%;
    height: 38px;
    padding: 0.5rem;
  }

  #autosuggest__input {
    outline: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 1px solid white !important; // for ICRS
    // border: solid $gray-300 1px;
    // padding-left: 38px; // for search icon display
    font-size: $font-md;
    border-radius: 0.25rem 0 0 0.25rem;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }

  // #autosuggest__input:focus {
  //   border-color: $blue-500;
  // }

  // #autosuggest__input:focus {
  //   border-color: var(--primary);
  // }

  ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0.75rem 1rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    width: 100%;
    display: inline-block;
    text-align: left;
    font-size: $font-md;
  }
  li:hover {
    cursor: pointer;
  }
  .searchIcon {
    width: 14px;
    height: 14px;
    margin: 12px 8px 12px 16px;
    position: absolute;
    left: 0px;
  }
  .search-bar-container {
    display: flex;
    width: 100%;
  }
  .autosuggest-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  #autosuggest {
    width: 100%;
    display: block;
  }
  .my_autosuggest__results {
    border: medium none;
    list-style: none outside none;
    border-radius: 3px;
    box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
    font-size: 0.8rem;
    padding: 0;
    text-shadow: none;
    position: absolute;
    background: $white;
    z-index: 11;
    width: 100%;
    max-height: 320px;
    overflow-y: auto;

    .btn {
      font-size: 14px;
      padding: 2px 8px;
    }
  }
  .my_autosuggest__results-container {
    position: relative;
  }
  // .autosuggest__results-item--highlighted {
  //   background: $blue-500;
  //   color: $white-500;
  // }
  // .autosuggest__results-item--highlighted {
  //   background: var(--primary);
  //   color: var(--white);
  // }
  .append {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 38px;
  }

  .tab-focus:focus-visible {
    outline: 2px solid gray;
  }
}
</style>
