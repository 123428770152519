<template>
  <div class="promoted-content-filter">
    <div
      class="promoted-content-filter__header"
      v-b-toggle="accordianId"
      role="tab"
    >
      <span
        class="promoted-content-filter-title"
        :class="{'promoted-content-filter-title--filter-applied': promotedFilterEnable}"
      >Promoted content</span>
      <icon-text
        class="when-opened"
        faType="fas"
        faName="chevron-up"
        size="sm"
      ></icon-text>
      <icon-text
        class="when-closed"
        faType="fas"
        faName="chevron-down"
        size="sm"
      ></icon-text>
    </div>
    <b-collapse
      :visible="isOpen"
      @input="handleCollapseInput"
      :id="accordianId"
      role="tabpanel"
    >
      <div class="promoted-content-filter__body">
        <Checkbox
          :value="promotedFilterEnable"
          @change="handleShowPromotedContent"
        >Display promoted content only</Checkbox>

        <PromotedDateRangeDropdown
          v-if="promotedFilterEnable"
          @change="handleDateRangeChange"
          class="promoted-content-filter__dropdown"
          :promotedByDateRangeMode="promotedByDateRangeMode"
          :promoteDateObject="promoteDateObject"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import { mapActions, mapGetters } from 'vuex';
import SearchConstants from '@/constants/search-service-constants';
import IconText from '@/components/IconText.vue';
import PromotedDateRangeDropdown from './components/PromotedDateRangeDropdown';

export default {
  components: {
    PromotedDateRangeDropdown,
    IconText,
  },
  data () {
    return {
      promoteDateObj: {
        field: SearchConstants.PROMOTED_CONTENT_FIELDS.FILTER,
        start: undefined,
        end: undefined,
      },
      accordianId: 'accordion-promoted-content',
    };
  },
  props: {
    group: String,
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('searchService/filters/promotedContent', [
      'promotedFilterEnable', 'promotedByDateRangeMode', 'promoteDateObject',
    ]),
    ...mapGetters('searchService/filters', [
      'selectedFilters',
    ]),
  },
  methods: {
    ...mapActions('searchService/filters/promotedContent', [
      'setPromotedContentFilterEnabled', 'setPromotedContentDateObject', 'setPromotedDateRangeMode',
      'resetPromotedDateConfig',
    ]),
    handleDateRangeChange (selectedDateRangeMode, selectedDateRange) {
      this.setPromotedDateRangeMode(selectedDateRangeMode);
      if (!(_isEmpty(selectedDateRange))) {
        this.setPromotedContentDateObject(selectedDateRange);
        this.$emit('date-range-change');
      }
    },
    handleShowPromotedContent (value) {
      this.setPromotedContentFilterEnabled(value);
      if (!value) {
        this.resetPromotedDateConfig();
      }
      this.handlePromotedContentFilterChange();
    },
    handlePromotedContentFilterChange () {
      const promotedContentParams = {};
      const promotedFieldName = SearchConstants.PROMOTED_CONTENT_FIELDS.FILTER;
      const promoteFilterField = {
        groupName: promotedFieldName,
        key: this.promotedFilterEnable,
      };

      promotedContentParams.filter = promoteFilterField;
      promotedContentParams.checked = this.promotedFilterEnable;

      this.$emit('filter-change', promotedContentParams);
    },
    handleCollapseInput (isOpen) {
      this.$emit('toggle-open', {
        isOpen,
        groupName: this.group,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.promoted-content-filter {
  &__header {
    padding: $gap-md;
    display: flex;
    justify-content: space-between;

    .promoted-content-filter-title {
      font-weight: $weight-semi-bold;
      color: $blue-gray-500;
      font-size: $font-md;

      &--filter-applied {
        color: $blue-500;
      }
    }
  }

  &--open {
    .promoted-content-filter__header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__body {
    padding: 0px $gap-md $gap-md $gap-md;
  }

  &__dropdown {
    margin-top: 5px;
    margin-left: 25px;
  }

  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
}
</style>
