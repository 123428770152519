// IE11 Polyfil fix
import 'document-register-element/build/document-register-element';
import 'isomorphic-fetch';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@babel/polyfill';

import VueDOMPurifyHTML from 'vue-dompurify-html';
import Vue from 'vue';
import vueCustomElement from 'vue-custom-element'; // https://github.com/karol-f/vue-custom-element
import Storage from 'vue-web-storage'; // https://github.com/ankurk91/vue-web-storage
import _ from 'lodash';
import BootstrapVue from 'bootstrap-vue';
import vClickOutside from 'v-click-outside';
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Clipboard from 'v-clipboard';

import App from './App.vue';
import ConfigService from './services/config.service';
import router from './router';
import store from './store/index';
import widgetCatalogue from './services/widget-catalogue.service';
import ComponentsPlugin from './plugins/component';
import './assets/fontawesome.config';
import { initApmAgent } from './apm-server';
// will delete
import searchConfig from '../public/search-ui.config.json';
// will delete
Vue.prototype.$config = searchConfig;

Vue.prototype._ = _;
Vue.use(VueDOMPurifyHTML);
Vue.use(vClickOutside);
Vue.use(BootstrapVue);
Vue.use(ComponentsPlugin);
Vue.use(Storage, {
  prefix: 'jhi-',
});
Vue.use(vueCustomElement);
Vue.use(VueFlatPickr);
Vue.use(Clipboard);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

if (ConfigService.getApmAgentEnabled()) {
  initApmAgent();
}

// /////////////////////////////////////////////////////
//  Get docs components and Register Custom Elements //
// /////////////////////////////////////////////////////
widgetCatalogue.registerCustomElements(store);


// Setup Wogaa
const wogaaScript = document.createElement('script');
if (window.location.origin === 'https://icrs.lawnet.com') {
// For Production
  wogaaScript.setAttribute('src', 'https://assets.wogaa.sg/scripts/wogaa.js');
} else if (ConfigService.getUseWOGAA()) {
// For Test
  wogaaScript.setAttribute('src', 'https://assets.dcube.cloud/scripts/wogaa.js');
}
document.querySelector('head').appendChild(wogaaScript);
