import Vue from 'vue';

export default {
  setDocumentContent (state, documentContent) {
    Vue.set(state, 'documentContent', documentContent);
  },
  setAllClass (state, allClass) {
    Vue.set(state, 'allClass', allClass);
  },
  setIndividualClass (state, individualClass) {
    Vue.set(state, 'individualClass', individualClass);
  },
  setBlacklistedField (state, blacklistedField) {
    Vue.set(state, 'blacklistedField', blacklistedField);
  },
};
