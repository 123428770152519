<template>
  <div>
    <span class="text-md font-secondary">Date range</span>
    <b-dropdown
      no-caret
      class="date-range-dropdown"
      @hide="handleDropdownHide"
      :class="{ 'date-range-dropdown__extend': showCalendarRange }"
      ref="dateDropdown"
    >
      <template #button-content>
        <Input
          id="input-8"
          icon="caret-down"
          :value="dropdownDisplayText"
          placeholder="Please select a date range"
        />
      </template>
      <div :class="{ 'dropdown-item-container': showCalendarRange}">
        <b-dropdown-item
          v-for="option in dateRangeOptions"
          :key="option.mode"
          @click="handleDateRangeSelected(option)"
        >{{ option.text }}</b-dropdown-item>
      </div>
      <div
        v-if="showCalendarRange"
        class="calendar-range"
      >
        <flatPickr
          :config="config"
          v-model="defaultDateRange"
          @on-change="handleCalendarOnChange"
        />
      </div>
    </b-dropdown>
  </div>
</template>
<script>
import moment from 'moment';
import _isUndefined from 'lodash/isUndefined';
import flatPickr from 'vue-flatpickr-component';
import constant from '../../../constants/search-service-constants';
import 'flatpickr/dist/flatpickr.css';
import {
  getDateRangeByRangeMode, getStartOfDay, getEndOfDay, formatDate, getPromoteDateRangeLabel, formatDateRangeObject,
} from '../../../helpers/promoted-content-helpers';

export default {
  components: {
    flatPickr,
  },
  data () {
    const { PROMOTED_DATE_RANGE_OPTIONS, PROMOTED_DATE_RANGE_MODE, PROMOTED_CONTENT_FIELDS } = constant;
    return {
      dateRangeOptions: PROMOTED_DATE_RANGE_OPTIONS,
      dateRangeModes: PROMOTED_DATE_RANGE_MODE,
      showCalendarRange: false,
      config: {
        dateFormat: 'j M Y',
        inline: true,
        mode: 'range',
      },
      startDate: formatDate(getStartOfDay(moment())),
      endDate: formatDate(getEndOfDay(moment())),
      defaultDateRange: [formatDate(getStartOfDay(moment())), formatDate(getEndOfDay(moment()))],
      displayDateRangeText: '',
      dateRangeIsSelected: false,
      promotedContentFilterFields: PROMOTED_CONTENT_FIELDS,
    };
  },
  props: {
    promotedByDateRangeMode: {
      type: String,
    },
    promoteDateObject: {
      type: Object,
    },
  },
  computed: {
    dropdownDisplayText () {
      if (this.promotedByDateRangeMode !== '') {
        if (this.promotedByDateRangeMode === this.dateRangeModes.CUSTOM_RANGE) {
          return getPromoteDateRangeLabel(this.promotedByDateRangeMode, this.promoteDateObject.start,
            this.promoteDateObject.end);
        }
        return this.dateRangeOptions[this.promotedByDateRangeMode].text;
      }
      return '';
    },
  },
  methods: {
    handleDateRangeSelected (selectedOption) {
      const selectedDateRange = getDateRangeByRangeMode(selectedOption.mode);
      this.$emit('change', selectedOption.mode, selectedDateRange);
    },
    handleDropdownHide (event) {
      const isCustomRangeSelected = this.promotedByDateRangeMode === this.dateRangeModes.CUSTOM_RANGE;
      if (isCustomRangeSelected && !this.dateRangeIsSelected) {
        event.preventDefault();
        this.showCalendarRange = true;
      } else if (isCustomRangeSelected && this.dateRangeIsSelected) {
        this.showCalendarRange = false;
        this.dateRangeIsSelected = false;
        this.endDate = _isUndefined(this.endDate) ? this.startDate : this.endDate;
        const formattedDateRangeObj = formatDateRangeObject(this.startDate, this.endDate,
          this.promotedContentFilterFields.FILTER);
        this.$emit('change', this.promotedByDateRangeMode, formattedDateRangeObj);
      }

      if (!isCustomRangeSelected) {
        this.showCalendarRange = false;
        this.dateRangeIsSelected = false;
      }
    },
    handleCalendarOnChange (selectedDates) {
      [this.startDate, this.endDate] = selectedDates;
      // format date DD MM YYYY in order to convert to UTC ISO formate correctly later
      this.startDate = formatDate(this.startDate);
      this.endDate = formatDate(this.endDate);
      if (selectedDates.length > 1) {
        this.defaultDateRange = [this.startDate, this.endDate];
        this.$refs.dateDropdown.hide();
        this.dateRangeIsSelected = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.date-range-dropdown {
  /deep/ button {
    background-color: transparent;
    border: none;
    padding: none;
    width: 225px;

    &,
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border: none;
      padding: 0;
      box-shadow: none;
    }
  }

  /deep/ input {
    cursor: pointer;
  }

  &__extend {
    /deep/ ul {
      display: flex !important;
      width: 500px;
      left: -150px !important;
    }
  }
}

.dropdown-item-container {
  width: 35%;
}

.calendar-range {
  height: inherit;
  display: block;
  width: 65%;

  input {
    display: none;
  }

  /deep/ .flatpickr-calendar {
    box-shadow: none;
  }
}
</style>
