/* eslint no-param-reassign: ["error", { "props": false }] */
import _ from 'lodash';
import ICRSService from '@/services/icrs-service';
import ConfigService from '@/services/config.service';
import SearchService from '../../../services/search.service';
import SearchServiceTypes from '../../../constants/search-service-constants';
import SearchParameter from '../../../services/models/search/search-parameter.model';
import SearchRequestParameter from '../../../services/models/search/search-request-parameter.model';
import SearchResultModel from '../../../services/models/search/search-result.model';
import { getRelevantFilterGroups, getOriginAggregations } from '../../../helpers/search-filters';
import AggregateParameter from '../../../services/models/search/aggregate-parameter.model';
import { formatSlashDateYearMonthDay, formatStartDateToUTC, formatEndDateToUTC } from '../../../helpers/date-filters';

const USE_ICRS_CONFIG = ConfigService.useICRSConfig();

export default {
  execGetAutocomplete (ctx, { textToComplete, type }) {
    const params = {
      searchText: textToComplete,
      type,
    };
    return SearchService.getAutocomplete(params).then(response => response.data);
  },
  /**
   * Build autocomplete list from map of autocomplete source to list of terms
   */
  buildAutocomplete (ctx, { autocompleteSuggest, autocompleteMetrics }) {
    let mergedAutocompletes = [];
    if (!autocompleteMetrics) {
      mergedAutocompletes = mergedAutocompletes.concat(mergedAutocompletes, autocompleteSuggest.slice(0, 10));
    } else if (!autocompleteSuggest) {
      mergedAutocompletes = mergedAutocompletes.concat(mergedAutocompletes, autocompleteMetrics.slice(0, 10));
    } else {
      const map = new Map();
      autocompleteSuggest.slice(0, 10).forEach((item) => { map.set(item.suggest, item); });
      autocompleteMetrics.every((item) => {
        if (!(map.has(item.suggest))) {
          map.set(item.suggest, item);
        }
        return map.size < 10;
      });
      mergedAutocompletes = Array.from(map.values());
    }
    return mergedAutocompletes.map(item => item.suggest);
  },
  /**
   * Refresh search results with current state
   */
  triggerSearchRefresh ({ state, dispatch }) {
    dispatch('triggerSearchQuery', {
      query: state.currentQuery,
      searchMode: SearchServiceTypes.SEARCH_MODE.PAGINATION,
    });
  },
  setCurrentPage ({ commit }, page) {
    commit('setCurrentPage', page);
  },
  setAppliedSort ({ commit }, sort) {
    commit('setAppliedSort', sort);
  },
  setCurrentQuery ({ commit }, query) {
    commit('setCurrentQuery', query);
  },
  triggerSearchWithinQuery ({ state, dispatch }) {
    return dispatch('triggerSearchQuery', {
      query: state.currentQuery,
      searchMode: SearchServiceTypes.SEARCH_MODE.SEARCH_WITHIN,
      resetSources: true,
      appliedSort: state.appliedSort,
    });
  },
  /**
   * Do search with provided query
   * Can be triggered from Searchbox / Filters / Date Range
   */
  triggerSearchQuery (
    { commit, dispatch },
    {
      query,
      searchMode = SearchServiceTypes.SEARCH_MODE.NEW,
      resetSources = false,
      appliedSynonyms = null,
      selectedSource,
      appliedSort,
      itemsPerPage,
      currentPage,
    },
  ) {
    // eslint-disable-next-line no-unused-vars
    let doResetSources = resetSources;
    if (!query || !query.length) {
      return Promise.resolve();
    }

    commit('setAppliedSort', appliedSort || {});
    commit('setItemsPerPage', itemsPerPage || SearchServiceTypes.RESULTS_PER_PAGE);
    commit('setCurrentQuery', query);
    commit('setCurrentSearchMode', searchMode);
    commit('setAppliedSynonyms', appliedSynonyms);
    if (searchMode === SearchServiceTypes.SEARCH_MODE.NEW) {
      commit('searchService/filters/date/setDateObject', { start: undefined, end: undefined }, { root: true });
      commit('setCurrentDateObject', undefined);
      commit('searchService/filters/setSelectedFilters', [], { root: true });
      commit('setSearchWithin', []);
      dispatch('searchService/filters/promotedContent/resetPromotedDateConfig', {}, { root: true });
      dispatch('setCurrentDateObject', {});
      doResetSources = true; //  new query always resets the sources tabs
    }

    if (searchMode !== SearchServiceTypes.SEARCH_MODE.PAGINATION) {
      commit('setCurrentPage', currentPage || 1);
    }

    if (doResetSources) {
      commit('setSelectedSource', selectedSource || undefined);
    }

    const promise = dispatch('executeSearchQuery');

    if (doResetSources) {
      // execute categories should be happen after executeSearchQuery
      // cos, executeSearchQuery is setting the query object
      // todo: make the prosedure more granular
      if (!USE_ICRS_CONFIG) {
        dispatch('executeCategories');
      }
    }

    return promise;
  },
  setCurrentSearchMode ({ commit }, searchMode) {
    commit('setCurrentSearchMode', searchMode);
  },
  setSelectedSource ({ commit }, source) {
    commit('setSelectedSource', source);
  },
  setItemsPerPage ({ commit }, number) {
    commit('setItemsPerPage', number);
  },
  getFilterGroupConfigs ({ commit }) {
    return SearchService.getAggregationConfigs()
      .then((data) => {
        commit('setFilterGroupConfigs', data);
      });
  },
  getFilterGroups ({ commit, state }, param) {
    commit('setFilterGroupFetchingStatus', true);
    return SearchService.getAggregations({
      ...param,
      searchMode: 'AGGREGATION',
    })
      .then((result) => {
        // get display filter and other message
        // result.aggregations            return data
        // state.filterGroupConfigs       data
        const data = getRelevantFilterGroups(result.aggregations, state.filterGroupConfigs);
        commit('setFilterGroups', data);
      })
      .catch((error) => {
        commit('setFilterGroups', []);
      })
      .finally(() => {
        commit('setFilterGroupFetchingStatus', false);
      });
  },
  /**
   *
   * @param {*} ctx
   * @param {*} payload
   * @param {Array<object>} payload.includes array of filter
   * @param {Array<object>} payload.excludes array of filter
   */
  async triggerShowMoreFilterSelect ({
    dispatch, commit, getters, state,
  }, payload) {
    const aggregateParam = new AggregateParameter(state.currentQuery, state.currentSearchParameter, {
      includes: payload.includes,
      excludes: payload.excludes,
      tempTopicsQuery: getters['filters/filterModalTempTopicsQuery'],
    });
    commit('filters/setModalTempTopicsQuery', aggregateParam.topicsQuery);
    await dispatch('getFilterGroupsLevelTwo', aggregateParam);
  },
  /**
   *
   * @param {*} ctx
   * @param {*} payload
   * @param {Array<object>} payload.includes array of filter
   * @param {Array<object>} payload.excludes array of filter
   */
  triggerFilterSelect ({ dispatch, state }, payload) {
    const aggregateParam = new AggregateParameter(state.currentQuery, state.currentSearchParameter, payload);
    dispatch('getFilterGroupsLevelTwo', aggregateParam);
  },
  /**
   * @param {*} ctx
   * @param {import('@models/search/aggregate-parameter.model').default} aggregateParam
   */
  getFilterGroupsLevelTwo ({ state, commit }, aggregateParam) {
    commit('setFilterGroupFetchingStatus', true);
    // new
    commit('setCurrentSearchMode', SearchServiceTypes.SEARCH_MODE.FILTER);
    // origin
    // return SearchService.getAggregationsLevelTwo(aggregateParam)
    //   .then((result) => {
    //     const data = getRelevantFilterGroups(result.aggregations, state.filterGroupConfigs);
    //     commit('setFilterGroups', data);
    //     commit('setCurrentSearchMode', SearchServiceTypes.SEARCH_MODE.FILTER);
    //   })
    //   .finally(() => {
    //     commit('setFilterGroupFetchingStatus', false);
    //   });
  },
  updateCurrentSearchParameter ({
    commit, state, rootGetters, rootState,
  }) {
    const param = new SearchParameter(
      state.currentSearchMode,
      rootGetters['searchService/filters/appliedFilters'],
      state.currentDateObject,
      state.currentPage,
      state.appliedSort,
      state.selectedSource,
      state.appliedSynonyms,
      state.itemsPerPage,
      state.currentSearchResults.id,
      state.searchWithin,
      state.promoteDateObject,
    );
    commit('setCurrentSearchParameter', param);
  },
  addSearchWithin ({ commit }, text) {
    commit('addSearchWithin', text);
  },
  removeSearchWithin ({ commit }, text) {
    commit('removeSearchWithin', text);
  },
  getSavedSearch (ctx, criteria) {
    return SearchService.getSavedSearch(criteria);
  },
  async searchForSavedSearch ({ commit, dispatch, getters }, request) {
    const {
      query,
      size,
      sortBy,
      source,
      rangeQuery,
      synonyms = null,
      from,
      searchWithin,
      topicsQuery = { must: [], should: [] },
    } = request;

    if (!query.length) {
      return;
    }
    commit('setCurrentPage', from);
    commit('setAppliedSort', sortBy);
    commit('setItemsPerPage', size);
    commit('setCurrentQuery', query);
    commit('setCurrentSearchMode', SearchServiceTypes.SEARCH_MODE.SAVE_SEARCH);
    commit('setAppliedSynonyms', synonyms);
    commit('setSearchWithin', SearchParameter.convertSearchWithinAsText(searchWithin));
    commit('setSelectedSource', Array.isArray(source) && !source.length ? undefined : source);

    /**
     * @description resets filters, date query and promoted content initially to do heavier operations down
     */
    commit('searchService/filters/setSelectedFilters', [], { root: true });
    dispatch('searchService/filters/date/updateCurrentDate', {
      start: undefined,
      end: undefined,
      field: SearchServiceTypes.DATE_FILER_FIELDS.FILTER,
    }, { root: true });
    dispatch('searchService/filters/promotedContent/resetPromotedDateConfig', {}, { root: true });

    const rangeQueryMap = _.groupBy(rangeQuery, 'field');

    if (rangeQueryMap.lastModifiedDate) {
      const dateRangeItem = {
        start: undefined,
        end: undefined,
        field: SearchServiceTypes.DATE_FILER_FIELDS.FILTER,
      };
      rangeQueryMap.lastModifiedDate.forEach((element) => {
        if (element.gte) {
          const gte = element.gte.includes('/') ? formatSlashDateYearMonthDay(element.gte) : element.gte;
          dateRangeItem.start = formatStartDateToUTC(gte);
        } else {
          const lte = element.lte.includes('/') ? formatSlashDateYearMonthDay(element.lte) : element.lte;
          dateRangeItem.end = formatEndDateToUTC(lte);
        }
      });
      dispatch('searchService/filters/date/updateCurrentDate', dateRangeItem, { root: true });
    }

    if (rangeQueryMap[SearchServiceTypes.PROMOTED_CONTENT_FIELDS.DATE_FROM]
      && rangeQueryMap[SearchServiceTypes.PROMOTED_CONTENT_FIELDS.DATE_TO]) {
      /**
       * promote period >= start date (metadata.promote_to) and <= end date (metadata.promote_from)
       */
      const startDateObj = rangeQueryMap[SearchServiceTypes.PROMOTED_CONTENT_FIELDS.DATE_TO].reduce(obj => obj);
      const endDateObj = rangeQueryMap[SearchServiceTypes.PROMOTED_CONTENT_FIELDS.DATE_FROM].reduce(obj => obj);
      const startDate = formatStartDateToUTC(startDateObj.gte);
      const endDate = formatEndDateToUTC(endDateObj.lte);

      /**
      * @type {import('@typings/search').DateRangeItem}
      */
      const promotedDateRangeItem = {
        field: SearchServiceTypes.PROMOTED_CONTENT_FIELDS.FILTER,
        start: startDate,
        end: endDate,
      };

      dispatch('searchService/filters/promotedContent/setPromotedContentFilterForSavedSearch',
        promotedDateRangeItem, { root: true });
    }

    /**
     * @todo revisit and check if block here can be refactored with the lastModifiedDate operations
     */
    request.rangeQuery.forEach((element) => {
      if (element.gte) {
        const gte = element.gte.includes('/') ? formatSlashDateYearMonthDay(element.gte) : element.gte;
        element.gte = formatStartDateToUTC(gte);
      }
      if (element.lte) {
        const lte = element.lte.includes('/') ? formatSlashDateYearMonthDay(element.lte) : element.lte;
        element.lte = formatEndDateToUTC(lte);
      }
    });
    const icrsTopicsMust = [...topicsQuery.must];
    if (USE_ICRS_CONFIG) {
      request.topicsQuery.must = [];
      request.searchWithin = [];
    }
    try {
      await dispatch('getFilterGroupConfigs');
      // .then(() => dispatch('getFilterGroups', request));

      // set applied filters
      let queries;
      if (USE_ICRS_CONFIG) {
        queries = icrsTopicsMust;
      } else {
        queries = topicsQuery.must;
      }

      if (queries.length) {
        let appliedFilters;
        if (USE_ICRS_CONFIG) {
          appliedFilters = queries.map(({ field, value }) => ({
            groupName: field,
            key: value,
          }));
        } else {
          appliedFilters = queries.map(({ field, value }) => getters.filterItems.find(item => (
            item.groupName === field && item.key === value
          ))).filter(filter => filter !== undefined);
        }

        if (!USE_ICRS_CONFIG) {
          const queriesCopy = _.groupBy(queries, 'field');
          if (queriesCopy[SearchServiceTypes.PROMOTED_CONTENT_FIELDS.FILTER]) {
            const promotedContentFilter = {
              groupName: SearchServiceTypes.PROMOTED_CONTENT_FIELDS.FILTER,
              key: true,
            };

            appliedFilters.push(promotedContentFilter);
            dispatch('searchService/filters/promotedContent/setPromotedContentFilterEnabled', true, { root: true });
          } else {
            dispatch('searchService/filters/promotedContent/setPromotedContentFilterEnabled', false, { root: true });
          }
        }
        commit('searchService/filters/setSelectedFilters', appliedFilters, { root: true });

        // ICRS Save Search Note:
        if (USE_ICRS_CONFIG) {
          dispatch('searchService/executeSearchQuery', { filterGroupLevel: 2 }, { root: true });
        }
      } else {
        dispatch('executeSearchQuery');
      }
    } catch (err) {
      console.warn('Failed to setup filter group');
      if (USE_ICRS_CONFIG) {
        dispatch('executeSearchQuery');
      }
    }

    if (!USE_ICRS_CONFIG) {
      dispatch('executeSearchQuery');
    }
    // dispatch('executeCategories');
  },
  /**
   * @param {object?} payload
   * @param {number?} payload.filterGroupLevel
   */
  executeSearchQuery ({
    state, commit, rootGetters, dispatch,
  }, payload = {}) {
    /**
     * @todo trigger nlp search for answer
     */
    const param = new SearchParameter(
      state.currentSearchMode,
      rootGetters['searchService/filters/appliedFilters'],
      state.currentDateObject,
      state.currentPage,
      state.appliedSort,
      state.selectedSource,
      state.appliedSynonyms,
      state.itemsPerPage,
      state.currentSearchResults.id,
      state.searchWithin,
      state.promoteDateObject,
    );
    commit('setCurrentSearchParameter', param);

    if (USE_ICRS_CONFIG) {
      state.icrsAppliedFilters = rootGetters['searchService/filters/appliedFilters'];
    }
    return dispatch('executeSimpleSearch', payload);
  },

  /**
   * Query server search API
   * @param {object?} payload
   * @param {number?} payload.filterGroupLevel
   */
  executeSimpleSearch ({ state, commit, dispatch }, payload = {}) {
    const { filterGroupLevel = 1 } = payload;
    const searchRequestParameter = new SearchRequestParameter(state.currentQuery, state.currentSearchParameter);

    commit('setCurrentSearchRequestParameter', searchRequestParameter);
    commit('setIsSearching', true);


    dispatch('getFilterGroupConfigs')
      .then(() => {
        if (filterGroupLevel === 1) {
          if (state.currentQuery !== state.previousQuery) {
            // dispatch('getFilterGroups', searchRequestParameter);
          }
        } else if (filterGroupLevel === 2) {
          dispatch('triggerFilterSelect');
        }
      });

    const overallFilterQueryArray = [];
    const topicsQueryShould = [];
    const icrsRangeFilters = {};
    if (state.icrsAppliedFilters !== undefined) {
      // eslint-disable-next-line array-callback-return
      Object.keys(state.icrsAppliedFilters).map((filterTopic) => {
        const icrsFilterSettings = ICRSService.getFiltersTopicTitleDisplayOrder()[filterTopic];
        if (icrsFilterSettings !== undefined) {
          const icrsFilterType = icrsFilterSettings.type;
          if (icrsFilterType === 'checkbox' || icrsFilterType === 'dropdown') {
            const filterQueryArray = [];
            state.icrsAppliedFilters[filterTopic].forEach((filterValue) => {
              if (filterTopic === 'sex') {
                topicsQueryShould.push({ field: `tags.${filterTopic}`, value: filterValue });
              } else filterQueryArray.push(`(tags.${filterTopic}:"${filterValue}")`);
            });
            if (filterQueryArray.length) overallFilterQueryArray.push(`(${filterQueryArray.join(' OR ')})`);
          } else {
            icrsRangeFilters[filterTopic] = state.icrsAppliedFilters[filterTopic];
          }
        }
      });
    }

    const overallQueryArray = [state.currentQuery];
    if (overallFilterQueryArray.length > 0) {
      overallQueryArray.push(overallFilterQueryArray.join(' AND '));
    }
    if (state.searchWithin !== undefined && state.searchWithin.length > 0) {
      overallQueryArray.push(state.searchWithin.join(' AND '));
    }

    const icrsQuery = overallQueryArray.join(' AND ');

    const finalSearchRequestParameter = USE_ICRS_CONFIG
      ? new SearchRequestParameter(
        icrsQuery,
        new SearchParameter(
          state.currentSearchMode,
          undefined,
          state.currentDateObject,
          state.currentPage,
          state.appliedSort,
          state.selectedSource,
          state.appliedSynonyms,
          state.itemsPerPage,
          state.currentSearchResults.id,
          undefined,
          state.promoteDateObject,
          icrsRangeFilters,
          topicsQueryShould,
        ),
      )
      : searchRequestParameter;
    // for auto spellcorrect and show what we corrected along with the results.
    finalSearchRequestParameter.keepOriginSearchText = state.keepOriginSearchText;

    commit('setICRSSearchRequestParameter', finalSearchRequestParameter);
    /* new logic */
    commit('setFilterGroupFetchingStatus', true);
    return SearchService.postSimpleSearch(finalSearchRequestParameter)
      .then((response) => {
        const { data } = response;
        if (data.spellCorrectedQueries.length) {
          const suggestQueryText = data.spellCorrectedQueries[0].suggestedQueryText;
          commit('setSuggestedQueryText', suggestQueryText);
          commit('setSpellCorrectedQueries', data.spellCorrectedQueries[0]);
        } else {
          commit('setSpellCorrectedQueries', { corrections: [] });
        }
        const aggregations = getOriginAggregations(data.facetResults);
        commit('setFilterGroups', aggregations);
        commit('setCurrentSearchResults', new SearchResultModel(data));


        // todo
        // set current page to another value
        // if the page is bigger than last page
        // when user changed the items per page
        dispatch('executeGetSuggestion');
        dispatch('executeGetNaturalLanguageSearch');
        dispatch('executeGetCorrection');
        dispatch('checkSaveSearch');
        if (USE_ICRS_CONFIG) {
          commit('setICRSPlotSearching', true);
          dispatch('executeGetICRSPlotData');
        }

        // Event Tracking disabled
        if (!USE_ICRS_CONFIG) {
          dispatch('eventTracker/execSearchTrackerMetrics', null, { root: true });
        }
        commit('setIsSearchSuccess', true);
      })
      .catch((e) => {
        commit('setCurrentSearchResults', new SearchResultModel({}));
        commit('setSpellCorrectedQueries', { corrections: [] });
        commit('setIsSearchSuccess', true);
        commit('setFilterGroups', []);

        if (USE_ICRS_CONFIG) {
          commit('setICRSPlotData', {});
          commit('setICRSPlotSuccess', true);
        }
      })
      .finally(() => {
        commit('setIsSearching', false);
        commit('setFilterGroupFetchingStatus', false);
        commit('setKeepOriginSearchText', true);
      });
  },
  async executeGetICRSPlotData ({ state, commit }) {
    ICRSService.execGetCustomPlotData(state.icrsSearchRequestParameter)
      .then((plotResponse) => {
        commit('setICRSPlotData', plotResponse.data);
        commit('setICRSPlotSuccess', true);
      })
      .catch(() => {
        commit('setICRSPlotData', {});
        commit('setICRSPlotSuccess', false);
      })
      .finally(() => {
        commit('setICRSPlotSearching', false);
        commit('setPreviousQuery', state.currentQuery);
      });
  },
  async executeGetSuggestion ({ state, commit }) {
    const { currentQuery } = state;

    if (!currentQuery.length) return;

    // const suggestionData = await SearchService.execSuggestion(currentQuery).then(response => response.data);

    const suggestionData = [];

    if (_.isUndefined(suggestionData)) return;

    const suggestions = [];
    _.forEach(suggestionData, (suggestData) => {
      if (!_.isUndefined(suggestData.suggest)) {
        suggestions.push(suggestData.suggest);
      }
    });

    commit('setSuggestions', suggestions);
  },
  async executeGetCorrection ({ state, commit }) {
    const { currentQuery } = state;

    if (!currentQuery.length) return;

    const correctionData = await SearchService.execCorrection(currentQuery).then(response => response.data);

    if (_.isUndefined(correctionData)) return;

    const corrections = [];
    _.forEach(correctionData, (suggestData) => {
      if (!_.isUndefined(suggestData.suggest)) {
        corrections.push(suggestData.suggest);
      }
    });

    commit('setCorrections', corrections);
  },
  async executeGetNaturalLanguageSearch ({ state, commit }) {
    const { currentQuery } = state;

    if (!currentQuery.length) return;
    const naturalLanguageAnswer = [];
    // const naturalLanguageAnswer = await SearchService.execNaturalLanguageSearch(currentQuery);
    commit('setNaturalLanguageAnswer', naturalLanguageAnswer);
  },
  async executeCategories ({ state, commit }) {
    const mySearchParameter = _.omit(state.currentSearchParameter, 'source');
    const mySearchRequestParameter = new SearchRequestParameter(state.currentQuery, mySearchParameter);
    mySearchRequestParameter.searchMode = SearchServiceTypes.SEARCH_MODE.AGGREGATION;

    SearchService.execCategories(mySearchRequestParameter)
      .then((categories) => {
        commit('setCategories', categories);
      })
      .catch((error) => {
        commit('setCategories', {});
      });
  },
  checkSaveSearch ({ state, commit }) {
    if (state.currentSearchResults && !_.isUndefined(state.currentSearchResults.id)) {
      commit('setCanSaveSearch', true);
    } else {
      commit('setCanSaveSearch', false);
    }
  },
  execGetTextContent (ctx, { id }) {
    const params = { id };
    return SearchService.getTextContent(params);
  },
  execGetTrendingSearch (ctx, { lookbackDays = 30, size = 5 }) {
    const params = { lookbackDays, size };
    return SearchService.getTrendingSearch(params).then(response => response.data);
  },

  execGetDocumentDetails (ctx, { id }) {
    return SearchService.getDocumentDetails({ id }).then(response => response.data);
  },

  getSavedSearches ({ commit, rootState }) {
    const userId = rootState.authService.user.id;
    return SearchService.getUserSavedSearches(userId)
      .then((data) => {
        commit('setSavedSearches', data);
      });
  },

  createSavedSearch ({ state, commit }, name) {
    const payload = {
      name,
      request: {
        ...state.currentSearchRequestParameter,
        searchMode: SearchServiceTypes.SEARCH_MODE.SAVE_SEARCH,
        id: state.currentSearchResults.id,
      },
    };

    return SearchService.createUserSavedSearch(payload)
      .then((data) => {
        commit('addSavedSearch', data);
      });
  },

  updateSavedSearch ({ commit }, payload) {
    return SearchService.updateUserSavedSearch(payload)
      .then(() => {
        commit('updateSavedSearch', payload);
      });
  },

  removeSavedSearch ({ commit }, savedSearchId) {
    return SearchService.removeUserSavedSearch(savedSearchId)
      .then(() => {
        commit('removeSavedSearch', savedSearchId);
      });
  },

  /**
   * @param {import('@/services/models/search/search-result-document.model').default} document
   */
  execDocumentMetrics ({ getters, dispatch }, document) {
    /**
     * @type {import('@typings/search').DocumentMetrics}
     */
    const metricsObj = {
      idQuery: getters.searchResultId,
      idDoc: document.id,
      title: document.title,
      path: document.path,
      source: [document.source],
    };

    // Event Tracking disabled
    if (!USE_ICRS_CONFIG) {
      dispatch('eventTracker/execDocumentTrackerMetrics', { document }, { root: true });
    }

    return SearchService.execDocumentMetrics(metricsObj);
  },
  setCurrentDateObject ({ commit }, dateObject) {
    commit('setCurrentDateObject', dateObject);
  },
  setPromoteDateObject ({ commit }, dateObject) {
    commit('setPromoteDateObject', dateObject);
  },
  setKeepOriginSearchText ({ commit }, keepOriginSearchText) {
    commit('setKeepOriginSearchText', keepOriginSearchText);
  },
  resetSearch ({ commit }) {
    commit('resetState');
  },
  setSuggestedQueryText ({ commit }, suggestedQueryText) {
    commit('setSuggestedQueryText', suggestedQueryText);
  },
  uploadFile ({ commit }, file) {
    return SearchService.postUploadFile(file);
  },
  downloadExcelTemplate () {
    return SearchService.getDownloadExcel();
  },
  saveFile ({ commit }, payload) {
    return SearchService.postSaveFile(payload);
  },
  setSavedSearchFromHomePage ({ commit }, isSavedSearchFromHomePage) {
    commit('setSavedSearchFromHomePage', isSavedSearchFromHomePage);
  },
  setIsMobileOveralOpen ({ commit }, icrsOverallOpen) {
    commit('setMobileOveralOpen', icrsOverallOpen);
  },
};
