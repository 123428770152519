import moment from 'moment';

export function formatSlashDateDayMonthYear (date) {
  return moment.utc(date.split('/').reverse().join('-')).format('D MMM YYYY');
}

export function formatSlashDateYearMonthDay (date) {
  return moment.utc(date.split('/').reverse().join('-')).format('YYYY MMM D');
}

export function formatStartDateToUTC (date) {
  return moment.utc(date).startOf('day').toISOString();
}

export function formatEndDateToUTC (date) {
  return moment.utc(date).endOf('day').toISOString();
}

export function formatDateDayMonthYear (date) {
  return moment.utc(date).format('D MMM YYYY');
}

export function formatDateYearMonthDayWithHyphen (date) {
  return moment.utc(date).format('YYYY-MM-DD');
}
