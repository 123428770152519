/* eslint no-param-reassign: ["error", { "props": false }] */
// eslint-disable-next-line arrow-body-style
const loggerInterceptor = (response) => {
  /**
     * @todo add error handler
     */
  return response;
};


export default loggerInterceptor;
