import createState from '../state';
import mutations from '../mutations';

describe('search-core/filters/date vuex mutations test', () => {
  test('setStartDate', () => {
    const state = createState();
    const startDateFromAction = '2021-03-16T00:00:00.00Z';
    mutations.setStartDate(state, startDateFromAction);
    expect(state.start).toBe(startDateFromAction);
  });

  test('setEndDate', () => {
    const state = createState();
    const endDateFromAction = '2021-03-30T00:00:00.00Z';
    mutations.setEndDate(state, endDateFromAction);
    expect(state.end).toBe(endDateFromAction);
  });

  test('setDateObject', () => {
    const state = createState();
    const dateObj = {
      start: '2021-03-09T00:00:00.000Z',
      end: '2021-03-11T23:59:59.999Z',
      field: 'lastModifiedDate',
    };

    mutations.setDateObject(state, dateObj);
    expect(state.start).toBe(dateObj.start);
    expect(state.end).toBe(dateObj.end);
  });
});
