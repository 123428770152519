<template>
  <span>
    <b-button
      :id="label"
      size="md"
      :variant="variant"
      :disabled="disabled"
      class="redirect_button text-smoke"
      :href="fixLink('#'+ pathLink)"
      @click="onClick"
    >
      <div>{{label}}</div>
      <font-awesome-icon :icon="faIcon" />
    </b-button>
  </span>
</template>

<script>
import { sanitizeUrl } from '@braintree/sanitize-url';
const NEW_TAB = 'newTab';
const REDIRECT = 'redirect';
export default {
  props: {
    label: String,
    pathLink: String,
    variant: String,
    type: {
      default: REDIRECT, // redirect, newTab
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    faIcon () {
      if (this.type === NEW_TAB) {
        return ['fas', 'external-link-alt'];
      }
      return ['fas', 'arrow-right'];
    },
  },
  methods: {
    fixLink(url) {
      return sanitizeUrl(url);
    },
    onClick (event) {
      if (this.type === NEW_TAB) {
        window.open(this.pathLink);
      } else {
        this.$router.push(this.pathLink);
      }
      event.preventDefault();
    },
  },
};
</script>

<style scoped lang= 'scss'>
.redirect_button {
  width: 17em;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
</style>
