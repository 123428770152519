<template>
  <div id="place-holder-page">
    <div class="content">
      <div  class="placeholderImgWrap">
        <img :src="require('@/assets/ui-catalog-page/UnderDevelop.png')" id="placeholderImg">
        <div class="placeholderText">
          This page is under development
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Placeholder',
};
</script>


<style scoped lang="scss">
.placeholderImgWrap{
    text-align: center;
    padding: 200px;
}
#placeholderImg{
    width: 320px;
    height: 260px;
}
.placeholderText{
    padding: 20px 0;
}
</style>
