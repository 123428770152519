<template>
  <div>
    <div class="content">
      <div class="topBar">
        <div class="topic"> Search Bar </div>
      </div>
      <LiveDemo />
      <SearchBarMarkdown class="markdownStyle" />
    </div>
  </div>
</template>

<script>

import LiveDemo from './SearchBarLiveDemo.vue';
import SearchBarMarkdown from './SearchBarModule.md';

export default {
  name: 'SearchBarModule',
  components: {
    SearchBarMarkdown,
    LiveDemo,
  },
};
</script>


<style scoped lang="scss">
@import "../../markdown.scss";
.topBar {
  height: 152px;
  background: $blue-200;
}
.topic {
  padding: 60px 100px;
  font-size: 36px;
  font-weight: $weight-normal;
}
</style>
