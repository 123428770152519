<template>
  <div class="d-flex flex-column align-items-center">
    <div class="banner">
      <div class="text-lg text-center title">{{ this.config.title }}</div>
      <b-tabs
        active
        class="tab-links"
      >
        <b-tab
          id="mainTab"
          title="Search in Knowledge Graphs"
        >
          <AdvancedSearchBar
            ref="advancedSearchBar"
            class="my-4"
            @on-search="onSearch"
            @on-click-badge="onClickBadge"
          />
        </b-tab>
        <b-tab title="Search Articles">
          <SearchBar
            class="w-100 my-4"
            search-input-placeholder="Search articles"
            @submit="handleSubmit"
          />
        </b-tab>
      </b-tabs>
    </div>
    <div class="alt-content">
      <TrendingTopics @trending-topic-click="trendingTopicClick" />
      <AdvancedSearchModal :showButton="showAdvancedSearchModalButton" />
    </div>
  </div>
</template>
<script>
import AdvancedSearchBar from '../advanced-search/components/AdvancedSearchBar';
import TrendingTopics from '../advanced-search/components/TrendingTopics';
import AdvancedSearchModal from '../advanced-search/AdvancedSearchModal';
import SearchBar from '../search-bar/SearchBar.vue';

export default {
  name: 'AltHomePage',
  data () {
    return {
      title: 'DSTA Technology Assessment Platform',
      showAdvancedSearchModalButton: false,
      config: {
        title: 'Hi! What insights would you like?',
      },
    };
  },
  components: {
    AdvancedSearchBar,
    TrendingTopics,
    AdvancedSearchModal,
    SearchBar,
  },
  mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
  },
  methods: {
    onSearch () {
      this.$bvModal.show('advanced-search-modal');
    },
    trendingTopicClick ({ label, individualId }) {
      this.$refs.advancedSearchBar.onIndividualSelect({ suggest: label, id: individualId });
    },
    handleSubmit () {
      this.$router.push('/faceted-search');
    },
    onClickBadge () {
      this.$bvModal.show('advanced-search-modal');
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  height: 32vh;
  background: $blue-gray-500 url("~@/assets/alt-home-page/alt-home-banner.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  color: $white-500;
  font-size: 32px;
  display: flex;
  justify-content: center;
  margin-top: 1em;
}
/deep/ .nav-tabs {
  border-bottom: 0;
}
/deep/ .tab-links {
  min-width: 40vw;
  margin: 1em;
  align-self: center;
  ul li a {
    border: 0 !important;
    font-size: 14px;
    background-color: transparent !important;
    &:active,
    &:focus,
    &:hover,
    &:not(.disabled) {
      color: $white-500 !important;
      border-bottom: 1px solid $white-500 !important;
    }
    &:not(.active) {
      color: $white-500 !important;
      border-bottom: 0 !important;
    }
  }
}
.alt-content {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2em;
}
</style>
