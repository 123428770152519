<template>
  <form
    @submit.prevent="triggerLogin"
    class="login-form"
  >
    <h1
      v-if="!useICRSConfig"
      class="title"
    >Sign In</h1>
    <h1 v-if="useICRSConfig">ICRS</h1>
    <h4 v-if="useICRSConfig">Intelligent Case Retrieval System</h4>

    <div
      class="login-message"
      :class="{
        'login-message--error': isFail,
        'login-message--success': isSuccess,
      }"
    >
      {{ message }}
    </div>
    <div v-if="!SALAuthEnabled && !SAMLAuthEnabled">
      <div>
        <Input
          type="text"
          id="username"
          label="Username"
          v-model="username"
          data-cy="username"
        />
      </div>
      <div class="mt-lg password-row">
        <Input
          type="password"
          id="password"
          label="Password"
          v-model="password"
          data-cy="password"
        />
      </div>

      <div class="mt-lg sign-in-button">
        <Button
          :disabled="!isFilled || isProcessing"
          type="submit"
          block
          variant="sign-in"
          data-cy="submit"
        >Sign in</Button>
      </div>
    </div>
    <div
      v-else
      class="mt-lg"
    >
      <Button
        class="icrs-login-button"
        type="submit"
        block
        variant="sign-in"
        data-cy="submit"
      >{{buttonLabel}}</Button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConfigService from '@/services/config.service';

export default {
  data () {
    return {
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
      password: undefined,
      username: undefined,
      redirect: '',
      SAMLAuthEnabled: ConfigService.getSAMLAuthEnabled(),
      SALAuthEnabled: ConfigService.getSALAuthEnabled(),
    };
  },
  computed: {
    ...mapGetters('authService', {
      user: 'getUser',
    }),
    ...mapGetters('authService', [
      'message',
      'isProcessing',
      'isFail',
      'isSuccess',
    ]),
    isFilled () {
      return Boolean(this.username && this.password);
    },
    buttonLabel () {
      if (this.SALAuthEnabled) {
        return 'Sign in with LawNet';
      }
      return 'SAML Sign In';
    },
  },
  methods: {
    ...mapActions('authService', [
      'login',
      'setErrorMessage',
    ]),
    checkError () {
      const { error } = this.$route.query;
      this.setErrorMessage(error);
    },
    checkRedirect () {
      const { redirect } = this.$route.query;
      if (!redirect) return;
      this.redirect = redirect;
    },
    triggerLogin () {
      if (this.SAMLAuthEnabled) {
        window.open(ConfigService.getICRSSAMLLoginUrl(), '_self');
      } else if (this.SALAuthEnabled) {
        window.open(ConfigService.getICRSSALLoginUrl(), '_self');
      } else {
        // todo: validate the inputs
        this.login({
          rememberMe: false,
          password: this.password,
          username: this.username,
        })
          .then(() => {
            this.$emit('logged-in', { isLoggedIn: true, redirect: this.redirect });
          })
          .catch((err) => {
            this.$emit('logged-in', { isLoggedIn: false });
            this.password = '';
            throw err;
          });
      }
    },
  },
  mounted () {
    this.checkError();
    this.checkRedirect();
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  padding: $gap-lg;
}

.login-message {
  font-weight: $weight-normal;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: $gap-md;
  min-height: 16px;

  &--success {
    color: $green-500;
  }

  &--error {
    color: $red-500;
  }
}

.dev-message {
  margin-top: $gap-lg;
  color: $green-500;
  font-size: 12px;
}

.title {
  font-weight: $weight-semi-bold;
  font-size: 36px;
  line-height: 35px;
  margin-bottom: $gap-xs;
}

.icrs-login-button {
  height: 50px;
  /deep/ span {
    font-size: $font-lg;
  }
}

.password-row{
    margin-top: 5px;
  }
  .sign-in-button {
    margin-top: 25px;
  }
</style>
