import getDefaultState from './state';

export default {
  setInputIndividual (state, inputIndividual) {
    state.inputIndividual = inputIndividual;
  },
  setIndividualId (state, individualId) {
    state.individualId = individualId;
  },
  setSelectedClass (state, selectedClass) {
    state.selectedClass = selectedClass;
  },
  setIndividualSearchSuggestMap (state, individualSearchSuggestMap) {
    state.individualSearchSuggestMap = individualSearchSuggestMap;
  },
  setFilterGroups (state, filterGroups) {
    state.filterGroups.data = filterGroups;
  },
  setFilterGroupFetchingStatus (state, flag) {
    state.filterGroups.isFetching = flag;
  },
  setFilterGroupConfigs (state, configs) {
    state.filterGroupConfigs = configs;
  },
  setCurrentSearch (state, currentSearch) {
    state.currentSearch = currentSearch;
  },
  setIsLoading (state, isLoading) {
    state.isLoading = isLoading;
  },
  setAdvancedSearchModalOpen (state, advancedSearchModalOpen) {
    state.advancedSearchModalOpen = advancedSearchModalOpen;
  },
  setSelectedFilterGroup (state, selectedFilterGroup) {
    state.selectedFilterGroup = selectedFilterGroup;
  },
  resetState (state) {
    Object.assign(state, getDefaultState());
  },
  setSearchShowOverview (state, isShowOverview) {
    state.isShowOverview = isShowOverview;
  },
};
