/* eslint no-param-reassign: ["error", { "props": false }] */
import Qs from 'qs';
import { X_CSRF_TOKEN, USER_OBJECT_TOKEN } from '@/constants/auth-service-constants';
import TokenService from '../storage.service';

const handleDataObject = (config, user) => {
  const isDataObject = _.isObject(config.data);
  /**
   * only need user authentication if post / delete / put
   */
  const isMethodValid = ['POST', 'DELTE', 'PUT'].includes(config.method.toUpperCase());

  if (isMethodValid && isDataObject) {
    config.data = config.data || {};
    if (user) {
      config.headers['USER-OBJECT'] = JSON.stringify(user);
      const isNotContainCriteriaOrShare = (
        config.url.indexOf('/criteria') === -1
          && config.url.indexOf('/share') === -1
      );
      if (isNotContainCriteriaOrShare) {
        config.data.user = user;
      } else if (!_.isUndefined(config.data.request)) {
        //  for criteria requests
        config.data.request.user = {};
      }
    }
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      config.data = Qs.stringify(config.data, { encode: false });
    }
  }

  return config;
};

/** Auth token interceptors */
const authInterceptor = (config) => {
  if (!config || !config.url) return config;

  const csrfToken = TokenService.getCsrfTokenUi();
  const user = TokenService.getUserModel();

  if (csrfToken) {
    config.headers[X_CSRF_TOKEN] = csrfToken;
  }

  if (user) {
    config.headers[USER_OBJECT_TOKEN] = JSON.stringify(user);
  }

  return handleDataObject(config, user);
};

export default authInterceptor;
