<template>
  <div :class="{
    'form-check': useICRSConfig,
    'icrs-form-check': useICRSConfig,
  }">
    <label
      v-if="!useICRSConfig"
      class="filter-item"
      :for="`${idPrefix}-${id}`"
    >
      <Checkbox
        :value="applied"
        @change="handleChange"
        :id="`${idPrefix}-${id}`"
      />
      <div
        :id="`text-wrapper-${id}`"
        class="text-wrapper"
      >
        <span>{{ elitLabel }}</span>
        <Tooltip
          :delay="1000"
          :container="`text-wrapper-${id}`"
          :target="`text-wrapper-${id}`"
        >
        {{ elitLabel }}
        </Tooltip>
      </div>
      <span
        v-if="!filterCountDisabled"
        class="ml-2 filter-badge"
      >{{ item.docCount }}</span>
    </label>
    <b-form-checkbox
      v-if="useICRSConfig"
      size="lg"
      button-variant="primary"
      :checked="applied"
      @change="handleChange"
      :name="`${idPrefix}-${id}`"
      :id="`${idPrefix}-${id}`"
      class="icrs-form-check"
    >
      <div class="icrs-form-check-div">
        <span class="icrs-form-check-text">
          {{ elitLabel }}
        </span>
        <span
          v-if="!filterCountDisabled"
          class="ml-2 filter-badge jds-tag has-primary-light icrs-filter-badge"
        >{{ item.docCount }}</span>
      </div>
    </b-form-checkbox>
  </div>
</template>

<script>

export default {
  props: {
    item: Object,
    idPrefix: {
      type: String,
      default: 'item',
    },
    applied: Boolean,
    groupName: String,
    filterCountDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
      icrsApplied: false,
    };
  },
  computed: {
    id () {
      return `${this.groupName}_${this.item.key}`;
    },
    elitLabel () {
      const value = this.item.key.split(' ; ');

      if (value.length > 1){
        return value[1];
      }
      else {
        return value[0]
      }
    }
  },
  methods: {
    handleChange (event) {
      this.$emit('change', {
        filter: {
          ...this.item,
          groupName: this.groupName,
        },
        checked: event,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-item {
  margin-bottom: 0;
  font-weight: $weight-normal;
  display: flex;
  align-items: center;
  height: 20px;
  width: 220px;
  font-size: $font-md;
  color: $blue-gray-400;
  font-weight: $weight-normal;
  line-height: 20px;

  .text-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.filter-badge {
  display: inline-block;
  background: $blue-gray-100;
  color: $blue-gray-300;
  min-width: 16px;
  height: 16px;
  padding: 0 4px;
  border-radius: 12px;
  font-size: 12px;
  line-height: 16px;
}
</style>
