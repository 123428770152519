<template >
  <IcrsTemplate>
    <template #content>
      <h2 class="text-center">Useful Links</h2>
      <div class="icrs-useful-links">
        <div class="container-fluid">
          <div class="row">
            <div
              v-for="(data, index) in usefulLinks.links"
              :key="`link_${index}`"
              class="col-md-6 col-lg-4"
            >
              <a
                :href="fixLink(data.url)"
                target="_blank"
                rel="noopener"
                class="icrs-useful-link-item"
              >
                <div class="icrs-useful-link-card">
                  <h4>
                    {{ data.acronym }}&nbsp;
                    <font-awesome-icon
                      :icon="['fal', 'external-link']"
                      size="lg"
                    />
                  </h4>
                  <span>{{ data.name }}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </IcrsTemplate>
</template>
<script>
import ICRSService from '@/services/icrs-service';
import IcrsTemplate from './template/IcrsStaticTemplate.vue';
import { sanitizeUrl } from '@braintree/sanitize-url';
export default {
  components: {
    IcrsTemplate,
  },
  computed: {
    usefulLinks () {
      return ICRSService.getUsefulLinks();
    },
  },
  methods: {
  fixLink(url) {
    return sanitizeUrl(url);
  }
}
};
</script>
<style lang="scss" scoped>
.icrs-useful-links {
  .icrs-useful-link-item {
    display: block;
    text-decoration: none;
    border-bottom: none;

    &:hover {
      border-bottom: none !important;
    }

    .icrs-useful-link-card {
      margin-top: 10px;
      padding: 0.5rem;
      border-radius: 0.5rem;
      border: 1px solid rgba(0, 0, 0, 0.125);

      &:hover {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15) !important;
        background: var(--khaki-light) !important;
      }
      span {
        color: #000;
      }
    }
  }
}
</style>
