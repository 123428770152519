export default {
  SEARCH_MODE: {
    NEW: 'NEW',
    SEARCH_WITHIN: 'SEARCH_WITHIN',
    FILTER: 'FILTER',
    SOURCE: 'SOURCE',
    PAGINATION: 'PAGINATION',
    SORTING: 'SORTING',
    AGGREGATION: 'AGGREGATION',
    SAVE_SEARCH: 'SAVE_SEARCH',
  },
  SOURCE_TYPES: {
    ALL: 'All',
  },
  RESULTS_PER_PAGE: 10,
  // TODO: Update with the latest sort options for ICRS
  SORT_BY_OPTIONS: [
    { value: 'relevance', text: 'Relevance', parameter: {} },
    {
      value: 'latestModifiedDate',
      text: 'Latest Modified Date',
      parameter: {
        field: 'lastModifiedDate',
        order: 'desc',
      },
    },
    {
      value: 'earliestModifiedDate',
      text: 'Earliest Modified Date',
      parameter: {
        field: 'lastModifiedDate',
        order: 'asc',
      },
    },
    {
      value: 'latestAssessmentDate',
      text: 'Latest Assessment Date',
      parameter: {
        field: 'tags.dateAssessment_s',
        order: 'desc',
      },
    },
    {
      value: 'earliestAssessmentDate',
      text: 'Earliest Assessment Date',
      parameter: {
        field: 'tags.dateAssessment_s',
        order: 'asc',
      },
    },
    {
      value: 'highestInjuryAmount',
      text: 'Highest Injury Amount',
      parameter: {
        field: 'tags.injuryFinalAwardedAmount_s',
        order: 'desc',
      },
    },
    {
      value: 'lowestInjuryAmount',
      text: 'Lowest Injury Amount',
      parameter: {
        field: 'tags.injuryFinalAwardedAmount_s',
        order: 'asc',
      },
    },
  ],
  RESULT_DISPLAY_MODE: {
    STANDARD: 'standard',
    TABLE: 'table',
  },
  FILTER_SORT_BY_OPTIONS: {
    MOST_TO_LEAST: 'MOST_TO_LEAST',
    LEAST_TO_MOST: 'LEAST_TO_MOST',
    A_TO_Z: 'A_TO_Z',
    Z_TO_A: 'Z_TO_A',
  },
  PROMOTED_DATE_RANGE_MODE: {
    TODAY: 'TODAY',
    YESTERDAY: 'YESTERDAY',
    LAST_SEVEN_DAYS: 'LAST_SEVEN_DAYS',
    LAST_THIRTY_DAYS: 'LAST_THIRTY_DAYS',
    THIS_MONTH: 'THIS_MONTH',
    LAST_MONTH: 'LAST_MONTH',
    CUSTOM_RANGE: 'CUSTOM_RANGE',
  },
  PROMOTED_DATE_RANGE_OPTIONS: {
    TODAY: { mode: 'TODAY', text: 'Today' },
    YESTERDAY: { mode: 'YESTERDAY', text: 'Yesterday' },
    LAST_SEVEN_DAYS: { mode: 'LAST_SEVEN_DAYS', text: 'Last 7 days' },
    LAST_THIRTY_DAYS: { mode: 'LAST_THIRTY_DAYS', text: 'Last 30 days' },
    THIS_MONTH: { mode: 'THIS_MONTH', text: 'This Month' },
    LAST_MONTH: { mode: 'LAST_MONTH', text: 'Last Month' },
    CUSTOM_RANGE: { mode: 'CUSTOM_RANGE', text: 'Custom Range' },
  },
  PROMOTED_CONTENT_FIELDS: {
    FILTER: 'metadata.promote',
    DATE_FROM: 'metadata.promote_from',
    DATE_TO: 'metadata.promote_to',
    ALL_PROMOTED_CONTENT: 'All',
  },
  PROMOTION_STATUS: {
    ONGOING: { class: 'font-success', text: 'Ongoing promotion' },
    EXPIRED: { class: 'font-error', text: 'Expired promotion' },
    UPCOMING: { class: 'font-warning', text: 'Upcoming promotion' },
  },
  DATE_FILER_FIELDS: {
    FILTER: 'lastModifiedDate',
  },
};
