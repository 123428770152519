<template>
  <div class="basic-information">
    <p v-b-toggle.collapse-1 class="header-title">Basic Information
      <i :class="`fas fa-chevron-${isShow ? 'down': 'up'}`"></i>
    </p>
    <b-collapse v-model="isShow" id="collapse-1">
      <div>
        <div class="form-container">
          <Label>Type</Label>
          <b-form-radio-group @change="(value) => setValue('type', value)" v-model="type" :options="options">
          </b-form-radio-group>
        </div>
        <div class="form-container">
          <Label>{{ type }} Title</Label>
          <b-form-group>
            <b-input
              :placeholder="`Enter title of the ${page.type.toLowerCase()}`"
              maxlength="50"
              v-model="title"
              @keydown="preventSpecial"
            />
          </b-form-group>
        </div>
        <div v-if="type === 'Link'" class="form-container">
          <Label>Link</Label>
          <b-input-group prepend="https://" class="link-input">
            <b-input
              placeholder="Enter link"
              maxlength="255"
              v-model="link"
            />
          </b-input-group>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    page: Object,
    setValue: Function,
    setEditor: Function,
    checkPageCurrent: String,
  },
  data () {
    return {
      options: [
        { text: 'Page', value: 'Page' },
        { text: 'Link', value: 'Link' },
      ],
      isShow: true,
      type: this.page.type,
      link: '',
      title: this.page.title,
    };
  },
  mounted () {
    this.title = this.$props.page.title;
  },
  watch: {
    title (val) {
      this.setValue('title', val);
    },
    link (val) {
      this.setValue('link', val);
    },
    page: {
      immediate: true,
      deep: true,
      handler (val) {
        this.title = val.title;
        this.type = val.type;
        if (this.checkPageCurrent === 'Link') {
          if (!this.link) {
            this.link = val.content.replace('https://', '');
          }
        } else {
          this.setEditor(val.content);
        }
      },
    },
  },
  methods: {
    preventSpecial (event) {
      // The purpose of this function is to block special characters that may cause XSS
      const ignoreKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete'];
      // match characters not numbers, character
      const regex = /[^a-zA-Z0-9 ]/g;
      if (regex.test(event.key) && !ignoreKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
  },
};
</script>


<style scoped lang="scss">
  .form-container /deep/ .form-group {
    &>div {
      display: flex;
      gap: 80px;
    }
  }
  /deep/ .custom-control-inline {
      margin-right: 0 !important;
  }
  /deep/ input:checked ~ label.custom-control-label {
    &::before {
      background-color: var(--primary) !important;
      border-color: var(--primary) !important;
    }
  }
  /deep/ .custom-control-label:after,
  /deep/ .custom-control-label:before {
    top: 0.04rem !important;
    left: -2rem !important;
    width: 1.3rem !important;
    height: 1.3rem !important;
  }

  .form-container {
    .link-input{
      .input-group-prepend{
        .input-group-text{
          color: #212529 !important;
        }
      }
      input{
        color: #212529 !important;
      }
    }
  }
  .basic-information {
    &>.collapse>div {
      padding: 16px;
      &>.form-container {
        &>label {
          display: block;
          padding-bottom: 8.5px;
          margin: 0;
          font-weight: 500 !important;
        }
        &>.bv-no-focus-ring {
          padding-left: 8px;
          display: flex;
          margin-bottom: 24px;
          gap: 40px;
        }
      }
    }
    .header-title {
      font-size: 16px !important;
      padding: 13px 16px !important;
      line-height: 21px !important;
      font-weight: 600 !important;
      position: relative;
      margin-bottom: 0 !important;
      border-bottom: 1px solid #DEE2E6;
      & i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
      }
    }
  }

</style>
