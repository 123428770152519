import Api from './api/api.service';
import ConfigService from './config.service';

const EVENT_TRACKER_CONTEXT = ConfigService.getEventTrackerContext();

export default {
  sendTrackerMetrics (payload) {
    return Api().post(`${EVENT_TRACKER_CONTEXT}/searchEvent/submit`, payload)
      .then(response => response.data);
  },
};
