import Vue from 'vue';
import configService from '@/services/config.service';

export default function auth (store) {
  store.subscribe((mutation) => {
    if (mutation.type === 'authService/setUser') {
      const configFiles = [
        'search-ui.config.json',
      ];

      if (store.getters['authService/isKnowledgeUser']) {
        configFiles.push('search-ui.knowledge.config.json');
      }

      if (store.getters['authService/isAdminUser']) {
        configFiles.push('search-ui.admin.config.json');
      }

      if (configService.getIntranetEnabled()) {
        configFiles.push('search-ui.intranet.config.json');
      }

      Promise.all(configFiles.map(fileName => fetch(`${process.env.BASE_URL}${fileName}`)
        .then(resp => resp.json())))
        .then((configList) => {
          const aggregateConfig = Object.assign(...configList);
          Vue.prototype.$config = aggregateConfig;
          store.dispatch('app/configActivated');
        });
    }
  });
}
