import Vue from 'vue';
import getDefaultState from './state';

export default {
  setCurrentQuery (state, currentQuery) {
    Vue.set(state, 'currentQuery', currentQuery);
  },
  setCurrentSearchMode (state, currentSearchMode) {
    Vue.set(state, 'currentSearchMode', currentSearchMode);
  },
  setCurrentSearchParameter (state, currentSearchParameter) {
    Vue.set(state, 'currentSearchParameter', currentSearchParameter);
  },
  setCurrentSearchRequestParameter (state, currentSearchRequestParameter) {
    Vue.set(state, 'currentSearchRequestParameter', currentSearchRequestParameter);
  },
  setCurrentSearchResults (state, searchResults) {
    Vue.set(state, 'currentSearchResults', searchResults);
  },
  setSpellCorrectedQueries (state, spellCorrectedQueries) {
    Vue.set(state, 'spellCorrectedQueries', spellCorrectedQueries);
  },
  setKeepOriginSearchText (state, keepOriginSearchText) {
    Vue.set(state, 'keepOriginSearchText', keepOriginSearchText);
  },
  setCanSaveSearch (state, canSaveSearch) {
    Vue.set(state, 'canSaveSearch', canSaveSearch);
  },
  setSuggestions (state, suggestions) {
    Vue.set(state, 'suggestions', suggestions);
  },
  setCorrections (state, corrections) {
    Vue.set(state, 'corrections', corrections);
  },
  setNaturalLanguageAnswer (state, naturalLanguageAnswer) {
    Vue.set(state, 'naturalLanguageAnswer', naturalLanguageAnswer);
  },
  setSelectedSource (state, selectedSource) {
    Vue.set(state, 'selectedSource', selectedSource);
  },
  setCategories (state, categories) {
    Vue.set(state, 'categories', categories);
  },
  setAppliedSynonyms (state, appliedSynonyms) {
    Vue.set(state, 'appliedSynonyms', appliedSynonyms);
  },
  setIsSearching (state, flag) {
    state.isSearching = flag;
  },
  setIsSearchSuccess (state, flag) {
    state.isSearchSuccess = flag;
  },
  setCurrentPage (state, page) {
    state.currentPage = page;
  },
  setItemsPerPage (state, number) {
    state.itemsPerPage = number;
  },
  setAppliedSort (state, sort) {
    state.appliedSort = sort;
  },
  setSavedSearches (state, savedSearches) {
    state.savedSearches = savedSearches.sort((itemA, itemB) => {
      const regex = new RegExp('\/\\[(\[^\\]]+?)\\]/');
      const matchA = itemA.name.match(regex);
      const matchB = itemB.name.match(regex);
      if (!new Date(matchA)) {
        return -1;
      }
      if (!new Date(matchB)) {
        return 1;
      }
      const savedDateA = matchA ? new Date(matchA[1]) : null;
      const savedDateB = matchB ? new Date(matchB[1]) : null;

      return savedDateB - savedDateA;
    });
  },
  addSavedSearch (state, savedSearch) {
    state.savedSearches.unshift(savedSearch);
  },
  updateSavedSearch (state, savedSearch) {
    const foundSavedSearch = state.savedSearches.find(item => item.request.criteriaId === savedSearch.request.criteriaId);

    foundSavedSearch.name = savedSearch.name;
  },
  removeSavedSearch (state, savedSearchId) {
    state.savedSearches = state.savedSearches
      .filter(item => item.request.criteriaId !== savedSearchId);
  },
  setFilterGroups (state, filterGroups) {
    state.filterGroups.data = filterGroups;
  },
  setFilterGroupFetchingStatus (state, flag) {
    state.filterGroups.isFetching = flag;
  },
  setFilterGroupConfigs (state, configs) {
    state.filterGroupConfigs = configs;
  },
  /**
   * @param {*} state
   * @param {string[]} text
   */
  setSearchWithin (state, searchWithin) {
    state.searchWithin = searchWithin;
  },
  /**
   * @param {*} state
   * @param {string} text
   */
  addSearchWithin (state, text) {
    const isExists = state.searchWithin.indexOf(text) > -1;
    if (isExists) return;
    state.searchWithin.push(text);
  },
  /**
   * @param {*} state
   * @param {string} text
   */
  removeSearchWithin (state, text) {
    state.searchWithin = state.searchWithin.filter(element => element !== text);
  },
  setCurrentDateObject (state, dateObject) {
    state.currentDateObject = dateObject;
  },
  setPromoteDateObject (state, dateObject) {
    state.promoteDateObject = dateObject;
  },
  setICRSPlotSearching (state, isSearching) {
    Vue.set(state, 'icrsPlotSearching', isSearching);
  },
  setICRSPlotData (state, plotData) {
    Vue.set(state, 'icrsPlotData', plotData);
  },
  setICRSPlotSuccess (state, success) {
    Vue.set(state, 'icrsPlotSuccess', success);
  },
  setICRSSearchRequestParameter (state, searchRequestParameter) {
    Vue.set(state, 'icrsSearchRequestParameter', searchRequestParameter);
  },
  setPreviousQuery (state, previousQuery) {
    Vue.set(state, 'previousQuery', previousQuery);
  },
  resetState (state) {
    Object.assign(state, getDefaultState());
  },
  setSuggestedQueryText (state, suggestedQueryText) {
    state.suggestedQueryText = suggestedQueryText;
  },
  setSavedSearchFromHomePage (state, isSavedSearchFromHomePage) {
    state.isSavedSearchFromHomePage = isSavedSearchFromHomePage;
  },
  setMobileOveralOpen (state, isMobileOveralOpen) {
    state.isMobileOveralOpen = isMobileOveralOpen;
  },
};
