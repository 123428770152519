import { SET_CSRF_TOKEN } from '@/constants/auth-service-constants';
import TokenService from '../storage.service';

/** CSRF token interceptors */
const csrfInterceptor = (response) => {
  const headerCSRF = response.headers[SET_CSRF_TOKEN];

  if (headerCSRF) {
    TokenService.setCsrfTokenUi(headerCSRF);
  }
  return response;
};

export default csrfInterceptor;
