<template>
  <div>
    <img
      class="not-found-img"
      width="351"
      alt="404"
      src="../../../assets/404.png"
    />
    <h1 class="not-found-label">Oops...</h1>
    <h5 class="not-found-message">
      {{label}}
    </h5>
    <b-button
      class="retry-button"
      @click="$emit('click-btn')"
      variant="primary"
    >
      Retry
    </b-button>
  </div>
</template>

<script>
import constants from '../../../constants/doc-manager-service-constants';

export default {
  data () {
    const { TEXT_INFO: { NOT_FOUND: pageLabel = '' } = {} } = constants;

    return {
      label: pageLabel,
    };
  },
};
</script>

<style lang="scss">
#doc-manager {
  #not-found {
    .not-found-img {
      opacity: 0.5;
    }
    .not-found-label {
      margin-top: 30px;
      margin-bottom: 0px;
      font-style: normal;
      font-weight: $weight-semi-bold;
      font-size: 24px;
      line-height: 54px;
      color: $blue-gray-500;
    }
    .not-found-message {
      margin-top: 16px;
      margin-bottom: 0px;
      text-align: center;
      width: 320px;
      font-style: normal;
      font-weight: $weight-normal;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      text-align: center;
      color: $blue-gray-500;
    }
    .retry-button {
      margin-top: 30px;
    }
  }
}
</style>
