<template>
  <IcrsTemplate>
    <template #content>
      <h2 class="text-center">Privacy Policy</h2>
      <p>This Privacy Policy applies to all information collected by Singapore Academy of Law ("SAL" or "the Academy"). For the purpose of this Policy, "we", "us" and "our" shall refer to SAL.</p>
      <div class="icrs-policy-terms">
        <ol>
          <li
            v-for="(content1, key1) in policy"
            :key="key1"
          >
            {{key1}}
            <ol>
              <li
                v-for="(content2, key2) in content1"
                :key="`${key1}_${key2}`"
              >
                {{key2}}
                <ol>
                  <li
                    v-for="(content3, idx) in content2"
                    :key="`${key1}_${key2}_${idx}`"
                  >
                    {{content3}}
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </template>
  </IcrsTemplate>
</template>
<script>
import ICRSService from '@/services/icrs-service';
import IcrsTemplate from './template/IcrsStaticTemplate.vue';

export default {
  components: {
    IcrsTemplate,
  },
  computed: {
    policy () {
      return ICRSService.getPolicy();
    },
  },
};
</script>
