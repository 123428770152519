import SearchResultCardModel from '@/services/models/search-result-card/search-result-card.model';
import PreviewViewerConstants from '@/constants/preview-viewer-service.constants';

const { DOCUMENT_TYPES } = PreviewViewerConstants;

export default{
  title: state => state.documentDetails.title,
  subtitle: (state) => {
    const { source, metadata, lastModifiedDate } = state.documentDetails;
    return SearchResultCardModel.formatSubtitle({ source, metadata, lastModifiedDate });
  },
  documentId: state => state.documentDetails.id,
  previewViewerModalOpen: state => state.previewViewerModalOpen,
  textContent: state => state.textContent,
  documentType: (state) => {
    if (!state.documentDetails) {
      return null;
    }
    if (state.documentDetails.metadata && state.documentDetails.metadata.pageId) {
      return DOCUMENT_TYPES.HTML;
    }
    return DOCUMENT_TYPES.TEXT;
  },

};
