export default ({
  inputIndividual = '',
  individualId = '',
  selectedClass = '',
  individualSearchSuggestMap = {},
  filterGroupConfigs = {},
  filterGroups = {
    isFetching: true,
    hasFetchedBefore: false,
    data: [],
  },
  currentSearch = {
    ontologySearch: false,
    filterBasedSearch: false,
  },
  isLoading = true,
  advancedSearchModalOpen = false,
  selectedFilterGroup = {},
  isShowOverview = false,
} = {}) => ({
  inputIndividual,
  individualId,
  selectedClass,
  individualSearchSuggestMap,
  filterGroupConfigs,
  filterGroups,
  currentSearch,
  isLoading,
  advancedSearchModalOpen,
  selectedFilterGroup,
  isShowOverview,
});
