<template>
  <div class="w-100 text-left">
    <div class="table-view">
      <div class="h-100 overflow-auto">
        <div v-if="this.table.length > 0">
          <b-container
            fluid
            class="column p-0 w-100"
          >
            <div
              v-for="(item, index) in displayedTable"
              :key="index"
              class="w-50 tables"
            >
              <OntologySearchClassTable
                :item="item"
                :triggerCalculatePageNumber="triggerCalculatePageNumber"
                v-on="$listeners"
                :data-cy="`ontology-search-class-table-${index}`"
              />
            </div>
          </b-container>
        </div>
        <div
          v-else
          class="text-center border rounded mb-3"
        >
          <img
            class="m-4 w-50 h-auto mx-auto"
            src="@/assets/ontology-search-illustration.png"
          />
          <div class="paragraph font-secondary my-3">No related information</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import _findIndex from 'lodash/findIndex';
import OntologySearchClassTable from './OntologySearchClassTable';

export default {
  data () {
    return {
      displayedTable: [],
      triggerCalculatePageNumber: false,
      selectedClassFilters: [],
    };
  },
  props: {
    classFilter: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    OntologySearchClassTable,
  },
  computed: {
    ...mapGetters('advancedSearch/ontologySearch', [
      'table',
      'classFilters',
    ]),
  },
  watch: {
    table (value) {
      if (value) {
        this.displayedTable = this.table;
      }
    },
    classFilter (value) {
      const { selectedClassFilters } = this;
      const selectedIndex = _findIndex(selectedClassFilters, item => item.filter.key === value.filter.key);
      if (value.checked && selectedIndex === -1) {
        selectedClassFilters.push(value);
      } else {
        this.selectedClassFilters = selectedClassFilters.filter(item => item.filter.key !== value.filter.key);
      }
      this.handleClassFilter();
    },
    classFilters () {
      this.selectedClassFilters = [];
    },
  },
  methods: {
    onReset () {
      this.$emit('on-reset');
    },
    handleClassFilter () {
      this.displayedTable = this.selectedClassFilters.length > 0 ? this.handleFilterTable() : this.table;
      this.execCalculatePageNumber();
    },
    handleFilterTable () {
      const { selectedClassFilters } = this;
      const { table } = this;
      const newTable = [];
      selectedClassFilters.forEach((filterValue) => {
        const filterKeyValue = filterValue.filter.key.toLowerCase().replaceAll(' ', '');
        const selectedClassTable = table
          .find(item => item.class.toLowerCase() === filterKeyValue);
        if (selectedClassTable) newTable.push(selectedClassTable);
      });
      newTable.sort((firstItem, secondItem) => firstItem.classIndex - secondItem.classIndex);
      return newTable;
    },
    execCalculatePageNumber () {
      this.triggerCalculatePageNumber = !this.triggerCalculatePageNumber;
    },
  },
};
</script>
<style lang="scss" scoped>
.table-view {
  display: flex;
  flex-direction: column;
  background: $white-500;
  margin-top: 4em;
}
.column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tables {
  margin-bottom: 1rem;

  &:nth-child(even) {
    padding-left: 0.5em;
  }
  &:nth-child(odd) {
    padding-right: 0.5em;
  }
}
</style>
