import qs from 'qs';
import configService from '@/services/config.service';
import router from '../../router';

export default function auth (store) {
  store.subscribe((mutation) => {
    const isInLoginPage = router.currentRoute.path === '/login-page';
    const isUserLoggedOut = mutation.type === 'authService/logout';

    // ICRS Specific
    const isUseIcrsConfig = process.env.VUE_APP_USE_ICRS_CONFIG === 'true';
    const isInErrorPage = router.currentRoute.path === '/error';

    if (isUserLoggedOut && !isInLoginPage) {
      // No redirect for error page
      if (isInErrorPage) {
        return;
      }

      const isKPOIntegrationEnabled = configService.getKPOIntegrationEnabled();
      const isADEnabled = configService.getIsADAuth();

      let to = '';

      if (isKPOIntegrationEnabled) {
        to = configService.getKPOAdminUIUrl();
      } else if (isADEnabled) {
        to = '/unauthorized-page';
      } else if (isUseIcrsConfig) {
        to = '/icrs-redirect-page';
      } else {
        to = '/login-page';
      }

      const message = mutation.payload && mutation.payload.message;
      const { targetPath } = mutation.payload;

      const param = {};
      if (message) param.error = message;
      if (targetPath) param.redirect = targetPath;
      if (isUseIcrsConfig && configService.getSALAuthEnabled()) {
        param.redirectMessage = 'Logging in to ICRS...';
        param.redirectUrl = configService.getICRSSALLoginUrl();
      }

      if (isUseIcrsConfig && configService.getSAMLAuthEnabled()) {
        param.redirectMessage = 'Logging in to ICRS...';
        param.redirectUrl = configService.getICRSSAMLLoginUrl();
      }

      const query = qs.stringify(param);
      if (query) to += `?${query}`;

      if (!to) {
        return;
      }

      if (isKPOIntegrationEnabled) {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('refresh_token');
        localStorage.removeItem('organization');
        localStorage.removeItem('roles');
        window.open(to, '_self');
      } else {
        router.push(to);
      }
    }
  });
}
