<template>
  <div>
    <div class="topBar">Faceted Search</div>
    <div class="content">
      <LiveDemo />
      <markdown class="markdownStyle" />
    </div>
  </div>
</template>

<script>
import LiveDemo from './FacetedSearchLiveDemo.vue';
import markdown from './FacetedSearchPage.md';

export default {
  components: {
    LiveDemo,
    markdown,
  },
};
</script>


<style scoped lang="scss">
@import "../../markdown.scss";
.markdownStyle {
  padding: 0 !important;
}
.topBar {
  height: 152px;
  background: $blue-200;
  padding: 60px 100px;
  font-size: 36px;
  font-weight: $weight-normal;
}
.content {
  padding: 0 100px;
}
</style>
