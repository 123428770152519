import { createTestAction } from '@/helpers/testing';
import constants from '@/constants/search-service-constants';
import FilterGroup from '@/services/models/search/filter-group.model';
import SearchParameter from '@/services/models/search/search-parameter.model';
import SearchRequestParameter from '@/services/models/search/search-request-parameter.model';
import SearchResultModel from '@/services/models/search/search-result.model';
import searchService from '../../../../services/search.service';
import actions from '../actions';

jest.mock('../../../../services/search.service', () => ({
  getAutocomplete: () => Promise.resolve({ data: ['Singapore'] }),
  getAggregationConfigs: jest.fn(),
  getAggregations: jest.fn(),
  getDocumentDetails: jest.fn(),
  getTrendingSearch: jest.fn(),
  execCorrection: jest.fn(),
  execSuggestion: jest.fn(),
  execNaturalLanguageSearch: jest.fn(),
  getTextContent: jest.fn(),
  execCategories: jest.fn(),
  postSimpleSearch: jest.fn(),
  getAggregationsLevelTwo: jest.fn(),
  removeUserSavedSearch: () => Promise.resolve(),
  updateUserSavedSearch: () => Promise.resolve(),
  getUserSavedSearches: jest.fn(),
  createUserSavedSearch: jest.fn(),
}));

jest.mock('../../../../services/icrs-service', () => ({
  getFiltersTopicTitleDisplayOrder: jest.fn(),
}));

jest.mock('../../../../services/config.service', () => ({
  useICRSConfig: () => false,
}));

function createRequest ({
  sortBy = { field: 'lastModifiedDate', order: 'desc' },
  query = 'singapore',
  size = 10,
  source = ['CASA'],
  rangeQuery = [],
  synonyms = null,
  from = 0,
  searchWithin = [],

} = {}) {
  return {
    sortBy,
    query,
    size,
    source,
    rangeQuery,
    synonyms,
    from,
    searchWithin,
  };
}

function createSearchParameter ({
  searchMode = constants.SEARCH_MODE.NEW,
  appliedFilters = [],
  dateObject,
  curPage = 1,
  appliedSort,
  source,
  appliedSynonyms,
  size = constants.RESULTS_PER_PAGE,
  id,
} = {}) {
  return new SearchParameter(
    searchMode,
    appliedFilters,
    dateObject,
    curPage,
    appliedSort,
    source,
    appliedSynonyms,
    size,
    id,
  );
}

const testAction = createTestAction(expect);

describe('search-core vuex action tests', () => {
  test('execGetAutocomplete', async () => {
    const result = await actions.execGetAutocomplete({}, { textToComplete: 'sing', type: '' });
    expect(result).toEqual(['Singapore']);
  });

  describe('buildAutocomplete', () => {
    test('should limit to 5', () => {
      const autocompleteSuggest = [
        { suggest: 'civil aviation a', id: '123' },
        { suggest: 'civil aviation b', id: '124' },
        { suggest: 'civil aviation c', id: '125' },
        { suggest: 'civil aviation d', id: '126' },
        { suggest: 'civil aviation e', id: '127' },
        { suggest: 'civil aviation f', id: '128' },
      ];
      const ctx = {};
      const result = actions.buildAutocomplete(ctx, { autocompleteSuggest });
      expect(result).toEqual(
        [
          'civil aviation a',
          'civil aviation b',
          'civil aviation c',
          'civil aviation d',
          'civil aviation e',
        ],
      );
    });

    test('should merge metrics and suggest', () => {
      const autocompleteSuggest = [
        { suggest: 'civil aviation', id: '123' },
      ];
      const autocompleteMetrics = [
        { suggest: 'singapore', type: 'query' },
      ];
      const ctx = {};

      const result = actions.buildAutocomplete(ctx, { autocompleteMetrics, autocompleteSuggest });
      expect(result).toEqual(
        [
          'civil aviation',
          'singapore',
        ],
      );
    });

    test('should limit both metrics and suggest by 5', () => {
      const autocompleteSuggest = [
        { suggest: 'civil aviation a', id: '123' },
        { suggest: 'civil aviation b', id: '124' },
        { suggest: 'civil aviation c', id: '125' },
        { suggest: 'civil aviation d', id: '126' },
        { suggest: 'civil aviation e', id: '127' },
        { suggest: 'civil aviation f', id: '128' },
      ];
      const autocompleteMetrics = [
        { suggest: 'singapore a', type: 'query' },
        { suggest: 'singapore b', type: 'query' },
        { suggest: 'singapore c', type: 'query' },
        { suggest: 'singapore d', type: 'query' },
        { suggest: 'singapore e', type: 'query' },
        { suggest: 'singapore f', type: 'query' },
      ];
      const ctx = {};

      const result = actions.buildAutocomplete(ctx, { autocompleteMetrics, autocompleteSuggest });
      expect(result).toEqual(
        [
          'civil aviation a',
          'civil aviation b',
          'civil aviation c',
          'civil aviation d',
          'civil aviation e',
          'singapore a',
          'singapore b',
          'singapore c',
          'singapore d',
          'singapore e',
        ],
      );
    });
  });

  test('triggerSearchRefresh', () => {
    const dispatch = jest.fn();
    const state = { currentQuery: 'a keyword' };
    actions.triggerSearchRefresh({ state, dispatch });

    expect(dispatch).toHaveBeenLastCalledWith('triggerSearchQuery', {
      query: 'a keyword',
      searchMode: constants.SEARCH_MODE.PAGINATION,
    });
  });

  test('setCurrentPage', () => {
    const commit = jest.fn();
    actions.setCurrentPage({ commit }, 2);

    expect(commit).toHaveBeenLastCalledWith('setCurrentPage', 2);
  });

  test('setAppliedSort', () => {
    const commit = jest.fn();
    const sort = { field: 'lastModifiedDate', order: 'desc' };
    actions.setAppliedSort({ commit }, sort);

    expect(commit).toHaveBeenLastCalledWith('setAppliedSort', sort);
  });

  test('setCurrentQuery', () => {
    const commit = jest.fn();
    const query = 'permits';
    actions.setCurrentQuery({ commit }, query);

    expect(commit).toHaveBeenLastCalledWith('setCurrentQuery', query);
  });

  test('setCurrentSearchMode', () => {
    const commit = jest.fn();
    actions.setCurrentSearchMode({ commit }, constants.SEARCH_MODE.NEW);

    expect(commit).toHaveBeenLastCalledWith('setCurrentSearchMode', constants.SEARCH_MODE.NEW);
  });

  test('setSelectedSource', () => {
    const commit = jest.fn();
    const source = ['CASA'];
    actions.setSelectedSource({ commit }, source);

    expect(commit).toHaveBeenLastCalledWith('setSelectedSource', source);
  });

  test('setItemsPerPage', () => {
    const commit = jest.fn();
    actions.setItemsPerPage({ commit }, 20);

    expect(commit).toHaveBeenLastCalledWith('setItemsPerPage', 20);
  });

  test('getFilterGroupConfigs', (done) => {
    const ctx = {};
    const response = { caa: {} };
    searchService.getAggregationConfigs.mockReturnValueOnce(Promise.resolve(response));

    testAction(actions.getFilterGroupConfigs, null, ctx, [
      { type: 'setFilterGroupConfigs', payload: response },
    ], done);
  });

  test('getFilterGroups', (done) => {
    const filterGroupConfigs = {
      caa: {
        displayName: 'CAA',
        displayed: true,
        field: 'tags.caa',
        showInCloud: true,
      },
      sectors: {
        displayName: 'Sectors',
        displayed: false,
        field: 'tags.sectors',
        showInCloud: true,
      },
    };
    const ctx = {
      state: { filterGroupConfigs },
    };
    const response = {
      aggregations: [
        { name: 'caa', docCount: 10, bucket: [] },
        { name: 'sectors', docCount: 0, bucket: [] },
      ],
    };

    searchService.getAggregations.mockReturnValueOnce(Promise.resolve(response));
    const param = {};
    testAction(actions.getFilterGroups, param, ctx, [
      { type: 'setFilterGroupFetchingStatus', payload: true },
      {
        type: 'setFilterGroups',
        payload: [
          new FilterGroup({
            name: 'caa',
            docCount: 10,
            bucket: [],
            displayName: 'CAA',
          }),
        ],
      },
      { type: 'setFilterGroupFetchingStatus', payload: false },
    ], done);
  });

  test('getFilterGroupsLevelTwo', () => {
    const searchParameter = createSearchParameter({
      searchMode: constants.SEARCH_MODE.AGGREGATION,
    });
    const filterGroupConfigs = {
      caa: {
        displayName: 'CAA',
        displayed: true,
        field: 'tags.caa',
        showInCloud: true,
      },
    };
    const currentQuery = 'singapore';
    const state = {
      currentQuery,
      filterGroupConfigs,
      currentSearchParameter: searchParameter,
    };
    const ctx = { state };
    const response = {
      aggregations: [{ name: 'caa', bucket: [], docCount: 10 }],
    };
    searchService.getAggregationsLevelTwo.mockReturnValueOnce(Promise.resolve(response));
    const filterGroups = [
      new FilterGroup({
        name: 'caa',
        bucket: [],
        docCount: 10,
        displayName: 'CAA',
      }),
    ];
    testAction(actions.getFilterGroupsLevelTwo, {}, ctx, [
      { type: 'setFilterGroupFetchingStatus', payload: true },
      { type: 'setFilterGroups', payload: filterGroups },
      { type: 'setCurrentSearchMode', payload: constants.SEARCH_MODE.FILTER },
      { type: 'setFilterGroupFetchingStatus', payload: false },
    ]);
  });

  test('updateCurrentSearchParameter', () => {
    const rootGetters = {
      'searchService/filters/appliedFilters': {},
    };

    const state = {
      currentSearchMode: constants.SEARCH_MODE.NEW,
      currentPage: 1,
      itemsPerPage: 10,
      currentSearchResults: {
        id: '123',
      },
    };
    const ctx = { state, rootGetters };
    const param = createSearchParameter({
      ...state,
      id: state.currentSearchResults.id,
    });
    testAction(actions.updateCurrentSearchParameter, null, ctx, [
      { type: 'setCurrentSearchParameter', payload: param },
    ]);
  });

  describe('searchForSavedSearch', () => {
    test('search with selected source', () => {
      const request = createRequest();
      const dispatch = jest.fn();
      const ctx = { dispatch };
      testAction(actions.searchForSavedSearch, request, ctx, [
        { type: 'setCurrentPage', payload: 1 },
        { type: 'setAppliedSort', payload: request.sortBy },
        { type: 'setItemsPerPage', payload: 10 },
        { type: 'setCurrentQuery', payload: 'singapore' },
        { type: 'setCurrentSearchMode', payload: constants.SEARCH_MODE.SAVE_SEARCH },
        { type: 'setAppliedSynonyms', payload: null },
        { type: 'setSearchWithin', payload: [] },
        { type: 'setSelectedSource', payload: ['CASA'] },
      ]);
      expect(dispatch.mock.calls[0][0]).toBe('searchService/filters/date/updateCurrentDate', {
        start: undefined,
        end: undefined,
        field: constants.DATE_FILER_FIELDS.FILTER,
      }, { root: true });
      expect(dispatch.mock.calls[1][0])
        .toBe('searchService/filters/promotedContent/resetPromotedDateConfig', {}, { root: true });
      expect(dispatch.mock.calls[2][0]).toBe('getFilterGroupConfigs');
      expect(dispatch.mock.calls[3][0]).toBe('executeSearchQuery');
      expect(dispatch.mock.calls[4][0]).toBe('executeCategories');
    });

    test('search without selected source', () => {
      const request = createRequest({
        source: [],
      });
      const dispatch = jest.fn();

      const ctx = { dispatch };

      testAction(actions.searchForSavedSearch, request, ctx, [
        { type: 'setCurrentPage', payload: 1 },
        { type: 'setAppliedSort', payload: request.sortBy },
        { type: 'setItemsPerPage', payload: 10 },
        { type: 'setCurrentQuery', payload: 'singapore' },
        { type: 'setCurrentSearchMode', payload: constants.SEARCH_MODE.SAVE_SEARCH },
        { type: 'setAppliedSynonyms', payload: null },
        { type: 'setSearchWithin', payload: [] },
        { type: 'setSelectedSource', payload: undefined },
      ]);
      expect(dispatch.mock.calls[0][0]).toBe('searchService/filters/date/updateCurrentDate', {
        start: undefined,
        end: undefined,
        field: constants.DATE_FILER_FIELDS.FILTER,
      }, { root: true });
      expect(dispatch.mock.calls[1][0])
        .toBe('searchService/filters/promotedContent/resetPromotedDateConfig', {}, { root: true });
      expect(dispatch.mock.calls[2][0]).toBe('getFilterGroupConfigs');
      expect(dispatch.mock.calls[3][0]).toBe('executeSearchQuery');
      expect(dispatch.mock.calls[4][0]).toBe('executeCategories');
    });


    test('should abort when no query', () => {
      const request = createRequest({
        query: '',
      });
      const dispatch = jest.fn();

      const ctx = { dispatch };
      testAction(actions.searchForSavedSearch, request, ctx, []);

      expect(dispatch).not.toBeCalled();
    });
  });

  describe('executeSearchQuery', () => {
    const rootGetters = {
      'searchService/filters/appliedFilters': [],
    };

    const state = {
      currentSearchMode: constants.SEARCH_MODE.NEW,
      currentDateObject: undefined,
      currentPage: 1,
      appliedSort: {},
      selectedSource: [],
      appliedSynonyms: null,
      itemsPerPage: 10,
      currentSearchResults: { id: '123' },
    };
    const dispatch = jest.fn();
    const ctx = {
      dispatch, state, rootGetters,
    };
    const payload = new SearchParameter(
      state.currentSearchMode,
      rootGetters['searchService/filters/appliedFilters'],
      state.currentDateObject,
      state.currentPage,
      state.appliedSort,
      state.selectedSource,
      state.appliedSynonyms,
      state.itemsPerPage,
      state.currentSearchResults.id,
    );
    testAction(actions.executeSearchQuery, {}, ctx, [
      { type: 'setCurrentSearchParameter', payload },
    ]);
    expect(dispatch).toHaveBeenLastCalledWith('executeSimpleSearch', {});
  });

  describe('executeSimpleSearch', () => {
    test('execute with level 1 aggregate', () => {
      const param = createSearchParameter();
      const state = {
        currentQuery: 'singapore',
        currentSearchParameter: param,
      };
      const dispatch = jest.fn();
      const ctx = { state, dispatch };
      const response = {};
      searchService.postSimpleSearch.mockReturnValueOnce(Promise.resolve(response));

      // mock for getFilterGroupConfigs action call
      dispatch.mockReturnValueOnce(Promise.resolve());

      testAction(actions.executeSimpleSearch, {}, ctx, [
        { type: 'setCurrentSearchRequestParameter', payload: param },
        { type: 'setIsSearching', payload: true },
        { type: 'setCurrentSearchResults', payload: new SearchResultModel(response) },
        { type: 'setIsSearchSuccess', payload: true },
        { type: 'setIsSearching', payload: false },
      ])
        .then(() => {
          expect(dispatch.mock.calls[0][0]).toBe('getFilterGroupConfigs');
          expect(dispatch.mock.calls[1][0]).toBe('getFilterGroups');
          expect(dispatch.mock.calls[2][0]).toBe('executeGetSuggestion');
          expect(dispatch.mock.calls[3][0]).toBe('executeGetNaturalLanguageSearch');
          expect(dispatch.mock.calls[4][0]).toBe('executeGetCorrection');
          expect(dispatch.mock.calls[5][0]).toBe('checkSaveSearch');
        });
    });

    test('execute with level 2 aggregate', () => {
      const param = createSearchParameter();
      const state = {
        currentQuery: 'singapore',
        currentSearchParameter: param,
      };
      const dispatch = jest.fn();
      const ctx = { state, dispatch };
      const response = {};
      searchService.postSimpleSearch.mockReturnValueOnce(Promise.resolve(response));

      // mock for getFilterGroupConfigs action call
      dispatch.mockReturnValueOnce(Promise.resolve());

      testAction(actions.executeSimpleSearch, { filterGroupLevel: 2 }, ctx, [
        { type: 'setCurrentSearchRequestParameter', payload: param },
        { type: 'setIsSearching', payload: true },
        { type: 'setCurrentSearchResults', payload: new SearchResultModel(response) },
        { type: 'setIsSearchSuccess', payload: true },
        { type: 'setIsSearching', payload: false },
      ])
        .then(() => {
          expect(dispatch.mock.calls[0][0]).toBe('getFilterGroupConfigs');
          expect(dispatch.mock.calls[1][0]).toBe('triggerFilterSelect');
          expect(dispatch.mock.calls[2][0]).toBe('executeGetSuggestion');
          expect(dispatch.mock.calls[3][0]).toBe('executeGetNaturalLanguageSearch');
          expect(dispatch.mock.calls[4][0]).toBe('executeGetCorrection');
          expect(dispatch.mock.calls[5][0]).toBe('checkSaveSearch');
        });
    });
  });

  describe('execSuggestion', () => {
    test('should receive the suggestions', () => {
      const currentQuery = 'sing';
      const state = { currentQuery };
      const ctx = { state };
      const response = [{ suggest: 'singapore' }];

      searchService.execSuggestion.mockReturnValueOnce(Promise.resolve(response));
      testAction(actions.executeGetSuggestion, null, ctx, [
        { type: 'setSuggestions', payload: ['singapore'] },
      ]);
      expect(searchService.execSuggestion).toHaveBeenLastCalledWith(currentQuery);
      searchService.execSuggestion.mockReset();
    });

    test('should abort when query is empty', () => {
      const currentQuery = '';
      const state = { currentQuery };
      const ctx = { state };

      testAction(actions.executeGetSuggestion, null, ctx, []);
      expect(searchService.execSuggestion).not.toBeCalled();
    });
  });

  describe('executeGetCorrection', () => {
    test('should receive the corrections', () => {
      const currentQuery = 'permir';
      const state = { currentQuery };
      const ctx = { state };
      const response = [{ suggest: 'permits' }];

      searchService.execCorrection.mockReturnValueOnce(Promise.resolve(response));
      testAction(actions.executeGetCorrection, null, ctx, [
        { type: 'setCorrections', payload: ['permits'] },
      ]);
      expect(searchService.execCorrection).toHaveBeenLastCalledWith(currentQuery);
      searchService.execCorrection.mockReset();
    });

    test('should receive the corrections', () => {
      const currentQuery = '';
      const state = { currentQuery };
      const ctx = { state };

      testAction(actions.executeGetCorrection, null, ctx, []);
      expect(searchService.execCorrection).not.toBeCalled();
    });
  });

  describe('executeGetNaturalLanguageSearch', () => {
    test('should receive the nlp answers', () => {
      const currentQuery = 'who is usa president';
      const state = { currentQuery };
      const ctx = { state };
      const response = ['United States president Joe Biden'];

      searchService.execNaturalLanguageSearch.mockReturnValueOnce(Promise.resolve(response));
      testAction(actions.executeGetNaturalLanguageSearch, null, ctx, [
        { type: 'setNaturalLanguageAnswer', payload: ['United States president Joe Biden'] },
      ]);
      expect(searchService.execNaturalLanguageSearch).toHaveBeenLastCalledWith(currentQuery);
      searchService.execNaturalLanguageSearch.mockReset();
    });

    test('should receive the nlp answers', () => {
      const currentQuery = '';
      const state = { currentQuery };
      const ctx = { state };

      testAction(actions.executeGetNaturalLanguageSearch, null, ctx, []);
      expect(searchService.execNaturalLanguageSearch).not.toBeCalled();
    });
  });

  test('executeCategories', () => {
    const searchParameter = createSearchParameter({
      searchMode: constants.SEARCH_MODE.AGGREGATION,
    });
    const currentQuery = 'singapore';
    const state = { currentQuery, currentSearchParameter: searchParameter };
    const ctx = { state };
    const response = { aggregations: [] };
    const requestParam = new SearchRequestParameter(currentQuery, searchParameter);

    searchService.execCategories.mockReturnValueOnce(Promise.resolve(response));
    testAction(actions.executeCategories, null, ctx, [
      { type: 'setCategories', payload: response },
    ]);
    expect(searchService.execCategories).toHaveBeenLastCalledWith(requestParam);
  });

  test('checkSaveSearch', () => {
    const state = { currentSearchResults: { id: 'result-id' } };
    const ctx = { state };
    testAction(actions.checkSaveSearch, null, ctx, [
      { type: 'setCanSaveSearch', payload: true },
    ]);
  });

  test('execGetTextContent', async () => {
    const payload = { id: 'an-id' };
    const response = 'Text content';
    const ctx = {};
    searchService.getTextContent.mockReturnValueOnce(Promise.resolve(response));
    const result = await actions.execGetTextContent(ctx, payload);
    expect(result).toEqual(response);
    expect(searchService.getTextContent).toHaveBeenLastCalledWith(payload);
  });

  test('execGetTrendingSearch', async () => {
    const payload = { lookbackDays: 30, size: 5 };
    const response = [{ keyword: 'aviation' }];
    const ctx = {};
    searchService.getTrendingSearch.mockReturnValueOnce(Promise.resolve({ data: response }));
    const result = await actions.execGetTrendingSearch(ctx, payload);
    expect(result).toEqual(response);
    expect(searchService.getTrendingSearch).toHaveBeenLastCalledWith(payload);
  });

  test('execGetDocumentDetails', async () => {
    const payload = { id: 'an-id' };
    const response = { type: 'document' };
    const ctx = {};
    searchService.getDocumentDetails.mockReturnValueOnce(Promise.resolve({ data: response }));
    const result = await actions.execGetDocumentDetails(ctx, payload);
    expect(result).toEqual(response);
    expect(searchService.getDocumentDetails).toHaveBeenLastCalledWith(payload);
  });

  test('getSavedSearches', (done) => {
    const rootState = {
      authService: { user: { id: '123' } },
    };
    const ctx = { rootState };
    const response = [];
    searchService.getUserSavedSearches.mockReturnValueOnce(Promise.resolve(response));

    testAction(actions.getSavedSearches, null, ctx, [
      { type: 'setSavedSearches', payload: response },
    ], done);
  });

  test('createSavedSearch', (done) => {
    const state = {
      currentSearchRequestParameter: {},
      currentSearchResults: { id: 'search-id' },
    };
    const response = {
      id: 'an-id',
      name: 'permits',
      request: {},
    };

    searchService.createUserSavedSearch.mockReturnValueOnce(Promise.resolve(response));
    testAction(actions.createSavedSearch, 'permits', { state }, [
      { type: 'addSavedSearch', payload: response },
    ], done);
    expect(searchService.createUserSavedSearch).toHaveBeenLastCalledWith({
      name: 'permits',
      request: {
        searchMode: constants.SEARCH_MODE.SAVE_SEARCH,
        id: state.currentSearchResults.id,
      },
    });
  });

  test('updateSavedSearch', (done) => {
    const payload = {
      id: 'an-id',
      name: 'permits',
      request: {},
    };
    testAction(actions.updateSavedSearch, payload, {}, [
      { type: 'updateSavedSearch', payload },
    ], done);
  });

  test('removeSavedSearch', (done) => {
    testAction(actions.removeSavedSearch, 'saved-search-id', {}, [
      { type: 'removeSavedSearch', payload: 'saved-search-id' },
    ], done);
  });
});
