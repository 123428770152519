import configService from '@/services/config.service';
import storageService from '@/services/storage.service';
import pageManagementRoutes from '../pageManagementRoutes';

/**
 * authGuard
 * @param {Object} to target route object
 * @param {Object} from previus route object
 * @param {Function} next function to be called to resolve the hook
 * @returns undefined
 */
export const authGuard = (to, from, next) => {
  const isAdmin = storageService.getUserModel() ? storageService.getUserModel().roles.indexOf('ROLE_ADMIN') !== -1 : false;
  const isPageManager = storageService.getUserModel() ? storageService.getUserModel().roles.indexOf('ROLE_PAGEMANAGER') !== -1 : false;
  const isCaseManager = storageService.getUserModel() ? storageService.getUserModel().roles.indexOf('ROLE_CASEMANAGER') !== -1 : false;

  if (pageManagementRoutes.map(item => item.path).indexOf(to.path) !== -1) {
    if (isAdmin) {
      return next();
    }
    if (isCaseManager && to.path === '/add-edit-data') {
      return next();
    }

    if (isPageManager && to.path !== '/add-edit-data') {
      return next();
    }

    return next('/unauthorized-page');
  }

  if (!configService.getEnableAuth()
      || configService.getKPOIntegrationEnabled()
      || configService.getIsADAuth()
      || configService.getSAMLAuthEnabled()
      || configService.getSALAuthEnabled()
  ) {
    return next();
  }

  if (!storageService.getUserModel()) {
    if (configService.getIsADAuth() && to.path !== '/unauthorized-page') {
      return next('/unauthorized-page');
    }

    if (!configService.getIsADAuth() && to.path !== '/login-page') {
      return next('/login-page');
    }
  }

  return next();
};

/**
 * pageGuard
 * @param {Object} to target route object
 * @param {Object} from previus route object
 * @param {Function} next function to be called to resolve the hook
 * @returns undefined
 */
export const pageGuard = (to, from, next) => {
  if (storageService.getUserModel()) {
    if (to.path === '/error') {
      return next('/');
    }
  }
  return next();
};
