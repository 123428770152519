import _flatMap from 'lodash/flatMap';
import _isUndefined from 'lodash/isUndefined';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import ICRSService from '../../icrs-service';
import SearchServiceTypes from '../../../constants/search-service-constants';

class SearchParameter {
  constructor (
    searchMode,
    appliedFilters,
    dateObject,
    curPage,
    appliedSort,
    source,
    appliedSynonyms,
    size = SearchServiceTypes.RESULTS_PER_PAGE,
    id,
    searchWithin,
    promotedDateObject,
    icrsRangeFilters,
    topicShould,
  ) {
    const rangeQueryList = SearchParameter.buildDateRange(dateObject)
      .concat(SearchParameter.buildPromotedDateRange(promotedDateObject));
    const offset = curPage;
    const topicsMust = SearchParameter.buildFilterParam(appliedFilters);
    this.topicsMust = _isUndefined(topicsMust) ? [] : topicsMust;
    this.topicsShould = _isUndefined(topicShould) ? [] : topicShould;

    // ICRS uses the icrsRangeFilters parameters for range queries
    // on fields such as age, salary and assessment/accident dates.
    if (process.env.VUE_APP_USE_ICRS_CONFIG === 'true') {
      const icrsRangeQueryList = SearchParameter.buildICRSRangeQueries(icrsRangeFilters);
      if (_isUndefined(icrsRangeQueryList) && _isUndefined(rangeQueryList)) {
        this.rangeQuery = [];
      } else if (_isUndefined(rangeQueryList)) {
        this.rangeQuery = icrsRangeQueryList;
      } else if (_isUndefined(icrsRangeQueryList)) {
        this.rangeQuery = rangeQueryList;
      } else {
        this.rangeQuery = [...rangeQueryList, ...icrsRangeQueryList];
      }
    } else {
      this.rangeQuery = _isUndefined(rangeQueryList) ? [] : rangeQueryList;
    }
    this.from = _isUndefined(offset) ? 1 : Number(offset);
    this.searchMode = searchMode;
    this.sortBy = _isEmpty(appliedSort) ? { field: '', order: 'desc' } : appliedSort;
    this.source = _isUndefined(source) ? undefined : source;
    this.synonyms = _isUndefined(appliedSynonyms) ? undefined : appliedSynonyms;
    this.id = _isUndefined(id) ? undefined : id;
    if (Array.isArray(searchWithin) && searchWithin) {
      this.searchWithin = SearchParameter.buildSearchWithin(searchWithin);
    }
    if (size) {
      this.size = size;
    }
  }

  /**
   * @param  {string[]} searchWithin
   * @return {import('@typings/search').SearchWithinQuery[]}
   */
  static buildSearchWithin (searchWithin) {
    return searchWithin.map(text => ({
      value: text,
      field: '',
    }));
  }

  /**
   * @param  {import('@typings/search').SearchWithinQuery[]} searchWithinQuery
   * @return {string[]}
   */
  static convertSearchWithinAsText (searchWithinQuery) {
    return searchWithinQuery.map(query => query.value);
  }

  /**
   * Build filter param with correct structure when using own list
   * from key: [val1, val2]
   * to [
   *  {field: key, value: val1},
   *  {field: key, value: val2}
   * ]
   */
  static buildFilterParam (appliedFilters) {
    if (_isUndefined(appliedFilters)) {
      return undefined;
    }
    const filterParam = _flatMap(appliedFilters, (ele, key) => _map(ele, e => ({ field: key, value: e })));
    return filterParam;
  }

  /**
   * @param {import('@typings/search').DateRangeItem} dateObject
   */
  static buildDateRange (dateObject) {
    if (!dateObject || !dateObject.field) {
      return [];
    }
    const rangeQueryList = [];

    if (dateObject.start) {
      const start = {
        field: dateObject.field,
        gte: dateObject.start,
      };
      rangeQueryList.push(start);
    }

    if (dateObject.end) {
      const end = {
        field: dateObject.field,
        lte: dateObject.end,
      };
      rangeQueryList.push(end);
    }
    return rangeQueryList;
  }

  static buildICRSRangeQueries (icrsRangeFilters) {
    const filterParam = _flatMap(icrsRangeFilters, (ele, key) => _map(ele, e => ({ field: key, value: e })));
    const rangeQueryList = filterParam.map((filter) => {
      if (ICRSService.getFiltersTopicTitleDisplayOrder()[filter.field].type === 'date') {
        return {
          field: `tags.${filter.field}`,
          from: filter.value.split(' To ')[0].split('From ')[1],
          to: filter.value.split(' To ')[1],
        };
      }
      return {
        field: `tags.${filter.field}`,
        // field: `tags.${filter.field}.number`,
        from: Number(filter.value.split(' To ')[0].split('From ')[1]),
        to: Number(filter.value.split(' To ')[1]),
      };
    });
    return rangeQueryList;
  }

  /**
   * @param {import('@typings/search').DateRangeItem} dateObject
   */
  static buildPromotedDateRange (dateObject) {
    if (!dateObject || !dateObject.field) {
      return [];
    }
    const rangeQueryList = [];

    if (dateObject.start) {
      const start = {
        field: SearchServiceTypes.PROMOTED_CONTENT_FIELDS.DATE_TO,
        gte: dateObject.start,
      };
      rangeQueryList.push(start);
    }

    if (dateObject.end) {
      const end = {
        field: SearchServiceTypes.PROMOTED_CONTENT_FIELDS.DATE_FROM,
        lte: dateObject.end,
      };
      rangeQueryList.push(end);
    }
    return rangeQueryList;
  }

  /**
   * Get Offset depending on what page of results is requested
   * @param {Integer} curPage
   */
  static getOffsetByPage (curPage = 1, size) {
    return (curPage - 1) * size ? ((curPage - 1) * size).toFixed(0) : 1;
  }

  static getPageFromOffset (from, size) {
    return (from / size) + 1;
  }
}
export default SearchParameter;
