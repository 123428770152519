<template>
  <div
    v-if="totalPages > 0"
    class="t-pagination"
  >
    <slot
      :start="start + 1"
      :end="end"
      :total="totalItems"
    >
      <div class="page-label">{{ start + 1 }} - {{ end }} of {{ totalItems }}</div>
    </slot>
    <div
      v-if="totalPages > 1"
      class="d-flex"
    >
      <slot
        :buttons="buttons"
        name="buttons"
      >
        <button
          :class="[
            'button',
            'button-page',
            buttonNavClass(key),
            buttonHover(key, button),
            buttonActive(button),
          ]"
          v-for="(button, key) in buttons"
          :key="key"
          :disabled="button.disabled"
          :title="button.title"
          @click="pageChange(button)"
        >
          <i
            v-if="button.loading"
            class="fa fa-spinner fa-spin"
          />
          <template
            v-else
            v-dompurify-html="button.html"
          >
            <iconArrowBack v-if="button.html === 'iconArrowBack'" />
            <iconArrowNext v-else-if="button.html === 'iconArrowNext'" />
            <span
              v-else
              v-dompurify-html="button.html"
            />
          </template>
        </button>
      </slot>
    </div>
    <div>
      <span class="items-per-page-label">Items per page</span>
      <select
        @change="changeItemsPerPage"
        class="items-per-page-select"
        v-model="localItemsPerPage"
      >
        <option
          :key="option"
          v-for="option in itemsPerPageOptions"
          v-bind:value="option"
        >{{
          option
        }}</option>
      </select>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

/**
 * Pagination component.
 */
import iconArrowBack from '@/assets/tds-icons/arrow_back.svg';
import iconArrowNext from '@/assets/tds-icons/arrow_next.svg';

export default {
  name: 'Pagination',
  status: 'release',
  release: '1.0.0',
  components: {
    iconArrowBack,
    iconArrowNext,
  },
  props: {
    /**
     * A zero-based number to set the page. Be aware you need to update the page property by the result of the page-change action!
     */
    page: {
      type: Number,
      default: 0,
      validator: page => page >= 0,
    },
    /**
     * The Options to configure the maximum amount of items on one page.
     */
    itemsPerPageOptions: {
      type: Array,
      default: () => [5, 10, 20, 30, 40, 50],
    },
    /**
     * The maximum amount of items on one page.
     */
    itemsPerPage: {
      type: Number,
      default: 10,
      validator: itemsPerPage => itemsPerPage > 0,
    },
    /**
     * The maximum number of pages to be visible if their are too many pages.
     */
    maxVisiblePages: {
      type: Number,
      default: 5,
      validator: maxVisiblePages => maxVisiblePages > 0,
    },
    /**
     * The total amount of items.
     */
    totalItems: {
      type: Number,
      required: true,
      validator: totalItems => totalItems >= 0,
    },
    /**
     * Indicates if the current page is loading.
     */
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      localItemsPerPage: this.itemsPerPage,
      //   iconArrowBack: req('./arrow_back.svg'),
      //   iconArrowNext: req('./arrow_next.svg'),
    };
  },
  computed: {
    start () {
      return this.page * this.localItemsPerPage;
    },
    end () {
      const end = this.start + this.localItemsPerPage;

      return this.totalItems < end ? this.totalItems : end;
    },
    totalPages () {
      if (this.localItemsPerPage === 0) {
        return 0;
      }

      return Math.ceil(this.totalItems / this.localItemsPerPage);
    },
    pages () {
      const filteredPages = this.filteredPages;
      const pages = filteredPages
        ? [
          filteredPages[0] - 1 === 1 ? 1 : '...',
          ...filteredPages,
          filteredPages[filteredPages.length - 1] + 1 === this.totalPages - 2
            ? this.totalPages - 2
            : '...',
        ]
        : [...Array(this.totalPages - 2).keys()].map(page => page + 1);

      return [this.page - 1, 0, ...pages, this.totalPages - 1, this.page + 1];
    },
    filteredPages () {
      const diff = this.maxVisiblePages / 2;
      const toFilterPages = [...Array(this.totalPages).keys()].slice(2, -2);

      if (toFilterPages.length > this.maxVisiblePages) {
        const diffFirst = this.page - toFilterPages[0];
        const diffLast = this.page - toFilterPages[toFilterPages.length - 1];

        if (diffFirst < diff) {
          return toFilterPages.slice(0, this.maxVisiblePages);
        } if (diffLast >= -diff) {
          return toFilterPages.slice(-this.maxVisiblePages);
        }
        return toFilterPages.filter((page) => {
          const diffPage = this.page - page;

          return diffPage < 0 ? Math.abs(diffPage) <= diff : diffPage < diff;
        });
      }

      return null;
    },
    buttons () {
      return this.pages.map((page, key) => ({
        page,
        active: page === this.page,
        disabled: this.disabled(page, key),
        html: this.html(page, key),
        title: this.title(key),
        loading: this.loading && page === this.page,
      }));
    },
  },
  watch: {
    itemsPerPage: {
      handler (value) {
        this.localItemsPerPage = value;
        this.rangeChange();
      },
    },
    totalItems: {
      handler: 'rangeChange',
    },
  },
  methods: {
    buttonActive (button) {
      return button.active ? 'active' : '';
    },
    buttonHover (key, button) {
      return !button.active && !button.disabled && key !== 0 && key !== this.pages.length - 1
        ? 'hoverable'
        : '';
    },
    buttonNavClass (key) {
      if (key === 0) {
        return 'button-back';
      }

      if (key === this.pages.length - 1) {
        return 'button-next';
      }
      return '';
    },
    pageChange (button) {
      const { page, disabled, active } = button;
      if (page === '...' || disabled || active) {
        return;
      }
      if (page >= this.totalPages && page !== 0 && this.totalPages !== 0) {
        throw new Error('page may be maximum the total number of pages minus one');
      }
      this.rangeChange();
      /**
       * Emitted on creation, to know the initial state, and if another page is clicked.
       *
       * @event page-change
       * @property {number} page - The zero-based current page.
       * @type function
       */
      this.$emit('page-change', page);
    },
    rangeChange () {
      if (this.page >= this.totalPages && this.totalPages !== 0) {
        this.$emit('page-change', this.totalPages - 1);
      } else {
        /**
         * Emitted on creation, to know the initial state, and if another page is clicked or the total items change and you're on the last page
         *
         * @event range-change
         * @property {number} start - A zero-based number to identify the first item.
         * @property {number} end - A zero-based number to identify the last item.
         * @type function
         */
        this.$emit('range-change', this.start, this.end);
      }
    },
    changeItemsPerPage (evt) {
      /**
       * Emitted on changing items per page dropdown
       *
       * @event items-per-page-change
       * @property {number} itemsPerPage - Total item per page
       * @type function
       */
      this.$emit('items-per-page-change', parseInt(evt.target.value));
    },
    html (page, key) {
      if (key === this.pages.length - 1) {
        return 'iconArrowNext';
      }

      if (key === 0) {
        return 'iconArrowBack';
      }

      if (page === '...') {
        return page;
      }

      return `${page + 1}`;
    },
    disabled (page, key) {
      return (
        (key === 0 && this.page === 0)
        || (key === this.pages.length - 1 && this.page === this.totalPages - 1)
        || page === '...'
      );
    },
    title (key) {
      if (key === 0) {
        return 'previous';
      }

      if (key === this.pages.length - 1) {
        return 'next';
      }

      return '';
    },
  },
};
</script>

<style lang="scss">
@mixin circle-button {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  padding: 0;
}

@mixin text-md-14 {
  font-size: $font-md;
  font-weight: $weight-normal;
  line-height: $ln-height-20;
  color: $blue-gray-400;
}

@mixin text-sm-12 {
  font-size: $font-sm;
  font-weight: $weight-normal;
  line-height: $ln-height-xs;
  color: $blue-gray-400;
}

.t-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button {
    @include circle-button;
  }

  .button-page {
    @include text-md-14;
    display: flex;
    justify-content: center;
    background: transparent;
    border: none;
    outline: none;
    margin-right: 4px;
    margin-left: 4px;
    &.hoverable:hover {
      background-color: $blue-gray-100;
    }
    &.active {
      background-color: $blue-500;
      color: $white-500;
      cursor: default;
    }
    span {
      margin: auto;
      align-self: center;
    }
  }

  .button-back,
  .button-next {
    border: 1px solid $blue-500;
    background-color: $white-500;
    position: relative;
    &:disabled {
      background-color: $blue-gray-100;
      border-color: $blue-gray-100;
    }
    svg {
      fill: $blue-500;
      position: absolute;
      top: 50%;
      left: 50%;
      bottom: auto;
      right: auto;
      transform: translate(-50%, -50%);
    }
    &:hover:not(:disabled) {
      background-color: $blue-500;
      svg {
        fill: $white-500;
      }
    }
  }

  .button-back {
    margin-right: 15px;
  }

  .button-next {
    margin-left: 15px;
  }

  .items-per-page-select {
    @include text-md-14;
    border: 1px solid $blue-gray-100;
    border-radius: $radius_4;
    background-color: $white-500;
  }

  .items-per-page-label {
    @include text-sm-12;
    margin-right: 7px;
  }

  .page-label {
    @include text-sm-12;
  }
}
</style>

<docs>
  ```jsx
  <div>
    <div class="mt-lg">
      <Pagination
        v-bind:total-items="200"
        v-bind:max-visible-pages="5"
        v-bind:page="1"
        v-bind:loading="false"
        v-bind:items-per-page="10">
      </Pagination>
    </div>
  </div>
  ```
</docs>
