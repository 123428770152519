<template>
  <div class="icrs-search-filter-modal">
    <button
      v-if="displayMode === 'both' || displayMode === 'action'"
      class="btn btn-primary"
      :disabled="!filterModalSelectedFilters.length"
      @click="$emit('handle-filter-modal-clear-all')"
    >Clear Filter</button>
    <button
      v-if="displayMode === 'both' || displayMode === 'action'"
      class="btn btn-primary"
      @click="$emit('handle-modal-ok')"
    >Apply Filter Options</button>
    <button
      v-if="displayMode === 'both' || displayMode === 'action'"
      class="btn btn-outline-primary"
      @click="$emit('handle-modal-close')"
    >Cancel</button>
    <ICRSSortByButton
      v-if="displayMode === 'both' || displayMode === 'sort'"
      :sort-by="filterModalSortBy"
      @change="$emit('handle-filter-modal-sort-by-change', $event)"
    />
  </div>
</template>

<script>
import ICRSSortByButton from '../ICRSSortByButton.vue';

export default {
  name: 'ICRSModalButtons',
  components: {
    ICRSSortByButton,
  },
  props: {
    displayMode: {
      type: String,
      default () {
        return 'both';
      },
    },
    filterModalSelectedFilters: Array,
    filterModalSortBy: String,
  },
};
</script>
