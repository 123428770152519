<template>
  <div class="icrs-static-template">
    <!-- Start of JDS Scroll to Top Widget -->
    <!-- <a
      v-if="showScrollToTop"
      id="scroll-to-top"
      title="Scroll to Top"
      class="show"
      @click="scrollTop"
    >
    <span class="fas fa-chevron-up"></span>
    </a> -->
    <!-- End of JDS Scroll to Top Widget -->
    <ScrollTop />
    <div class="icrs-home-search-bar">
      <div class="container container-fluid icrs-static-template-body">
        <div class="icrs-component icrs-search-bar-component">
          <div class="card">
            <div class="card-body">
              <slot name="content" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ICRSFooter />
  </div>
</template>
<script>
import ScrollTop from '@/components/scroll-top/ScrollTop.vue';
import ICRSFooter from '@/modules/icrs-footer/ICRSFooter.vue';

export default {
  name: 'IcrsTemplate',
  components: {
    ICRSFooter,
    ScrollTop
  },
  data () {
    return {
      // showScrollToTop: false,
    };
  },
  methods: {
    // scrollTop () {
    //   document.body.scrollTop = 0; // For Safari
    //   document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // },
    // onScroll () {
    //   // Get the current scroll position
    //   const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    //   // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
    //   if (currentScrollPosition < 0) {
    //     return;
    //   }

    //   if (currentScrollPosition === 0) {
    //     this.showScrollToTop = false;
    //   } else {
    //     this.showScrollToTop = true;
    //   }
    // },
  },
  mounted () {
    // this.scrollTop();
    // window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy () {
    // window.removeEventListener('scroll', this.onScroll);
  },
};
</script>
<style lang="scss" scoped>
.icrs-static-template {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .icrs-static-template-body {
    min-height: 65vh;
    .card-body {
      background-color: white !important;
      border-radius: 0.2rem !important;
      p,
      ul {
        margin: 0 !important;
      }
    }
  }
}
</style>
