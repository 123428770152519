export default ({
  relations = '',
  relationsMin = 0,
  relationsMax = 20,
  maxEdges = 20,
  nodes = [],
  edges = [],
  displayedNodes = [],
  displayedEdges = [],
  selectedNode = [],
  history = [],
  table = [],
  ontologyMap = {},
  maxHistoryLength = 40,
  classFilters = {},
  individualProperties = [],
} = {}) => ({
  relations,
  relationsMin,
  relationsMax,
  maxEdges,
  nodes,
  edges,
  displayedNodes,
  displayedEdges,
  selectedNode,
  history,
  table,
  ontologyMap,
  maxHistoryLength,
  classFilters,
  individualProperties,
});
