<template>
  <div>
    <b-button
      id="pro-tips-button"
      variant="link"
      :class="buttonPosition"
      data-cy="pro-tips-button"
    >How to use this?
    </b-button>

    <b-popover
      ref="protips"
      id="advanced-search-pro-tips"
      target="pro-tips-button"
      triggers="focus"
      customClass="protips-popover"
      placement="auto"
      delay="100"
      :show.sync="popoverShow"
    >
      <template #title>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div>Pro Tips:</div>
          <b-button
            @click="onClose"
            variant="link"
            size="sm"
          >
            <div class="text-primary">
              Close
            </div>
          </b-button>
        </div>
      </template>
      <img src="@/assets/ontology-pro-tips-filters.png" />
      <img src="@/assets/ontology-pro-tips-knowledge-graph.png" />
    </b-popover>
  </div>

</template>
<script>
export default {
  name: 'AdvancedSearchProTips',
  data () {
    return {
      popoverShow: false,
    };
  },
  props: {
    buttonPosition: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClose () {
      this.popoverShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
#pro-tips-button {
  color: $blue-500 !important;
}
.protips-popover {
  max-width: 650px !important;
}
#advanced-search-pro-tips {
  img {
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
