export default ({
  guidedSearch = false,
  dropdownGuidedTag = '',
  activeGuidedPrefix = 0,
  selectedLevel1 = { code: 'All' },
  selectedLevel2 = { code: 'All' },
  selectedLevel3 = { code: 'All' },
  level2CategoriesForSelectedLevel1 = [{ code: 'All' }],
  level3CategoriesForSelectedLevel2 = [{ code: 'All' }],
  allGuidedCategories = [{ level1: [], level2: [], level3: [] }],
  advancedSearchSelectedLevel = {
    level1: { code: 'All' },
    level2: { code: 'All' },
    level3: { code: 'All' },
    level2ForSelectedLevel1: [{ code: 'All' }],
    level3ForSelectedLevel2: [{ code: 'All' }],
  },
} = {}) => ({
  guidedSearch,
  dropdownGuidedTag,
  activeGuidedPrefix,
  selectedLevel1,
  selectedLevel2,
  selectedLevel3,
  level2CategoriesForSelectedLevel1,
  level3CategoriesForSelectedLevel2,
  allGuidedCategories,
  advancedSearchSelectedLevel,
});
