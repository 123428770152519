import * as actions from '../actions';

describe('search-core/filters/date action tests', () => {
  test('handleStartDateChange', () => {
    const commit = jest.fn();
    const dispatch = jest.fn();
    const startDate = '2021-04-05T00:00:00.000Z';

    actions.handleStartDateChange({ commit, dispatch }, startDate);

    expect(commit).toHaveBeenLastCalledWith('setStartDate', startDate);
    expect(dispatch.mock.calls[0][0]).toBe('triggerSearchQuery');
  });

  test('handleEndDateChange', () => {
    const commit = jest.fn();
    const dispatch = jest.fn();
    const endDate = '2021-04-20T00:00:00.000Z';

    actions.handleEndDateChange({ commit, dispatch }, endDate);

    expect(commit).toHaveBeenLastCalledWith('setEndDate', endDate);
    expect(dispatch.mock.calls[0][0]).toBe('triggerSearchQuery');
  });

  test('triggerSearchQuery', () => {
    const dispatch = jest.fn();

    actions.triggerSearchQuery({ dispatch });

    expect(dispatch.mock.calls[0][0]).toBe('updateSearchDateObject');
    expect(dispatch.mock.calls[1][0]).toBe('searchService/filters/executeFilterSearch');
  });

  test('updateSearchDateObject', () => {
    const dispatch = jest.fn();
    const getters = {
      dateObject: {
        start: '2021-04-05T00:00:00.000Z',
        end: '2021-04-20T00:00:00.000Z',
        field: 'lastModifiedDate',
      },
    };

    actions.updateSearchDateObject({ dispatch, getters });

    expect(dispatch.mock.calls[0][0]).toBe('searchService/setCurrentDateObject',
      getters.dateObject, { root: true });
    expect(dispatch.mock.calls[1][0]).toBe('searchService/newResults/setCurrentDateObject',
      getters.dateObject, { root: true });
  });

  test('updateCurrentDate', () => {
    const commit = jest.fn();
    const dispatch = jest.fn();
    const currentDate = {
      start: '2021-03-09T00:00:00.000Z',
      end: '2021-03-11T23:59:59.999Z',
      field: 'lastModifiedDate',
    };

    actions.updateCurrentDate({ commit, dispatch }, currentDate);
    expect(commit).toHaveBeenLastCalledWith('setDateObject', currentDate);
    expect(dispatch.mock.calls[0][0]).toBe('updateSearchDateObject');
  });
});
