export default class FilterItem {
  /**
   * @param {import('@typings/search').Bucket} bucket
   * @param {string} groupName
   */
  constructor (bucket, groupName) {
    const { key, docCount } = bucket;
    /** @member {string} */
    this.key = key;
    /** @member {number} */
    this.docCount = docCount;
    /** @member {string} */
    this.groupName = groupName;
  }

  static create (bucket, groupName) {
    return new FilterItem(bucket, groupName);
  }
}
