<template>
  <div class="sort-by-button">
    <div
      id="sort-by-button"
      class="d-inline-block"
    >
      <button
        @click="toggleDropdown"
        class="btn btn-link"
      >
        <font-awesome-icon
          class="icon"
          :icon="iconClass"
          size="1x"
        />
      </button>
      <Tooltip
        container="filter-modal"
        triggers="hover"
        target="sort-by-button"
      >
        {{ currentSortByTooltipText }}</Tooltip>
    </div>

    <template v-if="isOpen">
      <div
        v-click-outside="handleClickOutsideDropdown"
        class="dropdown"
      >
        <div
          id="sort-most-to-least"
          class="button-wrapper"
        >
          <button
            :class="{ 'active': sortBy === sortTypes.MOST_TO_LEAST }"
            @click="applySort(sortTypes.MOST_TO_LEAST)"
          >
            <font-awesome-icon
              class="icon"
              icon="sort-amount-up"
              size="sm"
            />
          </button>
          <Tooltip
            container="filter-modal"
            target="sort-most-to-least"
          >Sort filters most to least</Tooltip>
        </div>
        <div
          id="sort-least-to-most"
          class="button-wrapper"
        >
          <button
            :class="{ 'active': sortBy === sortTypes.LEAST_TO_MOST }"
            @click="applySort(sortTypes.LEAST_TO_MOST)"
          >
            <font-awesome-icon
              class="icon"
              icon="sort-amount-down-alt"
              size="sm"
            />
          </button>
          <Tooltip
            container="filter-modal"
            target="sort-least-to-most"
          >Sort filters least to most</Tooltip>
        </div>
        <div
          id="sort-a-z"
          class="button-wrapper"
        >
          <button
            :class="{ 'active': sortBy === sortTypes.A_TO_Z }"
            @click="applySort(sortTypes.A_TO_Z)"
          >
            <font-awesome-icon
              class="icon"
              icon="sort-alpha-down"
              size="sm"
            />
          </button>
          <Tooltip
            container="filter-modal"
            target="sort-a-z"
          >Sort filters A to Z</Tooltip>
        </div>
        <div
          id="sort-z-a"
          class="button-wrapper"
        >
          <button
            :class="{ 'active': sortBy === sortTypes.Z_TO_A }"
            @click="applySort(sortTypes.Z_TO_A)"
          >
            <font-awesome-icon
              class="icon"
              icon="sort-alpha-down-alt"
              size="sm"
            />
          </button>
          <Tooltip
            container="filter-modal"
            target="sort-z-a"
          >Sort filters Z to A</Tooltip>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import constants from '../../constants/search-service-constants';

export default {
  props: {
    sortBy: String,
  },
  data () {
    return {
      isOpen: false,
      sortTypes: constants.FILTER_SORT_BY_OPTIONS,
    };
  },
  computed: {
    currentSortByTooltipText () {
      const textMap = {
        [constants.FILTER_SORT_BY_OPTIONS.MOST_TO_LEAST]: 'most to least',
        [constants.FILTER_SORT_BY_OPTIONS.LEAST_TO_MOST]: 'least to most',
        [constants.FILTER_SORT_BY_OPTIONS.A_TO_Z]: 'A to Z',
        [constants.FILTER_SORT_BY_OPTIONS.Z_TO_A]: 'Z to A',
      };

      return `Sort filters ${textMap[this.sortBy]}`;
    },
    iconClass () {
      switch (this.sortBy) {
        case constants.FILTER_SORT_BY_OPTIONS.MOST_TO_LEAST:
          return 'sort-amount-up';
        case constants.FILTER_SORT_BY_OPTIONS.LEAST_TO_MOST:
          return 'sort-amount-down-alt';
        case constants.FILTER_SORT_BY_OPTIONS.A_TO_Z:
          return 'sort-alpha-down';
        case constants.FILTER_SORT_BY_OPTIONS.Z_TO_A:
          return 'sort-alpha-down-alt';
        default:
          return '';
      }
    },
  },
  methods: {
    applySort (value) {
      this.isOpen = false;
      this.$emit('change', value);
    },
    toggleDropdown () {
      this.isOpen = !this.isOpen;
    },
    handleClickOutside () {
      this.toggleDropdown();
    },
    handleClickOutsideDropdown () {
      this.isOpen = false;
    },
    unFocusSortByButton () {
      // this.$refs.sortByButton
    },
  },
};
</script>

<style lang="scss" scoped>
.sort-by-button {
  position: relative;

  .btn-link {
    color: $blue-500 !important;
    padding: 0;
    width: 30px;
    height: 30px;
    margin-right: 12px;

    &:hover {
      background: $blue-200;
    }
  }

  .dropdown {
    position: absolute;
    bottom: -36px;
    left: 0;
    z-index: 100;
    display: flex;
    border: 1px solid $blue-gray-100;
    border-radius: 4px;
    padding: 5px 6px;
    box-shadow: 2px 2px 4px rgba(119, 130, 149, 0.25);

    width: 88px;
    background: $white-500;

    .button-wrapper {
      margin-right: 4px;

      &:last-child {
        /* margin-right: 0; */
      }
    }

    button {
      color: $blue-gray-400;
      padding: 0;
      width: 16px;
      height: 16px;
      background: transparent;
      border: 0;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        color: $blue-500;
        background: $blue-200;
      }
    }
  }
}
</style>
