<template>
  <div :class="{'icrs-preview-page': showFooter}">
    <div class="preview-page container container-fluid" style="margin-top: 0 !important;"
      :class="{'no-breadcrumb': hasNoBreadcrumb}"
    >
      <!-- <a
        v-if="showScrollToTop"
        id="scroll-to-top"
        title="Scroll to Top"
        class="show"
        @click="scrollTop"
      >
        <span class="fas fa-chevron-up"></span>
      </a> -->
      <ScrollTop />
      <p v-if="showTitle">{{ title }}</p>
      <div v-if="previewData" class="p-4" v-dompurify-html="purifyHTML">
      </div>
      <div v-if="isLoadingData" class="spinner-container d-flex align-items-center justify-content-center"
       :style="{'min-height': `calc(${minContentHeight})`}"
      >
        <LoadingSpinner v-if="isLoadingData"
          text="Loading..."
          size="lg"
        />
      </div>
    </div>
    <div class="btn-close container container-fluid" v-if="!showFooter">
      <button
        @click="handleClosePreview"
        class="btn btn-primary"
      >
        Close
      </button>
    </div>
    <ICRSFooter v-if="showFooter" ref="footer"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ICRSFooter from '@/modules/icrs-footer/ICRSFooter.vue';
import PageManagementService from '@/services/page-management.service';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import DOMPurify from 'dompurify';
import ScrollTop from '@/components/scroll-top/ScrollTop.vue';

export default {
  name: '',
  components: {
    ICRSFooter,
    LoadingSpinner,
    ScrollTop
  },
  data () {
    return {
      isLoadingData: false,
      title: '',
      footerHeight: 0,
      // showScrollToTop: false,
    };
  },
  methods: {
    ...mapActions('pageManagement', ['setPreviewData']),
    handleClosePreview () {
      if (this.previewData.id) {
        this.$router.push('/page-management/edit-page');
      } else this.$router.push('/page-management/create-new-page');
    },
    async getDetails (id) {
      this.setPreviewData(undefined);
      this.isLoadingData = true;
      await PageManagementService.getPageDetail(id).then(({ data }) => {
        this.setPreviewData(data);
      }).finally(() => {
        this.isLoadingData = false;
      });
    },
    loadingHeight () {
      const height = this.$refs.footer.$el.clientHeight;
      this.footerHeight = height;
    },
    // scrollTop () {
    //   document.body.scrollTop = 0;
    //   document.documentElement.scrollTop = 0;
    // },
    // onScroll () {
    //   const currentScrollPosition = document.documentElement.scrollTop;
    //   if (currentScrollPosition < 0) {
    //     return;
    //   }

    //   if (currentScrollPosition === 0) {
    //     this.showScrollToTop = false;
    //   } else {
    //     this.showScrollToTop = true;
    //   }
    // },
  },
  async mounted () {
    // window.addEventListener('scroll', this.onScroll);

    const { from } = this.$route.query;
    if (from === 'Footer') this.loadingHeight();
    const { id } = this.$route.params;
    if (id) {
      this.getDetails(id);
      return;
    }
    if (!this.previewData) {
      this.$router.push('/page-management');
    }
  },
  beforeDestroy () {
    // window.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    ...mapGetters('pageManagement', ['bottomNavList', 'footerList', 'previewData']),
    footerAndNavData () {
      return [...this.bottomNavList, ...this.footerList];
    },
    hasNoBreadcrumb () {
      const { from } = this.$route.query;
      return from === 'Footer';
    },
    showFooter () {
      const { from } = this.$route.query;
      return from === 'Footer';
    },
    showTitle () {
      const { from } = this.$route.query;
      return from !== 'Footer';
    },
    currentId () {
      return this.$route.params.id;
    },
    minContentHeight () {
      return `calc(100vh - 79px - 10px - ${this.footerHeight}px)`;
    },
    purifyHTML () {
      return DOMPurify.sanitize(this.previewData.content);
    },
  },
  watch: {
    currentId: {
      handler (value) {
        if (value) this.getDetails(value);
      },
      deep: true, // Enable deep watching
      immediate: true, // Trigger the handler immediately
    },
    previewData: {
      handler (value) {
        if (value) this.title = value.title;
      },
      deep: true, // Enable deep watching
      immediate: true, // Trigger the handler immediately
    },
  },
};
</script>

<style lang="scss" scoped>
.icrs-preview-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.preview-page {
  padding-top: 150px;
  & > p {
    font-size: 32px !important;
    line-height: 43px !important;
    font-weight: bold !important;
  }
  &>div {
    background-color: #fff;
    img {
      width: 100% !important;
    }
  }
  .spinner-container{
    width: 100%;
  }
}

.btn-close{
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end
}

.preview-page.no-breadcrumb {
  padding-top: 78px;
}
</style>
