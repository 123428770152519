<template>
  <div id="connector-page">
    <div
      v-show="showWizardLink"
      class="container-fluid connector-page-add-new"
    >
      <router-link
        class="btn btn-primary"
        to="/connector/wizard"
      >Create New Connector</router-link>
    </div>
    <div class="container-fluid ">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showWizardLink: true,
    };
  },
  watch: {
    $route (to) {
      this.showWizardLink = (to.path === '/connector/dashboard');
    },
  },
  beforeRouteEnter (to, from, next) {
    // eslint-disable-next-line global-require
    // require('search-connector-ui/dist/js/app');
    next();
  },
};
</script>

<style lang="scss">
#connector-page {
  margin-top: 3.5rem;
  padding-top: 3rem;

  .search-connector-styles {
    display: block;
    .section {
      padding: 1rem 0;
    }
    h1.title {
      font-size: 2.5rem !important;
    }
    h1.subtitle {
      font-size: 2rem !important;
    }
  }
}
</style>
