<template>
  <b-card-body class="fixed_height" body-bg-variant="blue-gray-100_alpha">
    <b-card-title>Storage</b-card-title>

    <b-list-group flush>
      <b-card-text>
        Metadata and Tags generated from documents are stored and indexed.
      </b-card-text>

      <b-card-text id="iconTextGroup">
        <icon-text faType="fal" faName="folder-tree" text="Document Indexing"></icon-text>
      </b-card-text>

      <b-card-text>
        Manage documents with
        <strong>Document Settings Manager</strong>
      </b-card-text>

      <redirect-button
        label="Document Settings Manager"
        pathLink="/doc-manager"
        :variant="variant"
      ></redirect-button>
    </b-list-group>
    <dual-arrow-svg class="floating_arrow"/>
  </b-card-body>
</template>

<script>
import IconText from '@/components/IconText.vue';

import dualArrowSvg from '@/assets/admin-page/icons/circle-dual-arrow.svg';
import redirectButton from './RedirectionButton.vue';


export default {
  components: {
    redirectButton,
    IconText,
    dualArrowSvg,
  },
  props: {
    variant: String,
  },
  data () {
    return {
    };
  },
};
</script>

<style scoped lang="scss">
#iconTextGroup {
  margin-left: auto;
  margin-right: auto;
}
</style>
