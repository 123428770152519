<template>
  <div class="search-result">
    <div
      v-if="isSearching"
      class="loading-spinner-wrapper"
    >
      <LoadingSpinner
        text="Loading search results..."
        size="lg"
      />
    </div>

    <div
      class="keyword-answers-wrapper"
      v-if="!useICRSConfig"
    >
      <KeywordAnswers :answers="naturalLanguageAnswer" />
    </div>

    <div
      class="keyword-corrections-wrapper"
      v-if="!useICRSConfig"
    >
      <KeywordCorrections
        @link-click="handleLinkClick"
        :corrections="corrections"
      />
    </div>

    <!-- watchlist module -->
    <SearchResultsNew
      v-if="newSearchResultsEnabled"
      @open-preview-modal="openPreviewViewerModal"
      @open-document-settings-modal="openDocumentSettingsModal"
      @trigger-document-metrics="execDocumentMetrics"
    />

    <div
      v-if="!useICRSConfig"
      class="applied-filters-wrapper"
    >
      <AppliedFilters :filterCountDisabled="newSearchResultsEnabled" />
    </div>

    <div
      v-if="!useICRSConfig"
      class="filter-section"
      style="margin-bottom: 5px"
    >
      <div class="search-category">
        <span class="d-inline-block mr-2 text-md">
          <strong>{{ icrsCaseTotal }}</strong>
          {{ searchResultLabel }}
        </span>
        <Dropdown
          v-if="!config.isSourcesDropdownDisabled"
          class="category-dropdown"
          :value="selectedCategory"
          :options="searchCategoryOptions"
          :disabled="shouldDisableSearchCategory"
          @input="handleSelectedCategoryChange"
        ></Dropdown>
      </div>
      <div
        class="d-flex"
        style="align-items: center"
      >
        <div class="search-within-wrapper">
          <SearchWithin />
        </div>
        <span class="mr-2 text-md">Sort by</span>
        <Dropdown
          :value="selectedSortBy"
          :options="sortByOptions"
          @input="handleSortByChange"
        ></Dropdown>
        <div class="display-buttons-wrapper">
          <ToggleButton
            icon
            value="standard"
            :options="[
              { text: 'th-large', value: 'standard' },
              { text: 'list', value: 'table' }
            ]"
            v-model="display"
          ></ToggleButton>
        </div>
      </div>
    </div>

    <div
      v-if="useICRSConfig"
      class="row"
    >
      <div class="col-md-4">
        <span
          v-if="!icrsPlotSearching"
          class="d-inline-block mr-2"
        >
          <strong>{{ icrsCaseTotal }}</strong>
          <span v-if="icrsCaseTotal == 1">&nbsp;case found&nbsp;</span>
          <span v-if="icrsCaseTotal != 1">&nbsp;cases found&nbsp;</span>
          <br />
          <span v-if="showInjuries && icrsGlobalCount != -1 && icrsGlobalCount == 1">
            (includes {{ icrsGlobalCount }} with global awards)
          </span>
          <span v-if="showInjuries && icrsGlobalCount != -1 && icrsGlobalCount != 1">
            (includes {{ icrsGlobalCount }} with global awards)
          </span>
          <span v-if="showInjuries && icrsGlobalCount == -1">
            (includes unknown with global awards)
          </span>
        </span>
      </div>
      <div class="col-md-4">
        <span class="mr-2 text-md">Display Summary Options:</span>
        <b-dropdown
          block
          split
          variant="outline-primary"
          :text="selectedSummaryChoice.text"
          split-class="options-text"
        >
          <b-dropdown-item
            v-for="option in displaySummaryOptions"
            :key="'display_' + option.value"
            :active="selectedSummaryChoice.value === option.value"
            @click="handleSummaryChoice(option)"
          >
            {{option.text}}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="col-md-4">
        <span class="mr-2 text-md">Results Sorting Options:</span>
        <b-dropdown
          block
          split
          variant="outline-primary"
          :text="selectedSortBy"
          split-class="options-text"
        >
          <b-dropdown-item
            v-for="option in sortByOptions"
            :key="'sortby_' + option.value"
            :active="selectedSortBy === option.text"
            @click="handleSortByChange(option.value)"
          >
            {{option.text}}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <template v-if="!hasResult">
      <div class="no-result">
        <font-awesome-icon
          class="icon no-result__icon"
          :icon="['fal', 'file']"
          size="1x"
        />
        <p
          v-if="!useICRSConfig"
          class="no-result__text"
        >No results were found</p>
        <p
          v-if="useICRSConfig"
          class="no-result__text"
        >No Results Found</p>
      </div>
    </template>

    <!-- Standard display -->
    <template v-if="hasResult && display === 'standard'">
      <standard-results
        :searchResults="icrsFurtherFilteredDocuments"
        @open-preview-modal="openPreviewViewerModal"
        @open-document-settings-modal="openDocumentSettingsModal"
        @trigger-document-metrics="execDocumentMetrics"
        :overallShowAwards="overallShowAwards"
        :overallShowKeywords="overallShowKeywords"
      />
    </template>

    <!-- Table display -->
    <template v-if="hasResult && display === 'table'">
      <compact-results
        :searchResults="currentSearchResults__documents"
        @open-preview-modal="openPreviewViewerModal"
        @open-document-settings-modal="openDocumentSettingsModal"
        @trigger-document-metrics="execDocumentMetrics"
      />
    </template>

    <div
      v-if="icrsCaseTotal"
      class="pagination-wrapper"
    >
      <div class="pagination-desktop">
        <Pagination
          :total-items="icrsCaseTotal"
          :max-visible-pages="5"
          :page="offsetPage"
          :loading="false"
          :items-per-page="itemsPerPage"
          @page-change="handlePageChange"
          @items-per-page-change="handleItemsPerPageChange"
        />
      </div>
      <!-- Use simple pagination as work around to make pagination mobile friendly -->
      <div class="pagination-mobile">
        <SimplePagination
          :total-items="icrsCaseTotal"
          :page="offsetPage"
          :loading="false"
          :page-size="itemsPerPage"
          @page-change="handlePageChange"
        />
      </div>
    </div>

    <div
      class="keyword-suggestions-wrapper"
      v-if="suggestions.length  && !useICRSConfig"
    >
      <KeywordSuggestions
        @link-click="handleLinkClick"
        :suggestions="suggestions"
      />
    </div>

    <div class="modal-wrapper">
      <doc-manager
        ref="docManager"
        :haveWriteAccess="isAdminUser"
        @doc-manager-closed="triggerSearchRefresh"
      ></doc-manager>
      <preview-viewer-modal ref="previewViewerModal" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PreviewViewerModal from '@/modules/preview-viewer-modal/PreviewViewerModal';
import KeywordCorrections from '@/components/KeywordCorrections.vue';
import KeywordSuggestions from '@/components/KeywordSuggestions.vue';
import KeywordAnswers from '@/components/keyword-answers/KeywordAnswers.vue';
import DocManager from '../doc-manager/DocManager.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import AppliedFilters from '../applied-filters/AppliedFilters.vue';
import SearchServiceTypes from '../../constants/search-service-constants';
import SearchWithin from '../search-within/SearchWithin.vue';
import CompactResults from './components/CompactResults';
import StandardResults from './components/StandardResults';
import SearchResultsNew from './components/SearchResultsNew';
import { DISPLAY_OPTIONS } from './search-results-constants';

export default {
  name: 'SearchResults',
  props: {
    currentQuery: {
      type: String,
      default: null,
    },
    icrsGlobalCount: {
      type: Number,
      default: -1,
    },
    showInjuries: {
      type: Boolean,
      default: false,
    },
    icrsScatterPlotCaseIds: {
      type: Array,
      default () {
        return [];
      },
    },
    icrsCaseTotal: {
      type: Number,
      default: 0,
    },
  },
  components: {
    SearchWithin,
    DocManager,
    LoadingSpinner,
    KeywordCorrections,
    KeywordSuggestions,
    KeywordAnswers,
    AppliedFilters,
    PreviewViewerModal,
    CompactResults,
    StandardResults,
    SearchResultsNew,
  },
  data () {
    return {
      display: SearchServiceTypes.RESULT_DISPLAY_MODE.STANDARD,
      sortByOptions: SearchServiceTypes.SORT_BY_OPTIONS,
      displaySummaryOptions: [
        { value: DISPLAY_OPTIONS.HIDE_ALL, text: 'Hide All' },
        { value: DISPLAY_OPTIONS.SHOW_ALL_AWARDS, text: 'Show All Awards' },
        { value: DISPLAY_OPTIONS.SHOW_ALL_KEYWORDS, text: 'Show All Keywords' },
        { value: DISPLAY_OPTIONS.SHOW_ALL, text: 'Show All' },
      ],
      selectedSummaryChoice: { value: 'hideAll', text: 'Hide All' },
      overallShowAwards: false,
      overallShowKeywords: false,
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
      config: {
        isSourcesDropdownDisabled: false,
      },
    };
  },
  mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
  },
  computed: {
    ...mapGetters('searchService', [
      'currentSearchResults',
      'currentSearchResults__documents',
      'corrections',
      'suggestions',
      'naturalLanguageAnswer',
      'allSourceDocCount',
      'searchCategories',
      'isSearching',
      'currentPage',
      'itemsPerPage',
      'icrsPlotSearching',
    ]),
    ...mapGetters('searchService/newResults', [
      'criteriaId',
      'newSearchResultsEnabled',
    ]),
    ...mapGetters('advancedSearch', [
      'isShowOverview',
    ]),
    ...mapGetters('searchService', {
      currentAppliedSort: 'appliedSort',
      currentSelectedSource: 'selectedSource',
    }),
    ...mapGetters({
      isAdminUser: 'authService/isAdminUser',
    }),
    icrsFurtherFilteredDocuments () {
      if (this.useICRSConfig && this.showInjuries) {
        return this.currentSearchResults__documents.filter(
          document => this.icrsScatterPlotCaseIds.includes(document.id),
        );
      }
      return this.currentSearchResults__documents;
    },
    shouldDisableSearchCategory () {
      const count = this.searchCategoryOptions.length;
      return count < 2;
    },
    hasResult () {
      return Boolean(
        this.currentSearchResults__documents
        && this.currentSearchResults__documents.length,
      );
    },
    selectedSortBy () {
      const appliedSort = this.currentAppliedSort;
      const foundOption = SearchServiceTypes.SORT_BY_OPTIONS
        .find(({ parameter }) => (
          parameter.field === appliedSort.field
          && parameter.order === appliedSort.order
        ))
        // || { value: 'relevance' };
        || { text: 'Relevance' };

      // return foundOption.value;
      return foundOption.text;
    },

    selectedCategory () {
      if (!this.searchCategoryOptions || !this.currentSelectedSource) {
        return 'all';
      }
      const [source] = this.currentSelectedSource;
      return this.searchCategoryOptions.find(option => option.value === source);
    },
    searchCategoryOptions () {
      if (!this.searchCategories) return [];

      const searchCategories = this.searchCategories.map(item => ({
        text: `${item.key} (${item.docCount})`,
        value: item.key,
      }));
      return [{ value: 'all', text: `All sources (${this.allSourceDocCount})` }].concat(searchCategories);
    },
    offsetPage () {
      return this.currentPage - 1;
    },
    searchResultLabel () {
      const resultLabel = this.icrsCaseTotal === 1 ? 'result' : 'results';
      if (this.config.isSourcesDropdownDisabled) {
        return ` ${resultLabel} found`;
      }
      return ` ${resultLabel} found in`;
    },
  },
  watch: {
    isShowOverview (value) {
      if (!value) {
        const pageSizeSelect = document.querySelector('.items-per-page-select');
        if (pageSizeSelect) pageSizeSelect.setAttribute('aria-label', 'Page size select');
      }
    },
  },
  methods: {
    ...mapActions('searchService', [
      'triggerSearchQuery',
      'triggerSearchRefresh',
      'setAppliedSort',
      'setCurrentSearchMode',
      'setSelectedSource',
      'executeSearchQuery',
      'setCurrentPage',
      'setItemsPerPage',
      'execDocumentMetrics',
    ]),

    handleLinkClick (text) {
      this.triggerSearchQuery({
        query: text,
      });
    },

    handleSortByChange (value) {
      const nextSortBy = SearchServiceTypes.SORT_BY_OPTIONS
        .find(option => option.value === value);

      this.setAppliedSort(nextSortBy.parameter);
      this.setCurrentSearchMode(SearchServiceTypes.SEARCH_MODE.SOURCE);
      this.executeSearchQuery();
    },

    handleSummaryChoice (option) {
      this.selectedSummaryChoice = option;
      switch (this.selectedSummaryChoice.value) {
        case DISPLAY_OPTIONS.HIDE_ALL:
          this.overallShowAwards = false;
          this.overallShowKeywords = false;
          break;
        case DISPLAY_OPTIONS.SHOW_ALL_AWARDS:
          this.overallShowAwards = true;
          this.overallShowKeywords = false;
          break;
        case DISPLAY_OPTIONS.SHOW_ALL_KEYWORDS:
          this.overallShowAwards = false;
          this.overallShowKeywords = true;
          break;
        case DISPLAY_OPTIONS.SHOW_ALL:
          this.overallShowAwards = true;
          this.overallShowKeywords = true;
          break;
        default:
          this.overallShowAwards = false;
          this.overallShowKeywords = false;
          break;
      }
    },

    handleSelectedCategoryChange (value) {
      const nextCategory = value === 'all'
        ? undefined
        : [value];

      this.setSelectedSource(nextCategory);
      this.setCurrentSearchMode(SearchServiceTypes.SEARCH_MODE.SOURCE);
      this.executeSearchQuery();
    },

    handlePageChange (page) {
      this.setCurrentPage(page + 1);
      this.executeSearchQuery();
    },

    handleItemsPerPageChange (number) {
      this.setItemsPerPage(number);
      this.setCurrentPage(1);
      this.executeSearchQuery();
    },

    openDocumentSettingsModal (document, tab) {
      const { id } = document;
      this.$refs.docManager.showModal(id, tab);
    },

    openPreviewViewerModal (document) {
      this.$refs.previewViewerModal.openPreviewViewer(document);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-result {
  position: relative;

  &/deep/ .category-dropdown {
    .dropdown-toggle {
      padding-right: 20px;
    }
    .dropdown-menu.show {
      width: auto;
    }
  }

  .loading-spinner-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: rgba($white-500, 0.9);
    padding-top: 40px;
    text-align: center;
  }
}

.filter-section {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &/deep/ .form-group {
    margin-bottom: 0;
  }

  .search-category {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
}

.display-buttons-wrapper {
  margin-left: $gap-md;

  &/deep/ {
    .t-toggle-button {
      margin-bottom: 0 !important;
    }

    label.btn.focus {
      box-shadow: none !important;
    }
  }
}

@media (min-width: 540px) {
  .filter-section {
    flex-direction: row;

    .search-category {
      margin-bottom: 0;
    }
  }
}

.search-within-wrapper {
  margin-right: $gap-md;
}

.applied-filters-wrapper {
  padding-top: $gap-md;
}

.keyword-answers-wrapper {
  padding-top: $gap-lg;
}

.keyword-corrections-wrapper {
  padding-top: $gap-lg;
}

.keyword-suggestions-wrapper {
  padding: $gap-sm 0;
}

.no-result {
  margin-top: 22px;
  text-align: center;
  &/deep/ {
    svg.icon {
      font-size: 32px;
    }
  }

  &__text {
    margin-top: 20px;
    font-size: 20px;
  }
}
.pagination-wrapper {
  // padding: $gap-lg 0;
  padding-top: 15px;
  .pagination-desktop {
    display: none;
  }

  .pagination-mobile {
    display: block;
    display: flex;
    justify-content: flex-end;
    .t-simple-pagination{
      font-size: 18px;
    }
  }

  @media (min-width: 768px) {
    .pagination-desktop {
      display: block;
    }

    .pagination-mobile {
      display: none;
    }
  }
}
</style>
