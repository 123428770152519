var render = function render(){var _vm=this,_c=_vm._self._c;return _c('IcrsWrapperPage',[_c('div',{staticClass:"create-new-page"},[_c('div',{staticClass:"container container-fluid"},[_c('div',{staticClass:"create-new-page-content",class:{['d-none'] : _vm.isLoadingPage}},[_c('div',{staticClass:"page-title"},[_vm._v(_vm._s(this.newPageCurrent ? 'Edit' : 'Create New')+" Page / Link")]),_c('div',{staticClass:"page-content"},[_c('b-alert',{staticClass:"has-icon alert-large",attrs:{"show":_vm.dismissCountDown,"dismissible":"","variant":"error"},on:{"dismissed":function($event){_vm.dismissCountDown=0},"dismiss-count-down":_vm.countDownChanged}},[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"has-icon"},[_c('i',{staticClass:"fa fa-check-circle"})]),_c('div',{staticClass:"alert-content"},[_c('h4',[_vm._v("Error")]),_c('p',[_vm._v(_vm._s(_vm.errorMessage))])])])]),_c('div',{staticClass:"content-container top-content"},[_c('BasicInformation',{attrs:{"page":_vm.page,"setValue":_vm.setValue,"setEditor":_vm.setEditor,"checkPageCurrent":_vm.checkPageCurrent}})],1),(_vm.page.type === 'Page')?_c('div',{staticClass:"bottom-content"},[_c('div',{staticClass:"content-container left-content"},[_c('div',{staticClass:"left-content-header"},[_vm._v("\n                Page Content\n              ")]),_c('div',{staticClass:"left-content-body"},[_c('div',{staticClass:"btn-group btn-group-toggle text-type-toggle"},[_c('button',{staticClass:"btn",class:{
                      'btn-primary': _vm.textType === _vm.richText,
                      'btn-outline-primary': _vm.textType !== _vm.richText
                    },on:{"click":_vm.changeTextType}},[_vm._v("\n                    Rich-text\n                  ")]),_c('button',{staticClass:"btn",class:{
                      'btn-primary': _vm.textType === _vm.htmlText,
                      'btn-outline-primary': _vm.textType !== _vm.htmlText
                    },on:{"click":_vm.changeTextType}},[_vm._v("\n                    HTML\n                  ")])]),_c('div',{staticClass:"text-area"},[_c('RichTextEditor',{directives:[{name:"show",rawName:"v-show",value:(_vm.textType === _vm.richText),expression:"textType === richText"}],ref:"richTextEditor",model:{value:(_vm.richTextValue),callback:function ($$v) {_vm.richTextValue=$$v},expression:"richTextValue"}}),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.richTextValue),expression:"richTextValue",modifiers:{"trim":true}},{name:"show",rawName:"v-show",value:(_vm.textType === _vm.htmlText),expression:"textType === htmlText"}],staticClass:"html-text",domProps:{"value":(_vm.richTextValue)},on:{"input":function($event){if($event.target.composing)return;_vm.richTextValue=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})],1)])])]):_vm._e()],1),_c('div',{staticClass:"footer-btn-group"},[(_vm.page.type === 'Page')?_c('button',{staticClass:"btn btn-cancel",on:{"click":_vm.handlePreview}},[_vm._v("Preview")]):_vm._e(),_c('button',{staticClass:"btn btn-cancel",on:{"click":_vm.handleCancel}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-primary",class:{
            'disabled': _vm.isDisabled
          },attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.handleSave}},[(_vm.isLoadingButton)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['far', 'spinner-third']}}):_vm._e(),_vm._v("\n            "+_vm._s(_vm.isLoadingButton ? '' : 'Save')+"\n          ")],1)])]),_c('div',{staticClass:"align-items-center justify-content-center",class:{['d-flex'] : _vm.isLoadingPage, ['d-none'] : !_vm.isLoadingPage},staticStyle:{"margin":"200px 0px 100px 0px"}},[_c('LoadingSpinner',{attrs:{"size":"lg"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }