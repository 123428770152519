export default {
  inputIndividual: state => state.inputIndividual,
  individualId: state => state.individualId,
  selectedClass: state => state.selectedClass,
  individualSearchSuggestMap: state => state.individualSearchSuggestMap,
  isFetchingFilterGroups: state => state.filterGroups.isFetching,
  filterGroupConfigs: state => state.filterGroupConfigs,
  filterGroups: state => state.filterGroups.data,
  currentSearch: state => state.currentSearch,
  isLoading: state => state.isLoading,
  advancedSearchModalOpen: state => state.advancedSearchModalOpen,
  selectedFilterGroup: state => state.selectedFilterGroup,
  isShowOverview: state => state.isShowOverview,
};
