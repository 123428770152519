import store from '../../store';

const createPlatformResponseInterceptor = (httpConfig) => {
  const responseInterceptor = httpConfig.interceptors.response.use(
    response => response,
    (error) => {
      // Unauthorized
      if (error.response.status === 401
        && error.response.headers['www-authenticate']
        && ![
          '/uaa/oauth/token',
          '/uaa/oauth/revoke',
        ].includes(error.response.config.url)) {
        // Refresh token
        // This part is depends on application architecture,
        // application should set both access_token and refresh_token to web storage
        // after refresh token
        httpConfig.interceptors.response.eject(responseInterceptor);
        return store.dispatch('authService/refreshToken')

          // Retry previous request if refresh token successfully
          .then(() => httpConfig(error.response.config))
          .finally(createPlatformResponseInterceptor(httpConfig));
      }
      return Promise.reject(error);
    },
  );
};

export default createPlatformResponseInterceptor;
