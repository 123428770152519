export default {
  searchInput: state => state.searchInput,
  currentSearchResults: state => state.currentSearchResults,
  currentSearchResults__documents: state => (state.currentSearchResults && state.currentSearchResults.resultItems ? state.currentSearchResults.resultItems : []),
  isSearchSuccess: state => state.isSearchSuccess,
  isSearching: state => state.isSearching,
  currentQuery: state => state.currentQuery,
  currentPage: state => state.currentPage,
  pageCount: state => state.pageCount,
  itemsPerPage: state => state.itemsPerPage,
  allSourceDocCount: state => state.categories.total || 0,
  searchCategories: (state) => {
    const isDefaultValue = Array.isArray(state.categories) && !state.categories.length;
    if (isDefaultValue) return [];
    if (!state.categories) return [];
    if (!state.categories.aggregations) return [];
    if (!state.categories.aggregations.length) return [];

    const hasAggregations = !!state.categories.aggregations[0].bucket;
    if (!hasAggregations) return [];

    return [
      ...state.categories.aggregations[0].bucket.map(item => ({
        ...item,
        label: `${item.key} (${item.docCount})`,
      })),
    ];
  },
  corrections: state => state.corrections,
  suggestions: state => state.suggestions,
  naturalLanguageAnswer: state => state.naturalLanguageAnswer,
  savedSearches: state => state.savedSearches,
  selectedSource: state => state.selectedSource,
  appliedSort: state => state.appliedSort,
  filterGroups: state => state.filterGroups.data,
  filterItems: (state) => {
    const items = state.filterGroups.data
      .map(group => (
        group.items
          ? group.items.map(item => ({
            groupName: group.name,
            ...item,
          })) : []
      ))
      .reduce((acc, item) => acc.concat(item), []);

    return items;
  },
  isFetchingFilterGroups: state => state.filterGroups.isFetching,
  filterGroupConfigs: state => state.filterGroupConfigs,
  currentSearchRequestParameter: state => state.currentSearchRequestParameter,
  searchWithin: state => state.searchWithin,
  searchResultId: state => state.currentSearchResults.id,
  from: state => state.currentSearchParameter.from,
  size: state => state.currentSearchParameter.size,
  searchMode: state => state.currentSearchParameter.searchMode,
  source: state => state.currentSearchParameter.source,
  sortBy: state => state.currentSearchParameter.sortBy,
  topicsMust: state => state.currentSearchParameter.topicsMust,
  getDocumentPosition: (state, getters) => (document) => {
    const documents = (state.currentSearchResults ? state.currentSearchResults.documents : []);
    const index = _.findIndex(documents, document);
    return getters.itemsPerPage * (getters.currentPage - 1) + index + 1;
  },
  icrsPlotSearching: state => state.icrsPlotSearching,
  icrsPlotData: state => state.icrsPlotData,
  icrsPlotSuccess: state => state.icrsPlotSuccess,
  icrsSearchRequestParameter: state => state.icrsSearchRequestParameter,
  previousQuery: state => state.previousQuery,
  suggestedQueryText: state => state.suggestedQueryText,
  spellCorrectedQueries: state => state.spellCorrectedQueries,
  keepOriginSearchText: state => state.keepOriginSearchText,
  isSavedSearchFromHomePage: state => state.isSavedSearchFromHomePage,
  isMobileOveralOpen: state => state.isMobileOveralOpen,
};
