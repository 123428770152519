
const connectorRoutes = {
  name: 'connector',
  path: '/connector',
  component: () => import('./ConnectorPage.vue'),
  redirect: '/connector/dashboard',
  children: [
    {
      path: '/connector/dashboard',
      name: 'Connector Dashboard',
      component: () => import('./components/ConnectorDashboard.vue'),
    },
    {
      path: '/connector/dashboard/:connectorId/:tabIndex?',
      name: 'Connector Details',
      component: () => import('./components/ConnectorDetails.vue'),
      props: true,
      meta: { hide: true },
    },
    {
      path: '/connector/wizard',
      name: 'Connector Wizard',
      component: () => import('./components/ConnectorWizard.vue'),
    },
  ],
};

export default connectorRoutes;
