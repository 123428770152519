import DOMPurify from 'dompurify';

const camelizeRE = /-(\w)/g;

// eslint-disable-next-line import/prefer-default-export
export const camelize = str => str.replace(camelizeRE, (_, c) => (c ? c.toUpperCase() : ''));

export function isIE () {
  // eslint-disable-next-line spaced-comment
  return /*@cc_on!@*/false || !!document.documentMode;
}

export const scrollView = (elm, block = undefined) => elm.scrollIntoView({
  behavior: 'auto',
  block: block || 'start',
  inline: 'nearest',
});

export function toPascal (string) {
  return `${string.charAt(0).toUpperCase()}${camelize(string.slice(1))}`;
}

export function htmlEncode (str) {
  const htmlEntitiesMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;',
  };

  return String(DOMPurify.sanitize(str)).replace(/<strong>|<\/strong>|<em>|<\/em>|[&<>"'`=\/]/g, (s) => {
    if (s === '<strong>' || s === '</strong>' || s === '<em>' || s === '</em>') {
      return s; // Skip encoding for <strong>, <em> tags
      // <strong> for suggestion, <em> for result subtitle
    }
    return htmlEntitiesMap[s]; // Encode other special characters
  });
}

export function highlightQuery (text, input) {
  let regex = '';
  try {
    regex = new RegExp(input, 'ig');
  } catch (err) {
    return text;
  }
  return htmlEncode(text.replace(regex, `<strong>${input}</strong>`));
}
