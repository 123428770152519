import { getRelevantFilterGroups } from '@/helpers/search-filters';
import AdvancedSearchService from '@/services/advanced-search.service';
import { buildAutocomplete } from '@/modules/ontology-search/ontology-search-helpers';
import router from '@/router';
import SearchService from '../../../services/search.service';
import SearchRequestParameter from '../../../services/models/search/search-request-parameter.model';
import SearchParameter from '../../../services/models/search/search-parameter.model';
import searchConstants from '../../../constants/search-service-constants';

export default {
  updateInputIndividual ({ commit }, { inputIndividual }) {
    commit('setInputIndividual', inputIndividual);
  },
  updateCurrentSearch ({ commit }, { ontology, filter }) {
    const newCurrentSearch = {
      ontologySearch: ontology,
      filterBasedSearch: filter,
    };
    commit('setCurrentSearch', newCurrentSearch);
  },
  updateIndividualSearchSuggestMap ({ commit }, { autocompleteSuggest }) {
    const currentIndividualSearchSuggestMap = {};
    autocompleteSuggest.forEach((element) => {
      currentIndividualSearchSuggestMap[element.suggest] = element.id;
    });
    commit('setIndividualSearchSuggestMap', currentIndividualSearchSuggestMap);
  },
  resetFilterBadges ({ commit }) {
    commit('setFilterGroups', []);
  },
  updateIsLoading ({ commit }, isLoading) {
    commit('setIsLoading', isLoading);
  },
  updateIsFetchingFilterBadges ({ commit }, isFetching) {
    commit('setFilterGroupFetchingStatus', isFetching);
  },
  resetAdvancedSearch ({ commit }) {
    commit('advancedSearch/ontologySearch/resetState', [], { root: true });
    commit('resetState');
  },
  handleClickEventWithIndividualId ({
    state, rootState, commit, dispatch,
  }, indivId) {
    commit('setIndividualId', indivId);
    const { inputIndividual } = state;
    const { individualId } = state;
    const searchParameter = {
      individualId,
      individualLabel: inputIndividual,
      idQuery: rootState.authService.user.id,
    };
    AdvancedSearchService.postIndividualQueryMetrics(searchParameter);
    dispatch('ontologySearch/updateOntologySearchHistory', { inputIndividual, individualId });
  },
  handleSearchEvent ({ dispatch }, { inputIndividual }) {
    dispatch('resetAdvancedSearch');
    if (router.currentRoute.path !== '/faceted-search') {
      router.push('/faceted-search');
    }
    dispatch('searchService/triggerSearchQuery', { query: inputIndividual }, { root: true });
  },
  getFilterBadgeConfigs ({ commit }) {
    const aggregationConfigs = SearchService.getAggregationConfigs();
    commit('setFilterGroupConfigs', aggregationConfigs);
    return aggregationConfigs;
    // return SearchService.getAggregationConfigs();

    //   .then((data) => {
    //     commit('setFilterGroupConfigs', data);
    //   });
  },
  getFilterBadges ({ commit, state }, { currentSearchInput }) {
    // eslint-disable-next-line
    const dateObject = {
      start: null,
      end: null,
      field: null,
      uiTitle: null,
    };
    const searchParameterFields = new SearchParameter(
      searchConstants.SEARCH_MODE.FILTER,
      {},
      dateObject,
      1,
      {},
      undefined,
      null,
      5,
      null,
      null,
    );
    const param = new SearchRequestParameter(currentSearchInput, searchParameterFields);

    return SearchService.getAggregations({
      ...param,
      searchMode: 'AGGREGATION',
    })
      .then((result) => {
        const data = getRelevantFilterGroups(result.aggregations, state.filterGroupConfigs);
        if (data) commit('setFilterGroupFetchingStatus', true);
        commit('setFilterGroups', data);
      })
      .finally(() => {
        commit('setFilterGroupFetchingStatus', false);
      });
  },
  setSelectedFilterGroup ({ commit }, selectedFilterGroup) {
    commit('setSelectedFilterGroup', selectedFilterGroup);
  },
  getTrendingIndividuals (ctx, { lookbackDays, size }) {
    return AdvancedSearchService.getTrendingIndividuals({ lookbackDays, size });
  },
  getAdvancedSearchAutocomplete ({ dispatch }, { value, isFilterBasedSearchDisabled }) {
    dispatch('updateIsLoading', true);
    const individualSearchSuggestList = dispatch('getAutocompleteSuggestions', { value });
    dispatch('getAllFilterGroups', { value, isFilterBasedSearchDisabled });
    dispatch('updateIsLoading', false);
    return individualSearchSuggestList;
  },
  getAllFilterGroups ({ dispatch }, { value, isFilterBasedSearchDisabled }) {
    if (!isFilterBasedSearchDisabled) {
      dispatch('getFilterBadges', { currentSearchInput: value });
    }
  },
  async getAutocompleteSuggestions ({ dispatch }, { value }) {
    const response = await dispatch('searchService/execGetAutocomplete', { textToComplete: value }, { root: true });
    const { autocompleteSuggest } = response;
    dispatch('updateIndividualSearchSuggestMap', { autocompleteSuggest });
    const slicedList = autocompleteSuggest ? autocompleteSuggest.slice(0, 10) : null;
    return slicedList ? buildAutocomplete(slicedList) : [];
  },
  getEmptyAutocompleteSuggestions ({ dispatch }) {
    dispatch('updateIsFetchingFilterBadges', false);
    dispatch('updateIsLoading', false);
    return [];
  },
  setSearchShowOverview ({ commit }, isShowOverview) {
    commit('setSearchShowOverview', isShowOverview);
  },
};
