<template>
  <div>
    <b-button
      class="toggle-button"
      v-if="showButton"
      @click="toggleIsLoading"
    >Click to toggle</b-button>
    <div
      class="overlay is-full-page"
      :class="isLoading ? 'is-active' : ''"
    >
      <b-spinner
        class="icon"
        variant="primary"
        type="grow"
        label="Spinning"
      ></b-spinner>
      <div class="background"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    showButton: {
      type: Boolean,
      default: false,
    },
    stubComponent: {
      // acts as controller for the widget already declared globally
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'loadingPage/isLoading',
    }),
  },
  methods: {
    ...mapActions('loadingPage', ['execStartLoading', 'execStopLoading']),
    toggleIsLoading () {
      if (this.isLoading && !this.stubComponent) {
        this.execStopLoading();
      } else {
        this.execStartLoading();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
}
.overlay.is-full-page {
  z-index: 1090;
  position: fixed;
}
.overlay .background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: $white-500;
  opacity: 0.5;
}
.overlay.is-active {
  display: flex;
}
.overlay .icon {
  position: relative;
  z-index: 1000;
}
.toggle-button {
  z-index: 1000;
}
</style>
