<template>
  <div class="save-search">
    <CustomDropdownButton
      id="save-search-dropdown"
      :position="position"
      :text="headerText"
      :use-footer="isListShowing"
      @hide="handleDropdownHide"
      @show="handleDropdownShow"
      :hasAlert="hasUnreadNewResults"
    >
      <template v-slot:header>
        <div class="d-flex justify-content-between">
          <span>{{ headerText }}</span>
          <span
            class="saved-message"
            v-if="isSaved"
          >Search saved</span>
          <span
            class="deleted-message"
            v-if="isDeleted"
          >Search deleted</span>
          <span
            class="copied-message"
            v-if="isCopied"
          >
            Link copied to clipboard
          </span>
        </div>
      </template>
      <div class="saved-search-items">
        <template v-if="mode === modes.LIST">
          <div
            class="saved-search-items__placeholder"
            v-if="!savedSearches.length"
          >
            <div class="saved-search-items__placeholder-content">
              <img
                src="../../assets/empty.png"
                alt=""
              >
              <p class="mb-0 mt-lg">You haven't saved any search yet</p>
            </div>
          </div>

          <template v-if="savedSearches.length">
            <div class="saved-search-items__list">
              <div
                v-for="item in savedSearches"
                :key="item.id"
                class="saved-search-item"
                :class="{
                  'saved-search-item--hovered': isItemHovered(item)
                }"
                @click="handleSavedSearchClick(item)"
                @mouseover="handleHoverItem(item)"
              >
                <div class="details">
                  <div class="d-flex">
                    <p class="saved-search-item__name">{{ item.name }}</p>
                    <Badge
                      variant="primary"
                      v-if="item.newResults > 0"
                      pill
                    >+{{ item.newResults }}</Badge>
                  </div>
                  <p class="saved-search-item__options">
                    {{ getAppliedFiltersCountText(item) }}
                  </p>
                </div>

                <div class="actions">
                  <div
                    :id="`${item.id}-edit-wrapper`"
                    class="d-inline-block"
                  >
                    <button @click.stop="handleEdit(item)">
                      <font-awesome-icon
                        class="icon"
                        :icon="['fal', 'pen']"
                        size="sm"
                      />
                    </button>
                    <Tooltip
                      :container="`${item.id}-edit-wrapper`"
                      :target="`${item.id}-edit-wrapper`"
                    >
                      Edit
                    </Tooltip>
                  </div>
                  <div
                    :id="`${item.id}-delete-wrapper`"
                    class="d-inline-block"
                  >
                    <button @click.stop="handleDelete(item)">
                      <font-awesome-icon
                        class="icon"
                        :icon="['fal', 'trash-alt']"
                        size="sm"
                      />
                    </button>
                    <Tooltip
                      :container="`${item.id}-delete-wrapper`"
                      :target="`${item.id}-delete-wrapper`"
                    >
                      Delete
                    </Tooltip>
                  </div>
                  <div
                    :id="`share-wrapper-${item.id}`"
                    class="share-wrapper"
                  >
                    <button @click.stop="handleShare(item)">
                      <font-awesome-icon
                        class="icon"
                        :icon="['far', 'share-alt']"
                        size="sm"
                      />
                    </button>
                    <Tooltip
                      :container="`share-wrapper-${item.id}`"
                      :target="`share-wrapper-${item.id}`"
                    >
                      <span class="share-text">Get shareable link</span>

                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="saved-search-items__detail">
              <template v-if="hoveredItem">
                <div class="name">{{ hoveredItem.name }}</div>
                <div class="sort-by">Sorted by : {{ getSortByText(hoveredItem) }}</div>

                <template v-if="isPromotedContentFilterApplied">
                  <div class="filters-label">
                    Promoted content duration
                  </div>
                  <div class="mt-xxs">
                    <div class="badge-wrapper">
                      <SearchWithinBadge
                        :text="getPromotedContentLabel()"
                        :name="''"
                      />
                    </div>
                  </div>
                </template>

                <template v-if="getAppliedFiltersCount(hoveredItem)">
                  <div class="filters-label">
                    Applied Filters
                  </div>
                  <div class="mt-xxs">
                    <div
                      class="badge-wrapper w-100"
                      v-for="group in hoveredItemAppliedFilterGroups"
                      :key="`${group.name}-${hoveredItem.id}`"
                    >
                      <BadgeDropdown :text="`${group.name} (${group.items.length})`">
                        <div
                          class="filter-dropdown"
                          slot="dropdown"
                        >{{ getFilterText(group) }}</div>
                      </BadgeDropdown>
                    </div>
                    <div
                      class="badge-wrapper"
                      v-for="text in hoveredItemSearchWithin"
                      :key="text"
                    >
                      <SearchWithinBadge :text="text" />
                    </div>
                    <div
                      class="badge-wrapper"
                      v-for="date in hoveredItemRangeQuery.dateFilters"
                      :key="date.gte || date.lte"
                    >
                      <SearchWithinBadge
                        :text="date.gte || date.lte"
                        :name="date.gte ? 'Modified Date: from' : 'Modified Date: to'"
                      />
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </template>
        </template>

        <template v-if="mode === modes.EDIT">
          <ValidationObserver
            class="saved-search-form-wrapper"
            v-slot="{ invalid }"
          >
            <form
              class="saved-search-form"
              @submit.prevent="handleSaveEdit"
            >
              <div class="input-wrapper d-flex flex-column flex-align-start">
                <ValidationProvider
                  name="Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    ref="nameInput"
                    placeholder="Please enter name here"
                    type="text"
                    v-model="editedItem.name"
                    class="saved-search-form__input"
                    :class="{ 'saved-search-form__input--error': errors.length }"
                  >
                  <span class="saved-search-form__error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="ml-lg saved-search-form__actions">
                <Button
                  type="submit"
                  variant="primary"
                  :disabled="!isEditedTitleChanged || invalid || isSubmitting"
                >
                  Save
                </Button>
                <button
                  type="button"
                  class="secondary"
                  @click.stop="handleCancelEdit"
                >Cancel</button>
              </div>
            </form>
          </ValidationObserver>
        </template>

        <template v-if="mode === modes.CREATE">
          <ValidationObserver
            class="saved-search-form-wrapper"
            v-slot="{ invalid }"
          >
            <form
              class="saved-search-form"
              @submit.prevent="handleSaveSearch"
            >
              <div class="input-wrapper d-flex flex-column flex-align-start">
                <ValidationProvider
                  name="Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    ref="nameInput"
                    v-model="savedSearchName"
                    placeholder="Please enter name here"
                    type="text"
                    class="saved-search-form__input"
                    :class="{ 'saved-search-form__input--error': errors.length }"
                  >
                  <span class="saved-search-form__error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="ml-lg saved-search-form__actions">
                <Button
                  type="submit"
                  variant="primary"
                  :disabled="invalid || isSubmitting"
                >Save</Button>
                <button
                  type="button"
                  class="secondary"
                  @click.stop="handleCancelCreate"
                >Cancel</button>
              </div>
            </form>
          </ValidationObserver>
        </template>

        <template v-if="mode === modes.CONFIRM_REMOVAL">
          <form
            class="saved-search-form"
            @submit.prevent="handleDeleteSearch"
          >
            <div class="confirm-removal-text d-flex flex-column flex-align-start">
              Are you sure that you want to delete this saved search? This action cannot be undone.
            </div>
            <div class="ml-lg saved-search-form__actions">
              <Button
                type="submit"
                variant="primary"
                :disabled="isSubmitting"
              >Confirm</Button>
              <button
                type="button"
                class="secondary"
                @click.stop="handleCancelDelete"
              >Cancel</button>
            </div>
          </form>
        </template>
      </div>

      <template v-slot:footer>
        <div class="text-center">
          <Button
            type="button"
            :disabled="mode !== modes.LIST"
            @click.stop="handleCreate"
            variant="outline-primary"
          >
            Save current search
          </Button>
        </div>
      </template>
    </CustomDropdownButton>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import _groupBy from 'lodash/groupBy';
import _cloneDeep from 'lodash/cloneDeep';
import CustomDropdownButton from '@/components/custom-dropdown/CustomDropdownButton';
import SearchWithinBadge from '@/modules/search-within/SearchWithinBadge.vue';
import SearchConstants from '@/constants/search-service-constants';
import { formatDateRangeString } from '@/helpers/promoted-content-helpers';
import { createBadgeDropdownGroups } from '../../helpers/applied-filters';
import { formatSlashDateDayMonthYear, formatDateDayMonthYear } from '../../helpers/date-filters';

extend('required', {
  ...required,
  message: '{_field_} cannot be empty',
});

const SAVED_SEARCH_MODES = {
  LIST: 'list',
  EDIT: 'edit',
  CONFIRM_REMOVAL: 'confirm_removal',
  CREATE: 'create',
};

export default {
  components: {
    SearchWithinBadge,
    CustomDropdownButton,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    position: {
      type: String,
      default: 'left',
    },
  },

  data () {
    return {
      savedSearchName: '',
      initialEditedSaveSearchName: '',
      hoveredItem: null,
      editedItem: null,
      originalEditedItemName: '',
      hoveredItemAppliedFilterGroups: [],
      hoveredItemSearchWithin: [],
      hoveredItemRangeQuery: {
        dateFilters: null,
        promotedContentFilters: null,
      },
      beingRemovedItem: null,
      isSubmitting: false,
      isSaved: false,
      isDeleted: false,
      isCopied: false,
      mode: SAVED_SEARCH_MODES.LIST,
      dropdownBound: {},
      hasUnreadNewResults: false,
    };
  },

  computed: {
    ...mapGetters('searchService', [
      'savedSearches',
      'currentQuery',
      'filterGroupConfigs',
    ]),
    ...mapGetters('authService', {
      user: 'getUser',
    }),

    isListShowing () {
      return this.mode === SAVED_SEARCH_MODES.LIST;
    },

    headerText () {
      return `Saved search (${this.savedSearches.length})`;
    },

    modes () {
      return SAVED_SEARCH_MODES;
    },

    isEditedTitleChanged () {
      if (!this.editedItem) return false;
      return this.editedItem.name !== this.initialEditedSaveSearchName;
    },
    isPromotedContentFilterApplied () {
      let query = this.hoveredItem.request.topicsQuery.must;
      query = _groupBy(query, 'field');
      return query[SearchConstants.PROMOTED_CONTENT_FIELDS.FILTER] !== undefined;
    },
  },

  mounted () {
    this.getSavedSearches().then(() => {
      this.setHasUnreadNewResults();
    });
  },

  methods: {
    ...mapActions('searchService', [
      'getSavedSearches',
      'createSavedSearch',
      'updateSavedSearch',
      'removeSavedSearch',
      'searchForSavedSearch',
    ]),

    ...mapActions('searchService/newResults', [
      'searchForNewDocuments',
      'resetNewSearchResults',
    ]),

    getFilterText (group) {
      return group.items.map(item => item.value).join(', ');
    },

    getSortByText (item) {
      const { sortBy } = item.request;
      if (sortBy) {
        if (sortBy.field && sortBy.field === 'lastModifiedDate') {
          if (sortBy.order === 'desc') return 'Latest first';
          return 'Earliest first';
        }
      }

      return 'Relevance';
    },

    handleDropdownShow (rect) {
      const {
        top, right, bottom, left, width, height, x, y,
      } = rect;
      const bound = {
        top, right, bottom, left, width, height, x, y,
      };
      this.dropdownBound = bound;
      this.highlightFirstOption();
    },

    highlightFirstOption () {
      const firstSavedSearch = this.savedSearches[0];
      if (!firstSavedSearch) return;
      this.handleHoverItem(firstSavedSearch);
    },

    handleDropdownHide () {
      this.resetMessage();
      this.showList();
    },

    handleSavedSearchClick (savedSearch) {
      this.searchForSavedSearch(savedSearch.request);
      if (savedSearch.newResults > 0) {
        this.searchForNewDocuments(savedSearch);
      } else {
        this.resetNewSearchResults();
      }
      document.body.click();
    },

    getAppliedFiltersCount (savedSearch) {
      const { request } = savedSearch;
      const searchWithinCount = Array.isArray(request.searchWithin) ? request.searchWithin.length : 0;
      const savedSearchCount = this.getTopicQueryCount(request.topicsQuery.must);

      const savedRangeQuery = this.getRangeQueryCount(request.rangeQuery);
      return searchWithinCount + savedSearchCount + savedRangeQuery;
    },

    getRangeQueryCount (rangeQuery) {
      const filteredRangeQuery = rangeQuery
        .filter(query => !(query.field === SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_FROM
          || query.field === SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_TO));

      return filteredRangeQuery.length;
    },

    getTopicQueryCount (topicsQuery) {
      const filteredTopicsQuery = topicsQuery
        .filter(query => query.field !== SearchConstants.PROMOTED_CONTENT_FIELDS.FILTER);

      return filteredTopicsQuery.length;
    },

    getPromotedContentText (text, savedSearch) {
      let formattedText = text;
      const { request } = savedSearch;

      const containPromotedContent = request.topicsQuery.must
        .find(query => query.field === SearchConstants.PROMOTED_CONTENT_FIELDS.FILTER);

      if (containPromotedContent !== undefined) {
        if (formattedText !== ' ') {
          formattedText += ' | ';
        }
        formattedText += 'Promoted content history applied';
      }

      return formattedText;
    },

    getAppliedFiltersCountText (savedSearch) {
      const count = this.getAppliedFiltersCount(savedSearch);
      let text = ' ';
      if (count) {
        text = `${count} applied filter`;
        if (count > 1) text += 's';
      }

      text = this.getPromotedContentText(text, savedSearch);
      return text;
    },

    getPromotedContentLabel () {
      if (this.hoveredItemRangeQuery.promotedContentFilters.length > 0) {
        const promotedContentFiltersCopy = _cloneDeep(this.hoveredItemRangeQuery.promotedContentFilters);
        const promotedContentFiltersGroup = _groupBy(promotedContentFiltersCopy, 'field');

        /**
         * promote period >= start date (metadata.promote_to) and <= end date (metadata.promote_from)
         */
        const promotedStartDateObj = promotedContentFiltersGroup[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_TO][0];
        const promotedEndDateObj = promotedContentFiltersGroup[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_FROM][0];

        return formatDateRangeString(promotedStartDateObj.gte, promotedEndDateObj.lte);
      }

      return SearchConstants.PROMOTED_CONTENT_FIELDS.ALL_PROMOTED_CONTENT;
    },

    handleCreate () {
      this.savedSearchName = this.currentQuery;
      this.resetMessage();
      this.mode = SAVED_SEARCH_MODES.CREATE;
      this.$nextTick(() => {
        this.$refs.nameInput.select();
      });
    },

    handleCancelCreate () {
      this.savedSearchName = '';
      this.showList();
    },

    handleSaveSearch () {
      this.isSubmitting = true;
      this.resetMessage();
      this.createSavedSearch(this.savedSearchName.trim())
        .then(() => {
          this.showList();
          this.savedSearchName = '';
          this.isSaved = true;
          this.resetMessageDelayed();
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    resetMessageDelayed () {
      setTimeout(() => {
        this.resetMessage();
      }, 3000);
    },

    resetMessage () {
      this.isDeleted = false;
      this.isSaved = false;
      this.isCopied = false;
    },

    handleEdit (item) {
      this.resetMessage();
      this.editedItem = { ...item };
      this.mode = SAVED_SEARCH_MODES.EDIT;
      this.initialEditedSaveSearchName = item.name;
      this.$nextTick(() => {
        this.$refs.nameInput.select();
        this.$refs.nameInput.focus();
      });
    },

    handleSaveEdit () {
      this.isSubmitting = true;
      const editedItem = {
        ...this.editedItem,
        name: this.editedItem.name.trim(),
      };
      this.updateSavedSearch(editedItem)
        .then(() => {
          this.showList();
          this.editedItem = null;
          this.isSaved = true;
          this.resetMessageDelayed();
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    handleCancelDelete () {
      this.beingRemovedItem = null;
      this.showList();
    },

    handleDelete (item) {
      this.resetMessage();
      this.beingRemovedItem = item;
      this.mode = SAVED_SEARCH_MODES.CONFIRM_REMOVAL;
    },

    handleDeleteSearch () {
      this.isSubmitting = true;
      this.resetMessage();
      this.removeSavedSearch(this.beingRemovedItem.request.criteriaId)
        .then(() => {
          this.isDeleted = true;
          this.showList();
          this.resetMessageDelayed();
          this.setHasUnreadNewResults();
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    isItemHovered (item) {
      return this.hoveredItem && this.hoveredItem.id === item.id;
    },

    getSearchWithinText (searchWithin) {
      return searchWithin.value;
    },

    handleHoverItem (item) {
      this.hoveredItem = item;
      this.hoveredItemAppliedFilterGroups = createBadgeDropdownGroups(
        item.request.topicsQuery,
        this.filterGroupConfigs,
      );
      // handle range query
      this.hoveredItemRangeQuery = this.buildRangeQuery(item.request.rangeQuery);
      const hasSearchWithin = Boolean(item.request.searchWithin);
      this.hoveredItemSearchWithin = hasSearchWithin
        ? item.request.searchWithin.map(this.getSearchWithinText)
        : [];
    },

    handleCancelEdit () {
      this.editedItem.name = this.initialEditedSaveSearchName;
      this.initialEditedSaveSearchName = '';
      this.editedItem = null;
      this.showList();
    },

    handleShare (item) {
      this.resetMessage();
      const criteriaId = item.id;
      const baseUrl = this.getShareUrl(criteriaId);
      this.copyShareUrl(baseUrl);
    },
    getShareUrl (criteriaId) {
      let currUrl = window.location.href.split('?')[0];
      const hasTrailingSpace = currUrl[currUrl.length - 1] === '/';
      if (hasTrailingSpace) {
        currUrl = currUrl.slice(0, currUrl.length - 1);
      }
      /** if the current URL already contains a criteria id
         * (e.g. from a previously triggered saved search url from an email),
         * that criteriaId will be sliced off to get the baseUrl * */
      const criteriaStringIndex = currUrl.indexOf('?criteria=');
      const baseUrl = criteriaStringIndex === -1 ? currUrl : currUrl.slice(0, criteriaStringIndex);
      return `${baseUrl}?criteria=${criteriaId}`;
    },

    copyShareUrl (url) {
      this.$clipboard(url);
      this.isCopied = true;
      this.resetMessageDelayed();
    },

    showList () {
      this.mode = SAVED_SEARCH_MODES.LIST;
    },

    setHasUnreadNewResults () { this.hasUnreadNewResults = !!this.savedSearches.find(s => (s.newResults > 0)); },

    /**
     * @typedef {import('@typings/search').RangeQuery} RangeQuery
     * @param {RangeQuery[]} rangeQuery
     */
    buildRangeQuery (rangeQuery) {
      const rangeQueryCopy = _cloneDeep(rangeQuery);

      const formatedRangeQuery = {
        dateFilters: null,
        promotedContentFilters: null,
      };

      let dateFilters = [];

      const rangeQueries = _groupBy(rangeQueryCopy, 'field');

      if (rangeQueries.lastModifiedDate) {
        dateFilters = rangeQueries.lastModifiedDate.map(
          /**
           * @param {RangeQuery} element
           */
          (element) => {
            const date = element.gte ? 'gte' : 'lte';
            if (element[date]) {
              return {
                ...element,
                [date]: element[date].includes('/')
                  ? formatSlashDateDayMonthYear(element[date]) // todo can remove when backend is fixed
                  : formatDateDayMonthYear(element[date]),
              };
            }
            return { ...element };
          },
        );
      }

      formatedRangeQuery.dateFilters = dateFilters;

      const promotedContentFilters = [];

      /**
       * promote period >= start date (metadata.promote_to) and <= end date (metadata.promote_from)
       */
      if (rangeQueries[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_TO]
        && rangeQueries[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_FROM]) {
        /**
        * @type {RangeQuery}
        */
        const promoteStartDateObj = rangeQueries[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_TO].reduce(item => item);
        /**
         * @type {RangeQuery}
         */
        const promoteEndDateObj = rangeQueries[SearchConstants.PROMOTED_CONTENT_FIELDS.DATE_FROM].reduce(item => item);

        if (promoteStartDateObj) {
          promoteStartDateObj.gte = promoteStartDateObj.gte.includes('/')
            // todo: refactor when https://taiger.atlassian.net/browse/IS-2556 is fixed
            ? formatSlashDateDayMonthYear(promoteStartDateObj.gte)
            : formatDateDayMonthYear(promoteStartDateObj.gte);
          promotedContentFilters.push(promoteStartDateObj);
        }

        if (promoteEndDateObj) {
          promoteEndDateObj.lte = promoteEndDateObj.lte.includes('/')
            // todo: refactor when https://taiger.atlassian.net/browse/IS-2556 is fixed
            ? formatSlashDateDayMonthYear(promoteEndDateObj.lte)
            : formatDateDayMonthYear(promoteEndDateObj.lte);
          promotedContentFilters.push(promoteEndDateObj);
        }
      }

      formatedRangeQuery.promotedContentFilters = promotedContentFilters;

      return formatedRangeQuery;
    },

  },
};
</script>

<style lang="scss" scoped>
.save-search {
  display: inline-block;
}

.saved-search-items {
  max-height: 296px;
  display: flex;
  min-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;

  button:disabled {
    cursor: not-allowed;
  }

  &__placeholder {
    padding: $gap-xs 0;
    width: 100%;
    text-align: center;
    padding-top: 28px;
    padding-bottom: 74px;

    &-content {
      display: inline-block;
    }
  }

  &__list {
    width: 328px;
    min-height: 0;
    height: 296px;
    overflow-y: scroll;
    border-right: 1px solid $blue-gray-100;
    padding: $gap-xs 0;
    white-space: nowrap;
    padding-right: 10px;
  }

  &__detail {
    flex: 1 1 0%;
    padding: 16px 14px;
    overflow-y: auto;

    .name {
      white-space: break-spaces;
      font-size: 14px;
      color: $blue-gray-600;
      line-height: 20px;
    }

    .sort-by {
      color: $blue-gray-500;
      font-size: 12px;
      line-height: 16px;
      margin-top: 8px;
    }

    .filters-label {
      margin-top: 16px;
      font-weight: $weight-semi-bold;
      font-size: 12px;
      line-height: 16px;
      color: $blue-gray-600;
    }
  }
}

.saved-search-item {
  padding: $gap-xs;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  .details {
    width: 200px;
  }
  .actions {
    display: none;
    top: 0;
    bottom: 0;
    right: 0;
    width: 124px;
    background-color: $blue-200;
    align-items: center;
    justify-content: space-around;

    button {
      background: transparent;
      border: 0;
      color: $blue-gray-400;
      font-weight: $weight-bold;

      &:hover {
        color: $blue-400;
      }
    }
  }

  &--hovered {
    background-color: $blue-200;

    .actions {
      display: flex;
    }
  }

  &__name {
    color: $blue-gray-600;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: $font-md;
    margin-right: $gap-xxs;

    &/deep/ .badge {
      padding: 4px 8px;
      line-height: 12px;
    }
  }

  &__options {
    color: $blue-gray-400;
    margin: 0;
    margin-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-sm;
  }
}

.share-wrapper {
  position: relative;
  .share-text {
    white-space: nowrap;
  }
}

.saved-message,
.copied-message {
  color: $green-500;
  font-weight: $weight-normal;
  font-size: $font-md;
}

.deleted-message {
  color: $red-500;
  font-weight: $weight-normal;
  font-size: $font-md;
}

.saved-search-form-wrapper {
  width: 100%;
}

.badge-wrapper {
  margin-bottom: 4px;
  margin-right: 4px;
  display: inline-block;
}

.filter-dropdown {
  padding: $gap-xs;
}

.saved-search-form {
  padding-left: 0;
  padding-right: 0;
  padding-top: 24px;
  padding-bottom: 16px;
  display: flex;
  align-items: flex-start;

  .input-wrapper {
    flex: 1 1 0%;
  }

  .confirm-removal-text {
    white-space: initial;
    line-height: 20px;
    width: 100%;
  }

  .confirm-removal-text + .saved-search-form__actions {
    min-width: 178px;
  }

  &/deep/ .button {
    height: 36px;
  }

  &__error {
    margin-top: 4px;
    line-height: 16px;
    font-size: 12px;
    color: $red-500;
  }

  &__actions {
    display: flex;
    height: 36px;
  }

  &__input {
    border-radius: $radius-4;
    height: 36px;
    font-size: $font-md;
    padding: $gap-xxs $gap-xs;
    border: 1px solid $blue-500;
    flex: 1 1 0%;
    width: 100%;
    display: block;

    &--error {
      display: block;
      border-color: $red-500;
    }
  }

  button {
    padding: 8px 20px;
    border: 0;

    &.primary {
      color: $blue-500;

      &:disabled {
        color: $blue-300;
      }
    }

    &.secondary {
      background: transparent;
      color: $blue-gray-300;
      height: 36px;
    }
  }
}
</style>
