export default ({
  criteriaId = undefined,
  currentQuery = undefined,
  currentSearchMode = undefined,
  currentSearchParameter = undefined,
  currentSearchRequestParameter = undefined,
  currentPage = 1,
  itemsPerPage = 5,
  appliedSort = {},
  currentSearchResults = {
    total: 0,
  },
  selectedSource = undefined,
  categories = {},
  appliedSynonyms = [],
  isSearching = false,
  isSearchSuccess = false,
  searchWithin = [],
  currentDateObject,
  promoteDateObject = undefined,
} = {}) => ({
  criteriaId,
  currentQuery,
  currentSearchMode,
  currentSearchParameter,
  currentSearchRequestParameter,
  currentPage,
  itemsPerPage,
  appliedSort,
  currentSearchResults,
  selectedSource,
  categories,
  appliedSynonyms,
  isSearching,
  isSearchSuccess,
  searchWithin,
  currentDateObject,
  promoteDateObject,
});
