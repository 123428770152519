<template>
  <div id="advanced-search-bar">
    <div class='advanced-search-bar-container'>
      <div class="advanced-autosuggest-container">
        <font-awesome-icon
          class='searchIcon'
          :icon="['fas', 'search']"
        />
        <vue-autosuggest
          ref="advancedSearchAutosuggest"
          class="w-100"
          :suggestions="[{ data: individualSearchSuggestList }]"
          :input-props="{
            id: 'advanced_autosuggest__input',
            placeholder: config.searchInputPlaceholder,
            'data-cy': 'advanced-search-bar',
          }"
          :value="advancedSearchInput"
          :should-render-suggestions="shouldRenderSuggestions"
          @selected="onAutosuggestSelect"
          @input="handleIndividualSearchChange"
          @keydown.enter="onEnterKey"
          @keydown="onKeydown"
          componentAttrClassAutosuggestResults="advanced_autosuggest__results"
          componentAttrClassAutosuggestResultsContainer="advanced_autosuggest__results-container"
          data-cy="advanced-search-autosuggest"
        >
          <template
            v-if="advancedSearchInput.length > 0"
            slot="before-suggestions"
          >
            <div
              v-if="!this.config.isFilterBasedSearchDisabled && !isFetchingFilterGroups"
              @mouseup.stop
            >
              <div class="relevant-filters p-2 text-left">Relevant filters: </div>
              <!-- No Results State for Filter Badges -->
              <div
                v-if="!filterGroups.length"
                class="font-tertiary ml-2 text-left"
              >
                No relevant filters found.
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  v-for="(group, index) in filterGroups"
                  :key="group.name"
                  pill
                  size="sm"
                  @click="onClickBadge(group)"
                  class="filter-badge m-1 font-secondary border-0"
                  :data-cy="`filter-based-badge-${index}`"
                >
                  {{ group.displayName }}
                </b-button>
              </div>
              <hr class="m-2" />
            </div>
            <!-- No Results State for Autosuggest List  -->
            <div
              class="d-flex m-5 flex-column"
              v-if="individualSearchSuggestList.length === 0 && !isLoading"
            >
              <img
                class="w-50 align-self-center"
                src="@/assets/ontology-search-illustration.png"
              />
              <div class="text-center p-5 text-break">No results found for "{{ advancedSearchInput }}"</div>
            </div>
          </template>
          <template slot-scope="{suggestion}">
            <div
              v-if="suggestion.item.type"
              class="d-flex justify-content-between w-100"
            >
              <span v-dompurify-html="highlightQuery(suggestion.item.suggest, advancedSearchInput)"></span>
              <Badge
                pill
                class="float-right text-capitalize align-self-center"
                :style="{'background-color': suggestion.item.backgroundColor, 'color': suggestion.item.color}"
              >{{ suggestion.item.type }}</Badge>
            </div>
            <div
              v-else
              class="d-flex justify-content-between w-100"
            >
              <span v-dompurify-html="highlightQuery(suggestion.item.suggest, advancedSearchInput)"></span>
            </div>
          </template>
        </vue-autosuggest>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';
import { highlightQuery } from '@/helpers/helpers';

const MIN_LENGTH_FOR_AUTOCOMPLETE = 2;

export default {
  name: 'AdvancedSearchBar',
  components: {
    VueAutosuggest,
  },
  data () {
    return {
      individualSearchSuggestList: [], // autosuggest list that will be displayed
      advancedSearchInput: '',
      config: {
        searchInputPlaceholder: '',
        isFilterBasedSearchDisabled: false,
      },
    };
  },
  mounted () {
    // after DOM update throw this logic
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }

    this.updateIsLoading(true);
    if (!this.config.isFilterBasedSearchDisabled) {
      this.updateIsFetchingFilterBadges(true);
      this.getFilterBadgeConfigs();
    }
    if (this.inputIndividual) {
      this.advancedSearchInput = this.inputIndividual;
      this.getAutocomplete(this.advancedSearchInput);
    }
  },
  computed: {
    ...mapGetters('advancedSearch', [
      'inputIndividual',
      'individualId',
      'filterGroups',
      'isFetchingFilterGroups',
      'isLoading',
    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    advancedSearchInput: _.debounce(function (value) {
      return this.getAutocomplete(value);
    }, 300),
    inputIndividual (value) {
      this.advancedSearchInput = value;
    },
  },
  methods: {
    ...mapActions('advancedSearch', [
      'updateInputIndividual',
      'handleClickEventWithIndividualId',
      'getFilterBadgeConfigs',
      'resetFilterBadges',
      'updateIsLoading',
      'updateIsFetchingFilterBadges',
      'setSelectedFilterGroup',
      'updateCurrentSearch',
      'getAdvancedSearchAutocomplete',
      'getEmptyAutocompleteSuggestions',
      'resetAdvancedSearch',
    ]),
    // function called upon user input in the search bar
    handleIndividualSearchChange (value) {
      this.advancedSearchInput = value;
      if (!this.config.isFilterBasedSearchDisabled) {
        this.resetFilterBadges();
      }
    },
    async getAutocomplete (value) {
      if (value && value.length >= MIN_LENGTH_FOR_AUTOCOMPLETE) {
        this.getAdvancedSearchAutocomplete({
          value,
          isFilterBasedSearchDisabled: this.config.isFilterBasedSearchDisabled,
        }).then((res) => {
          this.individualSearchSuggestList = res;
        }).catch((error) => {
          this.getEmptyAutocomplete();
        });
      } else { // don't show autocomplete container
        this.updateIsLoading(true);
        this.updateIsFetchingFilterBadges(true);
        this.individualSearchSuggestList = [];
      }
    },
    getEmptyAutocomplete () { // show no results autocomplete container
      this.getEmptyAutocompleteSuggestions().then((res) => {
        this.individualSearchSuggestList = res;
      });
    },
    onAutosuggestSelect (event) {
      if (event) {
        const { item: { id, suggest } } = event;
        if (!id) {
          return;
        }
        this.onIndividualSelect({ id, suggest });
      }
    },
    onIndividualSelect ({ id, suggest }) {
      this.updateInputIndividual({ inputIndividual: suggest });
      this.onSearch(id);
    },
    // function called when user selects a badge and leads to filter-based search
    onClickBadge (item) {
      const currentSearchInput = item.displayName;
      this.$refs.advancedSearchAutosuggest.loading = true;
      this.updateInputIndividual({ inputIndividual: currentSearchInput });
      this.setSelectedFilterGroup(item);
      this.updateCurrentSearch({ ontology: false, filter: true });
      this.$emit('on-click-badge');
    },
    onSearch (individualId) {
      this.handleClickEventWithIndividualId(individualId);
      this.$refs.advancedSearchAutosuggest.internalValue = this.inputIndividual;
      this.updateCurrentSearch({ ontology: true, filter: false });
      this.$emit('on-search');
    },
    shouldRenderSuggestions (size, loading) {
      return !loading;
    },
    /**
     * Wrap matched substring with strong tag
     * @params {string} text
     * @return {string}
     */
    highlightQuery,
    onEnterKey (event) {
      this.$refs.advancedSearchAutosuggest.loading = false;
    },
    onKeydown (event) {
      const { keyCode } = event;
      if (keyCode === 38 || keyCode === 40) { // arrow up or arrow down
        const { suggest } = this.$refs.advancedSearchAutosuggest.internalValue;
        if (suggest) this.$refs.advancedSearchAutosuggest.internalValue = suggest;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.advanced-search-bar-container {
  display: flex;
  width: 40vw;
  margin: auto;
}
.advanced-autosuggest-container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
/deep/ .advanced_autosuggest__results {
  border: medium none;
  list-style: none outside none;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  font-size: 0.8rem;
  padding: 0;
  text-shadow: none;
  position: absolute;
  background: $white;
  z-index: 11;
  width: 100%;

  .btn {
    font-size: 14px;
    padding: 2px 8px;
  }
}
/deep/ .advanced_autosuggest__results-container {
  position: relative;
  ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 0;
  }
  li {
    padding: 0.75rem 1rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    width: 100%;
    display: inline-block;
    text-align: left;
    font-size: $font-md;
  }
  li:hover {
    cursor: pointer;
  }
}
/deep/ .autosuggest__results-item {
  padding: 1%;
}
/deep/ .autosuggest__results-item--highlighted {
  background: $blue-200;
  color: $blue-500;
}
/deep/ #advanced_autosuggest__input {
  width: 100%;
  height: 38px;
  padding-left: 3em;
  padding-right: 0.5em;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: solid $gray-300 1px;
  font-size: $font-md;
  border-radius: 0.25rem;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.searchIcon {
  color: $blue-500 !important;
  width: 17px;
  height: 17px;
  margin: 11px 8px 11px 16px;
  position: absolute;
  left: 0;
  border: 0;
}
.relevant-filters {
  color: $blue-500 !important;
}
.filter-badge {
  background: $blue-200;
}
</style>
