<template>
  <div id="ui-catalog-page">
    <Sidebar class="sideBar" :catalog-routes="catalogRoutes"/>
    <div class="content">
      <router-view/>
      <div class="bottomPlaceholder"></div>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar.vue';
import {
  mainCatalogRoutes,
  moduleRoutes,
  pageRoutes,
  componentRoutes,
} from './RouteSettings';

export default {
  name: 'UiCatalogPage',
  components: {
    Sidebar,
  },
  computed: {
    catalogRoutes () {
      return [
        ...mainCatalogRoutes,
        ...moduleRoutes,
        ...pageRoutes,
        ...componentRoutes,
      ];
    },
  },
};
</script>


<style scoped lang="scss">
#ui-catalog-page {
  height: 100%;
}
.sideBar {
  float: left;
  height: 100%;
  width: 300px;
}
.content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
/***
 To do task: padding-bottom for IE 11
***/
.bottomPlaceholder {
  height: 100px;
}
</style>
