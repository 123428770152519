import TokenService from '../storage.service';
import UserModel from '../models/auth/user.model';

const userobjectInterceptor = (response) => {
  const USER_OBJECT = 'user-object';

  const headerUserObject = response.headers[USER_OBJECT];

  if (headerUserObject) {
    TokenService.setUserModel(new UserModel(JSON.parse(headerUserObject)));
  }

  return response;
};


export default userobjectInterceptor;
