import Vue from 'vue';
import Vuex from 'vuex';

// eslint-disable-next-line
import modules from './modules';
import plugins from './plugins';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins,
  modules,
});
