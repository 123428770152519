import _uniqBy from 'lodash/uniqBy';
import constants from '@/constants/search-service-constants';
import { createFilterUniqueKey } from '../../../../../helpers/search-filters';

export default {
  /**
   * @param {object} state
   * @param {import('@models/search/filter-item.model').default[]} filters
   */
  setSelectedFilters (state, filters) {
    state.selectedFilters = filters;
  },

  /**
   * @param {*} state
   * @param {import('@models/search/filter-group.model').default} filterGroup
   */
  setModalSelectedFilterGroup (state, filterGroup) {
    state.selectedGroup = filterGroup;
  },

  setModalSelectedFilters (state, filters) {
    state.filterModal.selectedFilters = filters;
  },

  /**
   * @param {*} state
   * @param {import('@/services/models/search/filter-group.model').default} filterGroup
   */
  applyModalSelectedFilters (state, filterGroup) {
    const modalAppliedFilters = state.filterModal.selectedFilters;
    const modalAppliedFilterKeys = modalAppliedFilters.map(createFilterUniqueKey);

    // Remove un-checked filter
    const otherSelectedFiltersNotInGroup = state.selectedFilters
      .filter(item => item.groupName !== filterGroup.name);
    const selectedFiltersOfGroup = state.selectedFilters
      .filter((item) => {
        const key = createFilterUniqueKey(item);
        return modalAppliedFilterKeys.includes(key);
      });
    const nextSelectedFilters = _uniqBy(
      [
        ...otherSelectedFiltersNotInGroup,
        ...selectedFiltersOfGroup,
        ...modalAppliedFilters,
      ],
      item => createFilterUniqueKey(item),
    );

    state.selectedFilters = nextSelectedFilters;
  },

  /**
   * @param {*} state
   * @param {string} sortBy
   */
  setModalSearchKeyword (state, keyword) {
    state.filterModal.searchKeyword = keyword;
  },

  /**
   * @param {*} state
   * @param {import('@typings/search').SearchModalSorting} sortBy
   */
  setModalSortBy (state, sortBy) {
    const possibleValues = Object.values(constants.FILTER_SORT_BY_OPTIONS);
    const isValid = possibleValues
      .includes(sortBy);

    if (!isValid) {
      throw new Error(`Invalid sort type: ${sortBy}`);
    }

    state.filterModal.sortBy = sortBy;
  },

  /**
   * @param {*} state
   * @param {boolean} sortBy
   */
  setIsFirstOpenHasSelectedFilters (state, flag) {
    state.filterModal.isFirstOpenHasSelectedFilters = flag;
  },
  /**
   *
   * @param {*} state
   * @param {{should:[], must:[]}} topicsQuery
   */
  setModalTempTopicsQuery (state, topicsQuery) {
    state.filterModal.tempTopicsQuery = topicsQuery;
  },
  resetModalTempTopicsQuery (state) {
    state.filterModal.tempTopicsQuery = {
      should: [],
      must: [],
    };
  },
  setFilterGroupings (state, filterGroupings) {
    state.filterGroupings = filterGroupings;
  },
};
