<template>
  <div
    class="search-within"
    :class="{
      'search-within--no-result': !filteredSuggestions.length,
      'icrs-search-within': useICRSConfig
    }"
  >
    <div class="search-within__autosuggest">
      <vue-autosuggest
        ref="searchWithinAutoSuggest"
        class="autosuggest-input form-input"
        v-model="query"
        :limit="5"
        :suggestions="[{ data: filteredSuggestions }]"
        :input-props="inputProps"
        :should-render-suggestions="shouldRenderSuggestions"
        :get-suggestion-value="getSuggestionValue"
        @blur="handleInputBlur"
        @input="onInputChange"
        @selected="handleSelected"
        @keyup.enter="getSearchWithinResult"
      >
        <template slot-scope="{suggestion}">
          <span v-dompurify-html="highlightQuery(suggestion.item, query)"></span>
        </template>
      </vue-autosuggest>
      <b-button
        variant="search-within"
        :disabled="query === ''"
        @click="getSearchWithinResult"
        aria-label="Search Within"
      >
        <font-awesome-icon
          :icon="['fas', 'search']"
          size="sm"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapActions } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';
import { highlightQuery } from '@/helpers/helpers';

const SEARCH_LENGTH_TILL_AUTOCOMPLETE = 3;

export default {
  components: {
    VueAutosuggest,
  },

  data () {
    return {
      query: '',
      isSearching: false,
      filteredSuggestions: [],
      inputProps: {
        class: 'autosuggest__input',
        placeholder: 'Search Within Current Results',
      },
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
    };
  },

  props: {
    namespace: {
      type: String,
      default () {
        return 'searchService';
      },
    },
  },

  mounted () {
    this.listenToRemoveSearchWithin();
  },

  beforeDestroy () {
    this.unlistenToAddRemoveSearchWithin();
  },

  methods: {
    ...mapActions({
      addSearchWithin (dispatch, query) {
        return dispatch(`${this.namespace}/addSearchWithin`, query);
      },
      triggerSearchWithinQuery (dispatch) {
        return dispatch(`${this.namespace}/triggerSearchWithinQuery`);
      },
      buildAutocomplete: 'searchService/buildAutocomplete',
      execGetAutocomplete: 'searchService/execGetAutocomplete',
    }),
    /**
     * Wrap matched substring with strong tag
     */
    highlightQuery,
    listenToRemoveSearchWithin () {
      this.unlistenToAddRemoveSearchWithin = this.$store.subscribe((mutation) => {
        const listenedMutations = [
          `${this.namespace}/setIsSearchSuccess`,
          `${this.namespace}/addSearchWithin`,
          `${this.namespace}/removeSearchWithin`,
        ];
        if (listenedMutations.includes(mutation.type)) {
          this.query = '';
          this.filteredSuggestions = [];
        }
      });
    },
    handleInputBlur () {
      if (!this.query) {
        this.filteredSuggestions = [];
      }
    },
    getSuggestionValue (suggestion) {
      return suggestion.item;
    },
    shouldRenderSuggestions (size, loading) {
      return !loading;
    },
    handleSelected (event) {
      if (!event) return;
      this.query = event.item;
      this.$nextTick(() => {
        this.$refs.searchWithinAutoSuggest.$el.querySelector('input').focus();
      });
    },
    onInputChange (text) {
      this.query = text;
    },
    getAutocomplete (value) {
      if (value && value.length >= SEARCH_LENGTH_TILL_AUTOCOMPLETE) {
        this.execGetAutocomplete({ textToComplete: value })
          .then((resp) => {
            this.buildAutocomplete(resp).then((acResp) => {
              this.filteredSuggestions = acResp;
            });
          });
      } else {
        this.filteredSuggestions = [];
      }
    },
    getSearchWithinResult () {
      const query = `"${this.query.trim().replaceAll('"', '')}"`;
      if (!query) return;
      this.addSearchWithin(query);
      this.query = '';
      this.filteredSuggestions = [];

      this.isSearching = true;
      this.triggerSearchWithinQuery()
        .finally(() => {
          this.isSearching = false;
        });
    },
  },
  watch: {
    query: debounce(function debounceCallback (value) {
      if (this.isSearching) return;
      this.getAutocomplete(value);
    }, 200),
  },
};
</script>

<style lang="scss" scoped>
$input-width: 160px;
$search-icon-width: 13px;

.search-within {
  display: inline-flex;
  text-align: left;
  &__autosuggest {
    position: relative;
    word-wrap: break-word;
    .icon-wrapper {
      position: absolute;
      left: $input-width - $search-icon-width - $gap-xs;
      top: 6px;
      border: 0;
      padding: 0;
      background: transparent;
    }
  }
  &/deep/ {
    .autosuggest__input {
      outline: none;
      box-sizing: border-box;
      border: solid $gray-300 1px;
      padding: $gap-xs;
      padding-right: 24px;
      font-size: $font-md;
      border-radius: 0.25rem;
      transition: all 0.2s ease-out;
      height: 36px;
      width: $input-width;

      &:focus {
        border-color: $blue-500;
      }
    }

    .autosuggest__results {
      margin-top: 1px;
      position: absolute;
      width: 240px;
      background: $white;
      border: 1px solid $blue-gray-100;
      padding: $gap-xxs;
      border-radius: $radius-4;
      box-shadow: 2px 4px 8px rgba(153, 155, 168, 0.2);
      box-sizing: border-box;
      z-index: 10;

      ul {
        padding-left: 0;
        margin: 0;
        list-style: none;

        li {
          font-size: $font-md;
          padding: $gap-xxs;
          cursor: pointer;

          &:hover {
            background-color: $blue-200;
          }
        }
      }
    }
    .autosuggest__results-item--highlighted {
      background-color: $blue-200;
    }
  }

  &--no-result {
    &/deep/ {
      .autosuggest__results {
        display: none;
      }
    }
  }
}
</style>
