import { createTestAction } from '@/helpers/testing';
import actions from '../actions';

jest.mock('@/services/km.service', () => ({
  getRelations: jest.fn(),
}));

jest.mock('@/modules/ontology-search/ontology-search-helpers', () => ({
  createTable: jest.fn(),
  getClassFilter: jest.fn(),
  populateNodesColorClass: jest.fn(),
  populateEdgesColorLabel: jest.fn(),
  updateHistoryDropdown: jest.fn(),
}));

const testAction = createTestAction(expect);
const commit = jest.fn();
const dispatch = jest.fn();
const id = 'city_singapore';
const individual = 'singapore';
const concept = 'city';
const newId = 'country_singapore';
const newConcept = 'country';

describe('advanced-search/ontology-search vuex actions test', () => {
  describe('constructNetwork', () => {
    test('should receive relations', () => {
      const nodes = [{ id, label: individual }];
      const edges = [{
        id: 'cityOfCountry-country_singapore',
        title: 'cityOfCountry',
        from: id,
        to: newId,
      }];
      const state = { maxEdges: 20, nodes, edges };
      const ctx = { commit, state, dispatch };
      const response = { nodes, edges };
      dispatch.mockReturnValueOnce(Promise.resolve(response));

      testAction(actions.constructNetwork, { inputIndividual: individual, individualId: id }, ctx, [
        { type: 'advancedSearch/setInputIndividual', individual },
        { type: 'advancedSearch/setIndividualId', id },
        { type: 'setTable', payload: [] },
        { type: 'setClassFilters', payload: [concept] },
        { type: 'setDisplayedNodes', nodes },
        { type: 'setDisplayedEdges', edges },
        { type: 'setNodes', nodes },
        { type: 'setEdges', edges },
      ])
        .then(() => {
          expect(dispatch).toHaveBeenCalledWith('kmService/getRelations', id, { root: true });
          expect(dispatch).toHaveBeenLastCalledWith('updateRelations', edges);
          expect(dispatch).toHaveBeenCalledTimes(3);
        });
    });
  });

  test('handleSelectElementHistory', () => {
    const state = {
      history: [
        {
          id,
          individual,
          label: individual,
          concept,
        },
        {
          id: newId,
          individual,
          label: individual,
          concept: newConcept,
        },
      ],
    };
    const expectedHistory = [
      {
        id,
        individual,
        label: individual,
        concept,
      },
    ];
    const rootState = {
      advancedSearch: {
        inputIndividual: individual,
        individualId: id,
      },
    };
    const ctx = {
      commit, state, dispatch, rootState,
    };
    testAction(actions.handleSelectElementHistory, { index: 0 }, ctx, [
      { type: 'advancedSearch/setIndividualId', id },
      { type: 'advancedSearch/setInputIndividual', individual },
      { type: 'advancedSearch/setSelectedClass', concept },
      { type: 'setHistory', expectedHistory },
    ]);
    expect(dispatch).toHaveBeenLastCalledWith('constructNetwork', {
      inputIndividual: individual,
      individualId: id,
    });
  });

  test('handleNodeSelect', () => {
    const currentSelectedNode = {
      color: '#583604',
      font: '#583604',
      id: 'country_singapore',
      label: 'Singapore',
      type: '',
    };
    const state = {
      ontologyMap: {
        id: individual,
        newId: individual,
      },
      history: [
        {
          id,
          individual,
          label: individual,
          concept,
        },
      ],
      maxHistoryLength: 40,
      selectedNode: [],
      nodes: [currentSelectedNode],
    };
    const expectedHistory = [
      {
        id,
        individual,
        label: individual,
        concept,
      },
      {
        id: newId,
        individual,
        label: individual,
        concept: newConcept,
      },
    ];
    const rootState = {
      advancedSearch: {
        inputIndividual: individual,
        individualId: id,
      },
    };
    const ctx = {
      commit, state, dispatch, rootState,
    };
    const value = { nodes: [newId] };
    testAction(actions.handleNodeSelect, { value }, ctx, [
      { type: 'advancedSearch/setInputIndividual', individual },
      { type: 'advancedSearch/setIndividualId', newId },
      { type: 'setHistory', expectedHistory },
    ]);
  });

  test('updateRelationsMax', () => {
    actions.updateRelationsMax({ commit }, 10);
    expect(commit).toHaveBeenLastCalledWith('setRelationsMax', 10);
  });

  test('updateOntologyMap', () => {
    const nodes = [{ id, label: individual }];
    testAction(actions.updateOntologyMap, nodes, { commit }, [
      { type: 'setOntologyMap', payload: { id: individual } },
    ]);
  });

  test('updateSelectedClass', () => {
    const color = '#56075E';
    const currClass = {
      class: concept,
      color,
      backgroundColor: color,
      badgeLabel: concept,
    };
    const state = {
      nodes: [currClass],
    };
    const ctx = { commit, state };
    const showGraphNameInBadge = false;
    testAction(actions.updateSelectedClass, { showGraphNameInBadge }, ctx, [
      { type: 'advancedSearch/setSelectedClass', currClass },
    ]);
  });

  test('updateRelations', () => {
    const state = { maxEdges: 20 };
    const edges = [{
      id: 'cityOfCountry-country_singapore',
      title: 'cityOfCountry',
      from: id,
      to: newId,
    }];
    testAction(actions.updateRelations, edges, { commit, state }, [
      { type: 'setRelationsMax', payload: 1 },
      { type: 'setRelationsMin', payload: 1 },
    ]);
  });
  test('setDisplayedNodesAndEdgesCount', () => {
    const nodes = [{ id, label: individual }, { id, label: individual }];
    const edges = [{
      id: 'cityOfCountry-country_singapore',
      title: 'cityOfCountry',
      from: id,
      to: newId,
    }, {
      id: 'cityOfCountry-country_singapore',
      title: 'cityOfCountry',
      from: id,
      to: newId,
    }];
    const displayEdgeCount = 1;
    const state = { nodes, edges };
    testAction(actions.setDisplayedNodesAndEdgesCount, displayEdgeCount, { state, commit, dispatch }, [
      { type: 'setDisplayedNodes', payload: displayEdgeCount },
      { type: 'setDisplayedEdges', payload: displayEdgeCount },
    ]).then(() => {
      expect(dispatch).toHaveBeenLastCalledWith('updateRelationsMax', displayEdgeCount);
    });
  });
});
