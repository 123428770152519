<template>
  <b-card-body id="ontology_content" body-bg-variant="blue-gray-100_alpha">
    <b-card-title class="mb-2 darken">Knowledge Base</b-card-title>

    <b-list-group flush>
      <b-card-text>An Ontology or Knowledge Graph encapsulates the domain knowledge of Search.</b-card-text>
      <b-card-text id="iconTextGroup">
        <icon-text faType="fal" faName="mind-share" text="Ontology"></icon-text>
        <icon-text faType="fal" faName="chart-network" text="Knowledge Graph"></icon-text>
      </b-card-text>
      <b-card-text>
        View and manage Knowledge Graphs, Classes, Individuals and Relationships with
        <strong>Ontology Manager</strong>.
      </b-card-text>

      <redirect-button
        label="Ontology Manager"
        :pathLink="ontologyUrl"
        :variant="variant"
        type="newTab"
      ></redirect-button>
    </b-list-group>
    <dual-arrow-svg class="floating_arrow"/>
  </b-card-body>
</template>

<script>
import IconText from '@/components/IconText.vue';
import dualArrowSvg from '@/assets/admin-page/icons/circle-dual-arrow.svg';

import ConfigService from '@/services/config.service';
import redirectButton from './RedirectionButton.vue';

const ontologyUrl = ConfigService.getOntologyUrl();

export default {
  components: {
    redirectButton,
    dualArrowSvg,
    IconText,
  },
  props: {
    variant: String,
  },
  data () {
    return {
      ontologyUrl,
    };
  },
};
</script>

<style scoped lang="scss">
#ontology_content {
  position: relative;
  z-index: 10;
  width: calc(200% + 2.5em);
  margin-top: 1em;
}
#iconTextGroup {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

#iconTextGroup > * {
  margin-right: 1rem;
}

.floating_arrow {
  right: -3em !important
}
</style>
