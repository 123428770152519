<template>
  <div class="container container-fluid">
    <!-- Start of JDS Scroll to Top Widget -->
    <!-- <a
      v-if="showScrollToTop"
      id="scroll-to-top"
      title="Scroll to Top"
      class="show"
      @click="scrollTop"
    >
      <span class="fas fa-chevron-up"></span>
    </a> -->
    <!-- End of JDS Scroll to Top Widget -->
    <ScrollTop @handleCustom="scrollTop" />
    <div
      v-if="guidedDisplayOption === 'results'"
      class="icrs-search-bar-component"
    >
      <div class="card">
        <div class="card-body">
          <div
            class="row icrs-search-bar-results-section tablet-section"
            style="margin-bottom: 8px;"
          >
            <b-button
              class="control-button"
              block
              variant="discover-nature"
              v-b-modal="'guidedSearchModal'"
            >
              <font-awesome-icon
                :icon="['fal', 'eye']"
                size="lg"
              />&nbsp;&nbsp;Discover Cases By Nature Of Injury
            </b-button>
          </div>
          <SearchBar
            class="home-search-bar"
            :postMessageOnSearch="postMessageOnSearch"
            :icrsGuidedSearchQuery="icrsGuidedSearchQuery"
          />
          <div class="row icrs-search-bar-results-section tablet-section">
            <div v-if="!showSearchOptions">
              <b-button
                class="show-more-search-options-button"
                block
                variant="show-more-search-options"
                @click="showSearchOptions = !showSearchOptions"
              >
                Show More Search Options&nbsp;&nbsp;
                <font-awesome-icon
                  :icon="['far', 'chevron-down']"
                  size="lg"
                />
              </b-button>
            </div>
            <div v-if="showSearchOptions">
              <b-button
                class="show-more-search-options-button"
                block
                variant="hide-more-search-options"
                @click="showSearchOptions = !showSearchOptions"
              >
                Hide Search Options&nbsp;&nbsp;
                <font-awesome-icon
                  :icon="['far', 'chevron-up']"
                  size="lg"
                />
              </b-button>
            </div>
          </div>
          <div style="margin-top: 5px"></div>
          <div
            v-if="showSearchOptions"
            class="row icrs-search-bar-results-section"
          >
            <div class="col-lg-5 control-left">
              <b-button
                class="control-button desktop-only"
                block
                variant="discover-nature"
                v-b-modal="'guidedSearchModal'"
              >
                <font-awesome-icon
                  :icon="['fal', 'eye']"
                  size="lg"
                />&nbsp;&nbsp;Discover Cases By Nature Of Injury
              </b-button>
            </div>
            <div class="col-lg-2 control-center">
              <b-button
                class="control-button"
                block
                variant="search-suggestions"
                v-b-modal="'stickySuggestions'"
              >
                <span class="desktop-only">
                  <font-awesome-icon
                    :icon="['fal', 'lightbulb-on']"
                    size="lg"
                  />&nbsp;&nbsp;Suggestions
                </span>
                <span class="tablet-section">
                  <font-awesome-icon
                    :icon="['fal', 'lightbulb-on']"
                    size="lg"
                  />&nbsp;&nbsp;Suggestions
                </span>
              </b-button>
              <ICRSSearchSuggestions
                modalId='stickySuggestions'
                :bodyPartsResponse="bodyPartsResponse"
              />
            </div>
            <div class="col-lg-3 control-center">
              <b-button
                class="control-button"
                block
                variant="advanced-search"
                v-b-modal="'advancedSearchModal'"
              >
                <font-awesome-icon
                  :icon="['fal', 'tools']"
                  size="lg"
                />&nbsp;&nbsp;Advanced Search
              </b-button>
            </div>
            <div class="col-lg-2 control-right">
              <b-button
                class="control-button"
                block
                variant="view-saved-searches"
                v-b-modal="'savedSearchesModal'"
              >
                <font-awesome-icon
                  :icon="['fal', 'save']"
                  size="lg"
                />&nbsp;&nbsp;Saved Searches
              </b-button>

            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :id="'guidedSearchModal'"
      size="xl"
      :centered="true"
      :hide-footer="true"
      header-close-content="<i class='fa fa-times-circle' />"
      title="Discover Cases By Nature Of Injury"
      @hide="closeModal('guidedSearchModal')"
    >
      <ICRSGuidedSearchColumns
        :activeGuidedValue="activeGuidedValue"
        @updateGuidedDropdowns="updateGuidedDropdowns"
        :mobileMode="mobileMode"
        :is-modal="true"
      />
    </b-modal>
    <ICRSAdvancedSearch
      :mobileMode="mobileMode"
      @setIcrsAdvancedSearchQuery="setIcrsAdvancedSearchQuery"
    />
    <b-modal
      :id="'savedSearchesModal'"
      size="xl"
      :centered="true"
      :hide-footer="true"
      header-close-content="<i class='fa fa-times-circle' />"
      title="Saved Searches"
      @hide="closeSaveSearchModal"
    >
      <ICRSSaveSearch @saveCurrentMode="saveCurrentMode($event)" :icrsDisplayMode="currentSaveMode"/>
    </b-modal>
    <div v-if="guidedDisplayOption === 'landing' && mobileMode">
      <b-button
        :class="{
                'btn-other-search-inactive': !showLandingSearchBar,
                'btn-other-search-active': showLandingSearchBar,
              }"
        variant="other-search-mobile"
        @click="toggleLandingSearchBar"
      >
        Other Search Options&nbsp;&nbsp;
        <font-awesome-icon
          v-if="!showLandingSearchBar"
          :icon="['far', 'chevron-down']"
          size="lg"
        />
        <font-awesome-icon
          v-if="showLandingSearchBar"
          :icon="['far', 'chevron-up']"
          size="lg"
        />
      </b-button>
    </div>
    <b-collapse
      v-if="mobileMode"
      class="icrs-search-bar-component"
      :visible="showLandingSearchBar"
      :id="landingSearchBarMobileId"
    >
      <SearchBar
        class="home-search-bar"
        :postMessageOnSearch="postMessageOnSearch"
        :icrsGuidedSearchQuery="icrsGuidedSearchQuery"
      />
      <b-button
        class="control-button"
        style="margin-top: 0.5rem;"
        block
        variant="advanced-search-landing"
        v-b-modal="'advancedSearchModal'"
      >
        <font-awesome-icon
          :icon="['fal', 'tools']"
          size="lg"
        />&nbsp;&nbsp;Advanced Search
      </b-button>
      <b-button
        class="control-button"
        block
        variant="view-saved-searches-landing"
        v-b-modal="'savedSearchesModal'"
      >
        <font-awesome-icon
          :icon="['fal', 'save']"
          size="lg"
        />&nbsp;&nbsp;Saved Searches
      </b-button>
    </b-collapse>
    <div
      v-if="guidedDisplayOption === 'landing'"
      class="icrs-component icrs-landing-component"
    >
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-9 col-lg-8 col-md-7">
              <p class="discover-text guided-row">Discover Cases By Nature Of Injury</p>
            </div>
            <div
              v-if="!mobileMode"
              class="col-xl-3 col-lg-4 col-md-5"
            >
              <b-button
                :class="{
                'btn-other-search-inactive': !showLandingSearchBar,
                'btn-other-search-active': showLandingSearchBar,
              }"
                variant="other-search"
                @click="toggleLandingSearchBar"
              >
                Other Search Options&nbsp;&nbsp;
                <font-awesome-icon
                  v-if="!showLandingSearchBar"
                  :icon="['far', 'chevron-down']"
                  size="lg"
                />
                <font-awesome-icon
                  v-if="showLandingSearchBar"
                  :icon="['far', 'chevron-up']"
                  size="lg"
                />
              </b-button>
            </div>
          </div>
          <b-collapse
            v-if="!mobileMode"
            class="icrs-search-bar-component"
            :visible="showLandingSearchBar"
            :id="landingSearchBarId"
          >
            <div
              class="card"
              style="border-top-left-radius: 0; border-top: none; border-left: none;"
            >
              <div class="card-body">
                <SearchBar
                  class="home-search-bar"
                  :postMessageOnSearch="postMessageOnSearch"
                  :icrsGuidedSearchQuery="icrsGuidedSearchQuery"
                />
                <div style="margin-top: 5px"></div>
                <div class="row icrs-search-bar-results-section">
                  <div class="col-md-6">
                    <div class="control-left">
                      <b-button
                        class="control-button"
                        block
                        variant="advanced-search-landing"
                        v-b-modal="'advancedSearchModal'"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'tools']"
                          size="lg"
                        />&nbsp;&nbsp;Advanced Search
                      </b-button>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="control-right">
                      <b-button
                        class="control-button"
                        block
                        variant="view-saved-searches-landing"
                        v-b-modal="'savedSearchesModal'"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'save']"
                          size="lg"
                        />&nbsp;&nbsp;Saved Searches
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
          <div style="margin-top: 5px"></div>
          <ICRSGuidedSearchColumns
            :activeGuidedValue="activeGuidedValue"
            @updateGuidedDropdowns="updateGuidedDropdowns"
            :mobileMode="mobileMode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ICRSSaveSearch from '@/modules/icrs-save-search/ICRSSaveSearch.vue';
import ICRSSearchSuggestions from '@/modules/icrs-search-suggestions/ICRSSearchSuggestions.vue';
import SearchBar from '@/modules/search-bar/SearchBar.vue';
import ICRSGuidedSearchColumns from './components/ICRSGuidedSearchColumns.vue';
import ICRSAdvancedSearch from './components/ICRSAdvancedSearch.vue';
import ScrollTop from '@/components/scroll-top/ScrollTop.vue';

export default {
  name: 'ICRSSearchBarWrapper',
  props: {
    postMessageOnSearch: { // set true if search bar is used as individual entity
      type: Boolean,
      default: false,
    },
    guidedDisplayOption: {
      type: String,
      default: 'results',
    },
    showMoreSearchOptions: {
      type: Boolean,
      default: true,
    },
    // showScrollToTop: {
    //   type: Boolean,
    //   default: false,
    // },
    bodyPartsResponse: {
      type: Object,
      default: null,
    },
  },
  components: {
    SearchBar,
    ICRSGuidedSearchColumns,
    ICRSAdvancedSearch,
    ICRSSaveSearch,
    ICRSSearchSuggestions,
    ScrollTop
  },
  computed: {
    ...mapGetters('searchService', [
      'currentQuery',
    ]),
    ...mapGetters('icrs', [
      'dropdownGuidedTag',
      'activeGuidedPrefix',
      'selectedLevel1',
      'selectedLevel2',
      'selectedLevel3',
      'level2CategoriesForSelectedLevel1',
      'level3CategoriesForSelectedLevel2',
      'allGuidedCategories',
    ]),
    filterCategories () {
      if (this.activeGuidedPrefix === 0) {
        return [...this.guidedCategoriesFilter.level2].filter(item => item.parentCode === this.selectedLevel1.code);
      }
      if (this.activeGuidedPrefix === 1) {
        [...this.guidedCategoriesFilter.level3].filter(item => item.parentCode === this.selectedLevel2.code);
      }
      return [...this.guidedCategoriesFilter.level3].filter(item => item.parentCode === this.selectedLevel2.code);
    },
    guidedCategoriesFilter () {
      let temp = {...this.allGuidedCategories};
      if (temp) {
        temp.level1 = temp.level1.filter(injury => !injury.newAdded);
        temp.level2 = temp.level2.filter(injury => !injury.newAdded);
        temp.level3 = temp.level3.filter(injury => !injury.newAdded);
      }
      return temp;
    }
  },
  watch: {
    currentQuery: {
      immediate: true,
      handler (value) {
        if (value !== undefined && value !== null && value !== '' && !value.includes('tags.level')) {
          this.initialiseGuidedSearch();
          this.icrsGuidedSearchQuery = null;
        }
      },
    },
    showSearchOptions: {
      handler (value) {
        this.$emit('setOpenSearchOptions', value);
      },
    },
  },
  data () {
    return {
      guidedDisplayCategories: [],
      guidedDisplayDecks: [],
      guidedPrefixes: [
        'tags.level1code:',
        'tags.level2code:',
        'tags.level3code:',
      ],
      activeGuidedValue: '',
      guidedCategoriesForActive: {},
      icrsGuidedSearchQuery: '',
      boxesPerRow: 4,
      level1Categories: [],
      mobileMode: false,
      showSearchOptions: true,
      showLandingSearchBar: false,
      landingSearchBarId: 'landingSearchBar',
      landingSearchBarMobileId: 'landingSearchBarMobile',
      currentSaveMode: 'list',
    };
  },
  methods: {
    ...mapActions('searchService', [
      'setCurrentQuery',
    ]),
    ...mapActions('icrs', [
      'setDropdownGuidedTag',
      'setActiveGuidedPrefix',
      'setSelectedLevel1',
      'setSelectedLevel2',
      'setSelectedLevel3',
      'setLevel2CategoriesForSelectedLevel1',
      'setLevel3CategoriesForSelectedLevel2',
      'setAdvancedSearchSelectedLevel',
    ]),
    ...mapActions('advancedSearch', [
      'setSearchShowOverview',
    ]),
    toggleLandingSearchBar (landingSearchBarId) {
      this.showLandingSearchBar = !this.showLandingSearchBar;
      this.$root.$emit(`bv::collapse::${this.showLandingSearchBar}`, landingSearchBarId);
    },
    scrollTop () {
      if (this.mobileMode) {
        this.showSearchOptions = false;
      }
      // document.body.scrollTop = 0; // For Safari
      // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    saveCurrentMode (mode) {
      this.currentSaveMode = mode;
    },
    closeSaveSearchModal (bvModalEvent) {
      if (this.currentSaveMode !== 'list') {
        bvModalEvent.preventDefault();
        this.currentSaveMode = 'list';
      } else {
        this.closeModal('savedSearchesModal');
      }
    },

    closeModal (modalId) {
      this.$bvModal.hide(modalId);
      // this.$emit('hide');
    },
    getGuidedDisplayDecks () {
      const newGuidedDisplayDecks = [];
      for (let i = 0; i < this.guidedDisplayCategories.length; i += this.boxesPerRow) {
        newGuidedDisplayDecks.push(this.guidedDisplayCategories.slice(i, i + this.boxesPerRow));
      }
      this.guidedDisplayDecks = newGuidedDisplayDecks;
    },
    addedGuidedDisplayDefaultButtons () {
      if (this.guidedDisplayCategories.findIndex(item => item.code === 'All') === -1) {
        this.guidedDisplayCategories.unshift({ code: 'All' });
      }
      if (this.guidedDisplayCategories.findIndex(item => item.code === 'Previous Selection') === -1) {
        this.guidedDisplayCategories.push({ code: 'Previous Selection' });
      }
    },
    updateGuidedDropdowns (option, activeGuidedPrefix, guidedTag) {
      this.setActiveGuidedPrefix(activeGuidedPrefix);
      this.guidedCategoriesForActive = this.filterCategories;

      // if (guidedTag.includes('All')) {
      if (guidedTag.code === 'All') {
        if (activeGuidedPrefix === 0) {
          this.setSelectedLevel1(guidedTag);
          this.setSelectedLevel2({ code: 'All' });
          this.setSelectedLevel3({ code: 'All' });
          this.setLevel2CategoriesForSelectedLevel1([{ code: 'All' }]);
          this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);
          this.setDropdownGuidedTag('');
        } else if (activeGuidedPrefix === 1) {
          this.setSelectedLevel2(guidedTag);
          this.setSelectedLevel3({ code: 'All' });
          this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);
          this.setDropdownGuidedTag(this.selectedLevel1);
          this.activeGuidedValue = this.selectedLevel1.code;
          this.updateGuidedSearch(option, { code: 'All' });
        } else {
          this.setSelectedLevel3(guidedTag);
          this.setDropdownGuidedTag(this.selectedLevel2);
          this.activeGuidedValue = this.selectedLevel2.code;
          this.updateGuidedSearch(option, { code: 'All' });
        }
      } else if (guidedTag.code === 'Previous Selection') {
        this.updateGuidedSearch(option, guidedTag);
      } else {
        this.setDropdownGuidedTag(option, guidedTag);
        this.updateGuidedSearch(option, guidedTag);
      }
    },
    updateGuidedSearch (option, guidedTag) {
      if (guidedTag.code === 'Previous Selection') {
        if (this.activeGuidedPrefix === 2) {
          this.setActiveGuidedPrefix(1);
          this.setSelectedLevel2({ code: 'All' });
          this.activeGuidedValue = this.selectedLevel1.code;
          // this.guidedCategoriesForActive = this.allGuidedCategories[this.selectedLevel1];
          // this.guidedDisplayCategories = Object.keys(this.guidedCategoriesForActive);
          this.addedGuidedDisplayDefaultButtons();
          this.getGuidedDisplayDecks();
          this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);
        } else {
          this.initialiseGuidedSearch();
        }
      } else if (this.activeGuidedPrefix < 2 && guidedTag.code !== 'All') {
        // this.guidedCategoriesForActive = this.guidedCategoriesForActive[guidedTag];
        if (this.activeGuidedPrefix < 1) {
          this.setSelectedLevel1(guidedTag);
          this.setSelectedLevel2({ code: 'All' });
          this.setSelectedLevel3({ code: 'All' });
          // this.guidedDisplayCategories = Object.keys(this.guidedCategoriesForActive);
          this.addedGuidedDisplayDefaultButtons();
          this.getGuidedDisplayDecks();
          this.setLevel2CategoriesForSelectedLevel1([{ code: 'All' }, ...this.filterCategories]);
          this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);
        } else if (this.activeGuidedPrefix === 1) {
          this.setSelectedLevel2(guidedTag);
          this.setSelectedLevel3({ code: 'All' });
          // this.guidedDisplayCategories = [...this.guidedCategoriesForActive];
          this.addedGuidedDisplayDefaultButtons();
          this.getGuidedDisplayDecks();
          this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }, ...this.filterCategories]);
        } else {
          this.setSelectedLevel2(guidedTag);
          this.setSelectedLevel3({ code: 'All' });
          this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);
          // if (option === 'columns') {
          //   this.makeIcrsGuidedSearchQuery(guidedTag);
          //   this.closeModal('guidedSearchModal');
          // }
        }
        this.activeGuidedValue = guidedTag.code;
        this.setActiveGuidedPrefix(this.activeGuidedPrefix + 1);
      } else if (guidedTag.code !== 'All') {
        this.setSelectedLevel3(guidedTag);
        if (option === 'columns') {
          this.makeIcrsGuidedSearchQuery(guidedTag.code);
          this.closeModal('guidedSearchModal');
        }
      } else {
        // All Search
        this.setActiveGuidedPrefix(this.activeGuidedPrefix - 1);
        if (option === 'columns') {
          this.makeIcrsGuidedSearchQuery(this.activeGuidedValue);
          this.closeModal('guidedSearchModal');
        }
      }
    },
    initialiseGuidedSearch () {
      this.setActiveGuidedPrefix(0);
      // this.guidedCategoriesForActive = { ...this.allGuidedCategories };
      // this.guidedDisplayCategories = Object.keys(this.guidedCategoriesForActive);
      this.getGuidedDisplayDecks();
      this.setSelectedLevel1({ code: 'All' });
      this.setSelectedLevel2({ code: 'All' });
      this.setSelectedLevel3({ code: 'All' });
      this.setLevel2CategoriesForSelectedLevel1([{ code: 'All' }]);
      this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);
      this.setAdvancedSearchSelectedLevel({
        level1: { code: 'All' },
        level2: { code: 'All' },
        level3: { code: 'All' },
        level2ForSelectedLevel1: [{ code: 'All' }],
        level3ForSelectedLevel2: [{ code: 'All' }],
      });
    },
    makeIcrsGuidedSearchQuery (queryValue) {
      this.setSearchShowOverview(true);
      this.setAdvancedSearchSelectedLevel({
        level1: this.selectedLevel1,
        level2: this.selectedLevel2,
        level3: this.selectedLevel3,
        level2ForSelectedLevel1: this.level2CategoriesForSelectedLevel1,
        level3ForSelectedLevel2: this.level3CategoriesForSelectedLevel2,
      });
      if (queryValue !== '') {
        this.icrsGuidedSearchQuery = this.createQueryWithTimestamp(`${this.guidedPrefixes[this.activeGuidedPrefix]}"${queryValue}"`);
        // Setting all these here again to be doubly sure
        if (this.activeGuidedPrefix === 0) {
          const level1ByQuery = this.guidedCategoriesFilter.level1.find(item => item.code === queryValue);
          this.setSelectedLevel1(level1ByQuery);
          this.setSelectedLevel2({ code: 'All' });
          this.setLevel2CategoriesForSelectedLevel1([
            { code: 'All' },
            ...this.guidedCategoriesFilter.level2.filter(item => item.parentCode === this.selectedLevel1.code),
          ]);
          this.setSelectedLevel3({ code: 'All' });
          this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);
        }

        // Setting all these here again to be doubly sure
        if (this.activeGuidedPrefix === 1) {
          const level2ByQuery = this.guidedCategoriesFilter.level2.find(item => item.code === queryValue);
          this.setSelectedLevel2(level2ByQuery);
          this.setSelectedLevel3({ code: 'All' });
          this.setLevel3CategoriesForSelectedLevel2([
            { code: 'All' },
            ...this.guidedCategoriesFilter.level3.filter(item => item.parentCode === this.selectedLevel2.code),
          ]);
        }
      }
      // For syncing dropdownGuidedTag from boxes
      this.setDropdownGuidedTag(queryValue);
    },
    setIcrsAdvancedSearchQuery (queryValue) {
      this.icrsGuidedSearchQuery = this.createQueryWithTimestamp(queryValue);
    },
    onResize () {
      // if (window.outerWidth >= 992.02) {
      if (window.outerWidth >= 767.98) {
        this.mobileMode = false;
        this.showSearchOptions = true;
      } else {
        this.mobileMode = true;
        this.showSearchOptions = false;
      }
    },
    createQueryWithTimestamp (query) {
      return `${query}${Date.now()}`;
    },
  },
  mounted () {
    this.level1Categories = [...this.guidedCategoriesFilter.level1];
    if (this.guidedDisplayOption === 'landing') {
      this.setCurrentQuery('');
      this.initialiseGuidedSearch();
    }

    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>
