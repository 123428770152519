import _isUndefined from 'lodash/isUndefined';
import SearchRequeastParameter from './search-request-parameter.model';

export default class SearchNewRequestParameter extends SearchRequeastParameter {
  constructor (searchQuery, param) {
    super(searchQuery, param);

    this.criteriaId = !_isUndefined(param.criteriaId) ? param.criteriaId : null;
  }
}
