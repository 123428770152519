import _isUndefined from 'lodash/isUndefined';
import SearchServiceTypes from '../../../constants/search-service-constants';

class SearchRequestParameter {
  /**
   * Set Request Params for Searching
   * @param {String} searchQuery
   * @param {import('@models/search/search-parameter.model').default} param
   */
  constructor (searchQuery, param) {
    this.id = !_isUndefined(param.id) ? param.id : null;
    this.boolQuery = !_isUndefined(param.boolQuery) ? param.boolQuery : {};
    this.rangeQuery = !_isUndefined(param.rangeQuery) ? param.rangeQuery : [];
    this.topicsQuery = {
      should: !_isUndefined(param.topicsShould) ? param.topicsShould : [],
      must: !_isUndefined(param.topicsMust) ? param.topicsMust : [],
    };
    this.query = searchQuery;
    this.from = !_isUndefined(param.from) ? Number(param.from) : 1;
    this.size = !_isUndefined(param.size) ? Number(param.size) : SearchServiceTypes.RESULTS_PER_PAGE;
    this.searchMode = !_isUndefined(param.searchMode) ? param.searchMode : SearchServiceTypes.SEARCH_MODE.NEW;
    this.searchWithin = !_isUndefined(param.searchWithin) ? param.searchWithin : null;
    this.source = SearchRequestParameter.getSource(param.source);
    this.sortBy = !_isUndefined(param.sortBy) ? param.sortBy : null;
    this.synonyms = _isUndefined(param.synonyms) ? undefined : param.synonyms;
    if (!_isUndefined(param.searchWithin)) {
      this.searchWithin = param.searchWithin;
    }
    this.keepOriginSearchText = !_isUndefined(param.keepOriginSearchText) ? param.keepOriginSearchText : false;
  }

  static getSource (source) {
    if (!source || source === SearchServiceTypes.SOURCE_TYPES.ALL) {
      return [];
    }
    return source;
  }
}

export default SearchRequestParameter;
