<template>
  <div
    id="advanced-search-modal"
    class="d-inline-block"
  >
    <b-button
      v-if="showButton"
      variant="link"
      v-b-modal="'advanced-search-modal'"
      data-cy="advanced-search-button"
    >
      <div class="font-blue">
        Advanced Search
      </div>
    </b-button>

    <jumbo-modal
      :modalId="modalId"
      title="Advanced search"
      @hide="closeAdvancedSearchModal"
    >
      <template #nav>
        <div class="d-flex flex-row justify-content-between w-100">
          <div class="d-flex flex-row">
            <AdvancedSearchBar
              v-if="isAdvancedSearchActivated"
              ref="advancedSearchBar"
              class="mx-3"
            />
            <OntologySearchHistory v-if="currentSearch.ontologySearch" />
          </div>
          <div>
            <AdvancedSearchProTips
              v-if="config.showProTips && isAdvancedSearchActivated"
              :buttonPosition="'position-relative'"
            />
          </div>
        </div>
      </template>
      <template #content>
        <AdvancedSearch
          @on-reset="closeAdvancedSearchModal"
          @on-click-individual="onClickIndividual"
        />
      </template>
    </jumbo-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import JumboModal from '@/components/jumbo-modal/JumboModal';
import AdvancedSearch from './AdvancedSearch';
import AdvancedSearchBar from './components/AdvancedSearchBar';
import OntologySearchHistory from '../ontology-search/components/OntologySearchHistory';
import AdvancedSearchProTips from './components/AdvancedSearchProTips';

export default {
  name: 'AdvancedSearchModal',
  components: {
    JumboModal,
    AdvancedSearch,
    AdvancedSearchBar,
    OntologySearchHistory,
    AdvancedSearchProTips,
  },
  watch: {
    advancedSearchModalOpen (val, oldVal) {
      if (val) {
        this.$bvModal.show(this.modalId);
      }
    },
  },
  data () {
    return {
      modalId: 'advanced-search-modal',
      config: {
        showProTips: true,
      },
    };
  },
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('advancedSearch', [
      'advancedSearchModalOpen',
      'currentSearch',
    ]),

    isAdvancedSearchActivated () {
      return this.currentSearch.ontologySearch || this.currentSearch.filterBasedSearch;
    },
  },
  methods: {
    ...mapActions('advancedSearch', [
      'resetAdvancedSearch',
      'updateCurrentSearch',
    ]),
    closeAdvancedSearchModal () {
      this.resetAdvancedSearch();
      this.$bvModal.hide(this.modalId);
    },
    onClickIndividual ({ id, subIndividual }) {
      this.$refs.advancedSearchBar.onIndividualSelect({ suggest: subIndividual, id });
    },
  },
  mounted () {
    // after DOM update throw this logic
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
  },
};
</script>
<style lang="scss" scoped>
/deep/ .main-content-holder {
  height: 100%;
  padding-bottom: 0;
}
</style>
