import constants from '@/constants/search-service-constants';
import createState from '../state';
import mutations from '../mutations';

describe('search-core/filters/promoted-content vuex mutations test', () => {
  test('setPromotedFilterEnable', () => {
    const state = createState();
    const enablePromoteFilter = true;
    mutations.setPromotedFilterEnable(state, enablePromoteFilter);
    expect(state.promotedFilterEnable).toEqual(enablePromoteFilter);
  });

  test('setPromotedDateRangeMode', () => {
    const state = createState();
    const dateRangeMode = constants.PROMOTED_DATE_RANGE_MODE.TODAY;
    mutations.setPromotedDateRangeMode(state, dateRangeMode);
    expect(state.promotedByDateRangeMode).toBe(dateRangeMode);
  });

  test('setPromoteDateObject', () => {
    const state = createState();
    const dateObject = {
      field: 'metadata.promote',
      start: new Date(),
      end: new Date(),
    };
    mutations.setPromoteDateObject(state, dateObject);
    expect(state.promoteDateObject).toBe(dateObject);
  });

  test('resetPromotedContent', () => {
    const originalState = createState();
    const state = createState();
    const dateObject = {
      field: 'metadata.promote',
      start: new Date(),
      end: new Date(),
    };
    state.promotedFilterEnable = true;
    state.promotedByDateRangeMode = constants.PROMOTED_DATE_RANGE_MODE.TODAY;
    state.promoteDateObject = dateObject;

    mutations.resetPromotedContent(state);
    expect(state).toEqual(originalState);
  });
});
