<template>
  <div class="filter-group">
    <div
      class="filter-group__item"
      v-b-toggle="accordionId"
      role="tab"
    >
      <span
        class="filter-group-name"
        :class="{
          'filter-group-name--filter-applied': filtersAppliedCount
        }"
      >
        {{ groupItem.displayName }}
        <template v-if="filtersAppliedCount">({{ filtersAppliedCount }})</template>
      </span>
      <icon-text
        class="when-opened"
        faType="fas"
        faName="chevron-up"
        size="sm"
      ></icon-text>
      <icon-text
        class="when-closed"
        faType="fas"
        faName="chevron-down"
        size="sm"
      ></icon-text>
    </div>
    <b-collapse
      :id="accordionId"
      :visible="isOpen"
      role="tabpanel"
      @input="handleCollapseInput"
    >
      <div class="filter-checklist">
        <FilterItem
          v-for="item in limitedItems"
          :key="item.key"
          :item="item"
          :applied="appliedFilters.includes(
            createFilterKey(item.key, groupItem.name)
          )"
          :group-name="groupItem.name"
          @change="$emit('change', $event)"
        />

        <template v-if="itemsCount > 5">
          <button
            @click="$emit('show-more', groupItem)"
            class="show-more-btn"
          >
            Show more&nbsp;
            <template v-if="invisibleAppliedFiltersCount">
              ({{ invisibleAppliedFiltersCount }})
            </template>
          </button>
        </template>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import IconText from '@/components/IconText.vue';
import FilterItem from './FilterItem.vue';

export default {
  components: {
    IconText,
    FilterItem,
  },

  props: {
    groupItem: Object,
    appliedFilters: {
      type: Array,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    accordionId () {
      return `accordion-${this.groupItem.name}`;
    },

    itemsCount () {
      return this.groupItem.items.length;
    },

    limitedItems () {
      return this.groupItem.items.slice(0, 5);
    },

    availabelFilterKeys () {
      return this.groupItem.items.map(item => (
        this.createFilterKey(item.key, this.groupItem.name)));
    },

    filtersApplied () {
      return this.appliedFilters
        .filter(key => this.availabelFilterKeys.includes(key));
    },

    filtersAppliedCount () {
      return this.filtersApplied.length;
    },

    invisibleAppliedFiltersCount () {
      const visibleAppliedFilterKeys = this.limitedItems.map(
        filter => this.createFilterKey(filter.key, this.groupItem.name),
      );
      const visibleAppliedFilterCount = this.filtersApplied
        .filter(key => visibleAppliedFilterKeys.includes(key))
        .length;

      return this.filtersAppliedCount - visibleAppliedFilterCount;
    },
  },

  methods: {
    handleCollapseInput (isOpen) {
      this.$emit('toggle-open', {
        isOpen,
        groupName: this.groupItem.name,
      });
    },
    createFilterKey (key, groupName) {
      return `${groupName}_${key.split(' ; ').length === 2 ? key.split(' ; ')[0] : key}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-group {
  &__item {
    padding: 0px 0px $gap-md $gap-md;
    display: flex;
    justify-content: space-between;
  }

  &/deep/ .filter-item[aria-expanded="true"] svg {
    transform: rotate(180deg);
  }
}
.filter-group-name {
  font-weight: $weight-semi-bold;
  color: $blue-gray-500;
  font-size: $font-md;

  &--filter-applied {
    color: $blue-500;
  }
}

.filter-checklist {
  padding: 0px $gap-md $gap-md $gap-md;

  &/deep/ .filter-item {
    margin-bottom: $gap-xxs;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.show-more-btn {
  color: $blue-500;
  padding: 4px 6px;
  border: 0;
  background: transparent;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
