<template>
  <div>
    <b-button
      v-if="showModalButton"
      class="btn btn-primary btn-outline btn-xs"
      @click="showModal(docId)"
    >
      Open Document Manager
    </b-button>
    <jumbo-modal
      @hide="onModalClosed"
      title="Document Settings Manager"
      :modalId="modalId"
    >
      <template #content>
        <div id="doc-manager">
          <div class="doc-manager-container">
            <doc-input
              id="id-form"
              v-model="documentInput"
              :is-input-error="isInputError"
              @click-btn="handleClickSubmitId"
              v-if="currentPage === pageType.ID_FORM"
            />
            <preview-content
              :have-write-access="haveWriteAccess"
              :key="reRenderKey"
              id="preview-content"
              :is-editable="isEditable"
              :activated-tab="activatedTab"
              :tabs="activeTabs"
              v-model="modelDocument"
              @click-edit="handleClickEditDoc"
              @click-delete="handleClickDeleteDoc"
              @click-cancel="handleClickCancelDoc"
              @click-save="handleClickSaveDoc"
              v-else-if="currentPage === pageType.PREVIEW_CONTENT"
            />
            <not-found
              v-else
              id="not-found"
              @click-btn="handleClickTry"
            />
          </div>
        </div>
      </template>
    </jumbo-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import JumboModal from '@/components/jumbo-modal/JumboModal';
import constants from '../../constants/doc-manager-service-constants';
import { DocInput, NotFound, PreviewContent } from './components/index.module';


import '../../helpers/doc.veevalidate.config';

export default {
  name: 'DocManager',
  props: {
    docId: {
      type: String,
      default: '',
    },
    showModalButton: {
      type: Boolean,
      default: false,
    },
    haveWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    JumboModal,
    DocInput,
    NotFound,
    PreviewContent,
  },
  data () {
    return {
      currentPage: constants.PAGE_TYPE.ID_FORM,
      documentInput: '',
      isInputError: '',
      isEditable: true,
      documentModel: {},
      reRenderKey: 0,
      modalId: 'doc-manager-modal-fullpage',
      activatedTab: '',
      tabs: constants.getTabsConfig(),
    };
  },
  computed: {
    ...mapGetters({
      documentContent: 'docManagerService/documentContent',
    }),
    modelDocument: {
      get () {
        return this.documentContent;
      },
      set (value) {
        this.documentModel = { ...this.documentContent, ...value };
      },
    },
    pageType () {
      return constants.PAGE_TYPE;
    },
    activeTabs () {
      return this.tabs.filter(tab => tab.disabled === false);
    },
  },
  methods: {
    ...mapActions({
      execSearchDoc: 'docManagerService/execSearchDoc',
      getBlacklistedField: 'docManagerService/getBlacklistedField',
    }),
    onModalClosed () {
      // Todo event emmiter
      this.$emit('doc-manager-closed');
    },
    async onIdSubmitted (documentInput) {
      if (documentInput) {
        try {
          await this.execSearchDoc({ id: documentInput });
          await this.getBlacklistedField();
          this.currentPage = constants.PAGE_TYPE.PREVIEW_CONTENT;
        } catch (ex) {
          this.documentInput = '';
          this.currentPage = constants.PAGE_TYPE.NOT_FOUND;
        }
      }
    },
    async showModal (id, tab) {
      this.activatedTab = tab || '';

      if (id) {
        this.documentInput = id;
        await this.onIdSubmitted(id);
      }

      this.$bvModal.show(this.modalId);
    },
    onDocDeleted () {
      this.$bvModal.hide(this.modalId);
    },
    handleClickSubmitId () {
      const { documentInput } = this;
      this.onIdSubmitted(documentInput);
    },
    handleClickEditDoc () {
      this.isEditable = true;
    },
    handleClickDeleteDoc () {
      this.onDocDeleted();
    },
    handleClickCancelDoc () {
      this.isEditable = false;
      this.reRenderKey += 1;
    },
    handleClickSaveDoc () {
      this.isEditable = false;
    },
    handleClickTry () {
      this.currentPage = constants.PAGE_TYPE.ID_FORM;
    },
    /**
     * @param {String} page - page type from constants
     */
    handleChangePage (page) {
      this.currentPage = page;
    },
  },
  mounted () {
    if (this.showModalButton) {
      this.showModal();
    }
  },
};
</script>

<style lang="scss">
#doc-manager {
  width: 75vw;
  min-width: 800px;
  position: relative;

  .--top-categories {
    padding-top: 60px !important;
  }

  // global variable overwrite start

  .form-control.is-invalid {
    border-color: #dc3545 !important;
  }

  .form-error {
    font-style: normal;
    font-weight: $weight-normal;
    font-size: 12px;
    line-height: 16px;
    color: #dc3545;
  }

  .empty-table-text {
    font-style: normal;
    font-weight: $weight-normal;
    font-size: 14px;
    line-height: 21px;

    color: $blue-gray-500;
  }

  .dropdown-menu {
    padding: unset;
    min-width: 0;

    border-color: $blue-gray-100;

    .show {
      box-shadow: 1px 2px 4px rgba(153, 155, 168, 0.12);
    }
    a {
      font-size: 14px;
      padding: 4px 8px;
    }
  }

  .btn-primary {
    min-width: 98px;
    min-height: 36px;
    border-radius: 4px;

    font-style: normal;
    font-weight: $weight-normal;
    font-size: 14px;
    line-height: 20px;

    background-color: $blue-500;
    border-color: $blue-500;
  }

  .btn-link {
    text-decoration: none;
    background-color: $blue-200;
  }

  .btn:hover {
    opacity: 0.9;
  }

  .label {
    font-style: normal;
    font-weight: $weight-semi-bold;
    font-size: 14px;
    line-height: 20px;
    color: $blue-gray-400;
  }

  .form-control {
    border-color: $blue-gray-100;
  }

  .form-control:focus {
    color: #495057;
    background-color: $white-500;
    border-color: $blue-500;
    outline: 0;
    -webkit-box-shadow: unset;
    box-shadow: unset;
  }

  .m-tooltip {
    margin-left: 8px;
  }

  .tooltip {
    background-color: rgba($color: #000000, $alpha: 0.6s);
    border-radius: 5px;
    font-size: 12px;
    color: $white-500;
    padding: 5px;
  }

  // end

  .doc-manager-container {
    .table th {
      border-top: none !important;
      border-bottom: none !important;
      font-weight: $weight-bold;
    }
    .table td {
      vertical-align: middle !important;
      font-style: normal;
      font-weight: $weight-normal;
      font-size: 14px;
      line-height: 20px;
    }
    .table td,
    .table th {
      border-color: $blue-gray-100;
      color: $blue-gray-500;
      background-color: $white-500;
    }
  }
  #id-form {
    margin: 15px 0px;
  }
  #not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
