<template>
  <IcrsTemplate>
    <template #content>
      <h2 class="text-center">Support</h2>
      <p><strong>Motor Accident Claims Online (MACO)</strong> helps parties involved in motor accidents make informed decisions about their motor accident claims. By answering a series of questions in the system, parties or their appointed representatives (i.e. their lawyers or insurers) can get an effective indication of who may be at fault in an accident and/or how much damages they may be awarded. The information is useful for deciding how to proceed and potentially allow claims to be resolved without extensive legal proceedings.</p>
      <br>
      <p>The application has an Outcome Simulator that allows users to do the following:</p>
      <br>
      <p><span><strong>Liability simulation</strong></span> – Gives an indication who is at fault for the accident</p>
      <p><span><strong>Quantum Simulation</strong></span> – Gives an indication of the possible compensation a party may be awarded for injuries incurred from the accident. With the outcomes from the Outcome Simulator, parties can then engage in constructive discussions with one another with a view to facilitating a settlement.</p>
      <br>
      <p>The Outcome Simulator covers only motor accident scenarios involving two parties. The Simulator also does not cover:</p>
      <ul>
        <li>Damages for damage to vehicle and other property (e.g. cost of repairs and loss of use of vehicle)</li>
        <li>Damages due to personal injury which are awarded for (i) loss of future earnings or earning capacity, (ii) medical expenses.</li>
      </ul>
      <br>
      <p><span><strong>MACO</strong></span> is not a substitute for professional legal advice, and it is recommended that you seek professional legal advice when it comes to making claims.</p>
    </template>
  </IcrsTemplate>
</template>
<script>
import IcrsTemplate from './template/IcrsStaticTemplate.vue';

export default {
  components: {
    IcrsTemplate,
  },
};
</script>
