export default {
  relations: state => state.relations,
  relationsMin: state => state.relationsMin,
  relationsMax: state => state.relationsMax,
  maxEdges: state => state.maxEdges,
  nodes: state => state.nodes,
  edges: state => state.edges,
  selectedNode: state => state.selectedNode,
  table: state => state.table,
  history: state => state.history,
  displayedNodes: state => state.displayedNodes,
  displayedEdges: state => state.displayedEdges,
  ontologyMap: state => state.ontologyMap,
  maxHistoryLength: state => state.maxHistoryLength,
  classFilters: state => state.classFilters,
  individualProperties: state => state.individualProperties,
};
