<template>
  <connector-details-widget
    :connector-id="connectorId"
    :tab-index="tabIndex"
    class="search-connector-styles"
    @go-to-dashboard="goToDashboard"
  />
</template>

<script>
import { goToDashboard } from '../helpers';

export default {
  props: {
    connectorId: {
      type: String,
      default: null,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    goToDashboard,
  },
};
</script>
