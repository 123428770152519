
class SearchResultCard {
  constructor ({
    id, title, source, docFormat, lastModifiedDate, content, highlight, metadata, path, relationChain, newDocument,
  }) {
    this.id = _.isUndefined(id) ? undefined : id;
    this.subTitle = SearchResultCard.formatSubtitle({ source, metadata, lastModifiedDate });
    this.title = _.isUndefined(title) ? undefined : title;
    this.highlight = _.isUndefined(highlight) ? undefined : highlight;
    this.path = _.isUndefined(path) ? undefined : path;
    this.relationChain = _.isUndefined(relationChain) || _.isNull(relationChain)
      ? undefined : SearchResultCard.formatRelationChain(relationChain);
    this.newDocument = _.isUndefined(newDocument) ? false : newDocument;
    this.promotedDocument = _.isUndefined(metadata.promote) ? false : metadata.promote;
    this.source = _.isUndefined(source) ? '' : source;
    this.lastModifiedDate = _.isUndefined(lastModifiedDate) ? '' : lastModifiedDate;
    this.docFormat = _.isUndefined(docFormat) ? '' : docFormat;
  }

  /**
 *
 * @description format source, docFormat, lastModifiedDate to desired format
 */
  static formatSubtitle ({ source, metadata, lastModifiedDate }) {
    let typeText = '';
    let sourceText = '';

    if (metadata && metadata.fileExtension) {
      typeText = `・Type: ${metadata.fileExtension}`;
    }

    if (source) {
      sourceText = `・Source: ${source}`;
    }

    return `${lastModifiedDate}${typeText}${sourceText}`;
  }

  /**
*
* @description format source, docFormat, lastModifiedDate to desired format
*/
  static formatRelationChain (relationChain) {
    const myRelationChain = relationChain.map(r => r.term);
    return myRelationChain;
  }
}
export default SearchResultCard;
