import constants from '@/constants/search-service-constants';
import getters from '../getters';
import createState from '../state';


describe('search-core/filters/promoted-content vuex getters test', () => {
  test('promotedFilterEnable', () => {
    const state = createState();

    expect(getters.promotedFilterEnable(state)).toEqual(false);

    state.promotedFilterEnable = true;
    expect(getters.promotedFilterEnable(state)).toEqual(true);
  });

  test('promotedByDateRangeMode', () => {
    const state = createState();

    expect(getters.promotedByDateRangeMode(state)).toBe('');

    state.promotedByDateRangeMode = constants.PROMOTED_DATE_RANGE_MODE.TODAY;
    expect(getters.promotedByDateRangeMode(state)).toBe(constants.PROMOTED_DATE_RANGE_MODE.TODAY);
  });

  test('promoteDateObject', () => {
    const state = createState();

    const dateObject = {
      field: 'metadata.promote',
      start: new Date(),
      end: new Date(),
    };
    expect(getters.promoteDateObject(state)).toEqual({});

    state.promoteDateObject = dateObject;
    expect(getters.promoteDateObject(state)).toBe(dateObject);
  });
});
