import { render, staticRenderFns } from "./IcrsPageManagement.vue?vue&type=template&id=61582502&scoped=true"
import script from "./IcrsPageManagement.vue?vue&type=script&lang=js"
export * from "./IcrsPageManagement.vue?vue&type=script&lang=js"
import style0 from "./IcrsPageManagement.vue?vue&type=style&index=0&id=61582502&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61582502",
  null
  
)

export default component.exports