import Vue from 'vue';
import { CSRF_TOKEN_UI, USER_MODEL } from '@/constants/auth-service-constants';

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
* */
export default {

  getCsrfTokenUi () {
    return Vue.$localStorage.get(CSRF_TOKEN_UI);
  },
  setCsrfTokenUi (token) {
    return Vue.$localStorage.set(CSRF_TOKEN_UI, token);
  },
  getUserModel () {
    return Vue.$localStorage.get(USER_MODEL);
  },
  setUserModel (user) {
    Vue.$localStorage.set(USER_MODEL, user);
  },
  deleteUserModel () {
    Vue.$localStorage.remove(USER_MODEL);
  },
};
