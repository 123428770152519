<template>
  <footer class="icrs-footer jds-footer hide-for-printing">
    <div
      class="sitemap"
      style="padding-top: 2rem"
    >
      <div class="container-fluid">
        <h2 class="jds-footer-sitemap-header">ICRS</h2>
        <div class="footer-links" v-if="!isLoadingList">
          <template  v-for="item in bottomNavList"          >
            <a v-if="item.type === 'Link'"
              :key="item.id"
              :href="fixLink(getExternalLink(item))"
              target="_blank"
              rel="noopener"
            >{{ item.title }}
              <font-awesome-icon
                :icon="['fal', 'external-link']"
                size="sm"
              />
            </a>
            <router-link v-else :key="item.id"
            :to="getLinkHref(item)">{{ item.title }}</router-link>
          </template>
        </div>
        <LoadingSpinner v-if="isLoadingList"
          text="Loading..."
          size="md"
        />
      </div>
    </div>
    <hr class="divider">
    <div class="icrs-footer-bottom-section copyright">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-7">
            <div class="footer-links" v-if="!isLoadingList">
              <template v-for="item in footerList">
                  <a v-if="item.type === 'Link'"
                    :key="item.id"
                    :href="fixLink(getExternalLink(item))"
                    target="_blank"
                    rel="noopener"
                  >{{ item.title }}
                    <font-awesome-icon
                      :icon="['fal', 'external-link']"
                      size="sm"
                    />
                  </a>
                  <router-link v-else :key="item.id"
                  :to="getLinkHref(item)">{{ item.title }}</router-link>
              </template>
            </div>
            <LoadingSpinner v-if="isLoadingList"
              text="Loading..."
              size="md"
            />
            <div class="powered-by"></div>
          </div>
          <div class="col-lg-5 icrs-footer-bottom-section-copyright">
            COPYRIGHT © {{ getCurrentYear }} LAWNET. ALL RIGHTS RESERVED
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import ICRSService from '@/services/icrs-service';
import { mapGetters, mapActions } from 'vuex';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { sanitizeUrl } from '@braintree/sanitize-url';

export default {
  name: 'ICRSFooter',
  components: {
    LoadingSpinner,
  },
  computed: {
    ...mapGetters('pageManagement', [
      'bottomNavList',
      'footerList',
      'isLoadingList',
    ]),
    updatedDate () {
      const currentDate = new Date();
      const currentDateParts = currentDate.toString().split(' ');
      return `${currentDateParts[2]} ${currentDateParts[1]} ${currentDateParts[3]}`;
    },
    usefulLinks () {
      return ICRSService.getUsefulLinks();
    },
    getCurrentYear () {
      return new Date().getFullYear();
    },
  },
  methods: {
    ...mapActions('pageManagement', [
      'execGetBottomNavList',
      'execGetFooterList',
    ]),
    fixLink (url) {
      return sanitizeUrl(url);
    },
    getLinkHref (item) {
      return `/page-management/preview/${item.id}?from=Footer`;
    },
    getExternalLink (item) {
      return item.content.startsWith('https://') ? item.content : `https://${item.content}`;
    },
  },
  mounted () {
    if (this.bottomNavList.length === 0) this.execGetBottomNavList();
    if (this.footerList.length === 0) this.execGetFooterList();
  },
};
</script>
<style lang="scss" scoped>
.icrs-footer-bottom-section {
  padding-bottom: 2rem;

  .icrs-footer-bottom-section-copyright {
    text-align: right;
    padding-right: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

}

.footer-links a:focus-visible {
  outline: 2px solid gray;

}

@media (max-width: 992px) {
  .icrs-footer-bottom-section {
    .icrs-footer-bottom-section-copyright {
      margin-top: 1rem;
      text-align: left;
      padding-right: 0;
    }
  }
}
</style>
