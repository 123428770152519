import DocManagerService from '@/services/doc-manager.service';
import { createTestAction } from '@/helpers/testing';
import DocRequestParameter from '@/services/models/doc-manager/doc-request-parameter.model';
import DocUpdateParameter from '@/services/models/doc-manager/doc-update-parameter.model';
import actions from '../actions';

jest.mock('@/services/doc-manager.service', () => ({
  getBlacklistedFields: jest.fn(),
  getAllClasses: jest.fn(),
  getIndividualClass: jest.fn(),
  execSearchDoc: jest.fn(),
  execUpdateDoc: jest.fn(),
  execDeleteDoc: jest.fn(),
}));

jest.mock('@/services/event-bus.service', () => ({
  emitNotificationEvent: jest.fn(),
}));

const testAction = createTestAction(expect);

describe('doc manager vuex actions test', () => {
  test('getBlacklistedField', (done) => {
    const commit = jest.fn();
    const ctx = { commit };
    const response = ['id', 'appType', 'createdDate', 'lastModifiedDate', 'version', 'latest',
      'roles', 'docFormat', 'sensitive', 'visualization'];
    DocManagerService.getBlacklistedFields.mockReturnValueOnce(Promise.resolve(response));
    testAction(actions.getBlacklistedField, null, ctx, [
      { type: 'setBlacklistedField', payload: response },
    ], done);
  });

  test('getAllClasses', (done) => {
    const commit = jest.fn();
    const ctx = { commit };
    const response = {
      country: 'Country',
      occupation: 'Occupation',
      shape: 'Shape',
      software: 'Software',
      color: 'Color',
      city: 'City',
      artobject: 'Art Object',
      subject: 'Subject',
      programmes: 'Programmes',
      singheritage: 'SingHeritage',
    };
    DocManagerService.getAllClasses.mockReturnValueOnce(Promise.resolve(response));
    testAction(actions.getAllClasses, null, ctx, [
      { type: 'setAllClass', payload: response },
    ], done);
  });

  test('getIndividualClass', (done) => {
    const commit = jest.fn();
    const ctx = { commit };
    const response = {
      software: [
        'Omnitive Platform',
        'Omnitive Studio',
        'Ontology Manager',
      ],
      language: [
        'English',
        'English',
        'Malay',
        'Tamil',
      ],
      technology: [
        'Artificial Intelligence',
      ],
    };
    const params = ['software', 'language', 'technology'];
    DocManagerService.getIndividualClass.mockReturnValueOnce(Promise.resolve(response));
    testAction(actions.getIndividualClass, params, ctx, [
      { type: 'setIndividualClass', payload: response },
    ], done);
  });

  test('execSearchDoc', (done) => {
    const commit = jest.fn();
    const ctx = { commit };
    const response = {
      id: 'yahoo_supermassive_black_hole_found_wandering_through_space',
      title: 'Supermassive black hole found wandering through space',
      source: 'https://www.yahoo.com/news/rss',
      path: 'https://news.yahoo.com/supermassive-black-hole-found-wandering-153046384.html',
      content: ' | ',
      createdDate: '2021-03-17T04:00:03.274Z',
      lastModifiedDate: '2021-03-17T04:00:03.287Z',
      latest: true,
      roles: [
      ],
      metadata: {
        promote: true,
        promote_to: '9999-12-31T23:59:59.999Z',
        promote_from: '1970-01-01T00:00:00.000Z',
      },
      docFormat: 'html',
      nlpDate: '2021-03-17T04:00:11.977Z',
      connectorId: 1,
      priority: 0,
      manualUpdate: true,
      tags: {
      },
    };
    const param = { id: 'yahoo_supermassive_black_hole_found_wandering_through_space' };
    const docRequestParam = new DocRequestParameter(param);
    DocManagerService.execSearchDoc.mockReturnValueOnce(Promise.resolve(response));
    testAction(actions.execSearchDoc, docRequestParam, ctx, [
      { type: 'setDocumentContent', payload: response },
    ], done);
  });

  test('execUpdateDoc', (done) => {
    const ctx = {};
    const response = 'Document updated successfully';
    const params = {
      id: 'yahoo_supermassive_black_hole_found_wandering_through_space',
      path: 'https://news.yahoo.com/supermassive-black-hole-found-wandering-153046384.html',
      title: 'Supermassive black hole found wandering through space',
      createdDate: '2021-03-17T04:00:03.274Z',
    };

    const { id } = params;
    const docUpdateParameter = new DocUpdateParameter(params, id);
    DocManagerService.execUpdateDoc.mockReturnValueOnce(Promise.resolve(response));
    testAction(actions.execUpdateDoc, docUpdateParameter, ctx, [], done);
  });

  test('execDeleteDoc', (done) => {
    const ctx = {};
    const response = 'Document deleted successfully';
    const param = { id: 'yahoo_supermassive_black_hole_found_wandering_through_space' };
    DocManagerService.execDeleteDoc.mockReturnValueOnce(Promise.resolve(response));
    testAction(actions.execDeleteDoc, param, ctx, [], done);
  });
});
