import FilterGroup from '@/services/models/search/filter-group.model';
import constants from '@/constants/search-service-constants';
import FilterItem from '@/services/models/search/filter-item.model';
import createState from '../state';
import mutations from '../mutations';

describe('search-core/filters vuex mutations test', () => {
  test('setSelectedFilters', () => {
    const state = createState();
    const selectedFilters = [
      { key: 'caa', docCount: 1, groupName: 'Sections' },
      { key: 'casa', docCount: 2, groupName: 'Sections' },
    ];

    mutations.setSelectedFilters(state, selectedFilters);
    expect(state.selectedFilters).toBe(selectedFilters);
  });

  test('setModalSelectedFilterGroup', () => {
    const state = createState();
    const filterGroup = new FilterGroup({
      name: 'sections',
      docCount: 11,
      bucket: [],
      displayName: 'Sections',
    });

    mutations.setModalSelectedFilterGroup(state, filterGroup);
    expect(state.selectedGroup).toBe(filterGroup);
  });

  test('setModalSelectedFilters', () => {
    const state = createState();
    const selectedFilters = [
      { key: 'caa', docCount: 1, groupName: 'Sections' },
      { key: 'casa', docCount: 2, groupName: 'Sections' },
    ];

    mutations.setModalSelectedFilters(state, selectedFilters);
    expect(state.filterModal.selectedFilters).toBe(selectedFilters);
  });

  test('setModalSearchKeyword', () => {
    const state = createState();
    const keyword = 'A filter';

    expect(state.filterModal.searchKeyword).toBe('');
    mutations.setModalSearchKeyword(state, keyword);
    expect(state.filterModal.searchKeyword).toBe(keyword);
  });

  test('setModalSortBy', () => {
    const state = createState();

    mutations.setModalSortBy(state, constants.FILTER_SORT_BY_OPTIONS.A_TO_Z);
    expect(state.filterModal.sortBy).toBe(constants.FILTER_SORT_BY_OPTIONS.A_TO_Z);

    mutations.setModalSortBy(state, constants.FILTER_SORT_BY_OPTIONS.LEAST_TO_MOST);
    expect(state.filterModal.sortBy).toBe(constants.FILTER_SORT_BY_OPTIONS.LEAST_TO_MOST);

    expect(() => {
      mutations.setModalSortBy(state, 'invalid');
    }).toThrowError(/Invalid/i);

    // the value should not be changed
    expect(state.filterModal.sortBy).toBe(constants.FILTER_SORT_BY_OPTIONS.LEAST_TO_MOST);
  });

  test('setIsFirstOpenHasSelectedFilters', () => {
    const state = createState();

    expect(state.filterModal.isFirstOpenHasSelectedFilters).toBe(false);
    mutations.setIsFirstOpenHasSelectedFilters(state, true);
    expect(state.filterModal.isFirstOpenHasSelectedFilters).toBe(true);
  });

  describe('applyModalSelectedFilters', () => {
    test('applying filters when the applied filters is empty', () => {
      const modalSelectedFilters = [
        new FilterItem({ key: 'casa', docCount: 1 }, 'caa'),
      ];

      const selectedGroup = FilterGroup.create({
        name: 'caa',
        docCount: 3,
        displayName: 'CAA',
      });
      const state = createState();
      state.filterModal.selectedFilters = modalSelectedFilters;
      mutations.applyModalSelectedFilters(state, selectedGroup);

      expect(state.selectedFilters).toEqual(modalSelectedFilters);
    });

    test('change applied filter', () => {
      const appliedFilters = [
        new FilterItem({ key: 'casa', docCount: 1 }, 'caa'),
      ];
      const modalSelectedFilters = [
        new FilterItem({ key: 'faa', docCount: 1 }, 'caa'),
      ];

      const selectedGroup = FilterGroup.create({
        name: 'caa',
        docCount: 3,
        displayName: 'CAA',
      });
      const state = createState();

      state.selectedFilters = appliedFilters;
      state.filterModal.selectedFilters = modalSelectedFilters;

      mutations.applyModalSelectedFilters(state, selectedGroup);
      expect(state.selectedFilters).toEqual(modalSelectedFilters);
    });

    test('removing one of applied filter', () => {
      const appliedFilters = [
        new FilterItem({ key: 'casa', docCount: 1 }, 'caa'),
        new FilterItem({ key: 'fasa', docCount: 1 }, 'caa'),
      ];
      const modalSelectedFilters = [
        new FilterItem({ key: 'faa', docCount: 1 }, 'caa'),
      ];

      const selectedGroup = FilterGroup.create({
        name: 'caa',
        docCount: 3,
        displayName: 'CAA',
      });
      const state = createState();

      state.selectedFilters = appliedFilters;
      state.filterModal.selectedFilters = modalSelectedFilters;

      mutations.applyModalSelectedFilters(state, selectedGroup);
      expect(state.selectedFilters).toEqual(modalSelectedFilters);
    });

    test('adding one of applied filter', () => {
      const appliedFilters = [
        new FilterItem({ key: 'airplan', docCount: 1 }, 'sector'),
        new FilterItem({ key: 'fasa', docCount: 1 }, 'caa'),
      ];
      const modalSelectedFilters = [
        new FilterItem({ key: 'fasa', docCount: 1 }, 'caa'),
        new FilterItem({ key: 'caa', docCount: 1 }, 'caa'),
      ];

      const selectedGroup = FilterGroup.create({
        name: 'caa',
        docCount: 3,
        displayName: 'CAA',
      });
      const state = createState();

      state.selectedFilters = appliedFilters;
      state.filterModal.selectedFilters = modalSelectedFilters;

      mutations.applyModalSelectedFilters(state, selectedGroup);
      expect(state.selectedFilters).toEqual([
        new FilterItem({ key: 'airplan', docCount: 1 }, 'sector'),
        new FilterItem({ key: 'fasa', docCount: 1 }, 'caa'),
        new FilterItem({ key: 'caa', docCount: 1 }, 'caa'),
      ]);
    });
  });

  test('setFilterGroupings', () => {
    const state = createState();
    const filterGroupConfig = [
      {
        groupName: 'General',
        filters: [
          {
            classId: 'subject',
          },
        ],
      },
    ];
    mutations.setFilterGroupings(state, filterGroupConfig);
    expect(state.filterGroupings).toBe(filterGroupConfig);
  });
});
