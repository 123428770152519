<template>
  <b-modal
    lazy
    static
    centered
    header-class="border-0"
    footer-class="border-0"
    :id="id"
    :size="size"
    :title="title"
    no-close-on-backdrop
  >
    <template slot="modal-footer">
      <b-btn
        @click="handleCancel"
        class="confirm-btn mr-2 pl-4 pr-4 border-0"
        variant="outline-secondary"
      >
        Cancel
      </b-btn>
      <b-btn
        @click="handleDelete"
        class="confirm-btn ml-2 pl-4 pr-4"
        variant="danger"
      >
        Confirm
      </b-btn>
    </template>
    <div>
      <div v-if="type === modalType.METADATA">
        <p>
          Are you sure that you want to delete the metadata{{' '}}
          <strong>{{deletedItem.key}}</strong>? This action cannot be undone.
        </p>
      </div>
      <div v-if="type === modalType.TAG">
        <p>
          Are you sure that you want to delete tag{{' '}}
          <strong>{{deletedItem.key}}</strong> and all related tag values? This action cannot be undone.
        </p>
      </div>
      <div v-if="type === modalType.DOCUMENT">
        <p>
          Are you sure that you want to delete this document? This action cannot be undone.
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
import constants from '../../../constants/doc-manager-service-constants';

export default {
  name: 'delete-modal',
  props: {
    id: {
      type: String,
      default: 'delete-modal',
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    type: {
      type: String,
      default: constants.DELETE_MODAL_TYPE.METADATA,
    },
    deletedItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    const { DELETE_MODAL_TYPE } = constants;
    return {
      modalType: DELETE_MODAL_TYPE,
    };
  },
  methods: {
    handleCancel () {
      this.$bvModal.hide(this.id);
    },
    handleDelete () {
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" >
#doc-manager {
  #delete-modal {
    p {
      font-size: 14px;
      color: $blue-gray-400;
    }
  }
}
</style>
