<template>
  <div class="search-result">
    <div class="applied-filters-wrapper">
      <AppliedFilters
        namespace="searchService/newResults"
        :filterCountDisabled="true"
      />
    </div>

    <!-- filter-section -->
    <div class="filter-section">
      <div class="search-category">
        <span class="d-inline-block mr-2 text-md">
          <strong>{{ currentSearchResults.total }}</strong>
          {{ searchResultLabel }}
        </span>
        <Dropdown
          class="category-dropdown"
          :value="selectedCategory"
          :options="searchCategoryOptions"
          :disabled="shouldDisableSearchCategory"
          @input="handleSelectedCategoryChange"
        ></Dropdown>
      </div>
      <div
        class="d-flex"
        style="align-items: center"
      >
        <div class="search-within-wrapper">
          <SearchWithin namespace="searchService/newResults" />
        </div>
        <span class="mr-2 text-md">Sort by</span>
        <Dropdown
          :value="selectedSortBy"
          :options="sortByOptions"
          @input="handleSortByChange"
        ></Dropdown>
        <div class="display-buttons-wrapper">
          <ToggleButton
            icon
            value="standard"
            :options="[
              { text: 'th-large', value: 'standard' },
              { text: 'list', value: 'table' }
            ]"
            v-model="display"
          ></ToggleButton>
        </div>
      </div>
    </div>

    <!-- standard-results / compact results -->
    <template v-if="!hasResult">
      <div class="no-result">
        <font-awesome-icon
          class="icon no-result__icon"
          :icon="['fal', 'file']"
          size="1x"
        />
        <p class="no-result__text">No new results were found</p>
      </div>
    </template>

    <!-- Standard display -->
    <template v-if="hasResult && display === 'standard'">
      <standard-results
        :searchResults="currentSearchResults__documents"
        v-on="$listeners"
        :isNew="true"
      />
    </template>

    <!-- Table display -->
    <template v-if="hasResult && display === 'table'">
      <compact-results
        :searchResults="currentSearchResults__documents"
        v-on="$listeners"
        :isNew="true"
      />
    </template>

    <!-- pagination -->
    <div
      v-if="currentSearchResults.total"
      class="pagination-wrapper"
    >
      <div class="pagination-desktop">
        <Pagination
          :total-items="currentSearchResults.total"
          :max-visible-pages="5"
          :page="offsetPage"
          :loading="false"
          :items-per-page="itemsPerPage"
          @page-change="handlePageChange"
          @items-per-page-change="handleItemsPerPageChange"
        />
      </div>
      <!-- Use simple pagination as work around to make pagination mobile friendly -->
      <div class="pagination-mobile">
        <SimplePagination
          :total-items="currentSearchResults.total"
          :page="offsetPage"
          :loading="false"
          :page-size="itemsPerPage"
          @page-change="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SearchServiceTypes from '../../../constants/search-service-constants';
import SearchWithin from '../../search-within/SearchWithin';
import CompactResults from './CompactResults';
import StandardResults from './StandardResults';
import AppliedFilters from '../../applied-filters/AppliedFilters';

export default {
  components: {
    SearchWithin,
    CompactResults,
    StandardResults,
    AppliedFilters,
  },
  data () {
    return {
      display: SearchServiceTypes.RESULT_DISPLAY_MODE.STANDARD,
      sortByOptions: SearchServiceTypes.SORT_BY_OPTIONS,
    };
  },
  computed: {
    ...mapGetters('searchService/newResults', [
      'criteriaId',
      'currentSearchResults',
      'currentSearchResults__documents',
      'currentPage',
      'itemsPerPage',
      'allSourceDocCount',
      'searchCategories',
    ]),
    ...mapGetters('searchService/newResults', {
      currentAppliedSort: 'appliedSort',
      currentSelectedSource: 'selectedSource',
    }),
    ...mapGetters({
      isAdminUser: 'authService/isAdminUser',
    }),
    shouldDisableSearchCategory () {
      const count = this.searchCategoryOptions.length;
      return count < 2;
    },
    hasResult () {
      return Boolean(
        this.currentSearchResults__documents
        && this.currentSearchResults__documents.length,
      );
    },
    selectedSortBy () {
      const appliedSort = this.currentAppliedSort;
      const foundOption = SearchServiceTypes.SORT_BY_OPTIONS
        .find(({ parameter }) => (
          parameter.field === appliedSort.field
          && parameter.order === appliedSort.order
        )) || { value: 'relevance' };

      return foundOption.value;
    },
    selectedCategory () {
      if (!this.searchCategoryOptions || !this.currentSelectedSource) {
        return 'all';
      }
      const [source] = this.currentSelectedSource;
      return this.searchCategoryOptions.find(option => option.value === source);
    },
    searchCategoryOptions () {
      if (!this.searchCategories) return [];

      const searchCategories = this.searchCategories.map(item => ({
        text: `${item.key} (${item.docCount})`,
        value: item.key,
      }));
      return [{ value: 'all', text: `All sources (${this.allSourceDocCount})` }].concat(searchCategories);
    },
    offsetPage () {
      return this.currentPage - 1;
    },
    searchResultLabel () {
      const resultLabel = this.currentSearchResults.total === 1 ? 'result' : 'results';
      return ` new ${resultLabel} found in`;
    },
  },
  methods: {
    ...mapActions('searchService/newResults', [
      'executeSearchNewQuery',
      'executeCategoriesNew',
      'setAppliedSort',
      'setCurrentSearchMode',
      'setSelectedSource',
      'setCurrentPage',
      'setItemsPerPage',
      'triggerSearchQuery',
      'triggerSearchRefresh',
    ]),

    handleSortByChange (value) {
      const nextSortBy = SearchServiceTypes.SORT_BY_OPTIONS
        .find(option => option.value === value);

      this.setAppliedSort(nextSortBy.parameter);
      this.setCurrentSearchMode(SearchServiceTypes.SEARCH_MODE.SOURCE);
      this.executeSearchNewQuery();
      this.executeCategoriesNew();
    },

    handleSelectedCategoryChange (value) {
      const nextCategory = value === 'all'
        ? undefined
        : [value];

      this.setSelectedSource(nextCategory);
      this.setCurrentSearchMode(SearchServiceTypes.SEARCH_MODE.SOURCE);
      this.executeSearchNewQuery();
      this.executeCategoriesNew();
    },

    handlePageChange (page) {
      this.setCurrentPage(page + 1);
      this.executeSearchNewQuery();
      this.executeCategoriesNew();
    },

    handleItemsPerPageChange (number) {
      this.setItemsPerPage(number);
      this.setCurrentPage(1);
      this.executeSearchNewQuery();
      this.executeCategoriesNew();
    },
  },
};
</script>

<style lang="scss" scoped>
.search-result {
  position: relative;

  &/deep/ .category-dropdown {
    .dropdown-toggle {
      padding-right: 20px;
    }
    .dropdown-menu.show {
      width: auto;
    }
  }

  &/deep/ .search-result-card .card {
    margin: 0 -16px;
  }

  .loading-spinner-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: rgba($white-500, 0.9);
    padding-top: 40px;
    text-align: center;
  }
}

.filter-section {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &/deep/ .form-group {
    margin-bottom: 0;
  }

  .search-category {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
}

.display-buttons-wrapper {
  margin-left: $gap-md;

  &/deep/ {
    .t-toggle-button {
      margin-bottom: 0 !important;
    }

    label.btn.focus {
      box-shadow: none !important;
    }
  }
}

@media (min-width: 540px) {
  .filter-section {
    flex-direction: row;

    .search-category {
      margin-bottom: 0;
    }
  }
}

.search-within-wrapper {
  margin-right: $gap-md;
}

.applied-filters-wrapper {
  padding-top: 16px;
}

.keyword-corrections-wrapper {
  padding-top: 20px;
}

.no-result {
  margin-top: 22px;
  text-align: center;
  &/deep/ {
    svg.icon {
      font-size: 32px;
    }
  }

  &__text {
    margin-top: 20px;
    font-size: 20px;
  }
}

.search-table {
  width: 100%;

  tr {
    th {
      font-weight: 500;
      color: $blue-gray-500;
    }

    td {
      color: $blue-gray-400;
    }

    th,
    td {
      padding: $gap-lg $gap-xs;
    }
  }

  thead {
    tr {
      background: $blue-gray-50;

      th {
        font-weight: $weight-semi-bold;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $blue-gray-100;
      &:hover,
      &:active {
        background: $blue-200;
      }

      &/deep/ .btn-link {
        color: $blue-400 !important;
      }
    }
  }
}
.pagination-wrapper {
  padding: $gap-lg 0;

  .pagination-desktop {
    display: none;
  }

  .pagination-mobile {
    display: block;
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: 768px) {
    .pagination-desktop {
      display: block;
    }

    .pagination-mobile {
      display: none;
    }
  }
}
</style>
