import {
  createTable, getClassFilter, populateNodesColorClass,
  populateEdgesColorLabel, updateHistoryDropdown, getClassAndGraphFromType,
} from '@/modules/ontology-search/ontology-search-helpers';

export default {
  async constructNetwork ({ commit, state, dispatch }, {
    inputIndividual, individualId, showRelationName,
  }) {
    commit('advancedSearch/setInputIndividual', inputIndividual, { root: true });
    commit('advancedSearch/setIndividualId', individualId, { root: true });

    await dispatch('kmService/getRelations', individualId.toString(), { root: true }).then((response) => {
      const { nodes } = response;
      const { edges } = response;
      populateNodesColorClass(nodes);
      populateEdgesColorLabel(edges, showRelationName);
      dispatch('updateOntologyMap', nodes);
      dispatch('updateRelations', edges);

      const currentTable = nodes.length > 1 ? createTable(nodes, edges, showRelationName) : [];
      commit('setTable', currentTable);
      const classFilters = getClassFilter(currentTable);
      commit('setClassFilters', classFilters);

      const isMoreThanMaxEdges = edges.length > state.maxEdges;
      const displayedNodes = isMoreThanMaxEdges ? nodes.slice(0, 21) : nodes;
      const displayedEdges = isMoreThanMaxEdges ? edges.slice(0, 20) : edges;
      commit('setDisplayedNodes', displayedNodes);
      commit('setDisplayedEdges', displayedEdges);
      commit('setNodes', nodes);
      commit('setEdges', edges);
    }).catch((err) => { });
  },
  handleSelectElementHistory ({
    commit, state, dispatch, rootState,
  }, { index, showRelationName }) {
    commit('advancedSearch/setIndividualId', state.history[index].id, { root: true });
    commit('advancedSearch/setInputIndividual', state.history[index].individual, { root: true });

    dispatch('constructNetwork', {
      inputIndividual: rootState.advancedSearch.inputIndividual,
      individualId: rootState.advancedSearch.individualId,
      showRelationName,
    });

    const currentHistory = state.history;
    currentHistory.splice(index + 1);
    commit('setHistory', currentHistory);
  },
  handleNodeSelect ({
    commit, state, dispatch, rootState,
  }, { value, showRelationName }) {
    if (value.nodes[0] !== undefined && rootState.advancedSearch.individualId !== value.nodes[0]) {
      const { ontologyMap } = state;
      const indiv = ontologyMap[value.nodes];
      commit('advancedSearch/setInputIndividual', indiv, { root: true });
      commit('advancedSearch/setIndividualId', value.nodes, { root: true });
      const { inputIndividual } = rootState.advancedSearch;
      const { individualId } = rootState.advancedSearch;

      dispatch('updateOntologySearchHistory', { inputIndividual, individualId });
      dispatch('constructNetwork', {
        inputIndividual,
        individualId,
        showRelationName,
      });
    }
  },
  updateOntologySearchHistory ({ commit, state }, { inputIndividual, individualId }) {
    const { maxHistoryLength } = state;
    const { history } = state;
    const newHistoryItem = updateHistoryDropdown(maxHistoryLength, inputIndividual, individualId);
    history.push(newHistoryItem);
    commit('setHistory', history);
  },
  updateRelationsMax ({ commit }, relationsMax) {
    commit('setRelationsMax', relationsMax);
  },
  async getIndividualProperties ({ dispatch, commit }, individualId) {
    const { properties: individualProperties } = await dispatch('kmService/getProperties',
      individualId, { root: true })
      .catch((error) => {});
    commit('setIndividualProperties', individualProperties);
  },
  updateOntologyMap ({ commit }, nodes) {
    const ontologyMap = {};
    nodes.forEach((element) => {
      ontologyMap[element.id] = element.label;
    });
    commit('setOntologyMap', ontologyMap);
  },
  updateSelectedClass ({ commit, state }, { showGraphNameInBadge }) {
    const node = state.nodes[0];
    const currClass = {
      class: node.class,
      color: node.color,
      backgroundColor: node.backgroundColor,
      badgeLabel: showGraphNameInBadge ? getClassAndGraphFromType(node.type) : node.class,
    };
    commit('advancedSearch/setSelectedClass', currClass, { root: true });
  },
  updateRelations ({ commit, state }, edges) {
    const relationsMax = Math.min(state.maxEdges, edges.length);
    commit('setRelationsMax', relationsMax);
    const relationsMin = edges.length > 0 ? 1 : 0;
    commit('setRelationsMin', relationsMin);
  },
  setDisplayedNodesAndEdgesCount ({ commit, state, dispatch }, displayCount) {
    const { nodeCount, edgeCount } = displayCount;
    commit('setDisplayedNodes', state.nodes.slice(0, nodeCount));
    commit('setDisplayedEdges', state.edges.slice(0, edgeCount));
    dispatch('updateRelationsMax', edgeCount);
  },
};
