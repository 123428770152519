<template>
  <div :class="{'faq': useICRSConfig, 'icrs-search-result-section': useICRSConfig}">
    <div v-if="!useICRSConfig">
      <search-result-card
        v-for="(document, $i) in searchResults"
        :key="$i"
        :search-result-document="convertModel(document)"
        :isNew="isNew"
        @open-document-settings="$emit('open-document-settings-modal', document, $event)"
        @open-preview-viewer="$emit('open-preview-modal', document)"
        @trigger-document-metrics="$emit('trigger-document-metrics', document)"
      ></search-result-card>
    </div>
    <ul
      v-if="useICRSConfig"
      class="faq-container"
      style="margin: 0"
    >
      <li
        v-for="(document, $i) in searchResults"
        :key="`${currentQuery}_${document.id}_${$i}`"
        class="is-active"
        style="margin: 0"
      >
        <h4 @click="setActiveDoc(document)">
          <a style="font-weight: bold !important; cursor: pointer;">{{document.title}}</a>
        </h4>
        <search-result-card
          :search-result-document="convertModel(document)"
          :isNew="isNew"
          @open-document-settings="$emit('open-document-settings-modal', document, $event)"
          @open-preview-viewer="$emit('open-preview-modal', document)"
          @trigger-document-metrics="$emit('trigger-document-metrics', document)"
          @show-keywords-help-modal="showKeywordsHelpModal"
          :overallShowAwards="overallShowAwards"
          :overallShowKeywords="overallShowKeywords"
        ></search-result-card>
      </li>
    </ul>
    <ICRSSummarySheet
      modalId="icrsListSummarySheet"
      v-if="useICRSConfig"
      :activeDocTitle="activeDocTitle"
      :activeDocId="activeDocId"
      :activeDoc="activeDoc"
      viewType="listView"
      @reset-active-doc="activeDocId = ''"
    />
    <b-modal
      v-if="useICRSConfig"
      :id="modalIdKeywordsHelp"
      size="xs"
      :centered="true"
      title="Keywords Help"
      :hide-footer="true"
      header-close-content="<i class='fa fa-times-circle' />"
    >
      Clicking on the keyword tags triggers a new search for the selected keyword tag.
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchResultCard from '@/components/search-result-card/SearchResultCard';
import ICRSSummarySheet from '@/modules/icrs-summary-sheet/ICRSSummarySheet.vue';
import SearchResultDocumentModel from '@/services/models/search/search-result-document.model';

export default {
  components: {
    SearchResultCard,
    ICRSSummarySheet,
  },
  props: {
    searchResults: {
      type: Array,
      default: () => [],
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    overallShowAwards: {
      type: Boolean,
      default: false,
    },
    overallShowKeywords: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
      modalIdKeywordsHelp: 'keywords-help-modal',
      activeDocTitle: '',
      activeDocId: '',
      activeDoc: {},
      blankSummarySheet: {
        uuid: '',

        column1: [],
        column2: [],
        column3: [],
        column4: [],
        column5: {},
        column6: [],

        tier1: [],
        tier2: [],
        tier3: [],
        tier4: [],
      },
      activeSummarySheet: {
        uuid: '',

        column1: [],
        column2: [],
        column3: [],
        column4: [],
        column5: {},
        column6: [],

        tier1: [],
        tier2: [],
        tier3: [],
        tier4: [],
      },
      // displayAll: 'Show',
      // displayAwards: 'Show',
      // displayKeywords: 'Show',
      // overallShowAwards: false,
      // overallShowKeywords: false,
    };
  },
  computed: {
    ...mapGetters('searchService', [
      'currentQuery',
    ]),
  },
  methods: {
    convertModel (document) {
      return new SearchResultDocumentModel(document);
    },
    setActiveDoc (doc) {
      this.activeDocTitle = doc.title;
      this.activeDocId = doc.id;
      this.activeDoc = doc;
    },
    // displaySummary (mode, type) {
    //   let newMode = '';

    //   if (mode === 'Show') {
    //     newMode = 'Hide';
    //   } else {
    //     newMode = 'Show';
    //   }

    //   if (type === 'awards') {
    //     this.displayAwards = newMode;
    //     this.overallShowAwards = newMode === 'Hide';

    //     if (this.displayAwards === this.displayKeywords) {
    //       this.displayAll = newMode;
    //     }
    //   } else if (type === 'keywords') {
    //     this.displayKeywords = newMode;
    //     this.overallShowKeywords = newMode === 'Hide';

    //     if (this.displayAwards === this.displayKeywords) {
    //       this.displayAll = newMode;
    //     }
    //   } else {
    //     this.displayAll = newMode;
    //     this.displayAwards = newMode;
    //     this.displayKeywords = newMode;
    //     this.overallShowAwards = newMode === 'Hide';
    //     this.overallShowKeywords = newMode === 'Hide';
    //   }
    // },
    showKeywordsHelpModal () {
      this.$bvModal.show(this.modalIdKeywordsHelp);
    },
  },
};
</script>
<style lang="scss">
.faq .btn-outline-primary {
  margin-right: 5px;
}
</style>
