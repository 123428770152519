import {
  TIMEOUT_MESSAGE, AUTH_ERROR_STATUS, AUTH_UNAUTHORIZED_STATUS, MAX_RETRY_ATTEMPTS,
} from '@/constants/auth-service-constants';
import store from '../../store';
import route from '../../router';
import ConfigService from '../config.service';

const createErrorResponseInterceptor = (httpConfig) => {
  const interceptor = httpConfig.interceptors.response.use(
    (response) => {
      // clear errorConfig
      const responseConfig = response ? response.config : null;

      store.dispatch('authService/resetErrorConfig', { responseConfig });
      return response;
    },
    /**
     * @param {import('axios').AxiosError} error
     */
    (error) => {
      // Reject promise if usual error
      const status = error.response ? error.response.status : null;
      const errorConfig = error.response ? error.response.config : null;
      const retryAttempts = error.response ? error.response.config.headers.RETRY_ATTEMPTS : 0;

      if (status === AUTH_ERROR_STATUS) {
        /*
        * https://stackoverflow.com/a/53294310/12319315
        * When response code is 403, try to refresh the token.
        * Eject the interceptor so it doesn't loop in case of error response
        */

        httpConfig.interceptors.response.eject(interceptor);
        if (ConfigService.getIsADAuth()) {
          if (retryAttempts < MAX_RETRY_ATTEMPTS) {
          // eslint-disable-next-line no-param-reassign
            error.response.config.headers.RETRY_ATTEMPTS = retryAttempts + 1;
            return store.dispatch('authService/reAuthorize', { errorConfig })
              .then((response) => {
                if (!response) {
                  if (ConfigService.getIsADAuth()) {
                    route.push('/unauthorized-page');
                    return false;
                  }
                  // failed to reauthorize
                  store.dispatch('authService/logout', {
                    message: TIMEOUT_MESSAGE,
                    isForced: true,
                  });
                  return false;
                }

                return httpConfig(errorConfig);
              })
              .catch(reAuthError => Promise.reject(reAuthError))
              .finally(createErrorResponseInterceptor(httpConfig));
          }

          route.push('/unauthorized-page');
        }
        store.dispatch('authService/logout', {
          message: TIMEOUT_MESSAGE,
          isForced: true,
        });
      }
      if (status === AUTH_UNAUTHORIZED_STATUS) {
        if (ConfigService.getIsADAuth()) {
          route.push('/unauthorized-page');
        } else {
          const targetPath = route.currentRoute.fullPath;
          store.dispatch('authService/logout', {
            message: TIMEOUT_MESSAGE,
            targetPath,
            isForced: true,
          });
        }
      }
      return Promise.reject(error);
    },
  );
};

export default createErrorResponseInterceptor;
