<template>
  <div class="icrs-table-container" id="page-table">
    <div class="row icrs-table-header">
      <div class="col-3">Title</div>
      <div class="col-2">Type</div>
      <div class="col-5">Page / Link</div>
      <div class="col-2">Actions</div>
    </div>
    <div class="row icrs-table-content" v-for="(item,index) in listDatas" :key="index">
      <div class="col-3 text-primary icrs-table-title ">
        <a @click.prevent="openLink(item)"
        :style="{'max-width': titleMaxWidth(item)}">
          <SelfTooltip
            :text="item.title"
            :tooltipId="`table-title-${item.id}-${index}`"
            containerId="page-table"/>
        </a>
        <i v-if="item.type !== 'Page'" class="fas fa-external-link-alt"></i>
      </div>
      <div class="col-2">{{item.type}}</div>
      <div class="col-5 d-flex">{{ getPageLinkDisplay(item) }}</div>
      <div class="col-2 icrs-table-actions d-flex">
        <div>
          <i v-if="listDatas && index < listDatas.length - 1" class="fa fa-arrow-down"
           @click="moveDownItem(item, index)" aria-hidden="true"></i>
        </div>
        <div><i v-if="index > 0" class="fa fa-arrow-up" @click="moveUpItem(item, index)" aria-hidden="true"></i></div>
        <div><font-awesome-icon role="button" :icon="['fas', 'pen']" @click="editPageItem(item)"/></div>
        <div><i class="fa fa-trash" aria-hidden="true" @click="deletePageItem(item, index)"></i></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import SelfTooltip from '@/components/selfTooltip/SelfTooltip.vue';

export default {
  name: 'PageTable',
  components: {
    SelfTooltip,
  },
  props: {
    tableLists: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  data () {
    return {
    };
  },
  computed: {
    listDatas () {
      return this.sortByOrder();
    },
  },
  methods: {
    ...mapActions('pageManagement', ['setNewPageCurrent', 'setCheckPage']),
    moveDownItem (item, index) {
      const idPage1 = this.listDatas[index].id;
      const idPage2 = this.listDatas[index + 1].id;
      this.$emit('sorted', { checkPage: item.checkPage, idPage1, idPage2 });
    },
    moveUpItem (item, index) {
      const idPage1 = this.listDatas[index].id;
      const idPage2 = this.listDatas[index - 1].id;
      this.$emit('sorted', { checkPage: item.checkPage, idPage1, idPage2 });
    },
    editPageItem (item) {
      this.setNewPageCurrent(item.id);
      this.setCheckPage(item.checkPage);
      this.$router.push('/page-management/edit-page');
    },
    deletePageItem (item, index) {
      this.$emit('clickDelete', item, index);
    },
    getPageLinkDisplay (item) {
      if (item.type === 'Page') {
        return `Page://${item.title.replaceAll(' ', '_')}`;
      }
      if (item.content.startsWith('https://')) return item.content;
      return `https://${item.content}`;
    },
    sortByOrder () {
      return this.tableLists.sort((a, b) => a.orderInformation - b.orderInformation);
    },
    openLink (item) {
      // const defaultRoute = [
      //   {
      //     title: 'About',
      //     content: '/icrs-about',
      //   },
      //   {
      //     title: 'FAQs',
      //     content: '/icrs-faq',
      //   },
      //   {
      //     title: 'Useful Links',
      //     content: '/icrs-useful-links',
      //   },
      //   {
      //     title: 'Contact us',
      //     content: '/icrs-contact',
      //   },
      //   {
      //     title: 'Link X',
      //     content: 'http://www.lorem.com',
      //   },
      //   {
      //     title: 'Privacy Policy',
      //     content: '/icrs-policy',
      //   },
      //   {
      //     title: 'Terms of Use',
      //     content: '/icrs-terms',
      //   },
      // ];
      // Check if the URL is a local route or an external link
      // route to preview page in a new tab
      if (item.type === 'Page') {
        // Local route: Use Vue Router to resolve the route
        // if (defaultRoute.some(route => item.content.includes(route.content))) {
        //   const url = defaultRoute[defaultRoute.findIndex(route => item.content.includes(route.content))].content;
        //   const routeData = this.$router.resolve({ path: url });
        //   window.open(routeData.href, '_blank');
        // }
        // route to rich text page
        // else {}
        const routeData = this.$router.resolve({ path: `/page-management/preview/${item.id}` });
        window.open(routeData.href, '_blank');
      } else {
        // External link: Open directly in a new tab
        const externalUrl = item.content.startsWith('https://') ? item.content : `https://${item.content}`;
        window.open(externalUrl, '_blank');
      }
    },
    titleMaxWidth (item) {
      if (item.type === 'Link') return 'calc(100% - 16px)';
      return '100%';
    },
  },
};
</script>

<style lang="scss" scoped>

.icrs-table-container{
  .icrs-table-header{
    background-color: #FAF7F7;
    width: 100%;
    margin: 0;
    div {
      font-weight: 600;
      font-size: 16px;
      height: 48px;
      line-height: 48px;
    }
  }

  .icrs-table-content{
    width: 100%;
    margin: 0;
    div {
      font-size: 16px;
      height: 48px;
      line-height: 48px;
      border-bottom: 1px solid #DEE2E6;
      overflow: hidden;
    }

    .icrs-table-title{
      display: flex;
      align-items: center;
      gap: 4px;

      a {
        padding-bottom: 0 !important;
        cursor: pointer;
         text-decoration: underline;
         border:0;

         &:hover{
           text-decoration:none;
           border:0 !important;
         }
       }

      i {
        cursor: pointer;
        &::before{
          width: 16px;
          height: 16px;
          display: block !important;
          font-weight: 600;
          color: #751c24;
        }
      }
    }

    .icrs-table-actions{
      div {
        margin-right: 12px;
        width: 24px;
        font-size: 14px;
        i{
          cursor: pointer;
        }
      }
    }
  }
}

</style>
