<template>
  <div class="d-inline-block">
    <b-button
      class="icon-wrapper"
      variant="link"
      :id="data.id"
      @click="viewDetails"
    >
      <font-awesome-icon
        class='mx-1 mb-1'
        :icon="['fal', 'info-circle']"
      />
    </b-button>

    <b-popover
      ref="viewDetails"
      :target="data.id"
      triggers="click blur"
      customClass="details-popover"
      placement="right"
      boundary="viewport"
      :show.sync="popoverShow"
    >
      <template #title>
        <div class="paragraph font-primary mb-1">{{ relationName }}</div>
        <div class="d-flex flex-row align-items-center">
          <Badge
            pill
            class="font-white mr-1"
            :style="{'background-color': data.backgroundColor, 'color': data.color}"
          >{{ data.class | capitalize }}</Badge>
          <b-button
            variant="link"
            class="p-0"
            @click="onClickIndividual"
          >
            <h1 class="font-secondary label-md text-left popover-body__title">{{ data.subIndividual | capitalize }}</h1>
          </b-button>
        </div>
      </template>
      <template #default>
        <div
          v-if="showPopoverDetails"
          class="font-secondary popover-body__details"
        >
          <div
            v-for="(detail, index) in details"
            :key="index"
            class="label-sm mb-s w-50 class-detail"
          >
            <p>{{ detail.label | capitalize }}</p>
            <span class="value-label">
              <span v-if="!detail.viewMore">
                {{ detail.truncatedValue }}
              </span>
              <span v-else>{{ detail.values[0] }}</span>
              <br />
              <b-button
                v-if="detail.showViewMoreButton"
                class="p-0"
                variant="link"
                @click="toggleViewInformation(index)"
              >
                <div class="font-blue">
                  <span v-if="!detail.viewMore">More</span>
                  <span v-else>Less</span>
                  <font-awesome-icon
                    class="basic-information__view-icon"
                    :icon="!detail.viewMore ? ['fa', 'chevron-right'] : ['fa', 'chevron-up']"
                  />
                </div>
              </b-button>
            </span>
          </div>
        </div>
        <div
          v-else
          class="popover-body__no-results"
        >
          <div class="paragraph font-secondary m-2">
            No details found for "{{ data.subIndividual | capitalize }}"
          </div>
        </div>
      </template>
    </b-popover>
  </div>

</template>
<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import { initialisePropertyData } from '../ontology-search-helpers';

const PROPERTY_VALUE_MAX_CHARACTER = 77;

export default {
  name: 'DetailPopover',
  data () {
    return {
      popoverShow: false,
      details: [],
    };
  },
  props: {
    individualId: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
  },
  watch: {
    individualId (value) {
      if (value) {
        this.popoverShow = false;
      }
    },
  },
  computed: {
    showPopoverDetails () {
      return this.details.length > 0;
    },
    relationName () {
      return `${this.data.individual} - ${this.data.relation} - ${this.data.subIndividual}`;
    },
  },
  filters: {
    capitalize (value) {
      return value.toString().charAt(0).toUpperCase() + value.slice(1);
    },
  },
  methods: {
    ...mapActions('kmService', [
      'getProperties',
    ]),
    onClose () {
      this.popoverShow = false;
    },
    viewDetails () {
      const maxLength = PROPERTY_VALUE_MAX_CHARACTER;
      this.getProperties(this.individualId).then((response) => {
        this.details = response.properties;
        this.details.forEach((data) => {
          data = initialisePropertyData(data, maxLength); // eslint-disable-line
        });
      });
    },
    onClickIndividual () {
      this.$emit('on-click-individual');
    },
    toggleViewInformation (index) {
      const { details } = this;
      details[index].viewMore = !details[index].viewMore;
      Vue.set(details, index, details[index]);
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-wrapper {
  color: $blue-500 !important;
  padding: 0;
}
.details-popover {
  max-width: 650px !important;
  width: 500px;
  padding: $gap-xxs;

  /deep/ .popover-header {
    background-color: $white-500;
    border-bottom: none;
  }
}
/deep/.popover-body {
  overflow-y: scroll;
  max-height: 400px;

  &__title {
    margin: 0;
  }
  &__details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .class-detail {
      display: flex;
      flex-direction: row;
      break-inside: avoid;

      p {
        margin-bottom: 0px;
        word-break: normal;
        width: 40%;
        text-align: left;
      }

      .value-label {
        font-weight: $weight-normal;
        color: $blue-gray-500;
        word-break: normal;
        width: 60%;
        text-align: left;
      }
    }
  }
  &__no-results {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
