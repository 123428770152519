import mutations from '../mutations';
import initialState from '../state';

describe('doc manager vuex mutations test', () => {
  test('setDocumentContent', () => {
    const state = initialState;
    const docContent = {
      id: 'yahoo_supermassive_black_hole_found_wandering_through_space',
      title: 'Supermassive black hole found wandering through space',
      source: 'https://www.yahoo.com/news/rss',
      path: 'https://news.yahoo.com/supermassive-black-hole-found-wandering-153046384.html',
      content: ' | ',
      createdDate: '2021-03-17T04:00:03.274Z',
      lastModifiedDate: '2021-03-17T04:00:03.287Z',
      latest: true,
      roles: [
      ],
      metadata: {
        promote: true,
        promote_to: '9999-12-31T23:59:59.999Z',
        promote_from: '1970-01-01T00:00:00.000Z',
      },
      docFormat: 'html',
      nlpDate: '2021-03-17T04:00:11.977Z',
      connectorId: 1,
      priority: 0,
      manualUpdate: true,
      tags: {
      },
    };

    mutations.setDocumentContent(state, docContent);
    expect(state.documentContent).toBe(docContent);
  });

  test('setAllClass', () => {
    const state = initialState;

    const classes = {
      country: 'Country',
      occupation: 'Occupation',
      shape: 'Shape',
      software: 'Software',
      color: 'Color',
      city: 'City',
      artobject: 'Art Object',
      subject: 'Subject',
      programmes: 'Programmes',
      singheritage: 'SingHeritage',
    };

    mutations.setAllClass(state, classes);
    expect(state.allClass).toBe(classes);
  });

  test('setIndividualClass', () => {
    const state = initialState;

    const individualClasses = {
      software: [
        'Omnitive Platform',
        'Omnitive Studio',
        'Ontology Manager',
      ],
      language: [
        'English',
        'English',
        'Malay',
        'Tamil',
      ],
      technology: [
        'Artificial Intelligence',
      ],
    };

    mutations.setIndividualClass(state, individualClasses);
    expect(state.individualClass).toBe(individualClasses);
  });

  test('setBlacklistedField', () => {
    const state = initialState;

    const fields = ['id', 'appType', 'createdDate', 'lastModifiedDate', 'version', 'latest',
      'roles', 'docFormat', 'sensitive', 'visualization'];

    mutations.setBlacklistedField(state, fields);
    expect(state.blacklistedField).toBe(fields);
  });
});
