<template>
  <div class="tags-container">
    <b-table
      show-empty
      :tbody-tr-class="rowClass"
      :key="isEditable ? 0 : 1"
      :items="tableItems"
      :fields="displayedFields"
      caption-top
    >
      <template v-slot:table-caption>
        <div class="d-flex align-items-center justify-content-between">
          <div
            style="width: 40%;"
            class="d-flex align-item-center"
          >
            <span class="tab-title">Tags</span>
            <span
              id="tags-tooltip"
              class="m-tooltip"
            >
              <font-awesome-icon
                class="question-icon text-secondary align-self-center"
                :icon="['fa', 'question-circle']"
                size="sm"
              />
            </span>
            <b-tooltip
              target="tags-tooltip"
              triggers="hover"
            >
              Populated according to the ontology
            </b-tooltip>
          </div>
          <b-button
            v-if="showCreateButton"
            @click="handleClickAdd"
            class="button justify-self-end"
            variant="primary"
          >+ Create
          </b-button>
        </div>
      </template>

      <template v-slot:cell(topic)="{item: { key }}">
        <div>
          {{ key }}
        </div>
      </template>

      <template v-slot:cell(individuals)="{item: { value }}">
        <div class="d-flex flex-wrap w-100 align-items-center">
          <p
            :key="index"
            class="option-content"
            v-for="(item, index) in value"
          >
            {{ item }}
          </p>
        </div>
      </template>

      <template v-slot:cell(action)="{item: { value, key }, index}">
        <b-dropdown
          v-if="showActionButtons"
          no-caret
          toggle-class="border-0 bg-white hover"
        >
          <template v-slot:button-content>
            <font-awesome-icon
              class="menu-icon text-secondary"
              :icon="['fa', 'ellipsis-h']"
              size="lg"
            />
          </template>
          <b-dropdown-item>
            <div
              @click="handleEdit(index)"
              class="edit d-flex align-items-center"
            >
              <span class="ml-2 action-text">Edit</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div
              @click="handleDelete(index)"
              class="delete d-flex align-items-center"
            >
              <span class="ml-2 action-text">Delete</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template v-slot:empty>
        <span class="empty-table-text">No tags are attached to this document</span>
      </template>
    </b-table>
  </div>
</template>

<script>
// import Multiselect from 'vue-multiselect';

export default {
  props: {
    value: {},
    disableForm: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    haveWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    const { tags = {} } = this.value;
    const tagsKey = Object.keys(tags);

    // const topicItems = [
    //   {
    //     key: 'Topics',
    //     value: tagsKey || [],
    //     action: '',
    //   },
    // ];


    const otherItems = tagsKey.map((item, index) => {
      if (item !== 'topic') {
        return {
          key: item,
          value: tags[item],
          action: '',
        };
      }
      return null;
    }).filter(item => item);

    return {
      selected: [],
      // fields: ['topic', 'individuals', 'actions'],
      fields: [
        {
          key: 'topic',
          class: 'topic-mw',
        },
        {
          key: 'individuals',
          tdClass: 'ie-max-width',
        },
        {
          key: 'action',
          class: 'actions-mw',
        },
      ],
      displayedFields: [],
      options: ['a', 'b', 'c'],
      // topicItems,
      otherItems,
    };
  },
  mounted () {
    this.displayedFields = this.disableForm.TAGS ? this.fields.slice(0, 2) : this.fields;
  },
  methods: {
    rowClass (item, type) {
      let rowClass;
      if (item) {
        if (item.key === 'Topics') {
          rowClass = 'column-topics';
        }
      }
      return rowClass;
    },
    onSelect (value, index) {
      if (index === 0) {
        const lastValue = value[value.length - 1];

        this.topicItems[index].value = value;
        this.otherItems.push({
          key: lastValue,
          value: [],
          action: '',
        });
      } else {
        this.otherItems[index - 1].value = value;
      }
    },
    handleClickAdd () {
      this.$emit('add');
    },
    handleEdit (index) {
      const value = this.otherItems[index];

      this.$emit('edit', value);
    },
    handleDelete (index) {
      const value = this.otherItems[index];
      this.$emit('delete', value);
    },
  },
  computed: {
    tableItems () {
      const { otherItems } = this;
      const tableItems = otherItems;

      return tableItems;
    },
    showCreateButton () {
      return this.haveWriteAccess && !this.disableForm.TAGS;
    },
    showActionButtons () {
      return this.haveWriteAccess && !this.disableForm.TAGS;
    },
  },
};
</script>

<style lang="scss">
#doc-manager {
  #tags {
    @import "../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      .ie-max-width {
        max-width: 10px;
      }
    }

    .tags-container {
      .button {
        float: right;
      }
      .action-text {
        font-size: 14px;
      }
      .action-button {
        cursor: pointer;
        z-index: 2;
        margin-top: 25px;
        border-radius: 5px;
        position: absolute;
        background: $white-500;
        border: 0.5px solid rgba(0, 0, 0, 0.2);
        .edit,
        .delete {
          padding: 0px 10px;
        }
        .edit {
          padding-top: 5px;
          padding-bottom: 2.5px;
        }
        .delete {
          padding-top: 2.5px;
          padding-bottom: 5px;
        }
        span {
          font-size: 12px;
          color: #eb5757;
        }
      }
      .action-button .edit:hover {
        background-color: #007bff;
        color: $white-500;
      }
      .action-button .delete:hover {
        background-color: #eb5757;
        color: $white-500;
      }
      .actions-mw {
        width: 98px;
      }
      .topic-mw {
        width: 24%;
      }
      .column-topics {
        border-bottom: 2px solid $blue-500;
      }
      .option-content {
        padding: 2px 8px;
        height: 20px;
        background: $white-500;
        border: 1px solid $blue-gray-100;
        box-sizing: border-box;
        border-radius: 16px;

        font-style: normal;
        font-weight: $weight-normal;
        font-size: 12px;
        line-height: 16px;

        color: $blue-gray-400;

        margin: 0px;
        // padding: 5px 10px;
        // font-size: 12px;
        // margin: 4px 2px;
        // border-radius: 16px;
        // border: 1px solid $blue-gray-100;
      }
    }
  }
}
.multiselect__tag {
  background: transparent;
  color: black;
  border: 1px solid $blue-gray-100;
}
.multiselect__element {
  margin: 2px;
  border-radius: 2px;
  overflow: hidden;
}
.multiselect__tag-icon:hover {
  background: $white-500;
}
.multiselect__tag-icon:hover::after {
  color: black;
}
.multiselect__tag-icon:after {
  content: "\D7";
  color: black;
  font-size: 14px;
}
.multiselect__option--highlight,
.multiselect__option--highlight::after {
  background: $blue-200;
  color: #35495e;
}
.multiselect__option--selected,
.multiselect__option--selected::after {
  background: $blue-500;
  color: $white-500;
  font-weight: $weight-normal;
  content: "\2713";
}
.multiselect__option--highlight.multiselect__option--selected,
.multiselect__option--highlight.multiselect__option--selected::after {
  background: #eb5757;
  color: $white-500;
  content: "\D7";
}
</style>
