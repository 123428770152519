<template>
  <div class="w-100">
    <div class="text-left graph-view__title">Knowledge graph</div>
    <div class="graph-view rounded border">
      <div class="action-tray d-flex justify-content-between">
        <div
          class="font-secondary"
          v-if="this.edges.length > 0"
        >
          {{ this.relationsMin }} - {{ this.relationsMax }} of {{ this.edges.length }}
          <b-button
            v-if="this.displayedEdges.length < this.edges.length"
            class="relevant-filters"
            variant="link"
            @click="this.onViewMore"
            data-cy="view-all-button"
          >
            View more
          </b-button>
          <b-button
            v-if="this.displayedEdges.length > this.minRelationShown"
            class="relevant-filters"
            variant="link"
            @click="this.onReset"
          >
            Reset
          </b-button>
        </div>
        <div class="font-secondary">
          Scroll up/down in the graph to zoom out / in
        </div>
      </div>
      <network
        class="h-100"
        ref="network"
        :nodes="displayedNodes"
        :edges="displayedEdges"
        :options="options"
        :key="individualId.toString()"
        @click="onNodeClick"
        @hoverEdge="onHoverEdge"
      >
      </network>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { Network } from 'vue2vis';
import 'vue2vis/dist/vue2vis.css';

const RELATION_COUNT_TO_DISPLAY = 20;

export default {
  name: 'OntologySearchGraph',
  components: {
    Network,
  },
  data () {
    return {
      options: {
        autoResize: false,
        height: '100%',
        width: '100%',
        interaction: {
          hover: true,
        },
        physics: {
          enabled: true,
        },
        nodes: {
          shape: 'dot',
          size: 10,
          widthConstraint: {
            maximum: 270,
          },
        },
        edges: {
          smooth: false,
          arrows: {
            to: {
              scaleFactor: 1,
            },
          },
        },
      },
      config: {
        showRelationName: false,
        showRelationArrow: true,
        showGraphNameInBadge: false,
      },
      minRelationShown: RELATION_COUNT_TO_DISPLAY,
    };
  },
  mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
    this.constructGraph();
  },
  computed: {
    ...mapGetters('advancedSearch', [
      'inputIndividual',
      'individualId',
    ]),
    ...mapGetters('advancedSearch/ontologySearch', [
      'relationsMin',
      'relationsMax',
      'nodes',
      'edges',
      'displayedNodes',
      'displayedEdges',
    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    individualId: _.debounce(function (value, oldValue) {
      return this.constructGraph();
    }, 200),
  },
  methods: {
    ...mapActions('advancedSearch/ontologySearch', [
      'handleNodeSelect',
      'constructNetwork',
      'updateRelationsMax',
      'updateSelectedClass',
      'setDisplayedNodesAndEdgesCount',
    ]),
    async constructGraph () {
      await this.constructNetwork({
        inputIndividual: this.inputIndividual,
        individualId: this.individualId,
        showRelationName: this.config.showRelationName,
      });
      this.$nextTick(() => {
        this.updateSelectedClass({ showGraphNameInBadge: this.config.showGraphNameInBadge });
        this.setGraphSize();
        this.setGraphConfigurations();
      });
    },
    setGraphSize () {
      this.$refs.network.fit(this.displayedNodes);
      this.$refs.network.moveTo({
        scale: 0.75,
      });
    },
    setGraphConfigurations () {
      if (!this.config.showRelationArrow) {
        this.options.edges.arrows.to.scaleFactor = -1;
      }
      const curr = this;
      this.$refs.network.$on('stabilized', () => {
        curr.setPhysicsConfigs(false);
      });
    },
    setPhysicsConfigs (physicsEnabled) {
      this.$refs.network.setOptions({
        physics: physicsEnabled,
      });
    },
    onNodeClick (value) {
      document.getElementById('app').click();
      this.handleNodeSelect({
        value,
        showRelationName: this.config.showRelationName,
      });
    },
    onHoverEdge (edgeID) {
      return this.edges[edgeID];
    },
    refreshGraph () {
      this.setPhysicsConfigs(true);
      this.setGraphSize();
      this.setGraphConfigurations();
    },
    onViewMore () {
      const nodeCount = Math.min(this.displayedNodes.length + this.minRelationShown, this.nodes.length);
      const edgeCount = Math.min(this.displayedEdges.length + this.minRelationShown, this.edges.length);
      this.setDisplayedNodesAndEdgesCount({ nodeCount, edgeCount });
      this.refreshGraph();
    },
    onReset () {
      const nodeCount = this.minRelationShown + 1; // including central node
      const edgeCount = this.minRelationShown;
      this.setDisplayedNodesAndEdgesCount({ nodeCount, edgeCount });
      this.refreshGraph();
    },
  },
};
</script>
<style lang="scss" scoped>
.graph-view {
  background: $white-500;
  height: 100%;
  position: relative;

  &__title {
    color: $blue-500;
    font-weight: $weight-semi-bold;
    font-size: $font-lg;
    margin-bottom: 0.5em;
  }
}
.relevant-filters {
  color: $blue-500 !important;
}
.action-tray {
  width: 100%;
  z-index: 9;
  padding: 0.5em 1em;
  .font-secondary {
    margin: auto 0;
  }
}
/deep/ .vis-tooltip {
  background-color: $blue-gray-600 !important;
  color: $white-500 !important;
  border: $blue-gray-600;
  max-width: 15rem;
  white-space: normal !important;
}
</style>
