import Vue from 'vue';
import defaultState from './state';

export default {
  setPromotedFilterEnable (state, promotedFilterEnable) {
    Vue.set(state, 'promotedFilterEnable', promotedFilterEnable);
  },
  setPromotedDateRangeMode (state, dateRangeMode) {
    Vue.set(state, 'promotedByDateRangeMode', dateRangeMode);
  },
  setPromoteDateObject (state, promoteDateObject) {
    Vue.set(state, 'promoteDateObject', promoteDateObject);
  },
  resetPromotedContent (state) {
    Object.assign(state, defaultState());
  },
};
