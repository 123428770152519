<template>
  <div class="moduleStyle">
    <div class="content">
      <div class="topBar">
        <div class="topic">
          <slot name="topic"></slot>
        </div>
      </div>
      <div class="markdownStyle">
        <slot name="markdown"></slot>
      </div>
      <div class="display">
        <div
          class="displayWrap clickable"
          v-for="(widget, index) in overviewRoutes"
          :key="index"
          @click="goToRoute(widget)"
        >
          <div class="displayTitle">{{widget.label}}</div>
          <div class="displayComponentWrap">
            <div class="displayComponent">
              <div>
                <img
                  class="img"
                  :src="getImgSrc(widget.label)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-dynamic-require, global-require */

export default {
  name: 'Module',
  props: {
    overviewRoutes: {
      type: Array,
      default () {
        return {};
      },
    },
  },
  methods: {
    goToRoute (item) {
      this.$router.push(item.path).catch((err) => { });
    },
    getImgSrc (label) {
      try {
        return require(`@/assets/ui-catalog-page/${label.replace(
          /\s/g,
          '',
        )}.png`);
      } catch (e) {
        return require('@/assets/ui-catalog-page/UnderDevelop2.png');
      }
    },
  },
};
</script>


<style scoped lang="scss">
@import "../markdown.scss";
.topBar {
  height: 152px;
  background: $blue-200;
}
.topic {
  padding: 60px 100px;
  font-size: 36px;
  font-weight: $weight-normal;
}
.display {
  margin-top: $gap-xl;
  margin-left: 100px;
  width: 820px;
  overflow: hidden;
}
.displayWrap {
  width: 410px;
  height: 410px;
  border: 1px solid $blue-gray-100;
  float: left;
}
.displayTitle {
  font-size: $font-lg;
  line-height: $ln-height-lg;
  padding-left: $gap-xl;
  padding-top: $gap-lg;
}
.displayComponentWrap {
  margin-top: 20%;
  text-align: center;
}
.displayComponent {
  display: inline-block;
  height: 410px;
  width: 380px;
}
.placeholderText {
  padding: 10px 0;
}
.img {
  width: 100%;
  height: 100%;
}
.clickable {
  cursor: pointer;
}
</style>
