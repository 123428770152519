<template>
  <b-modal
    centered
    :id="id"
    :size="size"
    :title="title"
    :static="true"
    :lazy="true"
    @hide="onCloseModal"
    header-class="border-0"
    footer-class="border-0"
    no-close-on-backdrop
  >
    <template slot="modal-footer">
      <b-btn
        @click="handleCancel"
        class="confirm-btn mr-2 pl-4 pr-4"
        variant="link"
      >
        Cancel
      </b-btn>
      <b-btn
        :disabled="disableSubmit"
        @click="handleSave"
        class="confirm-btn ml-2 pl-4 pr-4"
        variant="primary"
      >
        Save
      </b-btn>
    </template>
    <div>
      <div>
        <label class="font-weight-bold">Key</label>
        <validation-provider rules="doc-required" v-slot="{ errors }">
          <b-form-input
            v-model="keyModel"
            v-bind:class="errors[0] || isKeyDuplicated ? 'is-invalid' : ''"
            placeholder="Enter the key here..."
          />
          <span class="form-error">
            {{
              isKeyDuplicated
                ? "The existing value with this key will be replaced"
                : errors[0] && "The metadata key cannot be empty"
            }}
          </span>
        </validation-provider>
      </div>
      <div class="mt-3">
        <label class="font-weight-bold">Value</label>
        <b-form-input
          v-model="valueModel"
          placeholder="Enter the value here..."
        ></b-form-input>
      </div>
    </div>
  </b-modal>
</template>

<script>
import _ from 'lodash';
import { ValidationProvider } from 'vee-validate';


export default {
  name: 'add-metadata',
  props: {
    selectedMetadata: {
      type: Object,
      default: () => ({
        key: '',
        value: '',
      }),
    },
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
    allMetadata: {
      type: Array,
      default: () => ([]),
    },
  },
  components: {
    ValidationProvider,
  },
  data () {
    const { key = '', value = '' } = this.selectedMetadata;
    return {
      keys: key,
      values: value,
      isKeyDuplicated: false,
    };
  },
  methods: {
    onCloseModal () {
      this.classModel = '';
      this.individualModel = [];
      this.$emit('closed');
    },
    handleCancel () {
      this.$bvModal.hide(this.id);
      this.$emit('cancel');
    },
    handleSave () {
      const { keys, values } = this;
      const metadata = {
        key: keys,
        value: values,
      };
      this.$emit('submit', metadata);
    },
  },
  computed: {
    disableSubmit () {
      if (this.keyModel) {
        return false;
      }
      return true;
    },
    keyModel: {
      get () {
        return this.keys;
      },
      set (value) {
        const isKeyDuplicated = _.includes(this.allMetadata, value);
        if (!this.selectedMetadata.keys) {
          if (isKeyDuplicated) this.isKeyDuplicated = isKeyDuplicated;
          else this.isKeyDuplicated = false;
        }
        this.keys = value;
      },
    },
    valueModel: {
      get () {
        return this.values;
      },
      set (value) {
        this.values = value;
      },
    },
  },
};
</script>
