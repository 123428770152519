<template>
  <b-card-body body-bg-variant="blue-gray-100_alpha">
    <b-card-title>Crawling</b-card-title>

    <b-list-group>
      <b-card-text>Crawl various data sources in configurable time intervals to
        parse and annotate documents.</b-card-text>
      <b-card-text>
        Start crawling a data source by creating a
        <strong>New Connector</strong>.
      </b-card-text>

      <redirect-button :label="connectorWizardLabel" :pathLink="connectorWizardPath" :variant="variant">
      </redirect-button>
    </b-list-group>

    <br />

    <b-list-group>
      <b-card-text>Manage connectors by editing its properties or update the configuration of
        its subprocesses:</b-card-text>
      <b-card-text id="iconTextGroup">
        <icon-text faType="fal" faName="cookie-bite" text="Ingest"></icon-text>
        <icon-text faType="fal" faName="sync-alt" text="Sync"></icon-text>
        <icon-text faType="fal" faName="tags" text="Postprocessing"></icon-text>
      </b-card-text>
      <b-card-text>
        View connectors in
        <strong>Connector Dashboard</strong>.
      </b-card-text>

      <redirect-button :label="connectorDashboardLabel" :pathLink="connectorDashboardPath" :variant="variant">
      </redirect-button>
    </b-list-group>

    <right-arrow-svg class="floating_arrow" />
  </b-card-body>
</template>

<script>
import IconText from '@/components/IconText.vue';
import rightArrowSvg from '@/assets/admin-page/icons/circle-right-arrow.svg';
import redirectButton from './RedirectionButton.vue';

export default {
  components: {
    redirectButton,
    IconText,
    rightArrowSvg,
  },
  data () {
    return {
      connectorWizardLabel: 'Connector Wizard',
      connectorWizardPath: '/connector/wizard',
      connectorDashboardLabel: 'Connector Dashboard',
      connectorDashboardPath: '/connector/dashboard',
    };
  },
  props: {
    variant: String,
  },
};
</script>

<style scoped lang="scss">
#iconTextGroup {
  margin-left: auto;
  margin-right: auto;
}
</style>
