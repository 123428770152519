import searchAdminService from '@/services/search-admin.service';
import EventBusService from '@/services/event-bus.service';

export default {
  async getSearchIndexes ({ commit }) {
    const indexes = await searchAdminService.getSearchIndexes();
    commit('setSearchIndexes', indexes);
    return indexes;
  },

  async getActiveSearchIndex ({ commit }) {
    const activeSearchIndex = await searchAdminService.getActiveSearchIndex();
    commit('setActiveSearchIndex', activeSearchIndex);
    return activeSearchIndex;
  },

  async saveActiveSearchIndex ({ dispatch }, searchIndex) {
    const resp = await searchAdminService.saveActiveSearchIndex(searchIndex).catch((e) => {
      EventBusService.emitNotificationEvent({ title: 'Update Failed', variant: 'danger' });
      throw e;
    });
    EventBusService.emitNotificationEvent({ title: 'Search Configuration Updated' });
    dispatch('getActiveSearchIndex');
    return resp;
  },
};
