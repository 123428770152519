const CSRF_TOKEN_UI = 'CSRF-TOKEN-UI';
const USER_MODEL = 'userModel';
const MAX_RETRY_ATTEMPTS = 3;

const TIMEOUT_MESSAGE = 'Session timed out. Please sign in again.';
const SUCCESS_MESSAGE = 'Successfully logged in!';
const FAIL_MESSAGE = 'You entered an incorrect username or password.';
const SAML_LOGIN_MESSAGE = 'Please login.';
const AUTH_ERROR_STATUS = 403;
const AUTH_UNAUTHORIZED_STATUS = 401;
const AUTH_AAD_UNAUTHORIZED_STATUS = 511;

const SET_CSRF_TOKEN = 'set-csrf-token';
const USER_OBJECT_TOKEN = 'USER-OBJECT';
const X_CSRF_TOKEN = 'X-CSRF-TOKEN';

export {
  CSRF_TOKEN_UI,
  USER_MODEL,
  MAX_RETRY_ATTEMPTS,
  TIMEOUT_MESSAGE,
  SUCCESS_MESSAGE,
  FAIL_MESSAGE,
  AUTH_ERROR_STATUS,
  AUTH_UNAUTHORIZED_STATUS,
  AUTH_AAD_UNAUTHORIZED_STATUS,
  SET_CSRF_TOKEN,
  USER_OBJECT_TOKEN,
  X_CSRF_TOKEN,
  SAML_LOGIN_MESSAGE,
};
