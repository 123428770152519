exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"badgePurple100": "rgba(194,11,218,.1)",
	"badgePurple200": "rgba(138,11,157,.1)",
	"badgePurple300": "rgba(85,7,95,.1)",
	"badgePink100": "rgba(218,11,110,.1)",
	"badgePink200": "rgba(170,9,87,.1)",
	"badgePink300": "rgba(101,6,52,.1)",
	"badgeBrown100": "rgba(166,102,8,.1)",
	"badgeBrown200": "rgba(122,75,6,.1)",
	"badgeBrown300": "rgba(88,54,4,.1)",
	"badgeYellow100": "rgba(142,113,6,.1)",
	"badgeYellow200": "rgba(86,69,6,.1)",
	"badgeYellow300": "rgba(50,42,6,.1)",
	"badgeGreen100": "rgba(33,131,69,.1)",
	"badgeGreen200": "rgba(26,97,54,.1)",
	"badgeGreen300": "rgba(17,65,34,.1)",
	"badgeDullGreen100": "rgba(78,111,6,.1)",
	"badgeDullGreen200": "rgba(78,95,7,.1)",
	"badgeDullGreen300": "rgba(46,60,6,.1)",
	"badgeBlueGreen100": "rgba(56,128,123,.1)",
	"badgeBlueGreen200": "rgba(38,84,84,.1)",
	"badgeBlueGreen300": "rgba(24,52,52,.1)",
	"badgeBlue100": "rgba(67,122,152,.1)",
	"badgeBlue200": "rgba(51,94,117,.1)",
	"badgeBlue300": "rgba(44,75,94,.1)",
	"badgeBluePurple100": "rgba(115,98,223,.1)",
	"badgeBluePurple200": "rgba(70,47,212,.1)",
	"badgeBluePurple300": "rgba(45,29,144,.1)"
};