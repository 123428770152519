<template>
  <div
    v-if="!useICRSConfig || icrsDisplayToggle"
    class="filter-group"
  >
    <div
      class="filter-group__item"
      v-b-toggle="accordionId"
      role="tab"
    >
      <span
        class="filter-group-name"
        :class="{
          'filter-group-name--filter-applied': filtersAppliedCount || icrsRangeApplied
        }"
      >
        {{ displayName }}
        <template v-if="!useICRSConfig && filtersAppliedCount">({{ filtersAppliedCount }})</template>
        <template v-if="useICRSConfig && filtersAppliedCount"><br />({{ filtersAppliedCount }} Selected)</template>
        <template v-if="useICRSConfig && !filtersAppliedCount && !icrsRangeApplied && !group.displayName.includes('Salary')"><br />(All Selected)</template>
        <template v-if="useICRSConfig && !filtersAppliedCount && !icrsRangeApplied && group.displayName.includes('Salary')"><br />(Salary in SGD only)</template>
        <template v-if="useICRSConfig && icrsRangeApplied"><br />(Range Selected)</template>
      </span>
      <icon-text
        class="when-opened icrs-filter-icon"
        faType="fas"
        faName="chevron-up"
        size="sm"
      ></icon-text>
      <icon-text
        class="when-closed icrs-filter-icon"
        faType="fas"
        faName="chevron-down"
        size="sm"
      ></icon-text>
    </div>
    <b-collapse
      :visible="isLocalOpen"
      @input="handleCollapseInput"
      :id="accordionId"
      role="tabpanel"
    >
      <div
        v-if="containsAnnual || containsAdHoc"
        class="btn-group btn-group-toggle icrs-salary-toggle"
      >
        <button
          class="btn btn-small icrs-salary-button"
          :class="{
            'btn-primary': containsAnnual,
            'btn-outline-primary': !containsAnnual
          }"
          @click="handleSalarySelect('Annual')"
        >
          Annual
        </button>
        <button
          class="btn btn-small icrs-salary-button"
          :class="{
            'btn-primary': containsAdHoc,
            'btn-outline-primary': !containsAdHoc
          }"
          @click="handleSalarySelect('Ad-Hoc')"
        >
          Ad-Hoc
        </button>
      </div>
      <div v-if="useICRSConfig">
        <button
          v-if="filtersAppliedCount || icrsRangeApplied"
          class="btn btn-clear-individual-filter btn-small icrs-clear-individual-button"
          @click="$emit('icrs-clear-filters', group.name)"
          :disabled="!filtersAppliedCount && !icrsRangeApplied"
        >Clear Filter</button>
      </div>
      <div
        class="text-md"
        style="font-weight: normal"
        v-if="useICRSConfig && itemsCount === 0"
      >No possible results in current search for {{group.displayName}}</div>
      <div
        class="text-md"
        style="font-weight: normal"
        v-if="useICRSConfig && showNumericErrorMessage"
      >Please key in a suitable range. The top value should be smaller than or equal to the bottom value.</div>
      <div
        class="text-md"
        style="font-weight: normal"
        v-if="useICRSConfig && showFloatErrorMessage && group.displayName === 'Amount Awarded for Pain and Suffering'"
      >Please enter whole numbers.</div>
      <div
        :class="{'filter-checklist': !useICRSConfig, 'icrs-check-filter': useICRSConfig}"
        v-if="!useICRSConfig || ((icrsFilterType == 'checkbox' || icrsFilterType == 'dropdown') && itemsCount > 0)"
      >
        <FilterItem
          v-for="item in limitedItems"
          :key="item.key"
          :item="item"
          :applied="appliedFilters.includes(
            createFilterKey(item.key, group.name)
          )"
          :group-name="group.name"
          @change="$emit('filter-change', $event)"
          :filterCountDisabled="!isUATDebug"
        />
        <template v-if="itemsCount > 5">
          <button
            @click="$emit('show-more', group)"
            :type="{'button': useICRSConfig}"
            :class="{
              'show-more-btn': !useICRSConfig,
              'btn btn-filter-show-more btn-small icrs-show-more': useICRSConfig
            }"
          >
            Show More Options
            <template v-if="invisibleAppliedFiltersCount">
              ({{ invisibleAppliedFiltersCount }})
            </template>
          </button>
        </template>
      </div>
      <div
        class="icrs-date-filter"
        v-if="useICRSConfig && icrsFilterType == 'date'  && displayOthers"
      >
        <b-form-datepicker
          :max="maxRange"
          :id="group.name + '_start_datepicker'"
          v-model="rangeStart"
          size="sm"
          placeholder="e.g 01/01/2021"
          :show-decade-nav="true"
          :no-highlight-today="true"
          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          locale="en-SG"
          button-variant="primary"
          nav-button-variant="primary"
        ></b-form-datepicker>
        <div class="icrs-range-to">to</div>
        <b-form-datepicker
          :min="minRange"
          class="mb-2"
          :id="group.name + '_end_datepicker'"
          v-model="rangeEnd"
          size="sm"
          placeholder="e.g 20/01/2021"
          :show-decade-nav="true"
          :no-highlight-today="true"
          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          locale="en-SG"
          button-variant="primary"
          nav-button-variant="primary"
        ></b-form-datepicker>
      </div>
      <div
        class="icrs-numeric-filter"
        v-if="useICRSConfig && icrsFilterType == 'numeric' && displayOthers && itemsCount > 0"
      >
        <b-input-group
          size="sm"
          prepend="$"
        >
          <b-form-input
            type="number"
            inputmode="numeric"
            :id="group.name + '_start_num_input'"
            v-model="rangeStart"
            size="sm"
            placeholder="e.g 10000"
            @focus="rangeChangeInProgress = true"
            @blur="rangeChangeInProgress = false"
            @paste="pasteOnlyNumbers($event)"
            @keydown="allowOnlyNumbers"
          ></b-form-input>
        </b-input-group>
        <div class="icrs-range-to">to</div>
        <b-input-group
          size="sm"
          prepend="$"
        >
          <b-form-input
            type="number"
            inputmode="numeric"
            :id="group.name + '_end_num_input'"
            v-model="rangeEnd"
            size="sm"
            placeholder="e.g 20000"
            @focus="rangeChangeInProgress = true"
            @blur="rangeChangeInProgress = false"
            @paste="pasteOnlyNumbers($event)"
            @keydown="allowOnlyNumbers"
          ></b-form-input>
        </b-input-group>
      </div>
      <div
        class="icrs-slider-filter"
        v-if="useICRSConfig && icrsFilterType == 'slider' && displayOthers"
      >
        <veeno
          connect
          :tooltips="[true, true]"
          :handles="[sliderStart, sliderEnd]"
          :range="{
            'min': minSlider,
            'max': maxSlider
          }"
          :step="1"
          @change="handleSliderUpdate"
          @update="showSliderText = true"
          :getset="getSliderObject"
        />
        <p
          v-if="showSliderText"
          class="slider-text"
        >Range: {{sliderStart}} to {{sliderEnd}}</p>
        <p
          v-if="!showSliderText"
          class="dummy-slider-text"
        ></p>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import veeno from 'veeno';
import '@/components/distribute/nouislider.min.css';
import FilterItem from './FilterItem.vue';
import IconText from '../../components/IconText.vue';

export default {
  components: {
    IconText,
    FilterItem,
    veeno,
  },

  props: {
    group: Object,
    appliedFilters: {
      type: Array,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    icrsFilterType: {
      type: String,
      default: 'dropdown',
    },
    icrsAssessmentSalaryView: {
      type: String,
      default: 'Annual',
    },
    icrsAccidentSalaryView: {
      type: String,
      default: 'Annual',
    },
    displayOthers: Boolean,
  },

  data () {
    return {
      isAccordionOpen: false,
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
      isLocalOpen: true,
      rangeStart: '',
      rangeEnd: '',
      rangeStartTemp: '',
      rangeEndTemp: '',
      rangeChangeInProgress: false,
      showNumericErrorMessage: false,
      showFloatErrorMessage: false,
      icrsRangeApplied: false,
      sliderStart: 0,
      sliderEnd: 100,
      minSlider: 0,
      maxSlider: 100,
      sliderObject: undefined,
      showSliderText: false,
      syncingActiveRangeStart: false,
      syncingActiveRangeEnd: false,
    };
  },

  updated () {
    if (this.isAccordionOpen) {
      this.isAccordionOpen = false;
    }
  },

  computed: {
    ...mapGetters('searchService', [
      'currentQuery',
      'topicsMust',
    ]),
    accordionId () {
      return `accordion-${this.group.name}`;
    },

    itemsCount () {
      return this.group.items.length;
    },

    limitedItems () {
      return this.group.items.slice(0, 5);
    },

    availabelFilterKeys () {
      return this.group.items.map(item => (
        this.createFilterKey(item.key, this.group.name)));
    },

    filtersApplied () {
      return this.appliedFilters
        .filter(key => this.availabelFilterKeys.includes(key));
    },

    filtersAppliedCount () {
      return this.filtersApplied.length;
    },

    invisibleAppliedFiltersCount () {
      const visibleAppliedFilterKeys = this.limitedItems.map(
        filter => this.createFilterKey(filter.key, this.group.name),
      );
      const visibleAppliedFilterCount = this.filtersApplied
        .filter(key => visibleAppliedFilterKeys.includes(key))
        .length;

      return this.filtersAppliedCount - visibleAppliedFilterCount;
    },

    containsAnnual () {
      return this.group.displayName.includes('Annual');
    },

    containsAdHoc () {
      return this.group.displayName.includes('Ad-Hoc');
    },

    icrsDisplayToggle () {
      if (this.group.displayName.includes('Salary')) {
        if (this.group.displayName.includes('Assessment')) {
          return this.group.displayName.includes(this.icrsAssessmentSalaryView);
        }
        if (this.group.displayName.includes('Accident')) {
          return this.group.displayName.includes(this.icrsAccidentSalaryView);
        }
      }
      return this.useICRSConfig;
    },
    maxRange () {
      if (this.rangeEnd === '') {
        return null;
      }
      return this.rangeEnd;
    },
    minRange () {
      if (this.rangeStart === '') {
        return null;
      }
      return this.rangeStart;
    },
    isUATDebug () {
      if (this.$route.query.debug === '1') {
        return true;
      }
      return false;
    },
    displayName () {
      const temp = this.group.displayName.split(') (');
      if (temp.length > 1) {
        return `${temp[0]})`
      }
      return this.group.displayName
    }
  },
  methods: {
    ...mapActions('searchService', [
      'setIsMobileOveralOpen',
    ]),
    handleCollapseInput (isOpen) {
      this.$emit('toggle-open', {
        isOpen,
        groupName: this.group.name,
      });
    },
    createFilterKey (key, groupName) {
      return `${groupName}_${key.split(' ; ').length === 2 ? key.split(' ; ')[0] : key}`;
    },
    handleSalarySelect (view) {
      if (this.group.displayName.includes('Assessment')) {
        this.$emit('icrs-toggle-assessment-salary', view);
      }
      if (this.group.displayName.includes('Accident')) {
        this.$emit('icrs-toggle-accident-salary', view);
      }
    },
    handleSliderUpdate (sliderObject) {
      const [newSliderStart, newSliderEnd] = sliderObject.values;
      this.sliderStart = Number(newSliderStart, 0);
      this.sliderEnd = Number(newSliderEnd, 0);

      this.rangeStart = this.sliderStart;
      this.rangeEnd = this.sliderEnd;
    },
    getSliderObject (sliderObject) {
      this.sliderObject = sliderObject;
      this.sliderObject.noUiSlider.updateOptions({
        format: {
          to: v => Number(v, 0),
          from: v => Number(v, 0),
        },
      });
    },
    resetSliderValues () {
      this.sliderObject.noUiSlider.set([this.minSlider, this.maxSlider]);
    },
    executeICRSRangeFilter () {
      if (!this.rangeChangeInProgress) {
        if (this.rangeStartTemp.toString() === this.rangeStart.toString()
        && this.rangeEndTemp.toString() === this.rangeEnd.toString()) return;

        this.rangeStartTemp = this.rangeStart;
        this.rangeEndTemp = this.rangeEnd;
        if (this.rangeStart !== '' && this.rangeEnd !== '') {
          // Validation check for numeric inputs
          if (this.icrsFilterType === 'numeric') {
            if (this.rangeStart.toString().includes('.') || this.rangeEnd.toString().includes('.')) {
              this.showFloatErrorMessage = true;
              return;
            }
            if (Number(this.rangeStart) > Number(this.rangeEnd)) {
              this.showNumericErrorMessage = true;
              return;
            }
          } else if (this.rangeStart > this.rangeEnd) {
            this.showNumericErrorMessage = true;
            return;
          }

          this.showFloatErrorMessage = false;
          this.showNumericErrorMessage = false;
          this.$emit('filter-change', {
            filter: {
              key: `From ${this.rangeStart} To ${this.rangeEnd}`,
              groupName: this.group.name,
            },
          });
        }
      }
    },
    clearRangeFilters () {
      this.rangeStart = '';
      this.rangeEnd = '';
    },
    clearErrorPrompt () {
      this.showNumericErrorMessage = false;
      this.showFloatErrorMessage = false;
    },
    pasteOnlyNumbers (event) {
      const pastedData = event.clipboardData.getData('text');

      if (!/^\d+$/.test(pastedData)) {
        event.preventDefault();
        return false;
      }

      return true;
    },
    allowOnlyNumbers (event) {
      // The purpose of this function is to block characters not numbers on iPad
      const ignoreKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab'];

      // match characters not numbers
      const regex = /[^0-9]/g;
      if (regex.test(event.key) && !ignoreKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
  },
  mounted () {
    if (window.outerWidth <= 768) {
      this.isLocalOpen = true;
      this.setIsMobileOveralOpen(true);
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      handler (value) {
        this.isLocalOpen = value;
      },
    },
    rangeStart: {
      immediate: true,
      handler () {
        if (this.syncingActiveRangeStart) {
          this.syncingActiveRangeStart = false;
        } else if (this.icrsFilterType !== 'numeric') {
          this.executeICRSRangeFilter();
        }
      },
    },
    rangeEnd: {
      immediate: true,
      handler () {
        if (this.syncingActiveRangeEnd) {
          this.syncingActiveRangeEnd = false;
        } else if (this.icrsFilterType !== 'numeric') {
          this.executeICRSRangeFilter();
        }
      },
    },
    rangeChangeInProgress: {
      immediate: true,
      handler () {
        // Handler for numeric input filter
        this.executeICRSRangeFilter();
      },
    },
    appliedFilters: {
      immediate: true,
      handler (appliedFilters) {
        if (this.icrsFilterType !== 'checkbox' && this.icrsFilterType !== 'dropdown') {
          const icrsAppliedFilter = appliedFilters.find(filter => filter.includes(this.group.name));
          if (icrsAppliedFilter === undefined) {
            this.clearRangeFilters();
            this.icrsRangeApplied = false;
            if (this.sliderObject !== undefined) {
              this.resetSliderValues();
              this.showSliderText = false;
            }
          } else {
            const activeRangeStart = icrsAppliedFilter.split('rom ')[1].split(' To ')[0];
            const activeRangeEnd = icrsAppliedFilter.split('rom ')[1].split(' To ')[1];

            if (this.icrsFilterType === 'numeric') {
              if (this.rangeStart !== Number(activeRangeStart)) {
                this.syncingActiveRangeStart = true;
                this.rangeStart = Number(activeRangeStart);
              }
              if (this.rangeEnd !== Number(activeRangeEnd)) {
                this.syncingActiveRangeEnd = true;
                this.rangeEnd = Number(activeRangeEnd);
              }
            }

            if (this.icrsFilterType === 'date') {
              if (this.rangeStart !== activeRangeStart) {
                this.syncingActiveRangeStart = true;
                this.rangeStart = activeRangeStart;
              }
              if (this.rangeEnd !== activeRangeEnd) {
                this.syncingActiveRangeEnd = true;
                this.rangeEnd = activeRangeEnd;
              }
            }

            if (this.icrsFilterType === 'slider') {
              if (this.sliderStart !== activeRangeStart) {
                this.syncingActiveRangeStart = true;
                this.sliderStart = activeRangeStart;
                this.rangeStart = activeRangeStart;
              }
              if (this.sliderEnd !== activeRangeEnd) {
                this.syncingActiveRangeEnd = true;
                this.sliderEnd = activeRangeEnd;
                this.rangeStart = activeRangeStart;
              }
              if (this.sliderObject) this.sliderObject.noUiSlider.set([this.sliderStart, this.sliderEnd]);
            }

            this.icrsRangeApplied = true;
          }
        }
      },
    },
    currentQuery: {
      immediate: true,
      handler (newCurrentQuery, oldCurrentQuery) {
        // clear prompt when execute new guide search/ advanced search
        this.clearErrorPrompt();
      },
    },
    topicsMust: {
      immediate: true,
      handler (newCurrentQuery, oldCurrentQuery) {
        // clear prompt when trigger a search by change filter
        this.clearErrorPrompt();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-group {
  border-bottom: 1px solid $blue-gray-100;
  &__item {
    padding: $gap-md;
    display: flex;
    justify-content: space-between;
  }

  &/deep/ .filter-item[aria-expanded="true"] svg {
    transform: rotate(180deg);
  }
}
.filter-group-name {
  font-weight: $weight-semi-bold;
  color: $blue-gray-500;
  font-size: $font-md;

  &--filter-applied {
    color: $blue-500;
  }
}

.filter-checklist {
  padding: 0px $gap-md $gap-md $gap-md;

  &/deep/ .filter-item {
    margin-bottom: $gap-xxs;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.show-more-btn {
  color: $blue-500;
  padding: 4px 6px;
  border: 0;
  background: transparent;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
