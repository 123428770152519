export { default as DetailsTab } from './Details.vue';
export { default as PromotedContentTab } from './PromotedContent.vue';
export { default as MetadataTab } from './Metadata.vue';
export { default as TagsTab } from './Tags.vue';
export { default as AddTag } from './AddTag.vue';
export { default as AddMetadata } from './AddMetadata.vue';
export { default as DeleteModal } from './DeleteModal.vue';

export { default as DocInput } from './DocInput.vue';
export { default as NotFound } from './NotFound.vue';
export { default as PreviewContent } from './PreviewContent.vue';
