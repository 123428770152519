import styles from '@/styles/_ontology-search-variables.scss';

export default {
  EDGES_COLOUR: '#778295',
  RELATION_LIST_COLOR: [
    '#C20BDA',
    '#DA0B6E',
    '#A66608',
    '#8E7106',
    '#218345',
    '#4E6F06',
    '#38807B',
    '#437A98',
    '#7362DF',
    '#8A0B9D',
    '#AA0957',
    '#7A4B06',
    '#564506',
    '#1A6136',
    '#4E5F07',
    '#265454',
    '#335E75',
    '#462FD4',
    '#55075F',
    '#650634',
    '#583604',
    '#322A06',
    '#114122',
    '#2E3C06',
    '#183434',
    '#2C4B5E',
    '#2D1D90',
  ],
  LIGHTEN_RELATION_LIST_COLOR: [
    styles.badgePurple100,
    styles.badgePink100,
    styles.badgeBrown100,
    styles.badgeYellow100,
    styles.badgeGreen100,
    styles.badgeDullGreen100,
    styles.badgeBlueGreen100,
    styles.badgeBlue100,
    styles.badgeBluePurple100,
    styles.badgePurple200,
    styles.badgePink200,
    styles.badgeBrown200,
    styles.badgeYellow200,
    styles.badgeGreen200,
    styles.badgeDullGreen200,
    styles.badgeBlueGreen200,
    styles.badgeBlue200,
    styles.badgeBluePurple200,
    styles.badgePurple300,
    styles.badgePink300,
    styles.badgeBrown300,
    styles.badgeYellow300,
    styles.badgeGreen300,
    styles.badgeDullGreen300,
    styles.badgeBlueGreen300,
    styles.badgeBlue300,
    styles.badgeBluePurple300,
  ],
};
