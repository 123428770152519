var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rich-text-editor-wrap"},[_c('editor',{attrs:{"init":{
      plugins: _vm.plugins,
      toolbar: _vm.toolbar,
      width: _vm.width,
      height: _vm.height,
      menubar: '',
      resize: false,
      promotion: false,
      statusbar: false,
      branding: false,
      toolbar_mode: 'wrap',
      setup: _vm.setupEditor,
      link_assume_external_targets: true,
    },"disabled":_vm.disabled,"inline":_vm.isInline},on:{"onChange":_vm.changeValue},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }