<template>
  <div class="filter-group">
    <div
      class="filter-group__item"
      v-b-toggle="accordionId"
      role="tab"
    >
      <span
        class="filter-group-name"
        :class="{ 'filter-group-name--filter-applied': filterApplied }"
      >
        {{ displayName | capitalize }}
      </span>
      <icon-text
        class="when-opened"
        faType="fas"
        faName="chevron-up"
        size="sm"
      ></icon-text>
      <icon-text
        class="when-closed"
        faType="fas"
        faName="chevron-down"
        size="sm"
      ></icon-text>
    </div>
    <b-collapse
      :visible="isOpen"
      @input="handleCollapseInput"
      :id="accordionId"
      role="tabpanel"
    >
      <div class="filter-checklist">
        <div class="input-group">
          <flat-pickr
            id="filter-start-date"
            ref="startDate"
            :config="configs.start"
            placeholder="Start date"
            class="flat-pickr-input"
            v-model="form.dateStart"
            @on-change="onStartChange"
          >

          </flat-pickr>
          <span>to</span>
          <flat-pickr
            id="filter-end-date"
            ref="endDate"
            :config="configs.end"
            placeholder="End date"
            class="flat-pickr-input"
            v-model="form.dateEnd"
            @on-change="onEndChange"
          >
          </flat-pickr>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatStartDateToUTC, formatEndDateToUTC, formatDateYearMonthDayWithHyphen } from '@/helpers/date-filters';
import IconText from '../../components/IconText.vue';

export default {
  components: {
    IconText,
  },

  props: {
    displayName: String,
    group: String,
    isOpen: {
      type: Boolean,
      default: false,
    },
    resetInput: {
      type: String,
      default: null,
    },
  },

  filters: {
    capitalize (value) {
      if (!value) return '';
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },

  data () {
    return {
      form: {
        dateStart: undefined,
        dateEnd: undefined,
      },
      configs: {
        wrap: true,
        start: {
          minDate: null,
          maxDate: new Date(),
          dateFormat: 'Y-m-d',
          altInput: true,
          altFormat: 'j M Y',
        },
        end: {
          minDate: null,
          maxDate: new Date(),
          dateFormat: 'Y-m-d',
          altInput: true,
          altFormat: 'j M Y',
        },
      },
      preventStartEventTrigger: false,
      preventEndEventTrigger: false,
      filterApplied: false,
    };
  },

  computed: {
    accordionId () {
      return `accordion-${this.group}`;
    },
    ...mapGetters('searchService/filters/date', [
      'dateObject',
    ]),
  },

  watch: {
    dateObject (newValue, oldValue) {
      /**
       * logic to prevent infinite loop issue
       * https://github.com/ankurk91/vue-flatpickr-component/discussions/190
       */
      this.filterApplied = Boolean(newValue.start || newValue.end);

      const newValueStart = newValue.start ? formatDateYearMonthDayWithHyphen(newValue.start) : undefined;
      const newValueEnd = newValue.end ? formatDateYearMonthDayWithHyphen(newValue.end) : undefined;

      if (newValueStart !== this.form.dateStart) {
        this.preventStartEventTrigger = true;
        this.form.dateStart = newValueStart;
      }
      if (newValueEnd !== this.form.dateEnd) {
        this.preventEndEventTrigger = true;
        this.form.dateEnd = newValueEnd;
      }
    },
  },
  mounted () {
    this.filterApplied = Boolean(this.dateObject.start || this.dateObject.end);
    if (this.filterApplied) {
      const start = this.dateObject.start ? formatDateYearMonthDayWithHyphen(this.dateObject.start) : undefined;
      const end = this.dateObject.end ? formatDateYearMonthDayWithHyphen(this.dateObject.end) : undefined;
      this.form.dateStart = start;
      this.form.dateEnd = end;
    }
  },
  methods: {
    handleCollapseInput (isOpen) {
      this.$emit('toggle-open', {
        isOpen,
        groupName: this.group,
      });
    },

    onStartChange (selectedDates, dateStr, instance) {
      this.configs.end.minDate = dateStr;
      if (dateStr !== this.form.dateStart) {
        this.preventStartEventTrigger = false;
      }
      const date = dateStr ? formatStartDateToUTC(dateStr) : undefined;
      if (!this.preventStartEventTrigger) {
        this.$emit('start-date-change', date);
      }
      this.preventStartEventTrigger = false;
    },

    onEndChange (selectedDates, dateStr, instance) {
      this.configs.start.maxDate = dateStr || new Date();
      if (dateStr !== this.form.dateEnd) {
        this.preventEndEventTrigger = false;
      }
      const date = dateStr ? formatEndDateToUTC(dateStr) : undefined;
      if (!this.preventEndEventTrigger) {
        this.$emit('end-date-change', date);
      }
      this.preventEndEventTrigger = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-group {
  &__item {
    padding: $gap-md;
    display: flex;
    justify-content: space-between;
  }

  &/deep/ .filter-item[aria-expanded="true"] svg {
    transform: rotate(180deg);
  }
}
.filter-group-name {
  font-weight: $weight-semi-bold;
  color: $blue-gray-500;
  font-size: $font-md;

  &--filter-applied {
    color: $blue-500;
  }
}

.filter-checklist {
  padding: 0px $gap-md $gap-md $gap-md;

  &/deep/ .filter-item {
    margin-bottom: $gap-xxs;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.show-more-btn {
  color: $blue-500;
  padding: 4px 6px;
  border: 0;
  background: transparent;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.input-group {
  border: 1px solid $blue-gray-100;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  /deep/ input {
    border-style: none;
    border-radius: 5px;
    padding: 6px;
    width: 100%;
    font-size: $font-md;
  }
  /deep/ .form-control {
    background-color: white;
  }
  /deep/ span {
    border-right: 1px solid $blue-gray-100;
    border-left: 1px solid $blue-gray-100;
    font-weight: $weight-semi-bold;
    color: $blue-gray-500;
    font-size: $font-md;
    padding: 10px;
  }
}
</style>
