<template>
  <div class="icrs-guided-search-boxes-section">
    <h4
      v-if="activeGuidedPrefix == 0"
      class="icrs-guided-question"
    >
      What is the nature of the injury?
    </h4>
    <h4
      v-if="activeGuidedPrefix > 0"
      class="icrs-guided-question"
    >
      You have selected
      <span style="color: var(--primary-light)">{{ activeGuidedValue }}</span>.
      Continue to choose one option below.
    </h4>
    <b-card-group
      v-for="(guidedDeck, index) in guidedDisplayDecks"
      :key="`guidedDeck_${index}`"
      class="icrs-guided-search-deck"
      deck
    >
      <b-card
        v-for="(guidedTag, deckIndex) in guidedDeck"
        :key="`guidedDeck_${index}_${deckIndex}`"
        :bg-variant="getGuidedBgVariant(guidedTag)"
        :border-variant="getGuidedBorderVariant(guidedTag)"
        class="text-center"
        :class="{
          'card-left': deckIndex === 0,
          'card-right': deckIndex === guidedDeck.length -1,
        }"
        @click="$emit('updateGuidedSearch', guidedTag)"
      >
        <b-card-text>
          {{ formatGuidedDisplay(guidedTag) }}
        </b-card-text>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ICRSGuidedSearchBoxes',
  props: {
    activeGuidedPrefix: {
      type: Number,
      default: 0,
    },
    activeGuidedValue: {
      type: String,
      default: '',
    },
    guidedDisplayDecks: {
      type: Array,
      default () {
        return [];
      },
    },
    guidedPrefixIgnoreList: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters('icrs', [
      'dropdownGuidedTag',
      'selectedLevel1',
      'selectedLevel2',
      'selectedLevel3',
      'level2CategoriesForSelectedLevel1',
      'level3CategoriesForSelectedLevel2',
    ]),
  },
  methods: {
    getGuidedBgVariant (guidedTag) {
      // if (guidedTag !== 'Previous Selection') {
      //   return 'primary';
      // }
      return null;
    },
    getGuidedBorderVariant (guidedTag) {
      if (guidedTag === 'Previous Selection') {
        return 'dark';
      }
      return 'primary';
      // return null;
    },
    formatGuidedDisplay (term) {
      let formattedTerm = term;
      // eslint-disable-next-line array-callback-return
      this.guidedPrefixIgnoreList.map((prefix) => {
        formattedTerm = formattedTerm.replace(prefix, '');
      });
      return formattedTerm;
    },
  },
};
</script>
