<template>
  <div>
    <div
      v-if="icrsPlotSearching"
      class="loading-spinner-wrapper"
    >
      <LoadingSpinner
        text="Loading search results..."
        size="lg"
      />
    </div>
    <b-alert
      :show="!icrsPlotSearching && !icrsPlotSuccess"
      class="has-icon alert-large"
      style="margin-top: 10px;"
      variant="danger"
    >
      <div style="display: flex">
        <span class="has-icon"><i class="fa fa-exclamation-circle"></i></span>
        <div class="alert-content">
          <h4>Error</h4>
          <p>Error encountered when retrieving data for overview.</p>
        </div>
      </div>
    </b-alert>
    <b-alert
      :show="!icrsPlotSearching && !showInjuries && icrsPlotSuccess"
      class="has-icon alert-large"
      style="margin-top: 10px;"
      variant="info"
    >
      <div style="display: flex">
        <span class="has-icon"><i class="fa fa-exclamation-circle"></i></span>
        <div class="alert-content">
          <h4>No Results Available</h4>
          <p>Overview is unavailable for this search query.</p>
        </div>
      </div>
    </b-alert>

    <div class="row">
      <div class="col-xl-9 col-md-8">
        <p
          v-show="!icrsPlotSearching && icrsPlotSuccess && showInjuries"
          style="margin-bottom: 0"
        >
          <font-awesome-icon
            :icon="['fas', 'info-circle']"
            size="lg"
            style="margin-right:10px; cursor: pointer"
            class="desktop-tooltip hide-for-printing"
            @click="$bvModal.show(helpIndicatorsModalId)"
          />
          <strong>{{displayInjuryCount}}</strong>
          <span v-if="displayInjuryCount == 1">&nbsp;injury found in&nbsp;</span>
          <span v-if="displayInjuryCount != 1">&nbsp;injuries found in&nbsp;</span>
          <strong>{{displayCaseCount}}</strong>
          <span v-if="displayCaseCount == 1">&nbsp;case&nbsp;</span>
          <span v-if="displayCaseCount != 1">&nbsp;cases&nbsp;</span>
          <span>{{globalCount}}</span>
        </p>
      </div>
      <div class="col-xl-3 col-md-4">
        <b-button
          v-show="!icrsPlotSearching && icrsPlotSuccess && showInjuries && showInjuries"
          class="btn desktop-section hide-for-printing"
          variant="print-overview"
          block
          @click="triggerPrint"
        >
          <font-awesome-icon
            :icon="['fal', 'print']"
            size="lg"
          />&nbsp;&nbsp;Print Overview
        </b-button>
      </div>
    </div>
    <!-- Key Indicators on Desktop -->
    <ICRSDesktopIndicators
      v-show="!icrsPlotSearching && icrsPlotSuccess && showInjuries"
      :showGlobal="showGlobal"
      :keyIndicators="keyIndicators"
      @set-active-doc-id="setActiveDocIdFromKeyIndicators"
    />
    <!-- Key Indicators on Mobile -->
    <div
      v-show="!icrsPlotSearching && icrsPlotSuccess && showInjuries"
      class="mobile-section hide-for-printing"
      style="margin-bottom: 10px"
    >
      <button
        class="btn btn-small btn-block"
        :class="{
            'btn-exclude-global': showGlobal,
            'btn-include-global': !showGlobal
          }"
        @click="setGlobal"
      >
        {{ globalButton }}
      </button>
    </div>
    <ICRSMobileIndicators
      v-show="!icrsPlotSearching && icrsPlotSuccess && showInjuries"
      :showGlobal="showGlobal"
      :keyIndicators="keyIndicators"
      @set-active-doc-id="setActiveDocIdFromKeyIndicators"
    />
    <Plotly
      v-show="!icrsPlotSearching && icrsPlotSuccess && showInjuries && showScatter"
      class="desktop-section"
      :data="data"
      :layout="layout"
      :displaylogo="false"
      @click="handleScatterClick"
      @relayout="handleScatterRelayout"
    ></Plotly>
    <Plotly
      v-show="!icrsPlotSearching && icrsPlotSuccess && showInjuries && showBar"
      class="desktop-section"
      :data="barData"
      :layout="barLayout"
      :displaylogo="false"
      @click="handleBarClick"
    ></Plotly>
    <div
      v-show="!icrsPlotSearching && icrsPlotSuccess && showInjuries"
      class="desktop-section hide-for-printing"
    >
      <div class="btn-group btn-group-toggle">
        <button
          class="btn btn-small"
          :class="{
            'btn-active': showScatter,
            'btn-exclude-global': showScatter && !showGlobal,
            'btn-outline-exclude-global': showBar && !showGlobal,
            'btn-include-global': showScatter && showGlobal,
            'btn-outline-include-global': showBar && showGlobal,
          }"
          @click="showScatter = true; showBar = false;"
        >
          Scatter Plot
        </button>
        <button
          class="btn btn-small"
          :class="{
            'btn-active': showBar,
            'btn-exclude-global': showBar && !showGlobal,
            'btn-outline-exclude-global': showScatter && !showGlobal,
            'btn-include-global': showBar && showGlobal,
            'btn-outline-include-global': showScatter && showGlobal,
          }"
          @click="showScatter = false; showBar = true;"
        >
          Bar Chart
        </button>
      </div>
      <font-awesome-icon
        v-if="showScatter"
        :icon="['fas', 'info-circle']"
        size="lg"
        style="margin-left:10px; cursor: pointer"
        @click="$bvModal.show(helpScatterModalId)"
      />
      <div style="margin-top: 10px">
        <button
          class="btn btn-small"
          :class="{
            'btn-exclude-global': showGlobal,
            'btn-include-global': !showGlobal
          }"
          @click="setGlobal"
        >
          {{ globalButton }}
        </button>
      </div>
    </div>
    <ICRSSummarySheet
      modalId="icrsChartsSummarySheet"
      :activeDocTitle="activeDocTitle"
      :activeDocId="activeDocId"
      :viewType="type"
      @reset-active-doc="activeDocId = ''"
    />
    <b-modal
      :id="helpIndicatorsModalId"
      size="xl"
      :centered="true"
      title="Key Indicators Help"
      :hide-footer="true"
      header-close-content="<i class='fa fa-times-circle' />"
    >
      <p>
        Click on any key indicator (i.e. percentile amounts awarded) or any scatter plot point to view case details.
      </p>
      <img
        width="100%"
        src="/icrs/images/help_indicators.gif"
      >
    </b-modal>
    <b-modal
      :id="helpScatterModalId"
      size="xl"
      :centered="true"
      title="Scatter Plot Help"
      :hide-footer="true"
      header-close-content="<i class='fa fa-times-circle' />"
    >
      <p>Click and drag your mouse to zoom in on the scatter plot.</p>
      <img
        width="100%"
        src="/icrs/images/help_scatter.gif"
      >
    </b-modal>
  </div>
</template>

<script>
import { Plotly } from 'vue-plotly';
import { mapGetters, mapActions } from 'vuex';
import ICRSSummarySheet from '@/modules/icrs-summary-sheet/ICRSSummarySheet.vue';
import ICRSDesktopIndicators from './components/ICRSDesktopIndicators.vue';
import ICRSMobileIndicators from './components/ICRSMobileIndicators.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';

export default {
  name: 'ICRSCharts',
  props: {
    currentQuery: {
      type: String,
      default: null,
    },
    // MOCK SETTING TO BE UNCOMMENTED
    showInjuries: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Plotly,
    ICRSSummarySheet,
    ICRSDesktopIndicators,
    ICRSMobileIndicators,
    LoadingSpinner,
  },
  data () {
    return {
      helpIndicatorsModalId: 'help-indicators',
      helpScatterModalId: 'help-scatter',
      showScatter: true,
      showBar: false,
      showGlobal: false,
      globalButton: 'Include Global Awards',
      globalCount: '(excludes global awards)',
      displayInjuryCount: 0,
      displayCaseCount: 0,
      includeGlobalInjuryCount: 0,
      includeGlobalCaseCount: 0,
      nonGlobalInjuryCount: 0,
      nonGlobalCaseCount: 0,
      activeDocId: '',
      activeDocTitle: '',
      newNonGlobal: {},
      newGlobal: {},
      type: 'overView',
      nonGlobalBase: {
        x: [],
        y: [],
        customdata: [],
        mode: 'markers',
        type: 'scatter',
        name: 'Injury',
        marker: {
          size: 25,
          color: process.env.VUE_APP_ICRS_COLORS_nonGlobal,
        },
        hovertemplate:
          '<b>Case:</b> %{customdata.id}<br>'
          + '<b>Date: </b> %{x}<br>'
          + '<b>Award Amount: </b> %{customdata.amount}<br>'
          + '<b>Injuries: </b> %{customdata.injury}<br>'
          + '<b>Level 1: </b> %{customdata.level1}<br>'
          + '<b>Level 2: </b> %{customdata.level2}<br>'
          + '<b>Level 3: </b> %{customdata.level3}',
      },
      globalBase: {
        x: [],
        y: [],
        customdata: [],
        mode: 'markers',
        type: 'scatter',
        name: 'Global',
        marker: {
          size: 15,
          // size: 20,
          color: process.env.VUE_APP_ICRS_COLORS_global,
          // color: '#c4ad89',
        },
        hovertemplate:
          '<b>Case:</b> %{customdata.id}<br>'
          + '<b>Date: </b> %{x}<br>'
          + '<b>Award Amount: </b> %{customdata.amount}<br>'
          + '<b>Injuries: </b> %{customdata.injury}<br>'
          + '<b>Level 1: </b> %{customdata.level1}<br>'
          + '<b>Level 2: </b> %{customdata.level2}<br>'
          + '<b>Level 3: </b> %{customdata.level3}',
      },
      data: [],
      layout: {
        title: 'Pain and Suffering Award Amounts of Past Cases',
        xaxis: {
          title: {
            text: 'Assessment Date',
            standoff: 50,
          },
          tickformatstops: [
            {
              dtickrange: [null, 'M1'],
              value: '%d %b %Y',
            },
            {
              dtickrange: ['M1', 'M12'],
              value: '%b %Y',
            },
            {
              dtickrange: ['M12', null],
              value: '%Y',
            },
          ],
        },
        yaxis: {
          title: 'Award Amount ($)',
        },
        hovermode: 'closest',
        legend: { orientation: 'h' },
        showlegend: true,
      },
      barData: [],
      barLayout: {
        title: 'Pain and Suffering Award Amounts of Past Cases',
        xaxis: {
          title: {
            text: 'Award Amount ($)',
            standoff: 50,
          },
          fixedrange: true,
        },
        yaxis: {
          title: 'No. of Injuries',
          fixedrange: true,
        },
        bargroupgap: 0.2,
      },
      barX: [],
      keyDates: [],
      keyIds: [],
      barXWithGlobal: [],
      keyDatesWithGlobal: [],
      keyIdsWithGlobal: [],
      // Configurations for display of key indicators.
      keyIndicators: {
        excludeGlobalAwardAmountMin: 0,
        excludeGlobalAwardAmountLowP: 0,
        excludeGlobalAwardAmountLowInter: 0,
        excludeGlobalAwardAmountMedian: 0,
        excludeGlobalAwardAmountHighInter: 0,
        excludeGlobalAwardAmountHighP: 0,
        excludeGlobalAwardAmountMax: 0,

        includeGlobalAwardAmountMin: 0,
        includeGlobalAwardAmountLowP: 0,
        includeGlobalAwardAmountLowInter: 0,
        includeGlobalAwardAmountMedian: 0,
        includeGlobalAwardAmountHighInter: 0,
        includeGlobalAwardAmountHighP: 0,
        includeGlobalAwardAmountMax: 0,

        excludeGlobalCaseIdMin: '',
        excludeGlobalCaseIdLowP: '',
        excludeGlobalCaseIdLowInter: '',
        excludeGlobalCaseIdMedian: '',
        excludeGlobalCaseIdHighInter: '',
        excludeGlobalCaseIdHighP: '',
        excludeGlobalCaseIdMax: '',

        includeGlobalCaseIdMin: '',
        includeGlobalCaseIdLowP: '',
        includeGlobalCaseIdLowInter: '',
        includeGlobalCaseIdMedian: '',
        includeGlobalCaseIdHighInter: '',
        includeGlobalCaseIdHighP: '',
        includeGlobalCaseIdMax: '',

        excludeGlobalTitleMin: '',
        excludeGlobalTitleLowP: '',
        excludeGlobalTitleLowInter: '',
        excludeGlobalTitleMedian: '',
        excludeGlobalTitleHighInter: '',
        excludeGlobalTitleHighP: '',
        excludeGlobalTitleMax: '',

        includeGlobalTitleMin: '',
        includeGlobalTitleLowP: '',
        includeGlobalTitleLowInter: '',
        includeGlobalTitleMedian: '',
        includeGlobalTitleHighInter: '',
        includeGlobalTitleHighP: '',
        includeGlobalTitleMax: '',

        percentiles: [0, 10, 25, 50, 75, 90, 100],
        labels: [
          'Lowest',
          '10th Percentile',
          'Lower Quartile',
          'Median',
          'Upper Quartile',
          '90th Percentile',
          'Highest',
        ],
      },
      awardAmountMin: null,
      awardAmountMax: null,
      assessmentChartDateStart: null,
      assessmentChartDateEnd: null,
      assessmentInputDateStart: null,
      assessmentInputDateEnd: null,
      // MOCK DATA TO BE COMMENTED OFF
      // showInjuries: true,
      // icrsPlotSuccess: true,
      // icrsPlotData: {
      //   nonGlobal: {
      //     id: [
      //       'Suit No 1685 of 1989 (High Court)',
      //       'DC Suit No 4581 of 1998',
      //       'DC Suit No 1054 of 2003',
      //       '[2004] SGHC 256',
      //       '[2003] 1 SLR(R) 333',
      //       'Suit No 687 of 2007 (High Court)',
      //       'Suit 77 of 1993 (High Court)',
      //       '[2018] SGCA 74',
      //       'DC Suit No 3984 of 2008',
      //       'DC (T) Suit No 50133 of 1999',
      //       '[2012] SGHC 33',
      //       'Suit No 893 of 2005 (High Court)',
      //     ],
      //     dates: [
      //       '1993-06-17',
      //       '1999-06-25',
      //       '2003-12-05',
      //       '2004-11-12',
      //       '1999-12-28',
      //       '2009-01-20',
      //       '1996-09-30',
      //       '2018-11-05',
      //       '2014-05-29',
      //       '2003-09-13',
      //       '2012-02-10',
      //       '2007-03-30',
      //     ],
      //     injury: [
      //       'Cerebral concussion',
      //       'Right mastoid bone fracture',
      //       '2 cm haematoma over the occiput',
      //       'Right temporal bone fracture',
      //       'Right extradural and parietal subdural haematomas',
      //       'Acute extra- dural hematoma/ haemorrhage overlying right temporal lobe, open fracture of right squamous temporal bone; multiple small pockets pneumocranium; effacement of ispilateral cerebal sulci; fractures of right zygomatic arch and of greater wing of right sphenoid bone and of medial wall of right orbit right-sided orbital emphysema.',
      //       'Fracture of the base of the skull with air in the anterior cranial fossa',
      //       'Extensive skull fractures (with basi-frontal intercranial haemorrhage, boggy open wound over frontal region, extra-axial blood and pneumocephalus mainly over left cerebrum; brain laceration due to skull fragments; depressed frontal bone at midline; comminuted fracture of left orbital bar and roof, multiple dura tears adjacent to cribriform plate, dura tear over left frontal region)',
      //       'Right occipital fracture with basioccipital fracture of the atlas',
      //       'Fracture of skull including the right frontal, parietal and temporal bone and related head injuries such as post-concussion syndrome, headache and giddiness, subluxation of jaw',
      //       'Fractures to the skull',
      //       'Left extradural haematoma with skull base and left frontal bone fractures',
      //     ],
      //     amount: [
      //       '3000',
      //       '5000',
      //       '2000',
      //       '18000',
      //       '90000',
      //       '35000',
      //       '20000',
      //       '40000',
      //       '25000',
      //       '35000',
      //       '100000',
      //       '80000',
      //     ],
      //     level1: [
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //     ],
      //     level2: [
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //     ],
      //     level3: [
      //       'General, Stable or Minor Head Injury or Concussion',
      //       'Skull - Depressed, Compound, Diastatic, Comminuted and Open Fracture',
      //       'General, Stable or Minor Head Injury or Concussion',
      //       'N.A',
      //       'Partial to Total Loss of Smell',
      //       'N.A',
      //       'Skull - Depressed, Compound, Diastatic, Comminuted and Open Fracture',
      //       'Mild Brain Damage measuring 13 to 15 on the GCS',
      //       'General, Stable or Minor Head Injury or Concussion',
      //       'Skull - Depressed, Compound, Diastatic, Comminuted and Open Fracture',
      //       'N.A',
      //       'Partial Loss of Hearing with Mild Tinnitus',
      //     ],
      //   },
      //   global: {
      //     id: [
      //       'DC Suit No 4420 of 1994',
      //       'DC Suit No 2014 of 2000',
      //       'MC/MC Suit No 8993 of 2017',
      //       '[1997] SGHC 289',
      //       'DC Suit No 2739 of 1994',
      //       'DC(T) Suit No 51019 of 1999',
      //       '[2011] 3 SLR 610 (Court of Appeal)',
      //       '[2011] 3 SLR 610 (Court of Appeal)',
      //       'Suit No 2103 of 1996 (High Court)',
      //       'DC(T) Suit No 5101844 of 1999',
      //       'Suit No 47 of 1996 (High Court)',
      //       'Suit No 47 of 1996 (High Court)',
      //       '[2013] SGHCR 3 Suit No 274 of 2011 (High Court)',
      //       '[1993] 1 SLR(R) 407 (High Court)',
      //       '[1993] 1 SLR(R) 407 (High Court)',
      //       'DC Suit No 4037 of 1996',
      //     ],
      //     dates: [
      //       '1995-11-10',
      //       '2003-09-17',
      //       '2018-04-10',
      //       '1997-11-06',
      //       '2000-03-08',
      //       '2001-04-23',
      //       '2009-10-06',
      //       '2009-10-06',
      //       '2000-02-25',
      //       '23/4/2001',
      //       '1997-10-21',
      //       '1997-10-21',
      //       '2013-01-21',
      //       '1993-03-13',
      //       '1993-03-13',
      //       '1998-02-09',
      //     ],
      //     injury: [
      //       'Fracture of the occiput',
      //       'Skull fracture with 4 cm diameter right parietal scalp hematoma',
      //       'Frontal scalp haematoma',
      //       'Head injury with left parietal extradural haemorrhage',
      //       'Fracture across occipital area',
      //       'Fracture of right temporal and mastoid bone',
      //       'Scalp haematoma over the right occipital region',
      //       'Cerebral oedema with a base of skull fracture',
      //       'Multiple face and skull fractures',
      //       'Fracture of right temporal and mastoid bone',
      //       'Left subdural haematoma',
      //       'Right extradural haematoma',
      //       'Fracture of left frontal skull bone',
      //       'Fracture of the parieto-temporal bone',
      //       'Fractured left skull resulting in contusion on the brain, poor blood circulation and swelling of the brain',
      //       'Scalp haematoma',
      //     ],
      //     amount: [
      //       '5000',
      //       '45000',
      //       '2700',
      //       '160000',
      //       '50000',
      //       '82000',
      //       '70000',
      //       '70000',
      //       '120000',
      //       '90000.0',
      //       '60000',
      //       '60000',
      //       '45000',
      //       '160000',
      //       '160000',
      //       '25000',
      //     ],
      //     level1: [
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //       'Head',
      //     ],
      //     level2: [
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //       'Skull',
      //     ],
      //     level3: [
      //       'Skull - Depressed, Compound, Diastatic, Comminuted and Open Fracture',
      //       'Skull - Simple, Linear, Closed or Unspecified Fracture',
      //       'Whiplash Grade 1, Grade 2 and unknown',
      //       'N.A',
      //       'Skull - Depressed, Compound, Diastatic, Comminuted and Open Fracture',
      //       'Skull - Simple, Linear, Closed or Unspecified Fracture',
      //       'Total Loss of Sight in One Eye',
      //       'N.A',
      //       'Skull - Simple, Linear, Closed or Unspecified Fracture',
      //       'Skull - Simple, Linear, Closed or Unspecified Fracture',
      //       'N.A',
      //       'N.A',
      //       'N.A',
      //       'Skull - Simple, Linear, Closed or Unspecified Fracture',
      //       'Skull - Depressed, Compound, Diastatic, Comminuted and Open Fracture',
      //       'N.A',
      //     ],
      //   },
      // },
    };
  },
  computed: {
    ...mapGetters('searchService', [
      'searchWithin',
      'icrsPlotSearching',
      // MOCK SETTING TO BE UNCOMMENTED
      'icrsPlotData',
      'icrsPlotSuccess',
    ]),
    ...mapGetters('searchService/filters', [
      'selectedFilters',
    ]),
  },
  watch: {
    icrsPlotSearching: {
      immediate: true,
      handler (value) {
        if (!value) {
          if (Object.keys(this.icrsPlotData).length !== 0) {
            this.newNonGlobal = { ...this.nonGlobalBase };
            this.newGlobal = { ...this.globalBase };

            this.newNonGlobal.x = this.icrsPlotData.nonGlobal.dates;
            this.newNonGlobal.y = this.icrsPlotData.nonGlobal.amount;
            this.newNonGlobal.customdata = this.icrsPlotData.nonGlobal.id.map((id, index) => ({
              id,
              injury: this.formatLongInjuryName(this.icrsPlotData.nonGlobal.injury[index]),
              amount: Number(this.icrsPlotData.nonGlobal.amount[index]).toLocaleString(
                'en-US',
                { style: 'currency', currency: 'USD' },
              ),
              level1: this.icrsPlotData.nonGlobal.level1[index] || '',
              level2: this.icrsPlotData.nonGlobal.level2[index] || '',
              level3: this.icrsPlotData.nonGlobal.level3[index] || '',
            }));

            this.newGlobal.x = this.icrsPlotData.global.dates;
            this.newGlobal.y = this.icrsPlotData.global.amount;
            this.newGlobal.customdata = this.icrsPlotData.global.id.map((id, index) => ({
              id,
              injury: this.formatLongInjuryName(this.icrsPlotData.global.injury[index]),
              amount: Number(this.icrsPlotData.global.amount[index]).toLocaleString(
                'en-US',
                { style: 'currency', currency: 'USD' },
              ),
              level1: this.icrsPlotData.global.level1[index] || '',
              level2: this.icrsPlotData.global.level2[index] || '',
              level3: this.icrsPlotData.global.level3[index] || '',
            }));

            this.nonGlobalInjuryCount = this.icrsPlotData.nonGlobal.id.length;
            this.nonGlobalCaseCount = new Set(this.icrsPlotData.nonGlobal.id).size;
            this.includeGlobalInjuryCount = this.icrsPlotData.nonGlobal.id.length + this.icrsPlotData.global.id.length;
            this.includeGlobalCaseCount = new Set([
              ...this.icrsPlotData.nonGlobal.id,
              ...this.icrsPlotData.global.id,
            ]).size;

            this.barX = this.icrsPlotData.nonGlobal.amount;
            this.keyDates = this.icrsPlotData.nonGlobal.dates;
            this.keyIds = this.icrsPlotData.nonGlobal.id;
            this.barXWithGlobal = [
              ...this.icrsPlotData.nonGlobal.amount,
              ...this.icrsPlotData.global.amount,
            ];
            this.keyDatesWithGlobal = [
              ...this.icrsPlotData.nonGlobal.dates,
              ...this.icrsPlotData.global.dates,
            ];
            this.keyIdsWithGlobal = [
              ...this.icrsPlotData.nonGlobal.id,
              ...this.icrsPlotData.global.id,
            ];

            this.updateDataToPlot();
          } else {
            this.newNonGlobal = { ...this.nonGlobalBase };
            this.newGlobal = { ...this.globalBase };
            this.updateDataToPlot();
          }
          if (this.selectedFilters.length === 0 && this.searchWithin.length === 0) {
            this.resetDefaultAwardAndAssessmentDate();
          }
        }
      },
    },
  },
  methods: {
    ...mapActions('searchService/filters', [
      'setSelectedFilters',
      'executeFilterSearch',
    ]),
    triggerPrint () {
      window.print();
    },
    setGlobal () {
      this.showGlobal = !this.showGlobal;
      this.updateDataToPlot();
    },
    setActiveDocIdFromKeyIndicators (index) {
      if (!this.showGlobal) {
        if (index === 0) {
          this.activeDocId = this.keyIndicators.excludeGlobalCaseIdMin;
          this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.excludeGlobalTitleMin}`;
        } else if (index === 1) {
          this.activeDocId = this.keyIndicators.excludeGlobalCaseIdLowP;
          this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.excludeGlobalTitleLowP}`;
        } else if (index === 2) {
          this.activeDocId = this.keyIndicators.excludeGlobalCaseIdLowInter;
          this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.excludeGlobalTitleLowInter}`;
        } else if (index === 3) {
          this.activeDocId = this.keyIndicators.excludeGlobalCaseIdMedian;
          this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.excludeGlobalTitleMedian}`;
        } else if (index === 4) {
          this.activeDocId = this.keyIndicators.excludeGlobalCaseIdHighInter;
          this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.excludeGlobalTitleHighInter}`;
        } else if (index === 5) {
          this.activeDocId = this.keyIndicators.excludeGlobalCaseIdHighP;
          this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.excludeGlobalTitleHighP}`;
        } else if (index === 6) {
          this.activeDocId = this.keyIndicators.excludeGlobalCaseIdMax;
          this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.excludeGlobalTitleMax}`;
        }
      } else if (index === 0) {
        this.activeDocId = this.keyIndicators.includeGlobalCaseIdMin;
        this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.includeGlobalTitleMin}`;
      } else if (index === 1) {
        this.activeDocId = this.keyIndicators.includeGlobalCaseIdLowP;
        this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.includeGlobalTitleLowP}`;
      } else if (index === 2) {
        this.activeDocId = this.keyIndicators.includeGlobalCaseIdLowInter;
        this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.includeGlobalTitleLowInter}`;
      } else if (index === 3) {
        this.activeDocId = this.keyIndicators.includeGlobalCaseIdMedian;
        this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.includeGlobalTitleMedian}`;
      } else if (index === 4) {
        this.activeDocId = this.keyIndicators.includeGlobalCaseIdHighInter;
        this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.includeGlobalTitleHighInter}`;
      } else if (index === 5) {
        this.activeDocId = this.keyIndicators.includeGlobalCaseIdHighP;
        this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.includeGlobalTitleHighP}`;
      } else if (index === 6) {
        this.activeDocId = this.keyIndicators.includeGlobalCaseIdMax;
        this.activeDocTitle = `${this.activeDocId} ${this.keyIndicators.includeGlobalTitleMax}`;
      }
    },
    updateDataToPlot () {
      if (this.showGlobal) {
        this.globalButton = 'Exclude Global Awards';
        this.data = [this.newNonGlobal, this.newGlobal];
        this.globalCount = '(includes global awards)';
        this.displayInjuryCount = this.includeGlobalInjuryCount;
        this.displayCaseCount = this.includeGlobalCaseCount;
        this.barData = [{
          x: this.barXWithGlobal,
          type: 'histogram',
          marker: {
            color: process.env.VUE_APP_ICRS_COLORS_global,
            // color: '#c4ad89',
          },
        }];
        this.calculateKeyIndicators();
      } else {
        this.globalButton = 'Include Global Awards';
        this.data = [this.newNonGlobal];
        this.globalCount = '(excludes global awards)';
        this.displayInjuryCount = this.nonGlobalInjuryCount;
        this.displayCaseCount = this.nonGlobalCaseCount;
        this.barData = [{
          x: this.barX,
          type: 'histogram',
          marker: {
            color: process.env.VUE_APP_ICRS_COLORS_nonGlobal,
          },
        }];
        this.calculateKeyIndicators();
      }
    },
    calculateKeyIndicators () {
      const barXCopy = [...this.barX];
      const barXWithGlobalCopy = [...this.barXWithGlobal];

      barXCopy.sort((a, b) => a - b);
      barXWithGlobalCopy.sort((a, b) => a - b);

      for (let j = 0; j < this.keyIndicators.percentiles.length; j += 1) {
        if (this.keyIndicators.percentiles[j] === 0) {
          // eslint-disable-next-line prefer-destructuring
          this.keyIndicators.excludeGlobalAwardAmountMin = barXCopy[0];
          // eslint-disable-next-line prefer-destructuring
          this.keyIndicators.includeGlobalAwardAmountMin = barXWithGlobalCopy[0];

          this.keyIndicators.excludeGlobalCaseIdMin = this.getLatestCase(
            this.keyIndicators.excludeGlobalAwardAmountMin,
            false,
            this.barX,
            this.barXWithGlobal,
            this.keyDates,
            this.keyDatesWithGlobal,
            this.keyIds,
            this.keyIdsWithGlobal,
          );
          this.keyIndicators.includeGlobalCaseIdMin = this.getLatestCase(
            this.keyIndicators.includeGlobalAwardAmountMin,
            true,
            this.barX,
            this.barXWithGlobal,
            this.keyDates,
            this.keyDatesWithGlobal,
            this.keyIds,
            this.keyIdsWithGlobal,
          );
          this.keyIndicators.excludeGlobalTitleMin = this.makeTitle(
            false,
            this.keyIndicators.labels[j],
            this.keyIndicators.excludeGlobalAwardAmountMin,
          );
          this.keyIndicators.includeGlobalTitleMin = this.makeTitle(
            false,
            this.keyIndicators.labels[j],
            this.keyIndicators.includeGlobalAwardAmountMin,
          );
        } else if (this.keyIndicators.percentiles[j] === 100) {
          // eslint-disable-next-line prefer-destructuring
          this.keyIndicators.excludeGlobalAwardAmountMax = barXCopy[barXCopy.length - 1];
          // eslint-disable-next-line prefer-destructuring
          this.keyIndicators.includeGlobalAwardAmountMax = barXWithGlobalCopy[barXWithGlobalCopy.length - 1];

          this.keyIndicators.excludeGlobalCaseIdMax = this.getLatestCase(
            this.keyIndicators.excludeGlobalAwardAmountMax,
            false,
            this.barX,
            this.barXWithGlobal,
            this.keyDates,
            this.keyDatesWithGlobal,
            this.keyIds,
            this.keyIdsWithGlobal,
          );
          this.keyIndicators.includeGlobalCaseIdMax = this.getLatestCase(
            this.keyIndicators.includeGlobalAwardAmountMax,
            true,
            this.barX,
            this.barXWithGlobal,
            this.keyDates,
            this.keyDatesWithGlobal,
            this.keyIds,
            this.keyIdsWithGlobal,
          );
          this.keyIndicators.excludeGlobalTitleMax = this.makeTitle(
            false,
            this.keyIndicators.labels[j],
            this.keyIndicators.excludeGlobalAwardAmountMax,
          );
          this.keyIndicators.includeGlobalTitleMax = this.makeTitle(
            false,
            this.keyIndicators.labels[j],
            this.keyIndicators.includeGlobalAwardAmountMax,
          );
        } else {
          const excludeGlobalNumArray = this.getNumArray(
            this.keyIndicators.percentiles[j],
            barXCopy,
          );
          const includeGlobalNumArray = this.getNumArray(
            this.keyIndicators.percentiles[j],
            barXWithGlobalCopy,
          );

          let excludeGlobalCaseId;
          let includeGlobalCaseId;
          let excludeGlobalAwardAmountToDisplay;
          let includeGlobalAwardAmountToDisplay;
          const excludeGlobalNoMatchFound = false;
          const includeGlobalNoMatchFound = false;

          // Gate 1 & 2 of key indicators logic flow
          if (excludeGlobalNumArray[0] === excludeGlobalNumArray[1]) {
            excludeGlobalCaseId = this.getLatestCase(
              excludeGlobalNumArray[0],
              false,
              this.barX,
              this.barXWithGlobal,
              this.keyDates,
              this.keyDatesWithGlobal,
              this.keyIds,
              this.keyIdsWithGlobal,
            );
            // eslint-disable-next-line prefer-destructuring
            excludeGlobalAwardAmountToDisplay = excludeGlobalNumArray[0];
          } else {
            // Gate 3 - See which award amount has higher number of cases
            const numCases0 = this.findNumCases(
              excludeGlobalNumArray[0],
              false,
              this.barX,
              this.barXWithGlobal,
            );
            const numCases1 = this.findNumCases(
              excludeGlobalNumArray[1],
              false,
              this.barX,
              this.barXWithGlobal,
            );

            if (numCases0 > numCases1) {
              excludeGlobalCaseId = this.getLatestCase(
                excludeGlobalNumArray[0],
                false,
                this.barX,
                this.barXWithGlobal,
                this.keyDates,
                this.keyDatesWithGlobal,
                this.keyIds,
                this.keyIdsWithGlobal,
              );
              // eslint-disable-next-line prefer-destructuring
              excludeGlobalAwardAmountToDisplay = excludeGlobalNumArray[0];
            } else if (numCases0 < numCases1) {
              excludeGlobalCaseId = this.getLatestCase(
                excludeGlobalNumArray[1],
                false,
                this.barX,
                this.barXWithGlobal,
                this.keyDates,
                this.keyDatesWithGlobal,
                this.keyIds,
                this.keyIdsWithGlobal,
              );
              // eslint-disable-next-line prefer-destructuring
              excludeGlobalAwardAmountToDisplay = excludeGlobalNumArray[1];
            } else {
              // Gate 4 - Last Resort: Simply choose the lower award amount.
              const lowerAwardAmount = Math.min(
                excludeGlobalNumArray[0],
                excludeGlobalNumArray[1],
              );
              excludeGlobalCaseId = this.getLatestCase(
                lowerAwardAmount,
                false,
                this.barX,
                this.barXWithGlobal,
                this.keyDates,
                this.keyDatesWithGlobal,
                this.keyIds,
                this.keyIdsWithGlobal,
              );
              excludeGlobalAwardAmountToDisplay = lowerAwardAmount;
            }
          }

          // Gate 1 & 2 of key indicators logic flow
          if (includeGlobalNumArray[0] === includeGlobalNumArray[1]) {
            includeGlobalCaseId = this.getLatestCase(
              includeGlobalNumArray[0],
              true,
              this.barX,
              this.barXWithGlobal,
              this.keyDates,
              this.keyDatesWithGlobal,
              this.keyIds,
              this.keyIdsWithGlobal,
            );
            // eslint-disable-next-line prefer-destructuring
            includeGlobalAwardAmountToDisplay = includeGlobalNumArray[0];
          } else {
            // Gate 3 - See which award amount has higher number of cases
            const numCases0 = this.findNumCases(
              includeGlobalNumArray[0],
              true,
              this.barX,
              this.barXWithGlobal,
            );
            const numCases1 = this.findNumCases(
              includeGlobalNumArray[1],
              true,
              this.barX,
              this.barXWithGlobal,
            );

            if (numCases0 > numCases1) {
              includeGlobalCaseId = this.getLatestCase(
                includeGlobalNumArray[0],
                true,
                this.barX,
                this.barXWithGlobal,
                this.keyDates,
                this.keyDatesWithGlobal,
                this.keyIds,
                this.keyIdsWithGlobal,
              );
              // eslint-disable-next-line prefer-destructuring
              includeGlobalAwardAmountToDisplay = includeGlobalNumArray[0];
            } else if (numCases0 < numCases1) {
              includeGlobalCaseId = this.getLatestCase(
                includeGlobalNumArray[1],
                true,
                this.barX,
                this.barXWithGlobal,
                this.keyDates,
                this.keyDatesWithGlobal,
                this.keyIds,
                this.keyIdsWithGlobal,
              );
              // eslint-disable-next-line prefer-destructuring
              includeGlobalAwardAmountToDisplay = includeGlobalNumArray[1];
            } else {
              // Gate 4 - Last Resort: Simply choose the lower award amount.
              const lowerAwardAmount = Math.min(
                includeGlobalNumArray[0],
                includeGlobalNumArray[1],
              );
              includeGlobalCaseId = this.getLatestCase(
                lowerAwardAmount,
                true,
                this.barX,
                this.barXWithGlobal,
                this.keyDates,
                this.keyDatesWithGlobal,
                this.keyIds,
                this.keyIdsWithGlobal,
              );
              includeGlobalAwardAmountToDisplay = lowerAwardAmount;
            }
          }

          if (this.keyIndicators.percentiles[j] < 25) {
            this.keyIndicators.excludeGlobalAwardAmountLowP = excludeGlobalAwardAmountToDisplay;
            this.keyIndicators.includeGlobalAwardAmountLowP = includeGlobalAwardAmountToDisplay;
            this.keyIndicators.excludeGlobalCaseIdLowP = excludeGlobalCaseId;
            this.keyIndicators.includeGlobalCaseIdLowP = includeGlobalCaseId;
            this.keyIndicators.excludeGlobalTitleLowP = this.makeTitle(
              excludeGlobalNoMatchFound,
              this.keyIndicators.labels[j],
              this.keyIndicators.excludeGlobalAwardAmountLowP,
            );
            this.keyIndicators.includeGlobalTitleLowP = this.makeTitle(
              includeGlobalNoMatchFound,
              this.keyIndicators.labels[j],
              this.keyIndicators.includeGlobalAwardAmountLowP,
            );
          } else if (this.keyIndicators.percentiles[j] < 50) {
            this.keyIndicators.excludeGlobalAwardAmountLowInter = excludeGlobalAwardAmountToDisplay;
            this.keyIndicators.includeGlobalAwardAmountLowInter = includeGlobalAwardAmountToDisplay;
            this.keyIndicators.excludeGlobalCaseIdLowInter = excludeGlobalCaseId;
            this.keyIndicators.includeGlobalCaseIdLowInter = includeGlobalCaseId;
            this.keyIndicators.excludeGlobalTitleLowInter = this.makeTitle(
              excludeGlobalNoMatchFound,
              this.keyIndicators.labels[j],
              this.keyIndicators.excludeGlobalAwardAmountLowInter,
            );
            this.keyIndicators.includeGlobalTitleLowInter = this.makeTitle(
              includeGlobalNoMatchFound,
              this.keyIndicators.labels[j],
              this.keyIndicators.includeGlobalAwardAmountLowInter,
            );
          } else if (this.keyIndicators.percentiles[j] === 50) {
            this.keyIndicators.excludeGlobalAwardAmountMedian = excludeGlobalAwardAmountToDisplay;
            this.keyIndicators.includeGlobalAwardAmountMedian = includeGlobalAwardAmountToDisplay;
            this.keyIndicators.excludeGlobalCaseIdMedian = excludeGlobalCaseId;
            this.keyIndicators.includeGlobalCaseIdMedian = includeGlobalCaseId;
            this.keyIndicators.excludeGlobalTitleMedian = this.makeTitle(
              excludeGlobalNoMatchFound,
              this.keyIndicators.labels[j],
              this.keyIndicators.excludeGlobalAwardAmountMedian,
            );
            this.keyIndicators.includeGlobalTitleMedian = this.makeTitle(
              includeGlobalNoMatchFound,
              this.keyIndicators.labels[j],
              this.keyIndicators.includeGlobalAwardAmountMedian,
            );
          } else if (this.keyIndicators.percentiles[j] <= 75) {
            this.keyIndicators.excludeGlobalAwardAmountHighInter = excludeGlobalAwardAmountToDisplay;
            this.keyIndicators.includeGlobalAwardAmountHighInter = includeGlobalAwardAmountToDisplay;
            this.keyIndicators.excludeGlobalCaseIdHighInter = excludeGlobalCaseId;
            this.keyIndicators.includeGlobalCaseIdHighInter = includeGlobalCaseId;
            this.keyIndicators.excludeGlobalTitleHighInter = this.makeTitle(
              excludeGlobalNoMatchFound,
              this.keyIndicators.labels[j],
              this.keyIndicators.excludeGlobalAwardAmountHighInter,
            );
            this.keyIndicators.includeGlobalTitleHighInter = this.makeTitle(
              includeGlobalNoMatchFound,
              this.keyIndicators.labels[j],
              this.keyIndicators.includeGlobalAwardAmountHighInter,
            );
          } else if (this.keyIndicators.percentiles[j] < 100) {
            this.keyIndicators.excludeGlobalAwardAmountHighP = excludeGlobalAwardAmountToDisplay;
            this.keyIndicators.includeGlobalAwardAmountHighP = includeGlobalAwardAmountToDisplay;
            this.keyIndicators.excludeGlobalCaseIdHighP = excludeGlobalCaseId;
            this.keyIndicators.includeGlobalCaseIdHighP = includeGlobalCaseId;
            this.keyIndicators.excludeGlobalTitleHighP = this.makeTitle(
              excludeGlobalNoMatchFound,
              this.keyIndicators.labels[j],
              this.keyIndicators.excludeGlobalAwardAmountHighP,
            );
            this.keyIndicators.includeGlobalTitleHighP = this.makeTitle(
              includeGlobalNoMatchFound,
              this.keyIndicators.labels[j],
              this.keyIndicators.includeGlobalAwardAmountHighP,
            );
          }
        }
      }
    },
    getNumArray (percentileNum, awardsArray) {
      if (awardsArray.length === 0) {
        return [0, 0];
      }

      if (awardsArray.length === 1) {
        return [awardsArray[0], awardsArray[0]];
      }

      if (percentileNum === 50 && awardsArray.length % 2 === 1) {
        const indexToGet = Math.floor(awardsArray.length / 2);
        return [awardsArray[indexToGet], awardsArray[indexToGet]];
      }
      const indexToGet = percentileNum / 100.0 * awardsArray.length;
      const indexesToGet = [Math.floor(indexToGet) - 1, Math.ceil(indexToGet) - 1];


      if (indexesToGet[0] === indexesToGet[1]) {
        indexesToGet[1] += 1;
      }

      if (indexesToGet[0] < 0) {
        indexesToGet[0] = 0;
      }

      if (indexesToGet[1] >= awardsArray.length) {
        indexesToGet[1] = awardsArray.length - 1;
      }

      return [awardsArray[indexesToGet[0]], awardsArray[indexesToGet[1]]];
    },
    findNumCases (awardAmount, includeGlobal, barX, barXWithGlobal) {
      let numCases = 0;
      if (!includeGlobal) {
        for (let i = 0; i < barX.length; i += 1) {
          if (barX[i] === awardAmount) {
            numCases += 1;
          }
        }
      } else {
        for (let i = 0; i < barXWithGlobal.length; i += 1) {
          if (barXWithGlobal[i] === awardAmount) {
            numCases += 1;
          }
        }
      }
      return numCases;
    },
    getLatestCase (
      awardAmount,
      includeGlobal,
      barX,
      barXWithGlobal,
      keyDate,
      keyDateWithGlobal,
      keyId,
      keyIdWithGlobal,
    ) {
      let caseId;
      let latestDatetime = new Date('1000-01-01').getTime();

      if (!includeGlobal) {
        for (let i = 0; i < barX.length; i += 1) {
          if (Number(barX[i]) === Number(awardAmount)) {
            const currentDatetime = new Date(keyDate[i]).getTime();
            if (currentDatetime > latestDatetime) {
              caseId = keyId[i];
              latestDatetime = currentDatetime;
            }
          }
        }
      } else {
        for (let i = 0; i < barXWithGlobal.length; i += 1) {
          if (Number(barXWithGlobal[i]) === Number(awardAmount)) {
            const currentDatetime = new Date(keyDateWithGlobal[i]).getTime();
            if (currentDatetime > latestDatetime) {
              caseId = keyIdWithGlobal[i];
              latestDatetime = currentDatetime;
            }
          }
        }
      }
      return caseId;
    },
    makeTitle (noMatchFound, label, AmountToDisplay) {
      const formattedAmount = Number.parseFloat(AmountToDisplay).toLocaleString(
        'en-US',
        { style: 'currency', currency: 'USD' },
      );
      if (noMatchFound) {
        return `(No Match Found For ${label} - ${formattedAmount})`;
      }
      return `(Case With ${label} - ${formattedAmount})`;
    },
    formatLongInjuryName (injury) {
      const maxLength = 50;
      if (injury.length < maxLength) {
        return injury;
      }
      const newString = `${injury.substring(0, maxLength)}...`;
      return newString;
    },
    handleScatterClick (data) {
      this.activeDocId = data.points[0].customdata.id;
      this.activeDocTitle = this.activeDocId;
    },
    handleBarClick (data) {
      const filteredArray = data.points[0].data.x.filter((amount, idx) => data.points[0].pointIndices.includes(idx));
      filteredArray.sort((a, b) => a - b);

      const awardMin = filteredArray[0];
      const awardMax = filteredArray.pop();

      if (this.showGlobal) {
        const awardsArray = [...this.barXWithGlobal].map(num => Number(num));
        const filteredAwardsArray = awardsArray.filter(amount => awardMin <= amount && amount <= awardMax);
        filteredAwardsArray.sort((a, b) => a - b);
        this.getBarMedian(filteredAwardsArray);
      } else {
        const awardsArray = [...this.barX].map(num => Number(num));;
        const filteredAwardsArray = awardsArray.filter(amount => awardMin <= amount && amount <= awardMax);
        filteredAwardsArray.sort((a, b) => a - b);
        this.getBarMedian(filteredAwardsArray);
      }
    },
    getBarMedian (filteredAwardsArray) {
      const numArray = this.getNumArray(50, filteredAwardsArray);
      // Gate 1 & 2 of key indicators logic flow
      if (numArray[0] === numArray[1]) {
        this.activeDocId = this.getLatestCase(
          numArray[0],
          this.showGlobal,
          this.barX,
          this.barXWithGlobal,
          this.keyDates,
          this.keyDatesWithGlobal,
          this.keyIds,
          this.keyIdsWithGlobal,
        );
        const formattedAwardMin = Number.parseFloat(numArray[0]).toLocaleString(
          'en-US',
          { style: 'currency', currency: 'USD' },
        );
        this.activeDocTitle = `${this.activeDocId} - Representative Case With ${formattedAwardMin}`;
      } else {
        // Gate 3 - See which award amount has higher number of cases

        let numCases0 = 0;
        let numCases1 = 0;
        for (let i = 0; i < filteredAwardsArray.length; i += 1) {
          if (filteredAwardsArray[i] === numArray[0]) {
            numCases0 += 1;
          }
        }
        for (let i = 0; i < filteredAwardsArray.length; i += 1) {
          if (filteredAwardsArray[i] === numArray[1]) {
            numCases1 += 1;
          }
        }

        if (numCases0 > numCases1) {
          this.activeDocId = this.getLatestCase(
            numArray[0],
            this.showGlobal,
            this.barX,
            this.barXWithGlobal,
            this.keyDates,
            this.keyDatesWithGlobal,
            this.keyIds,
            this.keyIdsWithGlobal,
          );
          const formattedAwardMin = Number.parseFloat(numArray[0]).toLocaleString(
            'en-US',
            { style: 'currency', currency: 'USD' },
          );
          this.activeDocTitle = `${this.activeDocId} - Representative Case With ${formattedAwardMin}`;
        } else if (numCases0 < numCases1) {
          this.activeDocId = this.getLatestCase(
            numArray[1],
            this.showGlobal,
            this.barX,
            this.barXWithGlobal,
            this.keyDates,
            this.keyDatesWithGlobal,
            this.keyIds,
            this.keyIdsWithGlobal,
          );
          const formattedAwardMin = Number.parseFloat(numArray[1]).toLocaleString(
            'en-US',
            { style: 'currency', currency: 'USD' },
          );
          this.activeDocTitle = `${this.activeDocId} - Representative Case With ${formattedAwardMin}`;
        } else {
          // Gate 4 - Last Resort: Simply choose the lower award amount.
          const lowerAwardAmount = Math.min(
            numArray[0],
            numArray[1],
          );
          this.activeDocId = this.getLatestCase(
            lowerAwardAmount.toFixed(0),
            this.showGlobal,
            this.barX,
            this.barXWithGlobal,
            this.keyDates,
            this.keyDatesWithGlobal,
            this.keyIds,
            this.keyIdsWithGlobal,
          );
          const formattedAwardMin = Number.parseFloat(lowerAwardAmount).toLocaleString(
            'en-US',
            { style: 'currency', currency: 'USD' },
          );
          this.activeDocTitle = `${this.activeDocId} - Representative Case With ${formattedAwardMin}`;
        }
      }
    },
    handleScatterRelayout (data) {
      if ('xaxis.range[0]' in data) {
        this.layout.xaxis.autorange = false;
        this.layout.yaxis.autorange = false;
        const timeDifference = new Date(data['xaxis.range[1]']).getTime() - new Date(data['xaxis.range[0]']).getTime();

        const minRange = 1000 * 60 * 60 * 168; // 7 days worth of ms

        if (timeDifference < minRange) {
          const newMidDate = this.formatDateObjectToChartDate(
            new Date(new Date(data['xaxis.range[0]']).getTime() + timeDifference / 2),
          );
          const newMidDatetime = new Date(newMidDate).getTime();
          const newStartDate = new Date(newMidDatetime - minRange / 2);
          const newEndDate = new Date(newMidDatetime + minRange / 2);
          const newStartChartDate = this.formatDateObjectToChartDate(newStartDate);
          const newEndChartDate = this.formatDateObjectToChartDate(newEndDate);

          this.assessmentChartDateStart = newStartChartDate;
          this.assessmentChartDateEnd = newEndChartDate;
          this.layout.xaxis.range = [this.assessmentChartDateStart, this.assessmentChartDateEnd];
        } else {
          // eslint-disable-next-line prefer-destructuring
          this.assessmentChartDateStart = data['xaxis.range[0]'].split(' ')[0];
          this.assessmentInputDateStart = this.formatChartDateToInputDate(this.assessmentChartDateStart);

          // eslint-disable-next-line prefer-destructuring
          this.assessmentChartDateEnd = data['xaxis.range[1]'].split(' ')[0];
          this.assessmentInputDateEnd = this.formatChartDateToInputDate(this.assessmentChartDateEnd);
        }
        this.awardAmountMin = Number(data['yaxis.range[0]'].toFixed(0));
        this.awardAmountMax = Number(data['yaxis.range[1]'].toFixed(0));

        if (this.awardAmountMin < 0) {
          this.awardAmountMin = 0;
        }

        if (this.awardAmountMax < 0) {
          this.awardAmountMax = 0;
        }

        this.handleFilterChange();
      } else if ('xaxis.autorange' in data) {
        this.resetDefaultAwardAndAssessmentDate();
        this.handleFilterChange();
      } else {
        this.resetDefaultAwardAndAssessmentDate();
      }
    },
    handleFilterChange () {
      let { selectedFilters } = this;
      selectedFilters = selectedFilters.filter(filter => filter.groupName !== 'dateAssessment');
      selectedFilters = selectedFilters.filter(filter => filter.groupName !== 'injuryFinalAwardedAmount');

      if (this.assessmentChartDateStart != null && this.assessmentChartDateEnd != null) {
        selectedFilters.push({
          key: `From ${this.assessmentChartDateStart} To ${this.assessmentChartDateEnd}`,
          groupName: 'dateAssessment',
        });
      }

      if (this.awardAmountMin != null && this.awardAmountMax != null) {
        selectedFilters.push({
          key: `From ${this.awardAmountMin} To ${this.awardAmountMax}`,
          groupName: 'injuryFinalAwardedAmount',
        });
      }

      this.setSelectedFilters(selectedFilters);
      this.executeFilterSearch();
    },
    formatChartDateToInputDate (dateString) {
      const dateArray = dateString.split('-');
      return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    },
    formatDateObjectToChartDate (dateObject) {
      let month = dateObject.getMonth() + 1;
      let day = dateObject.getDate();

      if (month < 10) {
        month = `0${month}`;
      }

      if (day < 10) {
        day = `0${day}`;
      }
      return `${dateObject.getFullYear()}-${month}-${day}`;
    },
    resetDefaultAwardAndAssessmentDate () {
      this.layout.xaxis.autorange = true;
      this.layout.yaxis.autorange = true;
      this.resetDefaultAward();
      this.resetDefaultAssessmentDate();
    },
    resetDefaultAward () {
      // this.awardAmountMin = this.includeGlobalAwardAmountMin;
      // this.awardAmountMax = this.includeGlobalAwardAmountMax;
      this.awardAmountMin = null;
      this.awardAmountMax = null;
    },
    resetDefaultAssessmentDate () {
      // this.assessmentChartDateStart = this.includeGlobalAssessmentChartDateStart;
      // this.assessmentChartDateEnd = this.includeGlobalAssessmentChartDateEnd;

      // this.assessmentInputDateStart = this.formatChartDateToInputDate(this.assessmentChartDateStart);
      // this.assessmentInputDateEnd = this.formatChartDateToInputDate(this.assessmentChartDateEnd);

      this.assessmentChartDateStart = null;
      this.assessmentChartDateEnd = null;

      this.assessmentInputDateStart = null;
      this.assessmentInputDateEnd = null;
    },
  },
};
</script>
