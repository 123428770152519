import CrawlContent from './components/CrawlContent.vue';
import TaggingContent from './components/TaggingContent.vue';
import StoreContent from './components/StoreContent.vue';
import SearchContent from './components/SearchContent.vue';
import OntologyContent from './components/OntologyContent.vue';

export default {
  cards: [
    {
      title: '1. Crawling',
      subtitle: 'Document to Text',
      imgUrl: 'spider',
      variant: 'blue-gray-600',
      content: CrawlContent,
    },
    {
      title: '2. Auto Tagging',
      subtitle: 'Text to Information',
      imgUrl: 'tags',
      variant: 'blue-gray-500',
      content: TaggingContent,
      additionalContent: {
        variant: 'blue-gray-500',
        content: OntologyContent,
      },
    },
    {
      title: '3. Store',
      subtitle: 'Info to Knowledge',
      imgUrl: 'database',
      variant: 'blue-gray-400',
      content: StoreContent,
    },
    {
      title: '4. Search',
      subtitle: 'Search & Discovery',
      imgUrl: 'search',
      variant: 'blue-gray-300',
      content: SearchContent,
    },
  ],
};
