<template>
  <div>
    <!-- useICRSConfig: true config.showPromotedContentFilter: true
      v-if=true
    -->
    <FilterPromotedContent
      v-if="!useICRSConfig && config.showPromotedContentFilter"
      :collapsed="config.isPromotedContentFilterCollapsed"
      :showPromotedContent="isPromotedContentShown"
      @toggle="handleTogglePromotedContentFilterCollapse"
      @filter-change="handleFilterChange"
      @date-range-change="handlePromoteContentDateRangeChange"
    />
    <div
      class="search-filter"
      :class="{ 'search-filter--open': !collapsed }"
    >
      <div
        v-if="!useICRSConfig"
        class="search-filter__header"
      >
        <span>Filters</span>
        <button
          @click="toggleOpen"
          class="search-filter__toggle-button"
        >{{ !collapsed ? 'Hide' : 'Show' }}</button>
      </div>
      <div
        v-show="!collapsed"
        class="search-filter__body filter-groups"
        :class="{ 'search-filter__body--no-item': !filterGroups.length }"
        role="tablist"
        aria-busy="true"
      >
        <div
          v-if="isFetchingFilterGroups"
          class="search-filter__loader"
        >
          <LoadingSpinner text="Loading filters..." />
        </div>
        <div v-if="useICRSConfig">
          <div class="btn-group btn-filters">
            <button
              type="button"
              class="btn btn-collapse-all-filters"
              @click="setICRSOverallOpen"
            >{{icrsOverallOpenMessage}} All</button>
            <button
              type="button"
              class="btn btn-clear-all-filters"
              @click="icrsClearAll"
            >Clear All</button>
          </div>
          <FilterGroup
            v-for="(topicDisplay, topicTitle) in filtersTopicTitleDisplayOrder"
            :key="topicTitle"
            :displayOthers="getCurrentSearchResultsLength()"
            :group="getICRSFilterGroup(topicTitle)"
            :applied-filters="selectedFilterKeys"
            :is-open="collapse[topicTitle]"
            :icrs-filter-type="filtersTopicTitleDisplayOrder[topicTitle].type"
            :icrs-assessment-salary-view="assessmentSalaryView"
            :icrs-accident-salary-view="accidentSalaryView"
            @toggle-open="handleFilterGroupToggle"
            @show-more="handleShowMore"
            @filter-change="handleFilterChange"
            @icrs-toggle-assessment-salary="toggleAssessmentSalary"
            @icrs-toggle-accident-salary="toggleAccidentSalary"
            @icrs-clear-filters="icrsClearIndividual"
          />
        </div>
        <!-- this.useICRSConfig: true config.useDefaultFilterGroupConfig: false
           v-if=false
        -->
        <div v-if="!useICRSConfig && config.useDefaultFilterGroupConfig">
          <FilterGrouping
            v-for="(group, index) in formattedFilterGroups"
            :applied-filters="selectedFilterKeys"
            :key="index"
            :group="group"
            :collapse="collapse"
            @toggle-open="handleFilterGroupToggle"
            @show-more="handleShowMore"
            @filter-change="handleFilterChange"
            @promote-date-range-change="handlePromoteContentDateRangeChange"
            @start-date-change="handleStartDateChange"
            @end-date-change="handleEndDateChange"
          />
        </div>
         <!-- this.useICRSConfig: true config.useDefaultFilterGroupConfig: false
           v-if=false
        -->
        <div v-if="!useICRSConfig && !config.useDefaultFilterGroupConfig">
          <FilterPromotedContent
            v-if="config.showPromotedContentFilter"
            class="bottom-border-line"
            group="_promotedContent"
            :is-open="collapse['_promotedContent']"
            @toggle-open="handleFilterGroupToggle"
            @filter-change="handleFilterChange"
            @date-range-change="handlePromoteContentDateRangeChange"
          />

          <FilterGroup
            v-for="group in filterGroups"
            :key="group.name"
            :group="group"
            :applied-filters="selectedFilterKeys"
            :is-open="collapse[group.name]"
            @toggle-open="handleFilterGroupToggle"
            @show-more="handleShowMore"
            @filter-change="handleFilterChange"
          />

          <FilterDate
            displayName="Modified Date"
            group="_modifiedDate"
            :is-open="collapse['_modifiedDate']"
            :applied-filters="selectedFilterKeys"
            @toggle-open="handleFilterGroupToggle"
            @start-date-change="handleStartDateChange"
            @end-date-change="handleEndDateChange"
          ></FilterDate>
        </div>

      </div>
    </div>

    <Modal
      id="filter-modal"
      static
      lazy
      ref="modal"
      no-close-on-backdrop
      :no-buttons="useICRSConfig"
      :centered="!useICRSConfig || icrsDesktopMode"
      :title="modalTitle"
      :visible="isModalShowing"
      @close="handleModalClose"
      @hidden="handleModalClose"
      @cancel="handleModalClose"
      @ok="handleModalOk"
      okTitle="Apply Filters"
    >
      <div class="container">
        <div class="row">
          <div
            v-if="!useICRSConfig"
            class="modal-toolbar-container"
          >
            <button
              style="padding-left: 0px"
              class="btn btn-link clear-button"
              :disabled="!filterModal.selectedFilters.length"
              @click="handleFilterModalClearAll"
            >Clear All</button>
            <div class="d-flex align-items-center">
              <SortByButton
                :sort-by="filterModal.sortBy"
                @change="handleFilterModalSortByChange"
              />
              <Input
                v-model="filterModal.searchKeyword"
                id="input-small"
                size="sm"
                icon="search"
                placeholder="Search Filters"
              />
            </div>
          </div>
          <div
            v-if="useICRSConfig"
            class="col-12"
          >
            <ICRSModalButtons
              displayMode="sort"
              :filterModalSelectedFilters="filterModal.selectedFilters"
              :filterModalSortBy="filterModal.sortBy"
              @handle-filter-modal-clear-all="handleFilterModalClearAll"
              @handle-modal-ok="handleModalOk"
              @handle-modal-close="handleModalClose"
              @handle-filter-modal-sort-by-change="handleFilterModalSortByChange"
            />
          </div>
          <div
            v-if="useICRSConfig"
            class="col-12"
          >
            <Input
              v-model="filterModal.searchKeyword"
              id="input-small"
              size="sm"
              icon="search"
              placeholder="Search Filter Options"
            />
          </div>
        </div>

        <div :class="{
          'modal-filter-items-container': !useICRSConfig || icrsDesktopMode,
          'icrs-mobile-modal-filter-items-container': useICRSConfig && !icrsDesktopMode
          }">
          <div
            v-if="isFetchingFilterGroups"
            class="loading-spinner-wrapper"
          >
            <LoadingSpinner text="Loading filter options..." />
          </div>
          <div
            v-if="!filterItemChunks.length"
            style="color: var(--black);"
          >
            There are no relevant filter options available.
          </div>
          <div
            v-for="(row, i) in filterItemChunks"
            :key="i"
            class="row"
          >
            <div
              v-for="(item, i) in row"
              :key="i"
              :class="`col-${icrsModalFilterCol}`"
            >
              <div class="filter-item-wrapper">
                <FilterItem
                  :group-name="selectedGroup.name"
                  :applied="selectedModalFilterKeys
                    .includes(createFilterKey(
                      item.key,
                      selectedGroup.name
                    ))
                  "
                  @change="handleModalFilterChange"
                  id-prefix="modal-item"
                  :item="item"
                  :filterCountDisabled="!isUATDebug"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="useICRSConfig"
          class="row"
        >
          <div
            class="col-12"
            style="text-align:right;"
          >
            <ICRSModalButtons
              displayMode="action"
              :filterModalSelectedFilters="filterModal.selectedFilters"
              :filterModalSortBy="filterModal.sortBy"
              @handle-filter-modal-clear-all="handleFilterModalClearAll"
              @handle-modal-ok="handleModalOk"
              @handle-modal-close="handleModalClose"
              @handle-filter-modal-sort-by-change="handleFilterModalSortByChange"
            />
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FilterGroupObject from '@/services/models/search/filter-group.model';
import ICRSService from '@/services/icrs-service';
import FilterPromotedContent from './FilterPromotedContent';
import FilterItem from './FilterItem.vue';
import FilterGroup from './FilterGroup.vue';
import SortByButton from './SortByButton.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import FilterDate from './FilterDate.vue';
import ICRSModalButtons from './components/ICRSModalButtons.vue';
import { sortFiltersBy, formatFiltersByGroup } from '../../helpers/search-filters';
import FilterGrouping from './FilterGrouping.vue';

export default {
  name: 'SearchFilter',
  components: {
    FilterItem,
    FilterGroup,
    SortByButton,
    LoadingSpinner,
    FilterPromotedContent,
    FilterDate,
    ICRSModalButtons,
    FilterGrouping,
  },

  props: {
    collapsed: Boolean,
  },

  data () {
    return {
      /**
       * @types { [keyof: string]: boolean }
       * example { country: false },
       */
      collapse: {},
      isPromotedContentShown: false,
      config: {
        showPromotedContentFilter: true,
        useDefaultFilterGroupConfig: true,
      },
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
      filtersTopicTitleDisplayOrder: ICRSService.getFiltersTopicTitleDisplayOrder(),
      icrsOverallOpen: false,
      icrsOverallOpenMessage: 'Collapse',
      assessmentSalaryView: 'Annual',
      accidentSalaryView: 'Annual',
      icrsModalFilterCol: 4,
      icrsDesktopMode: true,
      windowSize: 0,
    };
  },

  computed: {
    ...mapGetters('icrs', ['allGuidedCategories']),
    ...mapGetters('searchService', [
      'filterGroups',
      'currentSearchResults',
      'isFetchingFilterGroups',
      'isMobileOveralOpen',
    ]),
    ...mapGetters('searchService/filters', [
      'selectedFilters',
      'filterModal',
      'selectedGroup',
      'filterGroupings',
    ]),
    selectedFilterKeys () {
      return this.selectedFilters.map(filter => this.createFilterKey(filter.key, filter.groupName));
    },
    selectedModalFilterKeys () {
      return this.filterModal.selectedFilters.map(filter => this.createFilterKey(filter.key, filter.groupName));
    },
    isModalShowing () {
      return Boolean(this.selectedGroup);
    },
    modalTitle () {
      return this.selectedGroup
        ? this.selectedGroup.displayName
        : '';
    },
    filteredFilterItems () {
      if (!this.selectedGroup) return [];

      let items = sortFiltersBy(
        this.selectedGroup.items.slice(),
        this.filterModal.sortBy,
      );

      if (this.filterModal.searchKeyword) {
        items = items
          .filter((item) => {
            const keyword = this.filterModal.searchKeyword.toLowerCase();
            return item.key.toLowerCase().includes(keyword);
          });
      }

      return items;
    },
    filterItemChunks () {
      if (!this.selectedGroup) return [];

      return _.chunk(this.filteredFilterItems, 3);
    },
    formattedFilterGroups () {
      if (this.config.useDefaultFilterGroupConfig) {
        const formattedGroups = formatFiltersByGroup(this.filterGroupings, this.filterGroupCustom);
        return formattedGroups;
      }
      return this.filterGroupCustom;
    },
    isUATDebug () {
      if (this.$route.query.debug === '1') {
        return true;
      }
      return false;
    },
    filterGroupCustom () {
      return this.filterGroups.map((filterItem) => {
        if (filterItem.name === 'level3code') {
          filterItem.bucket = filterItem.bucket.map((bucketItem) => {
            bucketItem.key = this.elitLabel(bucketItem);
            return bucketItem;
          });
          return filterItem;
        }
        return filterItem;
      });
    },
  },
  watch: {
    'filterModal.selectedFilters': {
      immediate: true,
      handler (value) {
        const shouldDisable = !value.length;
        this.$nextTick(() => {
          this.toggleModalSaveButtonDisable(shouldDisable);
        });
      },
    },
    isMobileOveralOpen (value) {
      if (value) {
        this.icrsOverallOpen = true;
        this.setIsMobileOveralOpen(false);
      }
    },
    isFetchingFilterGroups: {
      immediate: true,
      handler (value) {
        // add aria-label to slider button
        const startSlider = document.querySelector('.noUi-handle-lower');
        if (startSlider) {
          startSlider.setAttribute('aria-label', 'Start slider');
        }
        const endSlider = document.querySelector('.noUi-handle-upper');
        if (endSlider) {
          endSlider.setAttribute('aria-label', 'End slider');
        }

        // add aria-label to datepicker calendar button
        const startButtons = document.querySelectorAll('button[id*="_start_datepicker"]');
        if (startButtons.length) {
          startButtons.forEach((button) => {
            button.setAttribute('aria-label', 'Open start calendar');
          });
        }

        const endButtons = document.querySelectorAll('button[id*="_end_datepicker"]');
        if (endButtons.length) {
          endButtons.forEach((button) => {
            button.setAttribute('aria-label', 'Open end calendar');
          });
        }
      },
    },
  },
  created () {
    this.getFilterGroupConfigs();
  },
  mounted () {
    this.getFilterGroupings();
    this.unsubscribeTriggerSearchQueryAction = this.$store.subscribeAction((action) => {
      if (action.type === 'searchService/triggerSearchQuery') {
        this.setAccordionsState();
      }
    });
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }

    this.onResize();
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy () {
    this.unsubscribeTriggerSearchQueryAction();
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    ...mapActions('searchService', [
      'getFilterGroupConfigs',
      'triggerShowMoreFilterSelect',
      'setCurrentSearchMode',
      'executeSearchQuery',
      'executeCategories',
      'setIsMobileOveralOpen',
    ]),

    ...mapActions('searchService/newResults',
      [
        'executeSearchNewQuery',
        'executeCategoriesNew',
      ]),

    ...mapActions('searchService/filters', [
      'applyModalSelectedFilters',
      'setModalSelectedFilterGroup',
      'setModalSelectedFilters',
      'setModalSearchKeyword',
      'setModalSortBy',
      'setSelectedFilters',
      'setIsFirstOpenHasSelectedFilters',
      'executeFilterSearch',
      'getFilterGroupings',
    ]),

    ...mapActions('searchService/filters/date', [
      'handleStartDateChange',
      'handleEndDateChange',
    ]),
    /**
     * @param {object} event
     * @param {string} event.groupName
     * @param {boolean} event.isOpen
     */
    handleFilterGroupToggle ({ groupName, isOpen } = {}) {
      this.$set(this.collapse, groupName, isOpen);
    },
    setAccordionsState () {
      // TODO: reset accordian state for filter group when done integration with filter group config endpoint
      this.collapse = this.filterGroupCustom.map(group => ({
        [group.name]: this.useICRSConfig,
      })).reduce((acc, current) => Object.assign(acc, current), {});
    },
    setICRSOverallOpen () {
      this.icrsOverallOpen = !this.icrsOverallOpen;
      if (this.icrsOverallOpen) {
        this.icrsOverallOpenMessage = 'Collapse';
      } else {
        this.icrsOverallOpenMessage = 'Expand';
      }
      Object.keys(this.collapse).map(topicTitle => this.setICRSIndividualCollapse(topicTitle));
    },
    setICRSIndividualCollapse (topicTitle) {
      this.collapse[topicTitle] = this.icrsOverallOpen;
    },
    icrsClearAll () {
      this.setSelectedFiltersCustom([]);
      this.executeFilterSearch();
    },
    icrsClearIndividual (groupName) {
      const nextFilters = this.selectedFilters.filter(filter => filter.groupName !== groupName);
      this.setSelectedFiltersCustom(nextFilters);
      this.executeFilterSearch();
    },
    /**
     * @param {string} key
     * @param {string} groupName
     */
    createFilterKey (key, groupName) {
      return `${groupName}_${key.split(' ; ').length === 2 ? key.split(' ; ')[0] : key}`;
    },
    toggleModalSaveButtonDisable (flag) {
      const button = this.$refs.modal.$el.querySelector('.btn.save');
      if (button !== undefined && button !== null) {
        if (flag && !this.filterModal.isFirstOpenHasSelectedFilters) {
          return button.setAttribute('disabled', flag);
        }

        return button.removeAttribute('disabled');
      }
      return null;
    },
    getNextFilters (uncheckedFilter, currentFilters) {
      const { key, groupName } = uncheckedFilter;
      const uncheckedFilterId = this.createFilterKey(key, groupName);
      const nextFilters = currentFilters
        .filter((filter) => {
          if (typeof filter.key === 'boolean') {
            return filter.groupName !== uncheckedFilter.groupName;
          }
          const filterId = this.createFilterKey(filter.key, filter.groupName);
          return uncheckedFilterId !== filterId;
        });
      return nextFilters;
    },
    handleModalFilterChange (event) {
      // for every filter item clicked in the modal,
      // we update the appliedFilters and
      // update the filter group / aggregates by calling the level2 endpoint
      let modalSelectedFilters = this.filterModal.selectedFilters;
      const includedFilter = [];
      const excludedFilter = [];
      if (event.checked) {
        const isExists = Boolean(
          modalSelectedFilters.find(filter => (
            filter.key === event.filter.key
            && filter.groupName === event.filter.groupName
          )),
        );

        if (isExists) return;
        modalSelectedFilters.push(event.filter);
        includedFilter.push(event.filter);
      } else {
        modalSelectedFilters = this.getNextFilters(event.filter, modalSelectedFilters);
        excludedFilter.push(event.filter);
      }

      this.setModalSelectedFiltersCustom(modalSelectedFilters);
      if (!this.useICRSConfig) {
        this.triggerShowMoreFilterSelect({
          includes: includedFilter,
          excludes: excludedFilter,
        })
          .then(this.updateSelectedFilterGroup);
      }
    },
    handleFilterChange (event) {
      let { selectedFilters } = this;
      const icrsFilterType = ICRSService.getFiltersTopicTitleDisplayOrder()[event.filter.groupName].type;

      if (icrsFilterType !== 'checkbox' && icrsFilterType !== 'dropdown') {
        selectedFilters = selectedFilters.filter(filter => filter.groupName !== event.filter.groupName);
        selectedFilters.push(event.filter);
      } else if (event.checked) {
        const isExists = Boolean(
          this.selectedFilters.find(filter => (
            filter.key === event.filter.key
            && filter.groupName === event.filter.groupName
          )),
        );

        if (isExists) return;
        selectedFilters.push(event.filter);
      } else {
        selectedFilters = this.getNextFilters(event.filter, selectedFilters);
      }
      this.setSelectedFiltersCustom(selectedFilters);
      this.executeFilterSearch();
    },
    handlePromoteContentDateRangeChange () {
      const { selectedFilters } = this;
      this.setSelectedFiltersCustom(selectedFilters);
      this.executeFilterSearch();
    },
    handleFilterModalClearAll () {
      const excludes = this.filterModal.selectedFilters;
      this.setModalSelectedFiltersCustom([]);
      if (!this.useICRSConfig) {
        this.triggerShowMoreFilterSelect({
          excludes,
        })
          .then(this.updateSelectedFilterGroup);
      }
    },
    updateSelectedFilterGroup () {
      const foundGroup = this.filterGroupCustom
        .find(group => group.name === this.selectedGroup.name);

      if (!foundGroup) throw new Error('Cannot update selected filter group(aggregation)');
      // Update the filter items data

      this.setModalSelectedFilterGroup(foundGroup);
    },
    handleFilterModalSortByChange (event) {
      this.setModalSortBy(event);
    },
    toggleOpen () {
      this.$emit('toggle', !this.collapsed);
    },
    handleShowMore (group) {
      this.setModalSelectedFilterGroup(group);
      const nextModalSelectedFilters = this.selectedFilters
        .filter(filter => filter.groupName === group.name);
      this.setModalSelectedFiltersCustom(nextModalSelectedFilters);
      this.setIsFirstOpenHasSelectedFilters(!!nextModalSelectedFilters.length);
    },
    handleModalOk () {
      this.setModalSearchKeyword('');
      this.applyModalSelectedFilters(this.selectedGroup);
      this.setModalSelectedFiltersCustom([]);
      this.executeFilterSearch();
      if (this.useICRSConfig) {
        this.$bvModal.hide('filter-modal');
      }
    },
    handleModalClose () {
      this.setModalSelectedFilterGroup(null);
      if (!this.useICRSConfig) {
        this.setModalSearchKeyword('');
        this.setModalSelectedFiltersCustom([]);
        this.executeFilterSearch();
      }
      this.setIsFirstOpenHasSelectedFilters(false);
    },
    handleTogglePromotedContentFilterCollapse (collapsed) {
      this.config.isPromotedContentFilterCollapsed = collapsed;
    },
    getCurrentSearchResultsLength () {
      if (this.currentSearchResults.resultItems && this.currentSearchResults.resultItems.length > 0) {
        return true;
      }
      return false;
    },
    getICRSFilterGroup (topicTitle) {
      const icrsFilterGroup = this.filterGroupCustom.find(
        filterGroup => this.checkFilterGroupName(filterGroup, topicTitle),
      );
      if (icrsFilterGroup === undefined) {
        return this.createDummyFilterGroup(topicTitle);
      }
      icrsFilterGroup.displayName = this.filtersTopicTitleDisplayOrder[topicTitle].display;
      const temp = new FilterGroupObject(icrsFilterGroup);
      return temp;
    },
    checkFilterGroupName (filterGroup, topicTitle) {
      if ('name' in filterGroup) {
        return filterGroup.name === topicTitle;
      }
      return false;
    },
    createDummyFilterGroup (topicTitle) {
      return new FilterGroupObject({
        name: topicTitle,
        docCount: 0,
        bucket: [],
        displayName: this.filtersTopicTitleDisplayOrder[topicTitle].display,
      });
    },
    toggleAssessmentSalary (view) {
      this.assessmentSalaryView = view;
    },
    toggleAccidentSalary (view) {
      this.accidentSalaryView = view;
    },
    onResize () {
      /*
        windowSize = 0 -> default onload
        windowSize = 1 -> screen <= 768
        windowSize = 2 -> screen > 768
      */
      // if (window.outerWidth >= 992.02) {
      // if (window.outerWidth <= 768) {
      if (window.outerWidth <= 768 && this.windowSize !== 1) {
        this.windowSize = 1;
        this.setICRSOverallOpen();
        this.icrsModalFilterCol = 12;
        this.icrsDesktopMode = false;
      }
      // if (window.outerWidth > 768) {
      if (window.outerWidth > 768 && this.windowSize !== 2) {
        this.windowSize = 2;
        this.setICRSOverallOpen();
        this.icrsModalFilterCol = 4;
        this.icrsDesktopMode = true;
      }
    },
    setSelectedFiltersCustom (selectedFilters) {
      const selectedFiltersMap = [...selectedFilters].map(item => ({ ...item, key: item.key.split(' ; ').length === 2 ? item.key.split(' ; ')[0] : item.key }));
      this.setSelectedFilters(selectedFiltersMap);
    },
    setModalSelectedFiltersCustom (selectedFilters) {
      const selectedFiltersMap = [...selectedFilters].map(item => ({ ...item, key: item.key.split(' ; ').length === 2 ? item.key.split(' ; ')[0] : item.key }));
      this.setModalSelectedFilters(selectedFiltersMap);
    },
    getDuplicateLevel3 () {
      const elitLabelCounts = {};

      for (const obj of this.allGuidedCategories.level3) {
        const elitLabel = obj.elitLabel;

        if (elitLabelCounts[elitLabel]) {
          elitLabelCounts[elitLabel]++;
        } else {
          elitLabelCounts[elitLabel] = 1;
        }
      }

      return elitLabelCounts;
    },
    elitLabel (bucketItem) {
      const value = bucketItem.key.split(' ; ');

      if (value.length === 1) return bucketItem.key;

      if (value.length > 1) {
        const duplicates = this.getDuplicateLevel3();
        if (duplicates[value[1]] >= 2) {
          const level3 = this.allGuidedCategories.level3.find(injury => injury.code === value[0]);
          if (level3) {
            const level2 = this.allGuidedCategories.level2.find(injury => injury.code === level3.parentCode);
            return level2 ? `${value[0]} ; ${level2.elitLabel} - ${value[1]}` : bucketItem.key;
          }
          return bucketItem.key;
        }

        return bucketItem.key;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-filter {
  color: $blue-gray-500;
  font-weight: $weight-semi-bold;
  height: 100%;
  background: $blue-gray-50;

  &__header {
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    background: $blue-200;
    padding: $gap-xs $gap-md;
    border-radius: $radius-4;
  }

  &--open {
    .search-filter__header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__body {
    position: relative;
    background: $white-500;
    min-height: 240px;

    &--no-item {
      min-height: initial;
    }
  }

  &__loader {
    user-select: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    position: absolute;
    font-weight: $weight-normal;
    color: $blue-gray-500;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }

  &__toggle-button {
    border: 0;
    background: transparent;
    font-weight: $weight-semi-bold;
  }

  &/deep/ {
    .modal-body {
      padding-top: 12px;
    }
  }
}

.filter-item-wrapper {
  margin-bottom: $gap-xxs;
}

.row .col .filter-item:first-child {
  padding-left: 5px;
}

.row .col .filter-item:last-child {
  padding-right: 5px;
}

.modal-filter-items-container {
  padding: $gap-md 0;
  height: 254px;
  position: relative;
  overflow: scroll;

  .loading-spinner-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.9);
    padding-top: 40px;
    text-align: center;
  }
}

.modal-toolbar-container {
  margin-bottom: $gap-sm;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.clear-button {
  color: $blue-500 !important;

  &:disabled {
    color: $blue-300 !important;
  }
}

.bottom-border-line {
  border-bottom: 1px solid $blue-gray-100;
}

</style>
