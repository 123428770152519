<template>
  <div
    class="key-indicator-wrapper desktop-section"
    :class="{'key-indicator-global': showGlobal}"
  >
    <section class="horizontal-key-indicator-container">
      <div
        class="key-indicator-block"
        style="font-weight:400 !important"
        @click="$emit('set-active-doc-id', 1)"
      >
        <div class="key-indicator-step-counter">{{ keyIndicators.percentiles[1] }}</div>
        <div class="key-indicator-content">
          <p
            v-if="!showGlobal"
            class="key-indicator-p"
          >
            {{ formatDisplayAmount(keyIndicators.excludeGlobalAwardAmountLowP) }}
            <br />
            {{ keyIndicators.labels[1] }}
          </p>
          <p
            v-if="showGlobal"
            class="key-indicator-p"
          >
            {{ formatDisplayAmount(keyIndicators.includeGlobalAwardAmountLowP) }}
            <br />
            {{ keyIndicators.labels[1] }}
          </p>
        </div>
      </div>
      <div
        class="key-indicator-block"
        style="font-weight:600 !important"
        @click="$emit('set-active-doc-id', 2)"
      >
        <div class="key-indicator-step-counter">{{ keyIndicators.percentiles[2] }}</div>
        <div class="key-indicator-content">
          <p
            v-if="!showGlobal"
            class="key-indicator-p"
          >
            {{ formatDisplayAmount(keyIndicators.excludeGlobalAwardAmountLowInter) }}
            <br />
            {{ keyIndicators.labels[2] }}
          </p>
          <p
            v-if="showGlobal"
            class="key-indicator-p"
          >
            {{ formatDisplayAmount(keyIndicators.includeGlobalAwardAmountLowInter) }}
            <br />
            {{ keyIndicators.labels[2] }}
          </p>
        </div>
      </div>
      <div
        class="key-indicator-block"
        style="font-weight:900 !important"
        @click="$emit('set-active-doc-id', 3)"
      >
        <div class="key-indicator-step-counter">{{ keyIndicators.percentiles[3] }}</div>
        <div class="key-indicator-content">
          <p
            v-if="!showGlobal"
            class="key-indicator-p"
          >
            {{ formatDisplayAmount(keyIndicators.excludeGlobalAwardAmountMedian) }}
            <br />
            {{ keyIndicators.labels[3] }}
          </p>
          <p
            v-if="showGlobal"
            class="key-indicator-p"
          >
            {{ formatDisplayAmount(keyIndicators.includeGlobalAwardAmountMedian) }}
            <br />
            {{ keyIndicators.labels[3] }}
          </p>
        </div>
      </div>
      <div
        class="key-indicator-block"
        style="font-weight:600 !important"
        @click="$emit('set-active-doc-id', 4)"
      >
        <div class="key-indicator-step-counter">{{ keyIndicators.percentiles[4] }}</div>
        <div class="key-indicator-content">
          <p
            v-if="!showGlobal"
            class="key-indicator-p"
          >
            {{ formatDisplayAmount(keyIndicators.excludeGlobalAwardAmountHighInter) }}
            <br />
            {{ keyIndicators.labels[4] }}
          </p>
          <p
            v-if="showGlobal"
            class="key-indicator-p"
          >
            {{ formatDisplayAmount(keyIndicators.includeGlobalAwardAmountHighInter) }}
            <br />
            {{ keyIndicators.labels[4] }}
          </p>
        </div>
      </div>
      <div
        class="key-indicator-block"
        style="font-weight:400 !important"
        @click="$emit('set-active-doc-id', 5)"
      >
        <div class="key-indicator-step-counter">{{ keyIndicators.percentiles[5] }}</div>
        <div class="key-indicator-content">
          <p
            v-if="!showGlobal"
            class="key-indicator-p"
          >
            {{ formatDisplayAmount(keyIndicators.excludeGlobalAwardAmountHighP) }}
            <br />
            {{ keyIndicators.labels[5] }}
          </p>
          <p
            v-if="showGlobal"
            class="key-indicator-p"
          >
            {{ formatDisplayAmount(keyIndicators.includeGlobalAwardAmountHighP) }}
            <br />
            {{ keyIndicators.labels[5] }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'ICRSDesktopIndicators',
  props: {
    showGlobal: {
      type: Boolean,
      default: false,
    },
    keyIndicators: {
      type: Object,
      default: null,
    },
  },
  methods: {
    formatDisplayAmount (amount) {
      return Number.parseFloat(amount).toLocaleString(
        'en-US',
        { style: 'currency', currency: 'USD' },
      );
    },
  },
};
</script>
