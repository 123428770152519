 <template>
  <div class="details-container">
    <b-form
      @submit="onSubmit"
      class="form"
    >
      <div class="mb-4">
        <div class="d-flex align-item-center justify-content-between">
          <label
            class="label"
            for="detail-title"
          >Document Title</label>
        </div>
        <validation-provider
          rules="doc-required"
          v-slot="{ errors }"
        >
          <b-form-textarea
            v-if="showTitleInput"
            name="title"
            id="detail-title"
            v-model="modelTitle"
            v-bind:class="errors[0] ? 'is-invalid' : ''"
            placeholder="Enter document title"
          />
          <span v-else>{{modelTitle || '--'}}</span>
          <span class="text-danger form-error">{{ errors[0] && 'The document title cannot be empty' }}</span>
        </validation-provider>
      </div>
      <div class="mb-4">
        <div class="d-flex align-item-center justify-content-between">
          <label
            class="label"
            for="detail-id"
          >Document ID</label>
        </div>
        <validation-provider
          rules="doc-required"
          v-slot="{ errors }"
        >
          <b-form-input
            v-if="showIdInput"
            id="detail-id"
            v-model="modelId"
            v-bind:class="errors[0] ? 'is-invalid' : ''"
            placeholder="Enter document id"
          />
          <span v-else>{{modelId || '--'}}</span>
          <span class="text-danger form-error">{{ errors[0] && 'The document ID cannot be empty' }}</span>
        </validation-provider>
      </div>
      <div class="mb-4">
        <div class="d-flex align-item-center justify-content-between">
          <label
            class="label"
            for="detail-path"
          >Document Path</label>
        </div>
        <validation-provider
          rules="doc-required"
          v-slot="{ errors }"
        >
          <b-form-textarea
            v-if="showPathInput"
            id="detail-path"
            v-model="modelPath"
            v-bind:class="errors[0] ? 'is-invalid' : ''"
            placeholder="Enter document path"
          />
          <span v-else>{{modelPath || '--'}}</span>
          <span class="text-danger form-error">{{ errors[0] && 'The document path cannot be empty' }}</span>
        </validation-provider>
      </div>
      <div class="">
        <div class="d-flex align-item-center justify-content-between">
          <label
            class="label"
            for="detail-created-date"
          >Document Creation Date</label>
        </div>
        <validation-provider
          rules="doc-required"
          v-slot="{ errors }"
        >
          <b-form-input
            v-if="showCreatedDateInput"
            id="detail-created-date"
            v-model="modelCreatedDate"
            v-bind:class="errors[0] ? 'is-invalid' : ''"
            placeholder="Enter document created date"
          />
          <span v-else>{{modelCreatedDate || '--'}}</span>
          <span class="text-danger form-error">{{ errors[0] && 'The document creation date cannot be empty' }}</span>
        </validation-provider>
      </div>
    </b-form>
    <div
      v-if="isEdited"
      class="d-flex justify-content-end"
    >
      <b-btn
        @click="handleCancel"
        class="pl-4 pr-4"
        variant="link"
      >
        Cancel
      </b-btn>
      <b-btn
        :disabled="disableSave"
        @click="handleSave"
        class="save-btn pl-4 pr-4"
        variant="primary"
      >
        Save
      </b-btn>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disableForm: {
      type: Object,
      default: () => ({}),
    },
    haveWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationProvider,
  },
  data () {
    const {
      id, title, path, createdDate,
    } = this.value || {};
    return {
      isEdited: false,
      formValue: {
        id,
        title,
        path,
        createdDate,
      },
    };
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault();
    },
    handleCancel () {
      const {
        id, title, path, createdDate,
      } = this.value || {};
      this.formValue = {
        id,
        title,
        path,
        createdDate,
      };
      this.isEdited = false;
    },
    setIsEdited (value) {
      this.isEdited = value;
    },
    handleSave () {
      const { formValue } = this || {};

      this.$emit('save', formValue, this.setIsEdited);
    },
  },
  computed: {
    disableSave () {
      const {
        id, title, path, createdDate,
      } = this.formValue;
      if (!id) return true;
      if (!title) return true;
      if (!path) return true;
      if (!createdDate) return true;
      return false;
    },
    modelId: {
      get () {
        return this.formValue.id;
      },
      set (value) {
        this.isEdited = true;
        this.formValue.id = value;
      },
    },
    modelTitle: {
      get () {
        return this.formValue.title;
      },
      set (value) {
        this.isEdited = true;
        this.formValue.title = value;
      },
    },
    modelPath: {
      get () {
        return this.formValue.path;
      },
      set (value) {
        this.isEdited = true;
        this.formValue.path = value;
      },
    },
    modelCreatedDate: {
      get () {
        return this.formValue.createdDate;
      },
      set (value) {
        this.isEdited = true;
        this.formValue.createdDate = value;
      },
    },
    showTitleInput () {
      return this.haveWriteAccess && !this.disableForm.TITLE;
    },
    showIdInput () {
      return this.haveWriteAccess && !this.disableForm.ID;
    },
    showPathInput () {
      return this.haveWriteAccess && !this.disableForm.PATH;
    },
    showCreatedDateInput () {
      return this.haveWriteAccess && !this.disableForm.CREATEDDATE;
    },
  },
};
</script>

<style  lang="scss">
#doc-manager {
  #details {
    .save-btn {
      margin-left: 8px;
    }

    .details-container {
      padding: 20px 0px;
      min-width: 500px;
      form {
        max-width: 50%;
      }
      span {
        font-size: 14px !important;
        color: $blue-gray-400;
      }
    }
  }
}
</style>
