import { formatDateDayMonthYear } from '@/helpers/date-filters';

export default {
  dateObject: state => ({
    start: state.start,
    end: state.end,
    field: state.field,
  }),
  dateFilters: (state, getters) => {
    // eslint-disable-next-line prefer-destructuring
    const dateObject = getters.dateObject;
    if (!dateObject) {
      return [];
    }
    const dateFilters = [];
    if (dateObject.start) {
      dateFilters.push({
        key: 'Modified Date: from',
        date: formatDateDayMonthYear(dateObject.start),
      });
    }
    if (dateObject.end) {
      dateFilters.push({
        key: 'Modified Date: to',
        date: formatDateDayMonthYear(dateObject.end),
      });
    }
    return dateFilters;
  },
};
