import createState from '../state';
import getters from '../getters';

describe('advanced-search vuex getters test', () => {
  test('inputIndividual', () => {
    let state = createState();
    expect(getters.inputIndividual(state)).toBe('');

    state = createState({ inputIndividual: 'Singapore' });
    expect(getters.inputIndividual(state)).toBe('Singapore');
  });

  test('individualId', () => {
    let state = createState();
    expect(getters.individualId(state)).toBe('');

    state = createState({ individualId: 'city_singapore' });
    expect(getters.individualId(state)).toBe('city_singapore');
  });

  test('selectedClass', () => {
    let state = createState();
    expect(getters.selectedClass(state)).toBe('');

    state = createState({ selectedClass: 'People' });
    expect(getters.selectedClass(state)).toBe('People');
  });

  test('individualSearchSuggestMap', () => {
    let state = createState();
    expect(getters.individualSearchSuggestMap(state)).toEqual({});

    const individualSearchSuggestMap = {
      'aia singapore': '6qltoced9i8',
      'amara singapore': 'g1ohevsnpk',
    };
    state = createState({ individualSearchSuggestMap });
    expect(getters.individualSearchSuggestMap(state)).toEqual(individualSearchSuggestMap);
  });

  test('filterGroupConfigs', () => {
    const filterGroupConfigs = {
      caa: {
        displayName: 'CAA',
        displayed: true,
        field: 'tags.caa',
        showInCloud: true,
      },
    };
    const state = createState({ filterGroupConfigs });

    expect(getters.filterGroupConfigs(state)).toEqual(filterGroupConfigs);
  });

  test('filterGroups', () => {
    let state = createState();
    expect(getters.filterGroups(state)).toEqual([]);

    state = createState({
      filterGroups: {
        data: [],
      },
    });
  });

  test('isFetchingFilterGroups', () => {
    let state = createState();
    expect(getters.isFetchingFilterGroups(state)).toEqual(true);

    state = createState({
      filterGroups: {
        isFetching: false,
      },
    });
    expect(getters.isFetchingFilterGroups(state)).toEqual(false);
  });

  test('currentSearch', () => {
    let state = createState();
    const initialCurrentSearch = {
      ontologySearch: false,
      filterBasedSearch: false,
    };
    expect(getters.currentSearch(state)).toEqual(initialCurrentSearch);

    const currentSearch = {
      ontologySearch: true,
      filterBasedSearch: false,
    };
    state = createState({ currentSearch });
    expect(getters.currentSearch(state)).toEqual(currentSearch);
  });

  test('isLoading', () => {
    let state = createState();
    expect(getters.isLoading(state)).toEqual(true);

    state = createState({ isLoading: false });
    expect(getters.isLoading(state)).toEqual(false);
  });

  test('advancedSearchModalOpen', () => {
    let state = createState();
    expect(getters.advancedSearchModalOpen(state)).toEqual(false);

    state = createState({ advancedSearchModalOpen: true });
    expect(getters.advancedSearchModalOpen(state)).toEqual(true);
  });
});
