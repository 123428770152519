<template>
    <connector-dashboard-widget
    class="search-connector-styles"
    @go-to-connector="goToConnector"/>
</template>

<script>
import { goToConnector } from '../helpers';

export default {
  methods: {
    goToConnector,
  },
};
</script>
