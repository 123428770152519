import initialState from '../state';
import getters from '../getters';

describe('doc manager vuex getters test', () => {
  test('documentContent', () => {
    const state = initialState;
    expect(getters.documentContent(state)).toEqual({});

    const docContent = {
      id: 'yahoo_supermassive_black_hole_found_wandering_through_space',
      title: 'Supermassive black hole found wandering through space',
      source: 'https://www.yahoo.com/news/rss',
      path: 'https://news.yahoo.com/supermassive-black-hole-found-wandering-153046384.html',
      content: ' | ',
      createdDate: '2021-03-17T04:00:03.274Z',
      lastModifiedDate: '2021-03-17T04:00:03.287Z',
      latest: true,
      roles: [
      ],
      metadata: {
        promote: true,
        promote_to: '9999-12-31T23:59:59.999Z',
        promote_from: '1970-01-01T00:00:00.000Z',
      },
      docFormat: 'html',
      nlpDate: '2021-03-17T04:00:11.977Z',
      connectorId: 1,
      priority: 0,
      manualUpdate: true,
      tags: {
      },
    };

    state.documentContent = docContent;
    expect(getters.documentContent(state)).toBe(docContent);
  });

  test('allClass', () => {
    const state = initialState;
    expect(getters.allClass(state)).toEqual([]);

    const classes = {
      country: 'Country',
      occupation: 'Occupation',
      shape: 'Shape',
      software: 'Software',
      color: 'Color',
      city: 'City',
      artobject: 'Art Object',
      subject: 'Subject',
      programmes: 'Programmes',
      singheritage: 'SingHeritage',
    };

    state.allClass = classes;
    expect(getters.allClass(state)).toBe(classes);
  });

  test('individualClass', () => {
    const state = initialState;
    expect(getters.individualClass(state)).toEqual([]);

    const individualClasses = {
      software: [
        'Omnitive Platform',
        'Omnitive Studio',
        'Ontology Manager',
      ],
      language: [
        'English',
        'English',
        'Malay',
        'Tamil',
      ],
      technology: [
        'Artificial Intelligence',
      ],
    };

    state.individualClass = individualClasses;
    expect(getters.individualClass(state)).toBe(individualClasses);
  });

  test('blacklistedField', () => {
    const state = initialState;
    expect(getters.blacklistedField(state)).toEqual([]);

    const fields = ['id', 'appType', 'createdDate', 'lastModifiedDate', 'version', 'latest',
      'roles', 'docFormat', 'sensitive', 'visualization'];

    state.blacklistedField = fields;
    expect(getters.blacklistedField(state)).toBe(fields);
  });
});
