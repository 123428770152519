import mutations from '../mutations';
import createState from '../state';

describe('advanced-search vuex mutations test', () => {
  it('setRelations', () => {
    const state = createState();
    const query = 'cityOfCountry';
    mutations.setRelations(state, query);
    expect(state.relations).toBe(query);
  });

  test('setRelationsMin', () => {
    const state = createState();
    mutations.setRelationsMin(state, 10);
    expect(state.relationsMin).toBe(10);
    mutations.setRelationsMin(state, 20);
    expect(state.relationsMin).toBe(20);
  });

  test('setRelationsMax', () => {
    const state = createState();
    mutations.setRelationsMax(state, 10);
    expect(state.relationsMax).toBe(10);
    mutations.setRelationsMax(state, 20);
    expect(state.relationsMax).toBe(20);
  });

  test('setMaxEdges', () => {
    const state = createState();
    mutations.setMaxEdges(state, 10);
    expect(state.maxEdges).toBe(10);
    mutations.setMaxEdges(state, 20);
    expect(state.maxEdges).toBe(20);
  });

  test('setNodes', () => {
    const state = createState();
    const nodes = [{
      color: '#583604',
      font: '#583604',
      id: 'city_singapore',
      label: 'Singapore',
      type: 'http://authoring.roots.sg/en/learn/collections/listing/city',
    }];
    mutations.setNodes(state, nodes);
    expect(state.nodes).toBe(nodes);
  });

  test('setEdges', () => {
    const state = createState();
    const edges = [{
      arrows: 'to',
      color: '#583604',
      from: 'city_singapore',
      id: 'cityOfCountry-country_singapore',
      length: 180,
      title: 'cityOfCountry',
      to: 'country_singapore',
    }];
    mutations.setEdges(state, edges);
    expect(state.edges).toBe(edges);
  });

  test('setSelectedNode', () => {
    const state = createState();
    const selectedNode = [{
      color: '#583604',
      font: '#583604',
      id: 'city_singapore',
      label: 'Singapore',
      type: 'http://authoring.roots.sg/en/learn/collections/listing/city',
    }];
    mutations.setSelectedNode(state, selectedNode);
    expect(state.selectedNode).toBe(selectedNode);
  });

  test('setTable', () => {
    const state = createState();
    const table = [{
      class: 'country',
      individual: 'Singapore',
      isHover: false,
      relation: 'cityOfCountry',
      subIndividual: 'Singapore',
    }];
    mutations.setTable(state, table);
    expect(state.table).toBe(table);
  });

  test('setHistory', () => {
    const state = createState();
    const history = [{
      concept: 'singapore',
      id: 'city_singapore',
      individual: 'singapore',
      label: 'singapore',
    }];
    mutations.setHistory(state, history);
    expect(state.history).toBe(history);
  });

  test('setDisplayedNodes', () => {
    const state = createState();
    const displayedNodes = [{
      color: '#583604',
      font: '#583604',
      id: 'city_singapore',
      label: 'Singapore',
      type: 'http://authoring.roots.sg/en/learn/collections/listing/city',
    }];
    mutations.setDisplayedNodes(state, displayedNodes);
    expect(state.displayedNodes).toBe(displayedNodes);
  });

  test('setDisplayedEdges', () => {
    const state = createState();
    const displayedEdges = [{
      arrows: 'to',
      color: '#583604',
      from: 'city_singapore',
      id: 'cityOfCountry-country_singapore',
      length: 180,
      title: 'cityOfCountry',
      to: 'country_singapore',
    }];
    mutations.setDisplayedEdges(state, displayedEdges);
    expect(state.displayedEdges).toBe(displayedEdges);
  });

  test('setOntologyMap', () => {
    const state = createState();
    const ontologyMap = [{
      'aia singapore': '6qltoced9i8',
      'amara singapore': 'g1ohevsnpk',
    }];
    mutations.setOntologyMap(state, ontologyMap);
    expect(state.ontologyMap).toBe(ontologyMap);
  });

  test('setMaxHistoryLength', () => {
    const state = createState();
    mutations.setMaxHistoryLength(state, 10);
    expect(state.maxHistoryLength).toBe(10);
    mutations.setMaxHistoryLength(state, 20);
    expect(state.maxHistoryLength).toBe(20);
  });
});
