<template>
  <div>
    <div class="topBar">
      <div class="topic"> Overview </div>
    </div>
    <OverviewMarkdown class="markdownStyle" />
  </div>
</template>

<script>
import OverviewMarkdown from './Overview.md';

export default {
  name: 'OverviewPage',
  components: {
    OverviewMarkdown,
  },
};
</script>


<style scoped lang="scss">
@import "../markdown.scss";
.topBar {
  height: 152px;
  background: $blue-200;
}
.topic {
  padding: 60px 100px;
  font-size: 36px;
  font-weight: $weight-normal;
}
</style>
