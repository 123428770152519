<template>
  <div>
    <div class="table-headers">
      <h1 class="label-lg text-capitalize font-blue">{{ item.class }}</h1>
      <SimplePagination
        :total-items="item.individuals.length"
        :page-size="calculateItemsPerPage(item.individuals)"
        :page="offsetPage"
        @page-change="handlePageChange"
        data-cy="class-table-pagination"
      ></SimplePagination>
    </div>
    <div class="border rounded">
      <b-table
        hover
        tbody-tr-class="table-row-style"
        thead-class="d-none"
        :items="item.individuals"
        :fields="[
        { key: 'subIndividual', label: '', tdClass: 'font-secondary'},
        { key: 'controls', label: '', tdClass: 'fixed-width'}
      ]"
        @row-hovered="rowHover"
        @row-unhovered="rowUnhover"
        :current-page="currentPage"
        :per-page="calculateItemsPerPage(item.individuals)"
      >
        <template #cell(subIndividual)="data">
          <span
            class="paragraph p-0 text-left table-individual"
            @click="onClickIndividual(data.item)"
          >{{ data.item.subIndividual }}</span>
          <DetailPopover
            v-if="config.showViewDetailsAction"
            :data="data.item"
            :individualId="data.item.id"
            @on-click-individual="onClickIndividual(data.item)"
          />
        </template>
        <template #cell(controls)="data">
          <div v-if="!data.item.isHover">
            <Badge
              pill
              class="float-right text-capitalize align-self-center"
              :style="{'background-color': data.item.backgroundColor, 'color': data.item.color}"
            >{{data.item.class}}</Badge>
          </div>
          <div
            v-else
            class="float-right"
          >
            <b-button
              class="search-button"
              variant="link"
              @click="searchButtonClick(data)"
              size="sm"
              data-cy="class-table-search-button"
            >
              <font-awesome-icon
                class='mx-1 mb-1'
                :icon="['fas', 'search']"
              />
              Search
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DetailPopover from './DetailPopover';

const maxRowsPerPage = 5;

export default {
  name: 'OntologySearchClassTable',
  data () {
    return {
      config: {
        showViewDetailsAction: false,
      },
      currentPage: 1,
      itemsPerPage: 5,
      maxPageItems: 5,
    };
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    triggerCalculatePageNumber: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DetailPopover,
  },
  computed: {
    ...mapGetters('advancedSearch', [
      'inputIndividual',
      'individualId',
    ]),
    ...mapGetters('advancedSearch/ontologySearch', [
      'relationsMax',
      'nodes',
      'edges',
    ]),
    offsetPage () {
      return this.currentPage - 1;
    },
  },
  mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
    if (this.relationsMax < maxRowsPerPage) {
      this.itemsPerPage = this.relationsMax;
      this.maxPageItems = this.relationsMax;
    } else {
      this.itemsPerPage = maxRowsPerPage;
      this.maxPageItems = maxRowsPerPage;
    }
  },
  watch: {
    individualId (value) {
      if (value) {
        this.recalculatePageNumbers();
        this.calculatePageNumbers();
      }
    },
    async nodes (value) {
      if (value) {
        this.recalculatePageNumbers();
      }
    },
    triggerCalculatePageNumber (value) {
      this.recalculatePageNumbers();
    },
  },
  methods: {
    rowHover (item) {
      const currentHoverItem = item;
      currentHoverItem.isHover = true;
    },
    rowUnhover (item) {
      const currentHoverItem = item;
      currentHoverItem.isHover = false;
    },
    searchButtonClick (data) {
      const clickedIndividual = `${data.item.class}:"${data.item.subIndividual}"`;
      this.$emit('on-click-search', clickedIndividual);
    },
    handlePageChange (page) {
      this.currentPage = page;
    },
    recalculatePageNumbers () {
      this.currentPage = 1;
      this.itemsPerPage = 5;
    },
    calculatePageNumbers () {
      // without maxPageItems, when user selects different nodes without refreshing of page,
      // the itemsPerPage may not be updated correctly
      if (this.edges.length < maxRowsPerPage) {
        this.maxPageItems = this.edges.length;
        this.itemsPerPage = this.maxPageItems;
      } else {
        this.itemsPerPage = maxRowsPerPage;
        this.maxPageItems = maxRowsPerPage;
      }
    },
    onClickIndividual (value) {
      this.$emit('on-click-individual', value);
    },
    calculateItemsPerPage (totalItems) { // totalItems
      return Math.min(totalItems.length, this.itemsPerPage);
    },
  },
};
</script>
<style lang="scss" scoped>
.table-headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.search-button {
  color: $blue-500 !important;
  padding: 0 !important;
}
/deep/ tr:first-child td {
  border-top: 0 !important;
}
/deep/ .table-row-style {
  &:hover {
    background-color: $blue-200 !important;
  }
  td {
    vertical-align: middle;
  }
}
/deep/ .table {
  margin-bottom: 0;
}
/deep/ .fixed-width {
  width: 100px;
}
.table-individual {
  &:hover {
    cursor: pointer;
  }
}
</style>
