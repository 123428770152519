import OntologySearchConstants from '@/constants/ontology-search-constants';
import _startCase from 'lodash/startCase';

const MAX_NODE_LENGTH = 50;
// const MIN_EDGE_LENGTH = 100;
const MAX_EDGE_LENGTH = 300;

/* eslint no-param-reassign: ["error", { "props": false }] */

export function getColorFromString (string) {
  let num = 0;
  const stringLength = string.length - 1;
  // sum up unicode of first, middle, last character of string
  num += string.charCodeAt(0);
  num += string.charCodeAt(stringLength / 2);
  num += string.charCodeAt(stringLength);
  const chosen = num % (OntologySearchConstants.RELATION_LIST_COLOR.length);
  const colors = {
    color: OntologySearchConstants.RELATION_LIST_COLOR[chosen],
    backgroundColor: OntologySearchConstants.LIGHTEN_RELATION_LIST_COLOR[chosen],
  };
  return colors;
}

export async function getValueByKey (value, individualSearchSuggestMap) {
  const currMap = individualSearchSuggestMap;
  return Object.keys(currMap).find(key => currMap[key].toString() === value.toString());
}

export function getClassFromType (type) {
  let splittedType = [];
  if (type.toString().includes('#')) {
    splittedType = type.toString().split('#');
  } else {
    splittedType = type.toString().split('/');
  }
  return splittedType[splittedType.length - 1];
}

export function getGraphFromType (type) {
  const splittedType = type.toString().split('/');
  const currentClass = splittedType[splittedType.length - 1].split('#');
  return _startCase(currentClass[0]);
}

export function getClassAndGraphFromType (type) {
  return `${getClassFromType(type)} • ${getGraphFromType(type)}`;
}

export function populateNodesColorClass (nodes) {
  nodes.forEach((node) => {
    const nodeClass = getClassFromType(node.type);
    const colors = getColorFromString(nodeClass);
    const { backgroundColor } = colors;
    const { color } = colors;
    node.class = nodeClass;
    node.color = color;
    node.font = { color };
    node.backgroundColor = backgroundColor;
    node.title = node.label;
    if (node.label.length > MAX_NODE_LENGTH) {
      node.label = `${node.label.substring(0, MAX_NODE_LENGTH)}...`;
    }
  });
}

export function populateEdgesColorLabel (edges, showRelationName) {
  edges.forEach((edge) => {
    const newEdgeColour = {
      color: OntologySearchConstants.EDGES_COLOUR,
      highlight: OntologySearchConstants.EDGES_COLOUR,
      hover: OntologySearchConstants.EDGES_COLOUR,
    };
    edge.color = newEdgeColour;
    edge.length = MAX_EDGE_LENGTH;
    if (showRelationName) {
      const newFont = { color: OntologySearchConstants.EDGES_COLOUR };
      edge.font = newFont;
      edge.label = edge.title;
      edge.title = undefined;
    }
  });
}

export function getRelationFromEdge (showRelationName, edge) {
  return showRelationName ? edge.label : edge.title;
}

export function createTable (nodes, edges, showRelationName) {
  const currentTable = [];
  let classIndex = 0;
  for (let index = 1; index < nodes.length; index += 1) {
    const tableElement = {
      id: nodes[index].id,
      individual: nodes[0].label,
      relation: getRelationFromEdge(showRelationName, edges[index - 1]),
      subIndividual: nodes[index].title,
      isHover: false,
      class: nodes[index].class,
      color: nodes[index].color,
      backgroundColor: nodes[index].backgroundColor,
    };
    const selectedElementClass = currentTable.find(ele => ele.class === tableElement.class);
    if (selectedElementClass) {
      selectedElementClass.individuals.push(tableElement);
    } else {
      currentTable.push({
        classIndex,
        class: tableElement.class,
        individuals: [tableElement],
      });
      classIndex += 1;
    }
  }
  return currentTable;
}

export function getClassFilter (currentTable) {
  const classFilters = []; // eslint-disable-line
  currentTable.forEach((tableItem) => {
    const currentClass = tableItem.class.toString().trim();
    classFilters.push({
      docCount: tableItem.individuals.length,
      key: _startCase(currentClass),
    });
  });
  return classFilters;
}

export function getHistoryDropdown (history) {
  const historyDropdown = [];
  history.forEach((item) => {
    let value = '';
    if (typeof item.id === 'object') {
      [value] = item.id;
    } else {
      value = item.id;
    }
    historyDropdown.push({
      value,
      text: item.label,
    });
  });
  return historyDropdown.reverse(); // latest history item at the front
}

export function updateHistoryDropdown (maxLength, inputIndividual, individualId) {
  const isMoreThanMaxLength = inputIndividual.length > maxLength;
  const clickHistoryLabel = isMoreThanMaxLength ? `${inputIndividual.slice(0, maxLength)}...` : inputIndividual;
  const newHistoryItem = {
    id: individualId,
    individual: inputIndividual,
    label: clickHistoryLabel,
  };
  return newHistoryItem;
}

export function buildAutocomplete (resultsList) {
  const updatedResultsList = resultsList.map((item) => {
    const colors = item.type ? getColorFromString(item.type) : null;
    const newItem = {
      suggest: item.suggest,
      type: item.type,
      color: colors ? colors.color : null,
      backgroundColor: colors ? colors.backgroundColor : null,
      id: item.id,
    };
    return newItem;
  });
  return updatedResultsList;
}

export function initialisePropertyData (data, maxLength) {
  if (data.values.length > 1) {
    data.values = data.values.join(', ');
  }
  data.showViewMoreButton = data.values[0].length > maxLength;
  data.viewMore = !data.showViewMoreButton;
  data.truncatedValue = data.showViewMoreButton
    ? `${data.values[0].substring(0, maxLength)}...`
    : '';
  return data;
}
