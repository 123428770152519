import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSearch,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faSort,
  faCheck,
  faHeart,
  faExternalLinkAlt,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDownAlt,
  faSortAmountUp,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faTags,
  faEllipsisV,
  faTimes,
  faPlus,
  faAngleUp,
  faAngleDown,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faFileAlt,
  faEllipsisH,
  faHeartBroken,
  faQuestionCircle,
  faTrashAlt,
  faPencilAlt,
  faSave,
  faBook,
  faSitemap,
  faTh,
  faCheckCircle,
  faArrowRight,
  faDatabase,
  faSpider,
  faList,
  faThLarge,
  faChevronLeft,
  faSlidersH as fasSlidersH,
  faTools as fasTools,
  faChartArea,
  faInfoCircle as fasInfoCircle,
  faPen as fasPen,
  faCloudUploadAlt,
  faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import {
  faPen,
  faTrashAlt as falTrashAlt,
  faCog,
  faFile as falFile,
  faCookieBite as falCookieBite,
  faSyncAlt as falSyncAlt,
  faTags as falTags,
  faFolderTree as falFolderTree,
  faChartNetwork as falChartNetwork,
  faMindShare as falMindShare,
  faFilter as falFilter,
  faLayerGroup as falLayerGroup,
  faShareAlt as falShareAlt,
  faInfoCircle as falInfoCircle,
  faSlidersH as farSlidersH,
  faLightbulbOn,
  faTools as falTools,
  faSave as falSave,
  faPrint as falPrint,
  faArrowUp,
  faEye,
  faExternalLink,
  faEnvelopeOpenText,
  faSearch as falSearch,
} from '@/assets/pro-light-svg-icons';
import {
  faShareAlt,
  faEllipsisH as farEllipsisH,
  faEye as farEye,
  faSpinnerThird,
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
  faChevronRight as farChevronRight,
  faChevronLeft as farChevronLeft,
  faQuestionCircle as farQuestionCircle,
} from '@/assets/pro-regular-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

library.add(
  faSearch,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSort,
  faCheck,
  faHeart,
  faExternalLinkAlt,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faTags,
  faEllipsisV,
  faTimes,
  faPlus,
  faAngleUp,
  faAngleDown,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faEllipsisH,
  faFileAlt,
  falFile,
  faHeartBroken,
  faQuestionCircle,
  farQuestionCircle,
  faTrashAlt,
  faPencilAlt,
  faSave,
  faBook,
  faSitemap,
  faTh,
  faCheckCircle,
  faPen,
  faShareAlt,
  falTrashAlt,
  faCog,
  farEllipsisH,
  farEye,
  faSpinnerThird,
  faArrowRight,
  falCookieBite,
  falSyncAlt,
  falTags,
  faDatabase,
  faSpider,
  falFolderTree,
  falChartNetwork,
  falMindShare,
  falFilter,
  falLayerGroup,
  farChevronDown,
  farChevronUp,
  farChevronRight,
  farChevronLeft,
  faList,
  faThLarge,
  falShareAlt,
  falInfoCircle,
  faChevronLeft,
  fasSlidersH,
  farSlidersH,
  fasTools,
  falTools,
  faLightbulbOn,
  falSave,
  faChartArea,
  falPrint,
  faArrowUp,
  fasInfoCircle,
  faEye,
  faExternalLink,
  faEnvelopeOpenText,
  falSearch,
  fasPen,
  faCloudUploadAlt,
  faFileDownload,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
