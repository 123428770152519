import defaultState from './state';

export default {
  setPreviewViewerModalOpen (state, previewViewerModalOpen) {
    state.previewViewerModalOpen = previewViewerModalOpen;
  },

  setDocumentDetails (state, documentDetails) {
    state.documentDetails = documentDetails;
  },

  setTextContent (state, textContent) {
    state.textContent = textContent;
  },

  resetPreviewViewer (state) {
    Object.assign(state, defaultState());
  },
};
