<template>
  <b-dropdown
    no-caret
    variant="link"
    toggle-class="btn-sm"
  >
    <template #button-content>
      <icon-text
        v-if="!disableLabel"
        faType="fal"
        faName="cog"
        text="Settings"
        size="sm"
      ></icon-text>
      <font-awesome-icon
        v-else
        class="icon"
        :icon="['fal', 'cog']"
        size="1x"
      />
    </template>
    <b-dropdown-item
      v-for="tab in activeTabs"
      :key="tab.id"
      @click="$emit('click', tab.id)"
    >
      {{ tab.label }}
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>
import IconText from '../IconText.vue';
import DocManagerConstants from '../../constants/doc-manager-service-constants';

export default {
  name: 'SettingsDropdown',
  components: {
    IconText,
  },
  props: {
    disableLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeTabs () {
      return this.tabs.filter(tab => tab.disabled === false);
    },
  },
  data () {
    return {
      tabs: DocManagerConstants.getTabsConfig(),
    };
  },
};
</script>
