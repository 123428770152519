var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.totalPages > 0)?_c('div',{staticClass:"t-pagination"},[_vm._t("default",function(){return [_c('div',{staticClass:"page-label"},[_vm._v(_vm._s(_vm.start + 1)+" - "+_vm._s(_vm.end)+" of "+_vm._s(_vm.totalItems))])]},{"start":_vm.start + 1,"end":_vm.end,"total":_vm.totalItems}),(_vm.totalPages > 1)?_c('div',{staticClass:"d-flex"},[_vm._t("buttons",function(){return _vm._l((_vm.buttons),function(button,key){return _c('button',{key:key,class:[
          'button',
          'button-page',
          _vm.buttonNavClass(key),
          _vm.buttonHover(key, button),
          _vm.buttonActive(button),
        ],attrs:{"disabled":button.disabled,"title":button.title},on:{"click":function($event){return _vm.pageChange(button)}}},[(button.loading)?_c('i',{staticClass:"fa fa-spinner fa-spin"}):[(button.html === 'iconArrowBack')?_c('iconArrowBack'):(button.html === 'iconArrowNext')?_c('iconArrowNext'):_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(button.html),expression:"button.html"}]})]],2)})},{"buttons":_vm.buttons})],2):_vm._e(),_c('div',[_c('span',{staticClass:"items-per-page-label"},[_vm._v("Items per page")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.localItemsPerPage),expression:"localItemsPerPage"}],staticClass:"items-per-page-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.localItemsPerPage=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.changeItemsPerPage]}},_vm._l((_vm.itemsPerPageOptions),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(_vm._s(option))])}),0)])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }