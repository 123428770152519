<template>
  <div id="text-viewer">
    <div class="title mb-xs label-xl" v-dompurify-html="title" />
    <div class="subtitle mb-xs text-sm">{{subtitle}}</div>
    <div class="paragraph" v-dompurify-html="textContent" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('previewViewer', ['title', 'subtitle', 'textContent']),
  },
};
</script>

<style lang="scss" scoped>
#text-viewer {
  width: 50vw;
  max-width: 680px;
  height: inherit;
  display: table;

  .title {
    font-weight: $weight-semi-bold !important;
  }

  .subtitle {
    color: $blue-gray-400;
  }
}
</style>
