import Api from './api/api.service';
import ConfigService from './config.service';

const SEARCH_ENDPOINT = ConfigService.getSearchSearchContext();

export default {
  getTrendingIndividuals ({ lookbackDays, size }) {
    return Api().get(`${SEARCH_ENDPOINT}/trendingIndividuals`, {
      params: {
        lookbackDays,
        size,
      },
    }).then(response => response.data);
  },
  postIndividualQueryMetrics (requestParameter) {
    return Api().post(`${SEARCH_ENDPOINT}/individual_query_metrics`, requestParameter);
  },
};
