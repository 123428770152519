<template>
  <IcrsWrapperPage>
    <div class="create-new-page">
      <div class="container container-fluid">
        <div :class="{['d-none'] : isLoadingPage}" class="create-new-page-content">
          <div class="page-title">{{this.newPageCurrent ? 'Edit' : 'Create New'}} Page / Link</div>
          <div class="page-content">
            <b-alert
              class="has-icon alert-large"
              :show="dismissCountDown"
              dismissible
              variant="error"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
            >
              <div style="display: flex">
                <span class="has-icon"><i class="fa fa-check-circle"></i></span>
                <div class="alert-content">
                  <h4>Error</h4>
                  <p>{{ errorMessage }}</p>
                </div>
              </div>
            </b-alert>
            <div class="content-container top-content">
              <BasicInformation :page="page" :setValue="setValue" :setEditor="setEditor"
                :checkPageCurrent="checkPageCurrent"/>
            </div>
            <div v-if="page.type === 'Page'" class="bottom-content">
              <div class="content-container left-content">
                <!-- Page content go here -->
                <div class="left-content-header">
                  Page Content
                </div>
                <div class="left-content-body">
                  <div class="btn-group btn-group-toggle text-type-toggle">
                    <button
                      class="btn"
                      :class="{
                        'btn-primary': textType === richText,
                        'btn-outline-primary': textType !== richText
                      }"
                      @click="changeTextType"
                    >
                      Rich-text
                    </button>
                    <button
                      class="btn"
                      :class="{
                        'btn-primary': textType === htmlText,
                        'btn-outline-primary': textType !== htmlText
                      }"
                      @click="changeTextType"
                    >
                      HTML
                    </button>
                  </div>
                  <div class="text-area">
                    <RichTextEditor
                      v-model="richTextValue"
                      v-show="textType === richText"
                      ref="richTextEditor"
                    />
                    <textarea
                      v-model.trim="richTextValue"
                      v-show="textType === htmlText"
                      class="html-text"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-btn-group">
            <button v-if="page.type === 'Page'" @click="handlePreview" class="btn btn-cancel">Preview</button>
            <button @click="handleCancel" class="btn btn-cancel">Cancel</button>
            <button @click="handleSave" class="btn btn-primary" :class="{
              'disabled': isDisabled
            }"
            :disabled="isDisabled">
              <font-awesome-icon
                v-if="isLoadingButton"
                class="icon"
                :icon="['far', 'spinner-third']"
              />
              {{ isLoadingButton ? '' : 'Save' }}
            </button>
          </div>
        </div>
        <div :class="{['d-flex'] : isLoadingPage, ['d-none'] : !isLoadingPage}"
          class="align-items-center justify-content-center"
          style="margin: 200px 0px 100px 0px">
          <LoadingSpinner size="lg" />
        </div>
      </div>
    </div>
  </IcrsWrapperPage>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import RichTextEditor from '@/components/rich-text-editor/RichTextEditor.vue';
import PageManagementService from '@/services/page-management.service';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import DOMPurify from 'dompurify';
import IcrsWrapperPage from '../icrs-wrapper-page/IcrsWrapperPage.vue';
import BasicInformation from './components/basic-information/BasicInformation.vue';

const textTypeOptions = ['RichText', 'HTMLText'];

export default {
  name: '',
  components: {
    IcrsWrapperPage,
    BasicInformation,
    RichTextEditor,
    LoadingSpinner,
  },
  data () {
    return {
      richTextValue: '',
      textType: textTypeOptions[0],
      richText: textTypeOptions[0],
      htmlText: textTypeOptions[1],
      page: {
        type: 'Page',
        title: '',
        content: '',
        checkPage: '',
        link: '',
      },
      checkPageCurrent: '',
      isLoadingPage: true,
      isLoadingButton: false,
      dismissCountDown: 0,
      errorMessage: '',
    };
  },
  methods: {
    ...mapActions('pageManagement', ['setUpdating', 'execGetBottomNavList',
      'execGetFooterList', 'setPreviewData']),
    setValue (key, value) {
      this.page[key] = value;
    },
    setEditor (value) {
      if (this.$refs.richTextEditor && value) this.$refs.richTextEditor.content = value;
    },
    changeTextType () {
      if (this.textType === this.richText) {
        this.textType = this.htmlText;
      } else {
        // this.richTextValue = this.$refs.richTextEditor.content;
        this.$refs.richTextEditor.content = this.richTextValue;
        this.textType = this.richText;
      }
    },
    async handleSave () {
      if (!this.isDisabled) {
        try {
          this.isLoadingButton = true;
          const newPage = { ...this.page };
          newPage.checkPage = this.checkPage;
          delete newPage.link;
          newPage.title = DOMPurify.sanitize(this.page.title);
          const submitLink = this.page.link.startsWith('https://') ? this.page.link : `https://${this.page.link}`;
          newPage.content = DOMPurify.sanitize(newPage.type === 'Link' ? submitLink : this.richTextValue);
          if (!newPage.id) {
            delete newPage.id;
            await PageManagementService.addNewPage(newPage);
            this.setUpdating('create');
          } else {
            await PageManagementService.editNewPage(newPage);
            this.setUpdating('edit');
          }
          this.errorMessage = '';
          this.$router.push('/page-management');
          if (this.checkPage === 'Navigation') {
            this.execGetBottomNavList();
          } else this.execGetFooterList();
        } catch (error) {
          if (error.response.data === 'PageEntity already exists !') {
            this.isLoadingButton = false;
            this.errorMessage = 'A page with the same name as current page/link exists.';
            this.dismissCountDown = 10;
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          }
          if (error.response.data === 'Link url invalid;') {
            this.isLoadingButton = false;
            this.errorMessage = 'Submitted link is invalid.';
            this.dismissCountDown = 10;
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          }
        }
      }
    },
    handlePreview () {
      const currentPage = { ...this.page };
      currentPage.checkPage = this.checkPage;
      this.setPreviewData(currentPage);
      this.$router.push('/page-management/preview');
    },
    handleCancel () {
      this.$router.push('/page-management');
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
      if (this.dismissCountDown === 0) {
        this.dismissCountDown = 0;
      }
    },
    decodedImage (base64Image) {
      const parts = base64Image.split(';');
      const mimType = parts[0].split(':')[1];
      const imageData = parts[1].split(',')[1];

      const raw = window.atob(imageData);
      const rawLength = raw.length;
      const array = new Uint8Array(new ArrayBuffer(rawLength));

      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < rawLength; j++) {
        array[j] = raw.charCodeAt(j);
      }

      const blob = new Blob([array], { type: mimType });
      return blob;
    },
  },
  async mounted () {
    if (!this.checkPage) {
      this.$router.push('/page-management');
      return;
    }

    // check if has preview data
    if (this.previewData) {
      this.page = { ...this.previewData };
      if (this.previewData.type === 'Page' && this.$refs.richTextEditor) {
        this.$refs.richTextEditor.content = this.previewData.content;
        this.richTextValue = this.previewData.content;
      }
      this.isLoadingPage = false;
      return;
    }

    // if not call api get details
    if (this.newPageCurrent) {
      this.setPreviewData(undefined);
      const { data } = await PageManagementService.getPageDetail(this.newPageCurrent);
      if (!data) return;
      this.page = { ...data, link: data.type === 'Link' ? data.content : '' };
      if (data.type === 'Page' && this.$refs.richTextEditor) {
        this.$refs.richTextEditor.content = data.content;
        this.richTextValue = data.content;
      }
      this.checkPageCurrent = data.type;
      this.isLoadingPage = false;
      return;
    }
    this.isLoadingPage = false;
  },
  computed: {
    ...mapGetters('pageManagement', ['newPageCurrent', 'checkPage', 'updating', 'previewData']),
    isDisabled () {
      const linkTypeValid = this.isValidTitle && this.isValidLink && this.page.type === 'Link';
      const pageTypeValid = this.isValidTitle && this.richTextValue && this.hasValidImage && this.page.type === 'Page';
      return !(linkTypeValid || pageTypeValid);
    },
    isValidTitle () {
      return this.page.title && this.page.title.trim().length <= 255;
    },
    isValidLink () {
      const urlRegex = /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#?&//=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%"+"._\\+~#?&//=]*)$/;
      return urlRegex.test(`https://${this.page.link}`);
    },
    hasValidImage () {
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.richTextValue, 'text/html');
      const images = doc.getElementsByTagName('img');

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < images.length; i++) {
        const decodedImage = this.decodedImage(images[i].src);
        const fileType = decodedImage.type;
        if (!fileType.startsWith('image')) return false;

        // if this is a valid image, check for the size larger than 2MB
        const fileSize = decodedImage.size;
        if (fileSize > 2 * 1024 * 1024) return false;
      }
      return true;
    },
  },
  watch: {
    richTextValue (val) {
      if (this.page.type === 'Page') {
        this.page.content = val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-new-page {
  margin-top: $nav-bar-height-breadcrumbs;
  .btn.btn-primary.disabled {
    cursor: not-allowed;
  }
  .btn.btn-primary {
    &/deep/ svg {
      animation: spin 500ms ease-in forwards infinite;
    }
  }
  .create-new-page-content{
    margin-left: 12px;
    .page-title {
      font-weight: 600;
      padding: 30px 0 16px 0;
      font-size: $page-title;
    }
    .page-content{
      margin-bottom: 24px;
      .content-container{
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0px 3px 6px #0000000D;
      }

      .top-content {
        margin-bottom: 16px;
      }
      .bottom-content {
        min-height: 546px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        .left-content {
          min-height: 546px;
          width: 100%;
          .left-content-header {
            height: 50px;
            border-bottom: 1px solid #e0e0e0;
            font-size: 16px !important;
            line-height: 21px !important;
            font-weight: 600 !important;
            padding-left: 16px;
            // font: normal normal medium 16px/21px Roboto;
            letter-spacing: 0px;
            color: #212529;
            display: flex;
            align-items: center;
          }
          .left-content-body {
            padding: 16px 16px 24px;
            display: flex;
            flex-direction: column;
            .text-type-toggle {
              display: flex;
              justify-content: flex-end;
              margin-bottom: 16px;
              button {
                flex: none;
              }
            }

            .html-text {
              height: 400px;
              width: 100%;
              resize: none;
            }
          }
        }
        .right-content{
          min-height: 546px;
          width: 25%;
        }
      }
    }
    .footer-btn-group{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      .btn{
        // font: normal normal normal 16px/21px Roboto;
        height: 42px;
      }
      .btn-cancel {
        border: 1px solid #283c61;
        color: #283c61;
        &:hover {
          color: #000 !important;
          border: 1px solid #283c61 !important;
        }
      }
    }
  }
}
</style>
