import IcrsPageManagement from '../pages/icrs-page-management/IcrsPageManagement.vue';
import IcrsDataPage from '../pages/icrs-data-page/IcrsDataPage.vue';
import CreateNewPage from '../pages/icrs-create-new-page/IcrsCreateNewPage.vue';
import IcrsPreviewPage from '../pages/icrs-preview-page/IcrsPreviewPage.vue';


const pageManagementRoutes = [{
  path: '/page-management',
  name: 'Page Management',
  component: IcrsPageManagement,
},
{
  path: '/page-management/create-new-page',
  name: 'Create New Page / Link',
  component: CreateNewPage,
},
{
  path: '/add-edit-data',
  name: 'Add/Edit Data',
  component: IcrsDataPage,
},
{
  path: '/page-management/edit-page',
  name: 'Edit Page / Link',
  component: CreateNewPage,
},
{
  path: '/page-management/preview/:id?',
  name: 'Preview',
  component: IcrsPreviewPage,
}];

export default pageManagementRoutes;
