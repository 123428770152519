import FilterGroup from '@/services/models/search/filter-group.model';
import constants from '@/constants/search-service-constants';
import searchService from '@/services/search.service';
import { createTestAction } from '@/helpers/testing';
import * as actions from '../actions';

jest.mock('@/services/search.service', () => ({
  getFilterGroupings: jest.fn(),
}));

const testAction = createTestAction(expect);

describe('search-core/filters action tests', () => {
  test('setSelectedFilters', () => {
    const commit = jest.fn();
    const dispatch = jest.fn();
    actions.setSelectedFilters({ commit, dispatch }, []);

    expect(commit).toHaveBeenLastCalledWith('setSelectedFilters', []);
    expect(dispatch).toHaveBeenLastCalledWith('searchService/updateCurrentSearchParameter', null, { root: true });
  });

  test('setModalSelectedFilterGroup', () => {
    const commit = jest.fn();
    const filterGroup = new FilterGroup({
      name: 'caa',
      docCount: 1,
      bucket: [],
      displayName: 'CAA',
    });
    actions.setModalSelectedFilterGroup({ commit }, filterGroup);

    expect(commit).toHaveBeenLastCalledWith('setModalSelectedFilterGroup', filterGroup);
  });

  test('setModalSelectedFilters', () => {
    const commit = jest.fn();
    actions.setModalSelectedFilters({ commit }, []);

    expect(commit).toHaveBeenLastCalledWith('setModalSelectedFilters', []);
  });

  test('applyModalSelectedFilters', () => {
    const commit = jest.fn();
    const filterGroup = new FilterGroup({
      name: 'caa',
      docCount: 1,
      bucket: [],
      displayName: 'CAA',
    });
    actions.applyModalSelectedFilters({ commit }, filterGroup);

    expect(commit).toHaveBeenLastCalledWith('applyModalSelectedFilters', filterGroup);
  });

  test('setModalSearchKeyword', () => {
    const commit = jest.fn();
    actions.setModalSearchKeyword({ commit }, 'a keyword');

    expect(commit).toHaveBeenLastCalledWith('setModalSearchKeyword', 'a keyword');
  });

  test('setModalSortBy', () => {
    const commit = jest.fn();
    actions.setModalSortBy({ commit }, constants.FILTER_SORT_BY_OPTIONS.A_TO_Z);

    expect(commit).toHaveBeenLastCalledWith('setModalSortBy', constants.FILTER_SORT_BY_OPTIONS.A_TO_Z);
  });

  test('setIsFirstOpenHasSelectedFilters', () => {
    const commit = jest.fn();
    actions.setIsFirstOpenHasSelectedFilters({ commit }, true);

    expect(commit).toHaveBeenLastCalledWith('setIsFirstOpenHasSelectedFilters', true);
  });
  test('getFilterGroupings', (done) => {
    const ctx = {};
    const response = [
      {
        groupName: 'General',
        filters: [
          {
            classId: 'subject',
          },
        ],
      },
    ];
    searchService.getFilterGroupings.mockReturnValueOnce(Promise.resolve(response));
    testAction(actions.getFilterGroupings, null, ctx, [
      { type: 'setFilterGroupings', payload: response },
    ], done);
  });
});
