import mutations from '../advanced-search/mutations';
import actions from './actions';

const kmService = {
  namespaced: true,
  mutations,
  actions,
};

export default kmService;
