<template>
  <b-card
    ref="pillar"
    :bg-variant="`${variant}_alpha`"
    :body-text-variant="'blue-gray-600'"
    class="pillarCard"
  >
    <icon-badge class="bordered" :variant="variant">
      <template v-slot:icon>
        <font-awesome-icon v-if="isFa" :icon="imgUrl" size="lg" />
      </template>
    </icon-badge>
    <b-card-title class="darken">{{title}}</b-card-title>
    <b-card-sub-title class="mb-2 darken" :sub-title-text-variant="'blue-gray-600'">{{subtitle}}</b-card-sub-title>
    <component class="pillarContent" :is="content" v-bind="{variant : variant}"></component>
    <component
      class="pillarContent"
      v-if="additionalContent"
      :is="additionalContent.content"
      v-bind="{variant : additionalContent.variant}"
    ></component>
  </b-card>
</template>

<script>
import IconBadge from '@/components/IconBadge.vue';

export default {
  components: {
    IconBadge,
  },
  props: {
    title: {
      default: 'Default Title',
      type: String,
    },
    subtitle: {
      default: 'Default Subitle',
      type: String,
    },
    imgUrl: {},
    variant: {
      type: String,
    },
    isFa: {
      type: Boolean,
      default: true,
    },
    content: {
      type: Object, // array of components
    },
    additionalContent: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped lang='scss'>

$variant-list:(
  'blue-gray-600' : $blue-gray-600,
  'blue-gray-500' : $blue-gray-500,
  'blue-gray-400' : $blue-gray-400,
  'blue-gray-300' : $blue-gray-300,
  'blue-400' : $blue-400,
  'blue-500' : $blue-500,
);

@each $key,$val in $variant-list{

  .bg-#{$key}{
    background-color: #{$val};
  }
  ::v-deep .btn-#{$key} {
    color: $white-500;
    background-color: #{$val};
  }
  ::v-deep .btn-#{$key}:hover {
    color: $white-500;
    background-color: #{$val};
  }
  .text-#{$key}{
    color: #{$val};
  }
}

.bg-blue-gray-600_alpha{
  background-color: transparentize($blue-gray-600, 0.5);
}
.bg-blue-gray-500_alpha{
  background-color: transparentize($blue-gray-500, 0.5);
}
.bg-blue-gray-400_alpha{
  background-color: transparentize($blue-gray-400, 0.5);
}
.bg-blue-gray-300_alpha{
  background-color: transparentize($blue-gray-300, 0.5);
}
.bg-blue-400_alpha{
  background-color: transparentize($blue-400, 0.5);
}
.bg-blue-500_alpha{
  background-color: transparentize($blue-500, 0.5);
}

.bg-blue-gray-100{
  background-color: $blue-gray-100;
}
.bg-blue-gray-100_alpha{
  background-color: transparentize($blue-gray-100, 0.5);
}


.pillarCard {
  width: 20em !important;
  margin: 0 !important;
  border-radius: 0 !important;
  border-width: 0;

  ::v-deep .card-title{
    font-size: $font-lg;
    text-align: center;
  }
  .card-subtitle{
    font-size: $font-md;
    text-align: center;
  }
}
.pillarCard:first-child {
  border-radius: 35px 0 0 35px !important;
}
.pillarCard:last-child {
  border-radius: 0 35px 35px 0 !important;
}
.pillarCard > * {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.pillarContent {
  border-radius: 30px;
}
.bordered {
  border: 5px solid $blue-gray-50;
}
.iconbadge {
  margin: 20px auto;
  margin-top: -60px;
}
</style>
