<template>
  <div class="login-page">
    <div
      v-if="!useICRSConfig"
      class="login-illustration"
    >
      <div class="login-illustration__logo">
        <Logo />
      </div>
    </div>

    <div
      v-if="useICRSConfig"
      class="icrs-login-illustration"
    >
      <div
        class="login-illustration__logo"
        style="top: 65px; left: 135px"
      >
        <img src="/icrs/images/sal-logo.png" />
      </div>
    </div>

    <div
      class="right-section"
      style="background-color: #fff"
    >
      <div class="login-form-wrapper">
        <LoginForm @logged-in="handleLoggedIn" />
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '../../modules/login/Login.vue';
import Logo from '../../assets/taiger-logo-white-horizontal.svg';
// import ICRSLogo from '../../../public/icrs/images/logo-white.svg';

export default {
  components: {
    LoginForm,
    // ICRSLogo,
    Logo,
  },
  data () {
    return {
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
    };
  },
  methods: {
    /**
     * @param {object}  event
     * @param {boolean} event.isLoggedIn
     * @param {string?} event.redirect
     */
    handleLoggedIn ({ isLoggedIn, redirect }) {
      if (isLoggedIn) {
        const to = redirect || '/';
        this.$router.push(to);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
}

.login-illustration {
  position: relative;
  background-image: url(../../assets/login-illustration.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 440px;
  min-height: 100vh;

  &__logo {
    position: absolute;
    top: 48px;
    left: 48px;
  }
}

.login-form-wrapper {
  width: 360px;
  margin: 0 auto;
}

.right-section {
  flex: 1 1 0%;
  display: flex;
  align-items: center;
}
</style>
