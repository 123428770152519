import defaultState from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const promotedContent = {
  namespaced: true,
  state: defaultState(),
  getters,
  actions,
  mutations,
};

export default promotedContent;
