export default {
  guidedSearch: state => state.guidedSearch,
  dropdownGuidedTag: state => state.dropdownGuidedTag,
  activeGuidedPrefix: state => state.activeGuidedPrefix,
  selectedLevel1: state => state.selectedLevel1,
  selectedLevel2: state => state.selectedLevel2,
  selectedLevel3: state => state.selectedLevel3,
  level2CategoriesForSelectedLevel1: state => state.level2CategoriesForSelectedLevel1,
  level3CategoriesForSelectedLevel2: state => state.level3CategoriesForSelectedLevel2,
  allGuidedCategories: state => state.allGuidedCategories,
  advancedSearchSelectedLevel: state => state.advancedSearchSelectedLevel,
};
