<template>
  <div class="content_logo_text">
    <slot name="icon"></slot>
    <span>{{text}}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>

<style lang="scss" scoped>
.content_logo_text {
  display: flex;
  padding: 0.3em 0;
}
.content_logo_text > span {
  margin-left: 0.5em;
  line-height: 32px; // height of logo
}
</style>
