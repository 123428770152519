var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icrs-guided-search-boxes-section",class:{'is-modal': _vm.isModal}},[_c('div',{staticClass:"row guided-row"},[((!_vm.mobileMode && _vm.activeGuidedPrefix >= 0)
      || (_vm.mobileMode && _vm.activeGuidedPrefix === 0 && _vm.selectedLevel1.code === 'All'))?_c('div',{staticClass:"col-md-4 guided-column level-1-column level-item"},[_c('div',{staticClass:"level-text"},[_vm._v("Level 1")]),(!_vm.mobileMode)?_c('div',{staticClass:"guided-options"},_vm._l((_vm.level1Categories),function(category,index){return _c('div',{key:`level1_${category.code}`,staticClass:"guided-option guided-1-option",class:{
            'selected-guided-option': category.code === _vm.selectedLevel1.code && category.code !== 'All',
            'selected-guided-1-option': category.code === _vm.selectedLevel1.code
          },on:{"mouseover":function($event){return _vm.handleSearch(0, category)}}},[(index === 0)?_c('hr',{staticClass:"guided-divider"}):_vm._e(),_c('p',{staticClass:"guided-option-label"},[(category.elitLabel.includes('Head'))?_c('head-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Neck'))?_c('neck-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Back'))?_c('back-thoracic-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Upper'))?_c('upper-limb-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Torso'))?_c('torso-internal-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Lower'))?_c('lower-limb-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Miscellaneous'))?_c('miscellaneous-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Psychiatric'))?_c('psychiatric-svg',{staticClass:"guided-icon"}):_vm._e(),_vm._v("\n             \n            "),_c('span',{staticClass:"guided-option-text"},[_vm._v(_vm._s(_vm.formatGuidedDisplay(category.elitLabel || category.code)))]),(category.code === _vm.selectedLevel1.code &&
              category.code !== 'All')?_c('font-awesome-icon',{staticClass:"guided-option-icon",attrs:{"icon":['fa', 'chevron-right'],"size":"lg"}}):_vm._e()],1),_c('hr',{staticClass:"guided-divider"})])}),0):_vm._e(),(_vm.mobileMode)?_c('div',{staticClass:"guided-options"},_vm._l((_vm.level1Categories),function(category,index){return _c('div',{key:`level1_${category.code}`,staticClass:"guided-option guided-1-option",class:{
            'selected-guided-option': category.code === _vm.selectedLevel1.code && category.code !== 'All',
            'selected-guided-1-option': category.code === _vm.selectedLevel1.code
          },on:{"click":function($event){return _vm.handleSearch(0, category)}}},[(index === 0)?_c('hr',{staticClass:"guided-divider"}):_vm._e(),_c('p',{staticClass:"guided-option-label"},[(category.elitLabel.includes('Head'))?_c('head-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Neck'))?_c('neck-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Back'))?_c('back-thoracic-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Upper'))?_c('upper-limb-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Torso'))?_c('torso-internal-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Lower'))?_c('lower-limb-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Miscellaneous'))?_c('miscellaneous-svg',{staticClass:"guided-icon"}):_vm._e(),(category.elitLabel.includes('Psychiatric'))?_c('psychiatric-svg',{staticClass:"guided-icon"}):_vm._e(),_vm._v("\n             \n            "),_c('span',{staticClass:"guided-option-text"},[_vm._v(_vm._s(_vm.formatGuidedDisplay(category.elitLabel || category.code)))]),(category.code === _vm.selectedLevel1.code &&
              category.code !== 'All')?_c('font-awesome-icon',{staticClass:"guided-option-icon",attrs:{"icon":['fa', 'chevron-right'],"size":"lg"}}):_vm._e()],1),_c('hr',{staticClass:"guided-divider"})])}),0):_vm._e()]):_vm._e(),(!_vm.mobileMode && _vm.selectedLevel1.code === 'All')?_c('div',{staticClass:"col-md-8 get-started-column level-2-column level-item"},[_vm._m(0)]):_vm._e(),((!_vm.mobileMode && _vm.selectedLevel1.code !== 'All')
        || (_vm.mobileMode && ((_vm.activeGuidedPrefix === 0 && _vm.selectedLevel1.code !== 'All') ||
        (_vm.activeGuidedPrefix === 1 && _vm.selectedLevel2.code === 'All'))))?_c('div',{staticClass:"col-md-4 guided-column level-2-column level-item"},[_c('div',{staticClass:"level-text"},[_vm._v("Level 2")]),(!_vm.mobileMode)?_c('div',{staticClass:"guided-options"},_vm._l((_vm.level2CategoriesForSelectedLevel1),function(category,index){return _c('div',{key:`level2_${category.code}`,staticClass:"guided-option guided-2-option",class:{
            'selected-guided-option': category.code === _vm.selectedLevel2.code && category.code !== 'All',
            'selected-guided-2-option': category.code === _vm.selectedLevel2.code && category.code !== 'All'
          },on:{"mouseover":function($event){return _vm.handleSearch(1, category, 'mouse')},"click":function($event){return _vm.handleSearch(1, category, 'click')}}},[(index === 0)?_c('hr',{staticClass:"guided-divider"}):_vm._e(),_c('p',{staticClass:"guided-option-label"},[(category.code === 'All')?_c('span',{staticClass:"guided-option-text"},[_vm._v(_vm._s(_vm.formatAllGuidedDisplay(_vm.selectedLevel1.elitLabel || _vm.selectedLevel1.code)))]):_vm._e(),(category.code !== 'All')?_c('span',{staticClass:"guided-option-text"},[_vm._v(_vm._s(_vm.formatGuidedDisplay(category.elitLabel || category.code)))]):_vm._e(),(category.code === _vm.selectedLevel2.code && category.code !== 'All')?_c('font-awesome-icon',{staticClass:"guided-option-icon",attrs:{"icon":['fa', 'chevron-right'],"size":"lg"}}):_vm._e()],1),_c('hr',{staticClass:"guided-divider"})])}),0):_vm._e(),(_vm.mobileMode)?_c('div',{staticClass:"guided-options"},[_c('div',{staticClass:"guided-option guided-2-option",on:{"click":function($event){return _vm.handleSearch(1, {code : 'Previous Selection'})}}},[_c('hr',{staticClass:"guided-divider"}),_c('p',{staticClass:"guided-option-label"},[_c('span',{staticClass:"guided-option-text"},[_vm._v("Previous Selection")]),_c('font-awesome-icon',{staticClass:"guided-option-icon",attrs:{"icon":['fa', 'chevron-left'],"size":"lg"}})],1)]),_vm._l((_vm.level2CategoriesForSelectedLevel1),function(category,index){return _c('div',{key:`level2_${category.code}`,staticClass:"guided-option guided-2-option",class:{
            'selected-guided-option': category.code === _vm.selectedLevel2.code && category.code !== 'All',
            'selected-guided-2-option': category.code === _vm.selectedLevel2.code && category.code !== 'All'
          },on:{"click":function($event){return _vm.handleSearch(1, category, 'click')}}},[(index === 0)?_c('hr',{staticClass:"guided-divider"}):_vm._e(),_c('p',{staticClass:"guided-option-label"},[(category.code === 'All')?_c('span',{staticClass:"guided-option-text"},[_vm._v(_vm._s(_vm.formatAllGuidedDisplay(_vm.selectedLevel1.elitLabel || _vm.selectedLevel1.code)))]):_vm._e(),(category.code !== 'All')?_c('span',{staticClass:"guided-option-text"},[_vm._v(_vm._s(_vm.formatGuidedDisplay(category.elitLabel || category.code)))]):_vm._e(),(category.code === _vm.selectedLevel2.code && category.code !== 'All')?_c('font-awesome-icon',{staticClass:"guided-option-icon",attrs:{"icon":['fa', 'chevron-right'],"size":"lg"}}):_vm._e()],1),_c('hr',{staticClass:"guided-divider"})])})],2):_vm._e()]):_vm._e(),((!_vm.mobileMode && _vm.selectedLevel2.code !== 'All')
      || (_vm.mobileMode && ((_vm.activeGuidedPrefix === 1 && _vm.selectedLevel2.code !== 'All') || _vm.activeGuidedPrefix === 2)))?_c('div',{staticClass:"col-md-4 guided-column level-3-column level-item"},[_c('div',{staticClass:"level-text"},[_vm._v("Level 3")]),(_vm.mobileMode)?_c('div',{staticClass:"guided-option guided-3-option",on:{"click":function($event){return _vm.handleSearch(2, {code: 'Previous Selection'})}}},[_c('hr',{staticClass:"guided-divider"}),_c('p',{staticClass:"guided-option-label"},[_c('span',{staticClass:"guided-option-text"},[_vm._v("Previous Selection")]),_c('font-awesome-icon',{staticClass:"guided-option-icon",attrs:{"icon":['fa', 'chevron-left'],"size":"lg"}})],1)]):_vm._e(),_c('div',{staticClass:"guided-options"},_vm._l((_vm.level3CategoriesForSelectedLevel2),function(category,index){return _c('div',{key:`level3_${category.code}`,staticClass:"guided-option guided-3-option",class:{
            'selected-guided-option': category.code === _vm.selectedLevel3.code && category.code !== 'All',
            'selected-guided-3-option': category.code === _vm.selectedLevel3.code && category.code !== 'All'
          },on:{"click":function($event){return _vm.handleSearch(2, category)}}},[(index === 0)?_c('hr',{staticClass:"guided-divider"}):_vm._e(),(category.code === 'All')?_c('p',{staticClass:"guided-option-label"},[_vm._v(_vm._s(_vm.formatAllGuidedDisplay(_vm.selectedLevel2.elitLabel || _vm.selectedLevel2.code)))]):_vm._e(),(category.code !== 'All')?_c('p',{staticClass:"guided-option-label"},[_vm._v(_vm._s(_vm.formatGuidedDisplay(category.elitLabel || category.code)))]):_vm._e(),_c('hr',{staticClass:"guided-3-divider"})])}),0)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"get-started-text"},[_c('a',{staticStyle:{"text-decoration":"none","color":"black !important"},attrs:{"href":"/#/page-management/preview/128?from=Footer","target":"_blank"}},[_c('i',{staticClass:"fa fa-info-circle"})]),_vm._v("\n        Get started by selecting a category on the left.\n      ")])
}]

export { render, staticRenderFns }