<template>
  <div class="breadcrumbs-container">
    <div class="container container-fluid ">
      <div class="breadcrumbs-content">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  data () {
    return {};
  },

};
</script>

<style lang="scss" scoped>
.breadcrumbs-container{
  width: 100vw;
  background-color: #fff;
  position:relative;

  .breadcrumbs-content{
    height: 56px;

    display: flex;
    align-items: center;

    background-color: #fff;
    .breadcrumb {
      font-size: 15px !important;
      padding-left: 0px;
      margin-bottom: 0 !important;

      :not(:first-child){
        padding-left: 10px
      }
    }
    .breadcrumb-item{
      margin-left: 0;
      font: Roboto #751c24;
      font-size: 15px;
      user-select: none;
      a {
        text-decoration: none;

        &:focus-visible {
          outline: 2px solid gray;
        }
      }
    }
    .breadcrumb-item span{
      color: #751c24;

    }
    .breadcrumb-item + .breadcrumb-item:before{
        content: ">";
        padding-right: 10px;
    }
  }
}
</style>
