import createState from './state';
import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';
import promotedContent from './promoted-content/index.module';
import date from './date/index.module';

export default {
  namespaced: true,
  state: createState(),
  getters,
  actions,
  mutations,
  modules: {
    promotedContent,
    date,
  },
};
