/* eslint-disable import/prefer-default-export */

export function appliedFilters (state) {
  return state.selectedFilters.reduce((accum, item) => {
    if (!accum[item.groupName]) {
      // eslint-disable-next-line no-param-reassign
      accum[item.groupName] = [];
    }

    accum[item.groupName].push(item.key);

    return accum;
  }, {});
}

export function selectedFilters (state) {
  return state.selectedFilters;
}

export function selectedGroup (state) {
  return state.selectedGroup;
}

export function filterModal (state) {
  return state.filterModal;
}

export function filterModalTempTopicsQuery (state) {
  return state.filterModal.tempTopicsQuery;
}

export function filterGroupings (state) {
  return state.filterGroupings;
}
