export default {
  showNavbar ({ commit }) {
    commit('setIsNavbarShowing', true);
  },
  hideNavbar ({ commit }) {
    commit('setIsNavbarShowing', false);
  },
  configActivated ({ commit }) {
    commit('setIsConfigSet', true);
  },
  configDeactivated ({ commit }) {
    commit('setIsConfigSet', false);
  },
};
