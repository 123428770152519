 <template>
  <Modal size="lg" title="Relationship browser" id="relationship-browser-modal"
    static
    lazy
    no-close-on-backdrop
    :okTitle="buttonTitle"
    :okDisabled="okDisabled"
    @ok="okayClick"
    @hidden="onHide"
  >
    <Dropdown block label="Concepts" class="concept-dropdown" v-model="selectedConcept"
      :placeholder="'Select a concept to view its individuals'"
      :options="conceptsOptions"
      @input="handleSelectedConceptChange"
    ></Dropdown>

    <Autocomplete class="autocomplete-block"
      v-show="this.selectedConcept.length"
      v-model="inputIndividual"
      label="Individuals"
      placeholder="Search individuals"
      :data="individualSearchSuggestList"
      :minMatchingChars=1
      @input="handleIndividualSearchChange"
      @hit="handleHit"
    />

    <div class="wrapper rounded" v-show="this.individualId.length">
      <div class="history-wrapper mt-2 ml-2 mb-2">
        <span class="inline_history" v-for="(historyElement, index) in history" :key="historyElement.id">
          <div v-if="index === history.length-1" class="inline_history">
            <Button class="history" is-small pill variant="primary">
              {{historyElement.label}}
            </Button>
          </div>
          <div v-else class="inline_history">
            <Button class="history" is-small pill variant="secondary" @click="selectElement(index)">
              {{historyElement.label}}
            </Button>
          </div>
        </span>
      </div>
      <network class="wrap" ref="network"
        :nodes="nodes"
        :edges="edges"
        :options="options"
        @click="onNodeSelect"
        @hoverEdge="onHoverEdge">
      </network>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { Network } from 'vue2vis';
import 'vue2vis/dist/vue2vis.css';

export default {
  components: {
    Network,
  },

  data () {
    return {
      history: [],
      concepts: [],
      conceptsOptions: [],
      selectedConcept: '',
      individualSearchSuggestList: [],
      individualSearchSuggestMap: {},
      inputIndividual: '',
      individualId: '',
      relations: '',
      maxEdges: 20,
      nodes: [],
      edges: [],
      options: {
        autoResize: true,
        height: '100%',
        width: '100%',
        interaction: {
          hover: true,
        },
        physics: {
          enabled: true,
        },
      },
      buttonTitle: 'Browse',
      okDisabled: true,
    };
  },

  mounted () {
    this.getConcepts().then((response) => { this.concepts = response; this.conceptOptions(); });
  },
  computed: {
    ...mapGetters('searchService', [
    ]),
  },
  methods: {
    ...mapActions('searchService', [
      'execGetAutocomplete',
      'triggerSearchQuery',
    ]),
    ...mapActions('kmService', [
      'getConcepts',
      'getRelations',
    ]),
    conceptOptions () {
      this.conceptsOptions = this.concepts.map(item => ({
        value: item.id,
        text: item.label,
      }));
    },
    handleSelectedConceptChange (value) {
      this.history = [];
      this.nodes = [];
      this.edges = [];
      this.inputIndividual = '';
      this.individualId = '';
      const nextConcept = value === null
        ? undefined
        : value;

      this.selectedConcept = nextConcept;
      return this.selectedConcept;
    },
    handleIndividualSearchChange (value) {
      this.execGetAutocomplete({ textToComplete: value, type: this.selectedConcept }).then((response) => {
        this.individualSearchSuggestList = response.autocompleteSuggest.map(item => item.suggest);
        response.autocompleteSuggest.forEach((element) => {
          this.individualSearchSuggestMap[element.suggest] = element.id;
        });
      });
    },
    handleHit () {
      this.individualId = this.individualSearchSuggestMap[this.inputIndividual];
      this.history = [];
      this.history.push({ id: this.individualId, label: this.inputIndividual, concept: this.selectedConcept });
      this.constructNetwork();
      this.okDisabled = false;
    },
    constructNetwork () {
      this.getRelations(this.individualId).then((response) => {
        let slicedNodes = response.nodes;
        response.nodes.forEach((element) => {
          this.individualSearchSuggestMap[element.id] = element.label;
        });
        const slicedEdges = response.edges.slice(0, Math.min(response.edges.length, this.maxEdges));

        if (slicedEdges > this.maxEdges) {
          const toEdges = slicedEdges.map(slicedEdge => slicedEdge.to);
          const fromEdges = slicedEdges.map(slicedEdge => slicedEdge.from);
          const validNodeIds = _(toEdges).concat(fromEdges).uniq().value();
          slicedNodes = slicedNodes.filter(node => validNodeIds.indexOf(node.id) > -1);
        }

        this.nodes = slicedNodes;
        this.edges = slicedEdges;
        this.$refs.network.fit(this.nodes);
      });
    },
    onNodeSelect (value) {
      if (value.nodes[0] !== undefined && this.individualId !== value.nodes[0]) {
        this.selectedConcept = 'Select a concept to view its individuals';
        this.inputIndividual = this.individualSearchSuggestMap[value.nodes[0]];
        [this.individualId] = value.nodes;
        this.history.push({
          id: this.individualId,
          label: this.individualSearchSuggestMap[this.individualId],
          concept: this.selectedConcept,
        });
        this.constructNetwork();
      }
    },
    selectElement (index) {
      this.individualId = this.history[index].id;
      this.inputIndividual = this.history[index].label;
      this.selectedConcept = this.history[index].concept;
      this.constructNetwork();
      this.history.splice(index + 1);
    },
    okayClick () {
      if (this.inputIndividual.length === 0) {
        this.inputIndividual = this.individualSearchSuggestMap[this.individualId];
      }
      this.triggerSearchQuery({
        query: this.inputIndividual,
      });
      this.onHide();
    },
    onHide () {
      this.history = [];
      this.selectedConcept = '';
      this.individualSearchSuggestList = [];
      this.individualSearchSuggestMap = {};
      this.inputIndividual = '';
      this.individualId = '';
      this.relations = '';
      this.nodes = [];
      this.edges = [];
      this.okDisabled = true;
    },
    onHoverEdge (edgeID) {
      return this.edges[edgeID];
    },
  },
};
</script>
<style lang="scss" scoped>
.concept-dropdown {
  padding-bottom: 20px;
}
.autocomplete-block {
  padding-bottom: 30px;
}
.network {
  overflow: visible;
}
.wrap {
  width: 100%;
  height: inherit;
}
.history-wrapper{
  position: absolute;
  z-index: 10;
}
.inline_history {
  display: inline;
}
.history {
  text-transform: capitalize;
}
.wrapper {
  position: relative;
  width: 760px;
  height: 400px;
  border: 1px solid #e3e3e3;
}
</style>
