<template>
  <div>
    <h1>Screenshots</h1>
    <div class="demoWrap">
      <div class="liveDemo">
        <img :src="labelContent[activeIndex].img" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LiveDemo',
  components: {
  },
  data () {
    return {
      activeIndex: 0,
      labelContent: [
        {
          title: 'Faceted Search',
          code: '<faceted-search class="taiger_styles"/>',
          // eslint-disable-next-line global-require
          img: require('../../../../assets/ui-catalog-page/FacetedSearch.png'),
        },
      ],
    };
  },
  methods: {
    selectStyle (index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  font-size: $font-xl;
  line-height: $ln-height-xl;
  font-weight: 500;
  padding-bottom: $gap-lg;
  padding-top: 60px;
}
.demoTitleWrap {
  padding-left: $gap-lg;
  display: block;
  background-color: $blue-200;
  margin-bottom: 0px !important;
  color: $blue-gray-300;
  font-weight: $weight-light;
  padding-top: $gap-xs;
  overflow: hidden;
}
.demoTitle {
  border: none;
  padding-bottom: $gap-xs;
  float: left;
  font-size: $font-md !important;
  line-height: $ln-height-20 !important;
  margin-right: $gap-lg;
}
pre {
  margin-top: 0px;
  white-space: pre;
  margin-bottom: -29px;
}
code {
  padding-left: 60px;
  display: block;
  margin-top: 0px;
  background-color: $blue-200;
  border-bottom-left-radius: $gap-xxs;
  border-bottom-right-radius: $gap-xxs;
  border-top: 1px solid $blue-gray-100;
}
.liveDemo {
  display: block;
  padding-top: $gap-lg;
  padding-bottom: $gap-lg;
  background-color: $blue-gray-50;
}
.active {
  color: $blue-500;
  border-bottom: 2px solid $blue-500;
}
.clickable {
  cursor: pointer;
}
img {
  width: 100%;
}
</style>
