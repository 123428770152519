<template>
  <div class="keyword-suggestions">
    You may also want to try:&nbsp;
    <span
      @click="handleLinkClick(text)"
      v-for="text in suggestions"
      :key="text"
      class="query-link"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    suggestions: {
      type: Array,
      default () {
        return [];
      },
    },
  },

  methods: {
    handleLinkClick (text) {
      this.$emit('link-click', text);
    },
  },
};
</script>


<style lang="scss" scoped>
.query-link {
  color: $blue-500;
  margin-left: $gap-md;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
}
</style>
