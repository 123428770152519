import ConfigService from '@/services/config.service';
import Api from '@/services/api/api.service';
import STATIC_PAGE_DATA from './static-page-data';
import {
  ICRS_STATUS,
  GUIDED_PREFIX_IGNORE_LIST,
  FILTERS_TOPIC_TITLE_DISPLAY_ORDER,
  KEYWORDS_TOPIC_TITLE_DISPLAY_ORDER,
} from './icrs-service-constants';

const SEARCH_CONTEXT = ConfigService.getSearchSearchContext();
const ICRS_EXCEL_UPLOAD_URL = ConfigService.getICRSExcelUploadUrl();
// const ICRS_SEARCH_CONNECTOR = ConfigService.getSearchConnector();
// const GATEWAY_CONTEXT = ConfigService.getICRSSearchGraphContext();


export default {
  Constants: {
    ICRS_STATUS,
  },

  /**
   * execGetICRSBodyParts
   * @param {String} inputText query parameter
   * @returns {Array}
   */
  execGetICRSBodyParts (inputText = '') {
    return Api().get(`${SEARCH_CONTEXT}/bodypart`, {
      params: {
        inputText,
      },
    })
      .then(response => response.data)
      .catch(() => {
        console.warn('No body parts found');
        return {};
      });
  },

  /**
   * execGetICRSPreview
   * @param {Object} body TODO
   * @returns {Array} TODO
   */
  execGetICRSPreview (body) {
    return Api().post(`${SEARCH_CONTEXT}/v3/preview`, body)
      .then(response => response.data)
      .catch(() => {
        console.warn('No preview found');
        return [];
      });
  },

  /**
   * execGetCustomPlotData
   * @param {Object} searchRequestParameter
   * @returns
   */
  execGetCustomPlotData (searchRequestParameter) {
    return Api().post(`${SEARCH_CONTEXT}/v3/plot`, searchRequestParameter);
  },

  /**
   * execUploadICRSExcel
   * @param {File} file blob file to be uploaded
   * @returns {Object} { status: <success, error>, message: <message string>}
   */
  execUploadICRSExcel (file) {
    const formData = new FormData();
    formData.append('file', file);
    const responseObject = {};
    return Api().post(
      ICRS_EXCEL_UPLOAD_URL,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    ).then(({ data }) => {
      if (!data) {
        responseObject.status = ICRS_STATUS.ERROR;
        return responseObject;
      }
      const responseStatus = (data.status || {}).status || '';
      const responseMsg = (data.status || {}).message || '';
      if (responseStatus.toLowerCase().includes(ICRS_STATUS.SUCCESS)) {
        responseObject.status = ICRS_STATUS.SUCCESS;
      } else {
        responseObject.status = ICRS_STATUS.ERROR;
      }
      responseObject.message = responseMsg;
      return responseObject;
    }).catch(({ response }) => {
      responseObject.status = ICRS_STATUS.ERROR;
      if (response
        && response.data
        && response.data.status
        && response.data.status.message) {
        responseObject.message = response.data.status.message;
      }
      return responseObject;
    });
  },

  /**
   * Static Data
   */
  getKeywordsTopicTitleDisplayOrder () {
    return KEYWORDS_TOPIC_TITLE_DISPLAY_ORDER;
  },
  getFiltersTopicTitleDisplayOrder () {
    return FILTERS_TOPIC_TITLE_DISPLAY_ORDER;
  },
  getGuidedPrefixIgnoreList () {
    return GUIDED_PREFIX_IGNORE_LIST;
  },
  getGuidedCategories () {
    const responseObject = {};
    return Api().post(`${SEARCH_CONTEXT}/getallcodelabelelit`).then(({ data }) => data)
      .catch(({ response }) => {
        responseObject.status = ICRS_STATUS.ERROR;
        if (
          response
          && response.data
          && response.data.status
          && response.data.status.message
        ) {
          responseObject.message = response.data.status.message;
        }
        return responseObject;
      });
  },
  getUsefulLinks () {
    return STATIC_PAGE_DATA.USEFUL_LINKS;
  },
  getFaq () {
    return STATIC_PAGE_DATA.FAQ;
  },
  getPolicy () {
    return STATIC_PAGE_DATA.POLICY;
  },
  getTerms () {
    return STATIC_PAGE_DATA.TERMS;
  },
  getAggregations () {
    return STATIC_PAGE_DATA.AGGREGATIONS;
  },
  getFilterGroupConfig () {
    return STATIC_PAGE_DATA.FILTERGROUPCONFIG;
  },
};
