<template>
  <IcrsTemplate>
    <template #content>
      <h2 class="text-center">FAQs</h2>
      <b-form-group>
        <b-form-input
          id="faqSearch"
          type="text"
          placeholder="Search FAQs"
          v-model="faqSearchTerm"
        />
      </b-form-group>
      <div
        v-for="(answer, question) in faq"
        :key="question"
        class="card summaryCard"
      >
        <div
          class="card-header"
          @click="toggleFaq(question)"
        >
          <i
            v-if="!showFaq[question]"
            class="fa fa-chevron-down"
          ></i>
          <i
            v-if="showFaq[question]"
            class="fa fa-chevron-up"
          ></i>
          <span
            style="margin-left: 10px"
            v-dompurify-html="question"
          />
        </div>
        <b-collapse
          :visible="showFaq[question]"
          :id="question"
        >
          <div
            class="card-body"
            v-dompurify-html="answer"
          />
        </b-collapse>
      </div>
    </template>
  </IcrsTemplate>
</template>
<script>
import ICRSService from '@/services/icrs-service';
import IcrsTemplate from './template/IcrsStaticTemplate.vue';

export default {
  components: {
    IcrsTemplate,
  },
  data () {
    return {
      showFaq: {},
      faq: {},
      originalShowFaq: {},
      originalFaq: {},
      faqSearchTerm: '',
    };
  },
  watch: {
    faqSearchTerm: {
      immediate: true,
      handler (value) {
        if (value.length >= 2) {
          const tempFaq = {};
          const tempShowFaq = {};
          const escapedValue = value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
          Object.keys(this.originalFaq).forEach((question) => {
            const formattedQuestion = question.replaceAll(
              new RegExp(`${escapedValue}(?!([^<]+)?>)`, 'gi'),
              '<span class="icrs-highlight">$&</span>',
            );
            tempFaq[formattedQuestion] = this.originalFaq[question].replaceAll(
              new RegExp(`${escapedValue}(?!([^<]+)?>)`, 'gi'),
              '<span class="icrs-highlight">$&</span>',
            );

            if (this.originalFaq[question].toLowerCase().includes(value.toLowerCase())) {
              if (tempFaq[formattedQuestion] === this.originalFaq[question]) {
                tempShowFaq[formattedQuestion] = false;
              } else {
                tempShowFaq[formattedQuestion] = true;
              }
            } else {
              tempShowFaq[formattedQuestion] = false;
            }
          });
          this.faq = { ...tempFaq };
          this.showFaq = { ...tempShowFaq };
        } else {
          this.faq = { ...this.originalFaq };
          this.showFaq = { ...this.originalShowFaq };
        }
      },
    },
  },
  mounted () {
    const faq = ICRSService.getFaq();
    this.faq = faq;
    Object.keys(this.faq).forEach((question) => {
      this.showFaq[question] = false;
    });
    this.originalShowFaq = { ...this.showFaq };
    this.originalFaq = { ...this.faq };
  },
  methods: {
    toggleFaq (question) {
      const showThisFaq = !this.showFaq[question];
      this.$root.$emit(`bv::collapse::${showThisFaq}`, question);
      const newShowFaq = { ...this.showFaq };
      newShowFaq[question] = showThisFaq;
      this.showFaq = { ...newShowFaq };
    },
  },
};
</script>
