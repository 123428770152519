<!--
  Usage demo:
  1.import component:
  import RichTextEditor from '@/components/rich-text-editor/RichTextEditor.vue';
  2.in components:
  components: {
    ...
    RichTextEditor,
  },
  3.in template:
  ...
  <RichTextEditor
    v-model="richText"
    :plugins="plugins"
    :toolbar="toolbar"
    :width="width"
    :height="height"
    :disabled="disabled"
    :inline="isInline"
  />
  4.in data:
  data () {
    return {
      ...
      richText: '', // required
      plugins: 'preview importcss searchreplace', // not required, if not pass it will use default value
      toolbar: 'bold italic underline | undo redo', // not required, if not pass it will use default value
      width: '700', // not required, if not pass it will use default value
      height: '700', // not required, if not pass it will use default value
      disabled: true, // not required, if not pass it will use default value
      isInline: true, // not required, if not pass it will use default value
    };
  },
-->
<template>
  <div class="rich-text-editor-wrap">
    <editor
      :init="{
        plugins,
        toolbar,
        width,
        height,
        menubar: '',
        resize: false,
        promotion: false,
        statusbar: false,
        branding: false,
        toolbar_mode: 'wrap',
        setup: setupEditor,
        link_assume_external_targets: true,
      }"
      v-model="content"
      @onChange="changeValue"
      :disabled="disabled"
      :inline="isInline"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  components: {
    editor: Editor,
  },
  data () {
    return {
      content: '',
      urlInput: '',
      prevInput: '',
    };
  },
  props: {
    value: {
      type: String,
      default () {
        return '';
      },
    },
    plugins: {
      type: [String, Array],
      default () {
        return 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen quickimage link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion';
      },
    },
    toolbar: {
      type: String,
      default () {
        return 'bold italic underline | undo redo | alignleft aligncenter alignright | bullist | link unlink quickimage | table | blocks fontfamily fontsize | forecolor backcolor';
      },
    },
    width: {
      type: [Number, String],
      default () {
        return 'auto';
      },
    },
    height: {
      type: Number,
      default () {
        return 400;
      },
    },
    disabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isInline: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  methods: {
    changeValue () {
      this.$emit('input', this.content);
    },
    setupEditor (editor) {
      editor.on('ExecCommand', (e) => {
        if (e.command === 'mceLink') {
          // reset urlInput when open
          this.urlInput = '';
          this.prevInput = '';
          this.waitForElement('div.tox-dialog__footer').then((modalFooter) => {
            const urlInputField = document.querySelector('.tox-textfield[type="url"]');
            const saveButton = Array.from(modalFooter.querySelectorAll('.tox-button')).find(btn => btn.textContent.trim() === 'Save');
            const toxForm = document.querySelector('.tox-form');
            if (urlInputField && saveButton) {
              if (urlInputField.value) {
                saveButton.disabled = false;
                this.prevInput = urlInputField.value;
              } else saveButton.disabled = true;

              urlInputField.addEventListener('input', (ev) => {
                this.urlInput = ev.target.value;
                saveButton.disabled = !this.isValidUrl;
              });
              urlInputField.addEventListener('change', (ev) => {
                this.urlInput = ev.target.value;
                saveButton.disabled = !this.isValidUrl;
              });

              // catch event select suggestion
              toxForm.addEventListener('mouseout', () => {
                this.prevInput = this.urlInput;
              });
              toxForm.addEventListener('mouseover', () => {
                if (this.prevInput !== urlInputField.value) {
                  // eslint-disable-next-line no-unused-expressions
                  this.urlInput = urlInputField.value;
                  this.prevInput = this.urlInput;
                  saveButton.disabled = !this.isValidUrl;
                }
              });
            }
          });
        }
        if (e.command === 'mceFocus') {
          this.waitForElement('.tox-dialog.tox-confirm-dialog').then((prefixDialog) => {
            // if the correct prefix dialog, remove No button
            const noButton = Array.from(prefixDialog.querySelectorAll('.tox-button')).find(btn => btn.textContent.trim() === 'No');
            if (noButton) noButton.remove();
          });
        }
      });
    },
    waitForElement (selector) {
      return new Promise((resolve, reject) => {
        const element = document.querySelector(selector);
        if (element) {
          resolve(element);
        } else {
          const observer = new IntersectionObserver((entries, obs) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                resolve(entry.target);
                obs.disconnect();
              }
            });
          });
          observer.observe(document.body);
        }
      });
    },
  },
  created () {
    // Prevent Bootstrap dialog from blocking focusin
    document.addEventListener('focusin', (e) => {
      if (e.target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
        e.stopImmediatePropagation();
      }
    });
  },
  // mounted () {
  //   this.content = this.value;
  // },
  computed: {
    isValidUrl () {
      // check invalid url character
      const urlCharRegex = /^[a-zA-Z0-9-_.!~*'();:@&=+$,/?%#[\]]*$/;

      if (!urlCharRegex.test(this.urlInput)) return false;

      const urlTest = this.urlInput.startsWith('https://') ? this.urlInput : `https://${this.urlInput}`;
      if (urlTest.length > 255) return false;

      const urlRegex = /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#?&//=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%"+"._\\+~#?&//=]*)$/;
      return urlRegex.test(urlTest);
    },
  },
};
</script>
