<template>
  <div class="btn-group">
    <button
      id="sort-most-to-least"
      class="btn"
      :class="{
        'btn-primary': sortBy === sortTypes.MOST_TO_LEAST,
        'btn-outline-primary': sortBy !== sortTypes.MOST_TO_LEAST }"
      @click="applySort(sortTypes.MOST_TO_LEAST)"
    >
      <font-awesome-icon
        class="icon"
        icon="sort-amount-up"
        size="lg"
      />
    </button>
    <Tooltip
      container="filter-modal"
      target="sort-most-to-least"
    >Sort From Most To Least</Tooltip>
    <button
      id="sort-least-to-most"
      class="btn"
      :class="{
        'btn-primary': sortBy === sortTypes.LEAST_TO_MOST,
        'btn-outline-primary': sortBy !== sortTypes.LEAST_TO_MOST}"
      @click="applySort(sortTypes.LEAST_TO_MOST)"
    >
      <font-awesome-icon
        class="icon"
        icon="sort-amount-down-alt"
        size="lg"
      />
    </button>
    <Tooltip
      container="filter-modal"
      target="sort-least-to-most"
    >Sort From Least To Most</Tooltip>
    <button
      id="sort-a-z"
      class="btn"
      :class="{
        'btn-primary': sortBy === sortTypes.A_TO_Z,
        'btn-outline-primary': sortBy !== sortTypes.A_TO_Z
      }"
      @click="applySort(sortTypes.A_TO_Z)"
    >
      <font-awesome-icon
        class="icon"
        icon="sort-alpha-down"
        size="lg"
      />
    </button>
    <Tooltip
      container="filter-modal"
      target="sort-a-z"
    >Sort From A To Z</Tooltip>
    <button
      id="sort-z-a"
      class="btn"
      :class="{
        'btn-primary': sortBy === sortTypes.Z_TO_A,
        'btn-outline-primary': sortBy !== sortTypes.Z_TO_A
      }"
      @click="applySort(sortTypes.Z_TO_A)"
    >
      <font-awesome-icon
        class="icon"
        icon="sort-alpha-down-alt"
        size="lg"
      />
    </button>
    <Tooltip
      container="filter-modal"
      target="sort-z-a"
    >Sort From Z to A</Tooltip>
  </div>
</template>

<script>
import constants from '../../constants/search-service-constants';

export default {
  name: 'ICRSSortByButton',
  props: {
    sortBy: String,
  },
  data () {
    return {
      isOpen: false,
      sortTypes: constants.FILTER_SORT_BY_OPTIONS,
    };
  },
  computed: {
    currentSortByTooltipText () {
      const textMap = {
        [constants.FILTER_SORT_BY_OPTIONS.MOST_TO_LEAST]: 'most to least',
        [constants.FILTER_SORT_BY_OPTIONS.LEAST_TO_MOST]: 'least to most',
        [constants.FILTER_SORT_BY_OPTIONS.A_TO_Z]: 'A to Z',
        [constants.FILTER_SORT_BY_OPTIONS.Z_TO_A]: 'Z to A',
      };

      return `Sort filters ${textMap[this.sortBy]}`;
    },
    iconClass () {
      switch (this.sortBy) {
        case constants.FILTER_SORT_BY_OPTIONS.MOST_TO_LEAST:
          return 'sort-amount-up';
        case constants.FILTER_SORT_BY_OPTIONS.LEAST_TO_MOST:
          return 'sort-amount-down-alt';
        case constants.FILTER_SORT_BY_OPTIONS.A_TO_Z:
          return 'sort-alpha-down';
        case constants.FILTER_SORT_BY_OPTIONS.Z_TO_A:
          return 'sort-alpha-down-alt';
        default:
          return '';
      }
    },
  },
  methods: {
    applySort (value) {
      this.$emit('change', value);
    },
  },
};
</script>
