<template>
  <!--
  @mouseup.stop is there because vue-autosuggest treats any component embedded in dropdown as a suggestion
  so any click will result in autosuggest emitting selected event with the event not binded to anything.
  having @mouseup.stop prevents this event from happening so that we can handle the click events manually
  https://github.com/darrenjennings/vue-autosuggest/issues/75
 -->
  <b-container
    id="TrendingSearchPlugin"
    class="mx-2 my-2 px-4 py-3"
    @mouseup.stop
  >
    <b-row>
      <h6 class="font-secondary">
        Trending topics
      </h6>
    </b-row>
    <b-row>
      <b-button
        v-for="(trendingText, $i) in trendingSearches"
        :key="$i"
        v-on:click="triggerTrendingClick(trendingText)"
        pill
        variant="primary"
        class="my-1 mr-1 text-sm"
      >{{trendingText}}</b-button>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';

// eslint-disable-next-line no-unused-vars
const STUB_TRENDING_SEARCHES = [
  'What constitutes a service injury',
  'How to make a claim',
  'What training can i enrol for',
  'I am pregnant how',
  'How to see my divisional records',
];

const LOOKBACK_DAYS = 30;
const SIZE = 5;

export default {
  name: 'TrendingSearchPlugin',
  props: {
    forceTrendingSearches: {
      type: Array,
      default: () => null,
    },
    eventName: {
      type: String,
      default: 'triggerTrendingClick',
    },
  },
  data () {
    return {
      trendingSearches: [],
    };
  },
  methods: {
    ...mapActions({
      execGetTrendingSearch: 'searchService/execGetTrendingSearch',

    }),
    triggerTrendingClick ($event) {
      this.$emit(this.eventName, $event);
    },
  },
  created () {
    if (this.forceTrendingSearches === null) {
      this.execGetTrendingSearch({ lookbackDays: LOOKBACK_DAYS, size: SIZE }).then((trendingSearches) => {
        this.trendingSearches = trendingSearches.map(t => t.term);
      });
    } else {
      this.trendingSearches = this.forceTrendingSearches;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
