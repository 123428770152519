<template>
  <div class="filter-grouping">
    <div v-if="group.groupName">
      <div
        class="filter-grouping__item"
        v-b-toggle="group.groupId"
        role="tab"
      >
        <span class="filter-grouping-name">
          {{ group.groupName }}
        </span>
        <icon-text
          class="when-opened"
          faType="fas"
          faName="chevron-up"
          size="sm"
        ></icon-text>
        <icon-text
          class="when-closed"
          faType="fas"
          faName="chevron-down"
          size="sm"
        ></icon-text>
      </div>
      <b-collapse
        :id="group.groupId"
        :visible="isOpen"
        role="tabpanel"
      >
        <div class="filter-checklist">
          <FilterGroupItem
            v-for="filterGroupItem in group.filters"
            :key="filterGroupItem.name"
            :groupItem="filterGroupItem"
            :applied-filters="appliedFilters"
            :is-open="collapse[filterGroupItem.name]"
            @toggle-open="$emit('toggle-open', $event)"
            @change="$emit('filter-change', $event)"
            @show-more="$emit('show-more', $event)"
          />
        </div>
      </b-collapse>
    </div>
    <div v-else>

      <div v-if="group.groupId === '_promotedContent'">
        <FilterPromotedContent
          v-if="config.showPromotedContentFilter"
          group="_promotedContent"
          :is-open="collapse['_promotedContent']"
          @toggle-open="$emit('toggle-open', $event)"
          @filter-change="$emit('filter-change', $event)"
          @date-range-change="$emit('promote-date-range-change', $event)"
        />
      </div>
      <div v-else-if="group.groupId === '_modifiedDate'">
        <FilterDate
          displayName="Modified Date"
          group="_modifiedDate"
          :is-open="collapse['_modifiedDate']"
          :applied-filters="appliedFilters"
          @toggle-open="$emit('toggle-open', $event)"
          @start-date-change="$emit('start-date-change', $event)"
          @end-date-change="$emit('end-date-change', $event)"
        ></FilterDate>
      </div>
      <div v-else>
        <FilterGroupItem
          v-for="filterGroupItem in group.filters"
          :key="filterGroupItem.name"
          :groupItem="filterGroupItem"
          :applied-filters="appliedFilters"
          :is-open="collapse[filterGroupItem.name]"
          @toggle-open="$emit('toggle-open', $event)"
          @change="$emit('filter-change', $event)"
          @show-more="$emit('show-more', $event)"
          class="filter-group-item"
        />
      </div>

    </div>
  </div>
</template>

<script>
import IconText from '@/components/IconText.vue';
import { getFilterGroupAccordianState } from '@/helpers/search-filters';
import FilterGroupItem from './FilterGroupItem.vue';
import FilterPromotedContent from './FilterPromotedContent';
import FilterDate from './FilterDate.vue';

export default {
  name: 'FilterGrouping',
  components: {
    IconText,
    FilterGroupItem,
    FilterPromotedContent,
    FilterDate,
  },
  data () {
    return {
      config: {
        showPromotedContentFilter: true,
      },
    };
  },
  props: {
    group: Object,
    appliedFilters: {
      type: Array,
    },
    collapse: {
      type: Object,
    },
  },
  computed: {
    isOpen () {
      return getFilterGroupAccordianState(this.appliedFilters, this.group);
    },
  },
  mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
  },
};
</script>

<style lang="scss" scoped>
.filter-grouping {
  border-bottom: 1px solid $blue-gray-100;
  &__item {
    padding: $gap-md;
    display: flex;
    justify-content: space-between;
  }

  &/deep/ .filter-item[aria-expanded="true"] svg {
    transform: rotate(180deg);
  }
}
.filter-grouping-name {
  font-weight: $weight-semi-bold;
  color: $blue-gray-500;
  font-size: $font-md;
}

.filter-checklist {
  padding: 0px $gap-md $gap-md $gap-md;

  &/deep/ .filter-item {
    margin-bottom: $gap-xxs;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.show-more-btn {
  color: $blue-500;
  padding: 4px 6px;
  border: 0;
  background: transparent;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.filter-group-item {
  padding-right: $gap-md;
  padding-top: $gap-md;
}
</style>
