export default ({
  bottomNavList = [],
  footerList = [],
  newPageCurrent = null,
  checkPage = '',
  previewData = {},
  updating = null,
  isLoadingList = false,
} = {}) => ({
  bottomNavList,
  footerList,
  newPageCurrent,
  checkPage,
  previewData,
  updating,
  isLoadingList,
});
