<template>
  <div class="others-container">
    <b-form @submit="onSubmit">
        <div class="mb-4" >
          <div class="d-flex align-item-center">
            <label for="other-radio" class="label" >Promoted document</label>
            <font-awesome-icon
              id="promoted-tooltip"
              class="question-icon text-secondary  m-tooltip align-self-center"
              style="margin-bottom: 0.5rem;"
              :icon="['fa', 'question-circle']"
              size="lg"/>
              <b-tooltip target="promoted-tooltip" triggers="hover">
                Promoted document will be displayed on the top of the search results
              </b-tooltip>
          </div>
          <div class="d-flex" >
            <b-form-radio
              :disabled="haveWriteAccess ? false : !(disableForm.FEATURED || false)"
              v-model="modelFeatured"
              value="true"
            >
              Yes
            </b-form-radio>
            <b-form-radio
              :disabled="haveWriteAccess ? false : !(disableForm.FEATURED || false)"
              v-model="modelFeatured"
              value="false"
              class="ml-3"
            >
              No
            </b-form-radio>
          </div>
        </div>
        <div class="mb-4" >
          <div class="d-flex align-item-center justify-content-between">
            <label for="other-priority" class="label">Document Display Priority</label>
          </div>
          <validation-provider rules="doc-integer" v-slot="{ errors }">
            <b-form-input
              type="number"
              min="0"
              pattern="[0-9]"
              :disabled="haveWriteAccess ? false : !(disableForm.PRIORITY || false)"
              id="other-priority"
              v-model="modelPriority"
              v-bind:class="errors[0] ? 'is-invalid remove-warning' : ''"
              placeholder="Enter priority level"/>
            <span class="form-error" >{{ errors[0] }}</span>
          </validation-provider>
        </div>
    </b-form>
    <div v-if="isEdited" class="d-flex justify-content-end" >
      <b-btn
        @click="handleCancel"
        class="confirm-btn mr-2 pl-4 pr-4"
        variant="link" >
        Cancel
      </b-btn>
      <b-btn
        :disabled="disableSave"
        @click="handleSave"
        class="confirm-btn ml-2 pl-4 pr-4"
        variant="primary" >
        Save
      </b-btn>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  props: {
    value: {},
    disableForm: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      defaut: false,
    },
    haveWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    const {
      priority = 0, featured = false,
    } = this.value || {};
    return {
      isEdited: false,
      formValue: {
        priority, featured,
      },
    };
  },
  components: {
    ValidationProvider,
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault();
    },
    handleCancel () {
      const {
        priority = 0, featured = false,
      } = this.value || {};
      this.formValue = {
        priority, featured,
      };
      this.$nextTick(() => {
        this.setIsEdited(false);
      });
    },
    setIsEdited (value) {
      this.isEdited = value;
    },
    handleSave () {
      const { formValue } = this || {};

      this.$emit('save', formValue, this.setIsEdited);
    },
  },
  computed: {
    disableSave () {
      if (this.modelPriority !== 0 && !this.modelPriority) return true;
      const reg = new RegExp('^-\\d+$');
      if (reg.test(`${this.modelPriority}`)) return true;
      return false;
    },
    modelFeatured: {
      get () {
        return this.formValue.featured;
      },
      set (value) {
        this.setIsEdited(true);
        this.formValue.featured = value;
      },
    },
    modelPriority: {
      get () {
        return Number(this.formValue.priority);
      },
      set (value) {
        this.setIsEdited(true);
        this.formValue.priority = Number(value);
      },
    },
  },
};
</script>

<style  lang="scss">
#doc-manager {
  .others-container {
    padding: 20px;
    min-width: 500px;
    form {
      max-width: 40%;
    }
    .question-icon {
      font-size: 14px;
    }
    .custom-control-label {
      font-size: 12px;
      padding-top: 5px
    }
    .is-invalid.remove-warning {
      background-image: inherit;
    }
  }
}
</style>
