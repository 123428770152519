<template>
  <b-tab :title="title" class="tab-page">
    <button class="btn btn-sign-out btn-lg" type="button" aria-label="Create New Page / Link"
      title="Create New Page / Link" @click="gotoCreatePage()">
      Create New Page / Link
    </button>
    <div class="content">
      <slot></slot>
    </div>
  </b-tab>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    title: String,
  },
  methods: {
    // methods go here
    ...mapActions('pageManagement', ['setCheckPage', 'setNewPageCurrent']),
    gotoCreatePage () {
      this.setNewPageCurrent('');
      this.setCheckPage(this.title === 'Bottom Navigation' ? 'Navigation' : this.title);
      this.$router.push('/page-management/create-new-page');
    },
  },
};
</script>

<style scoped lang="scss">
.tab-page>.btn.btn-sign-out.btn-lg {
  margin-top: 25px;
  margin-bottom: 16px;
}
</style>
