/* eslint-disable import/prefer-default-export */

import _groupBy from 'lodash/groupBy';

/**
 * @param {import('@typings/search').TopicQuery} topicsQuery
 * @param {import('@typings/search').AggregationConfig} aggregationConfigs
 */
export function createBadgeDropdownGroups (topicsQuery, aggregationConfigs) {
  const clone = { ...aggregationConfigs };
  const { level1, level2, level3 } = clone;
  delete clone.level1;
  delete clone.level2;
  delete clone.level3;
  clone.level1code = level1;
  clone.level2code = level2;
  clone.level3code = level3;
  let group = _groupBy(topicsQuery.must, item => item.field);
  group = Object.keys(group)
    .map((key) => {
      const config = clone[key];
      if (!config) return null;
      const { displayName } = config;

      return {
        key,
        name: displayName,
        items: group[key],
      };
    })
    .filter(Boolean);

  return group;
}
