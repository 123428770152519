<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="logo-container">
      <img
        width="80"
        alt="taiger"
        src="../../../assets/taiger-logo-black.png"
      />
    </div>
    <div
      id="headline"
      class="headline"
    >
      <span class="headline-text">Document Settings Manager</span>
    </div>
    <p class="headline-label">{{label}}</p>
    <b-form
      v-on:submit.prevent="$emit('click-btn')"
      class="form-container"
    >
      <div class="d-flex input-container">
        <b-input
          class="input"
          v-model="documentInput"
          placeholder="Please enter a document id"
        />
        <b-btn
          type="submit"
          variant="primary"
          class="ml-2 fetch-button"
          :disabled="documentInput.length <= 0"
        >
          Go
        </b-btn>
      </div>
      <label
        v-if="isInputError"
        class="text-danger"
      >{{ isInputError }}</label>
    </b-form>
  </div>
</template>

<script>
import constants from '../../../constants/doc-manager-service-constants';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    isInputError: {
      type: String,
      default: '',
    },
  },
  data () {
    const { TEXT_INFO: { ID_FORM: pageLabel = '' } = {} } = constants;

    return {
      label: pageLabel,
    };
  },
  computed: {
    documentInput: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss">
#doc-manager {
  #id-form {
    .logo-container {
      margin-top: 30px;
    }
    .headline {
      margin-top: 30px;
    }
    .headline-text {
      font-size: 24px;
      font-weight: $weight-bold;
    }
    .headline-label {
      margin-top: 16px;
    }
    .form-container {
      margin-top: 15px;
      label {
        font-size: 12px;
      }
    }
    .input-container {
      min-height: 36px;
    }
    .input {
      min-height: 36px;
      min-width: 300px;
      max-width: 300px;
      font-size: 14px;
      flex: 0.18;
    }
    .fetch-button {
      min-width: 80px;
      padding: 0px 20px;
      font-size: 13px;
    }
  }
}
</style>
