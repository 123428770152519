<template>
  <b-modal
    :id="'advancedSearchModal'"
    size="xl"
    :centered="true"
    :hide-footer="true"
    header-close-content="<i class='fa fa-times-circle' />"
    title="Advanced Search"
    @hide="closeModal('advancedSearchModal')"
  >
    <b-container fluid>
      <b-form-group
        label-cols-md="4"
        label-cols-lg="4"
        label-align-sm="right"
        label="Nature of Injury - Level 1"
      >
        <b-dropdown
          class="advanced_dropdown"
          :class="{'col-lg-10': !mobileMode}"
          block
          split
          variant="outline-primary"
          :text="formatGuidedDisplay(advancedSearchSelectedLevel.level1.elitLabel || 'All')"
          right
        >
          <b-dropdown-item
            v-for="category in [{code: 'All'},...level1Categories]"
            :key="'advanced_level1_' + category.code"
            :active="
              advancedSearchSelectedLevel.level1.code === category.code ||
              advancedSearchSelectedLevel.level1.code === category.elitLabel"
            @click="
              $parent.updateGuidedDropdowns('advanced', 0, category);
              updateSelectedDropdown(0, category)"
          >
            {{formatGuidedDisplay(category.elitLabel || 'All')}}
          </b-dropdown-item>
        </b-dropdown>
      </b-form-group>
      <b-form-group
        label-cols-md="4"
        label-cols-lg="4"
        label-align-sm="right"
        label="Nature of Injury - Level 2"
      >
        <b-dropdown
          class="advanced_dropdown"
          :class="{'col-lg-10': !mobileMode}"
          block
          split
          variant="outline-primary"
          :text="formatGuidedDisplay(advancedSearchSelectedLevel.level2.elitLabel || 'All')"
          right
        >
          <b-dropdown-item
            v-for="category in advancedSearchSelectedLevel.level2ForSelectedLevel1"
            :key="'advanced_level2_' + category.code"
            :active="
              advancedSearchSelectedLevel.level2.code === category.code ||
              advancedSearchSelectedLevel.level2.code === category.elitLabel"
            @click="
              $parent.updateGuidedDropdowns('advanced',1, category);
              updateSelectedDropdown(1, category)"
          >
            {{formatGuidedDisplay(category.elitLabel || 'All')}}
          </b-dropdown-item>
        </b-dropdown>
      </b-form-group>
      <b-form-group
        label-cols-md="4"
        label-cols-lg="4"
        label-align-sm="right"
        label="Nature of Injury - Level 3"
      >
        <b-dropdown
          class="advanced_dropdown"
          :class="{'col-lg-10': !mobileMode}"
          block
          split
          variant="outline-primary"
          :text="formatGuidedDisplay(advancedSearchSelectedLevel.level3.elitLabel || 'All')"
          right
        >
          <b-dropdown-item
            v-for="category in advancedSearchSelectedLevel.level3ForSelectedLevel2"
            :key="'advanced_level3_' + category.code"
            :active="
              advancedSearchSelectedLevel.level3.code === category.code ||
              advancedSearchSelectedLevel.level3.code === category.elitLabel"
            @click="
              $parent.updateGuidedDropdowns('advanced', 2, category);
              updateSelectedDropdown(2, category)"
          >
            {{formatGuidedDisplay(category.elitLabel || 'All')}}
          </b-dropdown-item>
        </b-dropdown>
      </b-form-group>
      <b-form-group
        label-cols-md="4"
        label-cols-lg="4"
        label-align-sm="right"
        label="Court Type"
      >
        <b-dropdown
          class="advanced_dropdown"
          :class="{'col-lg-10': !mobileMode}"
          block
          split
          variant="outline-primary"
          :text="advancedCourtType"
          right
        >
          <b-dropdown-item
            v-for="category in advancedCourtTypes"
            :key="'advanced_court_type_' + category"
            :active="advancedCourtType === category"
            @click="advancedCourtType = category"
          >
            {{category}}
          </b-dropdown-item>
        </b-dropdown>
      </b-form-group>
      <b-form-group
        label-cols-md="4"
        label-cols-lg="4"
        label-align-sm="right"
        label="Sex"
      >
        <div class="btn-group btn-group-toggle icrs-salary-toggle btn-same-width">
          <button
            class="btn"
            :class="{
              'btn-primary': advancedGender === 'Both Genders',
              'btn-outline-primary': advancedGender !== 'Both Genders'
            }"
            @click="advancedGender = 'Both Genders'"
          >
            All
          </button>
          <button
            class="btn"
            :class="{
              'btn-primary': advancedGender === 'Male',
              'btn-outline-primary': advancedGender !== 'Male'
            }"
            @click="advancedGender = 'Male'"
          >
            Male
          </button>
          <button
            class="btn"
            :class="{
              'btn-primary': advancedGender === 'Female',
              'btn-outline-primary': advancedGender !== 'Female'
            }"
            @click="advancedGender = 'Female'"
          >
            Female
          </button>
        </div>
      </b-form-group>
      <b-form-group
        label-cols-md="4"
        label-cols-lg="4"
        label-align-sm="right"
        label="Occupation"
        label-for="advanced_occupation"
      >
        <b-form-input
          id="advanced_occupation"
          type="text"
          placeholder="e.g. taxi driver"
          :class="{'col-lg-10': !mobileMode}"
          v-model="advancedOccupation"
        />
      </b-form-group>
      <b-form-group
        label-cols-md="4"
        label-cols-lg="4"
        label-align-sm="right"
      >
        <button
          @click="performAdvancedSearch"
          class="btn btn-primary perform-advanced-search"
          :disabled="
            advancedSearchSelectedLevel.level1.code === 'All' &&
            advancedSearchSelectedLevel.level2.code === 'All' &&
            advancedSearchSelectedLevel.level3.code === 'All' &&
            advancedCourtType === 'All Court Types' &&
            advancedGender === 'Both Genders' &&
            advancedOccupation === ''
          "
        >Perform Advanced Search</button>
      </b-form-group>
    </b-container>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ICRSService from '@/services/icrs-service';

export default {
  name: 'ICRSAdvancedSearch',
  props: {
    mobileMode: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      advancedCourtTypes: [
        'All Court Types',
        'Court Of Appeal',
        'Appellate Division',
        'High Court',
        'District Court',
        'Magistrates\' Court',
        'Others',
      ],
      advancedCourtType: 'All Court Types',
      advancedGender: 'Both Genders',
      advancedOccupation: '',
      level1Categories: [],
      guidedPrefixIgnoreList: [],
    };
  },
  computed: {
    ...mapGetters('icrs', [
      'dropdownGuidedTag',
      'selectedLevel1',
      'selectedLevel2',
      'selectedLevel3',
      'level2CategoriesForSelectedLevel1',
      'level3CategoriesForSelectedLevel2',
      'allGuidedCategories',
      'advancedSearchSelectedLevel',
    ]),
    guidedCategoriesFilter () {
      let temp = { ...this.allGuidedCategories };
      if (temp) {
        temp.level1 = temp.level1.filter(injury => !injury.newAdded);
        temp.level2 = temp.level2.filter(injury => !injury.newAdded);
        temp.level3 = temp.level3.filter(injury => !injury.newAdded);
      }
      return temp;
    }
  },
  methods: {
    ...mapActions('icrs', [
      'setDropdownGuidedTag',
      'setSelectedLevel1',
      'setSelectedLevel2',
      'setSelectedLevel3',
      'setLevel2CategoriesForSelectedLevel1',
      'setLevel3CategoriesForSelectedLevel2',
      'setAdvancedSearchSelectedLevel',
    ]),

    ...mapActions('advancedSearch', [
      'setSearchShowOverview',
    ]),

    performAdvancedSearch () {
      if (this.advancedSearchSelectedLevel.level1.code === 'All' && this.advancedSearchSelectedLevel.level2.code === 'All' && this.advancedSearchSelectedLevel.level3.code === 'All') {
        this.setSearchShowOverview(false);
      } else {
        this.setSearchShowOverview(true);
      }

      const advancedSearchComponents = [];

      if (this.advancedSearchSelectedLevel.level3.code !== 'All') {
        advancedSearchComponents.push(`tags.level3code:"${this.advancedSearchSelectedLevel.level3.code}"`);
      } else if (this.advancedSearchSelectedLevel.level2.code !== 'All') {
        advancedSearchComponents.push(`tags.level2code:"${this.advancedSearchSelectedLevel.level2.code}"`);
      } else if (this.advancedSearchSelectedLevel.level1.code !== 'All') {
        advancedSearchComponents.push(`tags.level1code:"${this.advancedSearchSelectedLevel.level1.code}"`);
      }

      if (this.advancedCourtType !== 'All Court Types') {
        advancedSearchComponents.push(`tags.courtType:"${this.advancedCourtType}"`);
      }
      if (this.advancedGender !== 'Both Genders') {
        advancedSearchComponents.push(`tags.sex:"${this.advancedGender}"`);
      }
      if (this.advancedOccupation !== '') {
        const occupationParts = this.advancedOccupation.split(/(\s+)/);
        const formattedOccupationParts = [];
        occupationParts.map(part => formattedOccupationParts.push(
          `${part.charAt(0).toUpperCase()}${part.substring(1)}`,
        ));
        this.advancedOccupation = formattedOccupationParts.join('');
        advancedSearchComponents.push(`tags.occupation:"${this.advancedOccupation}"`);
      }

      this.$emit('setIcrsAdvancedSearchQuery', advancedSearchComponents.join(' AND '));
      this.closeModal('advancedSearchModal');
    },
    formatGuidedDisplay (term) {
      let formattedTerm = term;
      // eslint-disable-next-line array-callback-return
      this.guidedPrefixIgnoreList.map((prefix) => {
        formattedTerm = formattedTerm.replace(prefix, '');
      });
      return formattedTerm;
    },
    closeModal (modalId) {
      this.$bvModal.hide(modalId);
      // this.$emit('hide');
    },
    updateSelectedDropdown (activeGuidedPrefix, guidedTag) {
      if (activeGuidedPrefix < 1) {
        this.setAdvancedSearchSelectedLevel({
          level1: guidedTag,
          level2: { code: 'All' },
          level3: { code: 'All' },
          level2ForSelectedLevel1: [
            { code: 'All' },
            ...this.guidedCategoriesFilter.level2.filter(item => item.parentCode === guidedTag.code),
          ],
          level3ForSelectedLevel2: [{ code: 'All' }],
        });
      } else if (activeGuidedPrefix === 1) {
        this.setAdvancedSearchSelectedLevel({
          ...this.advancedSearchSelectedLevel,
          level2: guidedTag,
          level3: { code: 'All' },
          level3ForSelectedLevel2: [
            { code: 'All' },
            ...this.guidedCategoriesFilter.level3.filter(item => item.parentCode === guidedTag.code),
          ],
        });
      } else {
        this.setAdvancedSearchSelectedLevel({
          ...this.advancedSearchSelectedLevel,
          level3: guidedTag,
        });
      }
    },
  },
  mounted () {
    this.level1Categories = [...this.guidedCategoriesFilter.level1];
    this.guidedPrefixIgnoreList = ICRSService.getGuidedPrefixIgnoreList();
  },

};
</script>

<style lang="scss" scoped>
@media (max-width: 992px) {
  .btn-same-width {
    width: 100%
  }
}

</style>
