import constants from '@/constants/search-service-constants';
import FilterGroup from '@/services/models/search/filter-group.model';
import * as helpers from '../search-filters';

const aggregationConfigs = {
  caa: {
    displayName: 'CAA',
    field: 'caa',
    showInCloud: false,
    displayed: true,
  },
  sectors: {
    displayName: 'Sectors',
    field: 'sectors',
    showInCloud: true,
    displayed: true,
  },
  penalties: {
    displayName: 'Penalties',
    field: 'penalties',
    showInCloud: true,
    displayed: true,
  },
  personnel: {
    displayName: 'Personnel',
    field: 'personnel',
    showInCloud: false,
    displayed: false,
  },
};

const aggregations = [
  {
    name: 'penalties',
    displayName: 'Penalties',
    docCount: 10,
    bucket: undefined,
  },
  {
    name: 'caa',
    docCount: 5,
    displayName: 'CAA',
    bucket: [
      { key: 'casa', docCount: 5 },
    ],
  },
  {
    name: 'sectors',
    displayName: 'Sectors',
    docCount: 5,
    bucket: [
      { key: 'airborne', docCount: 5 },
    ],
  },
  {
    name: 'personnel',
    displayName: 'Personnel',
    docCount: 2,
    bucket: [
      { key: 'crew', docCount: 2 },
    ],
  },
];

describe('search-filters', () => {
  test('createFilterUniqueKey', () => {
    const filter = { groupName: 'caa', key: 'casa' };
    expect(helpers.createFilterUniqueKey(filter)).toBe('caa_casa');
  });

  describe('sortFiltersBy', () => {
    const filters = [
      { key: 'Big', docCount: 4 },
      { key: 'Air', docCount: 1 },
      { key: 'Deep', docCount: 3 },
      { key: 'Cyan', docCount: 2 },
    ];

    test('a to z', () => {
      const result = helpers.sortFiltersBy(filters, constants.FILTER_SORT_BY_OPTIONS.A_TO_Z);
      expect(result).toEqual([
        { key: 'Air', docCount: 1 },
        { key: 'Big', docCount: 4 },
        { key: 'Cyan', docCount: 2 },
        { key: 'Deep', docCount: 3 },
      ]);
    });

    test('z to a', () => {
      const result = helpers.sortFiltersBy(filters, constants.FILTER_SORT_BY_OPTIONS.Z_TO_A);
      expect(result).toEqual([
        { key: 'Deep', docCount: 3 },
        { key: 'Cyan', docCount: 2 },
        { key: 'Big', docCount: 4 },
        { key: 'Air', docCount: 1 },
      ]);
    });

    test('most to least', () => {
      const result = helpers.sortFiltersBy(filters, constants.FILTER_SORT_BY_OPTIONS.MOST_TO_LEAST);
      expect(result).toEqual([
        { key: 'Big', docCount: 4 },
        { key: 'Deep', docCount: 3 },
        { key: 'Cyan', docCount: 2 },
        { key: 'Air', docCount: 1 },
      ]);
    });

    test('least to most', () => {
      const result = helpers.sortFiltersBy(filters, constants.FILTER_SORT_BY_OPTIONS.LEAST_TO_MOST);
      expect(result).toEqual([
        { key: 'Air', docCount: 1 },
        { key: 'Cyan', docCount: 2 },
        { key: 'Deep', docCount: 3 },
        { key: 'Big', docCount: 4 },
      ]);
    });
  });

  describe('getRelevantFilterGroups', () => {
    it('should only return relevant filter groups', () => {
      // Should exclude:
      // - hidden filters
      // - aggregation which are have no any bucket
      const result = helpers.getRelevantFilterGroups(aggregations, aggregationConfigs);
      const visibleIndexes = {
        CAA: 1,
        SECTORS: 2,
      };
      expect(result).toEqual([
        new FilterGroup(aggregations[visibleIndexes.CAA]),
        new FilterGroup(aggregations[visibleIndexes.SECTORS]),
      ]);
    });
  });
});
