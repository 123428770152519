
import PreviewViewerConstants from '@/constants/preview-viewer-service.constants';

const { DOCUMENT_TYPES } = PreviewViewerConstants;

export default {

  openPreviewViewer ({ rootGetters, commit, dispatch }, documentDetails) {
    commit('setDocumentDetails', documentDetails);
    const documentType = rootGetters['previewViewer/documentType'];
    switch (documentType) {
      case DOCUMENT_TYPES.TEXT:
        dispatch('openTextPreviewViewer');
        break;
      default:
    }
  },

  async openTextPreviewViewer ({ rootGetters, commit, dispatch }) {
    const id = rootGetters['previewViewer/documentId'];
    const textContent = await dispatch('searchService/execGetTextContent', { id }, { root: true });
    commit('setTextContent', textContent);
    commit('setPreviewViewerModalOpen', true);
  },

  resetPreviewViewer ({ commit }) {
    commit('resetPreviewViewer');
  },
};
