<template>
  <div class="metadata-container">
    <b-table
      show-empty
      :key="isEditable ? 0 : 1"
      :items="metadataModel"
      :fields="displayedFields"
      caption-top
    >

      <template v-slot:table-caption>
        <span class="tab-title">Metadata</span>
        <b-button
          v-if="showCreateButton"
          @click="$emit('add')"
          class="button"
          variant="primary"
        >+ Create
        </b-button>
      </template>

      <template v-slot:cell(key)="{item: { key }}">
        <div>
          {{ key }}
        </div>
      </template>

      <template v-slot:cell(value)="{item: { value }}">
        <div class="w-100">
          {{ value }}
        </div>
      </template>

      <template v-slot:cell(action)="{item: { value, key }, index}">
        <b-dropdown
          v-if="showActionButtons"
          no-caret
          toggle-class="border-0 bg-white hover text-right"
        >
          <template v-slot:button-content>
            <font-awesome-icon
              class="menu-icon text-secondary"
              :icon="['fa', 'ellipsis-h']"
              size="lg"
            />
          </template>
          <b-dropdown-item>
            <div
              @click="handleEdit(index)"
              class="edit d-flex align-items-center"
            >
              <span class="ml-2 action-text">Edit</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div
              @click="handleDelete(index)"
              class="delete d-flex align-items-center"
            >
              <span class="action-text">Delete</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template v-slot:empty>
        <span class="empty-table-text">No metadata is attached to this document</span>
      </template>

    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    disableForm: {
      type: Object,
      default: () => ({}),
    },
    haveWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      fields: [
        {
          key: 'key',
          class: 'topic-mw',
        },
        {
          key: 'value',
        },
        {
          key: 'action',
          class: 'actions-mw',
        },
      ],
      displayedFields: [],
    };
  },
  mounted () {
    this.displayedFields = this.disableForm.METADATA ? this.fields.slice(0, 2) : this.fields;
  },
  methods: {
    handleEdit (index) {
      const value = this.metadataModel[index];

      this.$emit('edit', value);
    },
    handleDelete (index) {
      const value = this.metadataModel[index];
      this.$emit('delete', value);
    },
  },
  computed: {
    metadataModel: {
      get () {
        const key = Object.keys(this.value);

        const metadata = key.map((item, index) => ({
          key: item,
          value: this.value[item],
          action: '',
        }));

        return metadata;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
    showCreateButton () {
      return this.haveWriteAccess && !this.disableForm.METADATA;
    },
    showActionButtons () {
      return this.haveWriteAccess && !this.disableForm.METADATA;
    },
  },
};
</script>

<style lang="scss">
#doc-manager {
  #metadata {
    .metadata-container {
      .button {
        float: right;
      }
      .action-button {
        z-index: 2;
        padding: 5px 10px;
        margin-top: 30px;
        border-radius: 5px;
        position: absolute;
        background: white;
        border: 0.5px solid rgba(0, 0, 0, 0.2);
        .hover:hover {
          background-color: $blue-200;
        }
      }
      .action-button:hover {
        background: #007bff;
        color: $white-500;
      }
      .actions-mw {
        width: 98px;
      }
      .topic-mw {
        width: 24%;
      }
    }
  }
}
</style>
