<template>
  <div :class="{'search-result-card': !useICRSConfig, 'icrs-search-result-card': useICRSConfig}">
    <b-card
      class="resultCard"
      @mouseover="cardHover = true"
      @mouseleave="cardHover = false"
    >
      <div
        v-if="!useICRSConfig"
        class="buttonTray"
        v-show="cardHover"
      >
        <icon-text
          v-if="config.showPreviewAction"
          faType="far"
          faName="eye"
          text="Preview"
          size="sm"
          @click.native="handlePreviewViewerClick"
        ></icon-text>
        <icon-text
          v-if="config.showShareAction"
          faType="fal"
          faName="share-alt"
          text="Share"
          size="sm"
          @click.native="handleShareClick"
        ></icon-text>
        <SettingsDropdown @click="$emit('open-document-settings', $event)" />
      </div>

      <b-card-title
        v-if="!useICRSConfig"
        class="resultTitle"
        title-tag="div"
      >
        <a
          :href="fixLink(cardModel.path)"
          rel="noopener"
          target="_blank"
          v-dompurify-html="cardModel.title"
          @click="handleTitleClick"
        />
      </b-card-title>

      <b-card-sub-title
        v-if="!useICRSConfig"
        class="resultSubTitle"
      >
        <span class="badge-wrapper">
          <Badge
            variant="primary"
            pill
            v-if="isNew"
          >NEW</Badge>
          <Badge
            pill
            variant="warning"
            v-if="cardModel.promotedDocument"
          >Promoted</Badge>
        </span>
        {{ cardModel.subTitle }}
        <span>

        </span>
      </b-card-sub-title>

      <b-card-text
        class="resultText"
        v-dompurify-html="htmlEncode(cardModel.highlight)"
      />
      <div
        class="card summaryCard"
        v-if="useICRSConfig"
      >
        <div
          class="card-header"
          @click="toggleAwards"
        >
          <i
            v-if="!showAwards"
            class="fa fa-chevron-down"
          ></i>
          <i
            v-if="showAwards"
            class="fa fa-chevron-up"
          ></i>
          Awards Summary
        </div>
        <b-collapse
          :visible="showAwards"
          :id="awardsId"
        >
          <div class="card-body awards-summary-card">
            <u>Pain and Suffering Amount Awarded</u>
            <table class="table table-bordered">
              <thead>
                <tr v-if="!showGlobal || !globalCase">
                  <th style="width: 50%;" scope="col">Type of Injuries</th>
                  <th
                    v-if="showInjuryTier1"
                    :style="{'width': widthPS}"
                    scope="col"
                  >Amount Awarded at First Instance</th>
                  <th
                    v-if="showInjuryTier2"
                    :style="{'width': widthPS}"
                    scope="col"
                  >Amount Awarded on Appeal</th>
                  <th
                    v-if="showInjuryTier3"
                    :style="{'width': widthPS}"
                    scope="col"
                  >Amount Awarded on Further Appeal</th>
                  <th
                    v-if="showInjuryTier4"
                    :style="{'width': widthPS}"
                    scope="col"
                  >Amount Awarded on Further Appeal</th>
                </tr>
                <tr v-if="showGlobal && globalCase">
                  <th style="width: 50%;" scope="col">Type of Injuries</th>
                  <th
                    v-if="showInjuryTier1"
                    colspan="2"
                    :style="{'width': widthPS}"
                    scope="col"
                  >Amount Awarded at First Instance</th>
                  <th
                    v-if="showInjuryTier2"
                    colspan="2"
                    :style="{'width': widthPS}"
                    scope="col"
                  >Amount Awarded on Appeal</th>
                  <th
                    v-if="showInjuryTier3"
                    colspan="2"
                    :style="{'width': widthPS}"
                    scope="col"
                  >Amount Awarded on Further Appeal</th>
                  <th
                    v-if="showInjuryTier4"
                    colspan="2"
                    :style="{'width': widthPS}"
                    scope="col"
                  >Amount Awarded on Further Appeal</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in formattedData.injuryMap"
                  :key="'injury_' + index"
                  v-show="row.length != 0"
                >
                  <td>{{row[0]}}</td>
                  <td
                    :class="`global-background-${Number(formattedData.globalAwardedMap[index][1]) % numGlobalColours}`"
                    v-if="showInjuryTier1  && globalCase && formattedData.globalAwardedMap[index][1] != 0"
                  >{{row[1]}}</td>
                  <td
                    :class="`global-background-${Number(formattedData.globalAwardedMap[index][1]) % numGlobalColours}`"
                    v-if="showInjuryTier1 && showGlobal && globalCase && formattedData.globalAwardedMap[index][1] != 0"
                  >
                    <template v-if="formattedData.globalAwardedMap[index][1]">
                      Global {{formattedData.globalAwardedMap[index][1]}}
                    </template>
                  </td>
                  <!-- eslint-disable-next-line max-len -->
                  <td v-if="showInjuryTier1 && formattedData.globalAwardedMap[index][1] == 0">{{row[1]}}</td>
                  <!-- eslint-disable-next-line max-len -->
                  <td v-if="showInjuryTier1 && showGlobal && globalCase && formattedData.globalAwardedMap[index][1] == 0"></td>

                  <td
                    :class="`global-background-${Number(formattedData.globalAwardedMap[index][2]) % numGlobalColours}`"
                    v-if="showInjuryTier2 && globalCase && formattedData.globalAwardedMap[index][2] != 0"
                  >{{row[2]}}</td>
                  <td
                    :class="`global-background-${Number(formattedData.globalAwardedMap[index][2]) % numGlobalColours}`"
                    v-if="showInjuryTier2 && showGlobal && globalCase && formattedData.globalAwardedMap[index][2] != 0"
                  >
                    <template v-if="formattedData.globalAwardedMap[index][2]">
                      Global {{formattedData.globalAwardedMap[index][2]}}
                    </template>
                  </td>
                  <!-- eslint-disable-next-line max-len -->
                  <td v-if="showInjuryTier1 && formattedData.globalAwardedMap[index][2] == 0">{{row[2]}}</td>
                  <!-- eslint-disable-next-line max-len -->
                  <td v-if="showInjuryTier1 && showGlobal && globalCase && formattedData.globalAwardedMap[index][2] == 0"></td>

                  <td
                    :class="`global-background-${Number(formattedData.globalAwardedMap[index][3]) % numGlobalColours}`"
                    v-if="showInjuryTier3 && globalCase && formattedData.globalAwardedMap[index][3] != 0"
                  >{{row[3]}}</td>
                  <td
                    :class="`global-background-${Number(formattedData.globalAwardedMap[index][3]) % numGlobalColours}`"
                    v-if="showInjuryTier3 && showGlobal && globalCase && formattedData.globalAwardedMap[index][3] != 0"
                  >
                    <template v-if="formattedData.globalAwardedMap[index][3]">
                      Global {{formattedData.globalAwardedMap[index][3]}}
                    </template>
                  </td>
                  <!-- eslint-disable-next-line max-len -->
                  <td v-if="showInjuryTier1 && formattedData.globalAwardedMap[index][3] == 0">{{row[3]}}</td>
                  <!-- eslint-disable-next-line max-len -->
                  <td v-if="showInjuryTier1 && showGlobal && globalCase && formattedData.globalAwardedMap[index][3] == 0"></td>

                  <td
                    :class="`global-background-${Number(formattedData.globalAwardedMap[index][4]) % numGlobalColours}`"
                    v-if="showInjuryTier4 && globalCase && formattedData.globalAwardedMap[index][4] != 0"
                  >{{row[4]}}</td>
                  <td
                    :class="`global-background-${Number(formattedData.globalAwardedMap[index][4]) % numGlobalColours}`"
                    v-if="showInjuryTier4 && showGlobal && globalCase && formattedData.globalAwardedMap[index][4] != 0"
                  >
                    <template v-if="formattedData.globalAwardedMap[index][4]">
                      Global {{formattedData.globalAwardedMap[index][4]}}
                    </template>
                  </td>
                  <!-- eslint-disable-next-line max-len -->
                  <td v-if="showInjuryTier1 && formattedData.globalAwardedMap[index][4] == 0">{{row[4]}}</td>
                  <!-- eslint-disable-next-line max-len -->
                  <td v-if="showInjuryTier1 && showGlobal && globalCase && formattedData.globalAwardedMap[index][4] == 0"></td>
                </tr>
              </tbody>
            </table>
            <u v-if="formattedData.lec.length != 0 || formattedData.lfe.length != 0">Other Award Types</u>
            <table
              class="table table-bordered"
              v-if="formattedData.lec.length != 0 || formattedData.lfe.length != 0"
            >
              <thead>
                <tr>
                  <th :style="{'width': widthOA}">Award Type</th>
                  <th
                    v-if="showTier1"
                    :style="{'width': widthOA}"
                  >Amount Awarded at First Instance</th>
                  <th
                    v-if="showTier2"
                    :style="{'width': widthOA}"
                  >Amount Awarded on Appeal</th>
                  <th
                    v-if="showTier3"
                    :style="{'width': widthOA}"
                  >Amount Awarded on Further Appeal</th>
                  <th
                    v-if="showTier4"
                    :style="{'width': widthOA}"
                  >Amount Awarded on Further Appeal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="formattedData.lec.length != 0">
                  <td>Loss of Earning Capacity</td>
                  <td v-if="showTier1">{{formattedData.lec[1]}}</td>
                  <td v-if="showTier2">{{formattedData.lec[2]}}</td>
                  <td v-if="showTier3">{{formattedData.lec[3]}}</td>
                  <td v-if="showTier4">{{formattedData.lec[4]}}</td>
                </tr>
                <tr v-if="formattedData.lfe.length != 0">
                  <td>Loss of Future Earnings</td>
                  <td v-if="showTier1">{{formattedData.lfe[1]}}</td>
                  <td v-if="showTier2">{{formattedData.lfe[2]}}</td>
                  <td v-if="showTier3">{{formattedData.lfe[3]}}</td>
                  <td v-if="showTier4">{{formattedData.lfe[4]}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-collapse>
      </div>
      <div
        class="card summaryCard"
        v-if="useICRSConfig"
      >
        <div
          class="card-header"
          @click="toggleKeywords"
        >
          <i
            v-if="!showKeywords"
            class="fa fa-chevron-down"
          ></i>
          <i
            v-if="showKeywords"
            class="fa fa-chevron-up"
          ></i>
          Keywords Summary
        </div>
        <b-collapse
          :visible="showKeywords"
          :id="keywordsId"
        >
          <div class="card-body">
            <div class="form-panel-body">
              <div
                v-if="natureTrails.length > 0"
                class="form-group"
              >
                <label
                  class="icrs-tag-category"
                  style="padding-left: 15px;"
                >
                  <i
                    class="fa fa-info-circle"
                    style="position: absolute; left: 10px; cursor: pointer;"
                    @click="$emit('show-keywords-help-modal')"
                  ></i>
                  Nature of Injury
                </label>
                <span class="icrs-tag-values tag-result-card">
                  <span
                    v-for="(natureTrail, index) in natureTrailMap"
                    :key="`${keywordsId}_naturaTrail_${index}`"
                  >
                    <span
                      v-for="(term, trailIndex) in natureTrail"
                      :key="`${keywordsId}_naturaTrail_${index}_${trailIndex}`"
                    >
                      <span v-if="trailIndex > 0">&nbsp;>&nbsp;</span>
                      <span
                        class="jds-tag has-primary-light"
                        @click="searchTag(`level${trailIndex+1}code`, term.code, natureTrail)"
                      >
                        {{ formatGuidedDisplay(term.elitLabel) }}
                      </span>
                    </span>
                    <hr class="nature-of-injury-keywords-divider" />
                  </span>
                </span>
              </div>
              <div
                class="form-group"
                v-for='(topicDisplay, topicTitle) in finalDisplayOrder'
                :key="'tags_' + SearchResultDocument.id + '_' + topicTitle"
              >
                <label class="icrs-tag-category">
                  {{ topicDisplay }}
                </label>
                <span class="icrs-tag-values">
                  <span
                    class="jds-tag has-primary-light"
                    v-for="(tag, tagIndex) in SearchResultDocument.tags[topicTitle]"
                    :key="SearchResultDocument.id + '_' + topicTitle + '_' + tagIndex"
                    @click="searchTag(topicTitle, tag)"
                  >
                    {{ tag }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        v-if="!useICRSConfig"
        class="footerTray"
      />

      <b-card-text
        class="resultText"
        v-if="!useICRSConfig && cardModel.relationChain"
      >
        <span>
          <strong>Relationship:</strong>
        </span>
        <span
          v-for="(r, index) in cardModel.relationChain"
          v-bind:key="index"
        >
          <font-awesome-icon
            v-if="index > 0"
            class="icon"
            icon="caret-right"
            size="1x"
          />
          {{ r }}
        </span>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ICRSService from '@/services/icrs-service';
import EventBusService from '@/services/event-bus.service';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { htmlEncode } from '@/helpers/helpers';
import SearchResultDocumentModel from '../../services/models/search/search-result-document.model';
import SearchResultCardModel from '../../services/models/search-result-card/search-result-card.model';
import IconText from '../IconText.vue';
import SettingsDropdown from '../settings-dropdown/SettingsDropdown.vue';

export default {
  name: 'SearchResultCard',
  components: {
    IconText,
    SettingsDropdown,
  },
  props: {
    SearchResultDocument: {
      type: SearchResultDocumentModel,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    overallShowAwards: {
      type: Boolean,
      default: false,
    },
    overallShowKeywords: {
      type: Boolean,
      default: false,
    },
    synonymSearchPluginEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      numGlobalColours: 6,
      cardHover: false,
      config: {
        showPreviewAction: true,
        showShareAction: false,
      },
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
      finalDisplayOrder: {},
      showAwards: false,
      showKeywords: false,
      previewData: null,
      formattedData: {
        injuryMap: [],
        globalAwardedMap: [],
        lec: [],
        lfe: [],
      },
      widthPS: null,
      numInjuryTiers: 0, // dummy initialisation
      showInjuryTier1: false,
      showInjuryTier2: false,
      showInjuryTier3: false,
      showInjuryTier4: false,
      messageInjuryTier2: '',
      messageInjuryTier3: '',
      messageInjuryTier4: '',
      globalCase: false,
      widthOA: null,
      numTiers: 0, // dummy initialisation
      showTier1: false,
      showTier2: false,
      showTier3: false,
      showTier4: false,
      messageTier2: '',
      messageTier3: '',
      messageTier4: '',
      natureTrails: [],
      guidedPrefixIgnoreList: [],
    };
  },
  computed: {
    ...mapGetters('icrs', ['allGuidedCategories']),
    cardModel () {
      return new SearchResultCardModel(this.SearchResultDocument);
    },
    showGlobal () {
      return window.outerWidth > 768;
    },
    firsTopicTitle () {
      return Object.keys(this.finalDisplayOrder)[0];
    },
    awardsId () {
      return `${this.SearchResultDocument.id.replaceAll(' ', '')}_awards`;
    },
    keywordsId () {
      return `${this.SearchResultDocument.id.replaceAll(' ', '')}_keywords`;
    },
    natureTrailMap () {
      let temp = [...this.natureTrails];
      temp = temp.map(child => [...child].map((value, index) => {
        let list;
        if (index === 0) list = this.allGuidedCategories.level1;
        if (index === 1) list = this.allGuidedCategories.level2;
        if (index === 2) list = this.allGuidedCategories.level3;

        if (!list) return value;

        const injury = [...list].find(item => item.code === value);
        if (injury) {
          return {
            code: value,
            elitLabel: injury.elitLabel || injury.code,
          };
        }
        return value;
      }));
      return temp;
    },
  },
  watch: {
    // for overallShowAwards and overallShowKeywords handlers,
    //  make sure to re-assign these 2 flags, to keep in sync with the parent
    //  because either overallShowAwards, overallShowKeywords watchers may not trigger
    //  if the value doesn't changes on the parent
    overallShowAwards: {
      immediate: true,
      handler () {
        this.showKeywords = this.overallShowKeywords;
        this.showAwards = this.overallShowAwards;
      },
    },
    overallShowKeywords: {
      immediate: true,
      handler () {
        this.showKeywords = this.overallShowKeywords;
        this.showAwards = this.overallShowAwards;
      },
    },
    showAwards: {
      immediate: true,
      handler (value) {
        if (value) {
          if (this.previewData == null) {
            this.populatePreviewData(this.SearchResultDocument.id);
          }
        }
      },
    },
  },
  methods: {
    htmlEncode,
    ...mapActions({
      triggerSearchQuery: 'searchService/triggerSearchQuery',
    }),
    ...mapActions('icrs', [
      'setGuidedSearch',
      'setDropdownGuidedTag',
      'setActiveGuidedPrefix',
      'setSelectedLevel1',
      'setSelectedLevel2',
      'setSelectedLevel3',
      'setLevel2CategoriesForSelectedLevel1',
      'setLevel3CategoriesForSelectedLevel2',
    ]),
    fixLink (url) {
      return sanitizeUrl(url);
    },
    toggleAwards () {
      this.showAwards = !this.showAwards;
    },
    toggleKeywords () {
      this.showKeywords = !this.showKeywords;
    },
    updateFinalDisplayOrder (topicTitle, topicDisplay) {
      if (topicTitle in this.SearchResultDocument.tags) {
        this.finalDisplayOrder[topicTitle] = topicDisplay;
      }
    },
    handlePreviewViewerClick () {
      this.$emit('open-preview-viewer');
    },
    handleTitleClick () {
      this.$emit('trigger-document-metrics');
    },
    populatePreviewData (id) {
      const params = { id };
      ICRSService.execGetICRSPreview(params).then((json) => {
        const data = JSON.parse(json.preview);
        this.previewData = data;
        this.populateAwardSummary();
      });
      // this.previewData = this.SearchResultDocument.metadata.preview;
      // this.populateAwardSummary();
    },
    populateAwardSummary () {
      const alphabets = 'abcdefghijklmnopqrstuvwxyz'.split('');
      const injuryMapKeys = Object.keys(this.previewData.injuryMap);

      alphabets.map(alphabet => this.alphabetsMap(alphabet, injuryMapKeys));
      this.previewData.lec.map(lecRow => this.lecArrayMap(lecRow));
      this.previewData.lfe.map(lfeRow => this.lfeArrayMap(lfeRow));
    },
    alphabetsMap (alphabet, injuryMapKeys) {
      const injuryArray = [];
      const globalArray = [];

      const injuryKey = `${alphabet}-${0}`;
      if (injuryMapKeys.includes(injuryKey)) {
        injuryArray[0] = `${alphabet}) ${this.previewData.injuryMap[injuryKey]}`;
        globalArray[0] = `${alphabet}) ${this.previewData.injuryMap[injuryKey]}`;
      }
      for (let num = 1; num <= 4; num += 1) {
        const currentKey = `${alphabet}-${num}`;
        if (injuryMapKeys.includes(currentKey)) {
          if (num > this.numTiers) {
            if (num === 1) {
              this.updateInjuryNumTiers(num, '');
            } else {
              const hearingText = this.previewData.previews.column6[`tier ${num}`][0];
              this.updateInjuryNumTiers(num, hearingText);
            }
          }
          injuryArray[num] = this.previewData.injuryMap[currentKey];
          globalArray[num] = this.previewData.globalAwardedMap[currentKey];
          if (this.previewData.globalAwardedMap[currentKey] !== 0) {
            this.globalCase = true;
          }
        }
      }
      this.formattedData.injuryMap.push(injuryArray);
      this.formattedData.globalAwardedMap.push(globalArray);
    },
    lecArrayMap (currentRow) {
      const arrayIndex = Number.parseInt(currentRow[1], 10);
      if (arrayIndex > this.numTiers) {
        if (arrayIndex === 1) {
          this.updateNumTiers(arrayIndex, '');
        } else {
          const hearingText = this.previewData.previews.column6[`tier ${arrayIndex}`][0];
          this.updateNumTiers(arrayIndex, hearingText);
        }
      }
      // eslint-disable-next-line prefer-destructuring
      this.formattedData.lec[arrayIndex] = currentRow[0];
    },
    lfeArrayMap (currentRow) {
      const arrayIndex = Number.parseInt(currentRow[1], 10);
      if (arrayIndex > this.numTiers) {
        if (arrayIndex === 1) {
          this.updateNumTiers(arrayIndex, '');
        } else {
          const hearingText = (this.previewData.previews.column6[`tier ${arrayIndex}`] || [])[0];
          this.updateNumTiers(arrayIndex, hearingText);
        }
      }
      // eslint-disable-next-line prefer-destructuring
      this.formattedData.lfe[arrayIndex] = currentRow[0];
    },
    updateInjuryNumTiers (newNum, text) {
      this.numInjuryTiers = newNum;
      this.widthPS = `${50 / newNum}%`;
      this[`showInjuryTier${newNum}`] = true;
      this[`messageInjuryTier${newNum}`] = text;
    },
    updateNumTiers (newNum, text) {
      this.numTiers = newNum;
      this.widthOA = `${100 / (newNum + 1)}%`;
      this[`showTier${newNum}`] = true;
      this[`messageTier${newNum}`] = text;
    },
    searchTag (tagCategory, tagValue, natureTrail) {
      // find injury by elitName
      const level = tagCategory.replace('level', '').replace('code', '');
      let codeInjury = tagValue;
      if (+level === 1 || +level === 2) {
        const injuryList = +level === 1 ? this.allGuidedCategories.level1 : this.allGuidedCategories.level2;
        injuryList.some((injury) => {
          if (injury.elitLabel === tagValue) {
            codeInjury = injury.code;
            return true;
          }
        });
      }
      if (+level === 3 && natureTrail) {
        const level2 = this.allGuidedCategories.level2.find(injury => injury.elitLabel === (natureTrail[1].elitLabel || natureTrail[1]));
        if (level2) {
          this.allGuidedCategories.level3.some((injury) => {
            if (injury.elitLabel === tagValue && injury.parentCode === level2.code) {
              codeInjury = injury.code;
              return true;
            }
          });
        }
      }
      // Clear existing nature of injury stuff
      this.setGuidedSearch(false);
      this.setDropdownGuidedTag('');
      this.setActiveGuidedPrefix(0);
      this.setSelectedLevel1({ code: 'All' });
      this.setSelectedLevel2({ code: 'All' });
      this.setSelectedLevel3({ code: 'All' });
      this.setLevel2CategoriesForSelectedLevel1([{ code: 'All' }]);
      this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);

      const searchBarInput = `tags.${tagCategory}:"${codeInjury}"`;
      let appliedSynonyms = null;

      if (this.synonymSearchPluginEnabled) {
        appliedSynonyms = this.activateSynonymSearchPlugin();
      }
      // Used by ICRS in search page
      const eventMsg = { resetSources: true, query: searchBarInput };
      if (appliedSynonyms) {
        eventMsg.appliedSynonyms = appliedSynonyms;
      }
      this.triggerSearchQuery(eventMsg);
    },
    activateSynonymSearchPlugin () {
      let appliedSynonyms = null;
      const mySynonyms = this.$refs.synonymSearchPlugin.getSelectedSynonyms();
      appliedSynonyms = mySynonyms || null;
      return appliedSynonyms;
    },
    async handleShareClick () {
      this.$clipboard(this.SearchResultDocument.path);
      EventBusService.emitNotificationEvent({
        title: 'Link copied to clipboard',
        variant: 'success',
      });
    },
    formatGuidedDisplay (term) {
      let formattedTerm = term;
      // eslint-disable-next-line array-callback-return
      this.guidedPrefixIgnoreList.map((prefix) => {
        formattedTerm = formattedTerm.replace(prefix, '');
      });
      return formattedTerm;
    },
  },
  mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }

    const displayOrder = ICRSService.getKeywordsTopicTitleDisplayOrder();
    Object.keys(displayOrder).forEach(topicTitle => this.updateFinalDisplayOrder(topicTitle, displayOrder[topicTitle]));

    this.guidedPrefixIgnoreList = ICRSService.getGuidedPrefixIgnoreList();
    const guidedCategories = {};

    this.allGuidedCategories.level1.forEach((level1) => {
      const dataLevel1 = this.allGuidedCategories.level2.filter(
        item2 => item2.parentCode === level1.code,
      );
      guidedCategories[level1.code] = {};
      dataLevel1.forEach((level2) => {
        guidedCategories[level1.code][level2.code] = this.allGuidedCategories.level3.filter(
          level3 => level3.parentCode === level2.code,
        ).map(level3 => level3.code);
      });
    });

    const natureTrails = [];
    const level1Terms = this.SearchResultDocument.tags.level1code || [];
    const level2Terms = this.SearchResultDocument.tags.level2code || [];
    const level3Terms = this.SearchResultDocument.tags.level3code || [];

    // eslint-disable-next-line array-callback-return
    Object.keys(guidedCategories).forEach((level1Key) => {
      if (level1Terms.includes(level1Key)) {
        let level2Found = false;
        // eslint-disable-next-line array-callback-return
        Object.keys(guidedCategories[level1Key]).forEach((level2Key) => {
          if (level2Terms.includes(level2Key)) {
            level2Found = true;
            let level3Found = false;
            // eslint-disable-next-line array-callback-return
            (guidedCategories[level1Key][level2Key] || []).forEach((level3Key) => {
              if (level3Terms.includes(level3Key)) {
                natureTrails.push([level1Key, level2Key, level3Key]);
                level3Found = true;
              }
            });
            if (!level3Found) {
              natureTrails.push([level1Key, level2Key]);
            }
          }
        });
        if (!level2Found) {
          natureTrails.push([level1Key]);
        }
      }
    });
    this.natureTrails = natureTrails;
  },
};
</script>

<style lang="scss">

.tag-result-card {
  .jds-tag {
    &.has-primary-light {
      word-break: break-all;
    }
  }
}

.search-result-card {
  em {
    font-weight: $weight-bold;
    font-style: normal;
  }

  .badge-wrapper {
    display: inline-block;
    margin-left: $gap-xxs;
  }

  &/deep/ .badge {
    padding: 4px 8px !important;
    line-height: 12px !important;
    margin-bottom: 2px;
  }

  .resultTitle {
    font-size: 18px;
    margin-bottom: 0.25rem;
    font-weight: $weight-semi-bold;
    align-items: center;
    & a {
      color: $blue-gray-500;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .card-body {
    position: relative;
    padding: $gap-md !important;
  }

  .resultCard {
    background-color: white;
    border: 0 !important;
    margin-left: -$gap-md;
    margin-right: -$gap-md;
    &:hover {
      background-color: $blue-200;
    }
  }

  .resultSubTitle {
    font-size: $font-sm;
    margin-top: 0;
    margin-bottom: 0.25rem;
  }

  .resultText {
    font-size: $font-md;
    color: $blue-gray-500;
  }

  .buttonTray {
    padding: 4px 8px;
    border-radius: $radius-4;
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    background: $blue-200;

    & .content_logo_text {
      margin-right: $gap-md;
      display: flex;
      align-items: center;
      color: $blue-400;

      &:last-child {
        margin-right: 0;
      }
    }

    & .btn {
      padding: 0px;
    }

    & * {
      cursor: pointer;
    }
  }

  .icon {
    position: relative;
    top: -1.5px;
  }
}
</style>
