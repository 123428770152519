<template>
  <div
    id="advanced-search-trending-topics"
    class="border rounded"
  >
    <div class="text-md">Trending concepts:</div>
    <b-table
      thead-class="d-none"
      tbody-tr-class="table-row-style paragraph"
      :items="this.displayedTable"
      :fields="[
        { key: 'label', label: '', tdClass: 'font-secondary'},
        { key: 'classLabel', label: ''}
      ]"
      @row-clicked="handleTrendingTopicClick"
    >
      <template #cell(label)="data">
        <div class="d-flex flex-column">
          <div class="individual-label text-capitalize">{{data.item.label}}</div>
          <div
            v-if="config.showGraphNameInBadge"
            class="graph-label"
          >{{data.item.graphName}}</div>
        </div>
      </template>
      <template #cell(classLabel)="data">
        <Badge
          pill
          class="float-right text-capitalize"
          :style="{'background-color': data.item.backgroundColor, 'color': data.item.color}"
        >{{data.item.classLabel}}</Badge>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { getColorFromString, getGraphFromType } from '../../ontology-search/ontology-search-helpers';

const lookbackDays = 30;
const trendingSize = 5;

export default {
  name: 'TrendingTopics',
  data () {
    return {
      displayedTable: [],
      config: {
        showGraphNameInBadge: false,
      },
    };
  },
  mounted () {
    // after DOM update throw this logic
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
    this.fetchTrendingIndividuals();
  },
  methods: {
    ...mapActions('advancedSearch', [
      'getTrendingIndividuals',
    ]),
    fetchTrendingIndividuals () {
      this.getTrendingIndividuals({ lookbackDays, size: trendingSize }).then((response) => {
        this.displayedTable = response.map((item) => {
          const graphName = getGraphFromType(item.classId);
          const colors = getColorFromString(item.classLabel);
          const rowObj = Object.assign(item, {
            color: colors.color,
            backgroundColor: colors.backgroundColor,
            graphName,
          });
          return rowObj;
        });
      });
    },
    handleTrendingTopicClick (event) {
      this.$emit('trending-topic-click', event);
    },
  },
};
</script>
<style lang="scss" scoped>
#advanced-search-trending-topics {
  padding: 1em;
  padding-bottom: 0;
  width: 40vw;
}
/deep/ tr:first-child td {
  border-top: 0 !important;
}
/deep/ .table-row-style {
  &:hover {
    background-color: $blue-200 !important;
    cursor: pointer;
  }
  td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 30vw;
    padding-left: 0px;
    &:first-child {
      float: left;
      max-width: 75%;
    }
    &:last-child {
      float: right;
      max-width: 25%;
      padding: 0;
      padding-right: 1em;
      padding-top: 0.65em;
    }
  }
}
/deep/ .table {
  margin-bottom: 0;
}
.individual-label {
  color: $blue-gray-400;
  font-size: $font-md;
  font-weight: $weight-normal;
}
.graph-label {
  color: $blue-gray-400;
  font-size: $font-sm;
  font-weight: $weight-light;
}
</style>
