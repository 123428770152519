<template>
  <div>
    <div class="floating_button_holder">
      <!-- SHOW SEMANTIC SEARCH LIST -->
      <b-button
        class="semantic-search m-0 mr-2 px-3 border-0 rounded d-flex align-items-center"
        :class="{ 'semantic-search-active': showSemanticSearchList }"
        @click="onShowSemanticSearchClicked"
        v-if="showSemanticSearch"
      >Also looking for...</b-button>
    </div>

    <!-- SEMANTIC SEARCH LIST -->
    <b-row
      class="semantic-search-list w-100 py-2 px-0 m-0 d-flex justify-content"
      v-if="showSemanticSearchList"
    >
      <b-col
        cols="1.5"
        class="semantic-search-title d-flex align-items-center justify-content-start px-3 py-1"
      >Also looking for...</b-col>

      <b-col
        v-if="currentSemanticSearchKeywordsList.length === 0"
        cols="9"
        class="semantic-search-keywords d-flex align-items-center text-left font-weight-normal"
      >No related words were found for this Query</b-col>

      <!-- KEYWORD LIST -->
      <b-col
        v-if="currentSemanticSearchKeywordsList.length > 0"
        cols="9"
        class="semantic-search-keywords d-flex align-items-center text-left font-weight-normal"
      >
        <b-row>

          <template v-for="(synonymObj, index) in visibleSynonymObjArray">
            <span
              class="rounded py-1 px-2 mr-1 border justify-content-center"
              :class="synonymObjClass(synonymObj)"
              :key="index + '-keyword'"
              @click="toggleSynonymObject(index)"
            >{{ synonymObj.name }}</span>
          </template>

          <!-- SHOW MORE BUTTON -->
          <b-button
            class="semantic-search-expand bg-white border-0 rounded py-1 px-2 m-0 ml-4"
            v-if="showShowAllButton"
            @click="expandSemanticSearchList = true"
          >Show All</b-button>
        </b-row>
      </b-col>
      <!-- SHOW LESS BUTTON -->
      <b-col
        cols="12"
        class="d-flex justify-content-center"
      >
        <b-button
          class="semantic-search-expand bg-white border-0 rounded py-1 px-2 mt-3 mb-1"
          @click="closeExpandSemanticSearchList"
          v-if="expandSemanticSearchList"
        >Show Less</b-button>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
// import _ from 'lodash';

/**
 * @todo refactor component and use it for search bar in fullpage modal(?)
 * data structure and state management for selected sematic keywords to be revised.
 *
 *  ALL synonyms applied on search
 * on unselect, send out event to be captured by search bar and do search again
 * synonyms list to be regulated (if empty, all are enabled)
 */
export default {
  props: {
    searchBarInput: {
      type: String,
      default: 'Singapore',
    },
    selectAllSynonymsOnLoad: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      compactSynonymCount: 15,
      showSemanticSearchList: false,
      expandSemanticSearchList: false,
      semanticSearch: [],
      currentSynonymQuery: null,
      synonymObjArray: [],
    };
  },
  computed: {
    ...mapState('fullpageModal', ['currentSemanticSearchKeywordsList']),
    showShowAllButton () {
      return (
        !this.expandSemanticSearchList
        && this.currentSemanticSearchKeywordsList.length > this.compactSynonymCount
      );
    },
    showSemanticSearch () {
      return this.searchBarInput.length > 0;
    },

    visibleSynonymObjArray () {
      let synonymObjArrayToShow = this.synonymObjArray;

      if (
        !this.expandSemanticSearchList
        && synonymObjArrayToShow.length > this.compactSynonymCount
      ) {
        synonymObjArrayToShow = synonymObjArrayToShow.slice(
          0,
          this.compactSynonymCount,
        );
      }

      return synonymObjArrayToShow;
    },
  },
  methods: {
    ...mapActions({
      // favoriteViewer
      execGetSemanticSearch: 'fullpageModal/execGetSemanticSearch',
    }),
    async onShowSemanticSearchClicked () {
      this.showSemanticSearchList = !this.showSemanticSearchList;
      const query = this.searchBarInput;
      this.currentSynonymQuery = query;

      if (this.showSemanticSearchList) {
        await this.execGetSemanticSearch({ query });

        this.semanticSearch = this.currentSemanticSearchKeywordsList;

        // chips are set true by default
        this.synonymObjArray = this.semanticSearch.map(synonym => ({
          name: synonym,
          selected: this.selectAllSynonymsOnLoad,
        }));
      }
    },
    synonymObjClass ({ name, selected }) {
      const synonymClasses = [];
      if (this.expandSemanticSearchList) {
        synonymClasses.push('mb-1');
      }
      if (selected) {
        synonymClasses.push('selected-semantic-keyword');
      } else {
        synonymClasses.push('semantic-keyword');
      }
      return synonymClasses.join(' ');
    },
    closeExpandSemanticSearchList () {
      this.expandSemanticSearchList = false;
    },

    toggleSynonymObject (index) {
      const mySynonymObject = this.synonymObjArray[index];
      mySynonymObject.selected = !mySynonymObject.selected;
    },

    /**
     * Called when search bar needs the value
     */
    getSelectedSynonyms () {
      if (this.currentSynonymQuery !== this.searchBarInput) {
        // if its not the same, close and reset
        this.resetSynonyms();
      }

      return this.showSemanticSearchList
        ? this.synonymObjArray.reduce((result, option) => {
          if (option.selected) {
            result.push(option.name);
          }
          return result;
        }, [])
        : null;
    },
    resetSynonyms () {
      this.showSemanticSearchList = false;
      this.semanticSearch = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.floating_button_holder {
  position: absolute;
  top: 5px;
  right: 86px;
}
.semantic-search {
  height: 28px;
  background: $white-500 !important;
  font-weight: $weight-semi-bold !important;
  font-size: 12px !important;
  color: #444 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

  &:hover {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    background: #f9f9f9;
  }
}
.semantic-search-active {
  background: #e1e1e1 !important;
  box-shadow: none;
}
.semantic-search-list {
  //   top: 50px;
  //   left: 0;
  position: absolute;
  z-index: 10;
  background: #f3f3f3;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);

  .semantic-search-title {
    font-weight: $weight-semi-bold;
    font-size: 12px;
    line-height: 1;
    color: #444444;
  }
  .semantic-search-keywords {
    font-size: 12px;
    color: #444444;
    word-wrap: break-word;

    .selected-semantic-keyword {
      background: #e2f1ee !important;
      border: 1px solid #bae5dc;

      &:hover {
        cursor: pointer;
      }
    }

    .semantic-keyword {
      background: #fff;

      &:hover {
        cursor: pointer;
        border: 1px solid $green-500 !important;
        // background: $green-500 !important;
      }

      &:active {
        color: $white-500;
        background: $green-500 !important;
      }
    }
  }

  .semantic-search-expand {
    color: #444444;
    font-weight: $weight-semi-bold;
    font-size: 12px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.45);

    &:hover {
      color: #444444;
      background: #f1f1f1 !important;
    }

    &:active,
    &:focus {
      outline: none !important;
      color: #444444 !important;
      background: #e1e1e1 !important;
    }
  }
}
</style>
