<template>
  <div>
    <div class="upload-container">
      <input style="display:none" type="file" :multiple="multiple" :accept="accept" :id="id" ref="fileInput" @change="addFile">
      <label class="label-container" :for="id">
        <font-awesome-icon style="color: #51565F;font-size: 32px;" :icon="['fas', 'cloud-upload-alt']" />
        <div class="upload-text">
          <span class="label-text">Click to upload</span>
          <span class="limit-text">{{ limitText }}</span>
        </div>
      </label>
    </div>
    <div class="data-show" v-if="fileList.length!==0">
      <div class="data-list" v-for="(file,index) in fileList" :key="index">
        <div class="file-container">
            <div class="file-icon">
              <img-svg  style="margin-top:4px;" v-if="file.type.includes('image')"></img-svg>
              <xlsx-svg v-if="file.type.includes('application/vnd.ms-excel')||file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')"></xlsx-svg>
            </div>
            <div class="file-data">
              <span class="file-name">{{ file.name }}</span>
              <span v-if="file.uploadSuccess" class="file-size">{{ formatFileSize(file.size) }}</span>
              <span v-else class="upload-failed">Upload failed{{ file.failureMessage }}</span>
            </div>
        </div>
        <font-awesome-icon class="cancel-btn" :icon="['fas', 'times']" @click="removeFile(file)" />
      </div>
    </div>
    <div
      class="upload-error-message"
      v-if="showErrorMessage"
    >
      Upload failed. Only excel with a filesize below 2MB is accepted.
    </div>
    <LoadingSpinner v-if="loading"
      text="Loading..."
      size="md"
    />
  </div>
</template>

<script>
import ImgSvg from '@/assets/upload-icons/img.svg';
import XlsxSvg from '@/assets/upload-icons/xlsx.svg';
import { mapActions, mapGetters } from 'vuex';
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  components: {
    ImgSvg,
    XlsxSvg,
    LoadingSpinner,
  },
  data () {
    return {
      fileList: [],
      loading: false,
      uploadProgress: 0,
      error: '',
      id: 'input-upload',
      showErrorMessage: false,
    };
  },
  props: {
    limitText: {
      type: String,
      default: 'up to 2MB(JPEG,JPG,or PNG)',
    },
    action: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/jpg',
    },
    uploadApi: {
      type: Function,
    },
    tempFolder: {
      type: String,
      default: '',
    },
    saveSuccessfully: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('searchService', [
      'uploadFileList',
    ]),
  },
  methods: {
    ...mapActions({
      uploadFile: 'searchService/uploadFile',
    }),
    async addFile (event) {
      if (event.target.files.length) {
        const data = [];
        [...event.target.files].map((file) => {
          if (file.type && this.accept.includes(file.type)) {
            data.push(file);
          }
          return data;
        });
        await this.fetchSubmit(data);
      }
    },
    removeFile (file) {
      this.fileList = this.fileList.filter(value => value.name !== file.name);
      this.$refs.fileInput.value = '';
      this.$emit('uploadFile', this.fileList);
    },
    formatFileSize (size) {
      const units = ['B', 'KB', 'MB', 'GB', 'TB'];
      let unitIndex = 0;
      while (size > 1024) {
        size /= 1024;
        unitIndex++;
      }
      return `${size.toFixed(2)} ${units[unitIndex]}`;
    },
    async fetchSubmit (files) {
      if (!files.length) {
        this.showErrorMessage = true;
        return;
      }

      files.map(async (file) => {
        const fileData = file;
        if (fileData.size > 2 * 1024 * 1024) {
          this.showErrorMessage = true;
          return;
        }

        this.loading = true;
        this.showErrorMessage = false;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('tempFolder', this.tempFolder ? this.tempFolder : null);

        await this.uploadApi(formData).then((res) => {
          const { data: { tempFolderName, id } } = res;
          fileData.tempFolderName = tempFolderName;
          fileData.id = id;
          fileData.uploadSuccess = true;
          fileData.failureMessage = '';
          this.fileList.push(fileData);
          this.loading = false;
          this.$emit('uploadFile', this.fileList);
        }).catch((error) => {
          const { data } = error.response;
          let errorMessage = '';
          if (data) {
            errorMessage = `, ${data.status.message.charAt(0).toLowerCase()
            + data.status.message.slice(1)}`;
          }

          fileData.id = '';
          fileData.tempFolderName = '';
          fileData.uploadSuccess = false;
          fileData.failureMessage = errorMessage;
          this.fileList.push(fileData);
          this.loading = false;
          this.$emit('uploadFile', this.fileList);
        });
      });
    },
  },
  watch: {
    saveSuccessfully (value) {
      if (value) {
        this.fileList = [];
        this.$refs.fileInput.value = '';
        this.showErrorMessage = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-container{
  position: relative;
  width: 100%;
  height: 95px;
  border: 1px dashed #D1CFCF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  border-radius: 5px;
  .label-container{
    position: absolute;
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .upload-text{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 16px;
      .label-text{
        font-size: 16px;
        color: #751C24;
      }
      .limit-text{
        color: #6C757D;
        font-size: 12px;
      }
    }
  }
  label{
    margin: 0;
  }
}
.data-show{
  .data-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 8px 16px;
    font-size: 16px;
    border: 1px solid #E8E9EA;
    .file-container{
      display: flex;
      align-items: center;
      overflow: hidden;
      .file-icon{
        margin-right: 16.5px;
      }
      .file-data{
        display: flex;
        flex-direction: column;
        // padding-left: 16.5px;
        overflow: hidden;

        .file-name{
          font-size: 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .file-size,.upload-failed{
          font-style: italic;
          font-size: 14px;
          color: #707174;
        }
        .upload-failed{
          color: #D01B1B;
        }
      }
    }
    .cancel-btn{
      cursor: pointer;
      padding-left: 10px;
    }
  }
}

.upload-error-message {
  margin-top: 15px;
  color: #D01B1B;
  font-style: italic;
  font-size: 14px;
}
</style>
