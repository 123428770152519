import SearchService from '../../../../../services/search.service';
import SearchServiceTypes from '../../../../../constants/search-service-constants';
import SearchNewParameter from '../../../../../services/models/search/search-new-parameter.model';
import SearchNewRequestParameter from '../../../../../services/models/search/search-new-request-parameter.model';
import SearchResultModel from '../../../../../services/models/search/search-result.model';

export default {
  setCurrentPage ({ commit }, page) {
    commit('setCurrentPage', page);
  },
  /**
   * Query server search API
   * @param {object?} payload
   * @param {number?} payload.filterGroupLevel
   */
  executeSimpleNewSearch ({ state, commit, dispatch }, payload = {}) {
    const searchNewRequestParameter = new SearchNewRequestParameter(state.currentQuery, state.currentSearchParameter);
    commit('setCurrentSearchRequestParameter', searchNewRequestParameter);
    commit('setIsSearching', true);

    return SearchService.postSimpleSearchNew(searchNewRequestParameter).then((response) => {
      const { data } = response;
      commit('setCurrentSearchResults', new SearchResultModel(data));

      // todo
      // set current page to another value
      // if the page is bigger than last page
      // when user changed the items per page
      commit('setIsSearchSuccess', true);
    })
      .finally(() => {
        commit('setIsSearching', false);
      });
  },

  /**
   * @param {object?} payload
   * @param {number?} payload.filterGroupLevel
   */
  executeSearchNewQuery ({
    state, commit, rootGetters, rootState, dispatch,
  }, payload = {}) {
    /**
     * @todo trigger nlp search for answer
     */
    if (!state.criteriaId || state.criteriaId.trim().length === 0) return false; // return if no criteriaId found
    const param = new SearchNewParameter(
      state.currentSearchMode,
      rootGetters['searchService/filters/appliedFilters'],
      state.currentDateObject,
      state.currentPage,
      state.appliedSort,
      state.selectedSource,
      state.appliedSynonyms,
      state.itemsPerPage,
      state.currentSearchResults.id,
      state.searchWithin,
      state.criteriaId,
      state.promoteDateObject,
    );
    commit('setCurrentSearchParameter', param);
    return dispatch('executeSimpleNewSearch', payload);
  },
  async searchForNewDocuments ({ commit, dispatch, getters }, savedSearch) {
    const { request, id: criteriaId } = savedSearch;
    const {
      query,
      size,
      sortBy,
      source,
      synonyms = null,
      from,
      searchWithin,
    } = request;
    if (!query.length) {
      return;
    }

    // pagination page size not set from saved search
    commit('setCriteriaId', criteriaId);
    commit('setCurrentPage', SearchNewParameter.getPageFromOffset(from, size));
    commit('setAppliedSort', sortBy);
    commit('setCurrentQuery', query);
    commit('setCurrentSearchMode', SearchServiceTypes.SEARCH_MODE.SAVE_SEARCH);
    commit('setAppliedSynonyms', synonyms);
    commit('searchService/filters/setSelectedFilters', [], { root: true });

    if (searchWithin) {
      commit('setSearchWithin', SearchNewParameter.convertSearchWithinAsText(searchWithin));
    }

    const selectedSource = Array.isArray(source) && !source.length
      ? undefined
      : source;

    commit('setSelectedSource', selectedSource);
    dispatch('executeSearchNewQuery');
    dispatch('executeCategoriesNew');
  },
  async executeCategoriesNew ({ state, commit }) {
    if (!state.criteriaId || state.criteriaId.trim().length === 0) return; // return if no criteriaId found
    const mySearchParameter = _.omit(state.currentSearchParameter, 'source');
    const mySearchNewRequestParameter = new SearchNewRequestParameter(state.currentQuery, mySearchParameter);
    mySearchNewRequestParameter.searchMode = SearchServiceTypes.SEARCH_MODE.AGGREGATION;

    SearchService.execCategoriesNew(mySearchNewRequestParameter)
      .then((categories) => {
        commit('setCategories', categories);
      });
  },

  setCurrentSearchMode ({ commit }, searchMode) {
    commit('setCurrentSearchMode', searchMode);
  },
  setSelectedSource ({ commit }, source) {
    commit('setSelectedSource', source);
  },
  setItemsPerPage ({ commit }, number) {
    commit('setItemsPerPage', number);
  },
  setAppliedSort ({ commit }, sort) {
    commit('setAppliedSort', sort);
  },
  resetNewSearchResults ({ commit }) {
    commit('resetState');
  },
  /**
   * Refresh search results with current state
   */
  triggerSearchRefresh ({ state, dispatch }) {
    dispatch('triggerSearchQuery', {
      query: state.currentQuery,
      searchMode: SearchServiceTypes.SEARCH_MODE.PAGINATION,
    });
  },
  /**
   * Do search with provided query
   * Can be triggered from Searchbox / Filters / Date Range
   */
  triggerSearchQuery (
    { commit, dispatch },
    {
      query,
      searchMode = SearchServiceTypes.SEARCH_MODE.NEW,
      resetSources = false,
      appliedSynonyms = null,
    },
  ) {
    // eslint-disable-next-line no-unused-vars
    let doResetSources = resetSources;

    if (!query || !query.length) {
      return Promise.resolve();
    }

    // prevent reset of pagination page size
    commit('setAppliedSort', {});
    commit('setCurrentQuery', query);
    commit('setCurrentSearchMode', searchMode);
    commit('setAppliedSynonyms', appliedSynonyms);

    if (searchMode === SearchServiceTypes.SEARCH_MODE.NEW) {
      // todo: date range and date object with new search
      commit('searchService/filters/setSelectedFilters', [], { root: true });
      commit('setSearchWithin', []);
      doResetSources = true; //  new query always resets the sources tabs
    }

    if (searchMode !== SearchServiceTypes.SEARCH_MODE.PAGINATION) {
      commit('setCurrentPage', 1);
    }

    if (doResetSources) {
      commit('setSelectedSource', undefined);
    }

    const promise = dispatch('executeSearchNewQuery');

    if (doResetSources) {
      // execute categories should be happen after executeSearchQuery
      // cos, executeSearchQuery is setting the query object
      // todo: make the prosedure more granular
      dispatch('executeCategoriesNew');
    }

    return promise;
  },
  addSearchWithin ({ commit }, text) {
    commit('addSearchWithin', text);
  },
  removeSearchWithin ({ commit }, text) {
    commit('removeSearchWithin', text);
  },
  triggerSearchWithinQuery ({ state, dispatch }) {
    return dispatch('triggerSearchQuery', {
      query: state.currentQuery,
      searchMode: SearchServiceTypes.SEARCH_MODE.SEARCH_WITHIN,
      resetSources: true,
    });
  },
  setCurrentDateObject ({ commit }, dateObject) {
    commit('setCurrentDateObject', dateObject);
  },
  setPromoteDateObject ({ commit }, dateObject) {
    commit('setPromoteDateObject', dateObject);
  },
};
