import MetricsObject from '@/services/models/event-tracker/metrics-object.model';
import EventTrackerService from '../../../services/event-tracker.service';

export default {

  execSearchTrackerMetrics ({ rootGetters, dispatch }) {
    const properties = {
      id: rootGetters['searchService/searchResultId'],
      from: rootGetters['searchService/from'],
      size: rootGetters['searchService/size'],
      searchMode: rootGetters['searchService/searchMode'],
      source: rootGetters['searchService/source'],
      sortBy: rootGetters['searchService/sortBy'],
    };

    const metricsObj = new MetricsObject({
      type: 'SEARCH',
      input: rootGetters['searchService/currentQuery'],
      properties: [properties],
    });

    if (properties.searchMode === 'FILTER') {
      dispatch('execFilterTrackerMetrics');
    }

    return EventTrackerService.sendTrackerMetrics(metricsObj);
  },

  execFilterTrackerMetrics ({ rootGetters }) {
    const metricsObj = new MetricsObject({
      type: 'FILTER',
      input: rootGetters['searchService/currentQuery'],
      properties: rootGetters['searchService/topicsMust'],
    });

    return EventTrackerService.sendTrackerMetrics(metricsObj);
  },

  execDocumentTrackerMetrics ({ rootGetters }, { document }) {
    const searchResultId = rootGetters['searchService/searchResultId'];
    const searchInput = rootGetters['searchService/currentQuery'];
    const documentPosition = rootGetters['searchService/getDocumentPosition'](document);


    const doc = {
      id: document.id,
      title: document.title,
      source: document.source,
      path: document.path,
      lastModifiedData: document.lastModifiedData,
      metadata: document.metadata,
      docFormat: document.docFormat,
      sensitive: document.sensitive,
      highlight: document.highlight,
      tags: document.tags,
    };

    const properties = {
      position: documentPosition,
      searchId: searchResultId,
      document: doc,
    };

    const metricsObj = new MetricsObject({
      type: 'SELECT_DOCUMENT',
      input: searchInput,
      properties: [properties],
    });

    return EventTrackerService.sendTrackerMetrics(metricsObj);
  },
};
