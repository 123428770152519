<template>
  <div
    class="faceted_search"
    :class="{ 'faceted_search--show-mobile-filter': !isSearchFilterCollapsed }"
  >
    <div class="section-separator">
      <div
        class="top-section"
        :class="{ 'top-section--open-more-options': isMobileMoreActionOpen }"
      >
        <div class="main-search-bar">
          <div class="search-bar-container">
            <search-bar
              ref="searchBar"
              :default-template-plugin="config.searchBar__defaultTemplatePlugin"
            ></search-bar>
          </div>
          <div class="more-options">
            <button
              @click="isMobileMoreActionOpen = !isMobileMoreActionOpen"
              class="btn btn-link"
            >
              {{ isMobileMoreActionOpen ? 'Less' : 'More' }}

              <icon-text
                faType="fas"
                faName="chevron-down"
                size="sm"
              ></icon-text>
            </button>
          </div>
        </div>
        <div class="search-actions">
          <div class="mx-2">
            <AdvancedSearchModal
              position="right"
              class="px-1"
            />
            <SaveSearch position="right" />
            <div class="filters-btn-wrapper d-inline-block">
              <b-button
                @click="isSearchFilterCollapsed = !isSearchFilterCollapsed"
                variant="link"
                class="ml-2"
              >
                Filters
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="isSearchSuccess"
      class="container container-wide"
    >
      <div class="row">
        <div class="col-12 col-xl-9 main-column">

          <SearchResults />

        </div>
        <div class="col">
          <div class="search-filter-wrapper">
            <SearchFilter
              :collapsed="isSearchFilterCollapsed"
              @toggle="handleToggleSearchFilterCollapse"
            />
          </div>
        </div>
      </div>
    </div>
    <notifications></notifications>
    <loading-page></loading-page>
  </div>
</template>

<script>
// https://vuejs.org/v2/guide/components.html#Dynamic-Components
// search bar **required
// results container ** required
// └── result card **dynamic
// filters **optional
import Qs from 'qs';
import { mapGetters, mapActions } from 'vuex';
import SearchBar from '@/modules/search-bar/SearchBar.vue';
import SearchFilter from '@/modules/search-filter/SearchFilter.vue';
import SaveSearch from '@/modules/save-search/SaveSearch.vue';
import SearchResults from '@/modules/search-results/SearchResults.vue';
import IconText from '@/components/IconText.vue';
import Notifications from '@/modules/notifications/Notifications.vue';
import LoadingPage from '@/modules/loading-page/LoadingPage.vue';
import AdvancedSearchModal from '@/modules/advanced-search/AdvancedSearchModal.vue';
import SearchParameter from '../../services/models/search/search-parameter.model';

export default {
  name: 'FacetedSearch',
  data () {
    return {
      isSearchFilterCollapsed: false,
      isMobileMoreActionOpen: false,
      config: {
        searchBar__defaultTemplatePlugin: '',
      },
    };
  },
  components: {
    IconText,
    SearchResults,
    SaveSearch,
    SearchFilter,
    SearchBar,
    Notifications,
    LoadingPage,
    AdvancedSearchModal,
  },
  computed: {
    ...mapGetters('searchService', [
      'isSearchSuccess',
      'currentQuery',
      'selectedSource',
      'appliedSort',
      'itemsPerPage',
      'currentPage',
    ]),

    ...mapGetters({
      isAdminUser: 'authService/isAdminUser',
    }),

    searchParameters ({
      currentQuery,
      selectedSource,
      appliedSort,
      itemsPerPage,
      currentPage,
    }) {
      return {
        currentQuery,
        selectedSource,
        appliedSort,
        itemsPerPage,
        currentPage,
      };
    },
  },
  watch: {
    searchParameters ({
      currentQuery,
      selectedSource,
      appliedSort,
      itemsPerPage,
      currentPage,
    }) {
      const encodedSortBy = Qs.stringify(appliedSort);
      const offset = currentPage;

      const urlParams = {
        query: currentQuery,
        source: selectedSource,
        sortBy: encodedSortBy,
        size: itemsPerPage,
        from: offset,
      };

      this.$router.replace({ path: '/faceted-search', query: urlParams }).catch(() => { });
    },
  },
  methods: {
    ...mapActions('searchService', [
      'searchForSavedSearch',
      'getSavedSearch',
      'triggerSearchQuery',
      'resetSearch',
    ]),

    ...mapActions('searchService/newResults', [
      'searchForNewDocuments',
      'resetNewSearchResults',
    ]),

    handleToggleSearchFilterCollapse (collapsed) {
      this.isSearchFilterCollapsed = collapsed;
    },

    checkCriteriaQuery () {
      const { criteria } = this.$route.query;
      if (!criteria) return;
      this.getSavedSearch(criteria)
        .then((savedSearch) => {
          this.searchForSavedSearch(savedSearch.request);
          if (savedSearch.newResults > 0) {
            this.searchForNewDocuments(savedSearch);
          } else {
            this.resetNewSearchResults();
          }
        });
    },
    /**
     * todo to be a helper method or its own class/model
     */
    checkRouteSearchQuery () {
      const {
        query, source, sortBy, from, size,
      } = this.$route.query;

      const selectedSource = source ? Array(source) : undefined;
      const appliedSort = Qs.parse(sortBy);
      const currentPage = from;
      const itemsPerPage = Number(size);

      this.triggerSearchQuery({
        resetSources: false, query, selectedSource, appliedSort, currentPage, itemsPerPage,
      });
    },
  },
  mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
    this.checkCriteriaQuery();
    this.checkRouteSearchQuery();
  },
  beforeDestroy () {
    this.resetSearch();
  },
};
</script>

<style lang="scss" scoped>
.faceted_search {
  background-color: $white-500;
}

.top-section {
  padding-top: $gap-lg;
  padding-bottom: $gap-lg;
  display: flex;
  flex-direction: column;
}

.search-actions {
  display: none;
  margin-top: $gap-lg;
}

.main-search-bar {
  flex: 1 1 0%;
  display: flex;
  max-width: 680px;
}

.search-bar-container {
  flex: 1 1 0%;
}

.search-filter-wrapper {
  background: $blue-gray-50;
  display: none !important;
}

// Smaller or equal to 1025px
.faceted_search--show-mobile-filter .search-filter-wrapper {
  display: block !important;
  position: fixed;
  z-index: 1;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  width: 289px;
  margin-top: 0;
}

.section-separator {
  border-bottom: 1px solid $blue-gray-100;
}

.top-section--open-more-options {
  .search-actions {
    display: flex !important;
    justify-content: flex-end;
  }

  &/deep/ {
    .more-options svg {
      transform: rotate(180deg);
    }
  }
}

.main-column {
  margin-left: -15px;
}

.more-options {
  .content_logo_text {
    display: inline-flex;
    margin-left: 0.25rem;
  }
}

@media (min-width: 1026px) {
  .more-options {
    display: none;
  }

  .top-section {
    flex-direction: row;
  }

  .search-actions {
    display: flex !important;
    margin-top: 0;
  }

  .search-filter-wrapper {
    display: block;
  }
}

@media (min-width: 540px) {
  .top-section {
    padding-top: $gap-lg;
    padding-bottom: $gap-lg;
  }
}

@media (min-width: 1200px) {
  .container-wide {
    max-width: 1200px !important;
    margin: 0 auto;
  }

  .top-section {
    max-width: 1200px !important;
    margin: 0 auto;
  }

  .filters-btn-wrapper {
    display: none !important;
  }

  .search-filter-wrapper,
  .faceted_search--show-mobile-filter .search-filter-wrapper {
    box-shadow: none;
    position: relative;
    display: block !important;
    width: initial;
    top: initial;
    right: initial;
    bottom: initial;
  }

  .search-filter-wrapper {
    margin-top: $gap-lg !important;
  }
}
</style>
