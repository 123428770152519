<template>
  <div class="icrs-section-title">
    <h3>{{ value }}</h3>
  </div>
</template>
<script>
export default {
  name: 'IcrsSectionTitle',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.icrs-section-title {
  margin: 2rem;
  h3 {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    &::after {
      display: inline-block;
      content: "";
      background-color: var(--grey-light);
      height: 4px;
      width: 100%;
      margin-left: 1rem;
      margin-top: calc(0.5rem + 4px);
    }
  }
}
</style>
