<template>
  <div>
    <OverviewTemplate :overview-routes="componentRoutes">
      <div slot="topic">Component</div>
      <ComponentMarkdown slot="markdown"/>
    </OverviewTemplate>
  </div>
</template>

<script>

import ComponentMarkdown from './ComponentWidgetOverview.md';
import { componentRoutes } from '../RouteSettings';
import OverviewTemplate from '../components/OverviewTemplate';

export default {
  data () {
    return {
      selectedItemPath: '',
      componentRoutes,
    };
  },
  components: {
    ComponentMarkdown,
    OverviewTemplate,
  },
};
</script>
