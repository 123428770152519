import PageManagementService from '@/services/page-management.service';

export default {
  execGetBottomNavList ({ commit }) {
    commit('setIsLoadingList', true);
    return PageManagementService.getTableItemsList('Navigation').then(({ data }) => {
      commit('setBottomNavList', data);
    }).catch((error) => {
      if (error) {
        commit('setBottomNavList', []);
      }
    }).finally(() => {
      commit('setIsLoadingList', false);
    });
  },
  setBottomNavList ({ commit }, bottomNavList) {
    commit('setBottomNavList', bottomNavList);
  },

  execGetFooterList ({ commit }) {
    commit('setIsLoadingList', true);
    return PageManagementService.getTableItemsList('Footer').then(({ data }) => {
      commit('setFooterList', data);
    }).catch((error) => {
      if (error) {
        commit('setFooterList', []);
      }
    }).finally(() => {
      commit('setIsLoadingList', false);
    });
  },

  setFooterList ({ commit }, footerList) {
    commit('setFooterList', footerList);
  },

  execSortNavItems ({ commit, state }, { checkPage, idPage1, idPage2 }) {
    return PageManagementService.changeOrder({ checkPage, idPage1, idPage2 }).then((res) => {
      commit('setBottomNavList', res.data);
    }).catch((error) => {
      if (error) {
        commit('setBottomNavList', state.pagemanagement.bottomNavList || []);
      }
    });
  },

  execSortFooterItems ({ commit, state }, { checkPage, idPage1, idPage2 }) {
    return PageManagementService.changeOrder({ checkPage, idPage1, idPage2 }).then((res) => {
      commit('setFooterList', res.data);
    }).catch((error) => {
      if (error) {
        commit('setFooterList', state.pagemanagement.footerList || []);
      }
    });
  },

  setNewPageCurrent ({ commit }, newPageCurrent) {
    commit('setNewPageCurrent', newPageCurrent);
  },

  setCheckPage ({ commit }, checkPage) {
    commit('setCheckPage', checkPage);
  },

  setPreviewData ({ commit }, previewData) {
    commit('setPreviewData', previewData);
  },

  setUpdating ({ commit }, updating) {
    commit('setUpdating', updating);
  },

  setIsLoadingList ({ commit }, isLoadingList) {
    commit('setIsLoadingList', isLoadingList);
  },
};
