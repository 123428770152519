<template>
  <div id="advanced-search-content">
    <div class="d-flex flex-column align-items-center">
      <h1 class="m-3 text-center">Search by knowledge graph</h1>
      <div class="d-flex flex-row justify-content-center">
        <AdvancedSearchBar />

        <div class='text-primary ml-2'>
          <AdvancedSearchProTips :buttonPosition="'position-absolute'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdvancedSearchBar from './AdvancedSearchBar';
import AdvancedSearchProTips from './AdvancedSearchProTips';

export default {
  components: {
    AdvancedSearchBar,
    AdvancedSearchProTips,
  },
};
</script>
<style lang="scss" scoped>
#advanced-search-content {
  h1 {
    font-size: $font-lg;
  }
}
</style>
