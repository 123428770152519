<template>
  <div class="w-100">
    <div v-if="!this.currentSearch.ontologySearch && !this.currentSearch.filterBasedSearch">
      <AdvancedSearchContent />
    </div>
    <div
      class="ontology-search-container"
      v-else-if="this.currentSearch.ontologySearch && !this.currentSearch.filterBasedSearch"
    >
      <OntologySearch v-on="{ ...$listeners, 'on-click-search': triggerSearchQuery }" />
    </div>
    <div
      v-if="this.currentSearch.filterBasedSearch"
      class="w-100"
    >
      <FilterBasedSearch
        v-if="currentSearch.filterBasedSearch"
        @on-reset="closeAdvancedSearchModal"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import OntologySearch from '../ontology-search/OntologySearch';
import AdvancedSearchContent from './components/AdvancedSearchContent';
import FilterBasedSearch from '../filter-based-search/FilterBasedSearch';

export default {
  name: 'AdvancedSearch',
  components: {
    AdvancedSearchContent,
    OntologySearch,
    FilterBasedSearch,
  },
  computed: {
    ...mapGetters('previewViewer', [
      'previewViewerModalOpen',
    ]),
    ...mapGetters('advancedSearch', [
      'currentSearch',
      'selectedFilterGroup',
    ]),
  },
  methods: {
    ...mapActions('advancedSearch', [
      'updateCurrentSearch',
      'handleSearchEvent',
    ]),
    closeAdvancedSearchModal () {
      this.$emit('on-reset');
    },
    triggerSearchQuery (clickedIndividual) {
      this.handleSearchEvent({ inputIndividual: clickedIndividual });
      this.closeAdvancedSearchModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.ontology-search-container {
  height: 100%;
}
</style>
