import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import defaultState from './state';

const previewViewer = {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions,
};

export default previewViewer;
