import axios from 'axios';
import authInterceptor from './auth.interceptor';
import csrfInterceptor from './csrf.interceptor';
import createErrorResponseInterceptor from './errorhandler.interceptor';
import userObjectInterceptor from './userobject.interceptor';
import configService from '../config.service';
import platformRequestInterceptor from './platform-request.interceptor';
import createPlatformResponseInterceptor from './platform-response.interceptor';
import createICRSErrorResponseInterceptor from './icrs-login-error-handler.interceptor';

function createHttpClient ({ contentType, baseUrl } = {}) {
  const httpClient = axios.create({
    baseURL: _.isUndefined(baseUrl) ? configService.getBaseUrl() : baseUrl, // With Tomcat
    withCredentials: configService.getEnableAuth(),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': _.isUndefined(contentType) ? 'application/json' : contentType,
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
      RETRY_ATTEMPTS: 0,
    },
  });

  return httpClient;
}

export default (type) => {
  const httpClient = createHttpClient(type);
  if (configService.getKPOIntegrationEnabled()) {
    httpClient.interceptors.request.use(authInterceptor, Promise.reject);
    httpClient.interceptors.request.use(platformRequestInterceptor, Promise.reject);
    httpClient.interceptors.response.use(userObjectInterceptor);
    createPlatformResponseInterceptor(httpClient);
  } else {
    /** Adding the request and response interceptors */
    httpClient.interceptors.request.use(authInterceptor);
    httpClient.interceptors.response.use(csrfInterceptor);
    httpClient.interceptors.response.use(userObjectInterceptor);

    if (configService.getSAMLAuthEnabled() || configService.getSALAuthEnabled()) {
      createICRSErrorResponseInterceptor(httpClient);
    } else {
      createErrorResponseInterceptor(httpClient);
    }
  }
  return httpClient;
};
