<template>
  <b-card-body class="fixed_height" body-bg-variant="blue-gray-100_alpha">
    <b-card-title>Information Extraction</b-card-title>

    <b-list-group>
      <b-card-text>Information crawled from connectors will be intelligently tagged with deep learning technology.
      </b-card-text>
      <b-card-text id="iconTextGroup">
        <svg-text class="svg_fill" text="NLP">
          <template v-slot:icon>
            <robo-talk-svg/>
          </template>
        </svg-text>
        <svg-text text="Machine Learning" >
          <template v-slot:icon>
            <ai-brain-svg/>
          </template>
        </svg-text>
      </b-card-text>
      <b-card-text>Demo for <strong>Automatic Entity Recognition</strong>.</b-card-text>

      <redirect-button label="Automatic Entity Recognition" pathLink="/ner" :variant="variant"></redirect-button>
    </b-list-group>
    <right-arrow-svg class="floating_arrow"/>
  </b-card-body>
</template>

<script>
import roboTalkSvg from '@/assets/admin-page/icons/robo-talk.svg';
import aiBrainSvg from '@/assets/admin-page/icons/ai-brain.svg';
import rightArrowSvg from '@/assets/admin-page/icons/circle-right-arrow.svg';
import SvgText from './SvgText.vue';
import redirectButton from './RedirectionButton.vue';

export default {
  components: {
    redirectButton,
    SvgText,
    aiBrainSvg,
    roboTalkSvg,
    rightArrowSvg,
  },
  props: {
    variant: String,
  },
};
</script>

<style scoped lang="scss">
#iconTextGroup {
  margin-left: auto;
  margin-right: auto;
}
</style>
