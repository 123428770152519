<template>
  <div
    id="ontology-search-table-filters"
    class="ontology-search-table-filters"
    :class="{ 'ontology-search-table-filters--open': !collapsed }"
    :key="refreshKey"
  >
    <div class="ontology-search-table-filters__header">
      <span>Filters</span>
      <button
        @click="toggleOpen"
        class="ontology-search-table-filters__toggle-button"
      >{{ !collapsed ? 'Hide' : 'Show' }}</button>
    </div>
    <div
      v-show="!collapsed"
      class="ontology-search-table-filters__body"
      :class="{ 'ontology-search-table-filters__body--no-item': !classFilters.length }"
      role="tablist"
    >
      <div
        v-if="classFilters.length"
        class="ontology-search-table-filters__filter-checklist"
      >
        <FilterItem
          v-for="item in classFilters"
          :key="item.key"
          :item="item"
          @change="$emit('class-filter', $event)"
          :data-cy="`filter-checkbox-${item.key}`"
        />
      </div>
      <div
        v-else
        class="text-center"
      >
        No available filters
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterItem from '@/modules/search-filter/FilterItem.vue';

export default {
  name: 'OntologySearchTableFilters',
  data () {
    return {
      collapsed: false,
      refreshKey: 0,
    };
  },
  components: {
    FilterItem,
  },
  computed: {
    ...mapGetters('advancedSearch/ontologySearch', [
      'classFilters',
    ]),
  },
  methods: {
    toggleOpen () {
      this.collapsed = !this.collapsed;
    },
  },
  watch: {
    classFilters () {
      this.refreshKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.ontology-search-table-filters {
  color: $blue-gray-500;
  font-weight: $weight-semi-bold;
  background: $white-500;

  &__header {
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    background: $blue-200;
    padding: $gap-xs $gap-md;
    border-radius: $radius-4;
  }

  &--open {
    .search-filter__header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__toggle-button {
    border: 0;
    background: transparent;
    font-weight: $weight-semi-bold;
  }

  &__body {
    position: relative;
    background: $white-500;
    padding: $gap-md;
    min-height: 240px;

    &--no-item {
      min-height: initial;
    }
  }

  &__filter-checklist {
    &/deep/ .filter-item {
      margin-bottom: $gap-xxs;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
