<template>
  <div class="promoted-content-container">
    <span class="tab-title">Promoted content</span>
    <span
      id="priority-tooltip"
      class="m-tooltip"
    >
      <font-awesome-icon
        class="question-icon text-secondary align-self-center"
        style="margin-bottom: 0.5rem;"
        :icon="['fa', 'question-circle']"
        size="sm"
      />
    </span>
    <b-tooltip
      target="priority-tooltip"
      triggers="hover"
    >
      Promoted document will be displayed on the top of the search results
    </b-tooltip>
    <b-form @submit="onSubmit">
      <div class="mb-4">
        <div class="d-flex align-item-center">
          <label
            for="promoted-content-radio"
            class="label"
          >Do you want to make it as a promoted content?</label>
        </div>
        <div class="d-flex">
          <b-form-radio
            :disabled="!haveWriteAccess"
            v-model="modelPromote"
            v-bind:value="true"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            :disabled="!haveWriteAccess"
            v-model="modelPromote"
            v-bind:value="false"
            class="ml-3"
          >
            No
          </b-form-radio>
        </div>
      </div>
      <div class="mb-4">
        <div class="d-flex align-item-center justify-content-between">
          <label
            for="promoted-content-priority"
            class="label"
          >Priority</label>
        </div>
        <validation-provider
          rules="doc-integer"
          v-slot="{ errors }"
        >
          <b-form-input
            type="number"
            min="0"
            pattern="[0-9]"
            :disabled="disableFormInput"
            id="promoted-content-priority"
            v-model="modelPriority"
            v-bind:class="errors[0] ? 'is-invalid remove-warning' : ''"
            placeholder="Enter priority level"
          />
          <span class="form-error">{{ errors[0] }}</span>
        </validation-provider>
        <span class="empty-table-text">Content with priority 1 will appear in the top of the search results</span>
      </div>
      <div
        class="mb-4"
        v-if="modelPromote"
      >
        <div class="d-flex align-item-center justify-content-between">
          <b-form-checkbox
            id="is-set-promoted-duration"
            v-model="modelShowsDatePicker"
            :disabled="!haveWriteAccess"
          >
            <label
              for="is-set-promoted-duration"
              class="label"
            >Set promotion duration</label>
          </b-form-checkbox>
        </div>
      </div>
      <div
        class="mb-6"
        v-show="modelPromote && modelShowsDatePicker"
      >
        <div class="input-group">
          <flat-pickr
            id="promoted-content-period"
            ref="datepicker"
            :config="configs"
            placeholder="Select promotion date range"
            class="flat-pickr-input"
            v-model="defaultDateRange"
            @on-change="dateRangeChange"
          >
          </flat-pickr>
          <div class="input-group-append">
            <button
              class="btn btn-default"
              type="button"
              title="Toggle"
              data-toggle
            >
              <i class="fa fa-calendar">
                <span
                  aria-hidden="true"
                  class="sr-only"
                >Toggle</span>
              </i>
            </button>
          </div>
        </div>
        <div
          v-if="showPromotionStatus"
          class="promotion-status"
        >
          <span
            class="mr-xxs promotion-status__icon"
            :class="promotionStatus.class"
          >&#8226;</span>
          <span class="promotion-status__text">{{ promotionStatus.text }}</span>
        </div>
      </div>
    </b-form>
    <div
      v-if="modelIsEdited"
      class="d-flex justify-content-end"
    >
      <b-btn
        @click="handleCancel"
        class="confirm-btn mr-2 pl-4 pr-4"
        variant="link"
      >
        Cancel
      </b-btn>
      <b-btn
        :disabled="disableSave"
        @click="handleSave"
        class="confirm-btn ml-2 pl-4 pr-4"
        variant="primary"
      >
        Save
      </b-btn>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { ValidationProvider } from 'vee-validate';
import moment from 'moment';
import {
  getStartOfDay, getEndOfDay, formatDate, getPromotionStatus,
  getMinDate, getMaxDate, isPermanentPromote, getDefaultDateRange,
} from '@/helpers/promoted-content-helpers';

export default {
  props: {
    value: {},
    disableForm: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      defaut: false,
    },
    haveWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    const {
      priority = 0, metadata = {},
    } = this.value || {};

    const isPromotePeriodSet = metadata.promote_to !== undefined;

    const {
      promote = false,
      promote_from = promote_from || moment(),
      promote_to = promote_to || promote_from,
    } = metadata || {};

    return {
      isEdited: false,
      formValue: {
        priority,
        metadata: {
          promote_from, promote_to, promote,
        },
      },
      showsDatePicker: isPromotePeriodSet && !isPermanentPromote(promote_from, promote_to),
      defaultDateRange: getDefaultDateRange(promote_from, promote_to),
      configs: {
        mode: 'range',
        wrap: true,
        dateFormat: 'j M Y',
      },
      isDateEditingInProgress: false,
      minDate: getMinDate(),
      maxDate: getMaxDate(),
    };
  },
  components: {
    ValidationProvider,
  },
  mounted () {
    this.$refs.datepicker.fp.config.ignoredFocusElements = [window.document.body];
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault();
    },
    handleCancel () {
      const {
        priority = 0, metadata = {},
      } = this.value || {};
      const {
        promote = false, promote_from = promote_from ? this.formatDate(moment(promote_from))
          : formatDate(moment()), promote_to = promote_to ? formatDate(promote_to) : undefined,
      } = metadata || {};
      this.showsDatePicker = promote_to !== undefined;
      this.formValue = {
        priority,
        metadata: {
          promote_from, promote_to, promote,
        },
      };
      this.setIsEdited(false);
    },
    setIsEdited (value) {
      this.isEdited = value;
    },
    handleSave () {
      let { formValue } = this || {};
      const { priority = 0 } = formValue;
      // eslint-disable-next-line prefer-const
      let { metadata: { promote, promote_to, promote_from } } = formValue;
      // check that if showsDatePicker is false, then set promote_from, promote_to == undefined
      if (!this.showsDatePicker) {
        promote_from = this.minDate;
        promote_to = this.maxDate;
      } else {
        promote_from = promote_from ? getStartOfDay(moment.utc(promote_from)).toISOString() : undefined;
        promote_to = promote_to ? getEndOfDay(moment.utc(promote_to)).toISOString()
          : getEndOfDay(moment.utc(promote_from)).toISOString();
      }
      formValue = {
        priority,
        metadata: {
          promote, promote_from, promote_to,
        },
      };
      this.isDateEditingInProgress = false;
      this.$emit('save', formValue, this.setIsEdited);
    },
    // formats Date() object into a string in the format 'j M Y', to correspond to the picker
    formatDate (dateObj) {
      return dateObj.format('DD MMM YYYY');
    },
    dateRangeChange (selectedDates, dateStr, instance) {
      const from = formatDate(selectedDates[0]);
      const to = formatDate(selectedDates[1]);
      this.formValue.metadata.promote_from = from;
      this.formValue.metadata.promote_to = to;
      this.isDateEditingInProgress = true;
      if (selectedDates.length === 2) {
        this.setIsEdited(true);
      } else {
        this.setIsEdited(false);
      }
    },
  },
  computed: {
    disableSave () {
      if (this.modelPriority !== 0 && !this.modelPriority) return true;
      const reg = new RegExp('^-\\d+$');
      if (reg.test(`${this.modelPriority}`)) return true;
      if (this.showsDatePicker) {
        return (!this.formValue.metadata.promote_from && !this.formValue.metadata.promote_to);
      }
      return false;
    },
    modelIsEdited () {
      return this.isEdited;
    },
    modelShowsDatePicker: {
      get () {
        return this.showsDatePicker;
      },
      set (value) {
        if (value !== this.showsDatePicker) {
          this.setIsEdited(true);
          this.showsDatePicker = value;
        }
      },
    },
    modelPromote: {
      get () {
        return this.formValue.metadata.promote;
      },
      set (value) {
        if (value !== this.formValue.metadata.promote) {
          this.setIsEdited(true);
          this.formValue.metadata.promote = value;
        }
      },
    },
    modelPriority: {
      get () {
        return Number(this.formValue.priority);
      },
      set (value) {
        this.setIsEdited(true);
        this.formValue.priority = Number(value);
      },
    },
    promotionStatus () {
      return getPromotionStatus(this.value.metadata.promote_from, this.value.metadata.promote_to);
    },
    showPromotionStatus () {
      return this.value.metadata.promote && !this.isDateEditingInProgress && !this.isEdited;
    },
    disableFormInput () {
      return !this.haveWriteAccess || !this.modelPromote;
    },
  },
};
</script>

<style  lang="scss" scoped>
#doc-manager {
  .promoted-content-container {
    padding: 20px 20px 20px 0px;
    min-width: 500px;
    form {
      max-width: 50%;
      margin-top: 15px;
    }
    .custom-control-label {
      font-size: 12px;
      padding-top: 5px;
    }
    .is-invalid.remove-warning {
      background-image: inherit;
    }
  }

  .input-group {
    border: 1px solid $blue-gray-100;
    border-radius: 5px;
    justify-content: space-between;
    width: 60%;
    display: inline-flex;
    /deep/ input {
      border-style: none;
      border-radius: 5px;
      margin: 0 5px;
      width: 70%;
    }
  }

  .is-invalid {
    border-color: $red-500;
  }
}

.promotion-status {
  margin-left: $gap-s;
  display: inline-flex;

  &__icon {
    margin-top: -5px;
    font-size: 20px;
  }

  &__text {
    font-size: $font-md;
    color: $blue-gray-400;
  }
}

.font-warning {
  color: $yellow-500;
}
</style>
