<template>
  <b-badge
    v-bind="$props"
    :variant="variant"
    :href="fixLink(href)"
    :rel="rel"
    :target="target"
    :active="active"
    :disabled="disabled"
    :to="to"
    :append="append"
    :replace="replace"
    :event="event"
    :active-class="activeClass"
    :exact="exact"
    :exact-active-class="exactActiveClass"
    :router-tag="routerTag"
    :no-prefetch="noPrefetch"
    :tag="tag"
    :pill="pill"
  >
    <!-- @slot Use this as default slot -->
    <slot></slot>
  </b-badge>
</template>

<script>
import { BBadge } from 'bootstrap-vue';
import { sanitizeUrl } from '@braintree/sanitize-url';
export default {
  name: 'Badge',
  status: 'release',
  components: {
    BBadge,
  },
  props: {
    /**
     * The value to set icon name. It uses fontawesome icon
     */
    icon: {
      type: String,
    },
    /**
     * Applies one of the Bootstrap theme color variants to the component
     */
    variant: {
      type: String,
      default: 'secondary',
    },
    /**
     * Sets the 'rel' attribute on the rendered link
     */
    rel: {
      type: String,
    },
    /**
     * Sets the 'target' attribute on the rendered link
     */
    target: {
      type: String,
      default: '_self',
    },
    /**
     * router-link prop: Configure the active CSS class applied when the link is active. Typically you will want to set this to class name 'active'
     */
    activeClass: {
      type: String,
    },
    /**
     * router-link prop: Configure the active CSS class applied when the link is active with exact match. Typically you will want to set this to class name 'active'
     */
    exactActiveClass: {
      type: String,
    },
    /**
     * Specify the HTML tag to render instead of the default tag
     */
    tag: {
      type: String,
      default: 'span',
    },
    /**
     * router-link prop: Specify which tag to render, and it will still listen to click events for navigation. 'router-tag' translates to the tag prop on the final rendered router-link. Typically you should use the default value
     */
    routerTag: {
      type: String,
      default: 'a',
    },
    /**
     * nuxt-link prop: To improve the responsiveness of your Nuxt.js applications, when the link will be displayed within the viewport, Nuxt.js will automatically prefetch the code splitted page. Setting 'no-prefetch' will disabled this feature for the specific link
     */
    noPrefetch: {
      type: Boolean,
      default: false,
    },
    /**
     * When set to 'true', places the component in the active state with active styling
     */
    active: {
      type: Boolean,
      default: false,
    },
    /**
     * router-link prop: The default active class matching behavior is inclusive match. Setting this prop forces the mode to exactly match the route
     */
    exact: {
      type: Boolean,
      default: false,
    },
    /**
     * router-link prop: Setting append prop always appends the relative path to the current path
     */
    append: {
      type: Boolean,
      default: false,
    },
    /**
     * router-link prop: Setting the replace prop will call 'router.replace()' instead of 'router.push()' when clicked, so the navigation will not leave a history record
     */
    replace: {
      type: Boolean,
      default: false,
    },
    /**
     * When set to 'true', disables the component's functionality and places it in a disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Renders the badge with the pill style appearance when set to 'true'
     */
    pill: {
      type: Boolean,
      default: false,
    },

    /**
     * router-link prop: Denotes the target route of the link. When clicked, the value of the to prop will be passed to router.push() internally, so
     * the value can be either a string or a Location descriptor object
     */
    to: {
      type: [String, Object],
    },
    /**
     * router-link prop: Specify the event that triggers the link. In most cases you should leave this as the default
     */
    event: {
      type: [String, Array],
      default: 'click',
    },
    /**
     * Denotes the target URL of the link for standard a links
     */
    href: {
      type: String,
    },
  },
  computed: {},
  methods: {
    fixLink(url) {
      return sanitizeUrl(url);
    }
  },
};
</script>

<style lang="scss" scoped>
.badge {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  font-size: $font-sm;
  font-weight: $weight-normal;
  line-height: $ln-height-sm;
  padding: $gap-xxs $gap-sm;
  &.badge-warning {
    color: $white-500;
  }
  &.badge-plain {
    color: $blue-gray-400;
    background: $blue-200;
  }
  &.badge-outline {
    color: $blue-gray-400;
    background: $white-500;
    border: 1px solid $blue-gray-100;
  }
}
</style>

<docs>
  ```jsx
  <div>
    <div>Straight Badges</div>
    <div>
      <Badge variant="plain">Plain</Badge>
      <Badge variant="primary">Badge</Badge>
      <Badge variant="success">Success</Badge>
      <Badge variant="danger">Danger</Badge>
      <Badge variant="warning">Warning</Badge>
      <Badge variant="outline">Outline</Badge>
    </div>
    <div class="mt-md">Rounded Badges</div>
    <div>
      <Badge pill variant="plain">Plain</Badge>
      <Badge pill variant="primary">Badge</Badge>
      <Badge pill variant="success">Success</Badge>
      <Badge pill variant="danger">Danger</Badge>
      <Badge pill variant="warning">Warning</Badge>
      <Badge pill variant="outline">Outline</Badge>
    </div>

  </div>
  ```
</docs>
