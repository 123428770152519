<template>
  <b-card-group
    class="mobile-section hide-for-printing"
    deck
  >
    <b-card
      :bg-variant="activeVariant"
      :border-variant="activeVariant"
      class="text-center"
      style="font-weight:400 !important"
      @click="$emit('set-active-doc-id', 1)"
    >
      <span
        v-if="!showGlobal"
        class="mobile-indicators-large"
      >
        {{ formatDisplayAmount(keyIndicators.excludeGlobalAwardAmountLowP) }}
      </span>
      <span
        v-if="showGlobal"
        class="mobile-indicators-large"
      >
        {{ formatDisplayAmount(keyIndicators.includeGlobalAwardAmountLowP) }}
      </span>
      <br />
      <span>
        {{ keyIndicators.labels[1] }}
      </span>
    </b-card>
    <b-card
      :bg-variant="activeVariant"
      :border-variant="activeVariant"
      class="text-center"
      style="font-weight:600 !important"
      @click="$emit('set-active-doc-id', 2)"
    >
      <span
        v-if="!showGlobal"
        class="mobile-indicators-large"
      >
        {{ formatDisplayAmount(keyIndicators.excludeGlobalAwardAmountLowInter) }}
      </span>
      <span
        v-if="showGlobal"
        class="mobile-indicators-large"
      >
        {{ formatDisplayAmount(keyIndicators.includeGlobalAwardAmountLowInter) }}
      </span>
      <br />
      <span>
        {{ keyIndicators.labels[2] }}
      </span>
    </b-card>
    <b-card
      :bg-variant="activeVariant"
      :border-variant="activeVariant"
      class="text-center"
      style="font-weight:900 !important"
      @click="$emit('set-active-doc-id', 3)"
    >
      <span
        v-if="!showGlobal"
        class="mobile-indicators-large"
      >
        {{ formatDisplayAmount(keyIndicators.excludeGlobalAwardAmountMedian) }}
      </span>
      <span
        v-if="showGlobal"
        class="mobile-indicators-large"
      >
        {{ formatDisplayAmount(keyIndicators.includeGlobalAwardAmountMedian) }}
      </span>
      <br />
      <span>
        {{ keyIndicators.labels[3] }}
      </span>
    </b-card>
    <b-card
      :bg-variant="activeVariant"
      :border-variant="activeVariant"
      class="text-center"
      style="font-weight:600 !important"
      @click="$emit('set-active-doc-id', 4)"
    >
      <span
        v-if="!showGlobal"
        class="mobile-indicators-large"
      >
        {{ formatDisplayAmount(keyIndicators.excludeGlobalAwardAmountHighInter) }}
      </span>
      <span
        v-if="showGlobal"
        class="mobile-indicators-large"
      >
        {{ formatDisplayAmount(keyIndicators.includeGlobalAwardAmountHighInter) }}
      </span>
      <br />
      <span>
        {{ keyIndicators.labels[4] }}
      </span>
    </b-card>
    <b-card
      :bg-variant="activeVariant"
      :border-variant="activeVariant"
      class="text-center"
      style="font-weight:400 !important"
      @click="$emit('set-active-doc-id', 5)"
    >
      <span
        v-if="!showGlobal"
        class="mobile-indicators-large"
      >
        {{ formatDisplayAmount(keyIndicators.excludeGlobalAwardAmountHighP) }}
      </span>
      <span
        v-if="showGlobal"
        class="mobile-indicators-large"
      >
        {{ formatDisplayAmount(keyIndicators.includeGlobalAwardAmountHighP) }}
      </span>
      <br />
      <span>
        {{ keyIndicators.labels[5] }}
      </span>
    </b-card>
  </b-card-group>
</template>
<script>
export default {
  name: 'ICRSMobileIndicators',
  props: {
    showGlobal: {
      type: Boolean,
      default: false,
    },
    keyIndicators: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      activeVariant: 'exclude-global',
    };
  },
  watch: {
    showGlobal: {
      immediate: true,
      handler (value) {
        if (value) {
          this.activeVariant = 'include-global';
        } else {
          this.activeVariant = 'exclude-global';
        }
      },
    },
  },
  methods: {
    formatDisplayAmount (amount) {
      return Number.parseFloat(amount).toLocaleString(
        'en-US',
        { style: 'currency', currency: 'USD' },
      );
    },
  },
};
</script>
