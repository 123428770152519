<template>
  <div class="center">
    <unauthorizedIcon />

    <p class="title">Error 401 - unauthorized</p>
    <p class="description">You don't have permission to view this page using the credentials provided.</p>
    <b-button
      variant="link"
      href="/"
    >Refresh page</b-button>
  </div>

</template>
<script>
import unauthorizedIcon from '@/assets/unauthorized.svg';

export default {
  name: 'UnauthorizedPage',
  components: {
    unauthorizedIcon,
  },
};
</script>
<style lang="scss" scoped>
.center {
  width: 30%;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
  flex-direction: column;
}

.title {
  font-size: $font-xl;
  margin: 20px 0;
}

.description {
  color: $blue-gray-400;
  margin-bottom: 20px;
}

/deep/ .btn-link {
  color: $blue-500 !important;
}
</style>
