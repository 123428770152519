import * as helpers from '../helpers';

test('camelize', () => {
  expect(helpers.camelize('awesome-component')).toBe('awesomeComponent');
  expect(helpers.camelize('super-awesome-component')).toBe('superAwesomeComponent');
});

test('toPascal', () => {
  expect(helpers.toPascal('awesomeComponent')).toBe('AwesomeComponent');
  expect(helpers.toPascal('awesome-component')).toBe('AwesomeComponent');
  expect(helpers.toPascal('super-awesome-component')).toBe('SuperAwesomeComponent');
});

test('scrollView', () => {
  const elmMock = { scrollIntoView: jest.fn() };
  const blockMock = {};

  helpers.scrollView(elmMock);

  expect(elmMock.scrollIntoView).toHaveBeenLastCalledWith({
    behavior: 'auto',
    block: 'start',
    inline: 'nearest',
  });

  helpers.scrollView(elmMock, blockMock);

  expect(elmMock.scrollIntoView).toHaveBeenLastCalledWith({
    behavior: 'auto',
    block: blockMock,
    inline: 'nearest',
  });
});
