import Api from './api/api.service';
import ConfigService from './config.service';

const SEARCH_CONTEXT = ConfigService.getSearchSearchContext();
const ADMIN_CONTEXT = ConfigService.getSearchAdminContext();

export default {
  getBlacklistedFields () {
    return Api().get(`${ADMIN_CONTEXT}/api/blacklist`).then(({ data } = {}) => data);
  },
  getAllClasses () {
    return Api().get(`${SEARCH_CONTEXT}/tags/class/all`).then(({ data } = {}) => data);
  },
  getIndividualClass (parameters) {
    return Api().post(`${SEARCH_CONTEXT}/tags/individual/all`, parameters).then(({ data } = {}) => data);
  },
  execSearchDoc (parameters) {
    return Api().post(`${SEARCH_CONTEXT}/getDocument`, parameters).then(({ data } = {}) => data);
  },
  execUpdateDoc (parameters, id) {
    return Api().put(`${ADMIN_CONTEXT}/api/document/${id}`, parameters).then(({ data } = {}) => data);
  },
  execDeleteDoc (id) {
    return Api().delete(`${ADMIN_CONTEXT}/api/document/${id}`).then(({ data = {} }) => data);
  },
};
