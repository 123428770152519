<template>
  <div class="t-simple-pagination">
    <span class="pagination-info">{{ fromItemNumber }} - {{ toItemNumber }} of {{ totalItems }}</span>
    <button
      class="nav-button button-back"
      @click="goToPreviousPage"
      :disabled="!hasPreviousPage"
    >
      <font-awesome-icon :icon="['fas', 'chevron-left']" />
    </button>
    <button
      class="nav-button button-next"
      @click="goToNextPage"
      :disabled="!hasNextPage"
    >
      <font-awesome-icon :icon="['fas', 'chevron-right']" />
    </button>
  </div>
</template>

<script>
/**
 * Simple Pagination component.
 */
export default {
  name: 'SimplePagination',
  status: 'release',
  release: '1.0.3',
  props: {
    /**
     * The total amount of items.
     */
    totalItems: {
      type: Number,
      required: true,
    },
    /**
     * A zero-based number to set the page. Be aware you need to update the page property
     * by the result of the page-change action!
     */
    page: {
      type: Number,
      required: true,
    },
    /**
     * The maximum amount of items on one page.
     */
    pageSize: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {};
  },
  computed: {
    fromItemNumber () {
      return this.page * this.pageSize + 1;
    },
    toItemNumber () {
      // Add in Math.min to handle toItemNumber of last page of items
      return Math.min(this.fromItemNumber + this.pageSize - 1, this.totalItems);
    },
    hasPreviousPage () {
      return this.currentPage !== 1;
    },
    hasNextPage () {
      return this.fromItemNumber + this.pageSize <= this.totalItems;
    },
    currentPage () {
      return this.page + 1;
    },
  },
  methods: {
    goToPreviousPage () {
      /**
       * Emitted if previous or next page button is clicked.
       *
       * @event page-change
       * @property {number} page - The zero-based current page.
       * @type function
       */
      this.$emit('page-change', this.page - 1);
    },
    goToNextPage () {
      /**
       * Emitted if previous or next page button is clicked.
       *
       * @event page-change
       * @property {number} page - The zero-based current page.
       * @type function
       */
      this.$emit('page-change', this.page + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin text-sm-12 {
  font-size: $font-sm;
  font-weight: $weight-normal;
  line-height: $ln-height-xs;
  color: $blue-gray-400;
}
.t-simple-pagination {
  @include text-sm-12;
  display: flex;
  margin: 0 20px;
  align-items: center;
  justify-content: flex-start;

  .pagination-info {
    margin-right: 12px;
  }

  .nav-button {
    background: $blue-200;
    height: 24px;
    width: 24px;
    position: relative;
    border-radius: 50%;
    font-size: 11px;
    border: 0;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;
    transform: translate(-50%, -50%);
  }

  .button-back,
  .button-next {
    line-height: 0;
    background-color: $white-500;
    &:disabled {
      color: $blue-gray-200;
    }
    color: $blue-500;
    &:hover:not(:disabled) {
      background-color: $blue-500;
      color: $white-500;
    }
  }

  .button-back {
    margin-right: 4px;
  }

  .button-next {
    margin-left: 4px;
  }
}
</style>

<docs>
  ```jsx
  <div>
    <div class="mt-lg">
      <simple-pagination
        v-bind:total-items="200"
        v-bind:page-size="5"
        v-bind:page="0">
      </simple-pagination>
    </div>
  </div>
  ```
</docs>
