import createState from '../state';
import getters from '../getters';

describe('advanced-search/ontology-search vuex getters test', () => {
  test('relations', () => {
    let state = createState();
    expect(getters.relations(state)).toBe('');

    state = createState({ relations: 'cityOfCountry' });
    expect(getters.relations(state)).toBe('cityOfCountry');
  });

  test('relationsMin', () => {
    let state = createState();
    expect(getters.relationsMin(state)).toBe(0);

    state = createState({ relationsMin: 1 });
    expect(getters.relationsMin(state)).toBe(1);
  });

  test('relationsMax', () => {
    let state = createState();
    expect(getters.relationsMax(state)).toBe(20);

    state = createState({ relationsMax: 21 });
    expect(getters.relationsMax(state)).toBe(21);
  });

  test('maxEdges', () => {
    let state = createState();
    expect(getters.maxEdges(state)).toBe(20);

    state = createState({ maxEdges: 21 });
    expect(getters.maxEdges(state)).toBe(21);
  });

  test('nodes', () => {
    let state = createState();
    expect(getters.nodes(state)).toEqual([]);

    const nodes = [{
      color: '#583604',
      font: '#583604',
      id: 'city_singapore',
      label: 'Singapore',
      type: 'http://authoring.roots.sg/en/learn/collections/listing/city',
    }];
    state = createState({ nodes });
    expect(getters.nodes(state)).toEqual(nodes);
  });

  test('edges', () => {
    let state = createState();
    expect(getters.edges(state)).toEqual([]);

    const edges = [{
      arrows: 'to',
      color: '#583604',
      from: 'city_singapore',
      id: 'cityOfCountry-country_singapore',
      length: 180,
      title: 'cityOfCountry',
      to: 'country_singapore',
    }];
    state = createState({ edges });
    expect(getters.edges(state)).toEqual(edges);
  });

  test('displayedNodes', () => {
    let state = createState();
    expect(getters.displayedNodes(state)).toEqual([]);

    const displayedNodes = [{
      color: '#583604',
      font: '#583604',
      id: 'city_singapore',
      label: 'Singapore',
      type: 'http://authoring.roots.sg/en/learn/collections/listing/city',
    }];
    state = createState({ displayedNodes });
    expect(getters.displayedNodes(state)).toEqual(displayedNodes);
  });

  test('displayedEdges', () => {
    let state = createState();
    expect(getters.displayedEdges(state)).toEqual([]);

    const displayedEdges = [{
      arrows: 'to',
      color: '#583604',
      from: 'city_singapore',
      id: 'cityOfCountry-country_singapore',
      length: 180,
      title: 'cityOfCountry',
      to: 'country_singapore',
    }];
    state = createState({ displayedEdges });
    expect(getters.displayedEdges(state)).toEqual(displayedEdges);
  });

  test('selectedNode', () => {
    let state = createState();
    expect(getters.selectedNode(state)).toEqual([]);

    const selectedNode = [{
      color: '#583604',
      font: '#583604',
      id: 'city_singapore',
      label: 'Singapore',
      type: 'http://authoring.roots.sg/en/learn/collections/listing/city',
    }];
    state = createState({ selectedNode });
    expect(getters.selectedNode(state)).toEqual(selectedNode);
  });

  test('history', () => {
    let state = createState();
    expect(getters.history(state)).toEqual([]);

    const history = [{
      concept: 'singapore',
      id: 'city_singapore',
      individual: 'singapore',
      label: 'singapore',
    }];
    state = createState({ history });
    expect(getters.history(state)).toEqual(history);
  });

  test('table', () => {
    let state = createState();
    expect(getters.table(state)).toEqual([]);

    const table = [{
      class: 'country',
      individual: 'Singapore',
      isHover: false,
      relation: 'cityOfCountry',
      subIndividual: 'Singapore',
    }];
    state = createState({ table });
    expect(getters.table(state)).toEqual(table);
  });

  test('ontologyMap', () => {
    let state = createState();
    expect(getters.ontologyMap(state)).toEqual({});

    const ontologyMap = [{
      'aia singapore': '6qltoced9i8',
      'amara singapore': 'g1ohevsnpk',
    }];
    state = createState({ ontologyMap });
    expect(getters.ontologyMap(state)).toEqual(ontologyMap);
  });

  test('maxHistoryLength', () => {
    let state = createState();
    expect(getters.maxHistoryLength(state)).toBe(40);

    state = createState({ maxHistoryLength: 41 });
    expect(getters.maxHistoryLength(state)).toBe(41);
  });
});
