<template>
  <div class="custom-dropdown">
    <button
      class="custom-dropdown__button"
      ref="button"
      aria-haspopup="true"
      :aria-expanded="isDropdownShowing"
      @click.stop="toggleShowDropdown"
      :class="{'isActive':isDropdownShowing}"
    >
      {{text}}
      <BadgeDot
        v-if="hasAlert"
        style="left:118px; top:8px;"
      />
      <font-awesome-icon
        :icon="['far', 'chevron-down']"
        size="xs"
        :class="{'chevron-up':isDropdownShowing}"
      />
    </button>
    <div
      :id="id"
      class="custom-dropdown__dropdown"
      v-show="isDropdownShowing"
      :style="dropdownStyles"
      v-click-outside="handleOutsideClick"
    >
      <div
        v-if="hasHeader"
        class="custom-dropdown__dropdown-header"
      >
        <slot name="header"></slot>
      </div>
      <div class="custom-dropdown__dropdown-content">
        <slot></slot>
      </div>
      <div
        v-if="useFooter"
        class="custom-dropdown__dropdown-footer"
      >
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BadgeDot from '@/components/badge-dot/BadgeDot';

export default {
  props: {
    id: String,
    text: {
      type: String,
    },
    position: {
      type: String,
      default: 'left',
      validation (value) {
        return ['right', 'left'].includes(value);
      },
    },
    useFooter: Boolean,
    hasAlert: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BadgeDot,
  },

  data () {
    return {
      buttonHeight: 0,
      isDropdownShowing: false,
    };
  },

  computed: {
    hasHeader () {
      return !!this.$slots.header;
    },
    dropdownStyles () {
      return {
        transform: `translate3d(0px, ${this.buttonHeight}px, 0px)`,
      };
    },
  },

  mounted () {
    this.setButtonHeight();
  },

  methods: {
    handleOutsideClick () {
      this.isDropdownShowing = false;
      this.$emit('hide');
    },
    setButtonHeight () {
      this.buttonHeight = this.$refs.button.offsetHeight;
    },
    toggleShowDropdown () {
      this.isDropdownShowing = !this.isDropdownShowing;
      if (this.isDropdownShowing) {
        this.$nextTick(() => {
          const dropdown = document.querySelector(`#${this.id}`);
          const rect = dropdown.getBoundingClientRect();
          this.$emit('show', rect);
        });
      } else {
        this.$emit('hide');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dropdown {
  display: inline-block;
  position: relative;

  &__button {
    font-size: $font-md;
    background: $white-500;
    color: $blue-gray-400;
    font-weight: $weight-normal;
    padding: $gap-xs $gap-lg;
    border-radius: $radius-4;
    border: 0;

    &.isActive {
      color: $blue-500;
    }

    .chevron-up {
      transform: rotate(180deg);
    }
  }

  &__dropdown {
    width: 640px;
    border-radius: $radius-4;
    border: 1px solid $blue-gray-100;
    box-shadow: 2px 4px 8px rgba(153, 155, 168, 0.2);
    background: $white;
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 10;
    padding: $gap-xs $gap-md;
  }

  &__dropdown-header {
    border-bottom: 1px solid $blue-gray-100;
    font-weight: $weight-semi-bold;
    padding-top: $gap-xs;
    padding-bottom: $gap-xs;
  }

  &__dropdown-footer {
    border-top: 1px solid $blue-gray-100;
    padding-top: $gap-md;
    padding-bottom: $gap-xs;
    background: $white-500;
  }
}
</style>
