import Vue from 'vue';
import getDefaultState from './state';

export default {
  setCriteriaId (state, criteriaId) {
    state.criteriaId = criteriaId;
  },
  setCurrentQuery (state, currentQuery) {
    Vue.set(state, 'currentQuery', currentQuery);
  },
  setCurrentSearchMode (state, currentSearchMode) {
    Vue.set(state, 'currentSearchMode', currentSearchMode);
  },
  setCurrentSearchParameter (state, currentSearchParameter) {
    Vue.set(state, 'currentSearchParameter', currentSearchParameter);
  },
  setCurrentSearchRequestParameter (state, currentSearchRequestParameter) {
    Vue.set(state, 'currentSearchRequestParameter', currentSearchRequestParameter);
  },
  setCurrentSearchResults (state, searchResults) {
    Vue.set(state, 'currentSearchResults', searchResults);
  },
  setSelectedSource (state, selectedSource) {
    Vue.set(state, 'selectedSource', selectedSource);
  },
  setCategories (state, categories) {
    Vue.set(state, 'categories', categories);
  },
  setAppliedSynonyms (state, appliedSynonyms) {
    Vue.set(state, 'appliedSynonyms', appliedSynonyms);
  },
  setIsSearching (state, flag) {
    state.isSearching = flag;
  },
  setIsSearchSuccess (state, flag) {
    state.isSearchSuccess = flag;
  },
  setCurrentPage (state, page) {
    state.currentPage = page;
  },
  setItemsPerPage (state, number) {
    state.itemsPerPage = number;
  },
  setAppliedSort (state, sort) {
    state.appliedSort = sort;
  },
  /**
   * @param {*} state
   * @param {string[]} text
   */
  setSearchWithin (state, searchWithin) {
    state.searchWithin = searchWithin;
  },
  /**
   * @param {*} state
   * @param {string} text
   */
  addSearchWithin (state, text) {
    const isExists = state.searchWithin.indexOf(text) > -1;
    if (isExists) return;
    state.searchWithin.push(text);
  },
  /**
   * @param {*} state
   * @param {string} text
   */
  removeSearchWithin (state, text) {
    state.searchWithin = state.searchWithin.filter(element => element !== text);
  },
  resetState (state) {
    Object.assign(state, getDefaultState());
  },
  setCurrentDateObject (state, dateObject) {
    state.currentDateObject = dateObject;
  },
  setPromoteDateObject (state, dateObject) {
    state.promoteDateObject = dateObject;
  },
};
