/**
 * defaultRoutes
 * Contains all the default routes of search-ui
 */
import { catalogRoutes } from '@/pages/ui-catalog-page/RouteSettings';
import widgetCatalogue from '@/services/widget-catalogue.service';

const mainRoutes = [
  { path: '/', redirect: '/search' },
//   {
//     path: '/dashboard',
//     name: 'dashboard',
//     component: () => import(/* webpackChunkName: "dashboard" */ '../pages/admin-page/AdminPage.vue'),
//   },
// Overwrite
// {
//   path: '/search',
//   name: 'search-home',
//   component: () => import(/* webpackChunkName: "search-home" */ '../modules/home/Home.vue'),
// },
//   {
//     path: '/configuration',
//     name: 'configuration',
//     component: () => import(/* webpackChunkName: "config-page" */ '../pages/config-page/ConfigPage.vue'),
//   },
//   {
//     path: '/ner',
//     name: 'ner',
//     component: () => import(/* webpackChunkName: "ner-page" */ '../pages/ner-page/NerPage.vue'),
//   },
];

const widgetRoutes = widgetCatalogue.routes.map((route) => {
  const {
    name, directory, tag, props, path,
  } = route;
  return {
    name,
    path,
    props,
    component: () => import(/* webpackChunkName: "[request]" */ `../${directory}/${tag}/${name}`),
  };
});


const {
  path, name, component, children,
} = catalogRoutes;

const catalogConfig = {
  path,
  name,
  component,
  children,
};


export default [
  ...mainRoutes,
  ...widgetRoutes,
  catalogConfig,
  // connectorRoutes,
];
