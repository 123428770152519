<template>
  <b-button class="iconbadge" :variant="variant">
    <slot name="icon" class="text-smoke"></slot>
  </b-button>
</template>

<script>
export default {
  props: {
    variant: String,
  },
};
</script>

<style scoped lang='scss'>
.iconbadge {
  border-radius: 2.5em !important;
  height: 72px;
  width: 72px;
  pointer-events: none;
}
</style>
