<template>
  <IcrsTemplate>
    <template #content>
      <h2 class="text-center">About </h2>
      <br>
      <p>
        <b>Intelligent Case Retrieval System (ICRS)</b> helps users easily identify,
        locate and retrieve previous Court cases relating to personal injuries.
        By performing a search in the system, users can view similar injury cases in the past.
      </p>
      <br>
      <p>
        <b>ICRS</b> is not a substitute for professional legal advice.
      </p>
      <IcrsSectionTitle value="Key Features"></IcrsSectionTitle>
      <section class="icrs-about-key-features-section container-fluid">
        <div class="row">
          <div class="col-lg-5">
            <div class="icrs-about-key-features-description">
              <h4>Discover cases with an intuitive navigation system</h4>
              <p>ICRS enables users to navigate a database of personal injury cases in order to discover cases based on the nature and location of the injury.</p>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="icrs-about-feature-image">
              <img
                src="icrs/images/guided-search.png"
                alt="sample guided search"
              >
            </div>
          </div>
        </div>
        <div class="row icrs-about-key-features-section--flip">
          <div class="col-lg-7">
            <div class="icrs-about-feature-image">
              <img
                src="icrs/images/scatter-plot.png"
                alt="sample plot result"
              >
            </div>
          </div>
          <div class="col-lg-5">
            <div class="icrs-about-key-features-description">
              <h4>Visualise award amounts of injury cases</h4>
              <p>Injury cases are represented on scatter plot in relation to their award amount and assessment date.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5">
            <div class="icrs-about-key-features-description">
              <h4>View the summary of injury case and award amount at one glance</h4>
              <p>Type of injuries and their relevant award amount are neatly presented in a tabular format.</p>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="icrs-about-feature-image">
              <img
                src="icrs/images/award-summary.png"
                alt="sample award details"
              >
            </div>
          </div>
        </div>
      </section>

      <IcrsSectionTitle value="More Features"></IcrsSectionTitle>
      <section class="icrs-about-other-features-section container">
        <div class="row">
          <div class="col-lg-6">
            <ul>
              <li>Free text search. Search for injury cases by entering a keyword. </li>
              <li>Auto complete search suggestion.</li>
              <li>Filter search results.</li>
            </ul>
          </div>
          <div class="col-lg-6">
            <ul>
              <li>Discover injury cases based on nearby body parts.</li>
              <li>Boolean search. For example head AND leg.</li>
              <li>Save current search query to be reused in future searches.</li>
            </ul>
          </div>
        </div>
      </section>
    </template>
  </IcrsTemplate>
</template>
<script>
import IcrsTemplate from './template/IcrsStaticTemplate.vue';
import IcrsSectionTitle from './components/IcrsSectionTitle.vue';

export default {
  components: {
    IcrsTemplate,
    IcrsSectionTitle,
  },
};
</script>
<style lang="scss" scoped>
.icrs-about-key-features-section,
.icrs-about-other-features-section {
  margin: 1rem;
}

.icrs-about-key-features-section {
  .row {
    margin: 2rem 0;
  }

  .row > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icrs-about-key-features-description {
    background-color: var(--grey-light);
    width: 80%;
    padding: 1rem;
    margin: 0 auto;
  }

  .icrs-about-feature-image {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      border: 1px solid var(--grey-light);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.178);
    }
  }
}

.icrs-about-other-features-section {
  background-color: var(--grey-light);
  ul {
    padding: 1rem 2rem;
  }
  li {
    margin: 0.5rem 0;
  }
}

@media (max-width: 992px) {
  .icrs-about-key-features-section {
    .icrs-about-key-features-description {
      width: 100%;
    }

    .icrs-about-feature-image {
      margin-top: 1rem;
    }

    .icrs-about-key-features-section--flip {
      flex-direction: column-reverse;
    }
  }

  .icrs-about-other-features-section {
    ul {
      padding: 0rem 2rem;
    }
    li {
      margin: 0.5rem 0;
    }
  }
}
</style>
