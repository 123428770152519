import mutations from './mutations';
import actions from './actions';
import createState from './state';
import getters from './getters';
import ontologySearch from './modules/ontology-search/index.module';

const advancedSearch = {
  namespaced: true,
  state: createState(),
  getters,
  mutations,
  actions,
  modules: {
    ontologySearch,
  },
};

export default advancedSearch;
