import constants from '@/constants/search-service-constants';
import SearchParameter from '@/services/models/search/search-parameter.model';
import SearchRequestParameter from '@/services/models/search/search-request-parameter.model';
import mutations from '../mutations';
import createState from '../state';

function createSearchParameter ({
  searchMode = constants.SEARCH_MODE,
  appliedFilters = {},
  dateObject = {},
  curPage = 1,
  appliedSort = {},
  source = constants.SOURCE_TYPES.ALL,
  appliedSynonyms = {},
  size = constants.RESULTS_PER_PAGE,
  id = 'a-uuid',
} = {}) {
  return new SearchParameter(
    searchMode,
    appliedFilters,
    dateObject,
    curPage,
    appliedSort,
    source,
    appliedSynonyms,
    size,
    id,
  );
}

jest.mock('../../../../services/icrs-service', () => ({
  getFiltersTopicTitleDisplayOrder: jest.fn(),
}));

describe('search-core vuex test', () => {
  it('setCurrentQuery', () => {
    const state = createState();
    const query = 'Singapore';
    mutations.setCurrentQuery(state, query);
    expect(state.currentQuery).toBe(query);
  });

  it('setCurrentSearchMode', () => {
    const state = createState();

    let mode = constants.SEARCH_MODE.NEW;
    mutations.setCurrentSearchMode(state, mode);
    expect(state.currentSearchMode).toBe(mode);

    mode = constants.SEARCH_MODE.SAVE_SEARCH;
    mutations.setCurrentSearchMode(state, mode);
    expect(state.currentSearchMode).toBe(mode);
  });

  it('setCurrentSearchParameter', () => {
    const state = createState();
    const searchParameter = createSearchParameter();

    mutations.setCurrentSearchParameter(state, searchParameter);
    expect(state.currentSearchParameter).toBe(searchParameter);
  });

  test('setCurrentSearchRequestParameter', () => {
    const searchParameter = createSearchParameter();
    const requestParam = new SearchRequestParameter('a query', searchParameter);

    const state = createState();
    mutations.setCurrentSearchRequestParameter(state, requestParam);
    expect(state.currentSearchRequestParameter).toBe(requestParam);
  });

  test('setCurrentSearchResults', () => {
    const state = createState();
    const result = {
      documents: [],
      id: '111111',
      size: 0,
      total: 0,
    };
    mutations.setCurrentSearchResults(state, result);
    expect(state.currentSearchResults).toBe(result);
  });

  test('setCanSaveSearch', () => {
    const state = createState();
    mutations.setCanSaveSearch(state, true);
    expect(state.canSaveSearch).toBe(true);
    mutations.setCanSaveSearch(state, false);
    expect(state.canSaveSearch).toBe(false);
  });

  test('setSuggestions', () => {
    const state = createState();
    const suggestions = ['topic a', 'topic b'];
    mutations.setSuggestions(state, suggestions);
    expect(state.suggestions).toBe(suggestions);
  });

  test('setCorrections', () => {
    const state = createState();
    const corrections = ['topic x', 'topic y', 'topic z'];
    mutations.setCorrections(state, corrections);
    expect(state.corrections).toBe(corrections);
  });

  test('setSelectedSource', () => {
    const state = createState();
    expect(state.selectedSource).toBe(undefined);
    const source = ['CASA'];
    mutations.setSelectedSource(state, source);
    expect(state.selectedSource).toBe(source);
  });

  test('setCategories', () => {
    const state = createState();
    const categories = {
      id: '1111',
      size: 0,
      total: 0,
      documents: [],
      aggregations: [],
    };
    mutations.setCategories(state, categories);
    expect(state.categories).toBe(categories);
  });

  test('setAppliedSynonyms', () => {
    const state = createState();
    const appliedSynonyms = {};
    mutations.setAppliedSynonyms(state, appliedSynonyms);
    expect(state.appliedSynonyms).toBe(appliedSynonyms);
  });

  test('setAppliedSort', () => {
    const state = createState();
    const appliedSort = { field: 'lastModifiedDate', order: 'desc' };
    mutations.setAppliedSort(state, appliedSort);
    expect(state.appliedSort).toBe(appliedSort);
  });

  test('setIsSearching', () => {
    const state = createState();
    mutations.setIsSearching(state, true);
    expect(state.isSearching).toBe(true);
    mutations.setIsSearching(state, false);
    expect(state.isSearching).toBe(false);
  });

  test('setIsSearchSuccess', () => {
    const state = createState();
    mutations.setIsSearchSuccess(state, true);
    expect(state.isSearchSuccess).toBe(true);
    mutations.setIsSearchSuccess(state, false);
    expect(state.isSearchSuccess).toBe(false);
  });

  test('setIsSearchSuccess', () => {
    const state = createState();
    mutations.setIsSearchSuccess(state, true);
    expect(state.isSearchSuccess).toBe(true);
    mutations.setIsSearchSuccess(state, false);
    expect(state.isSearchSuccess).toBe(false);
  });

  test('setCurrentPage', () => {
    const state = createState();
    mutations.setCurrentPage(state, 1);
    expect(state.currentPage).toBe(1);
    mutations.setCurrentPage(state, 2);
    expect(state.currentPage).toBe(2);
  });

  test('setItemsPerPage', () => {
    const state = createState();
    mutations.setItemsPerPage(state, 10);
    expect(state.itemsPerPage).toBe(10);
    mutations.setItemsPerPage(state, 20);
    expect(state.itemsPerPage).toBe(20);
  });

  test('setSavedSearches', () => {
    const state = createState();
    const savedSearches = [
      { id: '123', name: 'Saved search 1' },
    ];
    mutations.setSavedSearches(state, savedSearches);
    expect(state.savedSearches).toBe(savedSearches);
  });


  test('addSavedSearch', () => {
    const savedSearches = [
      { id: '123', name: 'Saved search 1' },
    ];
    const state = createState({ savedSearches });
    mutations.addSavedSearch(state, {
      id: '456',
      name: 'Saved search 2',
    });
    expect(state.savedSearches).toEqual([
      { id: '456', name: 'Saved search 2' },
      { id: '123', name: 'Saved search 1' },
    ]);
  });

  test('updateSavedSearch', () => {
    const savedSearches = [
      { id: '123', name: 'Saved search 1' },
    ];
    const state = createState({ savedSearches });
    mutations.updateSavedSearch(state, {
      id: '123',
      name: 'Updated saved search 1',
    });
    expect(state.savedSearches).toEqual([
      { id: '123', name: 'Updated saved search 1' },
    ]);
  });

  test('removeSavedSearch', () => {
    const savedSearches = [
      { id: '123', name: 'Saved search 1' },
    ];
    const state = createState({ savedSearches });
    mutations.removeSavedSearch(state, '123');
    expect(state.savedSearches).toEqual([]);
  });

  test('setFilterGroups', () => {
    const state = createState();
    const filterGroups = [];
    mutations.setFilterGroups(state, filterGroups);
    expect(state.filterGroups.data).toBe(filterGroups);
  });

  test('setFilterGroupFetchingStatus', () => {
    const state = createState();
    mutations.setFilterGroupFetchingStatus(state, true);
    expect(state.filterGroups.isFetching).toBe(true);
    mutations.setFilterGroupFetchingStatus(state, false);
    expect(state.filterGroups.isFetching).toBe(false);
  });

  test('setFilterGroupConfigs', () => {
    const state = createState();
    const config = { caa: {}, domains: {} };
    mutations.setFilterGroupConfigs(state, config);
    expect(state.filterGroupConfigs).toBe(config);
  });
});
