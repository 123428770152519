import getDefaultState from './state';

export default {
  setRelations (state, relations) {
    state.relations = relations;
  },
  setRelationsMin (state, relationsMin) {
    state.relationsMin = relationsMin;
  },
  setRelationsMax (state, relationsMax) {
    state.relationsMax = relationsMax;
  },
  setMaxEdges (state, maxEdges) {
    state.maxEdges = maxEdges;
  },
  setNodes (state, nodes) {
    state.nodes = nodes;
  },
  setEdges (state, edges) {
    state.edges = edges;
  },
  setSelectedNode (state, selectedNode) {
    state.selectedNode = selectedNode;
  },
  setTable (state, table) {
    state.table = table;
  },
  setHistory (state, history) {
    state.history = history;
  },
  setDisplayedNodes (state, displayedNodes) {
    state.displayedNodes = displayedNodes;
  },
  setDisplayedEdges (state, displayedEdges) {
    state.displayedEdges = displayedEdges;
  },
  setOntologyMap (state, ontologyMap) {
    state.ontologyMap = ontologyMap;
  },
  setMaxHistoryLength (state, maxHistoryLength) {
    state.maxHistoryLength = maxHistoryLength;
  },
  setClassFilters (state, classFilters) {
    state.classFilters = classFilters;
  },
  setIndividualProperties (state, individualProperties) {
    state.individualProperties = individualProperties;
  },
  resetState (state) {
    Object.assign(state, getDefaultState());
  },
};
