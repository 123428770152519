<template>
  <div>
    <b-dropdown
      block
      class="h-100"
      :text="historyLabel"
      variant="link"
      toggle-class="btn-sm"
      data-cy="search-history-button"
    >
      <b-dropdown-text>
        Recently viewed
      </b-dropdown-text>
      <b-dropdown-divider></b-dropdown-divider>
      <div
        v-for="(item, index) of historyDropdown"
        :key="index"
      >
        <b-dropdown-item
          @click="handleSelectHistory(index)"
          class="history-item text-capitalize"
          :data-cy="`search-history-item-${index}`"
        >{{ item.text }}</b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getHistoryDropdown } from '../ontology-search-helpers';

export default {
  name: 'OntologySearchHistory',
  data () {
    return {
      historyDropdown: [],
      config: {
        showRelationName: false,
      },
    };
  },
  computed: {
    ...mapGetters('advancedSearch/ontologySearch', [
      'history',
    ]),
    historyLabel () {
      return `Search history (${this.history.length})`;
    },
  },
  mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
    this.$nextTick(() => {
      this.historyDropdown = getHistoryDropdown(this.history);
    });
  },
  watch: {
    history (value) {
      if (value) {
        this.historyDropdown = getHistoryDropdown(value);
      }
    },
  },
  methods: {
    ...mapActions('advancedSearch/ontologySearch', [
      'handleSelectElementHistory',
    ]),
    handleSelectHistory (index) {
      const actualIndex = this.history.length - 1 - index;
      this.handleSelectElementHistory({
        index: actualIndex,
        showRelationName: this.config.showRelationName,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .dropdown-menu {
  min-width: 20em !important;
}
/deep/ .b-dropdown-text {
  font-weight: $weight-semi-bold;
  font-size: $font-md;
  padding-left: 0.5em;
}
/deep/ .btn.dropdown-toggle {
  color: $blue-400 !important;
  height: 100%;
}
</style>
