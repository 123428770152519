import app from './app/index.module';
import authService from './auth/auth.module';
import previewViewer from './preview-viewer/index.module';
import searchService from './search-core/index.module';
import docManagerService from './doc-manager/index.module';
import loadingPage from './loading-page/index.module';
import kmService from './km/index.module';
import searchAdmin from './search-admin/index.module';
import advancedSearch from './advanced-search/index.module';
import eventTracker from './event-tracker/index.module';
import icrs from './icrs/index.module';
import pageManagement from './page-management/index.module';

const modules = {
  app,
  authService,
  previewViewer,
  searchService,
  docManagerService,
  loadingPage,
  kmService,
  searchAdmin,
  advancedSearch,
  eventTracker,
  icrs,
  pageManagement,
};

export default modules;
