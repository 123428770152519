import { render, staticRenderFns } from "./FacetedSearch.vue?vue&type=template&id=5fab4e91&scoped=true"
import script from "./FacetedSearch.vue?vue&type=script&lang=js"
export * from "./FacetedSearch.vue?vue&type=script&lang=js"
import style0 from "./FacetedSearch.vue?vue&type=style&index=0&id=5fab4e91&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fab4e91",
  null
  
)

export default component.exports