<template>
  <div>
    <div v-if="useICRSConfig">
      <span><strong>Applied Filters: </strong></span>
      <span v-if="badgeGroups.length == 0  && searchWithin.length == 0">No filters applied.</span>
      <div
        class="badge-wrapper"
        v-for="group in badgeGroups"
        :key="getUniqueId(group)"
      >
        <SearchWithinBadge
          v-for="item in group.items"
          :key="getUniqueId(group) + '_' + item.key"
          show-icrs-close-button
          :name="group.displayName + ':'"
          :text="item.key"
          :icrs-filter-group-name="group.name"
          @remove="handleRemoveICRSFilter"
        />
      </div>
      <div
        class="badge-wrapper"
        v-for="text in searchWithin"
        :key="text"
      >
        <SearchWithinBadge
          show-close-button
          :name="'Search Within:'"
          :text="text"
          @remove="handleRemoveSearchWithin"
        />
      </div>
    </div>
    <div v-if="!useICRSConfig">
      <h1
        v-if="!useICRSConfig && promotedFilterEnable"
        class="promoted-content-title"
      >All promoted contents</h1>
      <Badge
        v-if="!useICRSConfig && promotedFilterEnable"
        variant="primary"
        class="badge-wrapper"
        pill
      >
        {{ promotedContentBadgeLabel }}
      </Badge>

      <div v-if="!useICRSConfig">
        <div
          class="badge-wrapper"
          v-for="group in badgeGroups"
          :key="getUniqueId(group)"
        >
          <AppliedFilterDropdownBadge
            :filterCountDisabled="filterCountDisabled"
            :filter-group="group"
            @blur="handleBadgeBlur"
            @show-filters="handleShowFilters"
          />
        </div>
      </div>

      <div
        class="badge-wrapper"
        v-for="date in dateFilters"
        :key="date.key"
      >
        <SearchWithinBadge
          show-close-button
          :name="date.key"
          :text="date.date"
          @remove="handleRemoveDateSearch(date)"
        />
      </div>
      <div
        class="badge-wrapper"
        v-if="promotedFilterEnable"
      >
        <SearchWithinBadge
          class="promoted-content-badge"
          show-close-button
          :name="'All promoted content: '"
          :text="promotedContentBadgeLabel"
          @remove="handleRemovePromotedContentFilter()"
        />
      </div>
      <div
        class="badge-wrapper"
        v-for="text in searchWithin"
        :key="text"
      >
        <SearchWithinBadge
          show-close-button
          :text="text"
          @remove="handleRemoveSearchWithin"
        />
      </div>
    </div>
  </div>

</template>

<script>
import {
  mapGetters, mapActions, mapState,
} from 'vuex';
import ICRSService from '@/services/icrs-service';
import SearchWithinBadge from '@/modules/search-within/SearchWithinBadge.vue';
import SearchConstants from '@/constants/search-service-constants';
import { getPromoteDateRangeLabel } from '../../helpers/promoted-content-helpers';
import AppliedFilterDropdownBadge from './components/AppliedFilterDropdown.vue';
import { createBadgeDropdownGroups } from '../../helpers/applied-filters';

export default {
  components: {
    SearchWithinBadge,
    AppliedFilterDropdownBadge,
  },

  data () {
    return {
      badgeGroups: [],
      useICRSConfig: process.env.VUE_APP_USE_ICRS_CONFIG === 'true',
    };
  },

  props: {
    namespace: {
      type: String,
      default () {
        return 'searchService';
      },
    },
    filterCountDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('searchService', [
      'filterGroups',
      'filterGroupConfigs',
    ]),
    ...mapGetters('searchService/filters', [
      'selectedFilters',
    ]),
    ...mapGetters('searchService/filters/date', [
      'dateFilters',
    ]),
    ...mapState({
      searchWithin (state, getters) {
        return getters[`${this.namespace}/searchWithin`];
      },
      currentSearchRequestParameter (state, getters) {
        return getters[`${this.namespace}/currentSearchRequestParameter`];
      },
    }),
    ...mapGetters('searchService/filters/promotedContent',
      ['promotedFilterEnable', 'promotedByDateRangeMode', 'promoteDateObject']),

    promotedContentBadgeLabel () {
      return getPromoteDateRangeLabel(this.promotedByDateRangeMode, this.promoteDateObject.start,
        this.promoteDateObject.end);
    },
  },

  watch: {
    'currentSearchRequestParameter.topicsQuery.must': {
      handler () {
        const hasButtonFocus = Boolean(this.$el.querySelector('.badge-dropdown__button:focus'));

        // if the button has focus, then we don't want
        // to update it because we want to keep the dropdown open.
        // If we update it, the component
        // will re-render and makes the dropdown hidden
        if (hasButtonFocus) return;

        this.initBadgeGroups();
      },
    },
  },

  mounted () {
    // Known issue:
    // Somehow when items filter is changing (unchecked)
    // mounted is called in IE11, while in Chrome it's not called
    // Resulting badge dropdown disappear when uncheck the only
    // applied filter item

    this.initBadgeGroups();
    this.listenToSetFiltersMutation();
  },

  beforeDestroy () {
    this.unlistenToSetFiltersMutation();
  },

  methods: {
    ...mapActions({
      removeSearchWithin (dispatch, text) {
        return dispatch(`${this.namespace}/removeSearchWithin`, text);
      },
      triggerSearchWithinQuery (dispatch) {
        return dispatch(`${this.namespace}/triggerSearchWithinQuery`);
      },
    }),
    ...mapActions('searchService/filters', [
      'setModalSelectedFilterGroup',
      'setModalSelectedFilters',
      'setIsFirstOpenHasSelectedFilters',
      'setSelectedFilters',
    ]),

    ...mapActions('searchService/filters/date', [
      'handleStartDateChange',
      'handleEndDateChange',
    ]),

    ...mapActions('searchService/filters/promotedContent', [
      'removePromotedContentFilters',
    ]),
    ...mapActions('searchService', [
      'setCurrentSearchMode',
      'executeSearchQuery',
      'getFilterGroupsLevelTwo',
      'executeCategories',
    ]),
    ...mapActions('searchService/newResults',
      [
        'executeSearchNewQuery',
        'executeCategoriesNew',
      ]),

    unlistenToSetFiltersMutation () {
      this.unsubscribeSetFiltersGroup();
    },

    listenToSetFiltersMutation () {
      // todo: teardown this listener
      this.unsubscribeSetFiltersGroup = this.$store.subscribe((mutation) => {
        if (mutation.type === 'searchService/setFilterGroups') {
          this.badgeGroups.forEach((badgeGroup) => {
            const filterGroup = this.filterGroups.find(group => group.name === badgeGroup.name);

            if (filterGroup) {
              badgeGroup.items.forEach((item) => {
                const { key } = item;
                const updatedFilterItem = filterGroup.bucket.find(it => it.key === key) || { docCount: 0 };
                const newDocCount = updatedFilterItem.docCount;
                this.$set(item, 'docCount', newDocCount);
              });
            }
          });
        }
      });
    },

    handleRemoveICRSFilter (filterGroupName, filterKey) {
      const nextSelectedFilters = this.selectedFilters.filter(
        currentFilter => this.checkICRSFilter(currentFilter, filterGroupName, filterKey),
      );
      this.setSelectedFilters(nextSelectedFilters);
      this.setCurrentSearchMode(SearchConstants.SEARCH_MODE.FILTER);
      this.executeSearchQuery({ filterGroupLevel: 2 });
      this.executeSearchNewQuery({ filterGroupLevel: 2 });
      // this.executeCategories();
      // this.executeCategoriesNew();
    },
    checkICRSFilter (currentFilter, filterGroupName, filterKey) {
      return !(currentFilter.groupName === filterGroupName && currentFilter.key === filterKey);
    },
    // /**
    //  * @param {string} key
    //  * @param {string} groupName
    //  */
    // createFilterKey (key, groupName) {
    //   return `${groupName}_${key}`;
    // },
    // getNextFilters (uncheckedFilter, currentFilters) {
    //   const { key, groupName } = uncheckedFilter;
    //   const uncheckedFilterId = this.createFilterKey(key, groupName);
    //   const nextFilters = currentFilters
    //     .filter((filter) => {
    //       const filterId = this.createFilterKey(filter.key, filter.groupName);
    //       return uncheckedFilterId !== filterId;
    //     });
    //   return nextFilters;
    // },
    handleRemoveSearchWithin (text) {
      this.removeSearchWithin(text);
      this.triggerSearchWithinQuery();
    },

    handleBadgeBlur ({ isEmpty, filterGroup }) {
      if (isEmpty) {
        this.badgeGroups = this.badgeGroups
          .filter(item => item.name !== filterGroup.name);
        return;
      }

      // update the items
      this.initBadgeGroups();
    },

    getUniqueId (group) {
      const id = window.crypto.getRandomValues(new Uint32Array(1))[0];
      return `${group.name}-${id}`;
    },

    initBadgeGroups () {
      if (!this.currentSearchRequestParameter) return;
      const { topicsQuery } = this.currentSearchRequestParameter;

      this.badgeGroups = createBadgeDropdownGroups(topicsQuery, this.filterGroupConfigs)
        .map((group) => {
          const aggregation = this.filterGroups
            .find(item => item.name === group.key);
          const bucketWhitelist = group.items.map(item => item.value);

          const icrsItems = group.items.map((item) => {
            const icrsItem = item;
            icrsItem.key = item.value;
            return icrsItem;
          });
          const items = process.env.VUE_APP_USE_ICRS_CONFIG === 'true'
            ? icrsItems
            : aggregation.items.filter(item => bucketWhitelist.includes(item.key));

          return {
            name: group.key,
            displayName: process.env.VUE_APP_USE_ICRS_CONFIG === 'true'
              ? ICRSService.getFiltersTopicTitleDisplayOrder()[group.key].display
              : group.name,
            items,
          };
        });
    },

    handleShowFilters (group) {
      const foundFilterGroup = this.filterGroups
        .find(filterGroup => filterGroup.name === group.name);
      const nextModalSelectedFilters = this.selectedFilters
        .filter(filter => filter.groupName === group.name);

      this.setModalSelectedFilters(nextModalSelectedFilters);
      this.setModalSelectedFilterGroup(foundFilterGroup);
      this.setIsFirstOpenHasSelectedFilters(!!nextModalSelectedFilters.length);
    },
    handleRemoveDateSearch (date) {
      if (date.key === 'Modified Date: from') {
        this.handleStartDateChange(undefined);
      } else {
        this.handleEndDateChange(undefined);
      }
    },
    handleRemovePromotedContentFilter () {
      const filteredFilters = this.selectedFilters
        .filter(filter => filter.groupName !== SearchConstants.PROMOTED_CONTENT_FIELDS.FILTER);
      this.removePromotedContentFilters(filteredFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-wrapper {
  margin-bottom: 4px;
  margin-right: 4px;
  display: inline-block;
}
.promoted-content-title {
  display: inline-block;
  margin-right: 5px;
}
.promoted-content-badge {
  max-width: 350px;
  /deep/ .search-within-badge__text {
    max-width: 350px;
  }
}
</style>
