<template>
  <div class="custom-control custom-checkbox t-checkbox">
    <input
      :id="localId"
      type="checkbox"
      :name="name"
      :required="required"
      :disabled="disabled"
      autocomplete="off"
      class="custom-control-input"
      v-model="localChecked"
      @change="changeEvent"
    />
    <label :for="localId" class="custom-control-label">
      <slot></slot>
    </label>
  </div>
</template>

<script>
/**
 * Checkbox component.
 */
export default {
  name: 'Checkbox',
  status: 'release',
  data () {
    return {
      checkedProxy: false,
    };
  },
  computed: {
    localChecked: {
      get () {
        return this.value;
      },
      set (value) {
        this.checkedProxy = value;
      },
    },
    localId () {
      // eslint-disable-next-line no-underscore-dangle
      return this.id ? this.id : `t-checkbox-${this._uid}`;
    },
  },
  props: {
    /**
     *
     * checkbox value
     */
    value: {
      type: Boolean,
      default: true,
    },
    /**
     *
     * Used to set the 'id' attribute on the rendered content, and used as the base to generate any additional element IDs as needed
     */
    id: {
      type: String,
    },
    /**
     *
     * Sets the value of the 'name' attribute on the form control
     */
    name: {
      type: String,
    },
    /**
     *
     * When set to 'true', disables the component's functionality and places it in a disabled state
     */
    disabled: {
      type: Boolean,
    },
    /**
     *
     * Adds the 'required' attribute to the form control
     */
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeEvent () {
      /**
       * Emitted when selected value(s) is changed due to user interaction
       *
       * @event input
       * @property {object} checked - Value of checkbox(es). When bound to multiple checkboxes, value will be an array.
       * @type {function}
       */
      this.$emit('input', this.checkedProxy);
      this.$emit('change', this.checkedProxy);
    },
  },
};
</script>

<style lang="scss" scoped>
.t-checkbox {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  .custom-control {
    min-height: 0;
  }
  .custom-control-label {
    font-size: $font-md;
    color: $blue-gray-400;
    line-height: $ln-height-sm;
    padding-top: 4px;

    &:before,
    &:after {
      width: 12px;
      height: 12px;
      top: 6px;
      left: -18px;
    }
    &:before {
      border-color: $blue-gray-300;
    }
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url('data:image/svg+xml;utf8,<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.54235 4.73378L0.645821 2.86801L0 3.49888L2.54235 6L8 0.630872L7.35873 0L2.54235 4.73378Z" fill="white"/></svg>');
  }
}
</style>

<docs>
  ```jsx
  <div>
    <div class="mt-lg">
      <div>With Label</div>
      <Checkbox id="checkbox-1" name="checkbox-1" value="accepted" unchecked-value="not_accepted">I accept the terms and use</Checkbox>
    </div>
    <div class="mt-lg">
      <div>With No Label</div>
      <Checkbox id="checkbox-2" name="checkbox-2" value="accepted" unchecked-value="not_accepted"></Checkbox>
    </div>
    <div class="mt-lg">
      <div>Disabled State</div>
      <Checkbox disabled id="checkbox-3" name="checkbox-3" value="accepted" unchecked-value="not_accepted">I accept the terms and use</Checkbox>
    </div>
  </div>
  ```
</docs>
