/* eslint-disable import/no-extraneous-dependencies */
import ICRSService from '@/services/icrs-service';
import { saveAs } from '@progress/kendo-file-saver';
import Api from './api/api.service';
import ConfigService from './config.service';


const SEARCH_CONTEXT = ConfigService.getSearchSearchContext();
const ICRS_SEARCH_CONNECTOR = ConfigService.getSearchConnector();

export default {
  getAutocomplete ({ searchText, type }) {
    return Api().get(`${SEARCH_CONTEXT}/autocomplete`, { params: { searchText, type } });
  },
  postSimpleSearch (searchRequestParameter) {
    return Api().post(`${SEARCH_CONTEXT}/v3/search`, searchRequestParameter);
  },
  postSimpleSearchNew (searchNewRequestParameter) {
    return Api().post(`${SEARCH_CONTEXT}/v3/search-new-documents`, searchNewRequestParameter);
  },
  /**
   *  Query "You may also want to try..." API.
   * @param {String} searchQuery
   */
  execSuggestion (searchQuery) {
    return Api().get(`${SEARCH_CONTEXT}/youmayalsowanttotry`, { params: { searchText: searchQuery } });
  },
  /**
   *  Query "Did You Mean?" API.
   * @param {String} searchQuery
   */
  execCorrection (searchQuery) {
    return Api().get(`${SEARCH_CONTEXT}/didyoumean`, { params: { searchText: searchQuery } });
  },
  /**
   * Query NLP API.
   * @param {String} searchQuery
   */
  execNaturalLanguageSearch (searchQuery) {
    return Api().post(`${SEARCH_CONTEXT}/naturalLanguageSearch`, searchQuery).then(response => response.data);
  },
  execCategories (parameters) {
    return Api().post(`${SEARCH_CONTEXT}/v3/search-category`, parameters).then(response => response.data);
  },
  execCategoriesNew (parameters) {
    return Api().post(`${SEARCH_CONTEXT}/v3/search-new-category`, parameters).then(response => response.data);
  },
  getTextContent (body) {
    return Api().post(`${SEARCH_CONTEXT}/v3/textContent`, body).then(response => response.data);
  },
  getDocumentDetails ({ id }) {
    return Api().post(`${SEARCH_CONTEXT}/getDocument`, { id }).then(response => response.data);
  },
  getDidYouMean (text) {
    return Api().post(`${SEARCH_CONTEXT}/didyoumean?searchText=${text}`).then(response => response.data);
  },

  getAggregationConfigs () {
    return Promise.resolve().then(() => ICRSService.getAggregations());
    // return Api().get(`${SEARCH_CONTEXT}/aggregations`).then(response => response.data);
  },

  /**
   * Get aggregations level 1.
   * @param {object} data
   * @param {string} data.id
   * @param {object} data.boolQuery
   * @param {Array}  data.rangeQuery
   * @param {object} data.topicsQuery
   * @param {string} data.query
   * @param {number} data.from
   * @param {number} data.size
   * @param {object} data.user
   * @param {Array}  data.source
   */
  getAggregations (data) {
    const {
      id = null,
      boolQuery = {},
      rangeQuery = [],
      topicsQuery = { should: [], must: [] },
      query,
      from = 0,
      size = 10,
      searchMode = 'AGGREGATION',
      user,
      source = [],
      searchWithin,
    } = data;
    const payload = {
      id,
      boolQuery,
      rangeQuery,
      topicsQuery,
      from,
      size,
      query,
      searchMode,
      user,
      source,
      searchWithin,
    };

    return Api().post(`${SEARCH_CONTEXT}/v3/search/aggs?level=1`, payload)
      .then(response => response.data);
  },

  /**
   * Get aggregations level 2.
   * @param {object} data
   * @param {string} data.id
   * @param {object} data.boolQuery
   * @param {Array}  data.rangeQuery
   * @param {object} data.topicsQuery
   * @param {string} data.query
   * @param {number} data.from
   * @param {number} data.size
   * @param {object} data.user
   * @param {object} data.aggsParam
   * @param {string[]} data.aggsParam.concept list of aggregate key
   */
  getAggregationsLevelTwo (data) {
    const {
      id = null,
      boolQuery = {},
      rangeQuery = [],
      topicsQuery = { should: [], must: [] },
      query,
      from = 0,
      size = 10,
      searchMode = 'AGGREGATION',
      user,
      aggsParam,
      source = [],
      searchWithin,
    } = data;
    const payload = {
      id,
      boolQuery,
      rangeQuery,
      topicsQuery,
      from,
      size,
      query,
      searchMode,
      user,
      aggsParam,
      source,
      searchWithin,
    };

    return Api().post(`${SEARCH_CONTEXT}/v3/search/aggs?level=2`, payload)
      .then(response => response.data);
  },

  getUserSavedSearches (userId) {
    return Api().get(`${SEARCH_CONTEXT}/v3/criteria/user/${userId}`)
      .then(response => response.data);
  },

  getSavedSearch (criteriaId) {
    return Api().get(`${SEARCH_CONTEXT}/v3/criteria/${criteriaId}`)
      .then(response => response.data);
  },

  /**
   * @param {object} payload
   * @param {string} payload.name
   * @param {object} payload.request
   */
  createUserSavedSearch (payload) {
    return Api().post(`${SEARCH_CONTEXT}/v3/criteria`, payload)
      .then(response => response.data);
  },

  /**
   * @param {object} payload
   * @param {string} payload.id
   * @param {string} payload.name
   * @param {object} payload.request
   */
  updateUserSavedSearch (payload) {
    return Api().put(`${SEARCH_CONTEXT}/v3/criteria/${payload.request.criteriaId}`, payload)
      .then(response => response.data);
  },

  removeUserSavedSearch (savedSearchId) {
    return Api().delete(`${SEARCH_CONTEXT}/v3/criteria/${savedSearchId}`)
      .then(response => response.data);
  },

  /**
   * @param {import('@typings/search').DocumentMetrics} payload
   */
  execDocumentMetrics (payload) {
    return Api().post(`${SEARCH_CONTEXT}/document_metrics`, payload)
      .then(response => response.data);
  },

  /**
   * Plugins
   */
  getTrendingSearch ({ lookbackDays, size }) {
    return Api().get(`${SEARCH_CONTEXT}/trendingSearch`, { params: { lookbackDays, size } });
  },
  /**
   * @description retrieve filter configuration
   * @todo switch to real api when it is ready, move to another service if there is one
   */
  getFilterGroupings () {
    return Promise.resolve().then(() => ICRSService.getFilterGroupConfig());
    // const filePath = `${ConfigService.getBaseUrl()}/filter-group-config.json`;
    // return fetch(filePath)
    //   .then(resp => resp.json())
    //   .then(filterConfigs => filterConfigs);
  },
  postUploadFile (formData) {
    return Api().post(`${ICRS_SEARCH_CONNECTOR}/courtcase/uploadexcel`, formData);
  },
  getDownloadExcel () {
    return Api().get(`${ICRS_SEARCH_CONNECTOR}/courtcase/downloadExcel`, { responseType: 'blob' })
      .then((response) => {
        saveAs(new Blob([response.data]), 'Case Upload Excel Template.xlsx');
        return response;
      });
  },
  /**
   * @param {object} payload
   * @param {string} payload.tempFolderName
   * @param {Array} payload.listUuid
   */
  postSaveFile (payload) {
    return Api().post(`${ICRS_SEARCH_CONNECTOR}/courtcase/save`, payload)
      .then(response => response.data);
  },
};
