<template>
    <ner-widget class="search-ner-styles"/>
</template>

<script>

export default {
  beforeRouteEnter (to, from, next) {
    // eslint-disable-next-line global-require
    //require('search-ner-ui/dist/js/app');
    next();
  },
};
</script>
