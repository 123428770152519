/**
 * Plugin for registering presentation component
 */
import Vue from 'vue';

// import Input from 'taiger-design-system/src/elements/Input.vue';
// import Dropdown from 'taiger-design-system/src/elements/Dropdown.vue';
// import Tooltip from 'taiger-design-system/src/elements/Tooltip.vue';
// import Button from 'taiger-design-system/src/elements/Button.vue';
// import Checkbox from 'taiger-design-system/src/elements/Checkbox.vue';
// import Badge from 'taiger-design-system/src/elements/Badge.vue';
// import RadioButton from 'taiger-design-system/src/elements/RadioButton.vue';
import BadgeDropdown from '@/components/badge-dropdown/BadgeDropdown.vue';
// import Autocomplete from 'taiger-design-system/src/elements/Autocomplete.vue';

// copied from TDS for icon integration
import Pagination from '@/components/Pagination.vue';
import Modal from '@/components/Modal.vue';
import TopNavigation from '@/components/TopNavigation.vue';
import ToggleButton from '@/components/toggle-button/ToggleButton.vue';
import SimplePagination from '@/components/SimplePagination.vue';

//  reverse-sourcemap
import Input from '../components/taiger-design-system/src/elements/Input.vue';
import Dropdown from '../components/taiger-design-system/src/elements/Dropdown.vue';
import Tooltip from '../components/taiger-design-system/src/elements/Tooltip.vue';
import Button from '../components/taiger-design-system/src/elements/Button.vue';
import Checkbox from '../components/taiger-design-system/src/elements/Checkbox.vue';
import Badge from '../components/taiger-design-system/src/elements/Badge.vue';
import RadioButton from '../components/taiger-design-system/src/elements/RadioButton.vue';
import Autocomplete from '../components/taiger-design-system/src/elements/Autocomplete.vue';

const ComponentsPlugin = {
  install () {
    Vue.component('BadgeDropdown', BadgeDropdown);
    Vue.component('Dropdown', Dropdown);
    Vue.component('Input', Input);
    Vue.component('ToggleButton', ToggleButton);
    Vue.component('Tooltip', Tooltip);
    Vue.component('Button', Button);
    Vue.component('Pagination', Pagination);
    Vue.component('Modal', Modal);
    Vue.component('TopNavigation', TopNavigation);
    Vue.component('SimplePagination', SimplePagination);
    Vue.component('Checkbox', Checkbox);
    Vue.component('Badge', Badge);
    Vue.component('Autocomplete', Autocomplete);
    Vue.component('RadioButton', RadioButton);
  },
};

export default ComponentsPlugin;
