import moment from 'moment';
import constant from '../constants/search-service-constants';

const { PROMOTED_DATE_RANGE_MODE, PROMOTED_CONTENT_FIELDS } = constant;

const MAX_DATE = new Date(2999, 0);
const MIN_DATE = new Date(0);

export function getStartOfDay (dateObj) {
  return dateObj.startOf('day');
}

export function getEndOfDay (dateObj) {
  return dateObj.endOf('day');
}

export function formatDate (dateObj) {
  return moment(dateObj).format('DD MMM YYYY');
}

export function formatDateUTC (dateObj) {
  return moment.utc(dateObj).format('DD MMM YYYY');
}

export function getDateBefore (dayCount) {
  return moment.utc().subtract(dayCount, 'days');
}

export function getMonthBefore (monthCount) {
  return moment.utc().subtract(monthCount, 'month');
}

/**
  * @return {import('@typings/search').DateRangeItem}
  */
export function getTodayDateRange (field) {
  const dateRangeItem = {
    field,
    start: getStartOfDay(moment.utc()),
    end: getEndOfDay(moment.utc()),
  };
  return dateRangeItem;
}

/**
  * @return {import('@typings/search').DateRangeItem}
  */
function getYesterdayDateRange (field) {
  const dateRangeItem = {
    field,
    start: getStartOfDay(getDateBefore(1)),
    end: getEndOfDay(getDateBefore(1)),
  };
  return dateRangeItem;
}

/**
  * @return {import('@typings/search').DateRangeItem}
  */
function getLastSevenDayDateRange (field) {
  const dateRangeItem = {
    field,
    start: getStartOfDay(getDateBefore(6)),
    end: getEndOfDay(moment.utc()),
  };
  return dateRangeItem;
}

/**
  * @return {import('@typings/search').DateRangeItem}
  */
function getLastThirtyDayDateRange (field) {
  const dateRangeItem = {
    field,
    start: getStartOfDay(getDateBefore(30)),
    end: getEndOfDay(moment.utc()),
  };
  return dateRangeItem;
}

/**
  * @return {import('@typings/search').DateRangeItem}
  */
function getThisMonthDateRange (field) {
  const dateRangeItem = {
    field,
    start: getStartOfDay(moment.utc().startOf('month')),
    end: getEndOfDay(moment.utc().endOf('month')),
  };
  return dateRangeItem;
}

/**
  * @return {import('@typings/search').DateRangeItem}
  */
function getLastMonthDateRange (field) {
  const dateRangeItem = {
    field,
    start: getStartOfDay(getMonthBefore(1).startOf('month')),
    end: getEndOfDay(getMonthBefore(1).endOf('month')),
  };
  return dateRangeItem;
}

export function getDateRangeByRangeMode (rangeMode) {
  const dateRange = [];
  const field = PROMOTED_CONTENT_FIELDS.FILTER;

  switch (rangeMode) {
    case PROMOTED_DATE_RANGE_MODE.TODAY:
      return getTodayDateRange(field);

    case PROMOTED_DATE_RANGE_MODE.YESTERDAY:
      return getYesterdayDateRange(field);

    case PROMOTED_DATE_RANGE_MODE.LAST_SEVEN_DAYS:
      return getLastSevenDayDateRange(field);
    case PROMOTED_DATE_RANGE_MODE.LAST_THIRTY_DAYS:
      return getLastThirtyDayDateRange(field);
    case PROMOTED_DATE_RANGE_MODE.THIS_MONTH:
      return getThisMonthDateRange(field);
    case PROMOTED_DATE_RANGE_MODE.LAST_MONTH:
      return getLastMonthDateRange(field);
    default:
      break;
  }

  return dateRange;
}

export function formatDateRangeString (startDate, endDate) {
  if (startDate !== endDate) {
    return `${startDate} - ${endDate}`;
  }

  return startDate;
}

export function getPromoteDateRangeLabel (dateRangeMode, startDate, endDate) {
  if (dateRangeMode === '') {
    return constant.PROMOTED_CONTENT_FIELDS.ALL_PROMOTED_CONTENT;
  }

  let formattedStartDate = null;
  let formattedEndDate = null;

  if (dateRangeMode !== PROMOTED_DATE_RANGE_MODE.CUSTOM_RANGE) {
    const dateRange = getDateRangeByRangeMode(dateRangeMode);

    formattedStartDate = formatDateUTC(dateRange.start);
    formattedEndDate = formatDateUTC(dateRange.end);
  } else {
    formattedStartDate = formatDateUTC(startDate);
    formattedEndDate = formatDateUTC(endDate);
  }

  return formatDateRangeString(formattedStartDate, formattedEndDate);
}

export function formatDateRangeObject (startDate, endDate, field) {
  const dateRangeItem = {
    field,
    start: getStartOfDay(moment.utc(startDate)),
    end: getEndOfDay(moment.utc(endDate)),
  };
  return dateRangeItem;
}

export function getPromotionStatus (startDate, endDate) {
  let isOngoing = moment.utc().isSame(moment.utc(startDate), 'day');
  const isUpcoming = moment.utc().isBefore(moment.utc(startDate), 'day');
  let isExpired = false;

  if (!isOngoing && !isUpcoming && endDate && endDate !== undefined) {
    isExpired = moment.utc().isAfter(moment.utc(endDate), 'day');
    isOngoing = !isExpired;
  }

  if (isOngoing) {
    return constant.PROMOTION_STATUS.ONGOING;
  } if (isUpcoming) {
    return constant.PROMOTION_STATUS.UPCOMING;
  }
  return constant.PROMOTION_STATUS.EXPIRED;
}

export function getMinDate () {
  return getStartOfDay(moment.utc(MIN_DATE)).toISOString();
}

export function getMaxDate () {
  return getEndOfDay(moment.utc(MAX_DATE)).toISOString();
}

export function isPermanentPromote (startDate, endDate) {
  return startDate === getMinDate() && endDate === getMaxDate();
}

export function getDefaultDateRange (startDate, endDate) {
  if (isPermanentPromote(startDate, endDate)) {
    const today = formatDateUTC(moment.utc());
    return [today, today];
  }
  return [formatDateUTC(startDate), formatDateUTC(endDate)];
}
