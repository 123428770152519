<template>
  <div class="t-dropdown">
    <div v-if="label" class="label">{{ label }}</div>
    <input :value="value" v-on:input="inputEvent(value)" v-on:change="changeEvent(value)" />
    <b-dropdown
      ref="dropdown"
      v-bind="$props"
      :disabled="disabled"
      :class="[hasSelected, blockClass, state]"
      :text="selected ? selected.text : placeholder"
    >
      <template v-for="option in options">
        <b-dropdown-item
          v-if="!option.disabled"
          :key="option.value"
          @click="selectOption(option)"
          >{{ option.text }}</b-dropdown-item
        >
        <li class="disabled" v-else :key="option.value">
          <span class="dropdown-item">{{ option.text }}</span>
        </li>
      </template>
      <b-dropdown-item link-class="action" v-if="dropdownItemAction">
        <slot name="dropdown-item-action"></slot>
      </b-dropdown-item>
    </b-dropdown>
    <div
      v-if="invalidFeedback"
      class="invalid-message text-sm mt-xxs"
      v-dompurify-html="invalidFeedback"
    ></div>
    <div v-if="validFeedback" class="valid-message text-sm mt-xxs" v-dompurify-html="validFeedback"></div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';

const ANY = [String, Number, Boolean, Array, Object, Date, Function];
/**
 * Dropdown component.
 */
export default {
  name: 'Dropdown',
  status: 'release',
  components: {
    BDropdown,
    BDropdownItem,
  },
  data () {
    return {
      selected: this.value
        ? this.options.find((v) => {
          let value;
          if (typeof this.value === 'object') {
            // eslint-disable-next-line prefer-destructuring
            value = this.value.value;
          } else {
            // eslint-disable-next-line prefer-destructuring
            value = this.value;
          }
          return v.value === value;
        })
        : this.placeholderObject,
      placeholderObject: { value: null, text: this.placeholder },
    };
  },
  props: {
    /**
     *
     * Return value as object
     */
    returnObject: {
      type: Boolean,
    },
    /**
     *
     * Renders a 100% width dropdown (expands to the width of it's parent container)
     */
    block: {
      type: Boolean,
    },
    /**
     *
     * Controls the validation state appearance of the component. possible values are valid, invalid', and 'null' for no validation state
     */
    state: {
      type: String,
      default: null,
    },
    /**
     *
     * Placeholder text
     */
    placeholder: {
      type: String,
      default: 'Please select an option',
    },
    /**
     *
     * Invalid feedback
     */
    invalidFeedback: {
      type: String,
    },
    /**
     *
     * Valid feedback
     */
    validFeedback: {
      type: String,
    },
    /**
     *
     * When set to 'true', disables the component's functionality and places it in a disabled state
     */
    disabled: {
      type: Boolean,
    },
    /**
     *
     * Text to place in the label/legend of the form group
     */
    label: {
      type: String,
    },
    /**
     *
     */
    value: {
      type: ANY,
    },
    /**
     *
     * Array of items to render in the component
     */
    options: {
      type: [Array, Object],
      default: () => [],
    },
  },
  methods: {
    closeDropdown () {
      this.$refs.dropdown.hide();
    },
    showDropdown () {
      this.$refs.dropdown.show();
    },
    selectOption (option) {
      this.selected = option;
      if (this.returnObject) {
        this.inputEvent(option);
        this.changeEvent(option);
      } else {
        this.inputEvent(option.value);
        this.changeEvent(option.value);
      }
    },
    inputEvent (value) {
      /**
       * Input event triggered by user interaction. Emitted after any formatting (not including 'trim' or 'number' props) and after the v-model is updated
       *
       * @event input
       * @property {object} value - Current value of input
       * @type {function}
       */
      this.$emit('input', value);
    },
    changeEvent (value) {
      /**
       * Change event triggered by user interaction. Emitted after any formatting (not including 'trim' or 'number' props) and after the v-model is updated.
       *
       * @event change
       * @property {object} value - Current value of input
       * @type {function}
       */
      this.$emit('change', value);
    },
  },
  watch: {
    options: {
      deep: true,
      handler (options) {
        if (this.selected) {
          const foundOption = options.find(option => option.value === this.selected.value);
          this.selected = foundOption;
        }
      },
    },
    value (newVal) {
      this.selected = this.options.find((option) => {
        let value;
        if (newVal && typeof newVal === 'object') {
          // eslint-disable-next-line prefer-destructuring
          value = newVal.value;
        } else {
          value = newVal;
        }
        return option.value === value;
      });
    },
  },
  computed: {
    dropdownItemAction () {
      return this.$slots['dropdown-item-action'];
    },
    hasSelected () {
      return this.selected && this.selected.value ? 'selected' : '';
    },
    blockClass () {
      return this.block ? 'block' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin button {
  color: $blue-gray-200;
  background-color: $white-500;
  border-color: $blue-gray-100;
  box-shadow: none;
}

@mixin text-md-14 {
  font-size: $font-md;
  line-height: $ln-height-20;
  font-weight: $weight_normal;
}

@mixin label-md-14 {
  font-size: $font-md;
  line-height: $ln-height-20;
  font-weight: $weight_semi_bold;
}

.t-dropdown /deep/ {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  input {
    display: none;
  }
  .show > .btn-secondary.dropdown-toggle {
    @include button;
  }
  .selected {
    .btn.dropdown-toggle {
      color: $blue-gray-400 !important;
    }
  }
  .label {
    @include label-md-14;
    color: $blue-gray-400;
    padding-bottom: $gap-xxs;
  }
  .b-dropdown:not(.block) {
    .dropdown-menu.show {
      min-width: 280px;
    }
  }
  .b-dropdown {
    &.valid {
      .btn.dropdown-toggle {
        border: 1px solid $green-500 !important;
      }
    }
    &.invalid {
      .btn.dropdown-toggle {
        border: 1px solid $red-500 !important;
      }
    }
  }
  .invalid-message {
    color: $red-500;
  }
  .valid-message {
    color: $green-500;
  }
  .btn.dropdown-toggle {
    @include text-md-14;
    color: $blue-gray-200;
    text-align: left;
    padding: $gap-xs;
    height: 36px;
    background: $white-500;
    border-color: $blue-gray-100;
    &.text {
      color: $blue-500;
    }
    &.disabled {
      cursor: default;
      background-color: $blue-gray-100;
      color: $blue-gray-200;
    }
    &:not(.btn-block) {
      min-width: 280px;
    }
    &.btn-secondary:not(:disabled):not(.disabled) {
      &:active,
      &.active,
      &:hover,
      &.hover,
      &:focus {
        @include button;
      }
    }

    &:after {
      position: absolute;
      right: 8px;
      top: 14px;
    }
  }
  .dropdown-menu.show {
    width: 100%;
    background-color: $white-500;
    border: 1px solid $blue-gray-100;
    padding: $gap-xxs;
    margin-top: $gap-xxs;
    li {
      margin-bottom: $gap-xxs;
      &:last-child {
        margin-bottom: 0;
      }
      &:not(.disabled) {
        .dropdown-item {
          &:focus,
          &:hover {
            background-color: $blue-200;
            color: $blue-500;
          }
        }
      }
      &.disabled {
        .dropdown-item {
          cursor: default;
          background-color: $blue-gray-100;
          color: $blue-gray-200;
        }
      }
    }
    .dropdown-item {
      @include text-md-14;
      color: $blue-gray-400;
      border-radius: $radius_4;
      padding: $gap-xxs $gap-xs;
      height: 28px;
      &.action {
        cursor: pointer;
        color: $blue-500;
      }
    }
  }
}
</style>

<docs>
  ```jsx
  <div>
    <div class="mt-lg">
      <Dropdown
        label="Custom Dropdown Item"
        v-bind:options="
          [
            { value: null, text: 'Please select an option' },
            { value: 1, text: 'First Option' },
            { value: 2, text: 'Second Option' },
            { value: 3, text: 'Third Option' }
          ]
        ">
          <template v-slot:dropdown-item-action>
            <div>
              <i class="far fa fa-plus"></i>
              <span>New State</span>
            </div>
          </template>
        </Dropdown>
    </div>
    <div class="mt-lg">
      <Dropdown
        label="Valid Dropdown"
        state="valid"
        valid-feedback="This is a valid message"
        v-bind:options="
          [
            { value: null, text: 'Please select an option' },
            { value: 1, text: 'First Option' },
            { value: 2, text: 'Second Option' },
            { value: 3, text: 'Third Option', disabled: true }
          ]
        "></Dropdown>
    </div>
    <div class="mt-lg">
      <Dropdown
        label="Invalid Dropdown"
        state="invalid"
        invalid-feedback="Please select an item"
        v-bind:options="
          [
            { value: null, text: 'Please select an option' },
            { value: 1, text: 'First Option' },
            { value: 2, text: 'Second Option' },
            { value: 3, text: 'Third Option', disabled: true }
          ]
        "></Dropdown>
    </div>
    <div class="mt-lg">
      <Dropdown
        label="Dropdown Item Disabled"
        v-bind:options="
          [
            { value: null, text: 'Please select an option' },
            { value: 1, text: 'First Option' },
            { value: 2, text: 'Second Option' },
            { value: 3, text: 'Third Option', disabled: true }
          ]
        "></Dropdown>
    </div>
    <div class="mt-lg">
      <Dropdown label="Normal Dropdown"
      v-bind:options="
      [
        { value: null, text: 'Please select an option' },
        { value: 1, text: 'First Option' },
        { value: 2, text: 'Second Option' }]"></Dropdown>
    </div>
    <div class="mt-lg">
      <Dropdown
      block
      label="Dropdown Full Width"
      v-bind:options="
      [
        { value: null, text: 'Please select an option' },
        { value: 1, text: 'First Option' },
        { value: 2, text: 'Second Option' }]"></Dropdown>
    </div>
    <div class="mt-lg">
      <Dropdown
      disabled
      label="Disabled Dropdown"
      v-bind:options="
      [
        { value: null, text: 'Please select an option' },
        { value: 1, text: 'First Option' },
        { value: 2, text: 'Second Option' }]"></Dropdown>
    </div>
    <div class="mt-lg">
      <Dropdown
      label="Custom Placeholder Text"
      placeholder="Custom placeholder text"
      v-bind:options="[
        { value: 1, text: 'First Option' },
        { value: 2, text: 'Second Option' }]"></Dropdown>
    </div>
  </div>
  ```
</docs>
