import { createBadgeDropdownGroups } from '../applied-filters';

const aggregationConfigs = {
  caa: {
    displayName: 'CAA',
    field: 'caa',
    showInCloud: false,
    displayed: true,
  },
  sectors: {
    displayName: 'Sectors',
    field: 'sectors',
    showInCloud: true,
    displayed: true,
  },
};

describe('createBadgeDropdownGroups', () => {
  it('should return group of query', () => {
    const topics = {
      must: [
        { field: 'caa', value: 'CASA' },
        { field: 'caa', value: 'CAA' },
        { field: 'sectors', value: 'Airborne' },
      ],
    };

    const result = createBadgeDropdownGroups(topics, aggregationConfigs);
    expect(result).toEqual([
      {
        key: 'caa',
        name: 'CAA',
        items: [
          { field: 'caa', value: 'CASA' },
          { field: 'caa', value: 'CAA' },
        ],
      },
      {
        key: 'sectors',
        name: 'Sectors',
        items: [
          { field: 'sectors', value: 'Airborne' },
        ],
      },
    ]);
  });

  it('should exclude topics that are not in config', () => {
    const topics = {
      must: [
        { field: 'caa', value: 'CASA' },
        { field: 'caa', value: 'CAA' },
        { field: 'none', value: 'none' },
      ],
    };

    const result = createBadgeDropdownGroups(topics, aggregationConfigs);
    expect(result).toEqual([
      {
        key: 'caa',
        name: 'CAA',
        items: [
          { field: 'caa', value: 'CASA' },
          { field: 'caa', value: 'CAA' },
        ],
      },
    ]);
  });
});
