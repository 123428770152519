<template>
  <div id="config-page">
    <div class="header">
      <h1 class="title">Search Configuration</h1>
      <div v-if="isAdminUser" class="button-wrapper">
        <b-button
          variant="link"
          :disabled="!activeSearchIndexChanged"
          @click="resetSelectedSearchIndex"
        >Cancel</b-button>
        <b-button
          variant="primary"
          :disabled="!activeSearchIndexChanged"
          @click="saveSelectedSearchIndex"
        >Save</b-button>
      </div>
    </div>
    <div class="mt-lg">
      <RadioButton
        label="Index selected for Search"
        name="inline"
        :disabled="!isAdminUser"
        :options="searchIndexOptions"
        v-model="selectedSearchIndex"
      ></RadioButton>
    </div>
    <notifications></notifications>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Notifications from '@/modules/notifications/Notifications';

export default {
  name: 'config-page',
  components: {
    Notifications,
  },
  data () {
    return {
      selectedSearchIndex: null,
    };
  },
  computed: {
    ...mapGetters('searchAdmin', ['searchIndexes', 'activeSearchIndex']),
    ...mapGetters('authService', ['isAdminUser']),

    searchIndexOptions () {
      return this.searchIndexes.map(index => ({
        text: index,
        value: index,
      }));
    },
    activeSearchIndexChanged () {
      return this.selectedSearchIndex !== this.activeSearchIndex;
    },
  },
  methods: {
    ...mapActions('searchAdmin', [
      'getSearchIndexes',
      'getActiveSearchIndex',
      'saveActiveSearchIndex',
    ]),
    async initSearchIndex () {
      await this.getSearchIndexes();
      await this.getActiveSearchIndex();
      this.selectedSearchIndex = this.activeSearchIndex;
    },
    resetSelectedSearchIndex () {
      this.selectedSearchIndex = this.activeSearchIndex;
    },
    saveSelectedSearchIndex () {
      this.saveActiveSearchIndex(this.selectedSearchIndex);
    },
  },
  mounted () {
    this.initSearchIndex();
  },
};
</script>

<style lang="scss" scoped>
#config-page {
  width: 750px;
  margin: $gap-xl auto;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      font-weight: $weight-semi-bold;
      font-size: 24px;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
