<template>
  <div class="icrs-search-bar-wrapper">
    <!-- For scroll responsive fixed search bar concept -->
    <div
      v-if="!isLoading"
      v-show="showSearchBar"
      :style="{top: searchBarTop, zIndex: searchBarZ}"
      class="icrs-results-search-bar hide-for-printing"
    >
      <ICRSSearchBarWrapper
        guidedDisplayOption="results"
        :showMoreSearchOptions="showMoreSearchOptions"
        :showScrollToTop="showScrollToTop"
        :bodyPartsResponse="bodyPartsResponse"
        @setOpenSearchOptions="setOpenSearchOptions"
      />
    </div>
    <div v-if="!isLoading" class="container container-fluid icrs-search-results"
      :class="{'more-options-active': isOpenSearchOptions}"
    >
      <div class="row icrs-search-results-row">
        <div
          v-show="!isSearchSuccess"
          class="col-sm-12 icrs-component hide-for-printing"
        >
          <div class="card">
            <div
              class="card-body"
              style="text-align: center"
            >
              <LoadingSpinner
                text="Loading search results..."
                size="lg"
              />
            </div>
          </div>
        </div>
        <div
          v-show="isSearchSuccess"
          class="col-sm-12 col-md-3 icrs-component icrs-filter-padding hide-for-printing"
        >
          <div class="card icrs-search-within-card">
            <div
              class="card-header icrs-filter-card-header"
              @click="toggleSuggestions"
            >
              <font-awesome-icon
                :icon="['fal', 'lightbulb-on']"
                size="lg"
              />&nbsp;&nbsp;<strong>Suggestions</strong>
              <i
                class="icrs-filter-icon fa"
                :class="{
                  'fa-chevron-up': isSearchSuggestionsOpened,
                  'fa-chevron-down': !isSearchSuggestionsOpened,
                }"
              ></i>
            </div>
            <b-collapse
              :visible="isSearchSuggestionsOpened"
              :id="icrsSearchSuggestionsId"
            >
              <div class="card-body">
                <font-awesome-icon
                  v-if="isSearching"
                  class="icon"
                  :icon="['far', 'spinner-third']"
                  size="lg"
                  style="animation: spin 500ms ease-in forwards infinite;"
                />
                <button
                  v-if="!isSearching && didYouMeanLength > 0"
                  type="button"
                  class="btn btn-did-you-mean btn-block"
                  @click="openSuggestionsModal(true, false, false)"
                >Did You Mean?</button>
                <button
                  v-if="!isSearching"
                  type="button"
                  class="btn btn-body-proximity btn-block"
                  @click="openSuggestionsModal(false, true, false)"
                >{{bodyProximityLength}} Body Proximity Terms</button>
                <button
                  v-if="!isSearching"
                  type="button"
                  class="btn btn-related-medical btn-block"
                  @click="openSuggestionsModal(false, false, true)"
                >{{relatedMedicalLength}} Related Medical Terms</button>
              </div>
            </b-collapse>
            <ICRSSearchSuggestions
              modalId='leftPanelSuggestions'
              :parentShowDidYouMean="showDidYouMean"
              :parentShowBodyProximity="showBodyProximity"
              :parentShowRelatedMedical="showRelatedMedical"
              @setDidYouMeanLength="setDidYouMeanLength"
              @setBodyProximityLength="setBodyProximityLength"
              @setRelatedMedicalLength="setRelatedMedicalLength"
              @setParentShow="setParentShow"
              :bodyPartsResponse="bodyPartsResponse"
            />
          </div>
          <div class="card icrs-search-within-card">
            <div
              class="card-header icrs-filter-card-header"
              @click="toggleWithin"
            >
              <font-awesome-icon
                :icon="['fal', 'search']"
                size="lg"
              />&nbsp;&nbsp;<strong>Search Within</strong>
              <i
                class="icrs-filter-icon fa"
                :class="{
                  'fa-chevron-up': isSearchWithinOpened,
                  'fa-chevron-down': !isSearchWithinOpened,
                }"
              ></i>
            </div>
            <b-collapse
              :visible="isSearchWithinOpened"
              :id="icrsSearchWithinId"
            >
              <div class="card-body">
                <SearchWithin />
              </div>
            </b-collapse>
          </div>
          <div class="card">
            <div
              class="card-header icrs-filter-card-header"
              @click="toggleFilter"
            >
              <font-awesome-icon
                :icon="['fal', 'sliders-h']"
                size="lg"
              />&nbsp;&nbsp;<strong>Filters</strong>
              <i
                class="icrs-filter-icon fa"
                :class="{
                  'fa-chevron-up': isSearchFilterOpened,
                  'fa-chevron-down': !isSearchFilterOpened,
                }"
              ></i>
            </div>
            <b-collapse
              :visible="isSearchFilterOpened"
              :id="icrsSearchFilterId"
            >
              <div class="card-body">
                <SearchFilter />
              </div>
            </b-collapse>
          </div>
        </div>
        <div
          v-show="isSearchSuccess"
          class="col-sm-12 col-md-9 icrs-component main-results-section"
        >
          <div class="card">
            <div v-if="spellCorrectedQueries.corrections.length > 0" class="card-header keep-origin-search">
              <div class="result-row">
                <div class="instead-result">
                  Search instead for
                </div>
                <div class="suggested-query-text"  @click="clickInstead">
                  {{ spellCorrectedQueries.suggestedQueryText}}
                </div>
                <div class="instead-result">
                  ?
                </div>
              </div>
            </div>
            <div class="card-header">
              <AppliedFilters :filterCountDisabled="true" />
            </div>
            <div class="card-header paddiing-0-card-header-for-print">
              <font-awesome-icon
                v-if="icrsPlotSearching || isSearching"
                class="icon"
                :icon="['far', 'spinner-third']"
                size="lg"
                style="animation: spin 500ms ease-in forwards infinite;"
              />
              <b-modal
                id="current-query-help"
                size="xs"
                :centered="true"
                title="Current Search Query Help"
                :hide-footer="true"
                header-close-content="<i class='fa fa-times-circle' />"
              >
                <!--eslint-disable-next-line max-len-->
                The <strong>Current Search Query</strong> is the query that is processed by the ICRS search engine to produce your search results. Copying this query into the free text search bar at the top produces exactly the same search results as your current search.
              </b-modal>
              <p
                v-if="!icrsPlotSearching && !isSearching"
                class="d-inline-block mr-2"
                style="margin-bottom: 0"
              >
                <strong>Current Search Query:&nbsp;</strong>
                <i
                  aria-label="Current Search Query Help"
                  class="fa fa-info-circle  hide-for-printing tab-focus"
                  style="cursor: pointer;"
                  v-b-modal="'current-query-help'"
                ></i>
                <span class="hide-for-printing">&nbsp;</span>
                <span>{{currentQuery}}</span>
              </p>
              <br />
              <b-modal
                id="injury-trail-help"
                size="xs"
                :centered="true"
                title="Nature Of Injury Help"
                :hide-footer="true"
                header-close-content="<i class='fa fa-times-circle' />"
              >
                Clicking on a term in the <strong>Nature Of Injury</strong> triggers a new search for the selected term.
              </b-modal>
              <p
                v-if="!icrsPlotSearching && !isSearching && guidedSearch && selectedLevel1.code !== 'All'"
                class="d-inline-block mr-2"
                style="margin-bottom: 0"
              >
                <strong>Nature Of Injury:</strong>
                <span class="hide-for-printing">&nbsp;</span>
                <i
                  aria-label="Nature Of Injury Help"
                  class="fa fa-info-circle  hide-for-printing tab-focus"
                  style="cursor: pointer;"
                  v-b-modal="'injury-trail-help'"
                ></i>
                <span class="hide-for-printing">&nbsp;</span>
                <span
                  class="injury-trail-text"
                  @click="performNatureInjurySearch(1,currentSelectedLevel1.code)"
                >
                  {{ formatGuidedDisplay(currentSelectedLevel1.elitLabel) }}
                </span>
                <span
                  v-if="currentSelectedLevel2.code !== 'All'"
                  class="injury-trail-text"
                  @click="performNatureInjurySearch(2,currentSelectedLevel2.code)"
                >
                  &nbsp;>&nbsp;
                  {{ formatGuidedDisplay(currentSelectedLevel2.elitLabel) }}
                </span>
                <span
                  v-if="currentSelectedLevel3.code !== 'All'"
                  class="injury-trail-text"
                  @click="performNatureInjurySearch(3,currentSelectedLevel3.code)"
                >
                  &nbsp;>&nbsp;
                  {{ formatGuidedDisplay(currentSelectedLevel3.elitLabel) }}
                </span>
              </p>
            </div>
            <div class="card-body">
              <div class="horizontal-boxed-tab has-icon">
                <div class="btn-group btn-block hide-for-printing">
                  <button
                    class="btn"
                    :class="{'btn-results-tab-active': showOverview, 'btn-results-tab-inactive': !showOverview}"
                    style="border-bottom-left-radius: 0;"
                    @click="showOverview = true; showListOfCases = false; setSearchShowOverview(true)"
                  >
                    <i class="fas fa-chart-area"></i> Overview
                  </button>
                  <button
                    class="btn"
                    :class="{'btn-results-tab-active': showListOfCases, 'btn-results-tab-inactive': !showListOfCases}"
                    style="border-bottom-right-radius: 0;"
                    @click="showOverview = false; showListOfCases = true; setSearchShowOverview(false)"
                  ><i class="fas fa-list"></i> List of Cases</button>
                </div>

                <!-- Tab panes -->
                <div class="tab-content">
                  <div
                    class="tab-pane"
                    :class="{active:showOverview}"
                  >
                    <ICRSCharts
                      :currentQuery="currentQuery"
                      :showInjuries="showInjuries"
                    />
                  </div>
                  <div
                    class="tab-pane"
                    :class="{active:showListOfCases}"
                  >
                    <SearchResults
                      :currentQuery="currentQuery"
                      :icrsGlobalCount="icrsGlobalCount"
                      :showInjuries="showInjuries"
                      :icrsScatterPlotCaseIds="icrsScatterPlotCaseIds"
                      :icrsCaseTotal="icrsCaseTotal"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="{'page-loading': isLoading}">
      <div
        v-if="isLoading"
        class="d-flex align-items-center justify-content-center"
        style="margin: 200px 0px 100px 0px">
        <LoadingSpinner size="lg" />
      </div>
      <ICRSFooter />
    </div>
    <notifications></notifications>
    <loading-page></loading-page>
  </div>
</template>

<script>
// https://vuejs.org/v2/guide/components.html#Dynamic-Components
// search bar **required
// results container ** required
// └── result card **dynamic
// filters **optional
import Qs from 'qs';
import { mapGetters, mapActions } from 'vuex';
import ICRSService from '@/services/icrs-service';
import ICRSSearchSuggestions from '@/modules/icrs-search-suggestions/ICRSSearchSuggestions.vue';
// import ICRSSaveSearch from '@/modules/icrs-save-search/ICRSSaveSearch.vue';
import ICRSSearchBarWrapper from '@/modules/icrs-search-bar-wrapper/ICRSSearchBarWrapper.vue';
import SearchFilter from '@/modules/search-filter/SearchFilter.vue';
import SearchResults from '@/modules/search-results/SearchResults.vue';
import Notifications from '@/modules/notifications/Notifications.vue';
import LoadingPage from '@/modules/loading-page/LoadingPage.vue';
import AppliedFilters from '@/modules/applied-filters/AppliedFilters.vue';
import SearchWithin from '@/modules/search-within/SearchWithin.vue';
import ICRSCharts from '@/modules/icrs-charts/ICRSCharts.vue';
import ICRSFooter from '@/modules/icrs-footer/ICRSFooter.vue';
import icrsService from '@/services/icrs-service/icrs-service';
import LoadingSpinner from '../../components/LoadingSpinner.vue';


export default {
  name: 'IcrsSearch',
  data () {
    return {
      searchBarTop: '70px',
      searchBarZ: 500,
      showSearchBar: true,
      lastScrollPosition: 0,
      showMoreSearchOptions: true,
      showScrollToTop: false,
      icrsSearchFilterId: 'icrs_search_filter',
      isSearchFilterOpened: true,
      icrsSearchWithinId: 'icrs_search_within',
      isSearchWithinOpened: true,
      icrsSearchSuggestionsId: 'icrs_search_suggestions',
      isSearchSuggestionsOpened: true,
      showOverview: true,
      showListOfCases: false,
      icrsGlobalCount: -1,
      newCurrentQuery: false,
      showInjuries: false,
      icrsScatterPlotCaseIds: [],
      icrsCaseTotal: 0,
      didYouMeanLength: 0,
      bodyProximityLength: 0,
      relatedMedicalLength: 0,
      showDidYouMean: false,
      showBodyProximity: false,
      showRelatedMedical: false,
      showInsteadPart: false,
      guidedPrefixIgnoreList: [],
      currentSelectedLevel1: '',
      currentSelectedLevel2: '',
      currentSelectedLevel3: '',
      bodyPartsResponse: {},
      isLoading: true,
      isOpenSearchOptions: true,
    };
  },
  components: {
    SearchResults,
    SearchFilter,
    ICRSSearchSuggestions,
    // ICRSSaveSearch,
    ICRSSearchBarWrapper,
    Notifications,
    LoadingPage,
    AppliedFilters,
    SearchWithin,
    ICRSCharts,
    ICRSFooter,
    LoadingSpinner,
  },
  computed: {
    ...mapGetters('searchService', [
      'isSearchSuccess',
      'currentQuery',
      'selectedSource',
      'appliedSort',
      'itemsPerPage',
      'currentPage',
      'icrsPlotSearching',
      'icrsPlotData',
      'currentSearchResults',
      'isSearching',
      'spellCorrectedQueries',
      'isSavedSearchFromHomePage',
    ]),

    ...mapGetters({
      isAdminUser: 'authService/isAdminUser',
    }),

    ...mapGetters('icrs', [
      'guidedSearch',
      'selectedLevel1',
      'selectedLevel2',
      'selectedLevel3',
      'allGuidedCategories',
    ]),

    ...mapGetters('advancedSearch', [
      'isShowOverview',
    ]),

    searchParameters ({
      currentQuery,
      selectedSource,
      appliedSort,
      itemsPerPage,
      currentPage,
    }) {
      return {
        currentQuery,
        selectedSource,
        appliedSort,
        itemsPerPage,
        currentPage,
      };
    },
  },
  watch: {
    searchParameters ({
      currentQuery,
      selectedSource,
      appliedSort,
      itemsPerPage,
      currentPage,
    }) {
      if (this.isShowOverview) {
        this.showOverview = true;
        this.showListOfCases = false;
      }

      const encodedSortBy = Qs.stringify(appliedSort);
      const offset = currentPage;

      const urlParams = {
        query: currentQuery,
        source: selectedSource,
        sortBy: encodedSortBy,
        size: itemsPerPage,
        from: offset,
      };

      this.$router.replace({ path: '/icrs-search', query: urlParams }).catch(() => { });

      this.currentSelectedLevel1 = this.selectedLevel1;
      this.currentSelectedLevel2 = this.selectedLevel2;
      this.currentSelectedLevel3 = this.selectedLevel3;
    },
    isSearchFilterOpened () {
      this.$root.$emit(`bv::collapse::${this.isSearchFilterOpened}`, this.icrsSearchFilterId);
    },
    isSearchWithinOpened () {
      this.$root.$emit(`bv::collapse::${this.isSearchWithinOpened}`, this.icrsSearchWithinId);
    },
    icrsPlotSearching: {
      immediate: true,
      handler (value) {
        if (!value) {
          if (Object.keys(this.icrsPlotData).length !== 0) {
            this.icrsGlobalCount = new Set([
              ...this.icrsPlotData.global.id,
            ]).size;
            this.displayInjuryCount = this.icrsPlotData.nonGlobal.id.length + this.icrsPlotData.global.id.length;
            // Note: The array below may contain repeated IDs.
            this.icrsScatterPlotCaseIds = [...this.icrsPlotData.nonGlobal.id, ...this.icrsPlotData.global.id];
          } else {
            this.icrsGlobalCount = -1;
            this.displayInjuryCount = -1;
            this.icrsScatterPlotCaseIds = [];
          }

          if (this.newCurrentQuery) {
            if (this.displayInjuryCount === 0 || this.displayInjuryCount === -1) {
              if (this.isShowOverview) {
                this.showOverview = true;
                this.showListOfCases = false;
              } else {
                this.showOverview = false;
                this.showListOfCases = true;
              }
            } else {
              this.showOverview = true;
              this.showListOfCases = false;
            }
            this.newCurrentQuery = false;
          }

          this.icrsCaseTotal = this.currentSearchResults.total;

          // if (this.displayInjuryCount === 0 || this.displayInjuryCount === -1) {
          //   this.icrsCaseTotal = this.currentSearchResults.total;
          // } else {
          //   this.icrsCaseTotal = new Set(this.icrsScatterPlotCaseIds).size;
          // }

          if (this.currentQuery === undefined || this.currentQuery === null || this.currentQuery === '') {
            this.showInjuries = false;
          } else {
            this.showInjuries = this.displayInjuryCount > 0; // || this.currentQuery.includes('tags.level');
          }
        }
      },
    },
    currentQuery: {
      immediate: true,
      handler (value) {
        this.newCurrentQuery = true;

        if (value) {
          // const formattedTerm = value.includes('"') ? value.split('"')[1].split('"')[0] : value;
          ICRSService.execGetICRSBodyParts(value)
            .then((data) => {
              this.bodyPartsResponse = data || null;
            });
        }
      },
    },
    isShowOverview: {
      immediate: true,
      handler (value) {
        if (value) {
          this.showOverview = true;
          this.showListOfCases = false;
        } else {
          this.showOverview = false;
          this.showListOfCases = true;
        }
      },
    },
  },
  methods: {
    ...mapActions('searchService', [
      'searchForSavedSearch',
      'getSavedSearch',
      'triggerSearchQuery',
      'setSavedSearchFromHomePage',
    ]),

    ...mapActions('searchService/newResults', [
      'searchForNewDocuments',
      'resetNewSearchResults',
    ]),

    ...mapActions('icrs', [
      'setGuidedSearch',
      'setDropdownGuidedTag',
      'setActiveGuidedPrefix',
      'setSelectedLevel1',
      'setSelectedLevel2',
      'setSelectedLevel3',
      'setLevel2CategoriesForSelectedLevel1',
      'setLevel3CategoriesForSelectedLevel2',
      'setAllGuidedCategories',
      'setAdvancedSearchSelectedLevel',
    ]),

    ...mapActions('advancedSearch', [
      'setSearchShowOverview',
    ]),
    clickInstead () {
      this.triggerSearchQuery({
        query: this.spellCorrectedQueries.suggestedQueryText,
      });
    },
    setDidYouMeanLength (value) {
      this.didYouMeanLength = value;
    },
    setBodyProximityLength (value) {
      this.bodyProximityLength = value;
    },
    setRelatedMedicalLength (value) {
      this.relatedMedicalLength = value;
    },
    setOpenSearchOptions (value) {
      this.isOpenSearchOptions = value;
    },

    openSuggestionsModal (showDidYouMean, showBodyProximity, showRelatedMedical) {
      this.showDidYouMean = showDidYouMean;
      this.showBodyProximity = showBodyProximity;
      this.showRelatedMedical = showRelatedMedical;
      this.$bvModal.show('leftPanelSuggestions');
    },

    setParentShow () {
      this.showDidYouMean = false;
      this.showBodyProximity = false;
      this.showRelatedMedical = false;
    },

    closeModal (modalId) {
      this.$bvModal.hide(modalId);
      // this.$emit('hide');
    },

    toggleFilter () {
      this.isSearchFilterOpened = !this.isSearchFilterOpened;
    },
    toggleWithin () {
      this.isSearchWithinOpened = !this.isSearchWithinOpened;
    },
    toggleSuggestions () {
      this.isSearchSuggestionsOpened = !this.isSearchSuggestionsOpened;
    },
    getQueryCode (requestQuery) {
      const firstQuoteIndex = requestQuery.indexOf('"');
      const secondQuoteIndex = requestQuery.indexOf('"', firstQuoteIndex + 1);
      const queryCode = requestQuery.substring(firstQuoteIndex + 1, secondQuoteIndex);

      return queryCode;
    },
    handleSelectedLevel (query) {
      if (query.includes('tags.level3')) {
        const codeLevel3 = this.getQueryCode(query);

        const foundLevel3 = this.allGuidedCategories.level3.find(item => item.code === codeLevel3);
        const foundLevel2 = this.allGuidedCategories.level2.find(item => item.code === foundLevel3.parentCode);
        const foundLevel1 = this.allGuidedCategories.level1.find(item => item.code === foundLevel2.parentCode);

        this.setSelectedLevel1(foundLevel1);
        this.setSelectedLevel2(foundLevel2);
        this.setSelectedLevel3(foundLevel3);
        this.setLevel2CategoriesForSelectedLevel1(
          [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
        );
        this.setLevel3CategoriesForSelectedLevel2(
          [{ code: 'All' }, ...this.allGuidedCategories.level3.filter(item => item.parentCode === foundLevel2.code)],
        );

        this.setAdvancedSearchSelectedLevel({
          level1: foundLevel1,
          level2: foundLevel2,
          level3: foundLevel3,
          level2ForSelectedLevel1: [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
          level3ForSelectedLevel2: [{ code: 'All' }, ...this.allGuidedCategories.level3.filter(item => item.parentCode === foundLevel2.code)],
        });
      } else if (query.includes('tags.level2')) {
        const codeLevel2 = this.getQueryCode(query);

        const foundLevel2 = this.allGuidedCategories.level2.find(item => item.code === codeLevel2);
        const foundLevel1 = this.allGuidedCategories.level1.find(item => item.code === foundLevel2.parentCode);

        this.setSelectedLevel1(foundLevel1);
        this.setSelectedLevel2(foundLevel2);
        this.setSelectedLevel3({ code: 'All' });
        this.setLevel2CategoriesForSelectedLevel1(
          [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
        );
        this.setLevel3CategoriesForSelectedLevel2(
          [{ code: 'All' }, ...this.allGuidedCategories.level3.filter(item => item.parentCode === foundLevel2.code)],
        );

        this.setAdvancedSearchSelectedLevel({
          level1: foundLevel1,
          level2: foundLevel2,
          level3: { code: 'All' },
          level2ForSelectedLevel1: [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
          level3ForSelectedLevel2: [{ code: 'All' }, ...this.allGuidedCategories.level3.filter(item => item.parentCode === foundLevel2.code)],
        });
      } else if (query.includes('tags.level1')) {
        const codeLevel1 = this.getQueryCode(query);
        const foundLevel1 = this.allGuidedCategories.level1.find(item => item.code === codeLevel1);

        this.setSelectedLevel1(foundLevel1);
        this.setSelectedLevel2({ code: 'All' });
        this.setSelectedLevel3({ code: 'All' });
        this.setLevel2CategoriesForSelectedLevel1(
          [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
        );
        this.setLevel3CategoriesForSelectedLevel2(
          [{ code: 'All' }],
        );

        this.setAdvancedSearchSelectedLevel({
          level1: foundLevel1,
          level2: { code: 'All' },
          level3: { code: 'All' },
          level2ForSelectedLevel1: [{ code: 'All' }, ...this.allGuidedCategories.level2.filter(item => item.parentCode === foundLevel1.code)],
          level3ForSelectedLevel2: [{ code: 'All' }],
        });
      } else {
        this.setSelectedLevel1({ code: 'All' });
        this.setSelectedLevel2({ code: 'All' });
        this.setSelectedLevel3({ code: 'All' });
        this.setLevel2CategoriesForSelectedLevel1([{ code: 'All' }]);
        this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);

        this.setAdvancedSearchSelectedLevel({
          level1: { code: 'All' },
          level2: { code: 'All' },
          level3: { code: 'All' },
          level2ForSelectedLevel1: [{ code: 'All' }],
          level3ForSelectedLevel2: [{ code: 'All' }],
        });
      }
    },
    checkCriteriaQuery () {
      const { criteria } = this.$route.query;
      if (!criteria) return;
      this.getSavedSearch(criteria)
        .then((savedSearch) => {
          this.searchForSavedSearch(savedSearch.request);
          this.handleSelectedLevel(savedSearch.request.query);
          this.setGuidedSearch(true);
          if (savedSearch.newResults > 0) {
            this.searchForNewDocuments(savedSearch);
          } else {
            this.resetNewSearchResults();
          }
        });
    },
    /**
    * todo to be a helper method or its own class/model
    */
    checkRouteSearchQuery () {
      const {
        query, source, sortBy, from, size,
      } = this.$route.query;

      const selectedSource = source ? Array(source) : undefined;
      const appliedSort = Qs.parse(sortBy);
      const currentPage = from;
      const itemsPerPage = Number(size);
      this.triggerSearchQuery({
        resetSources: false, query, selectedSource, appliedSort, currentPage, itemsPerPage,
      });
    },

    performNatureInjurySearch (level, term) {
      const query = `tags.level${level}code:"${term}"`;
      this.triggerSearchQuery({
        query,
      });
      this.setDropdownGuidedTag(term);
      if (level === 1) {
        this.setSelectedLevel2({ code: 'All' });
        this.setSelectedLevel3({ code: 'All' });
        this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);
        this.setActiveGuidedPrefix(0);
      }
      if (level === 2) {
        this.setSelectedLevel3({ code: 'All' });
        this.setActiveGuidedPrefix(1);
      }
    },

    onScroll () {
      // Get the current scroll position
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }

      if (currentScrollPosition === 0) {
        this.searchBarTop = '70px';
        this.searchBarZ = 500;
        this.showScrollToTop = false;
      } else {
        this.showScrollToTop = true;
      }

      // Here we determine whether we need to show or hide the navbar
      if (currentScrollPosition < this.lastScrollPosition) {
        this.showMoreSearchOptions = true;
      } else {
        this.showMoreSearchOptions = false;
        this.searchBarTop = '0px';
        this.searchBarZ = 1020;
      }
      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = currentScrollPosition;
    },
    formatGuidedDisplay (term) {
      let formattedTerm = term;
      // eslint-disable-next-line array-callback-return
      this.guidedPrefixIgnoreList.map((prefix) => {
        formattedTerm = formattedTerm.replace(prefix, '');
      });
      return term;
    },
  },
  async mounted () {
    const allGuidedCategories = await icrsService.getGuidedCategories();
    this.setAllGuidedCategories(allGuidedCategories);
    this.checkCriteriaQuery();
    this.checkRouteSearchQuery();
    window.addEventListener('scroll', this.onScroll);

    this.isSearchFilterOpened = window.outerWidth > 768;
    this.isSearchWithinOpened = window.outerWidth > 768;
    this.isSearchSuggestionsOpened = window.outerWidth > 768;

    this.guidedPrefixIgnoreList = ICRSService.getGuidedPrefixIgnoreList();

    if (this.isSavedSearchFromHomePage) {
      this.currentSelectedLevel1 = this.selectedLevel1;
      this.currentSelectedLevel2 = this.selectedLevel2;
      this.currentSelectedLevel3 = this.selectedLevel3;
      this.setSavedSearchFromHomePage(false);
    }
    this.isLoading = false;
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped>
.icrs-search-bar-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page-loading {
  @extend .icrs-search-bar-wrapper;
}

.tab-focus:focus-visible {
  outline: 2px solid gray;
}

.faceted_search {
  background-color: white;
}

.top-section {
  padding-top: $gap-lg;
  padding-bottom: $gap-lg;
  display: flex;
  flex-direction: column;
}

.search-actions {
  display: none;
  margin-top: $gap-lg;
}

.main-search-bar {
  flex: 1 1 0%;
  display: flex;
  max-width: 680px;
}

.search-bar-container {
  flex: 1 1 0%;
}

.search-filter-wrapper {
  background: $blue-gray-50;
  display: none !important;
}

// Smaller or equal to 1025px
.faceted_search--show-mobile-filter .search-filter-wrapper {
  display: block !important;
  position: fixed;
  z-index: 1;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  width: 289px;
  margin-top: 0;
}

.section-separator {
  border-bottom: 1px solid $blue-gray-100;
}

.top-section--open-more-options {
  .search-actions {
    display: flex !important;
    justify-content: flex-end;
  }

  &/deep/ {
    .more-options svg {
      transform: rotate(180deg);
    }
  }
}

.main-column {
  margin-left: -15px;
}

.more-options {
  .content_logo_text {
    display: inline-flex;
    margin-left: 0.25rem;
  }
}

@media (min-width: 1026px) {
  .more-options {
    display: none;
  }

  .top-section {
    flex-direction: row;
  }

  .search-actions {
    display: flex !important;
    margin-top: 0;
  }

  .search-filter-wrapper {
    display: block;
  }
}

@media (min-width: 540px) {
  .top-section {
    padding-top: $gap-lg;
    padding-bottom: $gap-lg;
  }
}


@media only screen and (max-width: 992px){
.icrs-search-results.more-options-active {
  margin-top: 350px !important;
}
}

@media (min-width: 1200px) {
  .container-wide {
    max-width: 1200px !important;
    margin: 0 auto;
  }

  .top-section {
    max-width: 1200px !important;
    margin: 0 auto;
  }

  .filters-btn-wrapper {
    display: none !important;
  }

  .search-filter-wrapper,
  .faceted_search--show-mobile-filter .search-filter-wrapper {
    box-shadow: none;
    position: relative;
    display: block !important;
    width: initial;
    top: initial;
    right: initial;
    bottom: initial;
  }

  .search-filter-wrapper {
    margin-top: $gap-lg !important;
  }

}
.keep-origin-search {

    .result-row{
      display: flex;
      .suggested-query-text {
        color: #751C24;
        cursor: pointer;
        text-underline-position: under;
        margin-left: 5px;
      }
    }
  }
</style>
