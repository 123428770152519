const NUM_MILLISECONDS_IN_SECOND = 1000;

class MetricsObjectModel {
  constructor (data = {}) {
    this.type = !_.isUndefined(data.type) ? data.type : null;
    this.input = !_.isUndefined(data.input) ? data.input : null;
    this.timestamp = !_.isUndefined(data.timestamp)
      ? data.timestamp : Date.now() / NUM_MILLISECONDS_IN_SECOND;
    this.properties = !_.isUndefined(data.properties) ? data.properties : null;
  }
}

export default MetricsObjectModel;
