export default {
  /**
   * @param {import('@typings/auth').AuthServiceState} state
   * @param {import('@typings/auth').User} user
   */
  setUser (state, user) {
    state.user = user;
  },

  /**
   * @param {import('@typings/auth').AuthServiceState} state
   * @param {string} message
   */
  setMessage (state, message) {
    state.message = message;
  },

  /**
   * @param {import('@typings/auth').AuthServiceState} state
   * @param {object}   payload
   * @param {string?}  payload.message
   * @param {boolean?} payload.isForced
   */
  logout (state, payload = {}) {
    state.user = null;
    state.message = payload.message;
    if (payload.isForced) {
      state.isFail = true;
    }
  },

  /**
   * @param {import('@typings/auth').AuthServiceState}  state
   * @param {boolean} flag
   */
  setIsProcessing (state, flag) {
    state.isProcessing = flag;
  },

  /**
   * @param {import('@typings/auth').AuthServiceState}  state
   * @param {boolean} flag
   */
  setIsSuccess (state, flag) {
    state.isSuccess = flag;
  },

  /**
   * @param {import('@typings/auth').AuthServiceState}  state
   * @param {boolean} flag
   */
  setIsFail (state, flag) {
    state.isFail = flag;
  },
  setIsADAuth (state, isADAuth) {
    state.isADAuth = isADAuth;
  },
  setLatestErrorConfig (state, latestErrorConfig) {
    state.latestErrorConfig = latestErrorConfig;
  },
};
