export default ({
  currentQuery = undefined,
  currentSearchMode = undefined,
  currentPage = 1,
  itemsPerPage = 10,
  currentDateObject = undefined,
  currentSearchParameter = {},
  currentSearchRequestParameter = undefined,
  currentSearchResults = {
    total: 0,
  },
  appliedFilters = {},
  appliedSort = {},
  canSaveSearch = false,
  suggestions = [],
  corrections = [],
  naturalLanguageAnswer = [],
  selectedSource = undefined,
  categories = {},
  appliedSynonyms = [],
  isSearching = false,
  isSearchSuccess = false,
  savedSearches = [],
  filterGroups = {
    isFetching: false,
    hasFetchedBefore: false,
    data: [],
  },
  filterGroupConfigs = {},
  searchWithin = [],
  promoteDateObject = undefined,
  icrsPlotSearching = false,
  icrsPlotData = {},
  icrsPlotSuccess = true,
  icrsSearchRequestParameter = undefined,
  previousQuery = undefined,
  suggestedQueryText = '',
  spellCorrectedQueries = { corrections: [] },
  keepOriginSearchText = true,
  isSavedSearchFromHomePage = false,
  isMobileOveralOpen = false,
} = {}) => ({
  currentQuery,
  currentSearchMode,
  currentPage,
  itemsPerPage,
  currentDateObject,
  currentSearchParameter,
  currentSearchRequestParameter,
  currentSearchResults,
  appliedFilters,
  appliedSort,
  canSaveSearch,
  suggestions,
  corrections,
  naturalLanguageAnswer,
  selectedSource,
  categories,
  appliedSynonyms,
  isSearching,
  isSearchSuccess,
  savedSearches,
  filterGroups,
  filterGroupConfigs,
  searchWithin,
  promoteDateObject,
  icrsPlotSearching,
  icrsPlotData,
  icrsPlotSuccess,
  icrsSearchRequestParameter,
  previousQuery,
  suggestedQueryText,
  spellCorrectedQueries,
  keepOriginSearchText,
  isSavedSearchFromHomePage,
  isMobileOveralOpen,
});
