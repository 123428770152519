import Api from './api/api.service';
import ConfigService from './config.service';

const ADMIN_CONTEXT = ConfigService.getSearchAdminContext();

export default {
  getSearchIndexes () {
    return Api().get(`${ADMIN_CONTEXT}/api/index/indices`).then(resp => resp.data);
  },

  saveActiveSearchIndex (searchIndex) {
    return Api().get(`${ADMIN_CONTEXT}/api/index/activateIndexForSearch/${searchIndex}`)
      .then(resp => resp.data);
  },

  getActiveSearchIndex () {
    return Api().get(`${ADMIN_CONTEXT}/api/index/checkIndexActivatedForSearch`).then(resp => resp.data);
  },
};
