<template>
  <div class="content_logo_text">
    <font-awesome-icon class="icon" :icon="[faType, faName]" :size="size" />
    <span :class="size">{{text}}</span>
  </div>
</template>

<script>
export default {
  props: {
    faType: String,
    faName: String,
    text: String,
    size: {
      type: String,
      default: 'lg',
    },
  },
};
</script>

<style lang="scss" scoped>
.content_logo_text {
  display: flex;
  padding: 0.3em 0;
}
.content_logo_text > span {
  margin-left: 0.5em;
}
.lg{
  font-size: 16px;
  line-height: unset;
}
.sm{
  line-height: 1.1;
  font-size: 14px;
}
// @todo size-reg
</style>
