export default {
  criteriaId: state => state.criteriaId,
  newSearchResultsEnabled: state => state.criteriaId != null,
  currentSearchResults: state => state.currentSearchResults,
  currentSearchResults__documents: state => (state.currentSearchResults ? state.currentSearchResults.resultItems : []),
  isSearchSuccess: state => state.isSearchSuccess,
  isSearching: state => state.isSearching,
  currentQuery: state => state.currentQuery,
  currentPage: state => state.currentPage,
  itemsPerPage: state => state.itemsPerPage,
  allSourceDocCount: state => state.categories.total || 0,
  appliedSort: state => state.appliedSort,
  selectedSource: state => state.selectedSource,
  searchCategories: (state) => {
    const isDefaultValue = Array.isArray(state.categories) && !state.categories.length;
    if (isDefaultValue) return [];
    if (!state.categories) return [];
    if (!state.categories.aggregations) return [];
    if (!state.categories.aggregations.length) return [];

    const hasAggregations = !!state.categories.aggregations[0].bucket;
    if (!hasAggregations) return [];

    return [
      ...state.categories.aggregations[0].bucket.map(item => ({
        ...item,
        label: `${item.key} (${item.docCount})`,
      })),
    ];
  },
  currentSearchRequestParameter: state => state.currentSearchRequestParameter,
  searchWithin: state => state.searchWithin,
};
