<template>
  <div class="t-autocomplete">
    <div>
      <label v-if="label" :for="localId" class="col-form-label pt-0" v-dompurify-html="label"></label>
      <Input
        :id="localId"
        icon="search"
        ref="input"
        type="search"
        :disabled="disabled"
        :placeholder="placeholder"
        :aria-label="placeholder"
        :value="inputValue"
        :state="state"
        :invalid-message="invalidMessage"
        @focus="focusEvent"
        @blur="handleBlur"
        @input="handleInput"
      />
    </div>
    <ul ref="list" v-show="isFocused && matchedItems.length > 0" class="autocomplete-dropdown">
      <li
        v-for="(item, id) in matchedItems"
        :key="id"
        :data="item.data"
        @mouseover="searchSelection = id"
        @mousedown.prevent="handleHit(item, $event)"
        tabindex="0"
        :class="{
          'item-default': id != searchSelection,
          'item-highlighted': id == searchSelection,
        }"
      >
        <slot name="suggestion" v-bind="{ data: data, htmlText: highlight(item.text) }">
          <span v-dompurify-html="highlight(item.text)"></span>
        </slot>
      </li>
    </ul>
  </div>
</template>

<script>
function sanitize (text) {
  return text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

function escapeRegExp (str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
/**
 * Autocomplete component.
 */
export default {
  name: 'Autocomplete',
  status: 'release',
  props: {
    /**
     *
     * Used to set invalid message if the preceding input has an invalid state
     */
    invalidMessage: {
      type: String,
    },
    /**
     *
     * Controls the validation state appearance of the component. 'true' for valid, 'false' for invalid', or 'null' for no validation state
     */
    state: {
      type: Boolean,
      default: null,
    },
    /**
     *
     * When set to 'true', disables the component's functionality and places it in a disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Autocomplete value.
     */
    value: {
      type: String,
    },
    /**
     *
     * Array of data to be available for querying. Required
     */
    data: {
      type: Array,
      required: true,
      validator: d => d instanceof Array,
    },
    /**
     *
     * Function used to convert the entries in the data array into a text string.
     */
    serializer: {
      type: Function,
      default: d => d,
      validator: d => d instanceof Function,
    },
    /**
     *
     * Maximum amount of list items to appear.
     */
    maxMatches: {
      type: Number,
      default: 10,
    },
    /**
     *
     * Minimum matching characters in query before the typeahead list appears
     */
    minMatchingChars: {
      type: Number,
      default: 2,
    },
    /**
     *
     * Input placeholder
     */
    placeholder: String,
    /**
     *
     * Label
     */
    label: String,
  },

  watch: {
    value (newVal) {
      this.inputValue = newVal;
    },
  },

  mounted () {
    this.inputValue = this.value;
  },

  computed: {
    highlight () {
      return (text) => {
        const textTem = sanitize(text);
        const re = new RegExp(this.escapedQuery, 'gi');

        return textTem.replace(re, '<strong>$&</strong>');
      };
    },
    escapedQuery () {
      return escapeRegExp(sanitize(this.inputValue));
    },
    matchedItems () {
      if (this.inputValue.length === 0 || this.inputValue.length < this.minMatchingChars) {
        return [];
      }

      const re = new RegExp(this.escapedQuery, 'gi');

      // Filter, sort, and concat
      const data = this.formattedData
        .filter(i => i.text.match(re) !== null)
        .sort((a, b) => {
          const aIndex = a.text.indexOf(a.text.match(re)[0]);
          const bIndex = b.text.indexOf(b.text.match(re)[0]);

          if (aIndex < bIndex) {
            return -1;
          }
          if (aIndex > bIndex) {
            return 1;
          }
          return 0;
        });
      return data.slice(0, this.maxMatches);
    },
    formattedData () {
      if (!(this.data instanceof Array)) {
        return [];
      }
      return this.data.map((d, i) => ({
        id: i,
        data: d,
        text: this.serializer(d),
      }));
    },
    localId () {
      // eslint-disable-next-line no-underscore-dangle
      return `t-input-${this._uid}`;
    },
  },

  methods: {
    focusEvent () {
      this.isFocused = true;
      this.$emit('focus');
    },
    handleHit (item, evt) {
      evt.preventDefault();
      if (typeof this.value !== 'undefined') {
        this.$emit('input', item.text);
      }

      this.inputValue = item.text;
      this.$emit('hit', item.data);
      this.isFocused = false;
      if (this.$refs.input.$children) {
        this.$refs.input.$children[0].blur();
      }
    },
    handleInput (value) {
      this.inputValue = value;

      // If v-model is being used, emit an input event
      if (typeof this.value !== 'undefined') {
        this.$emit('input', value);
      }
    },
    handleBlur () {
      this.isFocused = false;
      this.isSelect = false;
    },
  },

  data () {
    return {
      searchSelection: 0,
      isFocused: false,
      inputValue: '',
      active: false,
      isSelect: false,
    };
  },
};
</script>

<style lang="scss">
@mixin text-md-14 {
  font-size: $font-md;
  line-height: $ln-height-20;
  font-weight: $weight_normal;
}

@mixin label-md-14 {
  font-size: $font-md;
  line-height: $ln-height-20;
  font-weight: $weight_semi_bold;
}

.t-autocomplete {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  position: relative;
  fieldset {
    padding: 0;
    &.form-group {
      margin-bottom: 0;
    }
  }

  label {
    @include label-md-14;
    color: $blue-gray-400;
    padding-bottom: $gap-xxs;
  }

  .autocomplete-dropdown {
    list-style-type: none;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1000;
    border: 1px solid $blue-gray-100;
    padding: $gap-xxs;
    margin-top: 2px;
    background-color: $white-500;
    border-radius: $gap-xxs;
    li {
      @include text-md-14;
      border-radius: $radius_2;
      padding: $gap-xxs $gap-xs;
      height: 28px;
      cursor: pointer;
    }
  }

  .item-default {
    color: $blue-gray-400;
  }

  .item-highlighted {
    background-color: $blue-200;
    color: $blue-500;
  }
}
</style>

<docs>
  ```jsx
  <div>
    <div class="mt-lg">
        <Autocomplete
            label="Input Autocomplete"
            v-bind:data="['Canada', 'USA', 'Mexico', 'Canary']"
            placeholder="Type an address..."
        />
    </div>
    <div class="mt-lg">
        <Autocomplete
            label="Disabled Autocomplete"
            disabled
            v-bind:data="['Canada', 'USA', 'Mexico', 'Canary']"
            placeholder="Type an address..."
        />
    </div>
  </div>
  ```
</docs>
