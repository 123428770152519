<template>
  <b-form-group class="t-radio-button" v-bind="$props" :label="label">
    <b-form-radio-group
      :id="id"
      :name="name"
      :disabled="disabled"
      :required="required"
      :autofocus="autofocus"
      :aria-invalid="ariaInvalid"
      :stacked="stacked"
      :plain="plain"
      :options="options"
      :value-field="valueField"
      :text-field="textField"
      :disabled-field="disabledField"
      :state="state"
      :checked="value"
      v-on:input="inputEvent"
    ></b-form-radio-group>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormRadioGroup } from 'bootstrap-vue';

const ANY = [String, Number, Boolean, Array, Object, Date, Function];
/**
 * Radio Button component.
 */
export default {
  name: 'RadioButton',
  status: 'release',
  components: {
    BFormGroup,
    BFormRadioGroup,
  },
  props: {
    /**
     *
     * This value property determine the label of the Radio Button.
     */
    label: {
      type: String,
    },
    /**
     *
     * Used to set the 'id' attribute on the rendered content, and used as the base to generate any additional element IDs as needed
     */
    id: {
      type: String,
    },
    /**
     *
     * Sets the value of the 'name' attribute on the form control
     */
    name: {
      type: String,
    },

    /**
     *
     * When set to 'true', disables the component's functionality and places it in a disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Adds the 'required' attribute to the form control
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * When set to 'true', attempts to auto-focus the control when it is mounted, or re-activated when in a keep-alive. Does not set the 'autofocus' attribute on the control
     */
    autofocus: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Sets the 'aria-invalid' attribute value on the wrapper element. When not provided, the 'state' prop will control the attribute
     */
    ariaInvalid: {
      type: [Boolean, String],
      default: false,
    },
    /**
     *
     * When set, renders the radio group in stacked mode
     */
    stacked: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Render the form control in plain mode, rather than custom styled mode
     */
    plain: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Array of items to render in the component
     */
    options: {
      type: [Array, Object],
      default: () => [],
    },
    /**
     *
     * Field name in the 'options' array that should be used for the value
     */
    valueField: {
      type: String,
      default: 'value',
    },
    /**
     *
     * Field name in the 'options' array that should be used for the text label
     */
    textField: {
      type: String,
      default: 'text',
    },
    /**
     *
     * Field name in the 'options' array that should be used for the disabled state
     */
    disabledField: {
      type: String,
      default: 'html',
    },
    /**
     *
     * Controls the validation state appearance of the component. 'true' for valid, 'false' for invalid', or 'null' for no validation state
     */
    state: {
      type: Boolean,
      default: null,
    },
    /**
     * @model
     */
    value: {
      type: ANY,
    },
  },
  methods: {
    inputEvent (checked) {
      /**
       * Emitted when the selected value(s) is changed
       *
       * @event input
       * @property {object} checked - Value of radion button
       * @type {function}
       */
      this.$emit('input', checked);
    },
  },
};
</script>

<style lang="scss">
.t-radio-button {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  padding: 0;
  legend {
    color: $blue-gray-400;
  }
  .custom-control-label {
    height: 20px;
    min-height: 0;
    > span {
      font-size: $font-md;
      font-weight: $weight-normal;
      line-height: $ln-height-20;
      color: $blue-gray-400;
    }
    &:before,
    &:after {
      width: 12px;
      height: 12px;
      top: 4px;
      left: -22px;
    }
    &:before {
      border: 1.2px solid #9ea9b9;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: transparent;
    border-color: $blue-500;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.6' fill='%234d7cfe'/%3e%3c/svg%3e") !important;
  }
}
</style>

<docs>
  ```jsx
  <div>
    <div class="mt-lg">
      <RadioButton label="Radio Button Inline" name="inline" v-bind:options="[{ text: 'First radio', value: 'first' },{ text: 'Second radio', value: 'second' }]"></RadioButton>
    </div>
    <div class="mt-lg">
      <RadioButton label="Radio Button Stacked" stacked name="inline" v-bind:options="[{ text: 'First radio', value: 'first' },{ text: 'Second radio', value: 'second' }]"></RadioButton>
    </div>
  </div>
  ```
</docs>
