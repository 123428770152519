import constants from '@/constants/search-service-constants';

export default {
  setPromotedContentFilterEnabled ({ dispatch, commit, state }, promotedContentFilterEnabled) {
    commit('setPromotedFilterEnable', promotedContentFilterEnabled);
  },

  setPromotedDateRangeMode ({ commit }, rangeMode) {
    commit('setPromotedDateRangeMode', rangeMode);
  },

  /**
 * @param {import('@typings/search').DateRangeItem} promoteDateObject
 */
  setPromotedContentDateObject ({ commit, dispatch }, promoteDateObject) {
    commit('setPromoteDateObject', promoteDateObject);
    dispatch('setSearchQueryPromotedDateObject');
  },

  setSearchQueryPromotedDateObject ({ dispatch, state }) {
    dispatch('searchService/setPromoteDateObject', state.promoteDateObject, { root: true });
    dispatch('searchService/newResults/setPromoteDateObject', state.promoteDateObject, { root: true });
  },

  resetPromotedDateConfig ({ dispatch, commit }) {
    commit('resetPromotedContent');
    dispatch('setSearchQueryPromotedDateObject');
  },

  removePromotedContentFilters ({ dispatch }, filters) {
    dispatch('resetPromotedDateConfig');
    dispatch('triggerSearchQuery', filters);
  },

  triggerSearchQuery ({ getters, dispatch }, filters) {
    dispatch('searchService/setPromoteDateObject', undefined, { root: true });
    dispatch('searchService/newResults/setPromoteDateObject', undefined, { root: true });
    dispatch('searchService/filters/setSelectedFilters', filters, { root: true });
    dispatch('searchService/filters/executeFilterSearch', {}, { root: true });
  },

  setPromotedContentFilterForSavedSearch ({ dispatch }, promotedDateObject) {
    dispatch('setPromotedContentFilterEnabled', true);
    dispatch('setPromotedDateRangeMode', constants.PROMOTED_DATE_RANGE_MODE.CUSTOM_RANGE);
    dispatch('setPromotedContentDateObject', promotedDateObject);
  },
};
