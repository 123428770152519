/**
 * projectRoutes
 * Contains all the new routes that are specific to a project
 */

import IcrsUnauthorizedPage from '../pages/icrs-unauthorized-page/IcrsUnauthorizedPage.vue';
// import IcrsAbout from '../pages/icrs-static/IcrsAbout.vue';
// import IcrsFaq from '../pages/icrs-static/IcrsFaq.vue';
// import IcrsContact from '../pages/icrs-static/IcrsContact.vue';
// import IcrsPolicy from '../pages/icrs-static/IcrsPolicy.vue';
// import IcrsTerms from '../pages/icrs-static/IcrsTerms.vue';
import IcrsHome from '../pages/icrs-home/IcrsHome.vue';
import IcrsSearch from '../pages/icrs-search/IcrsSearch.vue';
import IcrsRedirectPage from '../pages/icrs-redirect-page/IcrsRedirectPage.vue';
// import IcrsUsefulLinks from '../pages/icrs-static/IcrsUsefulLinks.vue';

const projectRoutes = [
  {
    path: '/search',
    name: 'ICRS Home',
    component: IcrsHome,
  },
  {
    path: '/icrs-search',
    name: 'ICRS Search',
    component: IcrsSearch,
  },
  {
    path: '/icrs-redirect-page',
    name: 'Icrs RedirectPage',
    component: IcrsRedirectPage,
  },
  // {
  //   path: '/icrs-about',
  //   name: 'IcrsAbout',
  //   component: IcrsAbout,
  // },
  // {
  //   path: '/icrs-faq',
  //   name: 'IcrsFAQ',
  //   component: IcrsFaq,
  // },
  // {
  //   path: '/icrs-contact',
  //   name: 'IcrsContact',
  //   component: IcrsContact,
  // },
  // {
  //   path: '/icrs-policy',
  //   name: 'IcrsPolicy',
  //   component: IcrsPolicy,
  // },
  // {
  //   path: '/icrs-terms',
  //   name: 'IcrsTerms',
  //   component: IcrsTerms,
  // },
  // {
  //   path: '/icrs-useful-links',
  //   name: 'IcrsUsefulLinks',
  //   component: IcrsUsefulLinks,
  // },
  {
    path: '/error',
    name: 'Icrs Unauthorized Page',
    component: IcrsUnauthorizedPage,
  },
];


export default projectRoutes;
