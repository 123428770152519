<template>
  <div class="row">
    <div class="col-lg-3 control-left">
      <b-dropdown
        class="control-button"
        block
        split
        variant="outline-primary"
        :text="formatGuidedDisplay(formatDropdownDisplay(selectedLevel1))"
      >
        <b-dropdown-item
          v-for="category in level1Categories"
          :key="'level1_' + category"
          :active="selectedLevel1 === category"
          @click="$parent.updateGuidedDropdowns(0, category)"
        >
          {{formatGuidedDisplay(category)}}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-lg-3 control-center">
      <b-dropdown
        class="control-button"
        block
        split
        variant="outline-primary"
        :text="formatGuidedDisplay(formatDropdownDisplay(selectedLevel2))"
      >
        <b-dropdown-item
          v-for="category in level2CategoriesForSelectedLevel1"
          :key="'level2_' + category"
          :active="selectedLevel2 === category"
          @click="$parent.updateGuidedDropdowns(1, category)"
        >
          {{formatGuidedDisplay(category)}}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-lg-3 control-center">
      <b-dropdown
        class="control-button"
        block
        split
        variant="outline-primary"
        :text="formatGuidedDisplay(formatDropdownDisplay(selectedLevel3))"
      >
        <b-dropdown-item
          v-for="category in level3CategoriesForSelectedLevel2"
          :key="'level3_' + category"
          :active="selectedLevel3 === category"
          @click="$parent.updateGuidedDropdowns(2, category)"
        >
          {{formatGuidedDisplay(category)}}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-lg-3 control-right">
      <b-button
        class="guided-search-button"
        block
        variant="primary"
        :disabled="
          selectedLevel1 === 'All' &&
          selectedLevel2 === 'All' &&
          selectedLevel3 === 'All'
        "
        @click="makeIcrsGuidedSearchDropdownQuery(dropdownGuidedTag)"
      >Perform Guided Search
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ICRSGuidedSearchDropdown',
  props: {
    level1Categories: {
      type: Array,
      default () {
        return [];
      },
    },
    guidedPrefixIgnoreList: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  data () {
    return {
      dropdownCharDisplayLimit: 18,
    };
  },
  computed: {
    ...mapGetters('icrs', [
      'dropdownGuidedTag',
      'selectedLevel1',
      'selectedLevel2',
      'selectedLevel3',
      'level2CategoriesForSelectedLevel1',
      'level3CategoriesForSelectedLevel2',
    ]),
  },
  methods: {
    makeIcrsGuidedSearchDropdownQuery (queryValue) {
      this.activeGuidedPrefix = 2;
      if (this.selectedLevel3 === 'All') {
        this.activeGuidedPrefix = 1;
      } else if (this.selectedLevel2 === 'All') {
        this.activeGuidedPrefix = 0;
      }
      this.$emit('makeIcrsGuidedSearchQuery', queryValue);
    },
    formatDropdownDisplay (value) {
      if (window.outerWidth <= 768) {
        return value;
      }
      if (value.length <= this.dropdownCharDisplayLimit) {
        return value;
      }
      return `${value.substring(0, this.dropdownCharDisplayLimit - 3)}...`;
    },
    formatGuidedDisplay (term) {
      let formattedTerm = term;
      // eslint-disable-next-line array-callback-return
      this.guidedPrefixIgnoreList.map((prefix) => {
        formattedTerm = formattedTerm.replace(prefix, '');
      });
      return formattedTerm;
    },
  },
};
</script>
