<template>
  <div>
    <div class="content">
      <div class="topBar">
        <div class="topic"> Search Result Card </div>
      </div>
      <LiveDemo />
      <SearchResultCardMarkdown class="markdownStyle" />
    </div>
  </div>
</template>

<script>

import LiveDemo from './SearchResultCardLiveDemo.vue';
import SearchResultCardMarkdown from './SearchResultCardComponent.md';

export default {
  name: 'SearchResultCardComponent',
  components: {
    SearchResultCardMarkdown,
    LiveDemo,
  },
};
</script>


<style scoped lang="scss">
@import "../../markdown.scss";
.topBar {
  height: 152px;
  background: $blue-200;
}
.topic {
  padding: 60px 100px;
  font-size: 36px;
  font-weight: $weight-normal;
}
</style>
