export default {
  setStartDate (state, startDate) {
    state.start = startDate;
  },
  setEndDate (state, endDate) {
    state.end = endDate;
  },
  setDateObject (state, payload) {
    state.start = payload.start;
    state.end = payload.end;
  },
};
