import getters from '../getters';
import createState from '../state';


describe('search-core/filters/date vuex getters test', () => {
  test('dateObject', () => {
    let state = createState();

    const date = {
      start: '2021-02-06T00:00:00.00Z',
      end: '2021-02-14T00:00:00.00Z',
      field: 'lastModifiedDate',
    };

    state = createState(date);

    expect(getters.dateObject(state)).toEqual({
      start: '2021-02-06T00:00:00.00Z',
      end: '2021-02-14T00:00:00.00Z',
      field: 'lastModifiedDate',
    });
  });

  test('dateFilters when dateObject is undefined', () => {
    const state = createState();
    expect(getters.dateFilters(state, getters)).toEqual([]);
  });

  test('dateFilters when dateObject exists', () => {
    let state = createState();

    const date = {
      start: '2021-02-06T00:00:00.00Z',
      end: '2021-02-14T00:00:00.00Z',
      field: 'lastModifiedDate',
    };

    state = createState(date);
    getters.dateObject = getters.dateObject(state);

    expect(getters.dateFilters(state, getters)).toEqual([
      { key: 'Modified Date: from', date: '6 Feb 2021' },
      { key: 'Modified Date: to', date: '14 Feb 2021' },
    ]);
  });
});
