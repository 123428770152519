
class SearchResultDocumentModel {
  constructor (data = {}) {
    this.id = !_.isUndefined(data.id) ? data.id : null;
    this.title = !_.isUndefined(data.title) ? data.title : null;
    this.source = !_.isUndefined(data.source) ? data.source : null;
    this.path = !_.isUndefined(data.path) ? data.path : null;
    this.content = !_.isUndefined(data.content) ? data.content : null;
    this.appType = !_.isUndefined(data.appType) ? data.appType : null;
    this.createdDate = !_.isUndefined(data.createdDate) ? data.createdDate : null;
    this.lastModifiedDate = !_.isUndefined(data.lastModifiedDate) ? data.lastModifiedDate : null;
    this.version = !_.isUndefined(data.version) ? data.version : 0;
    this.latest = !_.isUndefined(data.latest) ? data.latest : false;
    this.roles = !_.isUndefined(data.roles) ? data.roles : [];
    this.metadata = !_.isUndefined(data.metadata) ? data.metadata : {};
    this.docFormat = !_.isUndefined(data.docFormat) ? data.docFormat : null;
    this.sensitive = !_.isUndefined(data.sensitive) ? data.sensitive : false;
    this.visualization = !_.isUndefined(data.visualization) ? data.visualization : null;
    this.blurb = !_.isUndefined(data.blurb) ? data.blurb : null;
    this.mailto = !_.isUndefined(data.mailto) ? data.mailto : null;
    this.alternativeTitle = !_.isUndefined(data.alternativeTitle) ? data.alternativeTitle : null;
    this.highlight = !_.isUndefined(data.highlight) ? data.highlight : null;
    this.tags = !_.isUndefined(data.tags) ? data.tags : {};
    this.annotations = !_.isUndefined(data.annotations) ? data.annotations : '';
    this.subsite = !_.isUndefined(data.annotations) ? data.annotations : '';
    this.featured = !_.isUndefined(data.featured) ? data.featured : null;
    this.relationChain = !_.isUndefined(data.relationChain) ? data.relationChain : null;
    this.newDocument = !_.isUndefined(data.newDocument) ? data.newDocument : false;
  }
}

export default SearchResultDocumentModel;
