import { createTestAction } from '@/helpers/testing';
import SearchService from '@/services/search.service';
import AdvancedSearchService from '@/services/advanced-search.service';
import FilterGroup from '@/services/models/search/filter-group.model';
import actions from '../actions';

jest.mock('@/services/search.service', () => ({
  getAggregationConfigs: jest.fn(),
  getAggregations: jest.fn(),
  getAutocomplete: jest.fn(),
}));

jest.mock('@/services/advanced-search.service', () => ({
  postIndividualQueryMetrics: jest.fn(),
  getTrendingIndividuals: jest.fn(),
}));

jest.mock('../../../../services/icrs-service', () => ({
  getFiltersTopicTitleDisplayOrder: jest.fn(),
}));

jest.mock('@/router', () => ({
  currentRoute: jest.fn(),
  push: jest.fn(),
}));

const testAction = createTestAction(expect);

describe('advanced-search vuex actions test', () => {
  test('updateInputIndividual', () => {
    const commit = jest.fn();
    actions.updateInputIndividual({ commit }, { inputIndividual: 'Singapore' });

    expect(commit).toHaveBeenLastCalledWith('setInputIndividual', 'Singapore');
  });

  test('updateCurrentSearch', () => {
    const currentSearch = {
      ontologySearch: true,
      filterBasedSearch: false,
    };
    const commit = jest.fn();
    actions.updateCurrentSearch({ commit }, {
      ontology: currentSearch.ontologySearch,
      filter: currentSearch.filterBasedSearch,
    });

    expect(commit).toHaveBeenLastCalledWith('setCurrentSearch', currentSearch);
  });

  test('updateIndividualSearchSuggestMap', () => {
    const autocompleteSuggest = [
      { suggest: 'aljunied crescent', id: 'gfdyb9fooio', score: 38.036399841308594 },
      { suggest: 'aljunied', id: 'geographics_aljunied', score: 27.652318954467773 },
    ];
    const individualSearchSuggestMap = {
      'aljunied crescent': 'gfdyb9fooio',
      aljunied: 'geographics_aljunied',
    };
    const commit = jest.fn();
    actions.updateIndividualSearchSuggestMap({ commit }, { autocompleteSuggest });

    expect(commit).toHaveBeenLastCalledWith('setIndividualSearchSuggestMap', individualSearchSuggestMap);
  });

  test('resetFilterBadges', () => {
    const commit = jest.fn();
    actions.resetFilterBadges({ commit });

    expect(commit).toHaveBeenLastCalledWith('setFilterGroups', []);
  });

  test('updateIsLoading', () => {
    const commit = jest.fn();
    actions.updateIsLoading({ commit }, false);

    expect(commit).toHaveBeenLastCalledWith('setIsLoading', false);
  });

  test('updateIsFetchingFilterBadges', () => {
    const commit = jest.fn();
    actions.updateIsFetchingFilterBadges({ commit }, true);

    expect(commit).toHaveBeenLastCalledWith('setFilterGroupFetchingStatus', true);
  });

  test('handleClickEventWithIndividualId', () => {
    const commit = jest.fn();
    const dispatch = jest.fn();
    const individualId = 'city_singapore';
    const inputIndividual = 'singapore';
    const state = {
      inputIndividual,
      individualSearchSuggestMap: { singapore: 'city_singapore' },
      individualId,
    };
    const rootState = {
      authService: {
        user: { id: 1 },
      },
    };
    const ctx = {
      commit, state, dispatch, rootState,
    };
    testAction(actions.handleClickEventWithIndividualId, individualId, ctx, [
      { type: 'setIndividualId', individualId },
    ]);
    expect(dispatch).toHaveBeenLastCalledWith('ontologySearch/updateOntologySearchHistory',
      { inputIndividual, individualId });
  });

  test('handleSearchEvent', () => {
    const dispatch = jest.fn();
    const ctx = { dispatch };
    testAction(actions.handleSearchEvent, { inputIndividual: 'singapore' }, ctx, [
      { type: 'advancedSearch/ontologySearch/resetState', payload: [] },
      { type: 'resetState' },
    ]);
    expect(dispatch).toHaveBeenLastCalledWith(
      'searchService/triggerSearchQuery',
      { query: 'singapore' },
      { root: true },
    );
  });

  test('getFilterBadgeConfigs', (done) => {
    const ctx = {};
    const response = { caa: {} };
    SearchService.getAggregationConfigs.mockReturnValueOnce(Promise.resolve(response));

    testAction(actions.getFilterBadgeConfigs, null, ctx, [
      { type: 'setFilterGroupConfigs', payload: response },
    ], done);
  });

  test('getFilterBadges', (done) => {
    const filterGroupConfigs = {
      caa: {
        displayName: 'CAA',
        displayed: true,
        field: 'tags.caa',
        showInCloud: true,
      },
      sectors: {
        displayName: 'Sectors',
        displayed: false,
        field: 'tags.sectors',
        showInCloud: true,
      },
    };
    const ctx = {
      state: { filterGroupConfigs },
    };
    const response = {
      aggregations: [
        { name: 'caa', docCount: 10, bucket: [] },
        { name: 'sectors', docCount: 0, bucket: [] },
      ],
    };

    SearchService.getAggregations.mockReturnValueOnce(Promise.resolve(response));
    const param = {};
    testAction(actions.getFilterBadges, param, ctx, [
      { type: 'setFilterGroupFetchingStatus', payload: true },
      {
        type: 'setFilterGroups',
        payload: [
          new FilterGroup({
            name: 'caa',
            docCount: 10,
            bucket: [],
            displayName: 'CAA',
          }),
        ],
      },
      { type: 'setFilterGroupFetchingStatus', payload: false },
    ], done);
  });

  test('getTrendingIndividuals', () => {
    const ctx = {};
    const response = [{
      individualId: 'city_singapore',
      label: 'singapore',
    }];
    AdvancedSearchService.getTrendingIndividuals.mockReturnValueOnce(Promise.resolve(response));
    testAction(actions.getTrendingIndividuals, { lookbackDays: 30, size: 5 }, ctx, []);
  });

  test('getAdvancedSearchAutocomplete', () => {
    const dispatch = jest.fn();
    const ctx = { dispatch };
    const value = 'aljunied';
    const isFilterBasedSearchDisabled = false;
    dispatch.mockReturnValue(Promise.resolve({ data: [value] }));
    testAction(actions.getAdvancedSearchAutocomplete, { value, isFilterBasedSearchDisabled }, ctx, []);
    expect(dispatch).toHaveBeenCalledWith('getAutocompleteSuggestions', { value });
    expect(dispatch).toHaveBeenCalledTimes(4);
    expect(dispatch).toHaveBeenLastCalledWith('updateIsLoading', false);
  });

  test('getAllFilterGroups', () => {
    const dispatch = jest.fn();
    const ctx = { dispatch };
    const value = 'aljunied';
    const isFilterBasedSearchDisabled = false;
    testAction(actions.getAllFilterGroups, { value, isFilterBasedSearchDisabled }, ctx, []);
    expect(dispatch).toHaveBeenLastCalledWith('getFilterBadges', { currentSearchInput: value });
  });

  test('getEmptyAutocompleteSuggestions', () => {
    const dispatch = jest.fn();
    const ctx = { dispatch };
    testAction(actions.getEmptyAutocompleteSuggestions, {}, ctx, []);
    expect(dispatch).toHaveBeenLastCalledWith('updateIsLoading', false);
  });
});
