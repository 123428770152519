<template>
  <div>
    <OverviewTemplate :overview-routes="pageRoutes">
      <div slot="topic">Page</div>
      <PageMarkdown slot="markdown"/>
    </OverviewTemplate>
  </div>
</template>

<script>

import PageMarkdown from './PageWidgetOverview.md';
import { pageRoutes } from '../RouteSettings';
import OverviewTemplate from '../components/OverviewTemplate';

export default {
  name: 'Page',
  data () {
    return {
      selectedItemPath: '',
      pageRoutes,
    };
  },
  components: {
    PageMarkdown,
    OverviewTemplate,
  },
};
</script>
