import { SUCCESS_MESSAGE, FAIL_MESSAGE } from '@/constants/auth-service-constants';
import UserModel from '@/services/models/auth/user.model';
import router from '@/router';
import TokenService from '../../../services/storage.service';
import UserService from '../../../services/user.service';
import ConfigService from '../../../services/config.service';

export default {

  resetErrorConfig ({ commit, state }, { responseConfig }) {
    const latestUrl = state.latestErrorConfig ? state.latestErrorConfig.url : null;
    const currentUrl = responseConfig ? responseConfig.url : null;
    if (latestUrl === currentUrl) {
      commit('setLatestErrorConfig', null);
    }
  },

  async reAuthorize ({ dispatch, commit, state }, { errorConfig }) {
    TokenService.deleteUserModel();

    commit('setLatestErrorConfig', errorConfig);

    await dispatch('authorize');
    return true;
  },

  async authorize ({ commit, dispatch }) {
    const userModel = TokenService.getUserModel();
    const isADAuth = ConfigService.getIsADAuth();

    commit('setIsADAuth', isADAuth);

    if (!ConfigService.getEnableAuth()) {
      return true;
    }

    if (!userModel && !isADAuth) {
      return true;
    }

    if (isADAuth) {
      await dispatch('loginAD');
    }

    return true; // return dispatched login
  },

  isAuthenticated () {
    const userModel = TokenService.getUserModel();
    if (!ConfigService.getEnableAuth()) {
      return true;
    }
    return !_.isUndefined(userModel) && userModel != null;
  },

  getUser () {
    return this.isAuthenticated() ? TokenService.getUserModel() : null;
  },

  setErrorMessage ({ commit }, message) {
    commit('setMessage', message);
    commit('setIsFail', true);
  },

  login ({ commit }, { username, password, rememberMe }) {
    commit('searchService/setCurrentQuery', '', { root: true });
    commit('setIsProcessing', true);
    commit('setIsSuccess', false);
    commit('setIsFail', false);
    commit('setMessage', '');

    return UserService.authenticate(username, password, rememberMe)
      .then(() => {
        commit('setUser', TokenService.getUserModel());
        commit('setMessage', SUCCESS_MESSAGE);
        commit('setIsSuccess', true);
      })
      .catch((err) => {
        commit('setIsFail', true);
        commit('setMessage', FAIL_MESSAGE);
        throw err;
      })
      .finally(() => {
        commit('setIsProcessing', false);
      });
  },

  /**
   * @param {object}  payload
   * @param {boolean} payload.isForced Indicates whether user is forced to be logged out
   * @param {boolean} payload.message
   * @param {string?} payload.targetPath
   */
  logout ({ commit }, payload = {}) {
    const {
      message = '', isForced = false, targetPath,
    } = payload;

    if (isForced) {
      TokenService.deleteUserModel();
      commit('logout', { message, isForced, targetPath });
      return Promise.resolve();
    }

    return UserService.logout()
      .finally(() => {
        TokenService.deleteUserModel();
        commit('logout', { message, isForced, targetPath });
      });
  },
  /**
   * @param {object}  payload
   * @param {boolean} payload.isForced Indicates whether user is forced to be logged out
   * @param {boolean} payload.message
   * @param {string?} payload.targetPath
   */
  aadLogout ({ commit }, payload = {}) {
    const {
      message = '', targetPath, isForced = false,
    } = payload;
    return Promise.resolve().finally(() => {
      TokenService.deleteUserModel();
      commit('logout', { message, isForced, targetPath });
      router.push({
        path: '/icrs-redirect-page',
        query: {
          redirectMessage: 'Logging out from ICRS...',
          redirectUrl: ConfigService.getICRSSALLogoutUrl(),
        },
      });
    });
  },
  async loginAD ({ commit }) {
    await UserService.getUserAuth();
    const userModel = TokenService.getUserModel();
    if (userModel) {
      commit('setUser', userModel);
    }
    return userModel;
  },

  async SAMLLogin ({ commit }) {
    await UserService.getICRSSAMLUserDetails();
    const userModel = TokenService.getUserModel();
    if (userModel) {
      commit('setUser', userModel);
    }
    return userModel;
  },

  async initUser ({ commit, dispatch }) {
    let userModel = null;
    if (ConfigService.getKPOIntegrationEnabled()) {
      const user = await UserService.getUser();
      userModel = new UserModel(user);
      TokenService.setUserModel(userModel);
    } else if (ConfigService.getIsADAuth()) {
      await dispatch('loginAD');
    } else if (ConfigService.getSAMLAuthEnabled()) {
      await dispatch('SAMLLogin');
    } else if (ConfigService.getSALAuthEnabled()) {
      await dispatch('SALLogin');
    } else {
      userModel = TokenService.getUserModel();
    }

    if (userModel) {
      commit('setUser', userModel);
    }
  },

  refreshToken (ctx) {
    return UserService.refreshToken().then((data) => {
      // eslint-disable-next-line camelcase
      const { access_token, refresh_token } = data;
      sessionStorage.setItem('access_token', access_token);
      sessionStorage.setItem('refresh_token', refresh_token);
    }).catch((error) => {
      // refresh token is invalid, e.g. expired
      if (error.response.status === 401 || error.response.status === 400) {
        ctx.dispatch('logout');
      }

      // throw error here so that axios interceptor will not retry previous request after refresh token
      throw error;
    });
  },

  resetPassword () {
    /**
     * @todo close login modal
     */
  },

  SALLogin ({ commit }) {
    return UserService.getICRSUserObject()
      .then(() => {
        const userModel = TokenService.getUserModel();
        if (userModel) {
          commit('setUser', userModel);
        }
        return userModel;
      }).catch(() => {
        console.error('Failed to get user account object');
        return null;
      });
  },
};
