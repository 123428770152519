/**
 * @param {Date} payload
 */
export function handleStartDateChange ({ commit, dispatch }, payload) {
  commit('setStartDate', payload);
  dispatch('triggerSearchQuery');
}

/**
 * @param {Date} payload
 */
export function handleEndDateChange ({ commit, dispatch }, payload) {
  commit('setEndDate', payload);
  dispatch('triggerSearchQuery');
}

export function triggerSearchQuery ({ getters, dispatch }) {
  dispatch('updateSearchDateObject');
  dispatch('searchService/filters/executeFilterSearch', {}, { root: true });
}

export function updateSearchDateObject ({ getters, dispatch }) {
  dispatch('searchService/setCurrentDateObject', getters.dateObject, { root: true });
  dispatch('searchService/newResults/setCurrentDateObject', getters.dateObject, { root: true });
}

/**
 * @param {import('@typings/search').DateRangeItem} payload
 */
export function updateCurrentDate ({ commit, getters, dispatch }, payload) {
  commit('setDateObject', payload);
  dispatch('updateSearchDateObject');
}
