<template>
  <div class="tab-container">
    <b-tabs v-model="activeTab">
      <ItemTab title="Bottom Navigation">
        <!--content here-->
        <PageTable v-if="!isLoading && activeTab === 0 " :tableLists="bottomNavList" @sorted="sortItems" @clickDelete="clickDelete"/>
        <LoadingSpinner v-if="isLoading"
          text="Loading..."
          size="md"
        />
      </ItemTab>
      <ItemTab title="Footer">
        <!--content here-->
        <PageTable v-if="!isLoading && activeTab === 1" :tableLists="footerList" @sorted="sortItems" @clickDelete="clickDelete"/>
        <LoadingSpinner v-if="isLoading"
          text="Loading..."
          size="md"
        />
      </ItemTab>
    </b-tabs>
  </div>
</template>

<script>
import PageTable from '@/components/PageTable.vue';
import { mapGetters } from 'vuex';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import ItemTab from './item-tab/ItemTab.vue';

export default {
  components: {
    ItemTab,
    PageTable,
    LoadingSpinner,
  },
  props: {
    isLoading: Boolean,
  },
  data () {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters('pageManagement', [
      'bottomNavList',
      'footerList',
    ]),
  },
  methods: {
    clickDelete (item, index) {
      this.$emit('clickDelete', item, index);
    },
    sortItems ({ checkPage, idPage1, idPage2 }) {
      this.$emit('sortItems', { checkPage, idPage1, idPage2 });
    },
  },
};
</script>

<style scoped lang="scss">
@mixin font($size, $height, $weight : normal) {
  font-size: $size !important;
  line-height: $height !important;
  font-weight: $weight;
}
.tab-container {
  background-color: #fff;
  padding: 0px 16px 12px 16px;
  border-radius: 4px;
}
/* override css */
/deep/ .tab-content {
  padding: 0 !important;
}

/deep/ .nav-tabs {
  display: flex;
  gap: 48px;

  &>.nav-item {
    a:focus-visible {
      outline: 2px solid gray !important;
    }
    &>.nav-link {
      @include font(16px, 21px);
      border: none !important;
      border-bottom-width: 4px !important;
      border-bottom-style: solid !important;
      border-bottom-color: transparent !important;
      padding: 15px 0px !important;

      &.active {
        border-bottom-color: var(--primary) !important;
      }
    }
  }
}
</style>
