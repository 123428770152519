import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import Vue from 'vue';
import ConfigService from '@/services/config.service';
import router from './router';


export function initApmAgent () {
  Vue.use(ApmVuePlugin, {
    router,
    config: {
      serviceName: 'search-ui',
      serverUrl: ConfigService.getApmServerEndpoint(),
      centralConfig: true,
    },
  });
}
