import _isUndefined from 'lodash/isUndefined';
import SearchParameter from './search-parameter.model';
import SearchServiceTypes from '../../../constants/search-service-constants';

class SearchNewParameter extends SearchParameter {
  constructor (
    searchMode,
    appliedFilters,
    dateObject,
    curPage,
    appliedSort,
    source,
    appliedSynonyms,
    size = SearchServiceTypes.RESULTS_PER_PAGE,
    id,
    searchWithin,
    criteriaId,
    promotedDateObject,
  ) {
    super(
      searchMode,
      appliedFilters,
      dateObject,
      curPage,
      appliedSort,
      source,
      appliedSynonyms,
      size,
      id,
      searchWithin,
      promotedDateObject,
    );

    this.criteriaId = !_isUndefined(criteriaId) ? criteriaId : null;
  }
}

export default SearchNewParameter;
