<template>
  <div id="admin-page">
    <b-container fluid>
      <left-bubble v-show="showLeftBubble"></left-bubble>
      <b-card-group
        deck
        id="pillarDeck"
      >
        <pillar
          v-for="(pillar, $i) in cardsSettings"
          :key="$i"
          :title="pillar.title"
          :subtitle="pillar.subtitle"
          :imgUrl="pillar.imgUrl"
          :isFa="pillar.isFa"
          :variant="pillar.variant"
          :content="pillar.content"
          :additionalContent="pillar.additionalContent"
        ></pillar>
      </b-card-group>
    </b-container>
  </div>
</template>

<script>
import Pillar from './components/Pillar.vue';
import PillarSettings from './PillarSettings';
import LeftBubble from './components/LeftBubble.vue';

export default {
  name: 'admin-page',
  components: {
    Pillar,
    LeftBubble,
  },
  computed: {
    showLeftBubble () {
      return this.mouseOverState === 0;
    },
  },
  data () {
    return {
      cardsSettings: PillarSettings.cards,
      mouseOverState: 0,
    };
  },
  methods: {
    mouseOver ($event) {
      this.mouseOverState = $event;
    },
  },
};
</script>

<style lang="scss" scoped>
#admin-page {
  background: $blue-gray-50;
  max-width: 1400px;
  margin: auto;
}
#pillarDeck {
  padding: 5em 10em;
  display: -webkit-box;
  min-width: 1400px;
}
#left_bubble {
  left: -0.8em;
  top: -1em;
}
::v-deep .floating_arrow {
  position: absolute;
  right: -1.8em;
  top: 34%;
  z-index: 10;
  width: 56px;
}
::v-deep .fixed_height {
  display: flex;
  flex-flow: column;
  flex: 0 1 auto;
}
::v-deep p {
  color: $blue-gray-600;
}
::v-deep strong {
  font-weight: $weight-semi-bold !important;
}
</style>
