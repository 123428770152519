<template>
  <div class="icrs-wrapper-page">
    <!-- Start of JDS Scroll to Top Widget -->
    <!-- <a
      v-if="showScrollToTop"
      id="scroll-to-top"
      title="Scroll to Top"
      class="show"
      @click="scrollTop"
    >
      <span class="fas fa-chevron-up"></span>
    </a> -->
    <!-- End of JDS Scroll to Top Widget -->
    <ScrollTop />
    <div class="wrapper-page-content">
      <slot></slot>
      <ICRSFooter />
    </div>
  </div>
</template>

<script>
import ScrollTop from '@/components/scroll-top/ScrollTop.vue';
import ICRSFooter from '@/modules/icrs-footer/ICRSFooter.vue';

export default {
  name: '',
  components: {
    ICRSFooter,
    ScrollTop
  },
  methods: {
    // scrollTop () {
    //   if (this.mobileMode) {
    //     this.showSearchOptions = false;
    //   }
    //   document.body.scrollTop = 0; // For Safari
    //   document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // },
    // onScroll () {
    //   // Get the current scroll position
    //   const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    //   // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
    //   if (currentScrollPosition < 0) {
    //     return;
    //   }

    //   if (currentScrollPosition === 0) {
    //     this.showScrollToTop = false;
    //   } else {
    //     this.showScrollToTop = true;
    //   }
    // },
  },
  data () {
    return {
      // showScrollToTop: false,
    };
  },
  mounted () {
    // window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy () {
    // window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style scoped lang="scss">
.icrs-wrapper-page {
  height: 100%;

  .wrapper-page-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
