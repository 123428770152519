<template>
  <b-modal
    centered
    :id="id"
    :size="size"
    :title="title"
    :static="true"
    :lazy="true"
    @hide="onCloseModal"
    header-class="border-0"
    footer-class="border-0"
    no-close-on-backdrop
  >
    <template slot="modal-footer">
      <b-btn
        @click="handleCancel"
        class="confirm-btn mr-2 pl-4 pr-4"
        variant="link" >
        Cancel
      </b-btn>
      <b-btn
        :disabled="disableSubmit"
        @click="handleSave"
        class="confirm-btn ml-2 pl-4 pr-4"
        variant="primary" >
        Save
      </b-btn>
    </template>
    <div>
      <div>
        <label class="font-weight-bold" >Topic</label>
        <multiselect
          selectLabel=''
          deselectLabel=''
          selectedLabel=''
          :disabled="selectedTag.key ? true : false"
          :value="classModel"
          @input="inputValue => onSelectClass(inputValue)"
          label="value"
          track-by="key"
          tag-placeholder="Add this as new tag"
          placeholder="Select a topic"
          :options="classValue"
          open-direction="below"
        />
      </div>
      <div class="mt-3" >
        <label class="font-weight-bold" >Individuals</label>
        <multiselect
          selectLabel=''
          deselectLabel=''
          selectedLabel=''
          :value="individualModel"
          @input="inputValue => onSelectIndividual(inputValue)"
          tag-placeholder="Add this as new tag"
          placeholder="Search or select individuals"
          :options="individualValue"
          :multiple="true"
          open-direction="below"
          :close-on-select="false"
          :clear-on-select="false"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _ from 'lodash';

export default {
  name: 'add-tag',
  components: {
    Multiselect,
  },
  props: {
    selectedTag: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
    classOptions: {
      type: [Array, Object],
      default: () => ({}),
    },
    individualOptions: {
      type: [Array, Object],
      default: () => ({}),
    },
    tagsValue: {
      type: [Array, Object],
      default: () => ({}),
    },
  },
  data () {
    const filterClassValue = (topic, currentTopic) => {
      if (currentTopic) {
        return topic.filter(({ key }) => !currentTopic.includes(key));
      }
      return topic;
    };
    const currentTagsValue = this.tagsValue.tags.topic;
    const classValue = Object.entries(this.classOptions)
      .map(([key, value]) => ({
        key,
        value,
      }));
    const filteredClassValue = filterClassValue(classValue, currentTagsValue);
    // .filter(({ key }) => !currentTagsValue.includes(key));

    const { key = '', value = [] } = this.selectedTag;
    const classModel = classValue.filter(item => item.key === key)[0];

    return {
      classValue: filteredClassValue,
      classModel,
      individualModel: value,
    };
  },
  methods: {
    onCloseModal () {
      this.classModel = '';
      this.individualModel = [];
      this.$emit('closed');
    },
    onSelectClass (value) {
      this.classModel = value;
      this.individualModel = [];
    },
    onSelectIndividual (value) {
      this.individualModel = value;
    },
    handleCancel () {
      this.$bvModal.hide(this.id);
      this.$emit('cancel');
    },
    handleSave () {
      const { key = '' } = this.classModel;
      const tags = {
        key,
        value: this.individualModel,
        isEditMode: !!this.selectedTag.key,
      };
      this.$emit('submit', tags);
    },
  },
  computed: {
    disableSubmit () {
      if (this.classModel && this.individualModel.length) {
        return false;
      }
      return true;
    },
    individualValue () {
      if (!_.isEmpty(this.classModel)) {
        const { key } = this.classModel;
        // const currentValue = this.tagsValue.tags[key];
        // const individualOptions = this.individualOptions[key].filter(item => !currentValue.includes(item));
        return this.individualOptions[key];
      }
      return [];
    },
  },
};
</script>
