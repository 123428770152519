<template>
  <div class="icrs-home">
    <ScrollTop />
    <div class="icrs-home-search-bar">
      <ICRSSearchBarWrapper
        v-if="!isLoading"
        :postMessageOnSearch="true"
        guidedDisplayOption="landing"
      />
      <div
        v-if="isLoading"
        class="d-flex align-items-center justify-content-center"
        style="margin: 200px 0px 100px 0px">
        <LoadingSpinner size="lg" />
      </div>
    </div>
    <ICRSFooter />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ICRSSearchBarWrapper from '@/modules/icrs-search-bar-wrapper/ICRSSearchBarWrapper.vue';
import ICRSFooter from '@/modules/icrs-footer/ICRSFooter.vue';
import icrsService from '@/services/icrs-service/icrs-service';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import ScrollTop from '@/components/scroll-top/ScrollTop.vue';

export default {
  name: 'IcrsHome',
  components: {
    ICRSSearchBarWrapper,
    ICRSFooter,
    LoadingSpinner,
    ScrollTop
  },

  data () {
    return {
      // showScrollToTop: false,
      isLoading: true,
    };
  },
  async mounted () {
    if (this.$config[this.$options.name]) {
      this.config = this.$config[this.$options.name];
    }
    window.addEventListener('scroll', this.onScroll);
    const allGuidedCategories = await icrsService.getGuidedCategories();
    this.setAllGuidedCategories(allGuidedCategories);
    this.isLoading = false;
  },
  methods: {
    ...mapActions('icrs', ['setAllGuidedCategories']),
    // onScroll () {
    //   // Get the current scroll position
    //   const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    //   // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
    //   if (currentScrollPosition < 0) {
    //     return;
    //   }

    //   if (currentScrollPosition === 0) {
    //     this.showScrollToTop = false;
    //   } else {
    //     this.showScrollToTop = true;
    //   }
    // },
  },
  beforeDestroy () {
    // window.removeEventListener('scroll', this.onScroll);
  },
};
</script>
<style lang="scss" scoped>
.icrs-home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
