import KMService from '../../../services/km.service';

export default {
  getConcepts () {
    return KMService.getConcepts().then(response => response);
  },

  getRelations (ctx, individualId) {
    return KMService.getRelations(individualId);
  },

  getProperties ({ commit }, id) {
    return KMService.getProperties(id);
  },
};
