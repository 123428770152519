<template>
  <div class="ontology-search">
    <BasicInformation class="ontology-search-information" />
    <div class="ontology-search__bottom-container">
      <div class="ontology-search-container">
        <OntologySearchGraph class="ontology-search-graph" />
        <OntologySearchClassContainer
          class="ontology-search-table"
          v-on="$listeners"
          :classFilter="classFilter"
        />
      </div>
      <div class="filters-container">
        <OntologySearchTableFilters @class-filter="handleClassFilter" />
      </div>
    </div>
  </div>
</template>

<script>
import OntologySearchGraph from './components/OntologySearchGraph';
import OntologySearchClassContainer from './components/OntologySearchClassContainer';
import BasicInformation from './components/BasicInformation';
import OntologySearchTableFilters from './components/OntologySearchTableFilters.vue';

export default {
  data () {
    return {
      classFilter: {},
    };
  },
  components: {
    OntologySearchGraph,
    OntologySearchClassContainer,
    BasicInformation,
    OntologySearchTableFilters,
  },
  methods: {
    handleClassFilter (event) {
      this.classFilter = event;
    },
  },
};
</script>
<style lang="scss" scoped>
.ontology-search {
  height: 100%;
  padding: 2em;
  padding-top: 0;

  &__bottom-container {
    display: flex;
    height: 100%;
  }
}
.ontology-search-container {
  width: 80%;
}
.filters-container {
  width: 20%;
  margin-left: 1em;
}
.ontology-search-graph {
  margin-bottom: 3em;
  height: 80%;
}
.ontology-search-table {
  padding-bottom: 6vh;
}
.ontology-search-information {
  margin-bottom: 1.5em;
}
</style>
