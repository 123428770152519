<template>
  <div class="icrs-guided-search-boxes-section" :class="{'is-modal': isModal}">
    <div class="row guided-row">
      <div v-if="(!mobileMode && activeGuidedPrefix >= 0)
        || (mobileMode && activeGuidedPrefix === 0 && selectedLevel1.code === 'All')"
        class="col-md-4 guided-column level-1-column level-item">
        <div class="level-text">Level 1</div>
        <div v-if="!mobileMode" class="guided-options">
          <div v-for="(category, index) in level1Categories" :key="`level1_${category.code}`"
            class="guided-option guided-1-option" :class="{
              'selected-guided-option': category.code === selectedLevel1.code && category.code !== 'All',
              'selected-guided-1-option': category.code === selectedLevel1.code
            }" @mouseover="handleSearch(0, category)">
            <hr v-if="index === 0" class="guided-divider" />
            <p class="guided-option-label">
              <head-svg class="guided-icon" v-if="category.elitLabel.includes('Head')" />
              <neck-svg class="guided-icon" v-if="category.elitLabel.includes('Neck')" />
              <back-thoracic-svg class="guided-icon" v-if="category.elitLabel.includes('Back')" />
              <upper-limb-svg class="guided-icon" v-if="category.elitLabel.includes('Upper')" />
              <torso-internal-svg class="guided-icon" v-if="category.elitLabel.includes('Torso')" />
              <lower-limb-svg class="guided-icon" v-if="category.elitLabel.includes('Lower')" />
              <miscellaneous-svg class="guided-icon" v-if="category.elitLabel.includes('Miscellaneous')" />
              <psychiatric-svg class="guided-icon" v-if="category.elitLabel.includes('Psychiatric')" />
              &nbsp;
              <span class="guided-option-text">{{ formatGuidedDisplay(category.elitLabel || category.code) }}</span>
              <font-awesome-icon v-if="category.code === selectedLevel1.code &&
                category.code !== 'All'" class="guided-option-icon"
                :icon="['fa', 'chevron-right']" size="lg" />
              <!--eslint-disable-next-line vue/no-parsing-error-->
            </p>
            <hr class="guided-divider" />
          </div>
        </div>
        <div v-if="mobileMode" class="guided-options">
          <div v-for="(category, index) in level1Categories" :key="`level1_${category.code}`"
            class="guided-option guided-1-option" :class="{
              'selected-guided-option': category.code === selectedLevel1.code && category.code !== 'All',
              'selected-guided-1-option': category.code === selectedLevel1.code
            }" @click="handleSearch(0, category)">
            <hr v-if="index === 0" class="guided-divider" />
            <p class="guided-option-label">
              <head-svg class="guided-icon" v-if="category.elitLabel.includes('Head')" />
              <neck-svg class="guided-icon" v-if="category.elitLabel.includes('Neck')" />
              <back-thoracic-svg class="guided-icon" v-if="category.elitLabel.includes('Back')" />
              <upper-limb-svg class="guided-icon" v-if="category.elitLabel.includes('Upper')" />
              <torso-internal-svg class="guided-icon" v-if="category.elitLabel.includes('Torso')" />
              <lower-limb-svg class="guided-icon" v-if="category.elitLabel.includes('Lower')" />
              <miscellaneous-svg class="guided-icon" v-if="category.elitLabel.includes('Miscellaneous')" />
              <psychiatric-svg class="guided-icon" v-if="category.elitLabel.includes('Psychiatric')" />
              &nbsp;
              <span class="guided-option-text">{{ formatGuidedDisplay(category.elitLabel || category.code) }}</span>
              <font-awesome-icon v-if="category.code === selectedLevel1.code &&
                category.code !== 'All'" class="guided-option-icon"
                :icon="['fa', 'chevron-right']" size="lg" />
              <!--eslint-disable-next-line vue/no-parsing-error-->
            </p>
            <hr class="guided-divider" />
          </div>
        </div>
      </div>
      <div v-if="!mobileMode && selectedLevel1.code === 'All'" class="col-md-8 get-started-column level-2-column level-item">
        <p class="get-started-text">
          <a href="/#/page-management/preview/128?from=Footer" target="_blank" style="text-decoration: none; color: black !important;">
            <i class="fa fa-info-circle"></i>
          </a>
          Get started by selecting a category on the left.
        </p>
      </div>
      <div
        v-if="(!mobileMode && selectedLevel1.code !== 'All')
          || (mobileMode && ((activeGuidedPrefix === 0 && selectedLevel1.code !== 'All') ||
          (activeGuidedPrefix === 1 && selectedLevel2.code === 'All')))"
        class="col-md-4 guided-column level-2-column  level-item">
        <div class="level-text">Level 2</div>
        <div v-if="!mobileMode" class="guided-options">
          <div v-for="(category, index) in level2CategoriesForSelectedLevel1" :key="`level2_${category.code}`"
            class="guided-option guided-2-option" :class="{
              'selected-guided-option': category.code === selectedLevel2.code && category.code !== 'All',
              'selected-guided-2-option': category.code === selectedLevel2.code && category.code !== 'All'
            }" @mouseover="handleSearch(1, category, 'mouse')" @click="handleSearch(1, category, 'click')">
            <hr v-if="index === 0" class="guided-divider" />
            <p class="guided-option-label">
              <span v-if="category.code === 'All'" class="guided-option-text">{{ formatAllGuidedDisplay(selectedLevel1.elitLabel || selectedLevel1.code)
              }}</span>
              <span v-if="category.code !== 'All'" class="guided-option-text">{{ formatGuidedDisplay(category.elitLabel || category.code) }}</span>
              <font-awesome-icon v-if="category.code === selectedLevel2.code && category.code !== 'All'" class="guided-option-icon"
                :icon="['fa', 'chevron-right']" size="lg" />
            </p>
            <hr class="guided-divider" />
          </div>
        </div>
        <div v-if="mobileMode" class="guided-options">
          <div class="guided-option guided-2-option" @click="handleSearch(1, {code : 'Previous Selection'})">
            <hr class="guided-divider" />
            <p class="guided-option-label">
              <span class="guided-option-text">Previous Selection</span>
              <font-awesome-icon class="guided-option-icon" :icon="['fa', 'chevron-left']" size="lg" />
            </p>
          </div>
          <div v-for="(category, index) in level2CategoriesForSelectedLevel1" :key="`level2_${category.code}`"
            class="guided-option guided-2-option" :class="{
              'selected-guided-option': category.code === selectedLevel2.code && category.code !== 'All',
              'selected-guided-2-option': category.code === selectedLevel2.code && category.code !== 'All'
            }" @click="handleSearch(1, category, 'click')">
            <hr v-if="index === 0" class="guided-divider" />
            <p class="guided-option-label">
              <span v-if="category.code === 'All'" class="guided-option-text">{{ formatAllGuidedDisplay(selectedLevel1.elitLabel || selectedLevel1.code)
              }}</span>
              <span v-if="category.code !== 'All'" class="guided-option-text">{{ formatGuidedDisplay(category.elitLabel || category.code) }}</span>
              <font-awesome-icon v-if="category.code === selectedLevel2.code && category.code !== 'All'" class="guided-option-icon"
                :icon="['fa', 'chevron-right']" size="lg" />
            </p>
            <hr class="guided-divider" />
          </div>
        </div>
      </div>
      <div v-if="(!mobileMode && selectedLevel2.code !== 'All')
        || (mobileMode && ((activeGuidedPrefix === 1 && selectedLevel2.code !== 'All') || activeGuidedPrefix === 2))"
        class="col-md-4 guided-column level-3-column level-item">
        <div class="level-text">Level 3</div>
        <div v-if="mobileMode" class="guided-option guided-3-option" @click="handleSearch(2, {code: 'Previous Selection'})">
          <hr class="guided-divider" />
          <p class="guided-option-label">
            <span class="guided-option-text">Previous Selection</span>
            <font-awesome-icon class="guided-option-icon" :icon="['fa', 'chevron-left']" size="lg" />
          </p>
        </div>
        <div class="guided-options">
          <div v-for="(category, index) in level3CategoriesForSelectedLevel2" :key="`level3_${category.code}`"
            class="guided-option guided-3-option" :class="{
              'selected-guided-option': category.code === selectedLevel3.code && category.code !== 'All',
              'selected-guided-3-option': category.code === selectedLevel3.code && category.code !== 'All'
            }" @click="handleSearch(2, category)">
            <hr v-if="index === 0" class="guided-divider" />
            <p v-if="category.code === 'All'" class="guided-option-label">{{ formatAllGuidedDisplay(selectedLevel2.elitLabel || selectedLevel2.code) }}</p>
            <p v-if="category.code !== 'All'" class="guided-option-label">{{ formatGuidedDisplay(category.elitLabel || category.code) }}</p>
            <hr class="guided-3-divider" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ICRSService from '@/services/icrs-service';
import HeadSvg from '@/assets/guided_icons/head.svg';
import MiscellaneousSvg from '@/assets/guided_icons/miscellaneous.svg';
import PsychiatricSvg from '@/assets/guided_icons/psychiatric.svg';
import NeckSvg from '@/assets/guided_icons/neck-cervical.svg';
import BackThoracicSvg from '@/assets/guided_icons/back-thoracic.svg';
import UpperLimbSvg from '@/assets/guided_icons/upper-limb.svg';
import TorsoInternalSvg from '@/assets/guided_icons/torso-internal.svg';
import LowerLimbSvg from '@/assets/guided_icons/lower-limb.svg';

export default {
  name: 'ICRSGuidedSearchColumns',
  props: {
    activeGuidedValue: {
      type: String,
      default: '',
    },
    mobileMode: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
    },
  },
  components: {
    HeadSvg,
    NeckSvg,
    BackThoracicSvg,
    UpperLimbSvg,
    TorsoInternalSvg,
    LowerLimbSvg,
    MiscellaneousSvg,
    PsychiatricSvg,
  },
  data () {
    return {
      level1Categories: [],
      guidedPrefixIgnoreList: [],
    };
  },
  computed: {
    ...mapGetters('icrs', [
      'allGuidedCategories',
      'dropdownGuidedTag',
      'activeGuidedPrefix',
      'selectedLevel1',
      'selectedLevel2',
      'selectedLevel3',
      'level2CategoriesForSelectedLevel1',
      'level3CategoriesForSelectedLevel2',
    ]),
    guidedCategoriesFilter () {
      const temp = { ...this.allGuidedCategories };
      if (temp) {
        temp.level1 = temp.level1.filter(injury => !injury.newAdded);
        temp.level2 = temp.level2.filter(injury => !injury.newAdded);
        temp.level3 = temp.level3.filter(injury => !injury.newAdded);
      }
      return temp;
    },
  },
  methods: {
    ...mapActions('icrs', [
      'setSelectedLevel1',
      'setSelectedLevel2',
      'setSelectedLevel3',
      'setLevel2CategoriesForSelectedLevel1',
      'setLevel3CategoriesForSelectedLevel2',
    ]),
    handleSearch (level, category, mouseAction) {
      if (
        (!(level === 1 && category.code === 'All')
          && !(level === 0 && this.selectedLevel1.code === category.code))
        || mouseAction === 'click') {
        this.$emit('updateGuidedDropdowns', 'columns', level, category);
      }

      if (level === 1 && category.code === 'All') {
        this.setSelectedLevel2({ code: 'All' });
        this.setSelectedLevel3({ code: 'All' });
        this.setLevel3CategoriesForSelectedLevel2([{ code: 'All' }]);
      }
    },
    getGuidedBgVariant (guidedTag) {
      // if (guidedTag !== 'Previous Selection') {
      //   return 'primary';
      // }
      return null;
    },
    getGuidedBorderVariant (guidedTag) {
      if (guidedTag === 'Previous Selection') {
        return 'dark';
      }
      return 'primary';
      // return null;
    },
    formatGuidedDisplay (term) {
      let formattedTerm = term;
      // eslint-disable-next-line array-callback-return
      this.guidedPrefixIgnoreList.map((prefix) => {
        formattedTerm = formattedTerm.replace(prefix, '');
      });
      return formattedTerm;
    },
    formatAllGuidedDisplay (term) {
      return `Discover All Cases Tagged With "${this.formatGuidedDisplay(term)}"`;
    },
  },
  mounted () {
    this.level1Categories = [...this.guidedCategoriesFilter.level1];
    this.guidedPrefixIgnoreList = ICRSService.getGuidedPrefixIgnoreList();
  },
};
</script>


<style scoped lang="scss">
.get-started-text a:focus-visible {
  outline: 2px solid gray;
}

@media screen and (min-width: 767.98px) {
  .is-modal {
    & .row.guided-row {
      position: relative;
      height: 565px;
      overflow: hidden;
    }

    & .level-item {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      &.level-1-column,
      &.level-2-column,
      &.level-3-column {
        overflow-y: scroll;
        @-moz-document url-prefix() {
          scrollbar-width: thin;
          scrollbar-color: #939599 transparent;
        }

      }

      &.level-2-column {
        left: calc(100% / 3);
      }
      &.level-3-column {
        left: calc(calc(100% / 3) * 2);
      }
    }
  }
}

</style>
