<template>
  <div id="keyword-answers" class="p-3" v-if="answers.length">
    <div class="title mb-2">Answer</div>
    <div
      @click="handleLinkClick(text)"
      v-for="text in answers"
      :key="text"
      class="body"
    >
      &nbsp;{{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    answers: {
      type: Array,
      default () {
        return [];
      },
    },
  },

  methods: {
    handleLinkClick (text) {
      this.$emit('link-click', text);
    },
  },
};
</script>

<style lang="scss" scoped>
#keyword-answers {
  border: 1px solid $blue-gray-100;
  border-radius: $radius-4;

  .title {
    font-size: $font-lg;
    font-weight: $weight-semi-bold;
    color: $blue-gray-500;
  }
  .body {
    font-size: $font-md;
  }
}
</style>
