import _uniqBy from 'lodash/uniqBy';
import constants from '../../../constants/search-service-constants';
import SearchRequestParameter from './search-request-parameter.model';

export default class AggregateParameter extends SearchRequestParameter {
  /**
   * @param {string} searchQuery
   * @param {import('./search-request-parameter.model').default} searchRequestParam
   * @param {import('@typings/search').AggregateParameterConfig} config
   */
  constructor (searchQuery, param, config = {}) {
    super(searchQuery, param);
    const { includes = [], excludes = [], tempTopicsQuery = { should: [], must: [] } } = config;
    const additionalTopics = AggregateParameter.toTopicsQuery(includes);

    let newMustTopicsQuery = this.topicsQuery.must.concat(additionalTopics, tempTopicsQuery.must);
    newMustTopicsQuery = _uniqBy(newMustTopicsQuery, item => item.field + item.value);

    const excludedKeys = AggregateParameter
      .toTopicsQuery(excludes)
      .map(AggregateParameter.createTopicQueryUniqueKey);
    newMustTopicsQuery = newMustTopicsQuery.filter((item) => {
      const key = AggregateParameter.createTopicQueryUniqueKey(item);
      return !excludedKeys.includes(key);
    });

    this.topicsQuery.must = newMustTopicsQuery;
    this.aggsParam = _uniqBy(newMustTopicsQuery.map(query => query.field));
    this.searchMode = constants.SEARCH_MODE.AGGREGATION;
  }

  /**
   * @param {string} searchQuery
   * @param {import('./search-request-parameter.model').default} searchRequestParam
   * @param {import('@typings/search').AggregateParameterConfig} config
   */
  static create (searchQuery, searchRequestParam, config) {
    return new AggregateParameter(searchQuery, searchRequestParam, config);
  }

  /**
   * @param  {import('./filter-item.model').default} filter
   * @return {string}
   */
  static createTopicQueryUniqueKey ({ field, value }) {
    return `${field}_${value}`;
  }

  /**
   * @param  {import('./filter-item.model').default[]} filters
   * @return {import('@typings/search').TopicQuery[]}
   */
  static toTopicsQuery (filters) {
    return filters.map(filter => ({
      field: filter.groupName,
      value: filter.key,
    }));
  }
}
