import { extend } from 'vee-validate';
import { required, integer } from 'vee-validate/dist/rules';

extend('doc-required', {
  ...required,
  message: 'Required field',
  computesRequired: true,
});

extend('doc-integer', {
  ...integer,
  message: 'Numbers only',
  computesRequired: true,
});
