<template>
  <b-tooltip
    custom-class="t-tooltip"
    :title="title"
    :target="target"
    :triggers="triggers"
    :placement="placement"
    :fallback-placement="fallbackPlacement"
    :variant="variant"
    :delay="delay"
    :boundary="boundary"
    :boundary-padding="boundaryPadding"
    :offset="offset"
    :no-fade="noFade"
    :container="container"
    :show="show"
    :disabled="disabled"
    :id="id"
  >
    <slot></slot>
  </b-tooltip>
</template>

<script>
import { BTooltip } from 'bootstrap-vue';
/**
 * Tooltip component.
 */
export default {
  name: 'Tooltip',
  status: 'release',
  components: {
    BTooltip,
  },
  props: {
    /**
     *
     * Text to place in the tooltip
     */
    title: {
      type: String,
    },
    /**
     *
     * Element string ID, or a reference to an element or component, that you want to trigger the tooltip.
     */
    target: {
      type: [String, Function, Object],
    },
    /**
     *
     * Specify which triggers will show the tooltip. Supported values are 'click', 'hover', 'focus'. Refer to the docs for special triggers 'blur' and 'manual'
     */
    triggers: {
      type: [String, Array],
      default: 'hover focus',
    },
    /**
     *
     * Placement of the tooltip: One of 'top', 'bottom', 'right', 'left', 'top-left', 'top-right', 'bottom-left', 'bottom-right', 'left-top', 'left-bottom', 'right-top', 'right-bottom'
     */
    placement: {
      type: String,
      default: 'top',
    },
    /**
     *
     * placement to use when the tooltip would be out of boundaries. Refer to the docs for more details
     */
    fallbackPlacement: {
      type: [String, Array],
      default: 'flip',
    },
    /**
     *
     * Applies one of the Bootstrap theme color variants to the component
     */
    variant: {
      type: String,
    },
    /**
     *
     * Value for the show and hide delay. Applies to both show and hide when specified as a number or string. Use object form to set show and hide delays individually
     */
    delay: {
      type: [Number, String, Object],
      default: 50,
    },
    /**
     *
     * The boundary constraint of the tooltip: 'scrollParent', 'window', 'viewport', or a reference to an HTMLElement or component
     */
    boundary: {
      type: [String, Object],
      default: 'scrollParent',
    },
    /**
     *
     * The tooltip will try and stay away from the edge of the boundary element by the number of pixels specificed
     */
    boundaryPadding: {
      type: [Number, String],
      default: 5,
    },
    /**
     *
     * Offset (in pixels) for the arrow center compared to the trigger target element
     */
    offset: {
      type: [Number, String],
      default: 0,
    },
    /**
     *
     * When set to 'true', disables the fade animation/transition on the component
     */
    noFade: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * The container element to append the rendered tooltip when visible. Default's to the body element
     */
    container: {
      type: [String, Object],
    },
    /**
     *
     * When set will show the tooltip
     */
    show: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * When set to 'true', disables the component's functionality and places it in a disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     *
     * Used to set the 'id' attribute on the rendered content, and used as the base to generate any additional element IDs as needed
     */
    id: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.t-tooltip {
  .tooltip-inner {
    min-height: 28px;
    font-size: $font-md;
    line-height: $ln-height-20;
    font-weight: $weight_normal;
    opacity: 0.7;
  }
  &.tooltip .arrow {
    display: none;
  }
}
</style>

<docs>
  ```jsx
  <div>
    <div class="mt-lg">
      <span id="button-wrapper" class="d-inline-block">
        <Button variant="primary">Hover Me</Button>
      </span>
      <Tooltip target="button-wrapper">sample tooltip sample tooltip sample tooltip sample tooltip sample tooltip sample tooltipsample tooltipsample tooltip sample tooltip</Tooltip>
    </div>
  </div>
  ```
</docs>
