<template>
  <div class="liveDemoComponent">
    <h1>Live Demo</h1>
    <div class="demoWrap">
      <div class="liveDemo">
        <search-bar
          class="taiger_styles"
          style="display: block; margin:4px;"
          v-bind="labelContent[activeIndex].props"
        >
        </search-bar>
      </div>
      <div>
        <div class="demoTitleWrap">
          <div
            v-for="(item,index) in labelContent"
            :key="item.id"
            @click="selectStyle (index) "
          >
            <strong
              class="demoTitle clickable"
              :class="{ 'active': index===activeIndex } "
            >
              {{item.title}}
            </strong>
          </div>
        </div>
        <div
          v-for="(item,index) in labelContent"
          :key="item.id"
        >
          <pre v-if="activeIndex===index"><code>
{{item.code}}
            </code>
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from '../../../../modules/search-bar/SearchBar.vue';

export default {
  name: 'LiveDemo',
  components: {
    SearchBar,
  },
  data () {
    return {
      activeIndex: 0,
      labelContent: [
        {
          title: 'Input Placeholder',
          code:
            `<search-bar
  class="taiger_styles"
  style="display: block; margin:4px;"
  search-input-placeholder="This is a search bar"
  >
</search-bar>`,
          props: {
            searchInputPlaceholder: 'This is a search bar',
            searchButtonText: 'Search',
            defaultTemplatePlugin: null,
          },
        },
        {
          title: 'Button Text',
          code:
            `<search-bar
  class="taiger_styles"
  style="display: block; margin:4px;"
  search-button-text="Go"
  >
</search-bar>`,
          props: {
            searchInputPlaceholder: ' ',
            searchButtonText: 'Go',
            defaultTemplatePlugin: null,
          },
        },
        {
          title: 'Template Plugin',
          code:
            `<search-bar
  class="taiger_styles"
  style="display: block; margin:4px;"
  default-template-plugin="TrendingSearchPlugin"
  >
</search-bar>`,
          props: {
            searchInputPlaceholder: ' ',
            searchButtonText: 'Search',
            defaultTemplatePlugin: 'TrendingSearchPlugin',
          },
        },
      ],
    };
  },
  methods: {
    selectStyle (index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
.liveDemoComponent {
  padding-left: 100px;
  width: 920px;
}
h1 {
  font-size: $font-xl;
  line-height: $ln-height-xl;
  font-weight: 500;
  padding-bottom: $gap-lg;
  padding-top: 60px;
}
.demoTitleWrap {
  padding-left: $gap-lg;
  display: block;
  background-color: $blue-200;
  margin-bottom: 0px !important;
  color: $blue-gray-300;
  font-weight: $weight-light;
  padding-top: $gap-xs;
  overflow: hidden;
}
.demoTitle {
  border: none;
  padding-bottom: $gap-xs;
  float: left;
  font-size: $font-md !important;
  line-height: $ln-height-20 !important;
  margin-right: $gap-lg;
}
pre {
  margin-top: 0px;
  white-space: pre;
  margin-bottom: -29px;
}
code {
  padding-left: 60px;
  display: block;
  margin-top: 0px;
  background-color: $blue-200;
  border-bottom-left-radius: $gap-xxs;
  border-bottom-right-radius: $gap-xxs;
  border-top: 1px solid $blue-gray-100;
}
.liveDemo {
  padding-left: $gap-xl;
  padding-right: $gap-xl;
  display: block;
  padding-top: 80px;
  margin-top: 0px;
  padding-bottom: 80px;
  background-color: $blue-gray-50;
  border-top-left-radius: $gap-xxs;
  border-top-right-radius: $gap-xxs;
}
.active {
  color: $blue-500;
  border-bottom: 2px solid $blue-500;
}
.clickable {
  cursor: pointer;
}
</style>
