<template>
  <div>
    <b-alert
      style="{width:'400px'}"
      class="position-fixed fixed-top m-5"
      :show="dismissCountDown"
      dismissible
      fade
      :variant="variant"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      <font-awesome-icon
        class="mr-2"
        icon="check-circle"
        style="{ bottom: '-2px', position: 'relative' }"
      ></font-awesome-icon>
      <strong class="mr-2">{{title}}</strong>
    </b-alert>

    <div v-if="showButton">
      <b-button
        @click="EventBusService.emitNotificationEvent({title:'Great Success!'})"
        class="mb-2"
      >click</b-button>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import EventBusService from '@/services/event-bus.service';
import NotificationsModel from '@/services/models/notifications/notification.model';

export default {
  /**
   * @todo
   * States for warn and error
   */
  props: {
    showButton: {
      type: Boolean,
      default: false,
    },
    stubComponent: {
      // acts as controller for the widget already declared globally
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      EventBusService,
      dismissCountDown: 0,
      title: '',
      autoHideDelay: 0,
      hasBody: false,
      bodyContent: '',
      variant: 'success',
    };
  },
  methods: {
    showNotification () {
      this.dismissCountDown = this.autoHideDelay / 1000;
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    initNotification (args) {
      if (this.stubComponent) {
        return;
      }
      const data = new NotificationsModel(args);
      this.title = data.title;
      this.autoHideDelay = data.autoHideDelay;
      this.hasBody = data.bodyContent && data.bodyContent.length > 0;
      this.bodyContent = data.bodyContent;
      this.variant = data.variant;
      this.showNotification();
    },
  },
  created () {
    EventBus.$on('triggerNotification', (data) => {
      this.initNotification(data);
    });
  },
};
</script>

<style scoped  lang="scss">
.alert {
  width: 400px;
  z-index: 1070; // > modal, < loading
}
</style>
