<template>
  <div
    id="app"
    class="wrapper sticky"
  >
    <Modal
      okTitle="OK"
      :hideCancel="true"
      :noCloseOnBackdrop="true"
      :noCloseOnEsc="true"
      :visible="showLogoutNotifDialog"
      @hidden="onHideLogoutNotifDialog"
    >
      <p>
        We have logout your previous session as it is still active.
      </p>
      <p>
        This is because we only allow single concurrent session per user for ICRS site.
      </p>
    </Modal>
    <Navbar
      v-if="isNavbarShowing && isConfigSet"
      :search-widgets="widgets"
      :connector-route-settings="connectorRouteSettings"
      @logout="handleLogout"
    />
    <div id="router-window">
      <router-view v-if="shouldDisplayRouterView && (logged || !getIsAddDev)" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import connectorRouteSettings from '@/pages/connector-page/RouteSettings';
import Navbar from './components/Navbar.vue';
import Modal from './components/Modal.vue';
import widgetCatalogue from './services/widget-catalogue.service';
import ConfigService from './services/config.service';

export default {
  components: {
    Navbar,
    Modal,
  },
  data () {
    return {
      connectorRouteSettings,
      logged: false,
    };
  },
  computed: {
    ...mapGetters('app', [
      'isNavbarShowing',
      'isConfigSet',
    ]),
    ...mapGetters('authService', {
      user: 'getUser',
    }),
    widgets () {
      return widgetCatalogue.components;
    },
    shouldDisplayRouterView () {
      const unAuthenticatedPaths = [
        '/error',
        '/icrs-redirect-page',
        '/login-page',
      ];
      // if return false the router will be a blank page.
      return this.isConfigSet || unAuthenticatedPaths.indexOf(this.$route.path) !== -1;
    },
    showLogoutNotifDialog () {
      return !!this.$route.query['invalidated-last-session'];
    },
    getIsAddDev () {
      return ConfigService.getSALAuthEnabled();
    },
  },
  methods: {
    ...mapActions('authService', [
      'initUser',
      'logout',
      'isAuthenticated',
    ]),
    ...mapActions('app', [
      'showNavbar',
      'hideNavbar',
      'configDeactivated',
    ]),
    handleLogout () {
      this.logout();
      if (ConfigService.getSAMLAuthEnabled()) {
        this.$router.push({
          path: '/icrs-redirect-page',
          query: {
            redirectMessage: 'Logging out from ICRS...',
            redirectUrl: ConfigService.getICRSSAMLLogoutUrl(),
          },
        });
      }

      if (ConfigService.getSALAuthEnabled()) {
        this.$router.push({
          path: '/icrs-redirect-page',
          query: {
            redirectMessage: 'Logging out from ICRS...',
            redirectUrl: ConfigService.getICRSSALLogoutUrl(),
          },
        });
      }
      this.configDeactivated();
    },
    onHideLogoutNotifDialog () {
      this.$router.push(this.$route.path);
    },
  },
  async created () {
    /**
     * handle authentication
     */
    await this.initUser();
  },
  watch: {
    user (val, oldVal) {
      if (val && !oldVal) {
        this.logged = true;
      } else if (!val && !oldVal) {
        if (this.getIsAddDev) {
          window.open(ConfigService.getICRSSALLoginUrl(), '_self');
        } else {
          this.$router.push('/login-page');
        }
      }
    },
    '$route.path': {
      immediate: true,
      handler (value) {
        const hideNavBarPages = ['/login-page', '/unauthorized-page', '/icrs-redirect-page'];
        if (hideNavBarPages.includes(value)) {
          this.hideNavbar();
        } else {
          this.showNavbar();
        }
      },
    },
  },
};
</script>

<style  lang="scss">
@import "./styles/style.scss";

#router-window {
  height: 100vh;
  overflow: hidden scroll;
}
</style>
