<template>
  <b-card-body body-bg-variant="blue-gray-100_alpha">
    <b-card-title class="mb-2">API</b-card-title>

    <b-list-group flush>
      <b-card-text>
        Utilize
        <strong>Content API</strong> to request for Results, Insights and Recommendations.
      </b-card-text>
      <b-card-text>
        Utilize
        <strong>Ontology API</strong> to request for Natural Language Queries and Knowledge Discovery.
      </b-card-text>

      <b-card-text>
        Apply Faceted Search to narrow down to desired results quickly.
      </b-card-text>

      <b-card-text id="iconTextGroup">
        <icon-text faType="fal" faName="layer-group" text="Facets"></icon-text>
        <icon-text faType="fal" faName="filter" text="Filters"></icon-text>
      </b-card-text>

      <b-card-text>
        Find results fast using <strong>Search UI</strong>.
      </b-card-text>
      <redirect-button label="Search UI" pathLink="/search" :variant="variant"></redirect-button>
    </b-list-group>
  </b-card-body>
</template>

<script>
import IconText from '@/components/IconText.vue';
import redirectButton from './RedirectionButton.vue';

export default {
  components: {
    redirectButton,
    IconText,
  },
  props: {
    variant: String,
  },
};
</script>

<style scoped lang="scss">
  #iconTextGroup {
    margin-left: auto;
    margin-right: auto;
  }
</style>
