import SearchConstants from '@/constants/search-service-constants';
import FilterConfigItem from '@/services/models/search/filter-config-item.model';
import SearchService from '@/services/search.service';

/**
 * @param {import('vuex').ActionContext} ctx
 * @param {import('@/services/models/search/filter-item.model').default[]} filters
 */
export function setSelectedFilters ({ dispatch, commit }, filters) {
  commit('setSelectedFilters', filters);
  dispatch('searchService/updateCurrentSearchParameter', null, { root: true });
}

export function setModalSelectedFilterGroup ({ commit }, filterGroup) {
  commit('setModalSelectedFilterGroup', filterGroup);
}

export function setModalSelectedFilters ({ commit }, filters) {
  if (filters && filters.length === 0) {
    commit('resetModalTempTopicsQuery');
  }
  commit('setModalSelectedFilters', filters);
}

export function applyModalSelectedFilters ({ commit }, group) {
  commit('applyModalSelectedFilters', group);
}

export function setModalSearchKeyword ({ commit }, keyword) {
  commit('setModalSearchKeyword', keyword);
}

export function setModalSortBy ({ commit }, sortBy) {
  commit('setModalSortBy', sortBy);
}

export function setIsFirstOpenHasSelectedFilters ({ commit }, flag) {
  commit('setIsFirstOpenHasSelectedFilters', flag);
}

export function executeFilterSearch ({ dispatch }) {
  dispatch('searchService/setCurrentSearchMode', SearchConstants.SEARCH_MODE.FILTER, { root: true });
  dispatch('searchService/executeSearchQuery', { filterGroupLevel: 2 }, { root: true });
  dispatch('searchService/newResults/executeSearchNewQuery', { filterGroupLevel: 2 }, { root: true });
  // dispatch('searchService/executeCategories', {}, { root: true });
  // dispatch('searchService/newResults/executeCategoriesNew', {}, { root: true });
}

export function getFilterGroupings ({ commit }) {
  // const filterConfigItems = SearchService.getFilterGroupings().map(filter => new FilterConfigItem(filter));
  // commit('setFilterGroupings', filterConfigItems);

  return SearchService.getFilterGroupings()
    .then((data) => {
      const filterConfigItems = data.map(filter => new FilterConfigItem(filter));
      commit('setFilterGroupings', filterConfigItems);
    });
}
